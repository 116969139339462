export const NavigatorArray = [
    [
        [
            {
                col1: "Центральный федеральный округ",
                col2: "Липецкая область",
                col3: "Управление образования и науки Липецкой области",
                col4: "ул. Циолковского, д. 18, г. Липецк, 398600",
                col5: "Шуйкова Инесса Анатольевна, начальник управления образования и науки Липецкой области",
                col6: "obluno@admlr.lipetsk.ru",
                col7: "8(4742) 34-95-25",
                col8: "https:\/\/uoin.schools48.ru\/",
                col9: "Анисимова Елена Геннадьевна, начальник отдела опеки и попечительства, управления образования и науки Липецкой области, тел. 8(4742)32-95-15, эл. почта AnisimovaEG@admlr.lipetsk.ru",

            },
        ], [
        {
            col1: "Центральный федеральный округ",
            col2: "Московская Область",
            col3: "Министерство социального развития Московской области",
            col4: "143407, Московская область, г. Красногорск, бульвар Строителей, д. 7 ",
            col5: "Заместитель министра Марченко Вера Петровна, Заместитель министра Романова Дарья Валентиновна  ",
            col6: "msrmo@mosreg.ru",
            col7: "Горячая линия Министерства - 122, далее 6",
            col8: "https:\/\/msr.mosreg.ru\/",
            col9: "Управление организации деятельности по опеке и попечительству, Начальник управления:\nКуркина Лидия Юрьевна"
        },
    ], [
        {
            col1: "Центральный федеральный округ",
            col2: "Орловская область ",
            col3: "Департамент социальной защиты, опеки и попечительства, труда и занятости Орловской области",
            col4: "ул. Лескова, д. 22, г. Орёл, Орловская область, 302040",
            col5: " Руководитель Департамента Гаврилина Ирина Александровна ",
            col6: "dszn@adm.orel.ru",
            col7: "7 (4862) 63-33-11, 7 (4862) 63-33-42",
            col8: "https:\/\/dszn57.ru\/",
            col9: "Трофименкова Елена Владимировна - начальник отдела опеки и попечительства управления оранизации социального обслуживания, опеки и попечительства, 8 (4862) 63-33-11 доб 287"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Рязанская область ",
            col3: "Министерство образования и молодежной политики Рязанской области",
            col4: "ул. Каширина, д.1, г. Рязань, 390000",
            col5: " Министр образования Рязанской области\nОльга Сергеевна Щетинкина",
            col6: "minobr@ryazan.gov.ru",
            col7: "(4912) 51-51-52 ",
            col8: "https:\/\/minobr.ryazan.gov.ru\/"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Смоленская область",
            col3: "Министерство образования и науки Смоленской области",
            col4: "ул. Николаева, д. 12 а, г. Смоленск, 214004",
            col5: "Новиков Владислав Викторович, министр",
            col6: "obraz@admin-smolensk.ru ",
            col7: "8(4812)381722",
            col8: "https:\/\/edu.admin-smolensk.ru\/",
            col9: "Заместитель начальника отдела опеки и попечительства Чмарова Оксана Михайловна, 8 (4812) 29-27-35, Chmarova_OM@admin-smolensk.ru"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Тамбовская область ",
            col3: "Министерство образования и науки Тамбовской области",
            col4: "ул. Советская, д. 108\/8, г. Тамбов, Тамбовская область, 392000",
            col5: "Котельникова Татьяна Петровна - министр образования и науки Тамбовской области; Быкова Марина Васильевна - заместитель министра-начальник управления дополнительного образования, воспитания, социализации государственной поддержки детей министерства образования и науки Тамбовской области ",
            col6: "post@obraz.tambov.gov.ru",
            col7: "8(4752)72-37-38, (4752)79-23-33 (доб.23-33), (4752)79-23-28 (доб.23-28)",
            col8: "https:\/\/obraz.tmbreg.ru\/",
            col9: "Масальская Светлана Сергеевна, начальник отдела охраны прав детства Министерства образования и науки Тамбовской области, opeka@obraz.tambov.gov.ru т . (4752) 79-23-30, (4752) 79-23-31, 79-23-32, 78-61-83, 78-61-88"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Тверская область ",
            col3: "Министерство социальной защиты населения Тверской области",
            col4: "170100, г. Тверь, набережная р.Лазури, д.20",
            col5: "Боброва Татьяна Вячеславовна, статс-секретарь - заместитель Министра социальной защиты населения Тверской области ",
            col6: "min_soczashity@tverreg.ru",
            col7: "8(4822) 34-27-63, 8(4822) 32 03 26, 8 (4822) 34 90 04 ",
            col8: "https:\/\/минсоцзащиты.тверскаяобласть.рф\/",
            col9: "Афанасьева Светлана Руслановна, начальник отдела опеки и попечительства, AfanasievaSR@tverreg.ru, 8 (4822) 34 86 57"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Белгородская область ",
            col3: "Министерство социальной защиты населения и труда Белгородской области",
            col4: "пр-т Славы,д. 24, г. Белгород, 308026",
            col5: "Батанова Елена Павловна\nМинистр социальной защиты населения и труда Белгородской области",
            col6: "minsoc@belregion.ru",
            col7: "7 (4722) 27-62-25",
            col8: "http:\/\/minsoc31.ru\/",
            col9: "начальник отдела по делам семьи и детей департамента социального обеспечения минсоцтруда области Соловьева Александра Евгеньевна, uszn31opeka@mail.ru; 8 (4722) 27-57-12"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Брянская область",
            col3: "Департамент социальной политики и занятости населения Брянской области",
            col4: "проспект Станке Димитрова, д. 88, г. Брянск, Брянская область, 241033",
            col5: "Петров Евгений Александрович, Директор департамента ",
            col6: "rpsbryansk@mail.ru ",
            col7: "8(4832) 30-10-18",
            col8: "https:\/\/www.uszn032.ru\/",
            col9: "Начальник отдела по опеке и попечительству департамента социальной политики и занятости населения Брянской области Жолнерова Людмила Константиновна, 8(4832) 30-32-46; zholnerova@uszn032.ru",
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Тульская область ",
            col3: "Министерство труда и социальной защиты Тульской области",
            col4: "ул. Пушкинская, д. 29, г. Тула, 300041",
            col5: "Зыкова Наталия Алексеевна, заместитель министра - директор департамента по вопросам семьи и демографии министерства труда и социальной защиты Тульской области",
            col6: "mintrud@tularegion.ru",
            col7: "8 (4872) 24-52-50",
            col8: "https:\/\/mintrud.tularegion.ru\/",
            col9: " Борзова Елена Михайловна, Заместитель директора - начальник отдела организации помощи семье и детям \n8 (4872) 24-51-96, 24-51-04 (доб. 35-13);  Костина Алена Сергеевна, Заместитель директора - начальник отдела организации опеки, попечительства и жилищного обеспечения 8 (4872) 24-51-93, 24-51-04 (доб. 35-12) "
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Владимирская область ",
            col3: "Министерство образования и молодежной политики Владимирской области",
            col4: "ул. Комсомольская, д. 1, г. Владимир, 600000",
            col5: "Первый заместитель Министра образования и молодежной политики Владимирской области - Арлашина Светлана Александровна",
            col6: "info@obrazovanie33.ru",
            col7: "8 (4922) 77-76-24",
            col8: "https:\/\/министерство.образование33.рф\/",
            col9: "Начальник отдела защиты детства и семейной политики Министерства образования и молодежной политики Владимирской области - Китаева Наталья Анатольевна,\nтел.(4922) 77-75-42"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Ярославская область",
            col3: "Министерство образования Ярославской области",
            col4: "ул. Советская, д. 7, г. Ярославль, Ярославская область, 150000",
            col5: "Лобода Ирина Валентиновна, министр образования Ярославской области, Иванова Татьяна Викторовна, заместитель министра",
            col6: "dobr@yarregion.ru",
            col7: " +7 (4852) 40-18-95, +7 (4852) 31-43-43",
            col8: "https:\/\/portal.yarregion.ru\/depts-dobr\/",
            col9: "Митюкова Светлана Павловна, начальник методического отдела государственного казенного учреждения Ярославской области \"Агентство по обеспечению функционирования системы образования Ярославской области\", 8(4852) 332284, 89038230727, msp.agentstvo@mail.ru",

        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Воронежская область ",
            col3: "Министерство социальной защиты Воронежской области",
            col4: "Ворошилова,14,  г. Воронеж, Воронежская область, 394006",
            col5: "Министр: Сергеева Ольга Владимировна",
            col6: "social@govvrn.ru",
            col7: "7 (4732) 77-18-17, запись на прием (473) 272-78-14",
            col8: "https:\/\/www.govvrn.ru\/organizacia\/-\/%7E\/id\/844493",
            col9: "отдел организации социального обслуживания\nсемьи, женщин и детей министерства социальной защиты Воронежской области\nтел. (473) 212-69-07, dsz_deti@govvrn.r"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Москва ",
            col3: "Департамент труда и социальной защиты населения города Москвы",
            col4: "ул. Новая Басманная, д. 10, стр. 1, г. Москва, 107078",
            col5: "заместитель руководителя Келлер Павел Анатольевич",
            col6: "dszn@mos.ru",
            col7: "Единая справочная служба Департамента труда и социальной защиты населения города Москвы:\n8 (495) 870-44-44 (круглосуточно)",
            col8: "dszn.ru",
            col9: "Данько Татьяна Валерьевна\nНачальник Управления опеки и попечительства Департамента труда и социальной защиты населения города Москвы, 8 (495) 625-37-20; Никишина Любовь Александровна \nЗаместитель начальника управления — начальник отдела организации работы органов опеки и попечительства, 8 (495) 625-55-17;Территориальные отделы по вопросам опеки и попечительства: https:\/\/dszn.ru\/department\/structure\/86"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Ивановская область ",
            col3: "Департамент социальной защиты населения Ивановской области ",
            col4: "пер. Свободный, д. 4, г. Иваново, Ивановская область, 153012",
            col5: "Демина Анна Юрьевна, ЧленПравительства Ивановской области-директор Департамента социальнойзащитынаселения Ивановской области\nИвановской области",
            col6: "dszn@ivreg.ru ",
            col7: "8-800-100-16-60 (бесплатный),(4932) 41-05-57",
            col8: "https:\/\/szn.ivanovoobl.ru\/?ysclid=lsumowoq9s862791385",
            col9: "Стахеева Елена Юрьевна, начальник управления по опеке и попечительству Департамента социальной защиы населения Ивановской области, тел.: 8 (4932) 41-21-40; Opekadszn@ivreg.ru"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Калужская область ",
            col3: "Министерство труда и социальной защиты Калужской области",
            col4: "ул. Пролетарская, д. 111, г. Калуга, Калужская область, 248016",
            col5: "Алексеева Елена Юрьевна - заместиель министра, начальник управления по опеке и попечительству демогарфической и семейной политике",
            col6: "mintrud@adm.kaluga.ru",
            col7: "8(4842) 71-94-11, 71-91-04",
            col8: "https:\/\/pre.admoblkaluga.ru\/sub\/minsocial\/",
            col9: "УПРАВЛЕНИЕ ПО ОПЕКЕ И ПОПЕЧИТЕЛЬСТВУ, ДЕМОГРАФИЧЕСКОЙ И СЕМЕЙНОЙ ПОЛИТИКЕ alekseevaeu@adm.kaluga.ru, 8(4842)71-91-47"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Костромская область ",
            col3: "Департамент по труду и социальной защите населения Костромской области",
            col4: "ул. Свердлова,д. 129, г. Кострома, Костромская область, 156029",
            col5: "директор Департамента Василькова Екатерина Александровна",
            col6: "socdep@adm44.ru",
            col7: "+7 (4942) 55-90-62",
            col8: "https:\/\/socdep.kostroma.gov.ru\/"
        },
    ], [
        {
            col1:"Центральный федеральный округ",
            col2: "Курская область ",
            col3: "Министерство социального обеспечения, материнства и детства Курской области",
            col4: "ул. Моковская, д. 2«Г» , г.Курск, 305007 ",
            col5: "Заместитель министра Полякова Наталья Анатольевна",
            col6: "kco@rkursk.ru",
            col7: "8 (4712)  400-300, доб. 105 ",
            col8: "https:\/\/kursk.ru\/region\/control\/strukturnye-podrazdeleniya-administratsii-kurskoy-oblasti\/komitet-sotsialnogo-obespecheniya-materinstva-i-detstva-kurskoy-oblasti\/?ysclid=lsx5sj2byi487118820",
            col9: "Фадеева Наталья Николаевна, начальник управления организации деятельности по опеке и попечительству Министерства социального обеспечения, материнства и детства Курской области,  8(4712)400-300 доб. 120, deppoopeke@rkursk.ru"
        },
    ]
    ], [
        [
            {
                col1: "Приволжский федеральный округ",
                col2: "Оренбургская область",
                col3: "Министерство образования Оренбургской области",
                col4: "ул. Володарского, дом 11(I корпус); пр. Парковый, 7 (II корпус), г. Оренбург, Оренбургская область, 460000",
                col5: "Министр   \nПахомов Алексей Александрович",
                col6: "minobr@mail.orb.ru",
                col7: "8(3532) 500-855",
                col8: "https:\/\/minobr.orb.ru\/",
                col9: "Самарина Наталья Ивановна, начальник отдела охраны прав детей, воспитания и специального образования, (3532) 500-840 доб.759; nis@mail.orb.ru  "
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Пензенская область ",
                col3: "Министерство труда, социальной защиты и демографии Пензенской области",
                col4: "440008,  г. Пенза,       ул. Некрасова, д. 24",
                col5: "Министр - Качан Алексей Андреевич, заместитель Министра - Казаков Сергей Алесандрович",
                col6: "mintrud58@mintrud58.ru",
                col7: "8(8412) 20-20-10",
                col8: "https:\/\/trud.pnzreg.ru\/",
                col9: "Брыкина Наталья Николаевна - начальник отдела организации социального спровождения отдельных категорий граждан, тел.  8(8412) 20-20-10*1002 (приемная), brykina@mintrud58.ru"
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Самарская область ",
                col3: "Министерство социально-демографической и семейной политики Самарской области",
                col4: "ул. Революционная, д. 44, г. Самара, 443086",
                col5: "Рубежанский Олег Юрьевич - Врио первого заместителя министра- руководитель департамента ресурсного обеспечения, опеки и попечительства",
                col6: "depart@socio.samregion.ru",
                col7: "8 (846) 334-27-02 ",
                col8: "https:\/\/minsocdem.samregion.ru\/",
                col9: "Дроздова Людмила Владимировна, руководитель управления организации опеки и попечительства министерства социально-демографической и семейной политики Самарской области, 7 (846)214-51-31"
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Саратовская область ",
                col3: "Министерство образования Саратовской области",
                col4: "ул. Соляная, д. 32, г.Саратов, 410002",
                col5: "Калягина Галина Владимировна, заместитель министра образования-начальник управления специального образования и защиты прав несовершеннолетних",
                col6: "minobr@minobr.saratov.gov.ru",
                col7: "(845-2) 49-21-03",
                col8: "http:\/\/minobr.saratov.gov.ru\/",
                col9: "Жаркова Наталья Михайловна-начальник отдела опеки и защиты прав несовершеннолетних, (845-2) 49-19-69, адрес эл. почты zharkovanm@minobr.saratov.gov.ru"
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Ульяновская область ",
                col3: "Министерство социального развития Ульяновской области",
                col4: "Ульяновская область, г.Ульяновск, ул.Федерации, д.60, 432071",
                col5: "Иванова Ольга Александровна, заместитель Министра социального развития Уляьнвоской области",
                col6: "glavtrud73@ulgov.ru ",
                col7: "8 (8422) 44-96-84",
                col8: "https:\/\/sobes73.ru\/",
                col9: "88422 449684 доб.0403"
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Республика Башкортостан",
                col3: "Министерство семьи, труда и социальной защиты населения Республики Башкортостан",
                col4: "ул. Пушкина, 95 г. Уфа, Республика Башкортостан, 450008",
                col5: "Заместитель министра Таболина Анна Николаевна",
                col6: "mintrud@bashkortostan.ru ",
                col7: "8 (347)2808814",
                col8: "https:\/\/mintrud.bashkortostan.ru\/",
                col9: "Пашина Елена Сергеевна, начальник Отдела организации опеки и попечительства над несовершеннолетними, +7 (347) 218-03-60, Pashina.ES@bashkortostan.ru",

            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Республика Марий Эл",
                col3: "Министерство труда и социальной защиты Республики Марий Эл",
                col4: "наб.Брюгге, д.3, г. Йошкар-Ола, Республика Марий Эл, 424033",
                col5: "Ярцева Галина Михайловна, заместитель министра труда и социальной защиты Республики Марий Эл",
                col6: "minso@mari-el.ru",
                col7: "8 (8362) 45-17-36, 8(8362) 42-16-59",
                col8: "https:\/\/mari-el.gov.ru\/ministries\/minsoc\/",
                col9: "Управление опеки и попечительства Министерства труда и социальной защиты Республики Марий Эл , начальник - Якурнова Наталья Олеговна (8362) 42-16-48",

            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Республика Мордовия ",
                col3: "Министерство социальной защиты,труда и занятости населения Республики Мордовия",
                col4: "ул. Титова, д. 133, г. Саранск, Республика Мордовия, 430027",
                col5: "Шувалова Светлана Ивановна - заместитель Министра",
                col6: "shuvalovasi@e-mordovia.ru",
                col7: "8(8342)39-30-06",
                col8: "minsoc.e-mordovia.ru",
                col9: "Лукьянова Ольга Александровна,и.о.начальника отдела опеки и попечительства в отношении несовершеннолетних граждан, 8(8342)39-31-34, mtszn-opnd@e-mordovia.ru"
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Республика Татарстан",
                col3: "Министерство образования и науки Республики Татарстан",
                col4: "ул. Кремлевская, д. 9, г. Казань, Республика Татарстан, 420111",
                col5: "Хадиуллин Ильсур Гараевич министр образования и науки Республики Татарстан",
                col6: "mon@tatar.ru",
                col7: "8 843 24 95 66, 8 843 294 95 60 ",
                col8: "https:\/\/mon.tatarstan.ru\/?ysclid=lsep1lxfw3147245996",
                col9: "Васильева Венера Олеговна, начальник опеки, попечительства и педагогической поддержки Министерства образования Республики Татарстан, Venera.Vasileva@tatar.ru "
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Удмуртская республика ",
                col3: "Министерство социальной политики и труда Удмуртской республики",
                col4: "улица Ломоносова, дом 5, г. Ижевск, Удмуртская Республика, 426004",
                col5: "Первый заместитель министра Чернов Алексей Александрович",
                col6: "mail@msp.udmr.ru",
                col7: " 8(3412) 22-27-30",
                col8: "https:\/\/minsoc18.ru\/",
                col9: "Зайцева Ольга Александровна\nначальник управления по вопросам семьи и детства, 7 (3412) 222-795, Bratukhina_OA@msp.udmr.ru "
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Чувашская республика ",
                col3: "Министерство образования Чувашской Республики",
                col4: "428004, г. Чебоксары, Президентский бульвар, д. 17",
                col5: "Заместитель министра  - Лежнина Марина Николаевна",
                col6: "minobr@cap.ru",
                col7: "(8352) 56-53-71 ",
                col8: "https:\/\/obrazov.cap.ru\/",
                col9: "(8352) 56-53-71 доб.1604. эл.почта: obrazov_zam3@cap.ru Заведующий сектором организации деятельности по опеке и попечительству и защите прав детей Министерства образования чувашской Республики - Зверева Ольга Владимировна, obrazov_opeka@cap.ru, 7 (8352) 56-53-83, доб. 1655"
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Пермский край ",
                col3: "Министерство социального развития Пермского края",
                col4: "Пермский край, г. Пермь, ул. Ленина, д. 51, 614015",
                col5: "Подъянова Надежда Евгеньевна, статс-секретарь - заместитель министра труда и социального развития Пермского края",
                col6: "info@social.permkrai.ru",
                col7: " 8 (342) 217-77-40",
                col8: "https:\/\/minsoc.permkrai.ru\/",
                col9: "Начальник отдела опеки и попечительства над несовершеннолетними Министерства социального развития Пермского края Кель Татьяна Григорьевна. Телефон: 8 (342) 240-46-66  tgkel@social.permkrai.ru"
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Кировская область ",
                col3: "Министерство образования Кировской области",
                col4: "ул.Карла Либкнехта, д.69, г.Киров, Кировская облласть, 610019",
                col5: "Рысева Ольга Николаевна, министр образования Кировской области, Воронкина Елена Станиславовна, заместитель министра образования Кировской области",
                col6: "infor@doko.kirov.ru ",
                col7: "(8332) 27-27-34, доб. 3402, 89127342064",
                col8: "https:\/\/43edu.ru\/",
                col9: "Видякина Татьяна Васильевна, начальник отдела защиты прав детей и специального образования министерства образования Кировской области, t.vidyakina@ako.kirov.ru, (8332) 27-27-34, доб. 3436, 3438, 3440, 3441"
            },
        ], [
            {
                col1: "Приволжский федеральный округ",
                col2: "Нижегородская область ",
                col3: "Министерство социальной политики Нижегородской области",
                col4: "603082, г. Нижний Новгород, Кремль, корп. 14",
                col5: "Седых Игорь Олегович - министр; Санинская Лариса Михайловна - заместитель министра",
                col6: "official@socium.kreml.nnov.ru",
                col7: "(831) 422-28-80",
                col8: "https:\/\/minsocium.ru\/",
                col9: "Алехина Ольга Александровна - начальник управления опеки и попечительства министерства социальной политики Нижегородской области, 8 (831) 422-29-60; Милькова Светлана Николаевна - начальник отдела защиты прав и интересов несовершеннолетних управления опеки и попечительства министерства социальной политики Нижегородской области, 8 (831) 422-29-03; opekadeti@minsocium.ru          "
            },
        ]
    ], [
        [
            {
                col1: "Северо-Западный федеральный округ",
                col2: "Республика Карелия ",
                col3: "Министерство социальной защиты Республики Карелия",
                col4: "пр. Ленина, д. 6, г. Петрозаводск, Республика Карелия, 185910",
                col5: "Детков Алексей Валентинович, заместитель Министра социальной защиты Республики Карелия",
                col6: "social@minsoc.karelia.ru, minsoc@soc.gov10.ru",
                col7: "\n+7 (8142) 59-57-48, \n+7 (8142) 59-29-65, +7 (8142) 59-57-65",
                col8: "https:\/\/soc.gov.karelia.ru\/",
                col9: "Отдел профилактики социального\nсиротства Министерства социальной защиты Республики Карелия, Начальник отдела профилактики социального сиротства Министерства социальной защиты Республики Карелия\nМинина Виктория Владимировна, \n(8142) 59 57 56 (вн.1903),\nminina@soc.gov10.ru"
            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Республика Коми",
                col3: "Министерство образования, науки и молодежной политики Республики Коми",
                col4: "ул. К. Маркса, д. 210, г. Сыктывкар, Республика Коми, 167982",
                col5: "Смирнова Лидия Валерьевна, первый заместитель министра образования и науки Республики Коми",
                col6: "minobr@minobr.rkomi.ru",
                col7: "88212)301662(доб 301)",
                col8: "minobr.rkomi.ru",
                col9: "Отдел контроля деятельности по опеке и попечительству Министерства образования и науки Республики Коми, Маловичко Надежда Владимировна, 8(212)301-262 доб.406",

            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Архангельская область ",
                col3: "Министерство труда, занятости и социального развития Архангельской области",
                col4: "пр. Новгородский, дом 160, г. Архангельск, Архангельская область, 163000",
                col5: "Маневская Светлана Брониславовна, заместитель министра - начальник управления защиты прав детства, опеки и попечительства в отношении несовершеннолетних министерства труда, занятости и социального развития Архангельской области ",
                col6: "mintrud@dvinaland.ru",
                col7: "(8182) 41-08-80",
                col8: "https:\/\/www.arhzan.ru\/",
                col9: "8 (8182) 41 08 44, manevskaya@dvinaland.ru"
            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Вологодская область ",
                col3: "Департамент социальнойзащиты населенияВологодской области",
                col4: "ул. Благовещенская, д. 9, г. Вологда, Вологодская область, 160001",
                col5: "Начальник Департамента РЫНДИН Николай Львович",
                col6: "depsoc@socium.gov35.ru",
                col7: "тел. (8172) 23-01-36, 23-01-35 ",
                col8: "https:\/\/socium.gov35.ru\/?ysclid=lsulac94jv141775529",
                col9: "Кудринская Ирина Валериевна, ведущий советник\n отдела по вопросам организации опеки и попечительства, семьи и детства управления по социальным вопросам, опеке и попечительству; KudrinskayaIV@socium.gov35.ru; (8172) 23 01 38 доб. 21 16)"
            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Калининградская область ",
                col3: "Министерство социальной политики Калининградской области",
                col4: "ул. Клиническая, 63, г. Калининград, Калининградская область, 236016",
                col5: "Заместитель министра Фещак Алексей Иванович",
                col6: "social@gov39.ru",
                col7: "8 (4012)599-614",
                col8: "https:\/\/social.gov39.ru\/ministerstvo\/social\/kontakty\/\n\/\n",
                col9: "Начальник отдела семьи, материнства и детства, учета и устройства детей- сирот и детей, оставшихся без попечения родителей. О.Ю. Люстер 8(4012)599-614"
            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Ленинградская область ",
                col3: "Комитет по социальной защите населения Ленинградской области",
                col4: "191124, Санкт-Петербург, ул. Лафонская, д. 6 Литер А",
                col5: "Заместитель председателя комитета по социальной защите населения Ленинградской области Всеволод Русланович Бойко",
                col6: "ktszn@lenreg.ru",
                col7: "8 (812) 539-46-46  ",
                col8: "https:\/\/social.lenobl.ru\/",
                col9: "Сектор опеки и попечительства отдела социальной политики в сфере семьи комитета по социальной защите населения Ленинградской области, тел.: 8(812)539-46-32, 8(812)539-44-71; Игнатьева Эльмира Арменовна - начальник отдела социальной политики в сфере семьи"
            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Мурманская область",
                col3: "Министерство образования и науки Мурманской области",
                col4: "ул. Трудовых Резервов, д.4, г.Мурманск, 183025",
                col5: "Кузнецова Диана Николаевна, министр образования и науки Мурманской области; Паражинскене Юлия Александровна, заместитель министра образования и науки",
                col6: "edco@gov-murman.ru",
                col7: "8(815 2) 486 701 доб. 6393",
                col8: "https:\/\/minobr.gov-murman.ru\/",
                col9: "Придатченко Елена Александровна, начальник отдела опеки и попечительства Министерства образования и науки Мурманской области, 8(815 2) 486 701 доб. 1731, pridatchenko@gov-murman.ru",

            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Новгородская область ",
                col3: "Министерство образования Новгородской области",
                col4: "ул. Новолучанская, д. 27, г.Великий Новгород, 173001",
                col5: "Уральская Наталья Григорьевна, первый заместитель министра образования Новгородской области",
                col6: "minobr@edu53.ru",
                col7: "8 (8162) 50-10-70",
                col8: "https:\/\/minobr.novreg.ru\/",
                col9: "Фильченкова Наталья Алексеевна, начальник отдела опеки и попечительства в отношении несовершеннолетних граждан министерства образования Новгородской области, телефон 8(8162)501076 n.filchenkova@edu53.ru"
            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Псковская область",
                col3: "Комитет по социальной защите Псковской области",
                col4: "ул. Некрасова, д. 23, г. Псков, 180001",
                col5: "Федорова Елена Александровна — заместитель председателя Комитета по социальной защите Псковской области",
                col6: "soc-info@obladmin.pskov.ru",
                col7: "8 (8112) 299998 доп. 151",
                col8: "social.pskov.ru",
                col9: "Чернова Валентина Владимировна - начальник отдела опеки, попечительства Комитета по социальной защите Псковской области vv.chernova@social.pskov.ru (8112) 29-99-98 доб. 171",

            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Санкт-Петербург",
                col3: "Комитет по социальной политике Санкт-Петербурга",
                col4: "191144, Санкт‑Петербург, Новгородская ул., 20, лит. А",
                col5: "Тугов Дмитрий Борисович, заместитель председателя Комитета по социальной политике Санкт-Петербурга",
                col6: "ksp@gov.spb.ru",
                col7: "8 (812) 576-24-61",
                col8: "https:\/\/www.gov.spb.ru\/gov\/otrasl\/trud\/",
                col9: "Смирнова Ольга Владимировна, начальник Управления по опеке и попечительству, 8 (812) 576-24-87, Савченко Наталия Леонидовна, начальник отдела по взаимодействию с органами местного самоуправления и организации отдыха и оздоровления воспитанников подведомственных учреждений Управления по опеке и попечительству, 8 (812) 576-24-72, (812) 576-25-45, scheglova@ksp.gov.spb.ru",

            },
        ], [
            {
                col1:"Северо-Западный федеральный округ",
                col2: "Ненецкий автономный округ ",
                col3: "Департамент здравоохранения, труда и социальной защиты населения Ненецкого автономного округа",
                col4: "ул. Смидовича, д. 25, г. Нарьян-Мар, Ненецкий автономный округ, 166000",
                col5: "Лудников Петр Александрович\nЗаместитель руководителя Департамента по социальным вопросам",
                col6: "medsoc@adm-nao.ru",
                col7: " +7 (81853) 2-13-66",
                col8: "https:\/\/medsoc.adm-nao.ru",
                col9: "Тимиров Алексей Александрович Председатель комитета по семейной политике, опеке и попечительству Департамента,   +7 (81853) 2-12-53, atimirov@adm-nao.ru "
            }
        ]
    ], [
        [
            {
                col1: "Уральский федеральный округ",
                col2: "Курганская область ",
                col3: "Главное управление социальной защиты населения Курганской области",
                col4: "ул. Р. Зорге, д. 39, г. Курган, Курганская область, 640001",
                col5: "Кудрявцева Ирина Валерьевна — заместитель начальника Главного управления социальной защиты населения Курганской области",
                col6: "gusznpost@kurganobl.ru ",
                col7: "8(3522)44-14-15",
                col8: "https:\/\/sz.gov45.ru\/",
                col9: "Мельникова Ирина Евгеньевна начальник отдела опеки и попечительства Главного управления социальной защиты населения Курганской области; sirotki@mail.ru  "
            },
        ], [
            {
                col1: "Уральский федеральный округ",
                col2: "Свердловская область ",
                col3: "Министерство социальной политики Свердловской области",
                col4: "ул. Большакова, д. 105, г. Екатеринбург, Свердловская область, 620144",
                col5: "  Злоказов Андрей Владимирович\nМинистр",
                col6: "mspso@egov66.ru",
                col7: "(343) 312 - 07 - 00",
                col8: "https:\/\/msp.midural.ru\/",
                col9: "Маевская Ирина Владимировна, начальник отдела организации и контроля деятельности по опеке и попечительству Министерства социальной политики Свердловской области, 8(343)312-00-08 доб. 012, 133"
            },
        ], [
            {
                col1: "Уральский федеральный округ",
                col2: "Тюменская область ",
                col3: "Департамент социального развития Тюменской области",
                col4: "ул. Республики, д. 83 а, г. Тюмень, 625048",
                col5: "\nГрамотин Дмитрий Викторович\nДиректор департамента социального развития",
                col6: "dsoc@72to.ru",
                col7: "8 (3452) 502-649, (3452) 50-24-30",
                col8: "https:\/\/soc.admtyumen.ru\/",
                col9: "Тигеева Инга Викторовна, начальник управления по вопросам семьи и детства Департамента социального развития Тюменской области, 8 (3452) 20-18-03"
            },
        ], [
            {
                col1: "Уральский федеральный округ",
                col2: "Ханты-Мансийский АО",
                col3: "Департамент социального развития Ханты-Мансийского автономного округа - Югры",
                col4: "ул. Мира, д. 5, г. Ханты-Мансийск, 628006",
                col5: "Заместитель директора Департамента Кинзина Ирина Александровна",
                col6: "Socprotect@admhmao.ru",
                col7: "8 (3467) 32-20-40 (доб. 3606) ",
                col8: "https:\/\/depsr.admhmao.ru\/ ",
                col9: "Начальник управления по опеке и попечительству Батраева Наталья Викторовна, 8(3467) 32-20-40 (доб.3715) BatraevaNV@admhmao.ru, 8 (3467) 32-20-40 (доб.3634) OstapenkoUI@admhmao.ru"
            },
        ], [
            {
                col1: "Уральский федеральный округ",
                col2: "Ямало-Ненецкий автономный округ ",
                col3: "Департамент образования Ямало-Ненецкого автономного округа",
                col4: "629007,  Ямало-Ненецкий автономный округ, г. Салехард, ул.Совхозная, д.14, корп.1",
                col5: "директор Кравец Марина Владимировна \n",
                col6: "yamaledu@yanao.ru",
                col7: "7 (34922) 4-01-51, (34922) 4-03-73 ",
                col8: "https:\/\/do.yanao.ru\/",
                col9: "Начальник отдела социально-правовой защиты детей и учащейся молодежи Крашенинников Александр Вадиславович 7(34922)4-01-03, AVKrasheninnikov@yanao.ru\n"
            },
        ], [
            {
                col1: "Уральский федеральный округ",
                col2: "Челябинская область ",
                col3: "Министерство социальных отношений Челябинской области",
                col4: "ул. Воровского, д. 30, г. Челябинск, Челябинская область, 454048",
                col5: " Буторина Ирина Вячеславовна, Министр социальных отношений Челябинской области   ",
                col6: "minsoc@gov74.ru",
                col7: "8 (351) 232 39 28",
                col8: "https:\/\/minsoc.gov74.ru",
                col9: "Зарезина Марина Леонидовна, начальник управления демографической политики Министерства социальных отношений Челябинской области, 8(351)232-41-39 Емельянова Елена Анатольевна, начальник отдела организации работы по опеке и попечительству Министерства социальных отношений Челябинской области, 8(351)232-41-45 Zarezina.ML@minsoc74.ru, Emelyanova.EA@minsoc74.ru,  opek@minsoc74.ru 8(351)264-07-90"
            }
        ]
    ], [[
        {
            col1: "Южный федеральный округ",
            col2: "Республика Адыгея",
            col3: "Министерство образования и науки Республики Адыгея",
            col4: "ул. Советская, д. 176, г. Майкоп, Республика Адыгея, 385000",
            col5: "заместитель министра Паранук Сусанна Руслановна",
            col6: "minobr_ra@mail.ru",
            col7: "8 (8772) 52-16-55",
            col8: "http:\/\/www.adygheya.ru\/ministers\/departments\/ministerstvo-obrazovaniya-i-nauki\/",
            col9: "Уджухова Белла Адамовна, начальник отдела социально-правовой поддержки детства и информационно- технологического обеспечения Министерства образования и науки Республики Адыгея, +7 (8772) 52-26-63 526314@mail.ru"
        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Республика Калмыкия ",
            col3: "Министерство образования и науки Республики Калмыкия",
            col4: "ул. А.С. Пушкина, д. 18, г. Элиста, Республика Калмыкия, 358000",
            col5: "Ангирова Булган Борисовна, \nМинистр\nМацакова Эльзятя Муняевна, заместитель Министра",
            col6: "mokinrk@rk08.ru",
            col7: "(84722) 3-41-84",
            col8: "www.monrk.ru",
            col9: "Оляева Нюдля Александровна, зведующий \nсектором защиты прав детей\n 8(84722)40729, opekark08@mail.ru"
        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Республика Крым",
            col3: "Министерство образовани, науки и молодежи Республики Крым",
            col4: "пер. Совнаркомовский, 3, г. Симферополь, Республика Крым, 295000",
            col5: "Асанов Тимур Решатович, заместитель министра образования, науки и молодежи Республики Крым",
            col6: "info@crimeaedu.ru ",
            col7: "7 (3652) 25-44-42",
            col8: "http:\/\/monm.rk.gov.ru\/",
            col9: "Зуева Инна Владимировна, начальник управления по защите прав несовершеннолетних Министерства образования, науки и молодежи Республики Крым, 7 (3652) 51-03-31, upravlenie-13@mail.ru"
        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Краснодарский край ",
            col3: "Министерство труда и социального развития Краснодарского края",
            col4: "ул. Чапаева, д. 58, г. Краснодар, Краснодарский край, 350000",
            col5: " Гедзь Алексей Алексеевич\nЗаместитель министра ",
            col6: "msrsp@krasnodar.ru",
            col7: "(861) 279-16-62 ",
            col8: "https:\/\/szn.krasnodar.ru\/",
            col9: "Савенко Наталья Валентиновна,заместитель начальника отдела опеки и попечительства в отношении несовершеннолетних, n.savenko@mtsr.krasnodar.ru, 8 (861) 259-16-46"
        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Астраханская область",
            col3: "Министерство социального развития и труда Астраханской области",
            col4: "ул. Бакинская, д. 147, г. Астрахань, Астраханская область, 414000",
            col5: "Заплавнов Дмитрий Михайлович - министр социального развития и труда Астраханской области",
            col6: "pens@astrobl.ru",
            col7: "8(8512)66-79-87",
            col8: "https:\/\/minsoctrud.astrobl.ru\/",
            col9: "Морозова Дарья Владимировна - начальник отдела развития семейных форм устройства детей управления по семейной политике и защите прав детей-сирот министерства социального развития и труда Астраханской области",

        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Волгоградская область ",
            col3: "Комитет социальной защиты населения Волгоградской области",
            col4: "ул. Новороссийская, д. 41, г. Волгоград, Волгоградская область, 400087",
            col5: "Председатель комитета\nЗаботина Лилия Юрьевна",
            col6: "uszn@volganet.ru",
            col7: " (8442) 30-80-00, (8442) 30-80-80",
            col8: "https:\/\/uszn.volgograd.ru\/",
            col9: "Начальник отдела опеки и попечительства Приклонская Юлия Викторовна, (8442) 30-82-13"
        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Ростовская область ",
            col3: "Министерство общего и профессионального образования Ростовской области",
            col4: "переулок Доломановский, д. 31, г. Ростов-на-Дону, Ростовская область, 344082",
            col5: "Министр общего и профессионального образования Ростовской области \nШЕВЧЕНКО Тамара Сергеевна",
            col6: "min@rostobr.ru",
            col7: "+7 (863) 240-34-97 ",
            col8: "https:\/\/minobr.donland.ru\/",
            col9: "Фенева Луиза Николаевна, начальник отдела социально-правовой поддержки детства и координации органов опеки и попечительства министерства общего и профессионального образования Ростовской области; feneva_ln@rostobr.ru, 8(863)240-49-47"
        },
    ], [
        {
            col1: "Южный федеральный округ",
            col2: "Севастополь",
            col3: "Департамент образования и науки города Севастополя",
            col4: "ул. Щербакова, д.10, г.Севастополь, 299011",
            col5: "Мачулина  Анна Владимировна, заместитель директора Департамента - начальник Управления по защите прав несовершеннолетних, опеки и попечительства",
            col6: "do@sev.gov.ru",
            col7: "8(8692) 55-02-52",
            col8: "https:\/\/edu.sev.gov.ru\/",
            col9: "uzn.do@gs.sev.gov.ru Заместитель начальника управления-начальник отдела опеки и попечительства Шемятина Марина Александровна, 8(8692)40-41-20, opeka.don@gs.sev.gov.ru; Начальник отдела по защите прав несовершеннолетних Титаренко Елена Анатольевна,  8(8692) 48-13-53, ozpn.don@gs.sev.gov.ru   "
        },
    ]
    ], [
        [
            {
                col1: "Сибирский федеральный округ",
                col2: "Алтайский край ",
                col3: "Министерство образования и науки Алтайского края",
                col4: "ул. Ползунова, д. 36, г. Барнаул, Алтайский край, 656043",
                col5: "министр Светлана Павловна Говорухина ",
                col6: "info@22edu.ru",
                col7: "8(3852) 298-600",
                col8: "http:\/\/www.educaltai.ru\/about_main\/leadership",
                col9: "Начальник Сектора профилактики социального сиротства, опеки и попечительства Баженова Любовь Григорьевна 8(3852)29-86-09 bazhenova_lg@22edu.ru, Начальник Отдел охраны прав детства Швецова Наталья Алексеевна 8(3852)298677"
            },
        ], [
            {
                col1:"Сибирский федеральный округ",
                col2: "Красноярский край",
                col3: "Министерство образования Красноярского края",
                col4: "ул. Карла Маркса, д 122, г. Красноярск, Красноярский край,660021",
                col5: "Первый заместитель министра образования Красноярского края Анохина Наталья Викторовна ",
                col6: "mon@krao.ru",
                col7: "7 (391) 211-93-10, 7 (391) 221-28-26",
                col8: "krao.ru",
                col9: "Долгих Галина Ивановна, начальник отдела по взаимодействию с муниципальными органами опеки и попечительства, усыновлению министерства образования Красноярского края, dolgih@krao.ru, 8 391 211 77 22",

            },
        ], [
            {
                col1:"Сибирский федеральный округ",
                col2: "Иркутская область ",
                col3: "Министерство социального развития, опеки и попечительства Иркутской области ",
                col4: "664025 Россия, Иркутская обл., г. Иркутск, ул. Канадзавы, д.2",
                col5: "Родионов Владимир Анатольевич Министр социального развития, опеки и попечительства Иркутской области",
                col6: "obl_sobes@sobes.admirk.ru",
                col7: "8 (3952) 52-75-22",
                col8: "https:\/\/irkobl.ru\/sites\/society\/index.php",
                col9: "Симакова Мария Сергеевна; Начальник управления опеки и попечительства мнистерства социального развития, опеки и попечительства Иркутской области;  simakova-ms@govirk.ru"
            },
        ], [
            {
                col1:"Сибирский федеральный округ",
                col2: "Кемеровская область",
                col3: "Министерство образования Кузбасса",
                col4: "пр-т Советский, д. 58, г. Кемерово, Кемеровская область, 650064",
                col5: "Софья Юрьевна Балакирева\nМинистр образования Кузбасса",
                col6: "recep@ruobr.ru",
                col7: "(3842) 36-43-21, (3842) 36-43-66 ",
                col8: "https:\/\/образование42.рф\/",
                col9: "Управление по вопросам защиты семьи и детства, Начальник управления Дмитриева Ирина Владимировна, 8 (384) 2 58-47-06"
            },
        ], [
            {
                col1:"Сибирский федеральный округ",
                col2: "Новосибирская область",
                col3: "Министерство труда и социального развития Новосибирской области",
                col4: "ул. Серебренниковская, д. 6, г.Новосибирск, 630107",
                col5: "Потапова Ольга Рамильевна, заместитель министра труда и социального развития Новосибисркой области",
                col6: "uszn@nso.ru",
                col7: "(383) 2387817",
                col8: "https:\/\/mtsr.nso.ru\/",
                col9: "Милеева Екатерина Сергеевна, начальник отдела опеки и попечительства управления семейной политики и защиты прав детей министерства труда и социального развития Новосибирской области, контактный телефон (383) 2387800, mies@nso.ru",

            },
        ], [
            {
                col1:"Сибирский федеральный округ",
                col2: "Омская область ",
                col3: "Министерство образования Омской области",
                col4: "ул. Красный путь, д. 5, г.Омск, 644002",
                col5: "Кротт Иван Иванович, Заместитель Председателя\nПравительства Омской области, Министр образования Омской области, 2.Груздева Оксана Владимировна, заместитель Министра образования Омской области- начальник управления опеки и попечительства над несовершеннолетним и и адаптивного образования",
                col6: "educate@omskportal.ru",
                col7: "8(3812)357000 (доб. 2705)",
                col8: "https:\/\/mobr.omskportal.ru\/oiv\/mobr",
                col9: "Дербенева Наталья Николаевна, заместитель начальника управления опеки и попечительства над несовершеннолетними и\nадаптивного образования — начальник отдела опеки и попечительства над несовершеннолетними Министерства\nобразования Омской области 8 (3812) 357 000 (доб. 2888),Кочетова Анджела Геннадьевна, начальник отдела координации деятельности комиссий по делам несовершеннолетних и защите их прав и организаций для детей-сирот и детей, оставшихся без попечения родителей,управления опеки и попечительства над несовершеннолетними и адаптивного образования Министерства образования Омской области\n8 (3812) 357 000 (доб. 2837), derbenevann@minobraz.omskportal.ru kochetova@minobraz.omskportal.ru"
            },
        ], [
            {
                col1:"Сибирский федеральный округ",
                col2: "Томская область",
                col3: "Департамент по вопросам семьи и детей Томской области",
                col4: "ул. Тверская, д. 74, г. Томск, Томская область, 634041",
                col5: "Шапарева Маргарита Анатольевна - начальник Департамента по вопросам семьи и детей Томской области; Крымская Светлана Марковна - заместитель начальника Департамента по вопросам семьи и детей Томской области",
                col6: "dep-sem@tomsk.gov.ru",
                col7: "7 (3822) 713-998",
                col8: "https:\/\/depsd.tomsk.gov.ru",
                col9: "Макиенко Елена Анатольевна - председатель комитета по организации защиты прав семьи и детей Департамента по вопросам семьи и детей Томской области, контактный номер телефона: +7 (3822) 713-983",
            },
        ], [
            {
                col1:"Сибирский федеральный округ",
                col2: "Республика Алтай",
                col3: "Министерство труда, социального развития и занятости населения Республики Алтай",
                col4: "649000, Республика Алтай, г.Горно-Алтайск, ул.Северная, д. 10",
                col5: "Рябченко Эмма Вадимовна, заместитель министра труда, социального развития и занятости населения Республики Алтай",
                col6: "mintrud@mt04.ru",
                col7: "8 (38822) 4-77-32",
                col8: "https:\/\/mt04.ru\/",
                col9: "Соболева Алена Дмитриевна, начальник отдела семейной политики, опеки и попечительства, +7 (38822) 4-77-32 soboleva@mt04.ru",

            },
        ], [
            {
                col1:"Сибирский федеральный округ",
                col2: "Республика Тыва",
                col3: "Министерство образования РеспубликиТыва",
                col4: "667000, Республика Тыва г. Кызыл, ул. Ленина, д. 39",
                col5: "Хардикова Елена Викторовна - министр образования Республики Тыва",
                col6: "doc.obr@rtyva.ru",
                col7: "8 (394-22) 6-22-54",
                col8: "https:\/\/monrt.rtyva.ru\/",
                col9: "Ооржак Людмила Семдер-ооловна, заместитель министра образования Республики Тыва, Кыныраа Дупчун-Байырмаа Геннадьевна, и.о.начальника отдела организации деятельности по опеке и попечительству, тел.раю. 8(39422)62102, 30021",

            },
        ], [
            {
                col1:"Сибирский федеральный округ",
                col2: "Республика Хакасия ",
                col3: "Министерство образования и науки Республики Хакасия",
                col4: "ул. Крылова, д. 72, г. Абакан, Республика Хакасия, 655017",
                col5: "Сагалаков Юрий Гаврилович, заместитель Министра ",
                col6: "education_19@r-19.ru",
                col7: "(3902) 295-214",
                col8: "https:\/\/r-19.ru\/authorities\/ministry-of-education-and-science-of-the-republic-of-khakassia\/common\/545\/",
                col9: "Бурдакова Марина Анатольевна, начальник отдела опеки и попечительства над несовершеннолетними, sagalakov@r-19.ru, bma@r-19.ru, 8 (3902)295-266, (3902) 295-205"
            }]
    ], [
        [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Республика Дагестан",
                col3: "Министерство труда и социального развития Республики Дагестан",
                col4: "ул. А.Абубакарова, дом 117, г.Махачкала, Республика Дагестан, 367015",
                col5: "Казиев Мурад Низамиевич Министр ",
                col6: "mintrud@e-dag.ru",
                col7: "8(8722) 64-27-37, (8722) 67-22-56",
                col8: "https:\/\/dagmintrud.ru\/"
            },
        ], [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Республика Ингушетия",
                col3: "Министерство образования и науки Республики Ингушетия",
                col4: "ул. Московская, д. 37, г. Назрань, Республика Ингушетия, 386102",
                col5: "заместитель министра Угурчиева Пятимат Башировна",
                col6: "mori_gov@mail.ru",
                col7: "8 (8732) 22-24-57",
                col8: "https:\/\/minobr06.ru",
                col9: "Тумгоева Луиза Каримсултановна, начальник отдела воспитания и социальной реабилитации детей, +7 (8732) 22-25-53, 8732) 22-17-93 dopotdel@mail.ru"
            },
        ], [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Кабардино-Балкарская республика",
                col3: "Министерство просвещения и науки Кабардино-Балкарской Республики",
                col4: "ул. Кешокова, д. 43, г. Нальчик, Кабардино-Балкарская Республика, 360000",
                col5: " Езаов Анзор Клишбиевич\nминистр просвещения и науки\nКабардино-Балкарской Республики ",
                col6: "minobrsc@kbr.ru",
                col7: "(8662)42-04-13",
                col8: "https:\/\/edu.kbr.ru\/",
                col9: "Отдел коррекционного образования, опеки и попечительства, Хучинаева Мадина Хасанбиевна, +7(8662) 42-12-19; Дажигова Ирина Муаедовна, ведущий специалист-эксперт (8662) 420398"
            },
        ], [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Карачаево-Черкесская республика",
                col3: "Министерство труда и социального развития Карачаево-Черкесской Республики",
                col4: "369000, Карачаево-Черкесская Республика, г. Черкесск, ул. Комсомольская, 23",
                col5: "Камурзаева Динара  Магомедовна – И. о. Министра труда и социального развития Карачаево-Черкесской Республики",
                col6: "mtisr@mail.ru",
                col7: "7 (8782) 26-69-48",
                col8: "https:\/\/www.mintrudkchr.ru\/",
                col9: " deti@mintrudkchr.ru,  8-878-2- (26-44-76)"
            },
        ], [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Республика Северная Осетия - Алания",
                col3: "Министерство труда и социального развития Республики Северная Осетия - Алания",
                col4: "ул. Бутырина, д. 29, г. Владикавказ, Республика Северная Осетия - Алания, 362025",
                col5: "Мамаева Анджела Кимовна, заместитель Министра труда и социального развития Республики Северная Осетия-Алания",
                col6: "docs@minsotc.alania.gov.ru",
                col7: "(8672) 54-00-00",
                col8: "https:\/\/minsotc.alania.gov.ru\/",
                col9: "Хетагурова Нина Геннадиевна, (8672) 53 49 84, opeka@minsotc.alania.gov.ru, (8672) 53 02 98"
            },
        ], [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Чеченская республика",
                col3: "Министерство образования и науки Чеченской Республики",
                col4: "ул. им. академика М.Д. Миллионщикова, 67 «а», г. Грозный, Чеченская Республика, 364021",
                col5: "Усманов Ахмед Рамазанович, заместитель министра образования и науки Чеченской Республики",
                col6: "moin.chr@mail.ru",
                col7: "88712225184, 89280229252",
                col8: "https:\/\/mon95.ru\/",
                col9: "Гучигов Асланбек Аликович, 88712225184 dopotdel@bk.ru, opeka-moin.chr@mail.ru, 8812225184",

            },
        ], [
            {
                col1: "Северо-Кавказский федеральный округ",
                col2: "Ставропольский край ",
                col3: "Министерство образования Ставропольского края ",
                col4: "ул. Ломоносова, д. 3, г. Ставрополь, Ставропольский край, 355003",
                col5: "Зубенко Галина Серафимовна, заместитель министра образования Ставропольского края",
                col6: "info@stavminobr.ru",
                col7: "(8652) 37-23-60, (8652) 37-23-96    ",
                col8: "https:\/\/stavminobr.ru\/",
                col9: "Начальник отдела развития семейных форм устройства детей оставшихся без попечения родителей Фирсова Наталья Ивановна, (8652) 37-31-05 "
            }]
    ], [[
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Забайкальский край ",
            col3: "Министерство труда и социальной защиты населения Забайкальского края",
            col4: "ул. Курнатовского, д.7, г. Чита, Забайкальский край, 672000",
            col5: "Заместитель министра - начальник управления демографии, семьи и детства\nКастарная Анна Анатольевна",
            col6: "pochta@minsz.e-zab.ru",
            col7: "8 (3022) 35-50-85, 35-07-20",
            col8: "https:\/\/minsoc.75.ru\/",
            col9: "Кузнецов Евгений Владимирович, начальник отдела опеки и попечительства несовершеннолетних, 8(3022) 321268, opeka_deti@minsz.e-zab.ru"
        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Камчатский край",
            col3: "Министерство социального благополучия и семейной политики Камчатского края",
            col4: "ул. Советская, д. 35, г. Петропавловск-Камчатский, Камчатский край, 683000",
            col5: "Горелова Юлия Олеговна, заместитель Министра социального благополучия и семейной политики Камчатского края",
            col6: "minsrt@kamgov.ru",
            col7: "8(4152) 234-911, 42-83-64",
            col8: "https:\/\/www.kamgov.ru\/mintrud",
            col9: "Анкудинова Эленора Сергеевна, начальник отдела опеки и попечительства Министерства социального благополучия и семейной политики Камчатского края, 89146250279, +74152234911, AnkudinovaES@kamgov.ru",

        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Приморский край ",
            col3: "Министерство труда и социальной политики Приморского края",
            col4: "ул. Пушкинская, 13, г. Владивосток, 690091 ",
            col5: "Красицкая Светлана Викторовна, министр;\nБик Евгения Валерьевна, заместитель министра ",
            col6: "sodef@primorsky.ru",
            col7: "(423) 226-72-96",
            col8: "https:\/\/soctrud.primorsky.ru\/",
            col9: "Довбыш Юлия Валерьевна, начальник отдела защиты прав и интересов несовершеннолетних, (423)240-02-70, Егорова Елена Александровна, заместитель начальника отдела защиты прав и интересов несовершеннолетних, (423) 240-04-00 dovbish_yv@primorsky.ru; egorova_ea@primorsky.ru"
        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Хабаровский край ",
            col3: "Министерство социальной защиты Хабаровского края",
            col4: "680000, \nг. Хабаровск, \nул. Фрунзе, д. 67\n",
            col5: "Дорофеев Александр Олегович, министр",
            col6: "mszn@adm.khv.ru",
            col7: "8(4212) 40-25-05, (4212) 30-46-16",
            col8: "https:\/\/mszn.khabkrai.ru",
            col9: "opeka-priemnaya@khv.gov.ru; Трегубенко Виктория Владимировна, заместитель министра-начальник управления опеки и попечительства, тел (4212) 46-43-31; Заместитель начальника управления Розаленко Светлана Борисовна, тел (4212) 46-43-02"
        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Амурская область",
            col3: "Министерство социальной защиты населения Амурской области",
            col4: "ул. Шимановского, д. 8, г. Благовещенск, Амурская область, 675000",
            col5: "Пирог Ирина Викторовна - заместитель министра социальной защиты населения Амурской области",
            col6: "1000@mszn.amurobl.ru",
            col7: "8 (4162) 200-273 ",
            col8: "https:\/\/szn.amurobl.ru\/",
            col9: "Аникиенко Любовь Валериевна - начальник отдела опеки и попечительства несовершеннолетних министерства социальной защиты населения Амурской области, anikienkolv@mszn.amurobl.ru, 8 (4162) 200-280",
        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Магаданская область",
            col3: "Министерство образования Магаданской области",
            col4: "ул. Транспортная, д. 5\/23, г.Магадан, 685000",
            col5: "Шурхно Анжела Владимировна, министр образования Магаданской области",
            col6: "minobr@49gov.ru",
            col7: "8 (4132) 623221",
            col8: "https:\/\/minobr.49gov.ru\/",
            col9: "Хан Ольга Валерьевна, начальник отдела охраны прав детей министерства образования Магаданской области, 8 (4132) 200902, khanOV@49gov.ru"
        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Сахалинская область ",
            col3: "Министерство образования Сахалинской области",
            col4: "693019, Сахалинская область, г. Южно-Сахалинск, ул. Ленина, д. 156",
            col5: "Смекалов Дмитрий Григорьевич, заместитель министра – директора департамента реализации государственной политики в сфере среднего профессионального образования, дополнительного образования, воспитания и защиты прав детей",
            col6: "minobr@sakhalin.gov.ru",
            col7: "(4242) 46-59-03, (4242) 46-59-00",
            col8: "https:\/\/obrazovanie.sakhalin.gov.ru\/",
            col9: "Неретин Николай Владиславович, заместитель директора департамента - начальник отдела реализации государственной политики в сфере воспитания, дополнительного образования и защиты прав детей министерства образования Сахалинской области, (4242)46-59-75 n.neretin@sakhalin.gov.ru; (4242) 46-59-02 d.smekalov@sakhalin.gov.ru"
        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Еврейская автономная область ",
            col3: "Департамент социальной защиты населения правительства Еврейской автономной области",
            col4: "\nПроспект 60-летия СССР, д. 12 А г. Биробиджан, ЕАО, 679016\n",
            col5: "Ким Екатерина Алексеевна – заместитель начальника департамента социальной защиты населения правительства Еврейской автономной области ",
            col6: "sobes@post.eao.ru",
            col7: "8 (42622) 23472",
            col8: "https:\/\/social.eao.ru\/?ysclid=lseng7uoxl424102737",
            col9: "Меркушева Ольга Анатольевна, начальник отдела по развитию форм семейного устройства и работы с семьей департамента социальной защиты населения правительства Еврейской автономной области"
        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Чукотский автономный округ",
            col3: "Департамент социальной политики Чукотского автономного округа",
            col4: "ул. Беринга, д. 2, г. Анадырь, Чукотский автономный округ, 689000",
            col5: "Заместитель Губернатора - Начальник Департамента социальной политики Чукотского автономного округа Брянцева Любовь Николаевна",
            col6: "info@dsp.chukotka-gov.ru",
            col7: "8(42722) 6-21-00",
            col8: "https:\/\/чукотка.рф\/",
            col9: "Начальник отдела опеки и попечительства Управления социальной поддержки населения Департамента социальной политики Чукотского автономного округа Хализова Ольга Олеговна 8(42722) 6-60-63, opeka-uspn@dsp.chukotka-gov.ru"
        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Республика Бурятия",
            col3: "Министерство социальной защиты населения Республики Бурятия",
            col4: "ул. Гагарина, д. 10, г. Улан-Удэ, Республика Бурятия, 670034",
            col5: "Министр Быкова Татьяна Александровна",
            col6: "mszn@govrb.ru",
            col7: "+7 (3012) 44-19-33",
            col8: "https:\/\/egov-buryatia.ru\/minsoc\/",
            col9: "Брянская Вера Георгиевна, начальник отдела опеки и попечительства, +7 (3012) 44-75-84 mintr@mtsrrb.eastsib.ru Заместитель министра-председатель Комитета по делам семьи и детей Эрдыниева Эльвира Григорьевна, тел. 83012(45-40-27)"
        },
    ], [
        {
            col1: "Дальневосточный федеральный округ",
            col2: "Республика Саха (Якутия)",
            col3: "Министерство труда и социального развития Республики Саха (Якутия)",
            col4: "677000 Республика Саха (Якутия), г. Якутск, ул. Петра Алексеева, д.6, к.1",
            col5: "Теплякова Елена Юрьевна – заместитель министра",
            col6: "mintrud@sakha.gov.ru",
            col7: "7(4112) 508-080",
            col8: "https:\/\/mintrud.sakha.gov.ru\/",
            col9: "Петрова Лана Григорьевна – заместитель руководителя департамента 8(4112)508-058",
        },
    ]
    ], [
        [
            {
                col1: "Донецкая Народная Республика",
                col2: "Донецкая Народная Республика",
                col3: "Государственная служба по делам семьи и детей Донецкой Народной Республики ",
                col4: "Донецкая Народная Республика, 83004, г. Донецк, ул. Маршака, 2",
                col5: "Майборода Светлана Анатольевна, директор Государственной службы по делам семьи и детей Донецкой Народной Республики",
                col6: "depsemyadeti-dnr@mail.ru",
                col7: "7(856)300-23-56",
                col8: "отсутствует",
                col9: "Сидоренко Елена Геннадиевна, начальник отдела по делам детей Государственной службы по делам семьи и детей Донецкой Народной Республики, 79493053413"
            },]
    ], [[
        {
            col1: "Запорожская область",
            col2: "Запорожская область",
            col3: "Министерство труда и социальной политики Военно-гражданской администрации Запорожской области",
            col4: "Запорожская область , г. Мелитополь, пр-т Б.Хмельницкого 68А",
            col5: "Заместитель министра труда и социальной политики Военно-гражданской администрации Запорожской области Узунова Светлана Юрьевна",
            col6: "adm@mintrud.zo.gov.ru",
            col7: 79900214242,
            col8: "",
            col9: "Начальник отдела семейной политики Министерства труда исоциальной политики Военно-гражданской администрации Запорожской области Слипченко Светлана Владимировна, +79901039968, электронный адрес отдела семейной политики otdelminsocpolzp@mail.ru",

        }]
    ], [[
        {
            col1: "Луганская Народная Республика",
            col2: "Луганская Народная Республика",
            col3: "Министерство образования и науки Луганской Народной Республики",
            col4: "Луганская Народная Республика, г. Луганск, ул. Рислянда, 2а",
            col5: "Шеховцова Владлена Валерьевна, и.о. заместителя министра образования и науки Луганской Народной Республики - начальник управления по вопросам опеки и попечительства в отношении несовершеннолетних детей",
            col6: "box@edu.lpr-reg.ru",
            col7: " 8 (8572) 34-50-86",
            col8: "https://edu.lpr-reg.ru/",
            col9: "Гроза Елена Валерьевна, начальник отдела"
        }]
    ], [[
        {
            col1: "Херсонская область",
            col2: "Херсонская область",
            col3: "Министерство труда и социальной защиты Херсонской области",
            col4: "Юридический адрес: 273000, г. Херсон, ул. Потёмкинская, 13а. Фактический адрес: 275500, Херсонская обл., г. Геническ, ул. Мира, 35",
            col5: "Министр Бархатнова Алла Викторовна",
            col6: "depsoc@khogov.ru; mtsp.socobsluzivanie@khogov.ru",
            col7: "",
            col8: "",
            col9: "Профильный заместитель начальника Управления организации социального обслуживания - Мушарова Анна Александровна, +79902391971; Начальник отдела организации и контроля деятельности по делам семьи, опеки и попечительства - Попова Анна Николаевна, +79900310755"
        }
    ]]
]