export const navArrayFirst = [
    {
        "col1": "Алтайский край",
        "col2": 1,
        "col3": "Министерство строительства и жилищно-коммунального хозяйства Алтайского края",
        "col4": "Алтайский край, г. Барнаул, пр. Ленина, 7 / minstroy@alregn.ru / (3852) 566301"
    },
    {
        "col1": "Амурская область",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Амурской области",
        "col4": "675000, Амурская область,\n г. Благовещенск,\n ул. Шимановского, 8/ malkovava@mszn.amurobl.ru/\n+7(4162) 990-276"
    },
    {
        "col1": "Архангельская область",
        "col2": 1,
        "col3": "Департамент по вопросам семьи, опеки и попечительства городского округа  «Город Архангельск»",
        "col4": "163000, г. Архангельск, пл. Ленина, д. 5 Телефон: 8 (8182) 60-74-39             Факс: 8 (8182) 60-74-24,dzsp@arhcity.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 2,
        "col3": "Отдел опеки и попечительства администрации городского округа Архангельской области «Город Коряжма»",
        "col4": "165651, Архангельская область, \nг. Коряжма, ул. Ленина, д. 29,Телефон/факс: \n8 (818-50)3-03-08, 3-49-57, opeka1@koradm.ru\n\n"
    },
    {
        "col1": "Архангельская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства управления по социальным вопросам администрации городского округа Архангельской области «Котлас»",
        "col4": "165300, Архангельская область, г. Котлас, пл. Советов, д. 3,Телефон: 8(81837) 2-06-39, 2-05-57, oop50481@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 4,
        "col3": "Орган опеки и попечительства Муниципального учреждения «Управление образования и социальной сферы администрации Мирного»",
        "col4": "164170, Архангельская область, г. Мирный,     ул. Ленина, д. 33, Телефон, факс: 8(81834) 5-04-20, opekamirnyi@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 5,
        "col3": "Отдел опеки и попечительства управления социальной политики администрации городского округа Архангельской области «Город Новодвинск»",
        "col4": "164900, Архангельская область, г. Новодвинск, ул. Фронтовых бригад, д. 6, корп. 1, Телефон: 8(81852) 5-12-97, а.volkova@novadmin.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 6,
        "col3": "Отдел опеки и попечительства над несовершеннолетними Управления социального развития, опеки и попечительства городского округа Архангельской области «Северодвинск»",
        "col4": "164507, Архангельская область,г. Северодвинск, ул. Ломоносова, д. 41а,Телефон: 8(8184) 56-98-16, opekadeti@adm.severodvinsk.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства администрации Вельского муниципального района",
        "col4": "165150, Архангельская область, Вельский район, г. Вельск, ул. Гагарина, д. 37, Телефон/Факс: 8(81836) 6-40-59,opeka@velskmo.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 8,
        "col3": "Отдел опеки и попечительства Управления образования администрации Верхнетоемского муниципального округа Архангельской области ",
        "col4": "165500, Архангельская область, Верхнетоемский район, с. Верхняя Тойма, ул. Кировская, д. 6, Телефон, факс: 8(81854) 3-18-37, opekavt@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 9,
        "col3": "Отдел опеки и попечительства Управления образования и культуры администрации Вилегодского муниципального округа Архангельской области",
        "col4": "165680, Архангельская область, Вилегодский район, с. Ильинско-Подомское, ул. Советская, д. 34, Телефон, факс: 8(81843) 4-14-63, vilopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 10,
        "col3": "Отдел опеки и попечительства администрации Виноградовского муниципального округа Архангельской области",
        "col4": "164570, Архангельская область, Виноградовский район,п. Березник, ул. П. Виноградова, д. 83-А, Телефон, факс: 8(81831)      2-19-33, opeka@vmoao.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 11,
        "col3": "Отдел опеки и попечительства администрации Каргопольского муниципального округа Архангельской области",
        "col4": "164110, Архангельская область, Каргопольский район, г. Каргополь, ул. Победы, д. 12, Телефон, факс: 8(81841)2-26-30,                      2-18-45, kargopeka@mail.ru\n"
    },
    {
        "col1": "Архангельская область",
        "col2": 12,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Коношский муниципальный район»",
        "col4": "164010, Архангельская область, Коношский район, п. Коноша, ул. Советская, д. 76, Телефон, факс: 8 (81858)2-21-63, 8(81858)2-37-01, opeka.konosha@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 13,
        "col3": "Отдел опеки и попечительства Управления по социальной политике администрации Котласского муниципального округа Архангельской области",
        "col4": "165300, Архангельская область,  г. Котлас, пл. Советов, д. 9, Телефон: 8(81837) 2-17-20                     Факс: 8 (81837) 2-11-51, opekakr@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Красноборского муниципального округа Архангельской области",
        "col4": "165430, Архангельская область, Красноборский район, с. Красноборск, ул. Гагарина, д. 7-А, Телефон, факс: 8(81840) 3-15-57, oopkras@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 15,
        "col3": "Территориальный отдел по опеке и попечительству администрации муниципального образования «Ленский муниципальный район»",
        "col4": "165780, Архангельская область, Ленский район, с. Яренск, ул. Братьев Покровских, д. 19, Телефон, факс: 8(81859) 5-35-71, lenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства Управления образования администрации Лешуконского муниципального округа Архангельской области",
        "col4": "164670, Архангельская область, Лешуконский район, с. Лешуконское, ул. Красных Партизан,  д. 12, Телефон, факс: 8(81833) 3-00-13, leschopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 17,
        "col3": "Отдел опеки и попечительства управления образования администрации Мезенского муниципального округа Архангельской области",
        "col4": "164750, Архангельская область, Мезенский район, г. Мезень, пр. Советский, д. 51, Телефон, факс: 8(81848) 9-16-89, mezenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 18,
        "col3": "Отдел опеки и попечительства администрации Няндомского муниципального округа Архангельской области",
        "col4": "164200, Архангельская область, Няндомский район, г. Няндома,  ул. 60 лет Октября, д. 13, Телефон, факс: 8(818-38)6-21-68, nanopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 19,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Онежский муниципальный район»",
        "col4": "164840, Архангельская область, Онежский район, г. Онега, ул. Шаревского, д. 6, Телефон: 8 (81839) 7-03-68\nФакс: 8 (818-39) 7-04-21, opeka@onegaland.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 20,
        "col3": "Отдел по социальным вопросам, опеки и попечительства администрации Пинежского муниципального округа Архангельской области",
        "col4": "164600, Архангельская область, Пинежский район, с. Карпогоры, ул. Победы, д. 10-Б, Телефон, факс: 8 (81856)2-17-28, 2-13-11, pinopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 21,
        "col3": "Отдел опеки и попечительства администрации Плесецкого муниципального округа Архангельской области",
        "col4": "164262, Архангельская область, Плесецкий район, п. Плесецк, ул. Ленина, д. 33, Телефон: 8 (81832)7-35-94, 7-40-83     Факс: 8 (818-32) 7-28-98, oop-amoples@plesadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 22,
        "col3": "Отдел по опеке и попечительству администрации Приморского муниципального округа Архангельской области",
        "col4": "163002, г. Архангельск, пр. Ломоносова, д. 30, Телефон, факс :8(8182) 68-01-39, opeka@primadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 23,
        "col3": "Отдел опеки и попечительства администрации Устьянского муниципального округа Архангельской области",
        "col4": "165210, Архангельская область, Устьянский район, рп. Октябрьский, ул. Комсомольская,  д. 7, Телефон, факс: 8 (818-55)5-11-41, ustopekun@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 24,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних управления образования администрации Холмогорского муниципального округа Архангельской области",
        "col4": "164530, Архангельская область, Холмогорский район, с. Холмогоры, пл. Морозова, д. 10, Телефон: 8(818-30) 3-34-99, opeka-holmogory@rambler.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 25,
        "col3": "Отдел опеки и попечительства администрации Шенкурского муниципального округа Архангельской области",
        "col4": "165160, Архангельская область, Шенкурский район, г. Шенкурск, ул. Детгородок, д. 8, Телефон, факс: 8(818-51) 4-04-52, shenkopeka@mail.ru"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 1,
        "col3": "Министерство социального развития и труда Астраханской области ",
        "col4": "почтовый адрес: 414000, г. Астрахань, \nул. Бакинская, д.147; адрес электронной почты: pens@astrobl.ru; Тел. (8512) 52-49-07; 51-35-89, 51-35-92\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 1,
        "col3": "Управление социальной защиты населения администрации Белгородского района",
        "col4": " 308501, Белгородская область, Белгородский район, \nп. Дубовое, ул. Лунная, д. 4а, usznbr@mail.ru, \n(4722) 25-70-66\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 2,
        "col3": "Комиссия в сфере жилищных правоотношений администрации Борисовского района                     ",
        "col4": "309340, Белгородская область, Борисовский район, п. Борисовка,                пл.Ушакова, д. 2, \nbor-gkh@mail.ru,             (47246)5-13-36  \n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 3,
        "col3": "Жилищные комиссии по рассмотрению вопросов  по признанию граждан нуждающимися в жилых помещениях на территории городского и сельских поселений администраций Вейделевского района",
        "col4": "309720, Вейделевский район, Белгородская область, п.Вейделевка, ул.Гайдара, д. 1, admveidelevka@mail.ru\n(47237)5-52-07\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 4,
        "col3": "Администрация муниципального района «Волоконовский район»",
        "col4": "309650, Белгородская область, Волоконовский район, п. Волоконовка, ул. Ленина, д.60,\nokcvol90@yandex.ru\n(47235)5-03-31\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 5,
        "col3": "Администрация Ивнянского района",
        "col4": "309110, Белгородская область, Ивнянский район, п. Ивня, ул. Ленина, д. 20, \nfilshina_ev@iv.belregion.ru,\n(47243) 5-57-08\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 6,
        "col3": "Управление социальной защиты населения администрации Корочанского района",
        "col4": "309210, Белгородская область, Корочанский район, г. Короча, пл. Васильева, д. 13, opeka_kor@mail.ru,\n(47231)5-54-76\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 7,
        "col3": "Межведомственная комиссия при администрации муниципального района «Красненский район» ",
        "col4": "309870, Белгородская область, Красненский район, с.Красное, ул. Подгорная, д.4, (47262) 5-27-97, 5-20-88, ovchinnikova_mr@kr.belregion.ru, uksigkh@kr.belregion.ru"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 8,
        "col3": "Жилищная комиссия при администрации Красногвардейского района Белгородской области",
        "col4": "309504, Белгородская область, Красногвардейский район, г. Бирюч, пл. Соборная, д. 1,\nu_gkh@bk.ru, (47247) 3-37-44, 3-10-62\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 9,
        "col3": "Администрация муниципального района «Краснояружский район»",
        "col4": "309420, Белгородская область, Краснояружский район, п.Красная\nЯруга, ул. Центральная, д. 14, (47263) 46-9-14,  yaruga@ky.belregion.ru\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 10,
        "col3": "Комитет имущественных, земельных отношений и правового обеспечения администрации Прохоровского района",
        "col4": "Белгородская область, Прохоровский район,\nп. Прохоровка, \nул. Советская, д.162,\ngil.polit@yandex.ru,        (47242) 2-15-55\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 11,
        "col3": "Районная межведомственная жилищная Комиссия",
        "col4": "309310, Белгородская область, Ракитянский район,\nп. Ракитное, ул. Пролетарская, д.20а, (47245)5-87-05, odm31@mail.ru\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 12,
        "col3": "Комиссия по жилищным вопросам при администрации Ровеньского района",
        "col4": "309740, Белгородская область,Ровеньский район, п.Ровеньки, ул. Ленина, д.50, \narovenki@ro.belregion.ru, (47238)5-57-35\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 13,
        "col3": "Межведомственная комиссия Чернянского района по жилищным вопросам",
        "col4": "309560, Белгородская область, Чернянский район,\nп. Чернянка, пл. Октябрьская, \nд. 13, adm@ch.belregion.ru,\n(47232) 5-53-30\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 14,
        "col3": "Жилищная комиссия при администрации Алексеевского муниципального округа",
        "col4": "309850, Белгородская область, Алексеевский муниципальный округ, г. Алексеевка, пл. Победы, д.73, (47234) 3-17- 42, 3-40-76, fadeeva-64@mail.ru, ryabtseva_sb@al.belregion.ru\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 15,
        "col3": "Управление муниципальной\nсобственности и земельных\nресурсов администрации\nВалуйского муниципального\nокруга",
        "col4": "309996, Белгородская область, Валуйский муниципальный округ,\nг. Валуйки, пл. Красная, д. 1, munsobstv@va.belregion.ru,\n(47236) 3-14-33, 3-05-45"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 16,
        "col3": "Управление  по строительству, транспорту, ЖКХ и ТЭК администрации Грайворонского муниципального округа",
        "col4": "309370, Белгородская область, Грайворонский район, г. Грайворон, ул. Комсомольская, д. 21,\npolina.davydenko.71@mail.ru, (47261)4-52-96\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 17,
        "col3": "Жилищная комиссия при администрации Новооскольского муниципального округа",
        "col4": "309640, Белгородская область, Новооскольский муниципальный округ, г.Новый Оскол, ул. 1 Мая, д.4, noskol@no.belregion.ru, goncharova_ms1@no.belregion.ru, (47233) 4-61-47\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 18,
        "col3": "Комиссия по жилищным вопросам при администрации Шебекинского муниципального округа",
        "col4": "309290, Белгородская область, Шебекинский муниципальный округ, г.Шебекино, пл. Центральная, д. 2, (47248) 3-29-55, Zhil_Hoz@sh.belregion.ru"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 19,
        "col3": "Комиссия по рассмотрению вопросов признания граждан нуждающимися в\nулучшении жилищных условий и предоставления жилых помещений в\nЯковлевском муниципапьном округе",
        "col4": "309070, Белгородская область, Яковлевский муниципальный округ, г.Строитель, ул. Ленина, д.2, zhilishchnyyotdel@mail.ru\n(47244) 6-93-64\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 20,
        "col3": "Жилищное управление администрации города Белгорода\n",
        "col4": "308007, Белгородская область, г. Белгород, ул. Некрасова, д. 9/15, (4722) 26-84-92, 26-84-95, gil_otdel@mail.ru"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 21,
        "col3": "Управление жилищно-коммунального комплекса и систем жизнеобеспечения администрации Губкинского городского округа",
        "col4": "309186, Белгородская область, Губкинский городской округ,\nг. Губкин, ул. Победы, д. 3, uzkk@gu.belregion.ru,\n(47241) 5-29-90\n"
    },
    {
        "col1": "Белгородская область\n",
        "col2": 22,
        "col3": "Жилищное управление департамента жилищно-коммунального хозяйства администрации Старооскольского городского округа Белгородской области",
        "col4": "309504, Белгородская область, Старооскольский городской округ, г. Старый Оскол, ул. Комсомольская, д. 57,\n gu@so.belregion.ru, (4725) 44-52-87\n"
    },
    {
        "col1": "Брянская область",
        "col2": 1,
        "col3": "Брянская городская администрация",
        "col4": "241050, Брянская область, г. Брянск,                 пр-т Ленина, д. 35, goradm@bga32.ru,  8(4832) 66-40-44"
    },
    {
        "col1": "Брянская область",
        "col2": 2,
        "col3": "Клинцовская городская администрация",
        "col4": "243140, Брянская область, г. Клинцы,             ул. Октябрьская, д. 42, klintsi@mail.ru, 8(48336) 4-03-28"
    },
    {
        "col1": "Брянская область",
        "col2": 3,
        "col3": "Новозыбковская городская администрация",
        "col4": "243020, Брянская область, г. Новозыбков,        пл. Октябрьской Революции, д. 2, novozibkovadm@mail.ru, 8(48343)5-69-46"
    },
    {
        "col1": "Брянская область",
        "col2": 4,
        "col3": "Администрация города Сельцо ",
        "col4": "241550, Брянская область, г. Сельцо,              ул. Горького, д. 14, selco32@mail.ru, 8(4832)97-44-17"
    },
    {
        "col1": "Брянская область",
        "col2": 5,
        "col3": "Администрация Стародубского муниципального округа",
        "col4": "243240, Брянская область, г. Стародуб,           ул. Свердлова, д. 4, stadmo2008@yandex.ru, 8(48348) 2-24-22"
    },
    {
        "col1": "Брянская область",
        "col2": 6,
        "col3": "Администрация города Фокино",
        "col4": "242610, Брянская область, г. Фокино,              ул. Ленина, д. 13, g_fokino@mail.ru, 8(483333)4-79-60"
    },
    {
        "col1": "Брянская область",
        "col2": 7,
        "col3": "Администрация Брасовского муниципального района",
        "col4": "242300, Брянская область, Брасовский район,    п. Локоть, пр-т Ленина, д. 2. edds.bras@yandex.ru, 8(48354) 9-11-42"
    },
    {
        "col1": "Брянская область",
        "col2": 8,
        "col3": "Админситрация Брянского муниципального района",
        "col4": "241525, Брянская область, Брянский район,      с. Глинищево, ул. П.М. Яшенина, д. 9, admbr@mail.ru, 8(4832)94-16-70"
    },
    {
        "col1": "Брянская область",
        "col2": 9,
        "col3": "Администрация Выгоничского муниципального  района",
        "col4": "243361, Брянская область, Выгоничский район, п. Выгоничи, ул. Ленина, д. 51, adminwr@mail.ru, 8(48341)2-14-52"
    },
    {
        "col1": "Брянская область",
        "col2": 10,
        "col3": "Администрация Гордеевского муниципального  района",
        "col4": "243650, Брянская область, Гордеевский район, с. Гордеевка, ул. Победы, д. 10, admorgotdel@mail.ru, 8(48340) 2-14-46"
    },
    {
        "col1": "Брянская область",
        "col2": 11,
        "col3": "Администрация Дубровского муниципального района ",
        "col4": "242750, Брянская область, Дубровский район, п. Дубровка, ул. Победы, д. 18, dbr-orgotdel@yandex.ru, 8(48332)9-15-25"
    },
    {
        "col1": "Брянская область",
        "col2": 12,
        "col3": "Администрация Дятьковского муниципального района",
        "col4": "242600, Брянская область, Дятьковский район, г. Дятьково, д. 141-а, radmdtk@mail.ru, 8(48333) 3-22-03"
    },
    {
        "col1": "Брянская область",
        "col2": 13,
        "col3": "Администрация Жирятинского муниципального района",
        "col4": "242030, Брянская область, Жирятинский район, с. Жирятино, ул. Мира, д. 10, adm@juratino.ru, 8 (48344) 3-06-06"
    },
    {
        "col1": "Брянская область",
        "col2": 14,
        "col3": "Администрация Жуковского муниципального округа",
        "col4": "242700, Брянская область, Жуковский муниципальный округ, г. Жуковка,                 ул. Октябрьская, д. 1, adm@zh32.ru, 8 (48334) 3-26-71"
    },
    {
        "col1": "Брянская область",
        "col2": 15,
        "col3": "Администрация Злынковского муниципального района",
        "col4": "243600, Брянская область, Злынковский район, г. Злынка, пл. Свободы, д. 35, RAZLYNKA2@MAIL.RU, 8 (48358) 2-10-31"
    },
    {
        "col1": "Брянская область",
        "col2": 16,
        "col3": "Администрация Карачевского муниципального района",
        "col4": "242500, Брянская область, Карачевский район, г. Карачев, ул. Советская, д. 64, inbox@karadmin.ru, 8(48335) 2-33-91"
    },
    {
        "col1": "Брянская область",
        "col2": 17,
        "col3": "Администрация Клетнянского муниципального района",
        "col4": "242820, Брянская область, Клетнянский район, п. Клетня, ул. Ленина, д. 92, klet-2007@yandex.ru, 8(48338) 9-15-61"
    },
    {
        "col1": "Брянская область",
        "col2": 18,
        "col3": "Администрация Климовского муниципального района",
        "col4": "243040, Брянская область, Климовский район, пгт. Климово, пл. Ленина, д. 1, kladm@inbox.ru, 8 (48347) 2-13-38"
    },
    {
        "col1": "Брянская область",
        "col2": 19,
        "col3": "Администрация Клинцовского муниципального района",
        "col4": "243140, Брянская область, г. Клинцы,              ул. Октябрьская, д. 42, priymnay@mail.ru, 8(48336) 4-05-12"
    },
    {
        "col1": "Брянская область",
        "col2": 20,
        "col3": "Администрация Комаричского муниципального района",
        "col4": "242400, Брянская область, Комаричский район, п. Комаричи, ул. Советская, д. 21, adminkom@mail.ru, 8(48355) 9-15-98"
    },
    {
        "col1": "Брянская область",
        "col2": 21,
        "col3": "Администрация Красногорского муниципального района",
        "col4": "243160, Брянская область, Красногорский район, пгт. Красная Гора, ул. Первомайская,    д. 6, krgadm@yandex.ru, 8(48346) 9-17-76"
    },
    {
        "col1": "Брянская область",
        "col2": 22,
        "col3": "Администрация Мглинского муниципального района",
        "col4": "243220, Брянская область, Мглинский район,   г. Мглин, пл. Советская, д. 6, mgladm@mail.ru, 8 (48339) 2-13-45"
    },
    {
        "col1": "Брянская область",
        "col2": 23,
        "col3": "Администрация Навлинского муниципального района",
        "col4": "242130, Брянская область, Навлинский район, п. Навля, ул. Красных Партизан, д. 21, priem-nvl@yandex.ru, 8 (48342) 2-26-91"
    },
    {
        "col1": "Брянская область",
        "col2": 24,
        "col3": "Администрация Погарского муниципального района",
        "col4": "243550, Брянская область, Погарский район, пгт. Погар, ул. Ленина, д.1, admin@pogaradm.ru, 8(48342) 2-12-80"
    },
    {
        "col1": "Брянская область",
        "col2": 25,
        "col3": "Администрация Почепского муниципального района",
        "col4": "243400, Брянская область, Почепский район,    г. Почеп, пл. Октябрьская, д. 3а, orgotdel74@mail.ru, 8(48345) 3-02-52"
    },
    {
        "col1": "Брянская область",
        "col2": 26,
        "col3": "Администрация Рогнединского муниципального района",
        "col4": "242770, Брянская область, Рогнединский район, п. Рогнедино, ул. Ленина, д. 29, admrrognedino@mail.ru, 8(48331) 2-13-53"
    },
    {
        "col1": "Брянская область",
        "col2": 27,
        "col3": "Администрация Севского муниципального района",
        "col4": "242440, Брянская область, Севский район,         г. Севск, ул. Розы Люксембург, д. 50, admsevsk@mail.ru, 8(48356) 9-14-33"
    },
    {
        "col1": "Брянская область",
        "col2": 28,
        "col3": "Администрация Суземского муниципального района",
        "col4": "242190, Брянская область, Суземский район,    п. Суземка, пл. Ленина, д. 1, info@adminsuzemka.ru, 8(48353) 2-18-34"
    },
    {
        "col1": "Брянская область",
        "col2": 29,
        "col3": "Администрация Суражского муниципального района",
        "col4": "243500, Брянская область, Суражский район,   г. Сураж, ул. Ленина, д. 40, 84833021434@mail.ru, 8(48330) 2-14-34"
    },
    {
        "col1": "Брянская область",
        "col2": 30,
        "col3": "Администрация Трубчевскогоского муниципального района",
        "col4": "242221, Брянская область, Трубчевский район, г. Трубчевск, ул. Брянская, д. 59,  raisovet-trubchevsk@yandex.ru, 8(48352) 2-22-81"
    },
    {
        "col1": "Брянская область",
        "col2": 31,
        "col3": "Администрация Унечского муниципального района",
        "col4": "243300, Брянская область, Унечский район,      г. Унеча, пл. Ленина, д. 1, unradm@mail.ru, 8 (48351) 2-10-32"
    },
    {
        "col1": "Владимирская область",
        "col2": 1,
        "col3": "Министерство образования Владимирской области",
        "col4": "600000, г. Владимир, ул. Комсомольская, д. 18 (4922) 77-76-34 info@obrazovanie33.ru "
    },
    {
        "col1": "Волгоградская область",
        "col2": 1,
        "col3": "Комитет социальной защиты населения Волгоградской области",
        "col4": "400087, Волгоград, ул.Новороссийская, д.41, телефоны: (8442) 30-80-80, 30-82-13, 30-82-14, \nuszn@volganet.ru\n"
    },
    {
        "col1": "Вологодская область",
        "col2": 1,
        "col3": "Министерство строительства Вологодской области",
        "col4": "160000, г. Вологда, ул. Герцена, д. 27, rumyantsevaes@depstroy.gov35.ru, (8172) 23-00-55 (доб. 1252)"
    },
    {
        "col1": "Воронежская область",
        "col2": 1,
        "col3": "Министерство социальной защиты Воронежской области",
        "col4": "394006, Воронежская обл.,\nг. Воронеж, ул. Ворошилова, д. 14 8(473)277-18-17,\nsocial@govvrn.ru"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 1,
        "col3": "Администрация Амвросиевского муниципального округа Донецкой Народной Республики ",
        "col4": "287304, г. Амвросиевка, \nул. Ленина, д. 24, amvr.ra@mail.ru, \n+7 (856 59) 2 30 35"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 2,
        "col3": "Администрация Володарского муниципального округа Донецкой Народной Республики ",
        "col4": "287000,  пгт. Володарское, ул. Ленина, д.87,\n info-volod@volodarskoe.glavadnr.ru,\n+79490350712"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 3,
        "col3": "Администрация Волновахского муниципального округа Донецкой Народной Республики",
        "col4": "285700, г. Волноваха, \nул. Героя России Владимира Жоги, д. 1; info-voln@volnovaha.glavadnr.ru; +79496203191"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 4,
        "col3": "Администрация городского округа Горловка Донецкой Народной Республики ",
        "col4": "284646,\nг. Горловка, пр-т Победы, д. 67, admin@gorlovka.gov-dpr.ru, \n+7 (8564) 52-05-26 "
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 5,
        "col3": "Администрация городского округа Дебальцево Донецкой Народной Республики ",
        "col4": " 284701, г. Дебальцево, \nул. Ленина, 12, e-mail: gorod.debaltsevo@mail.ru, \n+7(85649) 2-48-48"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 6,
        "col3": "Администрация городского округа Докучаевск Донецкой Народной Республики ",
        "col4": "285740, г. Докучаевск, \nул. Александра Владимировича Захарченко, 22, okrugdokuchaevsk@docuch.gov-dpr.ru, \n+7(85675)3 11 41 "
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 7,
        "col3": "Администрация городского округа Донецк Донецкой Народной Республики ",
        "col4": "283050,\nг. Донецк, ул. Артема, \nд. 98, donetskoffice@yandex.ru, \n+7 (856)338-09-55, "
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 8,
        "col3": "Администрация городского округа Енакиево Донецкой Народной Республики ",
        "col4": "286430, г. Енакиево, \nпл. Ленина, 7, \nupravleniyeopekienakievo@mail.ru, +78565291483"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 9,
        "col3": "Администрация городского округа Иловайск Донецкой Народной Республики   ",
        "col4": "286793, г. Иловайск, \nул. Шевченко, д. 139;  admin@ilovaisk.gov-dpr.ru, +78565720684, +78565720691"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 10,
        "col3": "Администрация Краснолиманского муниципального округа Донецкой Народной Республики ",
        "col4": "Юридический адрес: \n284406, г. Красный Лиман, \nул. Фрунзе, д. 46, \nфактический адрес: 286430, г. Енакиево, \nул. Щербакова, д. 114,\n adm-krasniyliman@mail.ru, \n+79495594969"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 11,
        "col3": "Администрация городского округа Макеевка Донецкой Народной Республики ",
        "col4": "286157, г. Макеевка, \nплощадь Советская, д. 1, admingor@makeevka.ugletele.com, \n+78563223188"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 12,
        "col3": "Администрации городского округа Мариуполь Донецкой Народной Республики ",
        "col4": "287537, г. Мариуполь, \nул. Громовой, 63 \nadm@mariupol.gov-dpr.ru, \n+ 79495009339 "
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 13,
        "col3": "Администрация Мангушского муниципального округа Донецкой Народной Республики ",
        "col4": "287400, пгт. Мангуш, \nул. Ленина, д. 72; m.o.mangush.oopdnr@mail.ru \n+7 (949) 713-91-07"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 14,
        "col3": "Администрация муниципального образования Новоазовского муниципального округа Донецкой Народной Республики",
        "col4": "287600, г. Новоазовск, \nул. Ленина, д. 6, \nnovorosnovo@mail.ru,\n+7 (856) 963 17 05"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 15,
        "col3": "Администрация  городского округа Снежное Донецкой Народной Республики ",
        "col4": "286500, г. Снежное, ул. Ленина, д. 32 snezhnoe.opeka@yandex.ru, +79493506065"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 16,
        "col3": "Администрация Старобешевского муниципального окруна Донецкой Народной Республики",
        "col4": "287200, пгт. Старобешево, ул. Советская, 43;  starobeshevo@admstb.gov-dpr.ru;\n+78565321809"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 17,
        "col3": "Администрация Тельмановского муниципального округа Донецкой Народной Республики ",
        "col4": "287100, пгт. Тельманово, ул. Ленина, 142, adm@telmanovo.gov-dpr.ru,\n+7(856) 795 11 80"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 18,
        "col3": "Администрация городского округа Торез Донецкой Народной Республики ",
        "col4": "286600,  г. Торез, \nул. Пионерская, д. 3; torvuk@mail.ru; +7856(54)32246"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 19,
        "col3": "Администрация городского округа Харцызск Донецкой Народной Республики ",
        "col4": "286709, г. Харцызск, \nул. Краснознаменская, 87А, goradmin@khartsyz.ugletele.com, \n+7 (85657) 4 27 36, (приемная), \n+7 (85657) 4 25 46"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 20,
        "col3": "Администрация Шахтерского муниципального округа Донецкой Народной Републики ",
        "col4": "286211 г. Шахтерск,\n ул. Ленина, д. 4, е-mail: info@shahtersk.gov-dpr.ru,\n тел. (085655) 4-20-73"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 21,
        "col3": "Администрация Ясиноватского муниципального округа Донецкой Народной Республики",
        "col4": "286000, г. Ясиноватая, \nул. Орджоникидзе, д. 147, \nE-mail:  info@yasinovataya.gov-dpr.ru,\nтел. +7 (85636) 4-25-14"
    },
    {
        "col1": "Еврейская автономная область",
        "col2": 1,
        "col3": "Департамент социальной защиты населения правительства Еврейской автономной области",
        "col4": "679000, Еврейская автономная область, г. Биробиджан, проспект 60 лет СССР, д. 12А, \nsobes@post.eao.ru, \n8 (42622) 2 22 19"
    },
    {
        "col1": "Забайкальский край",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты населения Забайкальского края",
        "col4": "672000, Забайкальский край, г. Чита, ул. Курнатовского, д. 7,  pochta@minsz.e-zab.ru (только для служебной корреспонденции), 8(3022) 35 50 85 приемная"
    },
    {
        "col1": "Ивановская область",
        "col2": 1,
        "col3": "Департамент социальной защиты населения Ивановской области",
        "col4": "153012, г. Иваново, \nпер. Свободный, д. 4, \ndszn@ivreg.ru, opekadszn@ivreg.ru ,\nт. 8 (4932) 90-10-42\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 1,
        "col3": "Отдел опеки и попечительства несовершеннолетних граждан по г. Иркутску",
        "col4": "Почтовый адрес: 664056, \nг. Иркутск, ул. Академическая, д. 74,\nАдрес электронной почты: mum1.opeka@mail.ru \nНомер телефона: 8 (3952)703404;"
    },
    {
        "col1": "Иркутская область",
        "col2": 2,
        "col3": "Отдел опеки и попечительства граждан по Иркутскому району",
        "col4": "Почтовый адрес: 664056,  \nг. Иркутск, ул. Академическая, д. 74,\nАдрес электронной почты: mum1.opeka.irkrayon@mail.ru \nНомер телефона: 8(3952)703417; 8(3952)703445;"
    },
    {
        "col1": "Иркутская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства граждан по Бодайбинскому району \n\n",
        "col4": "Почтовый адрес: 666904, Иркутская область, г. Бодайбо, \nул. Октябрьская, 21А,\nАдрес электронной почты: bodaybo-opeka@mail.ru \nНомер телефона: 8(39561)51040;"
    },
    {
        "col1": "Иркутская область",
        "col2": 4,
        "col3": "Отдел опеки и попечительства граждан по Киренскому району\n",
        "col4": "Почтовый адрес: 666703, Иркутская область, г.Киренск, \nул. Галата и Леонова, д.9,\nАдрес электронной почты: kirensk-opeka@mail.ru \nНомер телефона: 8(39568)43898;"
    },
    {
        "col1": "Иркутская область",
        "col2": 5,
        "col3": "Отдел опеки и попечительства граждан по Катангскому району\n",
        "col4": " Почтовый адрес: 666611, Иркутская область, Катангский р-н., с. Ербогачен, ул. Чкалова, д. 11,\nАдрес электронной почты: katanga-opeka@mail.ru \nНомер телефона: 8(39560)21380;"
    },
    {
        "col1": "Иркутская область",
        "col2": 6,
        "col3": "Отдел опеки и попечительства граждан по \nМамско-Чуйскому району\n",
        "col4": "Почтовый адрес: 666811, Иркутская область, Мамско-Чуйский район, п. Мама, ул. Первомайская, 10,\nАдрес электронной почты: mamchui-opeka@mail.ru \nНомер телефона: 8(39569)21042;"
    },
    {
        "col1": "Иркутская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства граждан по Слюдянскому району\n",
        "col4": "Почтовый адрес: 665904, Иркутская область, г.Слюдянка, ул.Ленина, 113,\nАдрес электронной почты: sludopeka1@mail.ru \nНомер телефона: 8(39544)52731"
    },
    {
        "col1": "Иркутская область",
        "col2": 8,
        "col3": "Отдел опеки и попечительства граждан по Шелеховскому району\n",
        "col4": "Почтовый адрес: 666032, Иркутская область, \nг. Шелехов, Ул. Орловских комсомольцев, д. 44,\nАдрес электронной почты: opeka-shelehov@mail.ru \nНомер телефона: 8(39550)53772, \n89914332144;"
    },
    {
        "col1": "Иркутская область",
        "col2": 9,
        "col3": "Отдел опеки и попечительства граждан по\nКачугскому и Жигаловскому районам \n",
        "col4": "Почтовый адрес: 666203 Иркутская область, Качугский район, п. Качуг, \nул. Ленских Событий, 26,\nАдрес электронной почты: Kachug-opeka@sobes.admirk.ru, \nНомер телефона: 8(39540)32079;\nПочтовый адрес: 666402, Иркутская область, Жигаловский район, п.Жигалово, ул.Партизанская,80,\nАдрес электронной почты: zhigalovo-opeka@sobes.admirk.ru, \nНомер телефона: 8(39551)31732;"
    },
    {
        "col1": "Иркутская область",
        "col2": 10,
        "col3": "Отдел опеки и попечительства\nграждан по Эхирит-Булагатскому району\n",
        "col4": " Почтовый адрес: 669001, Иркутская область, Эхирит-Булагатский район, п. Усть-Ордынский, \nул. Ленина, 8,\nАдрес электронной почты: opeka-ehirit@mail.ru, \nНомер телефона: 8(39541)31244; "
    },
    {
        "col1": "Иркутская область",
        "col2": 11,
        "col3": "Отдел опеки и попечительства граждан по Баяндаевскому и\nОльхонскому районам",
        "col4": "Почтовый адрес: 666130, Иркутская область, Ольхонский район, с.Еланцы, ул.Бураева,6,\nАдрес электронной почты: olhon-opeka@sobes.admirk.ru, \nНомер телефона: 8(39558)53020;\nПочтовый адрес: 669120, Иркутская область, Баяндаевский район, с. Баяндай, \nул. Полевая, 12-4,\nАдрес электронной почты: bayandai-opeka@sobes.admirk.ru, \nНомер телефона: 8(39537)91131;\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 12,
        "col3": "Отдел опеки и попечительства граждан по Заларинскому, Аларскому и Нукутскому районам\n",
        "col4": "Почтовый адрес: 666322, Иркутская область, Заларинский район, п. Залари, \nул. Комсомольская, д. 18,\nАдрес электронной почты: opekazalari@mail.ru, \nНомер телефона: 8(39552)21063;\nПочтовый адрес: 669452, Иркутская область, Аларский район, п. Кутулик, \nул. Советская, д. 24,\nАдрес электронной почты: opeka_ala@mail.ru, \nНомер телефона: 8(39564)37626;\nПочтовый адрес: 669401, Иркутская область, \nп. Новонукутский, \nул. Чехова, д. 26,\nАдрес электронной почты: nukut-opeka@sobes.admirk.ru, \nНомер телефона: 8(39549)21341"
    },
    {
        "col1": "Иркутская область",
        "col2": 13,
        "col3": "Отдел опеки и попечительства граждан по Усть-Удинскому и Балаганскому районам\n",
        "col4": "Почтовый адрес: 666352, Иркутская область,  \nУсть-Удинский район, \nп. Усть-Уда, ул. Пушкина, 9,\nАдрес электронной почты: \nust-uda_opeka@mail.ru, \nНомер телефона: 8(39545)31427;\nПочтовый адрес: 666391 Иркутская область, \nп. Балаганск, ул. Юбилейная, 9,\nАдрес электронной почты: \nbalagansk-opeka@sobes.admirk.ru Номер телефона: 8(39548)50825"
    },
    {
        "col1": "Иркутская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства граждан по Боханскому и Осинскому районам\n",
        "col4": "Почтовый адрес: 669311, Иркутская область, п. Бохан, \nул. Ленина, д. 27,\nАдрес электронной почты: \nbohan-opeka@mail.ru, \nНомер телефона: 8(39538)25562;\nПочтовый адрес: 669200, Иркутская область, \nОсинский р-н., с. Оса, \nул. Котовского, 6,\nАдрес электронной почты: \nоsa-opeka@mail.ru, \nНомер телефона: 8(39539)31394"
    },
    {
        "col1": "Иркутская область",
        "col2": 15,
        "col3": "Отдел опеки и попечительства граждан по г. Черемхово, \nг. Свирску и Черемховскому району\n",
        "col4": "Почтовый адрес: 665413, Иркутская область,\n г. Черемхово, ул. Ленина, д. 18,\nАдрес электронной почты: \ncheremhovo-opeka@sobes.admirk.ru\nНомер телефона: 8(39546)50591"
    },
    {
        "col1": "Иркутская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства граждан по Ангарскому району\n",
        "col4": "Почтовый адрес: 665813, Иркутская область, г. Ангарск, \nул. Коминтерна, д. 41,\nАдрес электронной почты: \nangarsk-opeka@sobes.admirk.ru,\nНомер телефона: 8(3955)533804"
    },
    {
        "col1": "Иркутская область",
        "col2": 17,
        "col3": "Отдел опеки и попечительства граждан\nпо г. Усолье-Сибирское и Усольскому району\n\n",
        "col4": "Почтовый адрес: 665452, Иркутская область, г. Усолье-\nСибирское, ул. Коростова, д. 23,\nАдрес электронной почты: \nusole-opeka@sobes.admirk.ru,\nНомер телефона: 8(39543)67632;"
    },
    {
        "col1": "Иркутская область",
        "col2": 18,
        "col3": "Отдел опеки и попечительства граждан по г. Зиме \nи Зиминскому району\n",
        "col4": "Почтовый адрес: 665390, г. Зима, \nул. Максима Горького, 65,\nАдрес электронной почты: \nopeka5zima@mail.ru,\nНомер телефона: 8(395 54)36038"
    },
    {
        "col1": "Иркутская область",
        "col2": 19,
        "col3": "Отдел опеки и попечительства граждан по г. Саянску",
        "col4": "Почтовый адрес: 666303, Иркутская область, г. Саянск, Олимпийский мкр., д. 30,\nАдрес электронной почты: \nansay2011@mail.ru,\nНомер телефона: 8(39553)72610\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 20,
        "col3": "Отдел опеки и попечительства граждан по г. Тулуну и Тулунскому району\n",
        "col4": "Почтовый адрес: 665267,\nИркутская область, г. Тулун, \nул. Ленина, д. 30а,\nАдрес электронной почты: \n79501030366@yandex.ru,\nНомер телефона: 8(39530)21023"
    },
    {
        "col1": "Иркутская область",
        "col2": 21,
        "col3": "Отдел опеки и попечительства граждан по Куйтунскому району\n",
        "col4": "Почтовый адрес: 665302, Иркутская область, р.п. Куйтун, \nул. Бурлова, д. 9/1,\nАдрес электронной почты: \nopeka-kuitun@mail.ru,\nНомер телефона: 8(39536)52175"
    },
    {
        "col1": "Иркутская область",
        "col2": 22,
        "col3": "Отдел опеки и попечительства граждан по Тайшетскому району\n",
        "col4": "Почтовый адрес: 665003, Иркутская область, г. Тайшет, ул. Автозаводская, д.3,\nАдрес электронной почты: \numsr-taishet@yandex.ru,\nНомер телефона: 8(39563)26528"
    },
    {
        "col1": "Иркутская область",
        "col2": 23,
        "col3": "Отдел опеки и попечительства граждан по Чунскому району",
        "col4": "Почтовый адрес: 665513, Иркутская область, р.п. Чунский, \nул. Фрунзе, д.15 Б,\nАдрес электронной почты: \nopekachunskii@mail.ru,\nНомер телефона: 8(39567)20393; 8-964-210-23-83;\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 24,
        "col3": "Отдел опеки и попечительства граждан по Нижнеудинскому району\n",
        "col4": "Почтовый адрес: 665106, Иркутская область, \nг. Нижнеудинск, \nул. Советская, д. 19,\nАдрес электронной почты: \nnigneud-opeka@sobes.admirk.ru,\nНомер телефона: 8(39557)70400"
    },
    {
        "col1": "Иркутская область",
        "col2": 25,
        "col3": "Отдел опеки и попечительства граждан по г. Братску\n",
        "col4": "Почтовый адрес: 665719, Иркутская область, \nг. Братск, ул. Энгельса, д. 21,\nАдрес электронной почты: \nbratsk-opeka@sobes.admirk.ru,\nНомер телефона: 8(3953)450350"
    },
    {
        "col1": "Иркутская область",
        "col2": 26,
        "col3": "Отдел опеки и попечительства граждан по Братскому району\n",
        "col4": "Почтовый адрес: 665719, Иркутская область, \nг. Братск, ул. Энгельса, д. 21,\nАдрес электронной почты: \nbratskrai-opeka@sobes.admirk.ru,\nНомер телефона: 8(3953)409718"
    },
    {
        "col1": "Иркутская область",
        "col2": 27,
        "col3": "Отдел опеки и попечительства граждан по Казачинско-Ленскому району\n",
        "col4": " Почтовый адрес: 666505, Иркутская область, Казачинско-Ленский район, п. Магистральный, \nул. 17 съезда ВЛКСМ, д. 16,\nАдрес электронной почты: \nkaz-lensk-opeka@sobes.admirk.ru,\nНомер телефона: 8(39562)40177"
    },
    {
        "col1": "Иркутская область",
        "col2": 28,
        "col3": "Отдел опеки и попечительства граждан по \nг. Усть-Илимску и \nУсть-Илимскому району\n",
        "col4": " Почтовый адрес: 666684, Иркутская область, \nг. Усть-Илимск, \nпр. Дружбы Народов, д. 46,\nАдрес электронной почты: \nustilim-opeka@sobes.admirk.ru,\nНомер телефона: 8(39535)30090"
    },
    {
        "col1": "Иркутская область",
        "col2": 29,
        "col3": "Усть-Кутскому району",
        "col4": "Почтовый адрес: 666781, Иркутская область, \nг. Усть-Кут, \nул. Речников, д. 5/2,\nАдрес электронной почты: \nust-kut-opeka@sobes.admirk.ru,\nНомер телефона: 8(39565)57060, 89642137769\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 30,
        "col3": "Отдел опеки и попечительства граждан по Нижнеилимскому району\n",
        "col4": "Почтовый адрес: 665653, Иркутская область, Нижнеилимский район, \nг. Железногорск-Илимский, квартал 8, д. 1а,\nАдрес электронной почты: \nnigneilim-opeka@sobes.admirk.ru,\nНомер телефона: 8(39566)32331"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 1,
        "col3": "Министерство просвещения и науки Кабардино-Балкарской Республики",
        "col4": "Кабардино-Балкарская Республика, г. Нальчик,  ул. Кешокова, д. 43, 360051/minobrsc@kbr.ru, soczachita07@mail.ru/8(8662) 42-03-98"
    },
    {
        "col1": "Калининградская область",
        "col2": 1,
        "col3": "Министерство социальной политики Калининградской области",
        "col4": "236016, Калининградская обл., г. Калининград, ул. Клиническая, д. 63, social@gov39.ru, 8 (4012) 599-665, 599-699."
    },
    {
        "col1": "Калужская область",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты Калужской области ",
        "col4": "\n248016 г. Калуга, ул. Пролетарская, д. 111, mintrud.admoblkaluga.ru, 719-140, 719-176"
    },
    {
        "col1": "Камчатский край",
        "col2": 1,
        "col3": "Министерство социального благополучия и семейной политики Камчатского края",
        "col4": "683003, Камчатский край,\nг. Петропавловск-Камчатский,\nул. Ленинградская 118,\nтел. +7 (4152) 42-83-55\nЭл. почта: minsrt@kamgov.ru"
    },
    {
        "col1": "Карачаево - Черкесская Республика",
        "col2": 1,
        "col3": "Министерство труда и социального развития Карачаево-Черкесской Республики",
        "col4": "369000, КЧР, г.Черкесск, ул. Комсомольская, 23,\nmtisr@mail.ru   8 878 226 67 48"
    },
    {
        "col1": "Кемеровская область",
        "col2": 1,
        "col3": "Управление по жилищной политике администрации Анжеро-Судженского городского округа",
        "col4": "652470 г. Анжеро-Судженск, ул. Ленина, д.6, 8(38453)6-21-10, skv@anzhero.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 2,
        "col3": "Отдел по учету и распределению жилой площади администрации Беловского городского округа",
        "col4": "652600 г.Белово, ул.Советская, д.21, 8(38452)2-08-08, home3@belovo42.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 3,
        "col3": "Отдел жилищных отношений  МКУ «Центр жилищных и земельно-имущественных отношений» Березовского городского округа",
        "col4": "652425 г.Берёзовский, пр. Ленина, д. 39а, 8(38445)3-02-70, ogo-42@inbox.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 4,
        "col3": "Отдел по учету и распределению жилья МКУ \"Управление муниципальным имуществом Калтанского городского округа\"",
        "col4": "652740 г. Калтан ул. Калинина, 44, 8(38472)3-40-92, kumi-kaltan@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 5,
        "col3": "Комитет по жилищным вопросам администрации г.Кемерово",
        "col4": "650991 г. Кемерово, пр. Советский, д. 54, 8(3842)58-40-76, kgv@kemerovo.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 6,
        "col3": "Управление городского развития  Киселевского городского округа",
        "col4": "652700 г. Киселевск, ул. Унжакова, д. 8а, 8(38464)2-20-27, ugrkgo@gmail.com"
    },
    {
        "col1": "Кемеровская область",
        "col2": 7,
        "col3": "Отдел учета, распределения и приватизации муниципального жилищного фонда муниципального казенного учреждения «Комитет по жилищным вопросам» города Междуреченска",
        "col4": "652877 г. Междуреченск, ул. Кузнецкая, д. 31, 8(38475)6-19-32, privatizaciy@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 8,
        "col3": "Отдел по жилищным вопросам администрации Мысковского городского округа",
        "col4": "652840 г. Мыски, ул. Серафимовича, д. 4, 8(38474)2-19-91, myski_gil.otdel@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 9,
        "col3": "Управление по учету и приватизации жилых помещений администрации города Новокузнецка",
        "col4": "654080 г. Новокузнецк, ул. Кирова, д. 71, 8(3843)52-91-34, upr_priv_priem@admnkz.info"
    },
    {
        "col1": "Кемеровская область",
        "col2": 10,
        "col3": "Отдел по учету и распределению жилья администрации Осинниковского городского округа",
        "col4": "652811 г. Осинники, ул. Советская, д. 17, 8(38471)4-39-55, admo-gil@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 11,
        "col3": "Отдел по учету, распределению и приватизации жилья администрации Прокопьевского городского округа",
        "col4": "653000 г. Прокопьевск, ул. Артема, д. 11, 8(3846)61-20-61, flt_prkp@inbox.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 12,
        "col3": "Управление жилищным коммунальным и дорожным хозяйством администрации Тайгинского городского округа",
        "col4": "652401 г.Тайга, ул. 40 лет Октября, д. 23, 8(38448)2-17-19, uzhkidh@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 13,
        "col3": "Жилищный отдел администрации города Юрги",
        "col4": "652050 г. Юрга, пр. Победы, д. 13, 8(38451)4-69-28, gilo@yurga.org"
    },
    {
        "col1": "Кемеровская область",
        "col2": 14,
        "col3": " Комитета по управлению муниципальным имуществом администрации Беловского муниципального округа",
        "col4": "652600 г. Белово, ул. Ленина, д. 10, 8(38452)2-69-80, kumi-zh@belovo.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 15,
        "col3": "Отдел по управлению жилищной сферой администрации Гурьевского муниципального округа",
        "col4": "652780 г. Гурьевск, ул. Коммунистическая, д. 21, 8(38463)5-07-65, gur_114@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 16,
        "col3": "Отдел жизнеобеспечения администрации Ижморского муниципального округа",
        "col4": "652120 пгт. Ижморский, ул. Ленинская, д. 63, 8(38459)2-33-89, adm-ijmorka-rn@ako.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 17,
        "col3": "Управление учета и распределение жилья администрации Кемеровского муниципального округа",
        "col4": "650010 г. Кемерово, ул. Совхозная,1 а, 8(3842)75-68-95, uurj_kmr@inbox.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 18,
        "col3": "Отдел по жилищным вопросам администрации Крапивинского муниципального округа",
        "col4": "652440 пгт. Крапивинский, ул. Юбилейная, д. 15, 8(38446)22-2-56, seryapina.2011@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 19,
        "col3": "Отдел по учету, распределению, приватизации и обмену жилья администрации Ленинск -Кузнецкого городского округа",
        "col4": "652500 г. Ленинск-Кузнецкий, пр.Кирова, 56, 8(38456)3-20-50, oj.lk@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 20,
        "col3": "Жилищный отдел администрации Мариинского муниципального округа",
        "col4": "652150 г. Мариинск, ул. Ленина, д. 40, 8(38443)5-22-25, zhilotdel.mariinsk@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 21,
        "col3": "Жилищный сектор отдела капитального строительства администрации Новокузнецкого муниципального округа",
        "col4": "654041 г. Новокузнецк, ул. Сеченова, д. 25, 8(3843)32-08-16, zhilotdel2009@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 22,
        "col3": "Жилищный отдел администрации Прокопьевского муниципального округа",
        "col4": "653039 г.Прокопьевск, пр.Гагарина 1В, 8(3846)62-01-62, otdel@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 23,
        "col3": "Жилищный отдел управления по жизнеобеспечению и строительству администрации Промышленновского муниципального округа",
        "col4": "652380 пгт. Промышленная, ул. Коммунистическая, д. 23а, 8(38442)7-46-21, prom_zhilotdel@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 24,
        "col3": "Отдел  по учету и распределению жилья администрации Таштагольского муниципального района",
        "col4": "652992 г. Таштагол, ул. Ленина, д. 60, 8(38473)2-31-92, giltash_atr@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 25,
        "col3": "Отдел по правовым вопросам и жилищной политике администрации Тисульского муниципального округа",
        "col4": "652210 пгт. Тисуль, ул. Ленина, д. 53, 8(38447)2-32-45, jilotdel@tisul.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 26,
        "col3": "Комитет по муниципальному имуществу администрации Топкинского муниципального округа",
        "col4": "652300 г. Топки, ул. Луначарского, д. 26, 8(38454)4-66-02, o.8ulanova@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 27,
        "col3": "Жилищный отдел управления по жизнеобеспечению и территориальному развитию Тяжинского муниципального округа",
        "col4": "652240 пгт. Тяжинский, ул. Советская, д.2, 8(38449)2-75-58, vorosh-na12@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 28,
        "col3": "Управление жизнеобеспечения администрации Чебулинского муниципального  округа",
        "col4": "652270 пгт.Верх-Чебула, ул. Мира, д. 16, 8(38444)2-11-77, ssw2010@rambler.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 29,
        "col3": "Жилищный отдел администрации Юргинского муниципального округа",
        "col4": "652057 г. Юрга, ул. Машиностроителей, д.37, 8(38451)4-19-92, jil@yurgregion.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 30,
        "col3": "Отдел жилищно-коммунального хозяйства и строительства администрации Яйского муниципального округа",
        "col4": "652100 пгт. Яя, ул. Советская, д. 17, 8(38441)2-11-87, Adm-gkh-yaya@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 31,
        "col3": "управление градостроительства администрации Яшкинского муниципального округа»",
        "col4": "652010 пгт. Яшкино, ул. Суворова, д. 8а, 8(38455)5-16-57, ujig2017@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 1,
        "col3": "Администрация Арбажского муниципального округа",
        "col4": "ул. Пионерская, д. 2, пгт Арбаж, Кировская область, 612180, (83330) 2-12-32, admarbazh@mail.ru; (83330) 2-15-57, opeka.arbazh@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 2,
        "col3": "Администрации Афанасьевского муниципального округа Кировской области",
        "col4": "613060 Кировская область, Афанасьевский район, пгт Афанасьево, ул.Красных Партизан, д.5, afan-opeka@yandex.ru, 883331 2-19-51"
    },
    {
        "col1": "Кировская область",
        "col2": 3,
        "col3": "Администрация Белохолуницкого муниципального района",
        "col4": "613200 Кировская область г.Белая Холуница ул.Глазырина, д.6/bh_opeka@mail.ru 88336442427"
    },
    {
        "col1": "Кировская область",
        "col2": 4,
        "col3": "Администрация Богородского муниципального округа",
        "col4": "612470, Кировская область, Богородский район, пгт Богородское, ул. Советская, д. 43, 883321251, admbogo@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 5,
        "col3": "Администрация Верхнекамского муниципального округа",
        "col4": "612820 Кировская область, г. Кирс, ул. Кирова, д. 16 883339 2-33-72 imushavr@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 6,
        "col3": "Администрация Верхошижемского района",
        "col4": "613310, Кировская область, пгт Верхошижемье, ул. Комсомольская, д. 2, 89123374172, opekashishma@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 7,
        "col3": "Администрация Вятскополянского муниципального района Кировской области",
        "col4": "612964, Кировская область, г.Вятские Поляны, ул.Гагарина, д.28, admvyatp@kirovreg.ru, 89005294510"
    },
    {
        "col1": "Кировская область",
        "col2": 8,
        "col3": "Администрация муниципального образования Даровской муниципальный район Кировскойобласти",
        "col4": "612140, Кировская область, Даровской район, пгт Даровской, ул. Набережная, д. 8, 88333621906, admdaro@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 9,
        "col3": "Администрация Зуевского района Кировской области",
        "col4": "612412, Кировская область г. Зуевка, ул. Опалева, д. 65, 8 (83337) 25461, azr@zrko.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 10,
        "col3": "Администрация Кикнурского муниципального округа",
        "col4": "612300, Кировская область, Кикнурский район, пгт Кикнур, ул. Советская, д. 36, kiknur-opeka@mail.ru, (83341)5-14-79"
    },
    {
        "col1": "Кировская область",
        "col2": 11,
        "col3": "Администрация Кильмезского муниципального района",
        "col4": "613570 Кировская область, пгт Кильмезь, ул.Советская, д.79, opekakilmez2017@yandex.ru (83338)22036"
    },
    {
        "col1": "Кировская область",
        "col2": 12,
        "col3": "Администрация Кирово-Чепецкого района",
        "col4": "613040 Кировская область, ул. Перовмайская, д. 6, 8(83361)49150, mailbox@admkchr.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 13,
        "col3": "Администрация Котельничского района ",
        "col4": "612600, Кировская область, г. Котельнич, ул. Карла Маркса, д. 16, kotelnich_rayon@mail.ru 8(83342)4-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 14,
        "col3": "Администрация Куменского района",
        "col4": "613400 Кировская область, Куменский район, ул. Кирова, д.11, 8 (83343)2-12-50, adm@kumensky.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 15,
        "col3": "Администрация Лебяжского муницильного округа",
        "col4": "613500, Кировская область, Лебяжский район, пгт Лебяжье, ул.Комсомольская, д.5, leb_opeka@bk.ru, 88334420942"
    },
    {
        "col1": "Кировская область",
        "col2": 16,
        "col3": "Администрация муниципального образования Лузский муниципальный округ Кировской области",
        "col4": "613980, Кировская область, г. Луза, ул. Ленина, д. 35, admluza@kirovreg.ru, 883346-5-18-04"
    },
    {
        "col1": "Кировская область",
        "col2": 17,
        "col3": "Администрация Малмыжского района",
        "col4": "612920, Кировская область, г. Малмыж, ул. Чернышевского, д. 2а opekamalm@yandex.ru (83347) 2-28-18"
    },
    {
        "col1": "Кировская область",
        "col2": 18,
        "col3": "Администрация Мурашинского муниципального округа",
        "col4": "613711, Кировская область, г. Мураши, ул. Карла Маркса, д. 28, opeka_murashi@mail.ru, 8(83348)2-28-86"
    },
    {
        "col1": "Кировская область",
        "col2": 19,
        "col3": "Администрация Нагорского района",
        "col4": "613260 кировская область пгт. Нагорск ул.Леушина д. 21 (83349) 2-16-70 admnago@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 20,
        "col3": "Администрация Немского муниципального округа ",
        "col4": "613470 Кировская область ул. Советская д. 36 т. 8(83350)2-22-85. opeka.nema@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 21,
        "col3": "Администрация Нолинского района",
        "col4": "613440, Кировская область, г.Нолинск, ул.Спартака, д.36, opekanolinsk@mail.ru, 88336821180"
    },
    {
        "col1": "Кировская область",
        "col2": 22,
        "col3": "Администрация Омутнинского района",
        "col4": "612740, Кировская область, г. Омутнинск, ул. Комсомольская, д. 9, admomut@kirovreg.ru, 88335221251"
    },
    {
        "col1": "Кировская область",
        "col2": 23,
        "col3": "Управление образования администрации Опаринского МО",
        "col4": "613810, Кировская область, пгт. Опарино, ул. Первомайская, 14 opeka.oparino@yandex.ru (83353) 2-11-02"
    },
    {
        "col1": "Кировская область",
        "col2": 24,
        "col3": "Администрация Орловского района",
        "col4": "612270, Кировская обл, Г.Орлов, ул. Ст.Халтурина, д. 18 orlovopeka@mail.ru (83365) 2-16-45"
    },
    {
        "col1": "Кировская область",
        "col2": 25,
        "col3": "Администрация Пижанского муниципального округа ",
        "col4": "613380, Кировская область, Пижанский район, пгт. Пижанка, ул. Труда, 25 opp4325@yandex.ru 8(83355)22172"
    },
    {
        "col1": "Кировская область",
        "col2": 26,
        "col3": "Администрация Подосиновского района Кировской области",
        "col4": "613930, Кировская область, Подосиновский район, пгт Подосиновец,ул. Советская д. 77, podopeka@yandex.ru, 8(83351) 2-16-54"
    },
    {
        "col1": "Кировская область",
        "col2": 27,
        "col3": "Администрация Санчурского Муниципального округа",
        "col4": "Кировская область, Санчурский район, пгт. Санчурск, ул. Р. Люксембург, д.6А, 612370 тел. 883357 2-13-21 , admsanch@kiroveg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 28,
        "col3": "Администрация Свечинского муниципального округа",
        "col4": "612040, Кировская область, пгт Свеча, ул. Октябрьская, д.20, Тел: 8(83358)2-23-35, Эл.п.: sv_opeka@mail.ru, admsvec@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 29,
        "col3": "Администрация Слободского муниципального района",
        "col4": "613150 Кировская область г. Слободской ул. Советская д.86 тел 88336241252 admslob@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 30,
        "col3": "Администрация Советского муниципального района, ",
        "col4": "613340,Кировская область, Советский район, г. Советск, ул. Кирова,д.5 (83375)2"
    },
    {
        "col1": "Кировская область",
        "col2": 31,
        "col3": "Администрация Сунского муниципального района ",
        "col4": "612450, Кировская область, Сунский район, пгт Суна, ул.Большевиков, д.1, 8(83369)33694 sunaopeka@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 32,
        "col3": "Администрация Тужинского муниципального района",
        "col4": "612200, Кировская область, Тужинский район, пгт Тужа, ул. Горького, д. 5, ruotuzha43@yandex.ru, 8(83340)2-16-81"
    },
    {
        "col1": "Кировская область",
        "col2": 33,
        "col3": "администрация Унинского муниципальноого округа",
        "col4": "612540 Кировская область Унинский район, пгт.Уни, ул.Ленина, д.17, admunin@kirovreg.ru 8833592-14- 65"
    },
    {
        "col1": "Кировская область",
        "col2": 34,
        "col3": "Администрация Уржумского муниципального района",
        "col4": "  613530, г.Уржум, ул.Рокина, д. 13, 9536724559, ruo.opeka.@ mаil.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 35,
        "col3": "Администрация Фаленского муниципального округа",
        "col4": "612500, Кировская область, Фаленский район, пгт Фаленки, ул. Свободы,д 65, 8833322-11-30 kd81123@yandex.ru, 89536922268, opekafalenki@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 36,
        "col3": "Администрация Шабалинского муниципального района",
        "col4": " 612020 Кировская область, Шабалинский район, пгт. Ленинское, ул. Советская д. 33 . shabal_admin@mail.ru (83345) 20158"
    },
    {
        "col1": "Кировская область",
        "col2": 37,
        "col3": "администрация Юрьянского района Кировской области",
        "col4": "613600 Кировская область, Юрьянский район, пгт Юрья, ул. Ленина, 46, admjurja@kirovred.ru 883366 2-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 38,
        "col3": "Сектор опеки и попечительства администрации Яранского муниципального района ",
        "col4": "612260 Кировская область, г.Яранск,  ул.Кирова, д.10 883367-2-06-59 opeka-yaransk@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 39,
        "col3": "Администрация ЗАТО Первомайский",
        "col4": "613648,ЗАТО Первомайский ул. Волкова, 14 , admzatopervomaysk@mail.ru, 8(833266)2-42-31"
    },
    {
        "col1": "Кировская область",
        "col2": 40,
        "col3": "Администрация города Вятские Поляны",
        "col4": "612964, Кировская область, г. Вятские Поляны, ул. Гагарина 28а 8(83334) 61162 opekavp@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 41,
        "col3": "Департамент образования администраци муниципального образования городской округ \"Город Кирово-Чепецк\" Кировской области",
        "col4": "613040, Кировская область, г. Кирово-Чепецк, ул. Ленина, д. 1б, 8 (83361)5-80-50, доб. 2034, k4-opeka@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 42,
        "col3": "Управление образования города Котельнича администрации городского округа города Котельнича",
        "col4": "612600, Кировская область, г. Котельнич, ул.Ленина, 26,               \n8 (83342) 4-15-72, yokotel@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 43,
        "col3": "Администрация города Слободского",
        "col4": "613150, г. Слободской, ул. Советская, д.86, тел. 8(8332) 25-53-49, slob-adm@rambler.ru."
    },
    {
        "col1": "Кировская область",
        "col2": 44,
        "col3": "Администрация города Кирова",
        "col4": "610000, Кировская область, г. Киров, ул. Воровского, д. 39, opeka@admkirov.ru, 8(8332)54-64-12, 54-00-42, 57-94-67, 54-09-68, 54-77-48"
    },
    {
        "col1": "Кировская область",
        "col2": 45,
        "col3": "Администрация Оричевского района",
        "col4": "612080, Кировская область, Оричевский район, пгт Оричи, ул. К. Маркса, д. 12 ( 883354)2-11-75, adm@orichi-rayon.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 1,
        "col3": "Департамент по труду и социальной защите населения Костромской области",
        "col4": "156029, Свердлова ул., 129, г. Кострома, Тел. (4942) 55-90-62, socdep@kostroma.gov.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 1,
        "col3": "Министерство труда и социального развития Краснодарского края",
        "col4": "Чапаева ул., д. 58, г. Краснодар, 350000 / msrsp@krasnodar.ru / +7 (861) 259-64-60, факс (861) 259-00-97"
    },
    {
        "col1": "Красноярский край",
        "col2": 1,
        "col3": "Министерство образования Красноярского края",
        "col4": "660021, Красноярский край, г. Красноярск, ул. Карла Маркса,д.122 \nmon@krao.ru, 8 (391) 211-93-10"
    },
    {
        "col1": "Курганская область",
        "col2": 1,
        "col3": "Администрация города Кургана",
        "col4": "640000, г. Курган, пл.им. В.И. Ленина,1, тел. (3522) 42-88-01; mail@kurgan-city.ru ; 45t00902@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 2,
        "col3": "Администрация города Шадринска",
        "col4": "641884, Курганская область, г. Шадринск, ул. Свердлова, д.59, тел. (35253) 63212; public@shadrinsk-city.ru; 45t02602@kurganobl.ru 26"
    },
    {
        "col1": "Курганская область",
        "col2": 3,
        "col3": "Администрация Альменевского муниципального округа Курганской области ",
        "col4": "641130, Курганская область, с. Альменево, пл. Комсомола, 1, тел. (35242) 9-87-41; 45t00102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 4,
        "col3": "Администрация Белозерского муниципального округа Курганской области ",
        "col4": "641330, Курганская область, с.Белозерское, ул. К.Маркса, д.16, тел.(35232) 2-90-70; belozeradm@mail.ru; 45t00202@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 5,
        "col3": "Администрация Варгашинского муниципального округа Курганской области",
        "col4": "641230, область Курганская, район Варгашинский, р.п.Варгаши, улица Чкалова, д. 22, тел. (35233) 2-21-55;  45t00302@kurganobl.ru  "
    },
    {
        "col1": "Курганская область",
        "col2": 6,
        "col3": "Администрация Далматовского муниципального округа Курганской области",
        "col4": "641730,  Курганская обл., Далматовский р-он, г. Далматово, ул. Советская, 187, тел. (35252) 3-81-30; radm@dalmatovo.ru; 45t00402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 7,
        "col3": "Администрация  Звериноголовского муниципального округа Курганской области",
        "col4": "641480, Курганская область, с. Звериноголовское, ул. Чапаева, 41, тел. (35240) 2-15-05; 45t00502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 8,
        "col3": "Администрация Каргапольского муниципального округа Курганской области",
        "col4": "641920, Курганская область, р.п. Каргаполье, ул. Калинина, д.35, тел. (35256) 2-16-30;  45t00602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 9,
        "col3": "Администрация Катайского района муниципального округа Курганской области",
        "col4": "641700, Курганская область, г.Катайск, ул. Ленина, д.200, тел. (35251)21545; katadmin@mail.ru ; 45t00702@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 10,
        "col3": "Администрация Кетовского муниципального округа Курганской области",
        "col4": "641310, Курганская область, с.Кетово, ул. Космонавтов, д.39, тел. (35231) 23541; AdmKetr@mail.ru; 45t00802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 11,
        "col3": "Администрация Куртамышского муниципального округа Курганской области",
        "col4": "641430, Курганская область, г. Куртамыш, ул. XХII партсъезда, д.40, тел. (35249)2-18-10; kurtamysh-gorod@yandex.ru; 45t01002@kurganobl.ru 10"
    },
    {
        "col1": "Курганская область",
        "col2": 12,
        "col3": "Администрация Лебяжьевского муниципального округа Курганской области",
        "col4": "641500, Курганская область, р.п. Лебяжье, ул. Пушкина, д.14, тел. (35237) 9-08-78; 45t01102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 13,
        "col3": "Администрация Макушинского муниципального округа Курганской области",
        "col4": "641600, Курганская область, г. Макушино, ул. Ленина, д.85, тел. (35236) 2-06-42; 45t01202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 14,
        "col3": "Администрация Мишкинского муниципального округа Курганской области",
        "col4": "641040, Курганская область, р.п. Мишкино, ул. Ленина, д.30, тел. (35247)2-28-01; 45t01302@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 15,
        "col3": "Администрация Мокроусовского муниципального округа Курганской области",
        "col4": "641530, Курганская область, с. Мокроусово, ул. Советская, д.31, тел. (35234)9-77-41; admmokr@mail.ru; 45t01402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 16,
        "col3": "Администрация Петуховского муниципального округа Курганской области",
        "col4": "641640, Курганская область, г. Петухово, ул. К. Маркса, 27, тел. (35235) 3-84-98; admpr@bk.ru; 45t01502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 17,
        "col3": "Администрация Половинского муниципального округа Курганской области",
        "col4": "641780, Курганская область, с. Половинное, ул. Победы, д.9, тел.(35238) 9-15-33; 45t01602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 18,
        "col3": "Администрация Притобольного муниципального округа Курганской области",
        "col4": "641400, Курганская область, с. Глядянское, ул.Красноармейская, д.19, (3522) 42-89-80 доб. 224;  45t01702@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 19,
        "col3": "Администрация Сафакулевского муниципального округа Курганской области",
        "col4": "641080, Курганская область, с. Сафакулево, ул. Куйбышева, д.35; тел. (35243) 2-93-71; safakulevo@bk.ru; 45t01802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 20,
        "col3": "Администрация Целинного муниципального округа Курганской области",
        "col4": "641150, Курганская область, с. Целинное, ул. Советская, д.66, тел. (35241)2-10-43;  45t01902@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 21,
        "col3": "Администрация Частоозерского муниципального округа Курганской области ",
        "col4": "641570, Курганская область, с. Частоозерское, ул. Октябрьская, д.126, (3522) 42-84-45; 45t02002@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 22,
        "col3": "Администрация Шадринского муниципального округа Курганской области",
        "col4": "641870, Курганская область, г. Шадринск ул. Р. Люксембург, д.10, тел. (35253) 7-62-43; 45t02102@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 23,
        "col3": "Администрация Шатровского муниципального округа Курганской области",
        "col4": "641960, Курганская область, с. Шатрово, ул. Федосеева, д.53, тел. (35257) 9-13-98; 45t02202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 24,
        "col3": "Администрация Шумихинского муниципального округа Курганской области",
        "col4": "641100, Курганская область, г. Шумиха, ул. Кирова, д.12, тел. (35245)2-11-67; 45t02302@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 25,
        "col3": "Администрация Щучанского муниципального округа Курганской области",
        "col4": "641010 Курганская область, г. Щучье, ул. Победы, 1, тел. (35244) 3-69-67; 45t02402@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 26,
        "col3": "Администрация Юргамышского муниципального округа Курганской области",
        "col4": "641200 Курганская область, р.п. Юргамыш, ул. Ленина, 43, тел.(35248) 9-26-91; 45t02502@kurganobl.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 1,
        "col3": "Министерство социального социального обеспечения, материнства и детства Курской области",
        "col4": "305007, г. Курск, ул. Моковская, д. 2-г, тел.: +7 (4712) 400-300 факс +7 (4712) 35-75-23 e-mail: kco@rkursk.ru; "
    },
    {
        "col1": "Курская область\n",
        "col2": 2,
        "col3": "Администрация Беловского района Курской области",
        "col4": "307910 Курская область, Беловский р-он, сл.Белая, Советская площадь, 1, тел.: 8(47149)2-11-40, e-mail: oop.belaya@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 3,
        "col3": "Администрация Большесолдатского района Курской области ",
        "col4": "305007, г. Курск, ул. Красная площадь, д.6, подъезд 3, каб. 247; тел.: 8(4712) 51-09-89, e-mail: petina6464@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 4,
        "col3": "Отдел опеки и попечительства Администрации Глушковского района Курской области \n",
        "col4": "305000 г. Курск, ул. Ендовищенская 8а, тел.:8(4712)44-62-20, e-mail:opeka-gl.307450@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 5,
        "col3": "Администрация Горшеченского района Курской области \n",
        "col4": "306800, Курская обл., Горшеченский р-н, рп. Горшечное, ул. Кирова, д. 18, тел. 8(47133)2-13-75, e-mail: oszn_gr@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 6,
        "col3": "Управление  образования, опеки и попечительства Администрации Дмитриевского района Курской области\n",
        "col4": "307500 г. Дмитриев, ул. Ленина, д. 79, тел. 8(47150) 2-23-06, e-mail: opeka_46@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 7,
        "col3": "Администрация Железногорского района Курской области \n",
        "col4": "301170, г Железногорск, ул. Ленина, д.52, каб. 124. тел., факс 8 (47148)3-50-48, e-mail: 35048@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 8,
        "col3": "Отдел образования, опеки и попечительства Администрации Золотухинского района Курской области \n",
        "col4": "306020 Золотухинский район, Курская область, Золотухинский район, п. Золотухино, ул. пер. Лесной, д. 5, тел.: 8(47151)2-13-68, e-mail:  opekazolotuhino@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 9,
        "col3": "Муниципальное образование «Касторенский муниципальный район Курской области» \n",
        "col4": "306700, Курская область, Касторенский район, п.Касторное, ул. 50 лет октября, д.6, тел.8(47157)2-19-52, e-mail: otdelpoopeke46@.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 10,
        "col3": "Администрация Конышевского района Курской области \n",
        "col4": "307620, Курская область, п. Конышевка, ул.Ленина, д.19, тел. 8(47156)2-13-55, e-mail:opeka4609@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 11,
        "col3": "Отдел опеки и попечительства управления по образованию, опеки и попечительству администрации Кореневского района \n",
        "col4": "305044, г. Курск, ул. Союзная, 29а,\nтел.: 8(4712) 34-28-98, e-mail: korenevo-opeka@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 12,
        "col3": "Администрация Курского района Курской области ",
        "col4": "305001, г. Курск, ул. Белинского, 21, тел. 8(4712)54-66-09, otdopeki.kurskrn@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 13,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 14,
        "col3": "Администрация Льговского района Курской области \n",
        "col4": "307750, Курская область, г. Льгов, ул. Красная Площадь, д. 4 б, тел.: 8(47140)2-13-31, opeka-lgov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 15,
        "col3": "Администрация Медвенского района Курской области \n",
        "col4": "307030, Курская область, пос.Медвенка, ул.Советская, д.20, тел.: 8(47146)4-19-76, e-mail: medwenka@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 16,
        "col3": "\nАдминистрация Мантуровского района Курской области \n",
        "col4": "307000, Курская область, Мантуровский район, с. Мантурово, ул. Ленина, д.13 тел.: 8(4715) 521286,\ne-mail: opeka@smanturovo.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 17,
        "col3": "Администрация Обоянского района Курской области\n \n",
        "col4": "306230 Курская область, г. Обоянь, ул. Шмидта, д.6, 8(47141)2-22–55, e-mail oboyan-opeka@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 18,
        "col3": "Администрация Октябрьского района Курской области \n",
        "col4": "307200, Курская область,Октябрьский район, п. Прямицыно, ул. Октябрьская, 134, тел.: 8(47142) 2-13-36, 2-13-07,2-11-57, 2-16-31, e-mail:opekaoct@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 19,
        "col3": "Администрация Пристенского района Курской области \n",
        "col4": "306200, Курская область, Пристенский район, п. Пристень, ул. Ленина, дом 5, тел.: 8(47134)2-10-51 факс  e-mail: opekapristen@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 20,
        "col3": "Отдел по опеке и попечительству Администрации Поныровского района Курской области \n\n",
        "col4": "306000, Курская область, Поныровский район, п. Поныри, ул. Ленина, д. 14, \nтел.: 8(47135)2-11-07, \ne-mail: ponyri.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 21,
        "col3": "Администрация Рыльского района Курской области \n",
        "col4": "307370, Курская область, г. Рыльск, ул. К. Либкнехта, д. 23А тел. 8(47152)2-19-98, e-mail: opeka.rylsk@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 22,
        "col3": "Отдел опеки и попечительства Администрации Советского района Курской области )\n",
        "col4": "306600 Курская область, Советский район, п. Кшенский, ул. Пролетарская, д.45, тел.: 8(4712) 2-22-32, факс 8(4712)2-22-32, e-mail: opeka.sovr@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 23,
        "col3": "Администрация Солнцевского района Курской области\n",
        "col4": " 306120, Курская область, Солнцевский район, п. Солнцево, ул. Ленина, д. 44, тел.: 8(47154 2-22-36, e-mail: adm4622@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 24,
        "col3": "Управление образования Администрации Суджанского района Курской области \n",
        "col4": "305004, г. Курск, ул. Садовая, д.31,3-этаж, каб.39, тел.: 8-951-339-92-30, e-mail: sudjanskruo23@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 25,
        "col3": "Отдел по опеке и попечительству Администрации Тимского района Курской области \n",
        "col4": "307060, Курская обл., Тимский район, п. Тим, ул. Кирова 51, тел.: 8(47153)2-37-39, e-mail:opeka.admtim@rkursk.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 26,
        "col3": "Отдел по опеке и попечительству Управления образования Администрации Фатежского района Курской области \n",
        "col4": "307100, Курская область, Фатежский район, г. Фатеж, ул. Урицкого, 45, тел.: 8(47144)2-13-90, e-mail:opekafafezh89@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 27,
        "col3": "Администрация Хомутовского района Курской области \n",
        "col4": "307540, Курская область, п. Хомутовка, ул. Память Ильича, д.85, тел: 8(471-37)2-15-06, e-mail: opeka.hom@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 28,
        "col3": "Отдел по опеке и попечительству Администрации Черемисиновского района Курского района\n",
        "col4": "306440, п. Черемисиново, ул. Советская, д. 4, тел.: 8(47159)2-10-75, e-mail: opeka.tcheremisinovo@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 29,
        "col3": "Администрация Щигровского района Курской области \n",
        "col4": "306530 Курская область, г. Щигры, ул. Октябрьская, д. 35; тел.: 8 (47145) 4-16-48, e-mail: shigri-opeca@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 30,
        "col3": "\nКомитет социальной защиты населения города Курска \n",
        "col4": "305007, г. Курск, ул. Пигорева, д. 2/17, тел.: (4712)70-70-35, e-mail: komsoz@kurskadmin.ru (почта отдела komitetsoc_det@mail.ru)"
    },
    {
        "col1": "Курская область\n",
        "col2": 31,
        "col3": "Администрация города Железногорска \n",
        "col4": "307170 Курская область, г. Железногорск, ул. Ленина, д. 52, тел. 8(47148)7-64-37, e-mail: zhel.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 32,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района Курской области \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, тел.: 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 33,
        "col3": "Администрация города Льгова Курской области\n\n",
        "col4": "307750 Курская область город Льгов, ул. Красная площадь, д.13\nТел. 8(74140)2-30-13, e-mail: shigri-opeca@mail.ru, admlov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 34,
        "col3": "Отдел по опеке и попечительству администрации города Щигры",
        "col4": " 306530, Курская область, г. Щигры, ул. Спортивная,1 8(47145)4-97-27, opeka-shigry@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 1,
        "col3": "администрация Бокситогорского муниципального района Ленинградской области",
        "col4": " адрес: 187650, Ленинградская область, г. Бокситогорск, ул. Социалистическая, д.9, телефон: 8(81366) 211-31,электронная почта: adm@boksitogorsk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 2,
        "col3": "комитет образования администрации Волосовского муниципального района Ленинградской области.",
        "col4": " Адрес: 188410, Ленинградская область, г. Волосово, ул. Краснофлотская, дом 6, телефон: 8(81373)22114, электронная почта:  volosovoopeka@inbox.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 3,
        "col3": "управление по опеке и попечительству администрации Волховского муниципального района Ленинградской области",
        "col4": " адрес: 187406 Ленинградская область, г. Волхов, пр. Державина, д. 60, телефон: 8(81363)72705, электронная почта: opekavolhov@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 4,
        "col3": "комитет по опеке и попечительству администрации Всеволожского муниципального района Ленинградской области",
        "col4": "адрес: 188640, Ленинградская область, г. Всеволожск, Всеволожский проспект д. 12, телефон:  8(81370)20020, 8(81370)31741, электронная почта: vsevopeka@mail.ru, people@vsevreg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 5,
        "col3": "управление опеки и попечительства администрации  муниципального образования \"Выборгский район\" Ленинградской области",
        "col4": "адрес:188800, Ленинградская область, \nг. Выборг, ул. Красноармейская д. 16, телефон: 8(81378)26353, \n 8(81378)20078, электронная почта: opeka.vbg@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 6,
        "col3": "администрация Гатчинского муниципального округа Ленинградской области",
        "col4": " адрес: 188300, Ленинградская область, г. Гатчина, ул. Карла Маркса, д.44, телефон: 8(81371)93100; 8 (81371) 95309, электронная почта: info@gmolo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 7,
        "col3": "отдел опеки и попечительства администрации МО Кингисеппский муниципальный район» Ленинградская область",
        "col4": " адрес: 188480, Ленинградская область, г. Кингисепп, проспект Карла Маркса, д.2а, телефон: 8(81375)4-88-40, электронная почта: adm@kingisepplo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 8,
        "col3": "администрация Киришского муниципального района Ленинградской области",
        "col4": " адрес: 187110, Ленинградская область, г. Кириши, улица Советская, дом 20, телефон: 8(81368) 609-00, электронная почта: admkir@admkir.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 9,
        "col3": "администрация Кировского муниципального района Ленинградской области",
        "col4": " адрес: 187342, Ленинградская область, Кировский район, г. Кировск, ул. Новая, д. 1, телефон: 8(813-62) 28-196, электронная почта: adm_kmr@kirovsk-reg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 10,
        "col3": "сектор опеки и попечительства отдела образования Администрации Лодейнопольского муниципального района Ленинградской области",
        "col4": "адрес: 187700, 187700, Ленинградская область, г. Лодейное Поле, пр. Ленина, д.16, телефон: 8(81364)39511, 8(81364)39822, lodpole-opeka@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 11,
        "col3": "отдел по опеке и попечительству администрации Ломоносовского муниципального района Ленинградской области",
        "col4": " адрес: 198412, г. Ломоносов, ул. Владимирская, дом 19/15, телефон: 8(81242)30030, электронная почта: lmn-reg@lomonosovlo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 12,
        "col3": "администрация Лужского муниципального района Ленинградской области",
        "col4": " адрес: 188230, Ленинградская область, г. Луга, пр. Кирова, д. 73, телефон: 8(81372) 2-23-06, Электронная почта: admin@adm.luga.ru , admluga@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 13,
        "col3": "администрация Подпорожского муниципального района Ленинградской области",
        "col4": " адрес: 187780, Ленинградская область, г. Подпорожье, пр. Ленина, д. 3, телефон: 8(81365)59003, электронная почта: reception@podadm.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 14,
        "col3": "администрация Приозерского муниципального района Ленинградской области",
        "col4": " адрес: 188760, Ленинградская область,  г. Приозерск, ул. Ленина, д.10, телефон: 8(81379)36973, Электронная почта: info@admpriozersk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 15,
        "col3": "администрация Сланцевского муниципального района Ленинградской области",
        "col4": " адрес: 188560, Ленинградская область, г. Сланцы, пер. Почтовый, д. 3, телефон: 8(81374)23273, Электронная почта: slanmo@slanmo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 16,
        "col3": "администрация Сосновоборского городского округа Ленинградской области",
        "col4": " адрес: 188540, Ленинградская область, г. Сосновый Бор, ул. Ленинградская, 46, телефон: 8(81369)26222, электронная почта: admsb@sbor.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 17,
        "col3": "комитет социальной защиты населения администрации Тихвинского района  (отдел опеки и попечительства)",
        "col4": " адрес: 187553, Ленинградская область, город Тихвин, 1 микрорайон, дом 2, телефон:  8(81367) 56250, 8(81367) 70294, электронная почта opeka.tikhvin@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 18,
        "col3": "комитет образования администрации Тосненского муниципального района Ленинградской области",
        "col4": "адрес: 187000, Ленинградская область,г. Тосно, ул. Советская, д. 10а, телефон: 8(81361)28305, электронная почта Opeka1960@yandex.ru"
    },
    {
        "col1": "Липецкая область",
        "col2": 1,
        "col3": "Министерство образования Липецкой области",
        "col4": "398043, г.Липецк,             \nул. Циолковского,         д. 18\ne-mail:obluno@admlr.lipetsk.ru\n8 (4742) 32 95 13"
    },
    {
        "col1": "Луганская Народная Республика",
        "col2": 1,
        "col3": "Министерство образования и науки Луганской Народной Республики",
        "col4": "ул. Рислянда, д. 2А, г. Луганск, г.о. Луганский, Луганская Народная Республика, 291005; e-mail: box@edu.lpr-reg.ru"
    },
    {
        "col1": "Магаданская область",
        "col2": 1,
        "col3": "Департамент имущественных и жилищных отношений мэрии города Магадана",
        "col4": "г. Магадан, ул. Горького, д. 16, каб. 214\ndizho@magadangorod.ru\n62-21-08\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 2,
        "col3": "Управление муниципальным имуществом Администрации Ольского муниципального округа Магаданской области",
        "col4": "685910, Магаданская область. Ольский район, поселок Ола, площадь Ленина дом № 4, kumi-rayon.ola@mail.ru, 8(41341)25543, 25031"
    },
    {
        "col1": "Магаданская область",
        "col2": 3,
        "col3": "Отдел  опеки и попечительства\nадминистрации\nОмсукчанского\nмуниципального округа",
        "col4": "6868410, п. Омсукчан, ул. Ленина, д. 19, info@omsukchan-adm.ru, 8(41346)91681"
    },
    {
        "col1": "Магаданская область",
        "col2": 4,
        "col3": "Администрация Среднеканского муниципального округа Магаданской области (Управление экономики и развития)",
        "col4": "Магаданская область, Среднеканский район, поселок Сеймчан, ул. Ленина, дом 9. amosred@mail.ru    8-413-47-9-42-59 (приемная), 8-413-47-9-44-46 (экономика)"
    },
    {
        "col1": "Магаданская область",
        "col2": 5,
        "col3": "Отдел  опеки и попечительства управления образования\nадминистрации\nСеверо-Эвенского муниципального округа",
        "col4": "686430, Магаданская область, п. Эвенск, ул. Курилова, д. 16, 8 (413-48) 2-23-74, uoobrazevensk@mail.ru"
    },
    {
        "col1": "Магаданская область",
        "col2": 6,
        "col3": "Администрация Сусуманского муниципального округа Магаданской области",
        "col4": "686314, Магаданская область. Сусуманский район, г. Сусуман, д. 17 ssmadm@mail.ru, 8(41345)22025"
    },
    {
        "col1": "Магаданская область",
        "col2": 7,
        "col3": "Управление образования администрации Тенькинского муниципального округа Магаданской области",
        "col4": "ул. Мира, д. 11, п. Усть-Омчуг, Тенькинский район, Магаданская область, 686050,\nтел. 8(41344)3-04-44, \n E-mail: Obrazovanietenka@49gov.ru\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 8,
        "col3": "Комитет по управлению муниципальным имуществом Хасынского муниципального округа Магаданской облас",
        "col4": "Руководитель КУМИ Шинкарюк Елена Владимировна  \n686110 Магаданская область, Хасынский район, п. Палатка, ул. Ленина, д.76\nE-mail: kumi.palatka@mail.ru\n84134292504\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 9,
        "col3": "Администрация Ягоднинского муниципального округа Магаданской области",
        "col4": "686230, поселок Ягодное, Ягоднинский район, Магаданская область, улица Спортивная, дом 6,  \nе-mail: Priemnaya_yagodnoe@49gov.ru, KushenkoAA@49gov.ru\nтел. (8 413 43) 2-35-29,  \n(8 413 43) 2-20-95\n"
    },
    {
        "col1": "Москва",
        "col2": 1,
        "col3": "Департамент труда и социальной защиты населения города Москвы",
        "col4": "107078, г. Москва, Новая Басманная ул., д. 10, стр. 1, Телефон: (495) 870-44-44, E-mail: dszn@mos.ru"
    },
    {
        "col1": "Московская область",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Мурманская область",
        "col2": 1,
        "col3": "Администрация города Апатиты",
        "col4": "пл. Ленина., д. 1, г. Апатиты, Мурманская область, 184209, (8 81555)  6 02 36, opeka-deti@apatity-city.ru  "
    },
    {
        "col1": "Мурманская область",
        "col2": 2,
        "col3": "Администрация ЗАТО Александровск",
        "col4": "ул. Флотская, д. 9, ЗАТО Александровск, Мурманская область, 184682, (881539) 5 23 22, ZarenkovaLV@zato-a.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 3,
        "col3": "Администрация ЗАТО пос. Видяево",
        "col4": "ул. Центральная, д. 8, ЗАТО Видяево, Мурманская область, 184372, (881553) 5 66 74, opeka@zatovid.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 4,
        "col3": "Администрация ЗАТО город Заозерск",
        "col4": "ул. Генерала Чумаченко, д. 4,                   г. Заозерск, Мурманская область, 184310,\nopekazaozersk@mail.ru,\n8(81556) 3 15 12"
    },
    {
        "col1": "Мурманская область",
        "col2": 5,
        "col3": "Администрация Кандалакшского района",
        "col4": "ул. Первомайская, д. 34, г. Кандалакша, Мурманская область, 184040, (8 815 33) 9 24 00, 9 30 11, \nopeka-kanda@yandex.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 6,
        "col3": "Администрация города Кировска",
        "col4": "пр. Ленина, д. 16, г. Кировск, Мурманская область, 184250, (881531) 5 52 75, gnevysheva.nv@gov.kirovsk.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 7,
        "col3": "Администрация Ковдорского муниципального округа",
        "col4": "пл. Ленина., д. 1, г. Ковдор, Мурманская область, 184141, (8 81535) 5 02 42 доб. 227, i.iosifova@kovadm.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 8,
        "col3": "Администрация Кольского района",
        "col4": "Советский пр., д. 50, г. Кола, Мурманская область, 184381, (81553) 3 61 82, opeka_deti@akolr.gov-murman.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 9,
        "col3": "Администрация Ловозерского района",
        "col4": "ул. Советская, д. 10, с. Ловозеро, Мурманская область, 184592, (8 815 38) 40 111, opeca-lov@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 10,
        "col3": "Администрация города Мончегорска",
        "col4": "ул. Железнодорожников, д. 6, г. Мончегорск, Мурманская область, 184511,  (881536) 3 18 90, opd@edumonch.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 11,
        "col3": "Администрация города Мурманска",
        "col4": "ул. Комсомольская, д. 10,                         г. Мурманск, 183036, (88152) 45 06 24, 45 61 71, kio@citymurmansk.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 12,
        "col3": "Администрация муниципального округа город Оленегорск с подведомственной территорией",
        "col4": "ул. Мира, д. 38, г. Оленегорск, Мурманская область, 184530, (8 815 52) 51 000, opeka-olen@admol.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 13,
        "col3": "Администрации ЗАТО г. Островной",
        "col4": "пл. Жертв Интервенции, д. 1,                     г. Островной, Мурманская обл. 184640,  (881558) 5 00 12, admzato@zato-ostrov.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 14,
        "col3": "Администрация Печенгского муниципального округа",
        "col4": "ул. Победы, д. 1, п.г.т. Никель, Печенгский район, Мурманская область, 184421, \nтелефон (81554) 5-19-40, 5-05-72;  obrpechenga@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 15,
        "col3": "Администрация города Полярные Зори",
        "col4": "ул. Ломоносова, д. 4, г. Полярные Зори, Мурманская область, 184230, (881532) 7 12 24, opeka@pz-city.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 16,
        "col3": "Администрация ЗАТО г. Североморск ",
        "col4": "пр. Советский, д. 50, г. Кола, Мурманская область, 184381, (8 81553)  3 61 82, 3 34 29, opeka-deti@akolr.gov-murman.ru   "
    },
    {
        "col1": "Мурманская область",
        "col2": 17,
        "col3": "Администрация Терского района",
        "col4": "пгт. Умба Терского района, Мурманская область, 184703, (8 81555)  6-71-12, ter.obrazovanie@mail.ru "
    },
    {
        "col1": "Ненецкий автономный округ",
        "col2": 1,
        "col3": "Департамент здравоохранения, труда и социальной защиты населения Ненецкого автономного округа",
        "col4": "ул. Смидовича, д.25, г. Нарьян-Мар, Ненецкий автономный округ, 166000; e-mail: m, тел. 8(81853) 2-13-68edsoc@adm-nao.ru"
    },
    {
        "col1": "Нижегородская область",
        "col2": 1,
        "col3": "Администрация Ардатовского муниципального округа",
        "col4": " \nул. Ленина, д.28, р.п. Ардатов, 607130 arono@mts-nn.ru        8(83179)5-02-56        \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 2,
        "col3": "Администрация Балахнинского муниципального округа",
        "col4": " ул. Лесопильная, дом 24, г. Балахна, 606403, opeka@adm.bal.nnov.ru 8(83144)68299 "
    },
    {
            "col1": "Нижегородская область",
        "col2": 3,
        "col3": "Администрация Богородского муниципального округа ",
        "col4": " ул. Ленина, д. 206, г. Богородск, 607600, opekabog@yandex.ru 8(83170)23871        "
    },
    {
        "col1": "Нижегородская область",
        "col2": 4,
        "col3": "Администрация Большеболдинского муниципального округа ",
        "col4": " ул. Пушкинская, д.144, с. Б.Болдино, 607940, opekaboldino@yandex.ru 8(83138)2-26-21"
    },
    {
        "col1": "Нижегородская область",
        "col2": 5,
        "col3": "Администрация Большемурашкинского муниципального округа",
        "col4": "ул. Свободы, д. 86, р.п. Б.Мурашкино, 606360 detimolkdn@mail.ru 8(83167)5-11-32"
    },
    {
        "col1": "Нижегородская область",
        "col2": 6,
        "col3": "Администрация городского округа г. Бор",
        "col4": " ул. Ленина, д. 97, г. Бор, Нижегородская область, 606440, opekabor@yandex.ru 8(831)5991478"
    },
    {
        "col1": "Нижегородская область",
        "col2": 7,
        "col3": "Администрация Бутурлинского муниципального округа",
        "col4": " ул. Ленина, д. 106, п.г.т. Бутурлино, 607440, opeka-buturlino@mail.ru 8(831)7251813"
    },
    {
        "col1": "Нижегородская область",
        "col2": 8,
        "col3": "Администрация\nВадского муниципального округа",
        "col4": " ул. 1 Мая, д. 41, с. Вад, 606380, opeka-vad@yandex.ru 8(831)4043042\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 9,
        "col3": "Администрация Варнавинского муниципального округа ",
        "col4": "   пл. Советская, д.2, р.п. Варнавино, 606760, ruo_varnavino@mail.ru        8 (831) 583 52 44      "
    },
    {
        "col1": "Нижегородская область",
        "col2": 10,
        "col3": "Администрации Вачского муниципального округа",
        "col4": " ул.Советская, дом 26б, р.п. Вача, 606150, vachaopeka52@yandex.ru 8(831)7361977"
    },
    {
        "col1": "Нижегородская область",
        "col2": 11,
        "col3": "Администрация Ветлужского муниципального округа ",
        "col4": " ул.Ленина, дом 42, г.Ветлуга, 606860, vetl-opeka@mail.ru 8(83150)235-04"
    },
    {
        "col1": "Нижегородская область",
        "col2": 12,
        "col3": "Администрация Вознесенского муниципального округа",
        "col4": " ул. Советская, д. 33, р.п. Вознесенское, 607340, paramonova-opeka@yandex.ru 8(83178)631-88 "
    },
    {
        "col1": "Нижегородская область",
        "col2": 13,
        "col3": "Администрация Володарского муниципального округа",
        "col4": " \nул. Клубная, дом 4, г. Володарск, 606070, opeka-volodarsk@mail.ru 8(831)3649202\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 14,
        "col3": "Администрация городского округа Воротынский",
        "col4": " пл. Советская, дом 6, р.п. Воротынец, 606260 opeka@adm.vrt.nnov.ru 8(831)6420151"
    },
    {
        "col1": "Нижегородская область",
        "col2": 15,
        "col3": "Администрация Воскресенского муниципального округа",
        "col4": " \nул. Ленина, д.105, р.п. Воскресенское, 606730 opeka-vosk@yandex.ru 8(83163)922-85\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 16,
        "col3": "Администрация городского округа г. Выкса ",
        "col4": "\nг.Выкса, Красная площадь, дом 1, 607060, uo@vyksa-okrug.ru 8(831)7734244 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 17,
        "col3": "Администрация Гагинского муниципального округа ",
        "col4": "\nул. Ленина, д. 44, с. Гагино, 607870, opekagag@yandex.ru 8(831)9521472\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 18,
        "col3": "Администрация Городецкого муниципального округа",
        "col4": "\nпл. Пролетарская, д. 30, г. Городец, 606500, gorodec_opeka@mail.ru 8(8316) 9-1766  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 19,
        "col3": "Администрация Дальнеконстантиновского муниципального округа",
        "col4": "\nул. Советская, д. 103, р.п. Дальнее Константиново, 606310, opekadk@yandex.ru \n8(831)6852218"
    },
    {
        "col1": "Нижегородская область",
        "col2": 20,
        "col3": "Администрация Дивеевского муниципального округа ",
        "col4": "\nул.Октябрьская, д.10, с.Дивеево, 607320, roo.adm.diveevo@mail.ru 8(83134)45460\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 21,
        "col3": "Администрация Княгининского муниципального округа",
        "col4": "\nул.Свободы, д.45, г. Княгинино, 606340, rukavisnikova.tatyana@mail.ru 8(831)6641046\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 22,
        "col3": "Администрация Ковернинского муниципального округа",
        "col4": "\nул. Карла Маркса, д. 4, р.п. Ковернино, 606570, roo@adm.kvr.nnov.ru 8(831)5722568\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 23,
        "col3": "Администрация Краснобаковского муниципального округа",
        "col4": " \nул. Свободы, д. 104, р.п. Красные Баки, 606710, opeka-krbaki@mailru 8(831)5622531\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 24,
        "col3": "Администрация Краснооктябрьского муниципального округа",
        "col4": " \nул. Кооперативная, дом 39, село Уразовка, 607530, obkroktyabr@mail.ru  8(831) 265 38 13 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 25,
        "col3": "Администрация Кстовского района",
        "col4": "\nпл. Ленина, дом 4, г. Кстово, 607650, opeka.kctadm@mail.ru 8(831)4539214\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 26,
        "col3": "Администрация городского округа г. Кулебаки",
        "col4": "\nул. Воровского, д. 43а, г. Кулебаки, 607010, opeka_kulebaki@bk.ru 8(831)7653062\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 27,
        "col3": "Администрация Лукояновского муниципального округа",
        "col4": " \nул. Коммуны, д. 38, г. Лукоянов, Нижегородской области, 607800, luk-opeka@yandex.ru 8(831)9642153\n\n\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 28,
        "col3": "Администрация Лысковского муниципального округа ",
        "col4": "\nул. Ленина, д. 23, г. Лысково, 606210, lysopeka@mail.ru 8(831)495-38-83   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 29,
        "col3": "Администрация городского округа Навашинский",
        "col4": "  \nул. 1 Мая, д. 6, г. Навашино, 607102, opekanavashino@yandex.ru 8(831)7557367\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 30,
        "col3": "Администрация Павловского муниципального округа ",
        "col4": "\nул.Ленина, д.27, г.Павлово, 606100, detiopekapavlovo@mail.ru 8(831)7121595\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 31,
        "col3": "Администрация городского округа г. Первомайск",
        "col4": "\nул. Ульянова, дом 2, г. Первомайск, 607760, perv_rono@mail.ru 8(831)3921472\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 32,
        "col3": "Администрация городского округа Перевозский",
        "col4": "\nпр. Советский, д. 8, г.Перевоз, 607400, opekapvz@yandex.ru 8(831)485-28-41   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 33,
        "col3": "Администрация Пильнинского муниципального округа ",
        "col4": "\nул. Урицкого, д. 16, р.п. Пильна, 607490, uomps@mail.ru 8(831)9251539  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 34,
        "col3": "Администрации Починковского муниципального округа",
        "col4": " \nпл.Ленина, д.9, с.Починки, 607910, karpaeva.lena@mail.ru 8(831)9750043\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 35,
        "col3": "Администрация городского округа Семеновский",
        "col4": "\nул.1-е Мая, д.1, г. Семенов, 606650, kdn@semenov.nnov.ru 8(83162)5-27-97\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 36,
        "col3": "Администрация\nСергачского муниципального округа",
        "col4": " \nул. Советская, дом 30, г. Сергач, 607510, opeka-grishina@mail.ru 8(831)9152911\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 37,
        "col3": "Администрация Сеченовского муниципального округа ",
        "col4": "\nпл. Советская, д. 4, с. Сеченово, 607580,  komolova-anna@mail.ru 8(831)93-5-24-08\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 38,
        "col3": "Администрация городского округа Сокольский",
        "col4": "\nул. Набережная, д. 9, п. Сокольское, 606670, detstvo.sokolskoe@mail.ru 8(831)372-07-73 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 39,
        "col3": "Администрация Сосновского муниципального округа",
        "col4": " \nул. Ленина, д. 27, р.п. Сосновское, д.27, 606170, opekasosn@yandex.ru 8(83174) 2-63-27\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 40,
        "col3": "Администрация Спасского муниципального округа",
        "col4": "\nпл. Революции, д. 71, с. Спасское, 606280, spasopeka@yandex.ru 8(831)652-60-15\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 41,
        "col3": "Администрация Тонкинского муниципального округа ",
        "col4": " \nул. Ленина, д.1, р.п. Тонкино, 606970, roo_tonkino2002@mail.ru   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 42,
        "col3": "Администрация Тоншаевского муниципального округа",
        "col4": " \nул. Свердлова, дом 2а, р.п. Тоншаево, 606950, ton_opeka@rambler.ru 8(831)512-22-95\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 43,
        "col3": "Администрация Уренского муниципального округа",
        "col4": " \nул. Советская, д. 26, г. Урень, 606800, uren.ruo@mail.ru    8(831)542-11-63\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 44,
        "col3": "Администрации городского округа г. Чкаловск",
        "col4": "\nпл. Комсомольская, д.2,    г. Чкаловск, 606540,  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 45,
        "col3": "Администрация\nШарангского муниципального округа",
        "col4": " \nул. Свободы, дом 2, пгт. Шаранга, 606840, roo_shar2003@mail.ru 8(831)5521763           \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 46,
        "col3": "Администрация\nШатковского муниципального округа",
        "col4": " \nул. Федеративная, д.4, р.п. Шатки, 607700, opeka0803@mail.ru 8(831)904-31-34\n \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 47,
        "col3": "Администрация городского округа г. Шахунья Нижегородской области ",
        "col4": "\nпл. Советская, д. 1, г. Шахунья, 606910, opekashah.52@mail.ru 8(831)5226655 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 48,
        "col3": "Администрация городского округа г. Арзамас",
        "col4": " \nул. Советская, дом 10, г. Арзамас 607220, arzopeka@rambler.ru 8(831)4775738 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 49,
        "col3": "Администрация г. Дзержинска",
        "col4": "\nпл. Ленина, д. 5, г. Дзержинск, 606032, opeka-dz@mail.ru 8(831)3397212\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 50,
        "col3": "Администрации г. Саров ",
        "col4": "\nул. Гагарина, дом 6, г. Саров, 607188,nud@adm-sarov.ru 8(831)3099124\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 51,
        "col3": "Департамент образования администрации города Нижнего Новгорода (Отдел опеки и попечительства)",
        "col4": "\nул. Большая Покровская, д.15, г. Н.Новгород, 603005, muhanova@admgor.nnov.ru 8(831)435-69-83  \n"
    },
    {
        "col1": "Новгородская область",
        "col2": 1,
        "col3": "Министерство образования Новгородской области",
        "col4": "г.Великий Новгород, ул.Новолучанская, д.27, 173001 , 8(8162)501070, адрес электронной почты: depobr@edu53.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 1,
        "col3": "администрация Баганского района Новосибирской области отдел опеки и попечительства ",
        "col4": "Новосибирская область, Баганский район, с. Баган,. ул.М. Горького д.21                                   8-383-53-21-967"
    },
    {
        "col1": "Новосибирская область",
        "col2": 2,
        "col3": "администрация Барабинского района Новосибирской области",
        "col4": "632334, Новосибирская область, г. Барабинск, ул. Ульяновская, 66; эл. почта: glv_admbar@nso.ru; тел.:  8-383-61-258-08"
    },
    {
        "col1": "Новосибирская область",
        "col2": 3,
        "col3": "администрация Болотнинского района Новосибирской области",
        "col4": "633340, Новосибирская область, Болотнинский район, город Болотное, улица Советская, 9, тел. 8-383-49-22-730, 8-383-49-22-136, bolotnoe-adm@yandex.ru, maer_en@nso.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 4,
        "col3": "Администрация Венгеровского района Новосибирской области",
        "col4": "632241, Новосибирская область, Венгеровский район, с.Венгерово, ул.Ленина, д.68/       pradmvengr@yandex.ru/           (383 69) 21-592"
    },
    {
        "col1": "Новосибирская область",
        "col2": 5,
        "col3": "Администрация Доволенского района Новосибирской области",
        "col4": "8-383-54-20-508, opeka-dovol@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 6,
        "col3": "администрация Здвинского района Новосибирской области",
        "col4": "632951, Новосибирская область, Здвинский район, с. Здвинск, ул. Мира, д.13, admn-zdv@yandex.ru,    8 383 63 21 278"
    },
    {
        "col1": "Новосибирская область",
        "col2": 7,
        "col3": " администрация Искитимского района Новосибимрской области",
        "col4": "633209, Новосибирская область, г. Искитим, ул. Пушкина, д. 57а, эл. почта : opeka_29@mail.ru? тел. 8 (38343) 4-22-60"
    },
    {
        "col1": "Новосибирская область",
        "col2": 8,
        "col3": "администрация Карасукского муниципального округа Новосибирской области",
        "col4": "632868, НСО, г. Карасук, ул. Октябрьская, дом 39, E-mail: radm-karasuk@nso.ru. Тел. 8(383)55 33-135, 8(383)55 32-256"
    },
    {
        "col1": "Новосибирская область",
        "col2": 9,
        "col3": "администрация Каргатского района Новосибирской области",
        "col4": "632402 Новосибирская область, Каргатский район,город Каргат, улица Советская, дом 122 тел.83836523326, электронная почта - anyusheva_mv@nso.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 10,
        "col3": "Администрация Колыванского района Новосибирской области ",
        "col4": "633161, Новосибирская область, Колыванский район, р.п. Колывань, ул. Ленина, д. 79, тел. 8 383 52 51 284,        эл. почта koluvan-adm@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 11,
        "col3": "администрация Коченевского района",
        "col4": "632640, Новосибирская область, р.п. Коченево,          ул. Октябрьская, 43/kochadm@nso.ru/            8(383) 5125406"
    },
    {
        "col1": "Новосибирская область",
        "col2": 12,
        "col3": "Администрация Кочковского района Новосибирской области",
        "col4": "632491, Новосибирская область, Кочковский район, с. Кочки, ул. Революционная, 11, admkck@mail.ru, kochkiopeka@mail.ru, 8(383)56-22-300"
    },
    {
        "col1": "Новосибирская область",
        "col2": 13,
        "col3": "админитрация Краснозерского района",
        "col4": "Новосибирская область, р.п.Краснозерское, ул.Чкалова 5.632902. 83835742398"
    },
    {
        "col1": "Новосибирская область",
        "col2": 14,
        "col3": " администрации Куйбышевского муниципального района Новосибирской области",
        "col4": "632380,г. Куйбышев, ул. Куйбышева, 12             Тел.8(383 62)51-462          e-mai:   kainsk_opeka@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 15,
        "col3": "Администрация Купинского района Новосибирской области",
        "col4": "632735, Новосибирская область, Купинский район, г.Купино, ул. Железнодорожная, 42-а ( отдел опеки и попечительства) Тел: 8-383-58-20-997; opekakup@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 16,
        "col3": "  администрация Кыштовского района Новосибирской области",
        "col4": "632270 ,Новосибирская область,Кыштовский район,с.Кыштовка,ул.Ленина,д.38\\opeka-kyshtovka@yandex.ru\\83837121 174"
    },
    {
        "col1": "Новосибирская область",
        "col2": 17,
        "col3": "администрация Маслянинского муниципального округа Новосибирской области",
        "col4": "633564, Новосибирская область, Маслянинский район, р.п. Маслянино, улица Партизанская, дом 1, кабинеты 18, 22. 8-383-47-23-231, 23-604, 21-376.   opekamas@yandex.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 18,
        "col3": "Администрация Мошковского района Новосибирской области",
        "col4": "633131, Новосибирская область, Мошковский район, р.п. Мошково, ул. Советская д. 9                                                                тел. 838321266, электронная почта: oopadm@rambler.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 19,
        "col3": "Администрация Новосибирского района Новосибирской области",
        "col4": "г.Новосибирск, ул.Фабричная, д.55, 6 этаж, оф.602, 20opeka13@mail.ru, 373-45-87"
    },
    {
        "col1": "Новосибирская область",
        "col2": 20,
        "col3": "Администрация          Ордынского района Новосибирской области",
        "col4": "633261, Новосибирская область, Ордынский район, р.п. Ордынское, пр. Революции, 17 /ordopeka@mail.ru/8-383-59-23-673"
    },
    {
        "col1": "Новосибирская область",
        "col2": 21,
        "col3": "Администрация Северного района Новосибирской области",
        "col4": "632080, Новосибирская обл., Северный р-н, с. Северное, ул Ленина, д.14 тел: 8(38360)21818 sevadmn@yandex.ru  "
    },
    {
        "col1": "Новосибирская область",
        "col2": 22,
        "col3": "администрация Сузунского района Новосибирской области",
        "col4": "Новосибирская область, Сузунский район, р.п. Сузун, ул. Ленина, 51                           adm@suzunadm.ru              (38346)22550"
    },
    {
        "col1": "Новосибирская область",
        "col2": 23,
        "col3": "администрация Татарского муниципального округа Новосибирской области",
        "col4": "632122, Новосибирская область, г. Татарск, ул. Ленина, 56, opekatat@mail.ru, 89383 64) 2-62-54"
    },
    {
        "col1": "Новосибирская область",
        "col2": 24,
        "col3": "администрация Тогучинского района Новосибирской области",
        "col4": "633456, Новосибирская область, г. Тогучин, ул. Садовая, д.9б/ togopeka@mail.ru/8383 40 24886"
    },
    {
        "col1": "Новосибирская область",
        "col2": 25,
        "col3": "администрация Убинского района Новосибирской области",
        "col4": "632520 Новосибирская область, Убинский район, с. Убинское, ул. Ленина, 23 тел. 8 (38366) 21-527              8 (38366) 21-131  ubinadm@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 26,
        "col3": "администрация Усть-Таркского района Новосибирской области",
        "col4": "632160, Новосибирская область, Усть-Таркский район, с. Усть-Тарка, ул. Иванова, 7;   ustopeka@nso.ru/83837223574 (ООиП)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 27,
        "col3": "Администрация Чановского района Новосибирской области",
        "col4": "632201, НСО,р.п.Чаны, ул.Советская, д.118\nт.21-185, факс 21-657\nchany-adm@mail.ru\n\n"
    },
    {
        "col1": "Новосибирская область",
        "col2": 28,
        "col3": "Администрация Черепановского района Новосибирской области",
        "col4": "633520, Новосибирская область, Черепановский район,г.Черепаново, ул.Партизанская, 12, Email:orgotd@nso.ru, т.8(383 45)21368,                      8(383 45)22206,  8(383 45)24538"
    },
    {
        "col1": "Новосибирская область",
        "col2": 29,
        "col3": "Администрация Чистоозерного района \nНовосибирской области ",
        "col4": "632720, Новосибирская область,\nр.п. Чистоозерное, ул. Покрышкина, 11\ngln68@nso.ru, opekachst@yandex.ru\n8 383 68 91 735   "
    },
    {
        "col1": "Новосибирская область",
        "col2": 30,
        "col3": "администрация Чулымского района Новосибирской области",
        "col4": "632551,Новосибирская область, Чулымский район, г. Чулым, ул. Чулымская, 43 (chladm@nso.ru. Тел.83835021656)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 31,
        "col3": "Админитсрация города Бердска",
        "col4": "633010, Новосибирская область, г.Бердск, ул. М.Горького, 7 oopberdsk@nso.ru  8(38341)29297"
    },
    {
        "col1": "Новосибирская область",
        "col2": 32,
        "col3": "Администрация города Искитима Новосибирской области",
        "col4": "\n633209, Новосибирская область, город Искитим, ул.Пушкина, 51.\npri_iskadm@mail.ru  83834324566\n"
    },
    {
        "col1": "Новосибирская область",
        "col2": 33,
        "col3": "Администрация рабочего поселка Кольцово",
        "col4": "рабочий поселок Кольцово, проспект Никольский, \n здание 1, офис 400\nНовосибирская область, 630559\nтелефон: 336-15-93, \nE-mail: ooip@kolcovo.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 34,
        "col3": "Администрация города Оби Новосибирской области",
        "col4": "633102, Новосибирская область, ул. Авиационная, д.12, т. 8(38373)56110, email: adm_nso@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 35,
        "col3": "Администрация Кировского района",
        "col4": "г. Новосибирск, ул. Петухова, 18  8 (383)227-49-36   EASafronova@admnsk.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 36,
        "col3": "Администрация Советского района",
        "col4": "г. Новосибирск, ул. Академика Лаврентьева, 14, каб. 315 (383) 228-86-90 OKasatkina@admnsk.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 37,
        "col3": "Администрация Калининского района",
        "col4": " г. Новосибирск ул. Богдана Хмельницкого, 14/3   8(383)2287379  IBorodina@admnsk.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 38,
        "col3": "Администрация Октябрьского района",
        "col4": "г. Новосибирск, ул. Сакко и Ванцетти, д. 33  8(383)228-82-19    NKonysheva@admnsk.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 39,
        "col3": "Администрация Первомайского района",
        "col4": "ул. Физкультурная, 7, г. Новосибирск, 630046   8(383)338-35-56   tkarzova@admnsk.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 40,
        "col3": "Администрация Дзержинского района",
        "col4": "г. Новосибирск, пр. Дзержинского, 16  8(383)227-57-52  EKasatova@admnsk.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 41,
        "col3": "Администрация Ленинского района",
        "col4": "г. Новосибирск, ул. Станиславского, 6 а   8 (383)2288412   OSaparmuradova@admnsk.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 42,
        "col3": "Администрация Центрального округа по Железнодорожному, Заельцовскому и Центральному районам города Новосибирска",
        "col4": "г. Новосибирск ул. Ленина, 57   8 (383) 227- 59-00 centrokrug@admnsk.ru"
    },
    {
        "col1": "Омская область\n",
        "col2": 1,
        "col3": "Министерство образования Омской области",
        "col4": "Красный Путь ул., д. 5,\n г. Омск, Россия, 644002 \n E–mail: educate@omskportal.ru\n Тел. (3812) 357–000(доб. 27–04)"
    },
    {
        "col1": "Оренбургская область",
        "col2": 1,
        "col3": "Министерство образования Оренбургской области",
        "col4": "460000, г. Оренбург, ул. Володарского, дом 11, \n8 (3532) 500-840 (доб.762), unt@mail.orb.ru"
    },
    {
        "col1": "Оренбургская область",
        "col2": 2,
        "col3": "Абдулинский муниципальный округ:",
        "col4": " Оренбургская область, г. Абдулино, ул. Коммунистическая, 276, 8 (35355) 2-52-47, ab@mail.orb.ru. "
    },
    {
        "col1": "Оренбургская область",
        "col2": 3,
        "col3": "город Бугуруслан: ",
        "col4": "Оренбургская обл., г. Бугуруслан, ул. Ленинградская, 47, 8 (35352) 3-33-69, bg@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 4,
        "col3": "город Бузулук: ",
        "col4": "Оренбургская область, г. Бузулук, ул.Ленина, 10, 8 (35342) 3-51-35, adm@buzuluk-town.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 5,
        "col3": " Гайский муниципальный округ: ",
        "col4": "Оренбургская область, г. Гай, ул. Ленина, 41, 8 (35362) 4-20-33. goradm@esoo.ru, gy@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 6,
        "col3": " Кувандыкский муниципальный округ",
        "col4": " Оренбургская область, г. Кувандык, ул. Оренбургская, 20, 8 (35361) 2-37-13, 2-22-20, ku@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 7,
        "col3": " город Медногорск:",
        "col4": " Оренбургская область, г. Медногорск, ул. Советская, 37, 8 (35379) 3-26-86, mo@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 8,
        "col3": "город Новотроицк: ",
        "col4": "Оренбургская область, г. Новотроицк, ул. Советская, 80, 8 (35376) 2-01-01, adm-nvk@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 9,
        "col3": " город Оренбург: ",
        "col4": "Оренбургская область, г. Оренбург, ул.Советская, 60, 8 (3532) 98-70-10, general@admin.orenburg.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 10,
        "col3": "город Орск:",
        "col4": " Оренбургская область, г. Орск, проспект Ленина, 29, 8 (3537) 25-30-90, uprava@orsk-adm.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 11,
        "col3": "Соль-Илецкий муниципальный округ",
        "col4": ": Оренбургская область, г.Соль-Илецк, ул. Карла Маркса, 6, 8 (35336) 2-32-32, si@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 12,
        "col3": "Сорочинский муниципальный округ:",
        "col4": " г. Сорочинск, ул.Советская, 1, 8 (35346) 4-11-51, admsor@esoo.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 13,
        "col3": " Ясненский муниципальный округ: ",
        "col4": "Оренбургская область, г. Ясный, ул. Ленина, 17, 8 (35368) 2-13-00, ys@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 14,
        "col3": "Адамовский район:",
        "col4": " Оренбургская область, Адамовский район, п.Адамовка, ул. Советская, 81, 8 (35365) 2-13-38, ad@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 15,
        "col3": "Акбулакский район:",
        "col4": " Оренбургская область, п. Акбулак, ул. Комсомольская, 15, 8 (35335) 2-11-40, ak@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 16,
        "col3": "Александровский район: ",
        "col4": "Оренбургская область, р-н Александровский, с. Александровка, ул. Мичурина, 49, 8 (35359) 2-11-02, al@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 17,
        "col3": "Асекеевский район: ",
        "col4": "Оренбургская область, с. Асекеево, ул. Чапаева, 28, 8 (35351) 2-00-83,  as@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 18,
        "col3": "Беляевский район:",
        "col4": " Оренбургская область, Беляевский район, с.Беляевка, ул. Советская, 46Б, 8 (35334) 2-17-25, be@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 19,
        "col3": "Бугурусланский район: ",
        "col4": "Оренбургская область, г. Бугуруслан, ул.Московская, 52а, 8 (35352) 2-39-16, bu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 20,
        "col3": "Бузулукский район: ",
        "col4": "Оренбургская область, г. Бузулук, ул. Ленина, 10, 8 (35342) 7-42-00, bz@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 21,
        "col3": "Грачевский район:",
        "col4": " Оренбургская область, с. Грачёвка, ул.Майская, 22, 8 (35344) 2-10-60, ge@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 22,
        "col3": " Домбаровский район: ",
        "col4": "Оренбургская область, Домбаровский район, п. Домбаровский, ул. 40 лет Октября, 20, 8 (35367) 2-24-52, dm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 23,
        "col3": "Илекский район: ",
        "col4": "Оренбургская область, Илекский район, с. Илек, ул. Октябрьская, 23, 8 (35337) 2-14-64, il@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 24,
        "col3": " Кваркенский район: ",
        "col4": "Оренбургская область, Кваркенский район, с. Кваркено, ул. 1-я Целинная, 18, 8 (35364) 2-17-63, kv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 25,
        "col3": " Красногвардейский район: ",
        "col4": "Оренбургская область, Красногвардейский район, с.Плешаново, ул.Мира, 5, 8 (35345) 3-14-44, 3-00-45, ko@mail.orb.ru"
    },
    {
        "col1": "Оренбургская область",
        "col2": 26,
        "col3": "Курманаевский район: ",
        "col4": "Оренбургская область, с. Курманаевка, пл. имени В. И.Ленина, 1, 8 (35341) 2-13-91, km@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 27,
        "col3": " Матвеевский район:",
        "col4": " Оренбургская обл., с. Матвеевка, ул. Комсомольская, 18, 8 (35356) 2-10-66, ma@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 28,
        "col3": "Новоорский район:",
        "col4": " Оренбургская  область, Новоорский район, п. Новоорск, ул. Рабочая, 1, 8 (35363) 7-70-01, 7-70-02, no@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 29,
        "col3": " Новосергиевский район: ",
        "col4": "Оренбургская область, п. Новосергиевка, ул. Краснопартизанская, 20, 8 (35339) 2-39-39, ns@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 30,
        "col3": " Октябрьский район: ",
        "col4": "Оренбургская область, с. Октябрьское, ул. Луначарского, 45, 8 (35330) 2-14-34, ok@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 31,
        "col3": " Оренбургский район:",
        "col4": " Оренбургская область, г. Оренбург, ул.Ст. Разина, 211, 8 (3532) 56-12-21, 77-28-91, or@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 32,
        "col3": " Первомайский район: ",
        "col4": "Оренбургская область, Первомайский район, п. Первомайский, ул. Советская, 33а, 8 (35348) 4-13-40, 4-13-42, pm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 33,
        "col3": "Переволоцкий район:",
        "col4": " Оренбургская область, п. Переволоцкий, ул. Ленинская, 76, 8 (35338) 32-2-79, pr@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 34,
        "col3": "Пономаревский район: ",
        "col4": "Оренбургская область, Пономарёвский район, с. Пономарёвка, ул. Советская, 32, 8 (35357) 21-4-50, 21-3-34, pn@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 35,
        "col3": " Сакмарский район: ",
        "col4": "Оренбургская область, с. Сакмара, ул. Советская, 25, 8 (35331) 2-11-30, 2-17-77, sk@mail.orb.ru,"
    },
    {
        "col1": "Оренбургская область",
        "col2": 36,
        "col3": "Саракташский район:",
        "col4": " Оренбургская область, п. Саракташ, ул.Вокзальная, 12, 8 (35333) 6-02-50, sa@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 37,
        "col3": " Светлинский район:",
        "col4": " Оренбургская обл., Светлинский р- н, п. Светлый, ул. Советская, 22, 8 (35366) 2-14-55, 2-13-56, sv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 38,
        "col3": " Северный район:",
        "col4": " Оренбургская область, с. Северное, ул. Советская, 24, 8 (35354) 2-17-75, 2-12-00, se@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 39,
        "col3": " Ташлинский район: ",
        "col4": "Оренбургская область, с. Ташла, ул. Довженко, 46, 8 (35347) 2-14-82, 2-13-09, tl@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 40,
        "col3": "Тоцкий район:",
        "col4": " Оренбургская область, с. Тоцкое, ул. Красная Площадь, 1, 8 (35349) 2-14-34, to@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 41,
        "col3": "Тюльганский район:",
        "col4": " Оренбургская область, Тюльганский район, п.Тюльган, ул. Ленина, 23, 8 (35332) 2-11-34, tu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 42,
        "col3": "Шарлыкский район:",
        "col4": " Оренбургская область, с. Шарлык, ул.Советская, 40, 8 (35358) 2-14-50, sl@mail.orb.ru."
    },
    {
        "col1": "Орловская область",
        "col2": 1,
        "col3": "Департамент социальной защиты, опеки и попечительства, труда и занятости Орловской области",
        "col4": "302040, г. Орел, ул. Лескова, д. 22, тел 8(4862)63-33-42 доб. 207, dszn@adm.orel.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 1,
        "col3": "Администрация города Пензы",
        "col4": "город Пенза, площадь Маршала Жукова, 4. e-mail:goradm@sura.ru, 68-22-72"
    },
    {
        "col1": "Пензенская область",
        "col2": 2,
        "col3": "Администрация города Заречного Пензенской области",
        "col4": "Пензенская область, город Заречный, пр.30-летия Победы, д. 27, e-mail: adm@zarechny.zato.ru, тел.: 8(8412) 652953"
    },
    {
        "col1": "Пензенская область",
        "col2": 3,
        "col3": "Администрация города Кузнецка",
        "col4": "Пензенская область, город Кузнецк, ул. Ленина, д.191,e-mail:kuzg_adm@sura.ru, 884157(30694)"
    },
    {
        "col1": "Пензенская область",
        "col2": 4,
        "col3": "Администрация Башмаковского района Пензенской области",
        "col4": "Пензенская область, р.п. Башмаково, ул. Советская, 17, baschm_adm@sura.ru 884143(4-13-40)"
    },
    {
        "col1": "Пензенская область",
        "col2": 5,
        "col3": "Администрация Бековского района Пензенской области",
        "col4": "Пензенская область, р.п. Беково, ул. Первомайская, д. 3а, E-mail: bekov_adm@sura.ru, тел.(8-841-41) 2-15-40"
    },
    {
        "col1": "Пензенская область",
        "col2": 6,
        "col3": "Администрация Белинского района Пензенской области (дети-сироты до 18 лет), Управление социальной защиты населения администрации Белинского района Пензенской области (лица старше 18 лет)",
        "col4": "Пензенская область, г. Белинский, площадь Комсомольская, д. 19, e-mail: opeka-bel@yandex.ru, тел.: 8(84153)20669 \nПензенская область, г. Белинский, ул. Белинская, д.3, e-mail: uszn.belinsk@yandex.ru, тел.: 8(84153)20454"
    },
    {
        "col1": "Пензенская область",
        "col2": 7,
        "col3": "Управление социальной защиты населения администрации Бессоновского района Пензенской области",
        "col4": "442780,Пензенская область, Бессоновский район, с.Бессоновка,ул.Комсомольская ,1Б/6. E-mal: soc_bess@mail.ru.Телефон 8(84140)25433"
    },
    {
        "col1": "Пензенская область",
        "col2": 8,
        "col3": "Администрация Вадинского района Пензенской области",
        "col4": "442170 Пензенская область, Вадинский район, с. Вадинск, ул. Площадь Ленина, д. 16, vadinsk_adm@sura.ru. 8(84142) 21169"
    },
    {
        "col1": "Пензенская область",
        "col2": 9,
        "col3": "Администрация Городищенского района",
        "col4": "gorodis_adm@sura.ru, 88415832260"
    },
    {
        "col1": "Пензенская область",
        "col2": 10,
        "col3": "Администрация Земетчинского района Пензенской области",
        "col4": "Пензенская область, р.п. Земетчино, пл. Победы, 4, zemet_adm@sura.ru, 84155(21350)"
    },
    {
        "col1": "Пензенская область",
        "col2": 11,
        "col3": "Администрация Иссинского района Пензенской области",
        "col4": "442710, Пензенская область , р.п. Исса, Черокманова д.21"
    },
    {
        "col1": "Пензенская область",
        "col2": 12,
        "col3": "Управление образования администрации Каменского района Пензенской области",
        "col4": "Пензенская область, Каменский район г. Каменка, ул. Гражданская, д.33, E-mail: kam_roo@sura.ru, тел.: 8(84156) 5-24-25)"
    },
    {
        "col1": "Пензенская область",
        "col2": 13,
        "col3": "Администрация Камешкирского района",
        "col4": "442450 Пензенская область, Камешкирский район, с. Русский Камешкир, ул. Радищева, д. 15/ kamesh_adm@sura_ru/ 8(84145) 21478"
    },
    {
        "col1": "Пензенская область",
        "col2": 14,
        "col3": "Администрация Колышлейского района Пензенской области",
        "col4": "Пензенская область, Колышлейский район, р.п. Колышлей, ул. Московская, д.20, E-mail: kolish_adm@sura.ru-тел.:(84146) 2-13-40"
    },
    {
        "col1": "Пензенская область",
        "col2": 15,
        "col3": "Управление социальной защиты населения администрации Кузнецкого района Пензенской области",
        "col4": "Пензенская область, г. Кузнецк, ул. Комсомольская, д. 51, soc-kuzrn@yandex.ru, тел.: 8(84157)3-00-69"
    },
    {
        "col1": "Пензенская область",
        "col2": 16,
        "col3": "Администрация Лопатинского района",
        "col4": "Пензенская область, Лопатинский район, с.Лопатино, ул.Пионерская,д.29, т.8(84148)25557"
    },
    {
        "col1": "Пензенская область",
        "col2": 17,
        "col3": "Управление социальной защиты населения администрации Лунинского района",
        "col4": "442730 Пензенская область, Лунинский район, р.п. Лунино, ул. Юбилейная, д.39 opeka.lunino@bk.ru 8(84161)31714"
    },
    {
        "col1": "Пензенская область",
        "col2": 18,
        "col3": "Администрация Малосердобинского района",
        "col4": "442800, Пензенская область, Малосердобинский район, село Малая Сердоба, Ленинская ул., д.42"
    },
    {
        "col1": "Пензенская область",
        "col2": 19,
        "col3": "Управление социальной защиты населения администрации Мокшанского района",
        "col4": "442370, Пензенская область, Мокшанский район, р.п. Мокшан, ул.Поцелуева, д.8,socmokshan@rambler.ru 8(84150)2-23-58"
    },
    {
        "col1": "Пензенская область",
        "col2": 20,
        "col3": "Управление социальной защиты населения и охраны труда администрации Наровчатского района",
        "col4": "с. Наровчат, ул. М.Горького 38 А, soz_narov@mail.ru, 88416321485"
    },
    {
        "col1": "Пензенская область",
        "col2": 21,
        "col3": "Управление социальной защиты населения администрации Неверкинского района",
        "col4": "Пензенская область Неверкинский район с. Неверкино ул. Куйбышева, 9, Эл.почта: nevuszn@sura.ru, тел. 8(84164)21485"
    },
    {
        "col1": "Пензенская область",
        "col2": 22,
        "col3": "Управление социальной защиты населения администрации Нижнеломовского района Пензенской области",
        "col4": "Пензенская область, Нижнеломовский район, г. Нижний Ломов, пл.Советская, 6Б, E-mail: soc_nlom@mail.ru, тел.: (841-54)4-41-85"
    },
    {
        "col1": "Пензенская область",
        "col2": 23,
        "col3": "Администрация Никольского района",
        "col4": "442680, Пензенская область, Никольский район, г.Никольск, ул.Московская, д.2, nikadm@nikrn.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 24,
        "col3": "Управление социальной защиты населения администрации Пачелмского района Пензенской области",
        "col4": "Пензенская область, р.п.Пачелма, ул.Драгунова, д.2, soc_pachelma@mail.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 25,
        "col3": "Управление социальной защиты населения администрации Пензенского района Пензенской области",
        "col4": "442400, Пензенская область, Пензенский район, с. Кондоль, ул. Осипова, 46, тел.: (884147) 55036, uszn_kondol@mail.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 26,
        "col3": "Администрация Сердобского района Пензенской области",
        "col4": "Пензенская область, Сердобский район, г.Сердобск, ул.Ленина, д.90, E-mail: serdr_adm@sura.ru, тел.: (84167) 2-35-42"
    },
    {
        "col1": "Пензенская область",
        "col2": 27,
        "col3": "Управление социальной защиты и охраны труда администрации Сосновоборского района",
        "col4": "Пензенская область, р.п. Сосновоборск, ул. Комсомольская, д. 52А, Тел. (84168)2-01-35, sosnovoborsckiiraion.opeka@yandex.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 28,
        "col3": "Администрация Спасского района Пензенской области",
        "col4": "Пензенская область, г.Спасск, Советская площадь, д.36 spassk-ray-adm@yandex.ru 8-84151-31231"
    },
    {
        "col1": "Пензенская область",
        "col2": 29,
        "col3": "Управление социальной защиты населения Тамалинского района",
        "col4": "Пензенская обл. Тамалинский район р.п. Тамала ул. Коммунистическая дом 36 soc_tam@mail.ru 884169 22757"
    },
    {
        "col1": "Пензенская область",
        "col2": 30,
        "col3": "Администрация Шемышейского района Пензенской области",
        "col4": "442430 Пензенская область, Шемышейский район, р.п. Шемышейка, ул. Ленина, д.47.,т.: (8-841-59) 2-17-80. Е-mail: shem_adm@sura.ru"
    },
    {
        "col1": "Пермский край",
        "col2": 1,
        "col3": "1. администрация Александровского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 20 а, г. Александровск, Александровский МО,Пермский край, 618320 Email: administration@aleksandrovsk.permkrai.ru   тел.: 8 (342) 743 53 77"
    },
    {
        "col1": "Пермский край",
        "col2": 2,
        "col3": "2. администрация Бардымского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 14, с. Барда, Бардымский МО, Пермский край, 618150  Admin-barda@rambler.ru  8 (342) 922 06 10"
    },
    {
        "col1": "Пермский край",
        "col2": 3,
        "col3": "3. администрация Берёзовского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 33, с. Березовка, Берёзовский МО, Пермский край, 617565 \nadministration@berezovka.permkrai.ru \n 8 (342) 513 11 33"
    },
    {
        "col1": "Пермский край",
        "col2": 4,
        "col3": "4. администрация Большесосновского муниципального округа Пермского края;",
        "col4": "ул. Ворошилова, д. 2, с. Большая Соснова, Большесосновский МО, Пермский край, 617072  administration@bsn.permkrai.ru  тел.: 8 (342) 572 73 73"
    },
    {
        "col1": "Пермский край",
        "col2": 5,
        "col3": "5. администрация Юсьвинского муниципального округа Пермского края;",
        "col4": "ул. Красноармейская, д. 14, с. Юсьва, Юсьвинский МО, Пермский край, 619170  \nadministration@yusva.permkrai.ru\nтел.: 8 (342) 462 71 35"
    },
    {
        "col1": "Пермский край",
        "col2": 6,
        "col3": "6. администрация Юрлинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 15, с. Юрла, Юрлинский МО, Пермский край, 619200\nadministration@yurla.permkrai.ru\nтел.: 8 (342) 942 11 72"
    },
    {
        "col1": "Пермский край",
        "col2": 7,
        "col3": "7. администрация Гайнского муниципального округа Пермского края;",
        "col4": "ул. Кашина, д. 41, пос. Гайны, Гайнский МО, Пермский край, 619650\nGainy_adm@mail.ru\nтел.: 8 (342) 452 11 98"
    },
    {
        "col1": "Пермский край",
        "col2": 8,
        "col3": "8. администрация Губахинского муниципального округа Пермского края;",
        "col4": "ул. Никонова, д. 44, г. Губаха, Губахинский МО, Пермский край, 618250\nadmin-gubaha@mail.ru\n8 (342) 484 19 59, 8 (342) 484 04 80\n"
    },
    {
        "col1": "Пермский край",
        "col2": 9,
        "col3": "9. администрация Еловского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 32, с. Елово, Еловский МО, Пермский край, 618170\nadministration@elovo.permkrai.ru\n 8 (342) 963 11 07"
    },
    {
        "col1": "Пермский край",
        "col2": 10,
        "col3": "10. администрация Карагайского муниципального округа;",
        "col4": "ул. Ленина, д. 5, с. Карагай, Карагайский МО, Пермский край, 617210\nadministration@karagay.permkrai.ru\n8 (342) 973 16 35"
    },
    {
        "col1": "Пермский край",
        "col2": 11,
        "col3": "11. администрация Кишертского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 40, с. Усть-Кишерть, Кишертский МО, Пермский край, 617593\nadministration@kishert.permkrai.ru\n8 (342) 522 14 35, 8 (342) 522 14 03"
    },
    {
        "col1": "Пермский край",
        "col2": 12,
        "col3": "12. администрация Косинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 66, с. Коса, Косинский МО, Пермский край, 619430\nadministration@kosa.permkrai.ru\n8 (342) 982 12 32"
    },
    {
        "col1": "Пермский край",
        "col2": 13,
        "col3": "13. администрация Кочевского муниципального округа Пермского края;",
        "col4": "ул. Калинина, д. 5, с. Кочево, Кочевский МО, Пермский край, 619317\nadmKochevo@list.ru\n8 (342) 939 12 86"
    },
    {
        "col1": "Пермский край",
        "col2": 14,
        "col3": "14. администрация Кудымкарского муниципального округа Пермского края;",
        "col4": "ул. Лихачева, д. 54, г. Кудымкар, Кудымкарский МО, Пермский край, 618994\nadministration@kudymkar.permkrai.ru\n8 (342) 604 52 43"
    },
    {
        "col1": "Пермский край",
        "col2": 15,
        "col3": "15. администрация Куединского муниципального округа Пермского края;",
        "col4": " ул. Гагарина, д. 25, пос. Куеда, Куединский МО, Пермский край, 617700\nadministration@kueda.permkrai.ru\n8 (342) 623 55 65, 8 (342) 623 55 68"
    },
    {
        "col1": "Пермский край",
        "col2": 16,
        "col3": "16. администрация Кунгурского муниципального округа Пермского края;",
        "col4": " ул. Советская, д. 26, г. Кунгур,\nКунгурский МО, Пермский край, 617470\nkungrad59@mail.ru\n8 (342) 712 42 83"
    },
    {
        "col1": "Пермский край",
        "col2": 17,
        "col3": "17. администрация Октябрьского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 57, раб.пос. Октябрьский, Октябрьский МО, Пермский край, 617860\nadministration@oktyabrskiy.permkrai.ru\n8 (342) 662 19 78, 8 (342) 662 14 75"
    },
    {
        "col1": "Пермский край",
        "col2": 18,
        "col3": "18. администрация Ординского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 12, с. Орда, Ординский МО, Пермский край, 617500\nadministration@orda.permkrai.ru\n8 (342) 582 01 49"
    },
    {
        "col1": "Пермский край",
        "col2": 19,
        "col3": "19. администрация Осинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 25, г. Оса, Осинский МО, Пермский край, 61812\nadministration@osa.permkrai.ru\n8 (342) 914 65 65"
    },
    {
        "col1": "Пермский край",
        "col2": 20,
        "col3": "20. администрация Оханского муниципального округа Пермского края;",
        "col4": "ул. Куйбышева, д. 35, г. Оханск, Оханский МО, Пермский край, 618100\nadministration@okhansk.permkrai.ru\n8 (342) 793 13 51"
    },
    {
        "col1": "Пермский край",
        "col2": 21,
        "col3": "21. администрация Очерского городского округа Пермского края;",
        "col4": "ул. Ленина, д. 41, г. Очер, Очерский МО, Пермский край, 617140\nadministration@oсher.permkrai.ru\n8 (342) 783 10 90"
    },
    {
        "col1": "Пермский край",
        "col2": 22,
        "col3": "22. администрация Пермского муниципального округа Пермского края;",
        "col4": "ул. Верхне-Муллинская, д. 71, г. Пермь, Пермский край, 614065\nadministration@permsky.permkrai.ru\n8 (342) 296 21 51"
    },
    {
        "col1": "Пермский край",
        "col2": 23,
        "col3": "23. администрация Сивинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 66, с. Сива, Сивинский МО, Пермский край, 617240\nadministration@siva.permkrai.ru\n8 (342) 772 13 00"
    },
    {
        "col1": "Пермский край",
        "col2": 24,
        "col3": "24. администрация Уинского муниципального округа Пермского края;",
        "col4": "ул. Октябрьская, д. 1, с. Уинское,\nУинский МО, Пермский край, 617520\nEmail: administration@uinsk.permkrai.ru\nтел.: 8 (342) 592 31 97\n"
    },
    {
        "col1": "Пермский край",
        "col2": 25,
        "col3": "25. администрация Частинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 40, с. Частые, Частинский МО, Пермский край, 617170\nEmail: administration@chastye.permkrai.ru.\nтел.: 8 (342) 682 14 34"
    },
    {
        "col1": "Пермский край",
        "col2": 26,
        "col3": "26. администрация Чусовского муниципального округа Пермского края;",
        "col4": "ул. Сивкова, д. 8б, г. Чусовой, Чусовского МО, Пермский край, 618204\nEmail: administration@chusovoy.permkrai.ru.\nтел.: 8 (342) 563 69 10\n"
    },
    {
        "col1": "Пермский край",
        "col2": 27,
        "col3": "27. администрация Кизеловского муниципального округа Пермского края;",
        "col4": "ул. Луначарского, д. 19, г. Кизел,\nКизеловский МО, Пермский край, 618350\nEmail: administration@kizel.permkrai.ru.\nтел.: 8 (342) 554 46 52\n"
    },
    {
        "col1": "Пермский край",
        "col2": 28,
        "col3": "28. администрация Добрянского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 14, г. Добрянка,\nДобрянский МО, Пермский край, 618740\nadministration@dobryanka.permkrai.ru 8 (342) 652 93 68\n"
    },
    {
        "col1": "Пермский край",
        "col2": 29,
        "col3": "29. администрация городского округа ЗАТО «Звездный»;",
        "col4": "ул. Ленина, д. 11А, пос. Звёздный,\nПермский край, 614575\nEmail: administration@zvezdny.permkrai.ru.\nтел.: 8 (342) 297 06 37\n"
    },
    {
        "col1": "Пермский край",
        "col2": 30,
        "col3": "30. администрация Красновишерского муниципального округа Пермского края;",
        "col4": "ул. Джержинского, д. 6а, г. Красновишерск, Красновишерский МО, Пермский край, 618592\nadministration@krasnovishersk.permkrai.ru.\n8 (342) 433 03 27\n"
    },
    {
        "col1": "Пермский край",
        "col2": 31,
        "col3": "31. администрация Краснокамского муниципального округа Пермского края;",
        "col4": "пр-т Маяковского, д. 11, г. Краснокамск, Краснокамский МО, Пермский край, 617060\nEmail: administration@ krasnokamsk.permkrai.ru\nтел.: 8 (342) 734 44 54\n"
    },
    {
        "col1": "Пермский край",
        "col2": 32,
        "col3": "32. администрация Лысьвенского городского округа Пермского края;",
        "col4": "пр-т Победы, д. 38, г. Лысьва, Лысьвенский МО, Пермский край, 618900\nEmail: administration@lysva.permkrai.ru.\nтел.: 8 (342) 496 07 06\n"
    },
    {
        "col1": "Пермский край",
        "col2": 33,
        "col3": "33. администрация муниципального округа «город Березники» Пермского края;",
        "col4": "пл. Советская, д. 1, г. Березники,\nПермский край, 618417\nEmail: administration@berezniki.permkrai.ru\nТелефон: 8 (342) 426 21 15\n"
    },
    {
        "col1": "Пермский край",
        "col2": 34,
        "col3": "34. администрация Суксунского муниципального округа Пермского края;",
        "col4": "ул. К. Маркса, д. 4, пос. Суксун,\nСуксунский МО, Пермский край, 617560\nadministration@suksun.permkrai.ru.\n8 (342) 753 15 75, 8 (342) 753 12 65\n"
    },
    {
        "col1": "Пермский край",
        "col2": 35,
        "col3": "35. администрация Нытвенского муниципального округа Пермского края;",
        "col4": "ул. Либкнехта, д. 2а, г. Нытва,\nНытвенский МО, Пермский край, 617000\nadministration@nytva.permkrai.ru.\nтел.: 8 (342) 723 08 56\n"
    },
    {
        "col1": "Пермский край",
        "col2": 36,
        "col3": "36. администрация Пермского городского округа Пермского края;",
        "col4": "ул. Ленина, д. 23, г. Пермь,\nПермский ГО, Пермский край, 614000\nEmail: main@gorodperm.ru.\nтел.: (342) 217 33 17\n"
    },
    {
        "col1": "Пермский край",
        "col2": 37,
        "col3": "37. администрация Соликамского муниципального округа Пермского края;",
        "col4": "ул. 20 лет Победы, д. 106, г. Соликамск,\nСоликамский МО, Пермский край, 618554\nEmail: administration@solikamsk.permkrai.ru.\nтел.: 8 (342) 537 03 05\n"
    },
    {
        "col1": "Пермский край",
        "col2": 38,
        "col3": "38. администрация Чайковского городского округа Пермского края;",
        "col4": "ул. Ленина, д. 37, г. Чайковский,\nЧайковский ГО, Пермский край, 617760\nadministration@chaykovsky.permkrai.ru.\nтел.: 8 (342) 413 25 07\n"
    },
    {
        "col1": "Пермский край",
        "col2": 39,
        "col3": "39. администрация Чердынского муниципального округа Пермского края;",
        "col4": "ул. Соборная, д. 18а, г. Чердынь,\nЧердынский МО, Пермский край, 618601\nadministration@cherdyn.permkrai.ru.\nтел.: 8 (342) 402 82 01\n"
    },
    {
        "col1": "Пермский край",
        "col2": 40,
        "col3": "40. администрация Чернушинского муниципального округа Пермского края;",
        "col4": "ул. Юбилейная, д. 9, г. Чернушка, Чернушинский МО, Пермский край, 617830\nadministration@chernushka.permkrai.ru.\nтел.: 8 (342) 614 43 95\n"
    },
    {
        "col1": "Пермский край",
        "col2": 41,
        "col3": "41. администрация Ильинского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 7, пос. Ильинский,\nИльинский ГО, Пермский край, 617020\nadministration@ilinsky.permkrai.ru.\nтел.: 8 (342) 769 16 08\n"
    },
    {
        "col1": "Пермский край",
        "col2": 42,
        "col3": "42. администрация Горнозаводского муниципального округа Пермского края;",
        "col4": "ул. Кирова, д. 65, г. Горнозаводск, Горнозаводский МО, Пермский край, 618820\nadministration@gornozavodsk.permkrai.ru.\nтел.: 8 (342) 694 14 55\n"
    },
    {
        "col1": "Пермский край",
        "col2": 43,
        "col3": "43. администрация Верещагинского муниципального округа Пермского края.",
        "col4": "ул. Ленина, д. 26, г. Верещагино, Верещагинский МО, Пермский край, 617120\nadministration@vereschagino.permkrai.ru.\nтел.: 8 (342) 543 33 50, 8 (342) 543 33 55\n"
    },
    {
        "col1": "Приморский край",
        "col2": 1,
        "col3": "Отдел по учету и распределению жилья управления жизнеобеспечения администрации Арсеньевского городского округа",
        "col4": "692337, Приморский край, г. Арсеньев, ул. Ленинская, 8 (каб.213) zhilotdel@ars.town 8(42361) 3-75-538;\n(42361) 3-75-54  "
    },
    {
        "col1": "Приморский край",
        "col2": 2,
        "col3": "Управление опеки и попечительства администрации Артемовского городского округа",
        "col4": "692760, Приморский край, г. Артём, ул. Кирова, дом 42 / opeka@artemokrug.ru / тел. 8 42337 32280"
    },
    {
        "col1": "Приморский край",
        "col2": 3,
        "col3": "отдел опеки и попечительства администрации городского округа Большой Камень",
        "col4": "692806, Приморский край, г. Большой Камень, ул. Карла Маркса, д. 4, opeka-bkamen@mail.ru, 8(42335)4-08-71"
    },
    {
        "col1": "Приморский край",
        "col2": 4,
        "col3": "Администрации города Владивостока      (Управление опеки и попечительства) ",
        "col4": "г. Владивосток, Океанский проспект, д. 20.  (прием документов по адресу: г. Владивосток, Океанский проспект, д. 7, каб.301,  тел. 8(423)264-92-51, 8(423)264-92-50, opeka@vlc.ru )"
    },
    {
        "col1": "Приморский край",
        "col2": 5,
        "col3": "Управление образования администрации Дальнегорского городского округа",
        "col4": "3-23-42 dalnegorsk-uo@yandex.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 6,
        "col3": "Администрация Дальнереченского городского округа",
        "col4": "г. Дальнереченск, ул. Победы, д.13, priem-adm@dalnerokrug.ru тел.842356 25-5-55"
    },
    {
        "col1": "Приморский край",
        "col2": 7,
        "col3": "отдел опеки и попечительства администрации Лесозаводского муниципального округа ",
        "col4": "г. Лесозаводск,  ул. Будника, д. 119,  каб. 201 opeka_lesozavodska@mail.ru8(42355) 29465"
    },
    {
        "col1": "Приморский край",
        "col2": 8,
        "col3": "Управление опеки и попечительства администрации Находкинского городского округа",
        "col4": "692922, г.Находка, ул. Пограничная, д.10а, офис 400-403, тел. 8(4236)69-91-74, 69-91-73"
    },
    {
        "col1": "Приморский край",
        "col2": 9,
        "col3": "Администрация муницапального  округа город Партизанск Приморского края",
        "col4": "692864, Приморский край,  г. Партизанск,  ул. Ленинская, д. 26 \"А\", pgo@partizansk.org.ru 824236360742"
    },
    {
        "col1": "Приморский край",
        "col2": 10,
        "col3": "Администрация городского округа Спасск-Дальний \nУправление ЖКХ ",
        "col4": "г.Спасск-Дальний ул.Борисова, д.17 gkh@spasskd.ru 84235220993"
    },
    {
        "col1": "Приморский край",
        "col2": 11,
        "col3": "Администрация Уссурийского городского округа Приморского края",
        "col4": "692519 Приморский край,  г. Уссурийск, ул. Ленина, д. 101,тел: 8(4234)32-26-31, эл. адрес: admin1@adm-ussuriisk.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 21,
        "col3": "Отдел опеки и попечительства администрации городского округа ЗАТО Фокино",
        "col4": "692880, г. Фокино, \nул. Постникова, д. 17\nТел.: 8(42339) 2-43-55"
    },
    {
        "col1": "Приморский край",
        "col2": 13,
        "col3": "Администрация Анучинского муниципального округа",
        "col4": "692300, Приморский край, Анучинский район, с.Анучино, ул. Лазо, д.6. anuchinsky@mail.ru,   Тел.: 8(42362) 91-6-78"
    },
    {
        "col1": "Приморский край",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Дальнереченского муниципального района",
        "col4": "692132, г. Дальнереченск, ул. Ленина,90/ opeka_dmr@mail.ru /  8 42(356) 32157"
    },
    {
        "col1": "Приморский край",
        "col2": 15,
        "col3": "Администрация Кавалеровского муниципального округа отдел опеки и попечительства Управления образования",
        "col4": "Приморский край, Кавалеровский район, п. Кавалерово, ул. Арсеньева, д. 111 тел. 8(42375)9-11-10 opeka1700@mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 16,
        "col3": "Администрация Кировского муниципального района",
        "col4": "692091, Приморский край, Кировский район,\n Пгт. Кировский, ул. Советская, д. 57, kiropeka@mail.ru,  8-42354-21567"
    },
    {
        "col1": "Приморский край",
        "col2": 17,
        "col3": "Администрация Красноармейского МО",
        "col4": "692171, Приморский край, Красноармейский район, с. Новопокровка, ул. Советская, 74, opeka19@mail.ru, 8(42359)22559"
    },
    {
        "col1": "Приморский край",
        "col2": 18,
        "col3": "Отдел опеки и попечительства администрации Лазовского муниципального округа",
        "col4": "692980 Приморский край, Лазовский район, с. Лазо, ул. Некрасовская, 31, 8(42377)20517, opekalazo@yandex.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 19,
        "col3": "Отдел опеки и попечительства администрации Михайловского муниципального округа",
        "col4": "Ровенко Анжелика Павловна, главный специалист отдела опеки и попечительства, тел. 8(42346)24451, 692651, с. Михайловка, ул. Красноармейская, д.16,  E-mail: opeka@mikhprim.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 20,
        "col3": "Отдел опеки и попечительства администрации Надеждинского муниципального района",
        "col4": "692491, Приморский край Надеждинский район,  п. Новый,\nул. Первомайская д.43 тел.: 8 (42334) 3-86-36, эл. почта: opeka22@mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 22,
        "col3": "Отдел опеки и попечительства администрации Ольгинского муниципального округа",
        "col4": "692460 пгт Ольга, ул. Ленинская, д.8 opeka24@bk.ru  8 (42376)92214"
    },
    {
        "col1": "Приморский край",
        "col2": 23,
        "col3": "Администрация Партизанского муниципального округа ",
        "col4": "692962, Приморский край, Партизанский район, с.Владимиро-Александровское, ул.Комсомольская 45а, 84236521825, opeka_pmr@rambler.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 24,
        "col3": "Администрация Пограничного МО, отдел опеки и попечительства",
        "col4": "692582 п. Пограничный Приморского края, ул. Советская, 29. opeka-pogranichnyi@mail.ru. 84234523262"
    },
    {
        "col1": "Приморский край",
        "col2": 25,
        "col3": "Отдел опеки и попечительства администрации Пожарского муниципального округа",
        "col4": "692001, Приморский край, Пожарский район,               пгт Лучегорск, 4 микрорайон, д. 2, каб. 518    pozopeka7000@mail.ru         8 (42357) 33 9 71"
    },
    {
        "col1": "Приморский край",
        "col2": 26,
        "col3": "Отдел по реализации отдельных государственных полномочий по обеспечению жилыми помещениями детей-сирот, детей, оставшихся без попечения родителей, лиц из числа  детей-сирот и детей, оставшихся без попечения родителей, администрации Спасского муниципального района",
        "col4": "692245 Приморский край, г. Спасск-Дальний, ул. Ленинская, 27, адрес электронной почты: spasskmr-opeka-dom@yandex.ru, тел. 8(42352) 3-45-67"
    },
    {
        "col1": "Приморский край",
        "col2": 27,
        "col3": "отдел опеки и попечительства администрации Тернейского муниципального округа",
        "col4": "отдел опеки и попечительства , 692150 Приморский край, Тернейский муниципальный округ, пгт Терней, ул. Партизанская, д. 52, каб. 2; opeka.terneiski@yandex.ru; 8(42374)31207"
    },
    {
        "col1": "Приморский край",
        "col2": 28,
        "col3": "Администрация Ханкайского муниципального округа, отдел опеки и попечительства",
        "col4": "Приморский край, Ханкайский район, с. Камень-Рыболов, ул. Октябрьская, д.6, 692684; opeka.hanka@mail.ru, 8(42349)97796"
    },
    {
        "col1": "Приморский край",
        "col2": 29,
        "col3": "Отдел опеки и попечительства администрации Хасанского муниципального округа ПК",
        "col4": "Приморский край, Хасанский муниципальный округ, пгт Славянка, ул. Молодежная, д. 1, каб. 2. hasanskai_opeka@mail.ru 8(42331)46-04"
    },
    {
        "col1": "Приморский край",
        "col2": 30,
        "col3": "Администрация Хорольского муниципального округа Приморского края",
        "col4": "692254, Приморский край, Хорольский район,с.Хороль, ул.Ленинская, 51 / admhmr@khorol.ru/ +7 (42347) 2-14-01"
    },
    {
        "col1": "Приморский край",
        "col2": 31,
        "col3": "Администрация Черниговского МО",
        "col4": "692372, с. Черниговка, ул. Буденного, 23, opeka32@mail.ru, 8 (42351) 25-2-58"
    },
    {
        "col1": "Приморский край",
        "col2": 32,
        "col3": "Администрация Чугуевского муниципального округа",
        "col4": "692623, Приморский край, Чугуевский район, с. Чугуевка, ул. 50 лет Октября, д. 193, e-mail chuguevskymo@mail.ru, телефон (42372) 22-3-03, тел./факс (42372) 22-6-01"
    },
    {
        "col1": "Приморский край",
        "col2": 33,
        "col3": "Отдел опеки и попечительства  Администрации Шкотовского муниципального района",
        "col4": "Г.Большой Камень,ул. Блюхера. 25 . Opeka_shkot@mail.ru 8 42 335 33-7-09"
    },
    {
        "col1": "Приморский край",
        "col2": 34,
        "col3": "Администрация Яковлевского муниципального округа",
        "col4": "692361 приморский край, Яковлевский район, с. Яковлевка, пер. Почтовый д. 5, tooipyakovlevka@mail.ru тел. 8(423711) 97441 "
    },
    {
        "col1": "Псковская область",
        "col2": 1,
        "col3": "Комитет по социальной защите Псковской области",
        "col4": "180001, г. Псков, ул. Некрасова, 23,  (8112) 29-99-98, \nsoc-info@obladmin.pskov.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 1,
        "col3": "Министерство труда и социального развития Республики Адыгея",
        "col4": "Республика Адыгея, \nг. Майкоп, \nул. Советская, 176, deti_mintrud@adygheya.gov.ru, 8(8772)52-18-86"
    },
    {
        "col1": "Республика Алтай",
        "col2": 1,
        "col3": "Министерство труда, социального развития и занятости населения Республики Алтай",
        "col4": "Республика Алтай, г. Горно - Алтайск, ул. Северная, 10, mintrud@altaigov.ru, 8(38822)47732"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 1,
        "col3": "Администрация муниципального района Абзелиловский район Республики Башкортостан (жилищная комиссия)",
        "col4": "453620, Республика Башкортостан, Абзелиловский район, с.Аскарово, ул.Ленина, д.41, adm01@bashkortostan.ru, 8(34772)2-15-10"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 2,
        "col3": "Администрация МР Альшеевский район РБ (сектор по опеке и попечительству Администрации)",
        "col4": "452120, Республика Башкортостан, с. Раевский, ул. Селькора, д. 7, opeka_alsh@mail.ru, 8(34754)3-15-57"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 3,
        "col3": "Администрация МР Архангельский район РБ(Отдел по строительству и ЖКХ)",
        "col4": "453030, Республика Башкортостан, Архангельский район, с. Архангельское, ул. Н.Крупская, д.2, тел 834774 -2 -13-76, 03.sjp@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 4,
        "col3": "Администрация муниципального района Аскинский район Республики Башкортостан",
        "col4": "452880, Республика Башкортостан, Аскинский район, с. Аскино, ул. Советская, д.21, adm04@bashkortostan.ru +7(347)712-17-65"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 5,
        "col3": "Администрация муниципального района Аургазинский район Республики Башкортостан (отдел опеки и попечительства)",
        "col4": "453480,Республика Башкортостан, с.Толбазы, ул.Ленина, 86 aur-roo-opeka@yandex.ru 834745(2-02-85"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 6,
        "col3": "Администрация МР Баймакский район РБ (отдел опеки и попечительства)",
        "col4": "453630, Республика Башкортостан, г. Баймак, пр .С.Юлаева,36"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 7,
        "col3": "Администрация муниципального района Бакалинский район Республики Башкортостан (отдел строительства АМР Бакалинский район РБ)",
        "col4": "452650, РБ, Бакалинский район, с. Бакалы, ул. Ленина, д. 91 adm06@bashkortostan.ru тел. (34742) 3-29-42"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 8,
        "col3": "Администрация \nмуниципального района \nБалтачевский район\nРеспублики Башкортостан",
        "col4": "452980, Республика \nБашкортостан, \nс. Старобалтачево,\nул. Советская, д. 48,\nadm07@bashkortostan.ru/\n+7 (34753) 2-19-61"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 9,
        "col3": "Администрации МР Белебеевский район РБ (жилищный отдел)",
        "col4": "452000,РБ, г. Белебей, ул. Красная, д. 116, 45.kgp@bashkortostan.ru, 8 (34786) 4-14-88"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 10,
        "col3": "Администрации муниципального района Белокатайский район (Отдел опеки и попечительства)",
        "col4": "РБ, Белокатайский район, с. Новобелокатай, ул. Советская, д. 116 adm08@bashkortostan.ru 8(34750)22772"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 11,
        "col3": "Администрации муниципального района Белорецкий район (Отдел опеки и попечительства)",
        "col4": "РБ, г. Белорецк, ул. Ленина, д. 71, adm46@bashkortostan.ru, +7 (34792) 3-08-06, 3-25-69"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 12,
        "col3": "Администрация муниципального района Бижбулякский район РБ (Сектор опеки и попечительства)",
        "col4": "РБ, Бижбулякский район, с.Бижбуляк, ул.Победы, д.13, adm09@bashkortostan.ru, тел: 8347-43 21296, 22369"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 13,
        "col3": "Администрация муниципального района Бижбулякский район РБ (Сектор опеки и попечительства)",
        "col4": "РБ, г. Бирск, ул. Курбатова, д. 63, opeka47@mail.ru, 8(347)8422807"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 14,
        "col3": "Администрация МР (сектор по жилищным вопросам отдела градостроительства и жилищных вопросов)",
        "col4": "РБ, г. Благовещенск, ул. Седова, д. 96, zhilsektor-blag@yandex.ru, +73476622007"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 15,
        "col3": "Администрация муниципального района Благоварский район Республики Башкортостан (Отдел опеки и попечительства )",
        "col4": "452740, Республика Башкортостан, Благоварский район, с.Языково, ул.Ленина, д.37, blagovar_opeka@mail.ru/83474722793"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 16,
        "col3": "Администрация Буздякского района \n(сектор опеки и попечительства)\n",
        "col4": "452710, Республика\nБашкортостан , с.Буздяк, \nул. Ленина, 7 \nbuzdyakopeka@yandex.ru, \n+73477331941"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 17,
        "col3": "Администрация МР Бураевский район (сектор опеки и попечительства)",
        "col4": "452960, Республика Башкортостан, с. Бураево, ул. М.Гафури, 41 buropeka@yandex.ru 8(34756)2-48-62"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 18,
        "col3": "Администрация МР Бурзянский район РБ (отдел опеки и попечительства)",
        "col4": "Администрация МР Бурзянский район РБ, 453580, Бурзянский район, с.Старосубхангулово, ул.Ленина, д.61 8(34755)35240, adm13@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 19,
        "col3": "Администрация МР Гафурийского района Республики Башкортостан (отдел капитального строительства и жизнеобеспечения )",
        "col4": "453050, Гафурийский район,\nс. Красноусольский, ул. Октябрьская, E-mail: adm14@bashkortostan.ru, тел.(34740)2-10-84"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 20,
        "col3": "Администрация МР Давлекановский район РБ (отдел опеки и попечительства)",
        "col4": "453400, Давлекановский район, г. Давлеканово, ул. Красная Площадь, д. 9, 83476831854, davlopeka@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 21,
        "col3": "Администрация муниципального района Дуванский район Республики Башкортостан",
        "col4": "452530, Республика Башкортостан, Дуванский район, с. Месягутово, ул. И. Усова, д.6 adm15@bashkortostan.ru 83479832341"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 22,
        "col3": "Администрация муниципального района Дюртюлинский район Республики Башкортостан",
        "col4": "452320, Республика Башкортостан, г. Дюртюли, ул. Чеверева, д.41, 50.opeka@bashkortostan.ru 83478722678"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 23,
        "col3": "Администрация муниципального района Ермекеевский район Республики Башкортостан",
        "col4": "452190, Ермекеевский район, с. Ермекеево, ул. Ленина, 15; adm16@bashkortostan.ru; тел.: 83474122413"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 24,
        "col3": "Администрация муниципального района Зианчуринский район Республики Башкортостан (межведомственная комиссия)",
        "col4": "453380, Зианчуринский район, с. Исянгулово, ул. Советская, 3; adm17@bashkortostan.ru; тел.: 83478521416"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 25,
        "col3": "Администрация МР Зилаирский район РБ (сектор по опеке и попечительству)",
        "col4": "453680, Республика Башкортостан, Зилаирский район, с. Зилаир, ул. Ленина, 70 opekazilair@mail.ru 8(347552)2-23-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 26,
        "col3": "Администрация муниципального района Иглинский район Республики Башкортостан\n(отдел опеки и попечительства)",
        "col4": "452410, Республика Башкортостан, Иглинский район, с. Иглино, ул. Ленина, д.79 iglino-opeka@yandex.ru тел. 8(43795)2-34-92"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 27,
        "col3": "Администрация муниципального района Илишевский район Республики Башкортостан",
        "col4": "452260, Республика Башкортостан, Илишевский район, с. Верхнеяркеево, ул. Красноармейская, д.35 adm20@bashkortostan.ru 83476251538"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 28,
        "col3": "Администрация муниципального района Ишимбайский район Республики Башкортостан (Отдел по опеке и попечительству)\n\n",
        "col4": "453200, Республика Башкортостан, г. Ишимбай, ул.Революционная, д.3,opeka-ish@mail.ru, 83479477152"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 29,
        "col3": "Администрация муниципального района Калтасинский район Республики Башкортостан\n(отдел опеки и попечительства)",
        "col4": "452860, Республика Башкортостан, Калтасинский район, с. Калтасы, ул. Карла Маркса, д. 80. kaltasyorgopeki@mail.ru\nтел. 3477942224"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 30,
        "col3": "Органы опеки и попечительства Администрации МР Караидельский район РБ",
        "col4": "452360, РБ, Караидельский район, с.Караидель, ул.Ленина, д.24, opeka.karaidel@mail.ru, 8(34744)2-00-15"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 31,
        "col3": "Администрация муниципального района Кармаскалинский район Республики Башкортостан( отдел опеки и попечительства, жилищная комиссия)",
        "col4": "453020,Республика Башкортостан, Кармаскалинский район, с.Кармаскалы, ул.Садовая,23, opeka.karm@mail.ru 8(34765)2-11-89"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 32,
        "col3": "Администрация муниципального района Кигинский район Республики Башкортостан",
        "col4": "452500, Республика Башкортостан, Кигинский район, с. Верхние киги, ул. Салавата, д. 2, adm24@bashkortostan.ru 83474837171"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 33,
        "col3": "Администрация муниципального района Краснокамский район Республики Башкортостан",
        "col4": "452930, Республика Башкортостан, Краснокамский район, с. Николо- Березовка, ул. Карла Маркса, д.3,adm25@bashkortostan.ru,83475977600"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 34,
        "col3": "Жилищно-бытовая комиссия Администрации муниципального района Кугарчинский район Республики Башкортостан",
        "col4": "453330, Республика Башкортостан, Кугарчинский район, с.Мраково, ул. Ленина, д.15, adm26@bashkortostan.ru, 83478921059"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 35,
        "col3": "Администрация муниципального района Кушнаренковский район Республики Башкортостан",
        "col4": "452230, РБ, Кушнаренковский район, с.Кушнаренково, ул.Октябрьская, 69, Zhilkom-kush@mail.ru, т. 8(34780) 57553"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 36,
        "col3": "Администрация МР Куюргазинский район РБ",
        "col4": "453360, РБ, Куюргазинский район, с. Ермолаево, пр-кт Мира, д. 10, тел. 83475761150, adm28@,bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 37,
        "col3": "Администрация муниципального района Мелеузовский район Республики Башкортостан / отдел опеки и попечительства",
        "col4": "453850, Республика Башкортостан, г. Мелеуз, ул. Воровского, д. 11, e-mail: adm54@bashkortostan.ru, т. +7(34764) 3-14-42 / 453850, Республика Башкортостан, г.Мелеуз, ул. Ленина, д. 150, e-mail: opeka@meleuz.bashkortostan.ru +7(34764)3-07-89"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 38,
        "col3": "Администрация муниципального района Мечетлинский район Республики Башкортостан",
        "col4": "452550, Республика Башкортостан, Мечетлинский район, с. Большеустьикинское, ул. Ленина, д. 20, \nadm29@bashkortostan.ru 8(34770)2-02-99"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 39,
        "col3": "Администрация муниципального района Мишкинский район Республики Башкортостан",
        "col4": "452340, РБ, Мишкинский район, с.Мишкино, ул.Ленина, 89, adm30@bashkortostan.ru, т. 8(34749) 21801"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 40,
        "col3": "Администрация муниципального района Миякинский район РБ (сектор по опеке и попечительству)",
        "col4": "452080, Республика Башкортостан, Миякинский район, с. Киргиз-Мияки, ул. Губайдуллина. д.137, 8(34788)2-27-84, эл.почта opekamiyaki@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 41,
        "col3": "Администрация муниципального района Нуримановский район РБ РБ, Нцримановский район, с. Красная Горка, ул. Советская,д.62",
        "col4": "452440, Республика Башкортостан, Нуримановский район, с. Красная Горка, ул. Советская, д.62, 8 347-76-226-06, reg.sabirova2013@yandex.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 42,
        "col3": "Администрация муниципального района Салаватский район Республики Башкортостан",
        "col4": "452490, Республика Башкортостан, Салаватский район, с.Малояз, ул.Коммунистическая, д.56 33.opeka@bashkortostan.ru 8(34777)21245"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 43,
        "col3": "Администрация муниципального района Стерлибашевский район РБ(орган опеки и попечительства АМР Стерлибашевский район РБ)",
        "col4": "453180, Стерлибашево, ул.Карла Маркса,97\nТел.2-11-16, факс 2-20-03, adm34@bashkortostan.ru\nopeka.stb@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 44,
        "col3": "Администрация муниципального района Стерлитамакский район Республики Башкортостан (орган опеки и попечительства)",
        "col4": "453124, Республика Башкортостан, г.Стерлитамак, ул.Худайбердина, д.16 35.opeka@bashkortostan.ru 8 3473 25 93 21"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 45,
        "col3": "Администрация муниципального района Татышлинский район (сектор по опеке и попечительству)",
        "col4": "452830, РБ Татышлинский район, с. Верхние Татышлы, ул. Ленина, д.75 орека-tatroo@yandex.ru 8(34778)2-11-33"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 46,
        "col3": "Администрация муниципального района Туймазинский район РБ (жилищный отдел АМР Туймазинский район РБ)",
        "col4": "452755, РБ, г. Туймазы, ул. Островского, д. 34, gilotdel2011@mail.ru тел.: 8(34782)7-30-14, 7-32-44"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 47,
        "col3": "Администрация муниципального района Уфимский район Республики Башкортостан",
        "col4": "г. Уфа, ул. Достоевского, 98, opeka_ur@mail.ru, (347)246-14-02"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 48,
        "col3": "Администрация муниципального района Учалинский район Республики Башкортостан (жилищный отдел)",
        "col4": "453700, Республика Башкортостан, Учалинский район, г. Учалы, ул. К. Маркса, д. 20, 61.zhilish@bashkortostan.ru, тел.: 6(34791)6-21-90"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 49,
        "col3": "Администрация муниципального района Федоровский район Республики Башкортостан",
        "col4": "453280, Республика Башкортостан, Федоровский район, с. Федоровка, ул. Ленина, д.48, opeka.fedorovka@mail.ru, тел. 8 (34746) 2-73-21"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 50,
        "col3": "Администрация муниципального района Федоровский район Республики Башкортостан",
        "col4": "453800, Республика Башкортостан, Хайбуллинский район, с. Акъяр, пр. С.Юлаева, д. 45, e-mail: haibopeka@mail.ru, тел.: +7(34758)2-26-90"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 51,
        "col3": "Администрация муниципального района Чекмагушевский район Республики Башкортостан",
        "col4": "452200 Республика Башкортостан, Чекмагушевский район, с. Чекмагуш, ул.Ленина, д.55, e-mail: chekmag.opeka@yandex.ru 8(34796)3-21-63"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 52,
        "col3": "Администрация муниципального района Чишминский район (отдел опеки и попечительства)",
        "col4": "452170, р.п.Чишмы, ул.Кирова, д.17, к.8, тел (834797)2-29-95, opekachishmi@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 53,
        "col3": "Администрация МР Шаранский район (сектор опеки и попечительства",
        "col4": "452630, РБ, Шаранский район, с.Шаран, ул. Пролетарская. д. 1 тел 34769 2-20-62"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 54,
        "col3": "Администрация муниципального района Янаульский район Республики Башкортостан (отдел по опеке и попечительству)",
        "col4": "452800, РБ, г.Янаул, пер.Малышева, д.4Б, ms,opeka@bk,ru, тел.:8(34760)51824"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 55,
        "col3": "Администрация ГО г.Агидель РБ",
        "col4": "г.Агидель Цветочный бульвар,д. 2 43.jil@bashkortostan.ru 28215"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 56,
        "col3": "Администрация городского округа город Кумертау Республики Башкортостан (отдел опеки и попечительство)",
        "col4": "453300, Республика Башкортостан, г.Кумертау, ул.Ленина, д.18, \nadm52@bashkortostan.ru (52.opeka2@bashkortostan.ru), 83476143327"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 57,
        "col3": "Администрация ЗАТО Межгорье Республики Башкортостан (Отдел по учету и распределению жилья Администрации ЗАТО Межгорье Республики Башкортостан)",
        "col4": "453571, Республика Башкортостан, г. Межгорье, ул. 40 лет Победы, д. 60, adm53@bashkoptostan.ru , тел. 8(34781)2-24-77, 8(34781)2-15-00"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 58,
        "col3": "Администрация городского округа город Нефтекамск Республики Башкортостан",
        "col4": "452680, г. Нефтекамск, пр-кт. Комсомольский, д. 25, тел. 8(34783)4-92-46, adm55@bashkortostan.ru; отдел опеки и попечительства - г. Нефтекамск, ул. Ленина, д. 13 А, тел. 8(34783)4-03-48, neft_opeka@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 59,
        "col3": "Администрация городского округа город Октябрьский Республики Башкортостан (отдел опеки и попечительства администрации)",
        "col4": "452600, г. Октябрьский, ул. Чапаева, д. 23, тел. 8(34767)61610, opeka61610@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 60,
        "col3": "Администрация городского округа город Салават Республики Башкортостан (отдел опеки и попечительства)",
        "col4": "453261, г. Салават, ул. Ленина, д. 2 , adm57@bashkortostan.ru/opeka@salavat.bashkortostan.ru тел. 8(3476)32-64-74"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 61,
        "col3": "Администрация г.о.г.Сибай (Отдел опеки и попечительства)",
        "col4": "453830,Республика Башкортостан,город Сибай,улица Ленина,д.12,каб.203;"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 62,
        "col3": "Администрация городского округа город Стерлитамак Республики Башкортостан",
        "col4": "453100, г.Стерлитамак, пр-кт Октября, д.32, adm59@bashkortostan.ru, 8(3473)24-18-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 63,
        "col3": "1) Администрация Демского района ГО г. Уфа \n2) Администрация Калининского района ГО г. Уфа  \n3) Администрация Кировского района ГО г. Уфа \n4) Администрация Ленинского района ГО г. Уфа \n5) Администрация Октябрьского района ГО г. Уфа  \n6) Администрация Орджоникидзевского района ГО г. Уфа \n7) Администрация Советского района ГО г. Уфа ",
        "col4": "1) г. Уфа, ул. Ухтомского, 3 surz_dema@mail.ru, 8 (347)281-28-33, 8(347)281-16-74 \n2)  г. Уфа, ул. Орджоникидзе, 3 kalinaourz@mail.ru, 8(347)263-34-49, 8(347)263-35-29 \n3) г. Уфа, ул. Пушкина, д. 85, Gafarova.rm@ufacity.info 8(347)279-90-10, 8(347)279-90-19\n4)  г. Уфа, ул. Мустая Карима, д. 19, regina2015@mail.ru 8(347)229-04-00, 8(347)229-04-06 \n5) г. Уфа, ул. Комсомольская, д. 142/1, oktadmgil@mail.ru, 2373240@gmail.com, 8(347)237-40-00, 8(347)237-47-97 \n6) г. Уфа, ул. Мира, д. 6, jil_org@mail.ru, 8(347)242-03-11, 8(347)242-02-72 \n7)  г. Уфа, ул. Революционная, д.111, sovadm12@bk.ru, 8(347)273-33-09, 8(347)273-15-38\n\n\n\n\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Республики Бурятия",
        "col4": "Республика Бурятия, г. Улан-Удэ, ул. Гагарина, д. 10, 670034/  mszn@govrb.ru /83012445039"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 1,
        "col3": "Министерство труда и социального развития Республики Дагестан",
        "col4": "367015, Республика Дагестан, г.Махачкала, ул.Абубакарова, 117;\n mintrud@e-dag.ru\n+7 (8722) 64-15-04"
    },
    {
        "col1": "Республика Ингушетия",
        "col2": 1,
        "col3": "Министерство образования и науки",
        "col4": "dopotdel@mail.ru; 8(8732) 22-17-93; 386102  Республика Ингушетия, г. Назрань,ул. Московская, 37"
    },
    {
        "col1": "Республика Калмыкия",
        "col2": 1,
        "col3": "-",
        "col4": "-",
    },
    {
        "col1": "Республика Карелия",
        "col2": 1,
        "col3": "Администрация Беломорского муниципального округа",
        "col4": "186500, г. Беломорск, ул. Ленинская, д. 9; (81437) 5-10-50 (приемная), e-mail: belkaradm@belomorsk-mo.ru; (81437) 5-15-84, e-mail: belopeka@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/belomorsk/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 2,
        "col3": "Администрация Калевальского национального муниципального района",
        "col4": "186910, Республика Карелия, пгт. Калевала, ул. Советская, д. 11; (81454) 4-11-05 (приемная); (81454) 4-18-77; e-mail: kalevadm@onego.ru; anna.kenieva@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/kaleval a/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 3,
        "col3": "Администрация Кемского муниципального района",
        "col4": "186610, г. Кемь, просп. Пролетарский, д. 30; (81458) 7-04-33 (приемная); e-mail: admikem@mail.ru; 8-964-317-80-17, (81458) 2-20-03, e-mail: soc.opeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/kem/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 4,
        "col3": "Администрация Кондопожского муниципального района",
        "col4": "186220, г. Кондопога, пл. Ленина, д. 1; (81451) 7-94-52 (приемная); e-mail: kondadm@kmr10.ru; (81451) 7-99-79, 8-964-317-83-58; paukova@kmr10.ru; https://gov.karelia.ru/power/local_govern ment/kondopoga/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 5,
        "col3": "Администрация Костомукшского городского округа",
        "col4": "186931, г. Костомукша ул. Строителей, д. 5; (81459) 5-10-10 (приемная); e-mail: adm-kos@msu.kostomuksha-rk.ru; 8-911-660-89-59, 8-911-660-15-68, 8-911-430-25-52 e-mail: opeka2kgo@list.ru; https://gov.karelia.ru/power/local_gov ernment/kostomuksha/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 6,
        "col3": "Администрация Лахденпохского муниципального района",
        "col4": "186730, г. Лахденпохья, ул. Советская, д.7-А; (81450) 4-54-98 (приемная), e-mail: amcylah@onego.ru; (81450) 46-3-92;’ e-mail: bratchikova-mn@lah-mr.ru; https://gov.karelia.ru/power/local_gov ernment/lah/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 7,
        "col3": "Администрация Лоухского муниципального района",
        "col4": "186660, п. Лоухи, ул. Советская, д. 27; (81439) 5-10-17 (приемная); e-mail: louhiadm@yandex.ru; (81439) 5-17-43, e-mail: opeca.louhi@mail.ru; https://gov.karelia.ru/power/local_gov ernment/louhiadm/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 8,
        "col3": "Администрация Медвежьегорского муниципального района",
        "col4": "186300, г. Медвежьегорск ул. Кирова, д. 7; (81434) 5-15-55 (приемная); e-mail: medgora.msu@onego.ru (81434) 5-26-80, 8-902-772-70-15, e-mail: medgora.opeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/medgora/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 9,
        "col3": "Администрация Муезерского муниципального района",
        "col4": "186960, п. Муезерский, ул. Октябрьская, д. 28; (81455) 3-36-30 (приемная); e-mail: mueadmin@inbox.ru; 8-921-526-60-80, (81455) 338-49, e-mail: mue.opeka@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/muezersky/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 10,
        "col3": "Администрация Олонецкого национального муниципального района",
        "col4": "186000, г. Олонец, ул. Свирских дивизий, д. 1; (81436) 4-15-06; e-mail: administr@onego.ru; 8-964-317-81-16; e-mail: olonopeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/olon/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 11,
        "col3": "Администрация Петрозаводского городского округа",
        "col4": "185001, г. Петрозаводск, просп. Ленина, д. 2; (8142) 71-33-00 (приемная), e-mail: adm@petrozavodsk-mo.ru; (8142) 71-35-52, 71-34-37, 71-34-38, 71-34-89; e-mail: opeka@petrozavodsk-mo.ru; https://gov.karelia.ru/power/local_gov ernment/petrozavodsk/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 12,
        "col3": "Администрация Питкярантского муниципального округа",
        "col4": "186810, г. Питкяранта, ул. Ленина, д. 43; (81433) 4-11-53 (приемная); e-mail: pitkaranta@onego.ru; 8-964-317-50-95; e-mail: opeka.apmr@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/pit/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 13,
        "col3": "Администрация Прионежского муниципального района",
        "col4": "185005, г. Петрозаводск, ул. Правды, д. 14, (8142) 57-81-68 (приемная), e-mail: adm@prionego.ru; 8-902-770-15-04, 8-902-770-12-45; e-mail: ivanova@prionego.ru; https://gov.karelia.ru/power/local_gov ernment/prione go/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 14,
        "col3": "Администрация Пряжинского национального муниципального района",
        "col4": "186601, п. Пряжа, ул. Советская, д. 61; (81456) 3-12-08 (приемная); e-mail: priagad@onego.ru; (81456) 3-21-86, e-mail: opekapr@bk.ru; https://gov.karelia.ru/power/local_gov ernment/pryazha/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 15,
        "col3": "Администрация Пудожского муниципального района",
        "col4": "186150, г. Пудож, ул. Ленина, д. 90; (81452) 5-17-33 (приемная); e-mail: pudogadm@pudogadm.ru; (81452) 5-10-31, e-mail: opeka.karelia@yandex.ru https://gov.karelia.ru/power/local_gov ernment/pudog/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 16,
        "col3": "Администрация Сегежского муниципального округа",
        "col4": "186420, г. Сегежа, ул. Ленина, д. 9-А; (81431) 4-24-24 (приемная), e-mail: ud1@segadmin.onego.ru; (81431) 4-25-74, e-mail: opeka-seg@yandex.ru https://gov.karelia.ru/power/local_gov ernment/seg/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 17,
        "col3": "Администрация Сортавальского муниципального округа",
        "col4": "186750, г. Сортавала, ул. Кирова, д. 11; (81430) 4-53-42 (приемная), e-mail: sort_org_otd@onego.ru; (81430) 4-81-85, e-mail: opeka.sortavala@mail.ru https://gov.karelia.ru/power/local_gov ernment/ sortavala/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 18,
        "col3": "Администрация Суоярвского муниципального округа",
        "col4": "186870, г. Суоярви, ул. Шельшакова, д. 6; (81457) 5-14-50 (приемная), e-mail: suodistrict@onego.ru; (81457) 5-18-17, 8-964-317-83-16, e-mail: opeka suo@mail.ru; https://gov.karelia.ru/power/local_gov ernment/ suoj arvi/"
    },
    {
        "col1": "Республика Коми",
        "col2": 1,
        "col3": "МО ГО \"Сыктывкар\"",
        "col4": "ул. Бабушкина, д. 22, г. Сыктывкар, Республика Коми, 167000 \nPovalyaeva-EN@sykt.rkomi.ru\n8 8212 21 66 08"
    },
    {
        "col1": "Республика Коми",
        "col2": 2,
        "col3": "МО МО \"Ухта\"",
        "col4": "ул. Бушуева, д. 11, г. Ухта, Республика Коми, 169300\nkumi012345@mail.ru\n8 8216 74 63 41"
    },
    {
        "col1": "Республика Коми",
        "col2": 3,
        "col3": "МО МО \"Воркута\"",
        "col4": "пл. Центральная, д. 7, г. Воркута, Республика Коми, 169900\npolikarpova.NG@mayor.vorkuta.ru\n8 8215 15 56 54"
    },
    {
        "col1": "Республика Коми",
        "col2": 4,
        "col3": "МО МО \"Инта\"",
        "col4": "ул. Горького, д. 16, г. Инта, Республика Коми, 169840\nna.docis@inta.rkomi.ru\n8 8214 56 49 21"
    },
    {
        "col1": "Республика Коми",
        "col2": 5,
        "col3": "МО МР \"Печора\"",
        "col4": "ул. Ленинградская, д. 15, г. Печора, Республика Коми, 169600\njilotdelpechora@mail.ru\n8 8214 27 38 13"
    },
    {
        "col1": "Республика Коми",
        "col2": 6,
        "col3": "МО МО \"Усинск\"",
        "col4": "ул. Ленина, д. 13, г. Усинск, Республика Коми, 169710\ni.s.kuzmina@usinsk.rkomi.ru\n8 8214 42 81 30 (доб. 136)"
    },
    {
        "col1": "Республика Коми",
        "col2": 7,
        "col3": "МО МО \"Вуктыл\"",
        "col4": "ул. Комсомольская, д. 14, г. Вуктыл, Республика Коми, 169570\nsocialpolitika@mail.ru\n8 8214 62 22 62 (доб. 26)"
    },
    {
        "col1": "Республика Коми",
        "col2": 8,
        "col3": "МО МР \"Сосногорск\"",
        "col4": "ул. Зои Космодемьянской, д. 72, г. Сосногорск, Республика Коми, 169500\nkui.sosn@mail.ru\n8 8214 95 62 45"
    },
    {
        "col1": "Республика Коми",
        "col2": 9,
        "col3": "МО МР \"Сыктывдинский\"",
        "col4": "ул. Домны Каликовой, д. 62, с. Выльгорт, Республика Коми, 168220\nv.a.vaneeva@syktyvdin.rkomi.ru\n8 8213 07 21 19"
    },
    {
        "col1": "Республика Коми",
        "col2": 10,
        "col3": "МО МР \"Сысольский\"",
        "col4": "ул. Советская, д. 35, с. Визинга,  Сысольский р-н, Республика Коми, 168100\nziyatdinova_78@mail.ru\n8 8213 19 15 96"
    },
    {
        "col1": "Республика Коми",
        "col2": 11,
        "col3": "МО МР \"Койгородский\"",
        "col4": "ул. Советская, д. 30, с. Койгородок, Койгородский р-н, Республика Коми, 168170\ninna.abih@yandex.ru\n8 8213 29 12 92"
    },
    {
        "col1": "Республика Коми",
        "col2": 12,
        "col3": "МО МР \"Прилузский\"",
        "col4": "ул. Мира, д. 76, с. Объячево, Прилузский р-н, Республика Коми, 168130\nm.y.tugolukova@priluzie.rkomi.ru\n8 8213 32 25 61"
    },
    {
        "col1": "Республика Коми",
        "col2": 13,
        "col3": "МО МР \"Корткеросский\"",
        "col4": "ул. Советская, д. 225, с. Корткерос, Корткеросский р-н, Республика Коми, 168020\ngkhkortkeros@mail.ru\n8 8213 69 24 95"
    },
    {
        "col1": "Республика Коми",
        "col2": 14,
        "col3": "МО МР \"Усть-Куломский\"",
        "col4": "ул. Советская, д. 37, с. Усть-Кулом, Усть-Куломский р-н, Республика Коми, 168060\nosp.uk@yandex.ru\n8 8213 79 35 57"
    },
    {
        "col1": "Республика Коми",
        "col2": 15,
        "col3": "МО МР \"Троицко-Печорский\"",
        "col4": "ул. Ленина, д. 2, пгт. Троицко-Печорск, Троицко-Печорский р-н, Республика Коми, 169420\notdel.zemel@mail.ru\n8 8213 89 75 09"
    },
    {
        "col1": "Республика Коми",
        "col2": 16,
        "col3": "МО МР \"Усть-Вымский\"",
        "col4": "ул. Центральная, д. 112, с. Айкино, Усть-Вымский р-н, Республика Коми, 169040\nkan_159632@mail.ru\n8 8213 42 82 13"
    },
    {
        "col1": "Республика Коми",
        "col2": 17,
        "col3": "МО МО \"Княжпогостский\"",
        "col4": "ул. Дзержинского, д. 81, г. Емва, Республика Коми, 169200\nuoemva@yandex.ru\n8 8213 92 33 25"
    },
    {
        "col1": "Республика Коми",
        "col2": 18,
        "col3": "МО МР \"Удорский\"",
        "col4": "ул. Центральная, д. 144, с. Кослан, Удорский р-н, Республика Коми, 169240\nzv@udora.info\n8 8213 53 30 34"
    },
    {
        "col1": "Республика Коми",
        "col2": 19,
        "col3": "МО МР \"Ижемский\"",
        "col4": "ул. Советская, д. 45, с. Ижма, Ижемский р-н, Республика Коми, 169460\njkhiks@mail.ru\n8 8214 09 41 07 (доб. 105)"
    },
    {
        "col1": "Республика Коми",
        "col2": 20,
        "col3": "МО МР \"Усть-Цилемский\"",
        "col4": "ул. Новый квартал, д. 11а, с. Усть-Цильма, Усть-Цилемский р-н, Республика Коми, 169480\ne.n.ostashova@ust-cilma.rkomi.ru\n8 8214 19 12 55"
    },
    {
        "col1": "Республика Крым",
        "col2": 1,
        "col3": "Министерство образования, науки и молодежи Республики Крым",
        "col4": "295000, Республика Крым, г. Симферополь, пер. Совнаркомовский, 3\nedu@monm.rk.gov.ru\n+7(3652) 27-52-32 "
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 1,
        "col3": "Администрация городского округа «Город Йошкар-Ола»",
        "col4": "424001, г. Йошкар-Ола, Ленинский проспект, 27 / admiola@i-ola.ru / (8362) 41-40-37"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 2,
        "col3": " Администрация городского округа \"Город Волжск\"",
        "col4": "425000, город Волжск, Коммунистическая ул., д.1 / volzhsk.adm@mari-el.gov.ru / (83631) 6-22-58"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 3,
        "col3": "Администрация городского округа «Город Козьмодемьянск»",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / kozm.adm@mari-el.gov.ru / (83632) 7-12-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 4,
        "col3": "Администрация Волжского муниципального района",
        "col4": "425000, г. Волжск, ул. Советская, д. 29 / admrvolzsk@mail.ru / (83631) 6-35-75"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 5,
        "col3": "Администрация Горномарийского муниципального района",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / gorn.adm@mari-el.gov.ru; adm-gorn2006@yandex.ru / (83632) 7-11-53"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 6,
        "col3": "Администрация Звениговского муниципального района",
        "col4": "425060, г. Звенигово, ул. Ленина, д. 39 / zven.adm@mari-el.gov.ru / (83645) 7-17-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 7,
        "col3": "Килемарский муниципальный округ Республики Марий Эл",
        "col4": "425270, Килемарский район, пгт Килемары, ул. Садовая 55 / adm-kilemary@yandex.ru / (83643) 2-14-35"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 8,
        "col3": "Администрация Куженерского муниципального района",
        "col4": "425550, п. Куженер, ул. Кирова 14 / kuzhen.adm@mari-el.gov.ru / (83637) 9-15-80"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 9,
        "col3": "Администрация Мари-Турекского муниципального района",
        "col4": "425500, п. Мари-Турек, ул.Парковая, д. 7 / mturek.adm@mari-el.gov.ru / (83634) 9-71-00"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 10,
        "col3": "Администрация Медведевского муниципального района",
        "col4": "425200, Медведевский район, пгт Медведево, Советская ул., д.20 / MED_ADM@MAIL.RU / (8362) 58-28-50"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 11,
        "col3": "Администрация Моркинского муниципального района",
        "col4": "425120, пос. Морки, ул.Советская, 14 / morki.adm@mari-el.gov.ru / (83635) 9-16-12"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 12,
        "col3": "Администрация Новоторъяльского муниципального района",
        "col4": "425430, п. Новый Торъял, ул. Культуры, д.33 / admtoryal@mail.ru / (83636) 9-15-51"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 13,
        "col3": "Администрация Оршанского муниципального района",
        "col4": "425250, пгт. Оршанка, ул. Советская, 109 / orshan.adm@mari-el.gov.ru / (83641) 2-34-41"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 14,
        "col3": "Администрация Параньгинского муниципального района",
        "col4": "425570, Параньгинский район, пгт Параньга, ул. Новая, д. 4 / paranga.adm@mari-el.gov.ru / (83639)4-72-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 15,
        "col3": "Администрация Сернурского муниципального района",
        "col4": "425450, Сернурский район, пгт. Сернур, ул. Комсомольская, д. 10 / sernur12_adm@mail.ru / (83633) 9-73-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 16,
        "col3": "Администрация Советского муниципального района",
        "col4": "425400, пгт. Советский, ул. Свердлова, д. 8 / sovetsk_adm@mail.ru / (83638) 9-43-15"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 17,
        "col3": "Администрация Юринского муниципального района",
        "col4": "425370, Юринский район, пгт.Юрино, ул. Красная площадь, д.23 / yurino.adm@mari-el.gov.ru / (83644) 3-22-24"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 1,
        "col3": "Администрация Ардатовского муниципального района",
        "col4": "тел. 8(834)31-32-193 , эл.адрес: zhilkomardt1@mail.ru, почтовый адрес: Республика Мордовия, г. Ардатов, Комсомольская, 121;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 2,
        "col3": "Администрация Атюрьевского муниципального район",
        "col4": "тел. 8(83454)2-13-46, эл. адрес: admaturevo@aturevo.e-mordovia.ru, почтовый адрес: Республика Мордовия, Атюрьевский район, с. Атюрьево, пер. Парковый, д. 1;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 3,
        "col3": "Администрация Атяшевского муниципального района",
        "col4": "тел. 8(834)34-2-30-57, эл.адрес: uoat@e-mordovia.ru, почтовый адрес: 431800, Республика Мордовия, р.п. Атяшево, ул. Центральная, д.8;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 4,
        "col3": "Администрация Больше-Березниковского муниципального район",
        "col4": "тел. 8 (83436) 2-30-16, эл.адрес: bberezadm1@bberezniki.e-mordovia.ru, почтовый адрес: 431750, Республика Мордовия, Большие Березники, ул. Московская, д. 25;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 5,
        "col3": "Администрация Больше-Игнатовского муниципального района",
        "col4": "тел. 8(834)42- 2-14-80, эл.адрес: TrifonovaEl@bignatovo.e-mordovia.ru; эл. адрес: otdel.arkh@yandex.ru, почтовый адрес: 431670, Республика Мордовия, Большеигнатовский район, с. Большое Игнатово, ул. Советская, д. 40;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 6,
        "col3": "Администрация Дубенского муниципального района",
        "col4": "тел. 8(834)47-2-18-89, эл.адрес: dub-opeka2e-mordovia@mail.ru, stroidubur@mail.ru, почтовый адрес: 431770, Республика Мордовия, Дубенский район, с. Дубенки, ул. Денисова, д. 4;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 7,
        "col3": "Администрация Ельниковского муниципального района",
        "col4": "тел. 8(834)44-2-25-67, эл.адрес: opeka.eln@elniki.e-mordovia.ru, почтовый адрес: 431370, Республика Мордовия, Ельниковский район, с. Ельники, ул. Ленина, д. 18;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 8,
        "col3": "Администрация Зубово-Полянского муниципального района",
        "col4": "тел. 8(834)58-2-10-96, эл. адрес: jurist_adm_zbp@mail.ru, почтовый адрес: 431110, Республика Мордовия, Зубово-Полянский район, р.п. Зубова Поляна, ул. Новикова Прибоя, 4;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 9,
        "col3": "Администрация Инсарского муниципального района",
        "col4": "тел.  8(834)49-2-10-06, эл.адрес: insar.opeka@e-mordovia.ru, почтовый адрес: 431430, Республика Мордовия, г. Инсар, ул. Гагарина, д. 28;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 10,
        "col3": "Администрация Ичалковского муниципального района",
        "col4": "тел. 8(834)33-01-86, эл. адрес: ich_administration@ichalki.e-mordovia.ru, почтовый адрес: Республика Мордовия, Ичалковский район, с. Кемля, ул. Советская, д. 62;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 11,
        "col3": "Администрация Кадошкинского муниципального района",
        "col4": "тел.  8(834)48-2-35-59, эл.адрес: kadoshkinouo@e-mordovia.ru, почтовый адрес: 431900, Республика Мордовия, Кадошкинский район, п. Кадошкино, ул. Заводская, д. 33;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 12,
        "col3": "Администрация Ковылкинского муниципального района",
        "col4": "тел. 8(834)53-2-25-83, эл.адрес: opeka.kov@e-mordovia.ru, почтовый адрес: Республика Мордовия, г. Ковылкино, ул. Большевистская, д. 23;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 13,
        "col3": "Администрация Кочкуровский муниципального района",
        "col4": "тел.  8(834)39-2-11-88, эл.адрес opekakmr@kochkurovo.e-mordovia.ru, почтовый адрес: 431580, Республика Мордовия, Кочкуровский район, с. Кочкурово, ул. Советская, д. 16;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 14,
        "col3": "Администрация Краснослободского района",
        "col4": "тел. 8(834)43-2-13-10, эл.адрес vasjutochkinva@krasnoslobodsk.e-mordovia.ru, почтовый адрес: 431260, Республика Мордовия, г. Краснослободск, ул. Интернациональная, д. 64;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 15,
        "col3": "Администрация Лямбирского муниципального района",
        "col4": "тел. 8 (834-41) 2-90-98, эл. адрес: opekalyambir@e-mordovia.ru, , эл. адрес: lyambirzhkx@mail.ru, почтовый адрес: 431510, Республика Мордовия, Лямбирский район, с. Лямбирь, ул. Ленина, 11;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 16,
        "col3": "Администрация Ромодановского муниципального район",
        "col4": "тел. 8(834)38-2-07-23, эл.адрес: voevoda@e-mordovia.ru, почтовый адрес: 431600 Республика Мордовия, Ромодановский район, п. Ромоданово, ул. Ленина, д. 138;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 17,
        "col3": "Администрация Рузаевского муниципального района",
        "col4": "тел. 8(834)51-4-08-48, эл.адрес baimashkina@bk.ru, почтовый адрес: 431440, Республика Мордовия, Рузаевка, ул. Ленина, д.61;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 18,
        "col3": "Администрация городского округа Саранск",
        "col4": "тел. 8(834)2-47-38-54, эл.адрес saransk-dsp@e-mordovia.ru, 430005, Республика Мордовия, г. Саранск, ул. Советская, д. 30;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 19,
        "col3": "Администрация Старошайговского муниципального район",
        "col4": "тел. 8(834)32-2-15-72, эл.адрес: stshopeka@staroshajgovo.e-mordovia.ru, 431540, Республика Мордовия, Старошайговский район, с. Старое Шайгово, ул. Ленина, д. 11;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 20,
        "col3": "Администрация Темниковского муниципального района",
        "col4": "тел. 8(834)45-2-60-15, эл.адрес opekatmn@e-mordovia.ru, почтовый адрес: 431220, Республика Мордовия, г. Темников, ул.Кирова, д. 26;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 21,
        "col3": "Администрация Теньгушевского муниципального района",
        "col4": "тел.  8(834)46-2-90-61, эл. адрес: tengushevskoeuo@e-mordovia.ru;\n"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 22,
        "col3": "Администрация Торбеевского муниципального района",
        "col4": "тел.  8(834)56-2-00-11, эл.адрес torbeevskoeuo@e-mordovia.ru, почтовый адрес: 431030, Республика Мордовия, п. Торбеево, ул. Карла Маркса, 7;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 23,
        "col3": "Администрация Чамзинского муниципального района",
        "col4": "тел. 8(834)37-2-12-22, эл. почта: orgotdel113@mail.ru, почтовый адрес: 431700, Республика Мордовия, Чамзинский район, р.п. Чамзинка, ул. Победы, д.1."
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 1,
        "col3": "Министерство труда и социального развития Республики Саха (Якутия)",
        "col4": "677000, г. Якутск,\nул. Петра Алексеева, 6/1,\nmintrud@sakha.gov.ru\n/8(4112)508-058"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 1,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Алагирскому району",
        "col4": "РСО-Алания, 363426, г. Алагир., ул. Комсомольская, 28, тел. 8(86731) 36405  Email:uszn-alagir@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 2,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Ардонскому  району",
        "col4": "РСО-Алания, 363330, г. Ардон, ул. Пролетарская, 71, тел. 8(86732) 33483, Email: uszn-ardon@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 3,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Дигорскому  району",
        "col4": "РСО-Алания, 363410, г. Дигора, ул. Энгельса, 47, 8(86733) 91960; Email:uszn-digora@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 4,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Ирафскому району",
        "col4": "РСО-Алания, 363500, с. Чикола, ул. Макоева, 19. 8(86734)31380 Email: uszn-iraf@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 5,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Кировскому району",
        "col4": "РСО-Алания,363600,  с.Эльхотово,  ул. Зортова, 20, 8(86735)51056. Email: uszn-kirov@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 6,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Моздокскому району",
        "col4": "РСО-Алания, 363760, г. Моздок, ул. Проезд Юбилейный,6    8(86736)24696  Email: uszn-mozdok@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 7,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Правобережному району",
        "col4": "РСО-Алания, 363020, г. Беслан, ул. Плиева, 16, 8(86737)31564, Email: uszn-prav@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 8,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Пригородному району",
        "col4": "РСО-Алания, 363130, с. Октябрьское, ул. Епхиева, 50, 8(86738)22202  Email: uszn-prig@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 9,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Иристонскому МО г. Владикавказа",
        "col4": "РСО-Алания, 362025, г. Владикавказ, ул. Ватутина, 17  8(8672)536486   Email: uszn-iriston@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 10,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Затеречному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362008, г. Владикавказ, ул. Коцоева, 17  8(8672)256298   Email: uszn-zater@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 11,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Промышленному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362002, г. Владикавказ, ул. Чкалова,4  8(8672)766474   Email: uszn-prom@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 12,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Северо-Западному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362031 г. Владикавказ, ул. Леонова,4  8(8672)746131   Email: uszn-sevzap@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Татарстан",
        "col2": 1,
        "col3": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Mon@tatar.ru, 8843 294-95-66",
        "col4": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Mon@tatar.ru, 8843 294-95-66"
    },
    {
        "col1": "Республика Тыва",
        "col2": 1,
        "col3": "Министерство образования Республики Тыва",
        "col4": "Республика Тыва, \nг. Кызыл, ул. Ленина, 39,  \nopeka.2022@mail.ru\nраб. номер \n8 (39422) 3-00-21\n"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 1,
        "col3": "Администрация города Абакана",
        "col4": "ул. Щетинкина, д. 10, г. Абакан, 655017, тел.: 8 (3902) 223-791, e-mail: abakanadm@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 2,
        "col3": "Администрация города Абазы",
        "col4": "ул. Ленина, д. 20а, г. Абаза, 655750, тел.: 8 (39047) 2-30-61, e-mail: abm-abaza@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 3,
        "col3": "Администрация города Саяногорска",
        "col4": "микр., Советский, д. 1, г. Саяногорск, 655602, тел.: 8 (39042) 2-02-00, e-mail: sayanogorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 4,
        "col3": "Администрация города Сорска",
        "col4": "ул. Кирова, д. 3, г. Сорск, 655111, тел.: 8 (39033) 2-43-55, e-mail: aosorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 5,
        "col3": "Администрация города Черногорска",
        "col4": "ул. Советская, д. 66, г. Черногорск, 655154, тел.: 8 (39031) 2-25-72, e-mail: adm.ch.22572@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 6,
        "col3": "Администрация Алтайского района",
        "col4": "ул. Ленина, д. 74, с. Белый Яр, Алтайский район,  тел.: 8 (39041) 2-12-53, e-mail: altay@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 7,
        "col3": "Администрация Аскизского района",
        "col4": "ул. Суворова, д. 2, с. Аскиз, Аскизский район, 655700,  тел.: 8 (39045) 9-13-31, e-mail: mo-askiz@mail.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 8,
        "col3": "Администрация Бейского района",
        "col4": "ул. Площадь Советов, д. 20, с. Бея, Бейский район, 655770,  тел.: 8 (39044) 3-20-00, e-mail: mo-beya@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 9,
        "col3": "Администрация Боградского района",
        "col4": "ул. Новая, д. 10, с. Боград, Боградский район, 655340,  тел.: 8 (39034) 9-12-56, e-mail: admbograd@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 10,
        "col3": "Администрация Орджоникидзевского района",
        "col4": "ул. Кирова, д. 16, с. Копьево, Орджоникидзевский район, 655250,  тел.: 8 (39036) 2-17-04, e-mail: amo-ordgo@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 11,
        "col3": "Администрция Таштыпского района",
        "col4": "ул. Ленина, д. 35, с. Таштып, Таштыпский район, 655740,  тел.: 8 (39046) 2-14-42, e-mail: amotash@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 12,
        "col3": "Администрация Усть-Абаканского района",
        "col4": "ул. Рабочая, д. 9, с. Усть-Абакан, Усть-Абаканский район, 655100,  тел.: 8 (39032) 2-16-56, e-mail: oo_ua@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 13,
        "col3": "Администрацитя Ширинского района",
        "col4": "ул. Октябрьская, д. 79, с. Шира, Ширинский район, 655200,  тел.: 8 (39035) 9-12-10, e-mail: adminshira@r-19.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 1,
        "col3": "Администрация Азовского района (Азовский районный отдел образования)",
        "col4": "346780, Россия, Ростовская область, г. Азов, ул. Мира, д. 102, каб. 4. 8 863 42 6 60 40 aar-doc@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 2,
        "col3": "Управление образования Администрации Аксайского района",
        "col4": "346720, Ростовская область, г. Аксай, ул. Ломоносова, 1                                 rmk@aksay.ru                              8 (86350) 5-51-07"
    },
    {
        "col1": "Ростовская область",
        "col2": 3,
        "col3": "Администрация Багаевского района",
        "col4": "346610 Ростовская область, Багаевский район, ст.Багаевская, ул.Подройкина, д.10, тел. 8(86357) 33-2-44,  е-mail: bagadm@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 4,
        "col3": "Служба реализации жилищных программ Администрации Белокалитвинского района",
        "col4": "347042, Ростовская область, г. Белая Калитва, ул. Чернышевского, д. 8, sektor25645@mail.ru, 8(86383)2-56-45"
    },
    {
        "col1": "Ростовская область",
        "col2": 5,
        "col3": "Администрация Боковского района",
        "col4": "346250 Ростовская область, Боковский район, станица Боковская, переулок Теличенко, 32/ bokovsk@donland.ru/ 8(86382) 3-14-53 "
    },
    {
        "col1": "Ростовская область",
        "col2": 6,
        "col3": "Администрация Верхнедонского района ",
        "col4": "346170, ул. Матросова, д. 12, ст-ца Казанская, Верхнедонской район, Ростовская область, kazanka@donland.ru 88636431262"
    },
    {
        "col1": "Ростовская область",
        "col2": 7,
        "col3": "Администрация Веселовского района (Отдел образования Администрации Веселовского района)",
        "col4": "347781, Ростовская область, Веселовский район, п. Веселый, пер. Базарный, д.5 E-mail: veselovsky@donland.ru 8(86358)6-15-86"
    },
    {
        "col1": "Ростовская область",
        "col2": 8,
        "col3": "Отдел образования администрации Волгодонского района Ростовской области",
        "col4": "347350, Ростовская область, Волгодонской район, ст. Романовская, ул. Почтовая, 9, телефон 88639470390, электронная почта: pomoo@vttc.donpac.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 9,
        "col3": "Администрация Дубовского района Ростовская область",
        "col4": "347410, Ростовская область, Дубовский район, с. Дубовское, пл. Павших Борцов,1, Adm_dub@donland.ru, 8(86377)2-02-70 доб. 129"
    },
    {
        "col1": "Ростовская область",
        "col2": 10,
        "col3": "Администрация Егорлыкского района Ростовской области",
        "col4": "347661, Ростовская область, Егорлыкский район, ст. Егорлыкская, ул. Мира, 90, admegorlyk@donland.ru +7(86370)21-3-15"
    },
    {
        "col1": "Ростовская область",
        "col2": 11,
        "col3": "Администрация Заветинского района",
        "col4": "347430 Ростовская область, Заветинский район,село Заветное, ул. Ломоносова, д.24,  zavetnoe61@donland.ru, +7 (86378) 2-14-00"
    },
    {
        "col1": "Ростовская область",
        "col2": 12,
        "col3": "Администрация Зерноградского района (Управление образования Администрации Зерноградского района)",
        "col4": "347740, Ростовская область, Зерноградский район, г. Зерноград, ул. Мира, 16, rayono@zern.donpac.ru, 886359 36-1-28"
    },
    {
        "col1": "Ростовская область",
        "col2": 13,
        "col3": "Администрация Зимовниковского района",
        "col4": "ул. Ленина, 114, п. Зимовники, Ростовская область, 347460 adm@zima.donpac.ru 8(86376)3-16-98"
    },
    {
        "col1": "Ростовская область",
        "col2": 14,
        "col3": "Отдел образования Кагальницкого района",
        "col4": "347700, Ростовская обл., Кагальницкий р-он., ст. Кагальницкая, ул. Калинина, д. 94 А, тел.: 8(86345)93-3-02, rookg12@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 15,
        "col3": "Отдел образования Администрации Каменского района ",
        "col4": " Ростовская область, Каменский район, рп. Глубокий ул. Ленина, д. 38,      glubray@mail.ru, 8(86365)-95-2-54; "
    },
    {
        "col1": "Ростовская область",
        "col2": 16,
        "col3": "Администрация Кашарского района (Кашарский отдел образования)",
        "col4": "346200, Ростовская область, Кашарский район, сл. Кашары, ул. Ленина, д. 58, эл.почта: roo_kasharsky@rostobr.ru, тел: 8-863-88-21-4-95"
    },
    {
        "col1": "Ростовская область",
        "col2": 17,
        "col3": "Администрация Константиновского района",
        "col4": "347250 Ростовская область, г. Константиновск, ул. 25 Октября 70, адрес электронной почты:  adm_konst@donland.ru. Номер телефона приемной Главы Администрации (863 93) 2-16-62"
    },
    {
        "col1": "Ростовская область",
        "col2": 18,
        "col3": "Отдел опеки и попечительства управления образования Красносулинского района ",
        "col4": "Ростовская область, \nг. Красный Сулин, \nул. Металлургов, 41 krasnyisulin.opeka@yandex.ru\n8(863)67-5-30-84"
    },
    {
        "col1": "Ростовская область",
        "col2": 19,
        "col3": "Администрация Куйбышевского района  Ростовской области",
        "col4": "346940 Ростовская область, Куйбышевский район,  село Куйбышево, улица Куйбышевская, 24, adkura@ donland.ru\nтел: 31-7-07\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 20,
        "col3": "Отдел образования Администрации Мартыновского района Ростовской области",
        "col4": "346660, Ростовская область, Мартыновский район, сл. Большая Мартыновка, ул. Ковалева, дом № 109а/roomart@donpac.ru/88639521299 "
    },
    {
        "col1": "Ростовская область",
        "col2": 21,
        "col3": "Отдел образования Администрации Матвеево-Курганского района",
        "col4": "346970, Ростовская область, Матвеево-Курганский район, п. Матвеев Курган, ул. 1-я Пятилетка, 104, roo_matveevo-kurgansky@rostobr.ru, тел. 8(86341) 20976"
    },
    {
        "col1": "Ростовская область",
        "col2": 22,
        "col3": "Администрация \nМиллеровского района",
        "col4": "346130,   ул. Максима Горького, д. 23,                                   г. Миллерово, \nРостовская область; E-mail: roo_millerovsky@rostobr. ru; тел./факс +7 (86385)          \n3-80-79\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 23,
        "col3": "Администрация Милютинского района",
        "col4": "347120, Ростовская область, ст.Милютинская, ул.Павших Героев, 46, miladm@donland.ru,  8-863-89-2-14-00 "
    },
    {
        "col1": "Ростовская область",
        "col2": 24,
        "col3": "Отдел образования Администрации Морозовского района",
        "col4": "347210, Ростовская область, г.Морозовск, ул.Ленина,д.204, obraz_morozov@donland.ru, 8(86384)50977"
    },
    {
        "col1": "Ростовская область",
        "col2": 25,
        "col3": "Муниципальное учржедние \"Отдел образования Администрации Мясниковского района\"",
        "col4": "346800 Ростовская область, Мясниковский район,с. Чалтырь, ул. 7-я линия, 1б/muotdelobr2017@yandex.ru/88634923695"
    },
    {
        "col1": "Ростовская область",
        "col2": 26,
        "col3": "Управление образования Администрации Неклиновского района",
        "col4": "346830 Ростовская область, Неклиновский район, с. Покровское, ул. Ленина, дом 271       roo_neklinovsky@rostobr.ru         8(86347)21502"
    },
    {
        "col1": "Ростовская область",
        "col2": 27,
        "col3": "Отдел образования Администрации Обливского района",
        "col4": "347140, Ростовская область, Обливский район, ст. Обливская, ул. Ленина, д. 47, obraz@oblivka.donpac.ru, 8(86396)21-8-38"
    },
    {
        "col1": "Ростовская область",
        "col2": 28,
        "col3": "Отдел образования Администрации Октябрьского района",
        "col4": "346480 Ростовская область, Октябрьский район, р.п. Каменоломни, ул. Дзержинского, д. 78-А. Электронный адрес: opeka_okt_s@mail.ru. Тел. 8(86360) 2-27-33"
    },
    {
        "col1": "Ростовская область",
        "col2": 29,
        "col3": "Упраление образования Орловского района",
        "col4": "347510, Ростовская обл,Орловсий р-н., п.Орловский, ул.Пионерская, д.74, orlrono@orlovsky.donpac.ru, 8(86375) 33-1-90"
    },
    {
        "col1": "Ростовская область",
        "col2": 30,
        "col3": "Администрация Песчанокопского района Ростовской области",
        "col4": "347570, Ростовская область, Песчанокопский район, с. Песчанокопское, ул. Суворова,4 admin273@donland.ru, +7(86373)9-13-98"
    },
    {
        "col1": "Ростовская область",
        "col2": 31,
        "col3": "Администрация Пролетарского района Ростовская область",
        "col4": "347540, Ростовская область, г.Пролетарск, ул. Пионерска, д.120 А, vovenkolybov@yandex.ru 88637499019"
    },
    {
        "col1": "Ростовская область",
        "col2": 32,
        "col3": "Ремонтненский отдел образования Администрации Ремонтненского района",
        "col4": "347480,Ростовская область, Ремонтненский район, с.Ремонтное, ул.Ленинская, 69, +7(86379)31-2-66,remroo@remont.donpac.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 33,
        "col3": "Отдел социального развития Администрации Родионово-Несветайского района",
        "col4": "346580, Ростовская обл., Родионово-Несветайский          р-н, сл. Родионово-Несветайская, ул. Пушкинская, 34,                         эл. почта adminrod61@donland.ru\n8(86340)31-3-20\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 34,
        "col3": "Управление образования Сальского района",
        "col4": "Ростовская область,  г. Сальск, ул. Ленина, 33;         opeka72@yandex.ru           886372 7-25-12          "
    },
    {
        "col1": "Ростовская область",
        "col2": 35,
        "col3": "Отдел образования Администрации Семикаракорского района Ростовской области",
        "col4": "3466030, Ростовская область,  г.Семикаракорск, проспект В.А. Закруткина,79,  roo@semikar.donpac.ru, 86356 4-11-82"
    },
    {
        "col1": "Ростовская область",
        "col2": 36,
        "col3": "Администрация Советского района ",
        "col4": "347180, Ростовская область, Советский район, ст. Советская, ул. Орджоникидзе, д. 14, sov_adm.ro@mail.ru,  тел. 88636323200"
    },
    {
        "col1": "Ростовская область",
        "col2": 37,
        "col3": "Муниципальное учреждение Отдел образования Администрации Тарасовского района, как уполномоченный орган опеки и попечительства",
        "col4": "346050 Ростовская область Тарасовский район п.Тарасовский ул.Ленина, 29 roo_tarasovsky@rostobr.ru 8(863 86) 32-2-70"
    },
    {
        "col1": "Ростовская область",
        "col2": 38,
        "col3": "Отдел образования Администрации Тацинского района Ростовской области.",
        "col4": "347060 Ростовская область\nТацинский район\nст. Тацинская,ул. Ленина, 66\nтел./факс 8-8(6397)-2-12-78\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 39,
        "col3": "Администрация Усть-Донецкого района",
        "col4": "346550, Ростовская область, Усть-Донецкий район, р.п. Усть-Донецкий, ул. Ленина, д.11, ustland@donland.ru, 8(86351)-9-11-57"
    },
    {
        "col1": "Ростовская область",
        "col2": 40,
        "col3": "Отдел образования Администрации Целинского района",
        "col4": "347760, Ростовская область, Целинский район, п.Целина, ул.Советская, 35,  адрес электронной почты coo@celina.donpac.ru,      телефон 8 (86371) 9-12-43\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 41,
        "col3": "Администрация Цимлянского района",
        "col4": "347320 Ростовская область, Цимлянский район, г. Цимлянск, ул. Ленина, 24. cim6141@donland.ru, тел. 8(86391)5-11-44;факс 8(86391)5-10-99"
    },
    {
        "col1": "Ростовская область",
        "col2": 42,
        "col3": "Орган опеки и попечительства в отношении несовершеннолетних отдела образования Администрации Чертковского района",
        "col4": "346000, Ростовская область, Чертковский р-н, п.Чертково, ул.Петровского, д.95/roo_chertkovsky@rostobr.ru/(86387)22226"
    },
    {
        "col1": "Ростовская область",
        "col2": 43,
        "col3": "Отдел образования администрации Шолоховского района  ",
        "col4": "346270, Ростовская область, Шолоховский район, ст. Вешенская, ул. Шолохова, 55 raiono.veshenskaya@yandex.ru, 88635321363"
    },
    {
        "col1": "Ростовская область",
        "col2": 44,
        "col3": "Управление образования администрации города Азова",
        "col4": "346870, Ростовская область, город Азов, ул. Кондаурова, 53/ uo_azov@rostobr.ru/ 8(86342)6-22-14"
    },
    {
        "col1": "Ростовская область",
        "col2": 45,
        "col3": "Управление образованния города Батайска",
        "col4": "346880, Ростовская область, город Батайск, ул. Куйбышева, д.184а, тел. (886354) 2-36-38, 2-36-06; эл. bataysk.opeka@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 46,
        "col3": "Администрация муниципального образования городской округ \"Город Волгодонск\" Ростовская область",
        "col4": "Советская ул., д.2,\nг. Волгодонск, Ростовская область,\nРоссийская Федерация, 347366\nт.(8639)22-25-23, эл.адрес: volgodonskgorod.donland.ru\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 47,
        "col3": "Отдел образования администрации г. Гуково",
        "col4": "347871, Ростовская область, г. Гуково, ул. Маяковского, 36, Obrazov-gukovo@donland.ru, opeka61@yandex.ru, 8(86361)5-25-34,                              8(86361)5-47-30)"
    },
    {
        "col1": "Ростовская область",
        "col2": 48,
        "col3": "Администрация города Донецка",
        "col4": "346330, Ростовская область, г.Донецк, пр-кт Мира, 39   adm-don@donland.ru  +7(86368) 2-33-15"
    },
    {
        "col1": "Ростовская область",
        "col2": 49,
        "col3": "Отдел образования Администрации города Зверево",
        "col4": "346311 Ростовская область,г. Зверево, ул. Рижская, д.5 oozverevo@yandex.ru  88635541055"
    },
    {
        "col1": "Ростовская область",
        "col2": 50,
        "col3": "Администрации город Каменск-Шахтинский",
        "col4": "347800, Ростовская область, г. Каменск-Шахтинский, ул. Ленина 74 E-mail: abmkam@donland.ru 8 (86365) 7-49-63"
    },
    {
        "col1": "Ростовская область",
        "col2": 51,
        "col3": "Управление образования Администрации города",
        "col4": "346400, Ростовская область, г. Новочеркасск, пр-кт Платовский, д. 59б. Эл. почта: p0school@novoch.ru, тел. 8(86352)259871, 8(86352)246046"
    },
    {
        "col1": "Ростовская область",
        "col2": 52,
        "col3": "Управление жилищной политики Администрации города",
        "col4": "346900, Ростовская область, г. Новошахтинск, ул. Харьковская, 58, 8 (863 69) 2 21 17, jilnov@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 53,
        "col3": "Управление образования г. Таганрога",
        "col4": "347923, Ростовская область, г. Таганрог, пер. Красногвардейский, д. 1 / goruo@tagobr.ru / +7 (8634) 648-235"
    },
    {
        "col1": "Ростовская область",
        "col2": 54,
        "col3": "Департамент образования г.Шахты",
        "col4": "346500, Ростовская область, г.Шахты, ул.Советская, 145, priem@shakhty-edu.ru          8 (8636) 22-45-46"
    },
    {
        "col1": "Ростовская область",
        "col2": 55,
        "col3": "Администрация Ворошиловского района города Ростова-на-Дону                 (МКУ \"Отдел образования Ворошиловского района города Ростова-на-Дону\")",
        "col4": "344113, г. Ростов-на-Дону, пр. Королева, 25Г             (863) 235-05-00  roovr@rostov-gorod.ru "
    },
    {
        "col1": "Ростовская область",
        "col2": 56,
        "col3": "Администрация Железнодорожного района города Ростова-на-Дону (МКУ \"Отдел образования Железнодорожного района города Ростова-на-Дону\")",
        "col4": "344101, г. Ростов – на – Дону,  ул. 1я Баррикадная, 48                                    (863) 222 47 83    roog1@bk.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 57,
        "col3": "Администрация Кировского района города Ростова-на-Дону (МКУ \"Отдел образования Кировского района города Ростова-на-Дону\")",
        "col4": "344002, г. Ростов-на-Дону, ул. Московская, д. 76  (863) 262 58 26    rookirovsk@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 58,
        "col3": "Администрация Лениского района города Ростова-на-Дону (МКУ \"Отдел образования Лениского района города Ростова-на-Дону\")",
        "col4": "344082, г. Ростов-на-Дону, пер. Островского, 35     (863)282-02-99     len_roo@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 59,
        "col3": "Администрация Октябрьского района города Ростова-на-Дону (МКУ \"Отдел образования Октябрьского района города Ростова-на-Дону\")",
        "col4": "344068, г. Ростов-на-Дону, ул. Ларина, д. 19/2                           (863) 245 57 30   roopriemnay@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 60,
        "col3": "Администрация Первомайского района города Ростова-на-Дону (МКУ \"Отдел образования Первомайского района города Ростова-на-Дону\")",
        "col4": "344029, Ростовская область, \nг. Ростов-на-Дону, ул. Металлургическая 110/1 (863) 227-91-45   opekap@bk.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 61,
        "col3": "Администрация Пролетарского района города Ростова-на-Дону (МКУ \"Отдел образования Пролетарского района города Ростова-на-Дону\")",
        "col4": "344025, г. Ростов-на-Дону, ул. 27 линия, д. 4                 (863) 251-35-91                 roopr-rnd@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 62,
        "col3": "Администрация Советского района города Ростова-на-Дону (МКУ \"Отдел образования Советского района города Ростова-на-Дону\")",
        "col4": " 344091   Ростов-на-Дону,   Коммунистический пр., дом 24,                               (863) 222-74-12   roosov@mail.ru    \n"
    },
    {
        "col1": "Рязанская область",
        "col2": 1,
        "col3": "Министерство образования Рязанской области",
        "col4": "390000, г. Рязань, ул. Каширина, д.1; suu@min-obr.ru, +7 (4912) 51-51-52 (доб. 324)"
    },
    {
        "col1": "Самарская область",
        "col2": 1,
        "col3": "Департамент опеки, попечительства и социальной поддержки Администрации городского округа Самара;",
        "col4": "443099, г. Самара, ул. Куйбышева, д. 44, opeka@samadm.ru, 8(846)332 24 49"
    },
    {
        "col1": "Самарская область",
        "col2": 2,
        "col3": "Администрация городского округа Тольятти",
        "col4": "г.Тольятти, ул.Белорусская, 33, каб.910, dumi@tgl.ru, 54-37-00"
    },
    {
        "col1": "Самарская область",
        "col2": 3,
        "col3": "Жилищный отдел КЖКХ Администрации г.о. Сызрань",
        "col4": "Самарская область, г. Сызрань, ул. Ульяновская, д.61,каб.2, jil_otdel@bk.ru, 8 (846 4) 98-66-53"
    },
    {
        "col1": "Самарская область",
        "col2": 4,
        "col3": "Департамент городского хозяйства администрации городского округа Новокуйбышевск",
        "col4": "г. Новокуйбышевск, ул. Миронова, д. 2, adm@nvkb.ru, 88463563386"
    },
    {
        "col1": "Самарская область",
        "col2": 5,
        "col3": "Администрация городского округа Чапаевск (жилищный  отдел)",
        "col4": "г.Чапаевск, ул. Пролетарская, 1, gilotdel@bk.ru, 8(84639)20317"
    },
    {
        "col1": "Самарская область",
        "col2": 6,
        "col3": "Администрация городского округа Отрадный Самарской области",
        "col4": "г. Отрадный, ул.Отрадная, д.15, dom@otradny.org, 8(84661)22439"
    },
    {
        "col1": "Самарская область",
        "col2": 7,
        "col3": "Отдел по жилищным вопросам комитета по управлению муниципальным имуществом администрации городского округа Жигулевск",
        "col4": "445350, г. Жигулевск, ул. Пушкина, д. 17, Poliynskova.Irina@yandeх.ru; zhilotd@admzhg.ru, 8(84862)2-48-93, 2-42-31"
    },
    {
        "col1": "Самарская область",
        "col2": 8,
        "col3": "Служба по учету и распределению жилья Администрации г.о.Октябрьск",
        "col4": "г.Октябрьск, ул.Ленина, 54, oktyabrsk-go@mail.ru, 8(84646)21576"
    },
    {
        "col1": "Самарская область",
        "col2": 9,
        "col3": "Администрация городского округа Кинель Самарской области в лице МКУ \"Управление по вопросам емьи и демографического развития\"",
        "col4": "г. Кинель, ул. Мира, д.41, kumikinel@mail.ru,  8(84663) 2-14-19"
    },
    {
        "col1": "Самарская область",
        "col2": 10,
        "col3": "Администрация городского округа Похвистнево Самарской области",
        "col4": "446450, Самарская обл., г. Похвистнево, ул. Лермонтова, 16, pohgor@samtel.ru, 8(84656)25812 8(84656)23466 "
    },
    {
        "col1": "Самарская область",
        "col2": 11,
        "col3": "Администрация муниципального района Алексеевский Самарской области (Юридический отдел -жилищная комиссия)",
        "col4": "Самарская область, Алексеевский район, с.Алексеевка, ул.Советская,7, yurotd@alexadm63.ru, 8(84671)22367"
    },
    {
        "col1": "Самарская область",
        "col2": 12,
        "col3": "Комитет по управлению муниципальным имуществом администрации муниципального района Безенчукский Самарской области",
        "col4": "Самарская обл, Безенчукский р-н, пгт Безенчук, ул Нефтяников, д 11, kumibez@mail.ru, 88467623308"
    },
    {
        "col1": "Самарская область",
        "col2": 13,
        "col3": "Комитет по вопросам семьи, материнства и детства муниципального района Богатовский самарской области",
        "col4": "с.Богатое, ул. Комсомольская, д.46, osemya@yandex.ru, 8(84666)2-15-87"
    },
    {
        "col1": "Самарская область",
        "col2": 14,
        "col3": "Муниципальный район Большеглушицкий Самарской области",
        "col4": "446180, Самарская область, Большеглушицкий район, с. Большая Глушица, ул Гагарина, д. 91, bg@admbg.org, 8-846-73-2-16-33"
    },
    {
        "col1": "Самарская область",
        "col2": 15,
        "col3": "Администрация Большечерниговского района Самарской области",
        "col4": "с. Большая Черниговка, ул. Полевая, д.77, chernigovka.adm@yandex.ru, 88467221049"
    },
    {
        "col1": "Самарская область",
        "col2": 16,
        "col3": "Жилищная комиссия Администрации муниципального района Борский Самарской области",
        "col4": "Самарская область  Борский район с.Борское  ул.Октябрьская, д. 57, borsk.zhil.otdel@yandex.ru, 8(84667)25164"
    },
    {
        "col1": "Самарская область",
        "col2": 17,
        "col3": "Администрация муниципального района Волжский Самарской области",
        "col4": "443045, г. Самара, ул. Дыбенко, д. 12 Б, каб. 110, vr@v-adm63.ru, 8 846 260 33 35"
    },
    {
        "col1": "Самарская область",
        "col2": 18,
        "col3": "отдел экономики муниципального района Елховский Самарской области",
        "col4": "Самарская область, Елховский район, с. Елховка, ул. Советская, д. 2, economy.elhovka@yandex.ru, 88465833269"
    },
    {
        "col1": "Самарская область",
        "col2": 19,
        "col3": "Администарция м.р.Исаклинский",
        "col4": "с.Исаклы, ул.Куйбышевская, 75А, isakeconomi@mail.ru, (84654)2-16-36"
    },
    {
        "col1": "Самарская область",
        "col2": 20,
        "col3": "Комиссия  по осуществлению государственных полномочий по обеспечению жилыми помещениями отдельных категорий граждан",
        "col4": "Самарская область, Камышлинский р-н, с.Камышла, ул.Красноармейская 37а, kamadm63@mail.ru, kamdom@bk.ru, 88466433054, 88466433079"
    },
    {
        "col1": "Самарская область",
        "col2": 21,
        "col3": "Администрация муниципального района Кинельский Самарской области",
        "col4": "446433, Самарская область, г. Кинель, ул. Ленина, 36, radmin@kinel.ru, tishina@kinel.ru, 88466321176, 884663 21145"
    },
    {
        "col1": "Самарская область",
        "col2": 22,
        "col3": "Отдел по жилищной политике комитета по управлению имуществом Кинель-Черкасского района",
        "col4": "446350, Самарская область, Кинель-Черкасский район, с. Кинель-Черкассы, ул. Красноармейская,69., jil_otdel@bk.ru, 88466041175"
    },
    {
        "col1": "Самарская область",
        "col2": 23,
        "col3": "Администрация муниципального района Клявлинский ",
        "col4": "Самарская область, Клявлинский район, ст.Клявлино, ул.Октябрьская, 60, priemnaia2012@yandex.ru, 8(846 53)2-15-05"
    },
    {
        "col1": "Самарская область",
        "col2": 24,
        "col3": "МКУ Отдел по делам семьи, охране прав материнства и детства Администрации муниципального района Кошкинский Самарской области",
        "col4": "Самарская обл., Кошкинский р-н, с. Кошки, ул. Первомайская, д. 16, otsem@mail.ru, 8(84650)2-25-77"
    },
    {
        "col1": "Самарская область",
        "col2": 25,
        "col3": "Администрация муниципального района Красноармейский Самарской области",
        "col4": "446140,Самарская область, Красноармейский район, с. Красноармейское, пл. Центральная, д. 12, oldukova.elena@yandex.ru, 8(846)7522251"
    },
    {
        "col1": "Самарская область",
        "col2": 26,
        "col3": "Управление опеки и попечительства администрации м.р.Красноярский Самарской области ",
        "col4": "Самарская область, Красноярский район, с.Красный Яр, ул. Комсомольская, д.92 А, semya.kryar@yandex.ru, 8 (846 57)2-02-30"
    },
    {
        "col1": "Самарская область",
        "col2": 27,
        "col3": "МКУ Управление по вопросам семьи, опеки и попечительства муниципального района Нефтегорский",
        "col4": "Самарская обл., г. Нефтегорск, ул.Буровиков, 12, celfond@mail.ru, 88467020185, 88467022446"
    },
    {
        "col1": "Самарская область",
        "col2": 28,
        "col3": "Администрация муниципального района Пестравский Самарской области",
        "col4": "Самарская область, Пестравский район, село Пестравка, ул. Крайнюковская, д. 84, yapestravsky@yandex.ru, 8(84674) 21233"
    },
    {
        "col1": "Самарская область",
        "col2": 29,
        "col3": "Администрация м.р. Похвистневский",
        "col4": "446450, Самарская область, г. Похвистнево, ул. Ленинградская, д.9, gfdtk@samtel.ru, 88465621354"
    },
    {
        "col1": "Самарская область",
        "col2": 30,
        "col3": "Администрация муниципального района Приволжский Самарской области",
        "col4": "Самарская область, Приволжский район, с. Приволжье, ул. Парковая, д. 14, zhilkom.pv@yandex.ru, 8(846 47)91981"
    },
    {
        "col1": "Самарская область",
        "col2": 31,
        "col3": " МКУ «Комитет по делам семьи и детства» муниципального района Сергиевский Самарской области",
        "col4": "Самарская область, Сергиевский район, с.Сергиевск, ул. Ленина, д.87а, opeka-sergievsk@yandex.ru,  (884655)24209"
    },
    {
        "col1": "Самарская область",
        "col2": 32,
        "col3": "МКУ \"Комитет по управлению муницпальным имуществом администрации муницпального района Ставролький Самарской области\"",
        "col4": "Самарская область, город Тольятти, пл.Свободы,9, stavr-ur@mail.ru, stavr-kumi@mail.ru, 8(8482)283310, 8(8482)281567"
    },
    {
        "col1": "Самарская область",
        "col2": 33,
        "col3": "МКУ \"Управление социального развития администрации Сызранского района\"",
        "col4": "Самарская обл., Сызранский район, п. Варламово, ул. Советская, д. 14, ussofis.varlamovo@yandex.ru , 8(8464)998256"
    },
    {
        "col1": "Самарская область",
        "col2": 34,
        "col3": "Администрация муниципального района Хворостянский Самарской области",
        "col4": "Самарская область, Хворостянский район, с. Хворостянка, пл. Плясункова, д. 10, каб. 103, klimova.ia@hvorostyanka.ru,  8(84677)92903"
    },
    {
        "col1": "Самарская область",
        "col2": 35,
        "col3": "Администрация муниципального района Челно-Вершинский",
        "col4": "446840, Самарская область, Челно-Вершинский район, с. Челно-Вершины,  ул. Почтовая, д.8., komarovaol@admver.ru, 88465123046"
    },
    {
        "col1": "Самарская область",
        "col2": 36,
        "col3": "Комитет по управлению имуществом, архитектуры, капитального строительства и жилищно-коммунального хозяйства администрации муниципального района Шенталинский",
        "col4": "446910, Самарская область, Шенталинский район, ж/д ст. Шентала, ул. Советская, д.33, ivanov@shentala.su, 8 (846)52-2-15-36"
    },
    {
        "col1": "Самарская область",
        "col2": 37,
        "col3": "Администрация муниципального района Шигонский Самарской области ",
        "col4": "Самарская область, с. Шигоны, пл. Ленина 1, adm@shigony.samregion.ru, (884648)21556"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 1,
        "col3": "Администрация Адмиралтейского района Санкт-Петербурга",
        "col4": "Измайловский пр., д. 10,\nСанкт-Петербург, 190005\nhouse2@tuadm.gov.spb.ru\n(812) 576-83-89"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 2,
        "col3": "Администрация Василеостровского\nрайона Санкт-Петербурга\n",
        "col4": "Большой пр. В.О., д. 55,      Санкт-Петербург, 199178,\ntuvo@gov.spb.ru\n(812)576-93-78\n(812)573-93-35\n\n"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 3,
        "col3": "Администрация  Выборгского района Санкт-Петербурга ",
        "col4": "пр. Большой Сампсониевский, д.88,\nСанкт-Петербург, 194100\nzhil@tuvyb.gov.spb.ru\n(812) 241-49-85,                  (812) 576-56-09"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 4,
        "col3": "Администрация Калининского района Санкт-Петербурга ",
        "col4": "Арсенальная наб., д.13/1, \nСанкт-Петербург, 195009\ntukalin@gov.spb.ru               (812) 417-47-46, \n(812) 417-47-47\n"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 5,
        "col3": "Администрация Кировского района Санкт-Петербурга\n",
        "col4": "Стачек пр., д. 18,                           Санкт-Петербург, 198095 tukir@gov.spb.ru                           bordak@tukir.gov.spb.ru\n(812) 252-30-17\n(812) 417-69-04"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 6,
        "col3": "Администрация Колпинского района Санкт-Петербурга",
        "col4": "б-р Победы, д. 1,   г. Колпино, Санкт-Петербург, 196653\nkaa@tukolp.gov.spb.ru, zia@tukolp.gov.spb.ru, \n(812) 573-92-05, (812) 573-92-09"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 7,
        "col3": "Администрация Красногвардейского района Санкт-Петербурга",
        "col4": "Среднеохтинский пр., д.50, \nСанкт-Петербург, 195027 \n tukrgv@gov.spb.ru\n(812) 576-87-50"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 8,
        "col3": "Администрация Красносельского района\nСанкт-Петербурга",
        "col4": "ул. Партизана Германа, д. 3, Санкт-Петербург, 198329\nsevastvanova@tukrsl.gov.spb.ru (812) 576-14-38"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 9,
        "col3": "Администрация Кронштадтского района\nСанкт-Петербурга",
        "col4": "пр. Ленина, д. 36, г. Кронштадт,  Санкт-Петербург, 197760     Zhil1@tukrns.gov.spb.ru   zhiln@tukrns.gov.spb.ru         (812) 576-90-07  (812) 576-90-28"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 10,
        "col3": "Администрация  Курортного района  Санкт-Петербурга ",
        "col4": "пл.Свободы, д.1, г.Сестрорецк,                       Санкт-Петербург, 197706    tukur@gov.spb.ru                 (812)576-81-06 "
    },
    {
        "col1": "Санкт Петербург",
        "col2": 11,
        "col3": "Администрация Московского района Санкт-Петербурга",
        "col4": "Московский пр., д.129,       Санкт-Петербург, 196006 \ndilyara@tumos.gov.spb.ru\n(812) 576-89-01"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 12,
        "col3": "Администрация Невского района Санкт-Петербурга",
        "col4": "пр. Обуховской\nОбороны, д. 163, Санкт-Петербург, 192131\nflat@tunev.gov.spb.ru\n(812) 417-39-62  (812) 417-39-64"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 13,
        "col3": "Администрация Петроградского района Санкт-Петербурга, ",
        "col4": "ул. Большая Монетная, д. 17-19, Санкт-Петербург, 197101\ntupetr@gov.spb.ru \n(812) 576-50-54  \n (812) 576-50-39"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 14,
        "col3": "Администрация Петродворцового района Санкт-Петербурга",
        "col4": "ул. Калининская, д.7, г. Петергоф,                        Санкт-Петербург, 198510 \ntuptrdv@gov.spb.ru\n(812) 576-95-61                   (812) 576-95-65 "
    },
    {
        "col1": "Санкт Петербург",
        "col2": 15,
        "col3": "Администрация Приморского района Санкт-Петербурга",
        "col4": "аллея Котельникова, д.2а,  Санкт-Петербург, 197341  \nrozanov@tuprim.gov.spb.ru\n(812)241-56-32"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 16,
        "col3": "Администрация Пушкинского района Санкт-Петербурга, \n",
        "col4": "Октябрьский бул., д. 24,      г. Пушкин,                          Санкт-Петербург, 196601\nios@tupush.gov.spb.ru\njo@tupush.gov.spb.ru\n(812) 576-92-47  (812) 576-92-15"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 17,
        "col3": "Аадминистрация Фрунзенского района Санкт-Петербурга",
        "col4": "ул. Пражская, д. 46, Санкт-Петербург, 192241 \nisakova@tufruns.gov.spb.ru\n(812) 576-85-53"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 18,
        "col3": "Администрация Центрального района Санкт-Петербурга",
        "col4": " Невский пр., д. 176, Санкт-Петербург, 191167,\nmishin@tucentr.gov.spb.ru    (812) 417-45-93"
    },
    {
        "col1": "Саратовская область",
        "col2": 1,
        "col3": "Министерство строительства и жилищно-коммунального хозяйства Саратовской области",
        "col4": "410012, г. Саратов, ул. Челюскинцев, 114\nminstroy@saratov.gov.ru\n8(8452)74-00-47\nКонсультационный центр для детей-сирот 8(8452)74-00-47"
    },
    {
        "col1": "Сахалинская область",
        "col2": 1,
        "col3": "Управление социальной политики Александровск-Сахалинского муниципального округа",
        "col4": "694420, Сахалинская область, г. Александровск-Сахалинский, ул. Ленина, д. 4, тел.: 8 (42434) 4-34-55, e-mail: opeka_Alex@bk.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 2,
        "col3": "Департамент социального развития администрации Анивского муниципального округа ",
        "col4": "694030, Сахалинская область, Анивский район, г. Анива, ул. Калинина, д. 57, тел.: 8 (42441) 4-20-59, e-mail: opeka.aniva@sakhalin.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства администрации муниципального образования Долинский муниципальный округ",
        "col4": "694051, Сахалинская область, г. Долинск, ул. Комсомольская, д. 37, e-mail: dolinsk@sakhalin.gov.ru, тел.: 8 (42442) 2-68-22\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 4,
        "col3": "Отдел опеки и попечительства департамента социального развития администрации \nКорсаковского муниципального округа\n",
        "col4": "694020, Сахалинская область, г. Корсаков, ул. Советская, д. 41,  e-mail: opeka_akgo@sakhalin.gov.ru, тел.: 8 (42435) 4-05-41 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 5,
        "col3": "Администрация Курильского муниципального округа",
        "col4": "694530, Сахалинская область, г. Курильск, ул. Приморское шоссе, д. 5/1,\nтел.: (42454) 42467, e-mail: kurilsk@sakhalin.gov.ru \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 6,
        "col3": "Администрация Макаровского муниципального образования ",
        "col4": "694140, Сахалинская обл., г. Макаров, ул. 50 лет Октября, д. 32, тел.: 8 (42443) 9-01-48, e-mail:  makarov@sakhalin.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 7,
        "col3": "Отдел образования администрации Невельского муниципального округа",
        "col4": "694740, Сахалинская область, г. Невельск, ул. Советская, д. 55, тел.: 8 (42436) 6-10-18, 8 (42436) 6-02-45, e-mail: y.petrova@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 8,
        "col3": "Департамент социальной политики администрации муниципального образования Ногликский муниципальный округ ",
        "col4": "694450, Сахалинская область, пгт. Ноглики, ул. Советская, д. 15, e-mail: opeka@nogliki-adm.ru, тел.: 8 (42444) 9-10-58  \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 9,
        "col3": "Департамент социального развития администрации Охинского муниципального округа \n",
        "col4": "694490, Сахалинская область, г. Оха, ул. Ленина, д. 8, e-mail: uprobrsek@sakhalin.gov.ru, okhaopeka@mail.ru, тел.: 8 (42437) 3-06-14\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 10,
        "col3": "Отдел опеки и попечительства Администрации Поронайского муниципального округа ",
        "col4": "694240, Сахалинская область, г. Поронайск, ул. Октябрьская, д. 61А,  e-mail: poronaisk_opeka@mail.ru, тел.: 8 (42431) 5-60-01 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 11,
        "col3": "Отдел образования,  социальной  и молодежной политики, культуры и спорта администрации Северо-Курильского муниципального округа",
        "col4": "694550, Сахалинская область, г. Северо-Курильск, ул. Сахалинская, д. 34 А, e-mail: Opeka-skgo@yandex.ru, тел.: 8 (42453) 4-22-13 \n\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 12,
        "col3": "Управление образования администрации Смирныховского муниципального округа ",
        "col4": "694350, Сахалинская область, пгт. Смирных, ул. Маяковского, д. 7, тел.: 8 (42452) 42-8-68, e-mail: opeka_smirnih@mail.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 13,
        "col3": "Отдел образования Томаринского муниципального округа",
        "col4": "694820, Сахалинская область, г. Томари, ул. Новая, д. 1, e-mail: ronotomari@mail.ru, тел.: 8 (42446) 2-61-41\n\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 14,
        "col3": "Управление образования Тымовского муниципального округа ",
        "col4": "694400, Сахалинская область,Тымовский район, пгт. Тымовское, ул. Парковая, д. 9, \ne-mail: tymovsk-uo@sakhalin.gov.ru, тел.: 8 (42447) 9-10-77"
    },
    {
        "col1": "Сахалинская область",
        "col2": 15,
        "col3": "Управление образования Углегорского муниципального округа \n\n",
        "col4": "694920, Сахалинская область, г. Углегорск, ул. Победы, д. 142, тел.: (42432) 44-5-16, e-mail: ugo.uougo@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 16,
        "col3": "Администрация Холмского муниципального округа ",
        "col4": "694620, Сахалинская область, г. Холмск, ул. Советская, д. 66, e-mail: kholmsk.oop@sakhalih.gov.ru  \nтел.: 8(42433)2-16-89 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 17,
        "col3": "Отдел образования Южно-Курильского муниципального округа",
        "col4": "694500, Сахалинская область, пгт. Южно-Курильск, пл. Ленина, д. 1, e-mail: yk.obrazov@sakhalin.gov.ru, тел.: 8 (42455) 21-074, 8 (42455) 21-345\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 18,
        "col3": "Департамент образования администрации города Южно-Сахалинска\n\n",
        "col4": "693000, г. Южно-Сахалинск, ул. Амурская, д. 88, e-mail: yusgo.do@sakhalin.gov.ru, тел.: 8 (4242) 3-12-680"
    },
    {
        "col1": "Свердловская область",
        "col2": 1,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 1 (на территории муниципального округа муниципального образования Алапаевское Свердловской области, Муниципального округа Муниципального образования город Алапаевск Свердловской области, муниципального округа Махнёвское муниципальное образование Свердловской области) \n(далее – Управление социальной политики № 1)",
        "col4": "624600, г. Алапаевск, ул. Береговая, 44 / tusp01@egov66.ru / 8 (34346) 2-61-68"
    },
    {
        "col1": "Свердловская область",
        "col2": 2,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 2 (на территории Режевского муниципального округа Свердловской области, Артемовского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 2)",
        "col4": "623750, г. Реж, ул. Бажова, 15, оф. 16 / tusp02@egov66.ru / 8 (34364) 3-52-31"
    },
    {
        "col1": "Свердловская область",
        "col2": 3,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 3 (на территории городского округа Красноуфимск Свердловской области, Красноуфимского муниципального округа Свердловской области, Ачитского муниципального округа Свердловской области, Артинского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 3)",
        "col4": "623300, г. Красноуфимск, ул. Берёзовая, 12 / tusp03@egov66.ru / 8 (34394) 5-21-84"
    },
    {
        "col1": "Свердловская область",
        "col2": 4,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 4 (на территории Нижнесергинского муниципального района Свердловской области, Бисертского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 4)",
        "col4": "623090, г. Нижние Серги, ул. Федотова, 17 / tusp04@egov66.ru / 8 (34398) 27-2-06"
    },
    {
        "col1": "Свердловская область",
        "col2": 5,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 5 (на территории муниципального округа Первоуральск Свердловской области, муниципального округ Ревда Свердловской области, Шалинского муниципального округа Свердловской области, муниципального округа Дегтярск Свердловской области, муниципального округа Староуткинск Свердловской области) \n(далее – Управление социальной политики № 5)",
        "col4": "623100, г. Первоуральск, ул. 1 Мая, 8А / tusp05@egov66.ru / 8 (34396) 4-74-34"
    },
    {
        "col1": "Свердловская область",
        "col2": 6,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 6 (на территории Городского округа «город Ирбит» Свердловской области, муниципального округа Ирбитское муниципальное образование Свердловской области, Байкаловского муниципального района Свердловской области, Слободо–Туринского муниципального района Свердловской области) \n(далее – Управление социальной политики № 6)",
        "col4": "623850, г. Ирбит, ул. Красноармейская, 15 / tusp06@egov66.ru / 8 (34355) 6-41-85"
    },
    {
        "col1": "Свердловская область",
        "col2": 7,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 7 (на территории Тавдинского муниципального округа Свердловской области, Таборинского муниципального района Свердловской области, Туринского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 7)",
        "col4": "623950, г. Тавда, ул. Ленина, 78 А / tusp07@egov66.ru / 8 (34360) 5-26-26"
    },
    {
        "col1": "Свердловская область",
        "col2": 8,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 8 (на территории Камышловского городского округа Свердловской области, Камышловского муниципального района Свердловской области, Пышминского муниципального округа Свердловской области) \n(далее – Управление социальной политики  № 8)",
        "col4": "624860, г. Камышлов, ул. Гагарина, 1-А / tusp08@egov66.ru / 8 (34375) 2-04-60"
    },
    {
        "col1": "Свердловская область",
        "col2": 9,
        "col3": "9) Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 9 (на территории Талицкого муниципального округа Свердловской области, Тугулымского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 9)",
        "col4": "623640, г. Талица, ул. Ленина, д. 71 / tusp09@egov66.ru / 8 (34371) 2-19-78"
    },
    {
        "col1": "Свердловская область",
        "col2": 10,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 10 (на территории Асбестовского муниципального округа Свердловской области, муниципального округа Заречный Свердловской области, Белоярского муниципального округа Свердловской области, городского округа Верхнее Дуброво Свердловской области, городского округа Рефтинский Свердловской области, городского округа ЗАТО Уральский Свердловской области, Малышевского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 10)",
        "col4": "624272, г. Асбест, ул. Московская, 30 / tusp10@egov66.ru / 8 (34365) 2-06-18"
    },
    {
        "col1": "Свердловская область",
        "col2": 11,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 11 (на территории муниципального округа Сухой Лог Свердловской области, муниципального образования муниципального округа Богданович Свердловской области) \n(далее – Управление социальной политики № 11)",
        "col4": "624800, г. Сухой Лог, ул. Юбилейная, 15 / tusp11@egov66.ru / 8 (34373) 4-36-02"
    },
    {
        "col1": "Свердловская область",
        "col2": 12,
        "col3": "12) Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 12 (на территории Каменск–Уральского городского округа Свердловской области, Каменского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 12)",
        "col4": "623406, г. Каменск-Уральский, ул. Строителей, д. 27 / tusp12@egov66.ru / 8 (34393) 5-33-31"
    },
    {
        "col1": "Свердловская область",
        "col2": 13,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 13 (на территории Невьянского муниципального округа Свердловской области, Кировградского муниципального округа Свердловской области, муниципального округа Верхний Тагил Свердловской области) (далее – Управление социальной политики № 13)",
        "col4": "624194, г. Невьянск, ул. Ленина, 20 / tusp13@egov66.ru / 8 (34356) 40-7-40"
    },
    {
        "col1": "Свердловская область",
        "col2": 14,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 14 (на территории Новолялинского муниципального округа Свердловской области, муниципального округа Верхотурский Свердловской области) (далее – Управление социальной политики № 14)",
        "col4": "624400, г. Новая Ляля, ул. Уральская, 2а / tusp14@egov66.ru / 8 (34388) 2-13-79"
    },
    {
        "col1": "Свердловская область",
        "col2": 15,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 15 (на территории муниципального округа Краснотурьинск Свердловской области, Волчанского муниципального округа Свердловской области, муниципального округа Карпинск Свердловской области) (далее – Управление социальной политики № 15)",
        "col4": "624440, г. Краснотурьинск, ул. К. Маркса, 24 / tusp15@egov66.ru / 8 (34384) 6-55-60"
    },
    {
        "col1": "Свердловская область",
        "col2": 16,
        "col3": "16) Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 16 (на территории Кушвинского муниципального округа Свердловской области, муниципального округа Красноуральск Свердловской области, Городского округа Верхняя Тура Свердловской области) \n(далее – Управление социальной политики № 16)",
        "col4": "624300, г. Кушва, ул. Красноармейская, 16 / tusp16@egov66.ru / 8 (34344) 2-57-57"
    },
    {
        "col1": "Свердловская область",
        "col2": 17,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 17 (на территории городского округа «Город Лесной» Свердловской области, Нижнетуринского муниципального округа Свердловской области, Качканарского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 17)",
        "col4": "624200, г. Лесной, ул. К. Маркса, 8 / tusp17@egov66.ru / 8 (34342) 6-87-28"
    },
    {
        "col1": "Свердловская область",
        "col2": 18,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 18 (на территории Североуральского муниципального округа Свердловской области, Ивдельского муниципального округа Свердловской области, муниципального округа Пелым Свердловской области) \n(далее – Управление социальной политики № 18)",
        "col4": "624480, г. Североуральск, ул. Молодёжная, 15 / tusp18@egov66.ru / 8 (34380) 2-79-01"
    },
    {
        "col1": "Свердловская область",
        "col2": 19,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 19 (на территории Серовского муниципального округа Свердловской области, Гаринского муниципального округа Свердловской области, Сосьвинского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 19)",
        "col4": "624992, г. Серов, ул. Победы, 32 / tusp19@egov66.ru / 8 (34385) 7-22-16"
    },
    {
        "col1": "Свердловская область",
        "col2": 20,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 20 (на территории Новоуральского городского округа Свердловской области, городского округа Верх–Нейвинский Свердловской области) \n(далее – Управление социальной политики № 20)",
        "col4": "624130, г. Новоуральск, ул. Гагарина, д. 7А / tusp20@egov66.ru / 8 (34370) 5-40-70"
    },
    {
        "col1": "Свердловская область",
        "col2": 21,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 21 (на территории муниципального округа город Нижний Тагил Свердловской области, муниципального округа Горноуральский Свердловской области) \n(далее – Управление социальной политики № 21)",
        "col4": "622034, г. Нижний Тагил, ул. Карла Маркса, 42 / tusp21@egov66.ru / 8 (3435) 41-92-61"
    },
    {
        "col1": "Свердловская область",
        "col2": 22,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 22 (на территории Верхнесалдинского муниципального округа Свердловской области, городского округа ЗАТО Свободный Свердловской области, муниципального округа Нижняя Салда Свердловской области) \n(далее – Управление социальной политики № 22)",
        "col4": "624760, г. Верхняя Салда, ул. Воронова 6/1 / tusp22@egov66.ru / 8 (34345) 5-25-08"
    },
    {
        "col1": "Свердловская область",
        "col2": 23,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 23 (на территории Орджоникидзевского района городского округа муниципального образования «город Екатеринбург», городского округа Верхняя Пышма Свердловской области, муниципального округа Среднеуральск Свердловской области) (далее – Управление социальной политики № 23)",
        "col4": "620091, г. Екатеринбург, ул. Бабушкина, 22 / tusp23@egov66.ru / 8 (343) 349-38-22"
    },
    {
        "col1": "Свердловская область",
        "col2": 24,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 24 (на территории Кировского района городского округа муниципального образования «город Екатеринбург», Березовского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 24)",
        "col4": "620062, г. Екатеринбург, ул. Генеральская, 6 / tusp24@egov66.ru / 8 (343) 301-66-77"
    },
    {
        "col1": "Свердловская область",
        "col2": 25,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 25 (на территории Чкаловского района городского округа муниципального образования «город Екатеринбург», Сысертского муниципального округа Свердловской области, Полевского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 25)",
        "col4": "620085, г. Екатеринбург, пер. Автомобильный, 3 / tusp25@egov66.ru / 8 (343) 310-28-08"
    },
    {
        "col1": "Свердловская область",
        "col2": 26,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 26 (на территории Верх-Исетского и Железнодорожного районов городского округа муниципального образования «город Екатеринбург»)\n(далее – Управление социальной политики № 26)",
        "col4": "620014, г. Екатеринбург, ул. Хомякова, 14а / tusp26@egov66.ru / 8 (343) 381-85-80"
    },
    {
        "col1": "Свердловская область",
        "col2": 27,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 27 (на территории Ленинского и Октябрьского районов городского округа муниципального образования «город Екатеринбург») \n(далее – Управление социальной политики № 27)",
        "col4": "620014, г. Екатеринбург, ул. Шейнкмана, 22 / tusp27@egov66.ru / 8 (343) 227-85-86"
    },
    {
        "col1": "Севастополь",
        "col2": 1,
        "col3": "Департамент образования и науки города Севастополя",
        "col4": "г. Севастополь, ул. Щербака, 10, 299011 тел. +7 (8692) 40-41-20, эл. почта: uzn.do@gs.sev.gov.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 1,
        "col3": "Управление образования Администрнациии муниципального образования \"Сафоновский муниципальный округ\" Смоленской области",
        "col4": "215500, Смоленская область, г. Сафоново,          ул. Ленина, д. 3                          т. 8 (48142) 4-10-06"
    },
    {
        "col1": "Смоленская область",
        "col2": 2,
        "col3": "Администрация муниципального образования \"Темкинский муниципальный округ\" Смоленской области",
        "col4": "215350, Смоленская область, с.Темкино,                ул. Советская, д. 27, Temkino_OBR@admin-smolensk.ru,                                  8 (48136) 2-14-07"
    },
    {
        "col1": "Смоленская область",
        "col2": 3,
        "col3": "Администрация муниципального образования \"Шумячский муниципальный округ\" Смоленской области",
        "col4": "216410 Смоленская область, п. Шумячи,               ул. Школьная, д. 1  shumichi@admin-smolensk.ru  4-12-65 "
    },
    {
        "col1": "Смоленская область",
        "col2": 4,
        "col3": "Администрация муниципального образования \"Ельнинский муниципальный округ\" Смоленской области",
        "col4": "216330 Смоленская область, г. Ельня, ул. Советская, д. 23; e-mail admin_elnia@admin-smolensk.ru; тел. (8 481 46) 4-18-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 5,
        "col3": "Администрация муниципального образования \"Новодугинский мунициплаьный округ\" Смоленской области",
        "col4": "215240, Смоленская область, с. Новодугино, ул. 30 лет Победы, д. 2     amo-novodug@yandex.ru   (8 481 38)  2-20-31"
    },
    {
        "col1": "Смоленская область",
        "col2": 6,
        "col3": "Администрация муниципального образования \"Сычевский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, г. Сычевка, ул. Пушкина, д.25,\nSychevka.opeka@gmail.com                                   8(48130)4-12-77\n"
    },
    {
        "col1": "Смоленская область",
        "col2": 7,
        "col3": " Администрация муниципального образования \"Велижский муниципальный округ\" Смоленской области",
        "col4": " г.Велиж, пл. Дзержинского, д.7, тел.4-15-33 эл. Почта:velizh@admin-smolensk,ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 8,
        "col3": "Администрация муниципального образования \"Ершичский муниципальный округ\" Смоленской области",
        "col4": "216580, Смоленская область, с. Ершичи, ул. Советская, д.22, тел. 8(48155) 2-16-60, эл.почта <ershadm@yandex.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 9,
        "col3": "Комитет территориального развития, строительства и жилищно-коммунального хозяйства Администрации муниципального образования \"Глинковский муниципальный округ\" Смоленской области",
        "col4": "216320, Смоленская область, Глинковский район, с. Глинка, ул. Ленина, д. 8, glinka@admin-smolensk.ru 8(481-65) 2-13-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 10,
        "col3": "Управление опеки и попечительства Администрации города Смоленска",
        "col4": "214000, г. Смоленск, ул. Дзержинского, д. 9/ (84812)388930,(84812)383701/ uop@smoladmin.ru,  ssv@smoladmin.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 11,
        "col3": "Администрация муниципального образования \"Угранский муниципальный округ\" Смоленской области",
        "col4": "215430, Смоленская область, село Угра, ул. Ленина, д. 38 ugra@admin-smolensk.ru? 8(48137)4-12-90"
    },
    {
        "col1": "Смоленская область",
        "col2": 12,
        "col3": "Администрация МО \"Духовщинский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, город Духовщина, улица М.Горького, 23, 84816641707"
    },
    {
        "col1": "Смоленская область",
        "col2": 13,
        "col3": "Администрация муниципального образования \"Хиславичский муниципальный округ\" Смоленской области",
        "col4": "216620, Смоленская область, п. Хиславичи, ул. Советская, д. 23, адрес электронной почты: hislav@admin-smolensk.ru, тел. 8(48140) 2-20-20"
    },
    {
        "col1": "Смоленская область",
        "col2": 14,
        "col3": "Администрация муниципального образования \"Починковский муниципальный округ\" Смоленской области",
        "col4": "216450, Смоленская область,          г. Починок, ул. Советская,                 д. 1/potch@admin-smolensk.ru/     8 (48149)4-11-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 15,
        "col3": " Администрация муниципального образования \"Гагаринский муниципальный округ\" Смоленской области",
        "col4": "215010, Смоленская область, ул. Советская, д. 8; e-mail:gagarin-komitet@mail.ru, opeka.gagarin@yandex.ru; конт. тел. 8(48135)6-40-61, 6-40-68"
    },
    {
        "col1": "Смоленская область",
        "col2": 16,
        "col3": "Администрация муниципального образования \"Ярцевский муниципальный округ\" Смоленской области",
        "col4": "8-48143-7-10-30 opeka-yarcevo@mail.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 17,
        "col3": "Администрация МО  \"Демидовский муниципальный округ \" Смоленской области\n ",
        "col4": "216240, Смоленская область, г.Демидов, ул.Коммунистическая, д.10,  https: demidov@admin-smolensk.ru.                     Т.(48147) 4-11-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 18,
        "col3": "Администрация МО «город Десногорск» Смоленской области  ",
        "col4": "г. Десногорск, 2 мкр., стр. 1/ эл. почта: kiizo@yandex.ru/ 8-48153-7-44-60, 848153-7-23-08"
    },
    {
        "col1": "Смоленская область",
        "col2": 19,
        "col3": "Администрация МО \"Монастырщинский муниципальный округ\" Смоленской области",
        "col4": "216130, Смоленская область, п. Монастырщина, ул. 1-я Краснинская, д. 14. E-mail: monast@admin-smolensk.ru, т. 8 (48148) 4-18-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 20,
        "col3": "Администрация МО \"Краснинский муниципальный округ",
        "col4": "пгт. Красный,ул. Карла-Маркса, д.16,krasniy@admin-smolensk.ru, 84814541449"
    },
    {
        "col1": "Смоленская область",
        "col2": 21,
        "col3": "Администрация МО \"Дорогобужский муниципальный округ\"",
        "col4": "г. Дорогобуж, ул. Кутузова, д.1 dorobrz67@mail.ru 848144 41756"
    },
    {
        "col1": "Смоленская область",
        "col2": 22,
        "col3": "Администрация МО \"Вяземский муниципальный округ\" Смоленской области",
        "col4": " 215110,  Смоленская область, г. Вязьма, ул. 25 Октября, д. 11, 8 (48131) 4–11–50, office@admin-smolensk.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 23,
        "col3": "Администрации МО \"Смоленский муниципальный округ\" Смоленской области",
        "col4": "тел./факс  8 (4812) 55-53-89\nе-mail: smolray@admin-smolensk.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 24,
        "col3": "Администрация МО \"Кардымовский муниципальный округ\" Смоленской области",
        "col4": "215850, Смоленская область, пгт. Кардымово, ул. Ленина, д.  14/ kardymovo@admin-smolensk.ru/ 8 (48167) 4-11-33"
    },
    {
        "col1": "Смоленская область",
        "col2": 25,
        "col3": "Администрация МО \"Рославльский муниципальный округ\" Смоленской области ",
        "col4": "216500, Смоленская область, г. Рославль, площадь Ленина, д. 1, 8-48134-6-84-52, administrator@roslavl.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 26,
        "col3": "Отдел по образованию Администрации МО  \"Холм-Жирковский муниципальный округ\" Смоленской области",
        "col4": "215650\nСмоленская область, п.г.т.Холм-Жирковский , ул. Нахимовская, д.9\nE-mail: holm_otdel@mail.ru\nтел./факс:  8 (48139) 2-14-67\n"
    },
    {
        "col1": "Смоленская область",
        "col2": 27,
        "col3": "Отдел образования Администрации муниципального образования \"Руднянский муниципальный округ Смоленской области",
        "col4": "Смоленская область, г.Рудня, ул.Киреева, д.93; rud_obrazovanie@admin-smolensk.ru; Телефон (факс): (48141) 4-94-86"
    },
    {
        "col1": "Ставропольский край",
        "col2": 1,
        "col3": "Министерство образования Ставропольского края",
        "col4": "355003, г. Ставрополь, ул. Ломоносова, д. 3, info@stavminobr.ru (8652) 74-75-50"
    },
    {
        "col1": "Тамбовская область",
        "col2": 1,
        "col3": "Министерство социальной защиты и семейной политики Тамбовской области",
        "col4": "392000, Тамбовская область, г. Тамбов, ул. Московская, 27а, email:uprsoc@uszn.tambov.gov.ru, телефон: 8(4752)791602"
    },
    {
        "col1": "Тверская область",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Тверской области",
        "col4": "170100, г. Тверь, набережная р.Лазури, д.20, min_soczashity@tverreg.ru,(4822) 34-27-63 "
    },
    {
        "col1": "Томская область",
        "col2": 1,
        "col3": "Александровское  сельское поселение",
        "col4": "636760, Томская область, Александровский район, с.Александровское, ул.Лебедева, д. 30 alsaleks@tomsk.gov.ru,              8 (38255)2-54-30"
    },
    {
        "col1": "Томская область",
        "col2": 2,
        "col3": " Лукашкин-Ярское сельское поселение",
        "col4": "636764, Томская область, Александровский район,\nс. Лукашкин Яр,\nул. Центральная, д. 27 alsluk@tomsk.gov.ru     8(38255)4-33-16, 2-42-40\n\n"
    },
    {
        "col1": "Томская область",
        "col2": 3,
        "col3": "Назинское сельское поселение",
        "col4": "636765, Томская область, Александровский район, с.Назино, пер.Центральный, д.2 /alsnaz@tomsk.gov.ru/\n8(382 55)4-21-30\n"
    },
    {
        "col1": "Томская область",
        "col2": 4,
        "col3": "Новоникольское сельское поселение",
        "col4": "636766, Томская область, Александровский район, с.Новоникольское, пер.Школьный, д. 3 alsnik@tomsk.gov.ru.\n8 (38255)4-11-24,4-11-25\n"
    },
    {
        "col1": "Томская область",
        "col2": 5,
        "col3": "Октябрьское сельское поселение",
        "col4": "636767, Томская область, Александровский район,\n с. Октябрьское, ул.Лесная, д.11 alsokt@tomsk.gov.ru.\n8-983-343-71-80\n"
    },
    {
        "col1": "Томская область",
        "col2": 6,
        "col3": "Северное сельское поселение",
        "col4": "636763, Томская область, Александровский район,\n с. Северное, ул.Дорожная, д.5 alssev@tomsk.gov.ru.\n                   8-923-441-1366\n"
    },
    {
        "col1": "Томская область",
        "col2": 7,
        "col3": "Асиновское городское поселение",
        "col4": "636840, Томская область, г. Асино, ул.  имени Ленина, 40/ adminpos@asino.tomsknet.ru/\n8(38241)22498,\n8(38241)21748\n"
    },
    {
        "col1": "Томская область",
        "col2": 8,
        "col3": " Большедороховское сельское поселение",
        "col4": "636803, Томская область, Асиновский район, с. Больше-Дорохово, ул. Центральная, д. 26/\nb-dorokhovo-sp@asino.gov70.ru\n/ 8(38241)47205\n"
    },
    {
        "col1": "Томская область",
        "col2": 9,
        "col3": "Батуринское сельское поселение",
        "col4": "636820 Томская область, Асиновский район, с.Батурино, ул. Клубная ,34/\nbaturino-sp@asino.gov70.ru/\n8(38241)41155\n"
    },
    {
        "col1": "Томская область",
        "col2": 10,
        "col3": "Новокусковское сельское поселение",
        "col4": "636810 Томская обл., Асиновский р-он, с.Ново-Кусково, ул.Школьная, д.55/ nkselp@mail.tomsknet.ru/\n8(38241)45045;\n8(38241) 45001\n"
    },
    {
        "col1": "Томская область",
        "col2": 11,
        "col3": "Новиковское сельское поселение",
        "col4": "636830 Томская область Асиновский район с. Новиковка ул. Советская д.14 /\nadm-novikovka@asino.gov70.ru\n/8(38241)44166; \n8(38241)44220\n"
    },
    {
        "col1": "Томская область",
        "col2": 12,
        "col3": "Новониколаевское сельское поселение",
        "col4": "636813 Томская область Асиновский район, с.Новониколаевка, ул. Школьная, д.30, / n-nikolaevka-sp@asino.gov70.ru/ 8(38)24142159; 8(38)24142117"
    },
    {
        "col1": "Томская область",
        "col2": 13,
        "col3": "Ягодное сельское поселение",
        "col4": "636806 Томская обл., Асиновский р-он, с.Ягодное, ул.Школьная, д.1 Г/ yagodnoe-sp@asino.gov70.ru/\n8 (38241)43537\n"
    },
    {
        "col1": "Томская область",
        "col2": 14,
        "col3": "Бакчарское сельское поселение",
        "col4": "636200. Томская область, Бакчарский район, с. Бакчар ул. Ленина д. 53, \n baksp@tomsk.gov.ru, 8(38249)22-284\n"
    },
    {
        "col1": "Томская область",
        "col2": 15,
        "col3": "Вавиловское сельское поселение",
        "col4": "636202, Томская область, Бакчарский район, с. Вавиловка, ул. Центральная д.2, vavilsp@tomsk.gov.ru, 8 (38249) 37-295"
    },
    {
        "col1": "Томская область",
        "col2": 16,
        "col3": "Парбигское сельское поселение",
        "col4": "636220,Томская область, Бакчарский район, с. Парбиг, ул. Советская д. 55, parbigsp@tomsk.gov.ru, 8 (38249) 44-384"
    },
    {
        "col1": "Томская область",
        "col2": 17,
        "col3": "Высокоярское сельское поселение",
        "col4": "636225, Томская область, Бакчарский район, с. Высокий Яр, пер. Трактовый, д. 7, vsp.06@mail.ru, 8 (38249) 38-124"
    },
    {
        "col1": "Томская область",
        "col2": 18,
        "col3": "Плотниковское сельское поселение",
        "col4": "636210, Томская область, Бакчарский район, с. Плотниково, ул. Школьная д. 4, plotsp@tomsk.gov.ru, 8 (38249) 39-436"
    },
    {
        "col1": "Томская область",
        "col2": 19,
        "col3": "Поротниковское сельское поселение",
        "col4": "636213, Томская область, Бакчарский район, с Поротниково, ул. Советская, д.19, portsp@tomsk.gov.ru, 8 (38249) 36-138"
    },
    {
        "col1": "Томская область",
        "col2": 20,
        "col3": "Белоярское городское поселение",
        "col4": "636500,Томская область, Верхнекетский район, р.п.Белый Яр, ул.Гагарина,47стр.1, upravdel.admbel@mail.ru тел.8-38258-2-56-82"
    },
    {
        "col1": "Томская область",
        "col2": 21,
        "col3": "Катайгинское сельское поселение",
        "col4": "636518, Томская область, Верхнекетский район, п.Катайга, ул.Кирова, д.39\"А\"  \n (38258) 3-31-38\n sakat@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 22,
        "col3": "Клюквинское сельское поселение",
        "col4": "636511, Томская область, Верхнекетский район, п. Клюквинка, ул. Центральная, д. 13\n8 (38258) 24-136\nsaklk@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 23,
        "col3": " Макзырское сельское поселение",
        "col4": "636511, Томская область, Верхнекетский район, п. Лисица, ул. Таежная, д. 16\n8 (38258) 35-148\nmsadm@seversk.tomsknet.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 24,
        "col3": "Орловское сельское поселения",
        "col4": "636513, Томская область, Верхнекетский район, п.Центральный, пер.Школьный, д.11.\n (38258) 37-2-26,  saorl@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 25,
        "col3": "Палочкинское сельское поселение",
        "col4": "636506, Томская область, Верхнекетский район, село Палочка, ул. Молодёжная, д. 26\n palsp@tomsk.gov.ru\n 8 (38 )258 -34-136\n"
    },
    {
        "col1": "Томская область",
        "col2": 26,
        "col3": "Степановское сельское поселение",
        "col4": "636516 Томская обл., Верхнекетский р-н п. Степановка, пер.Аптечный,д.4 sastp@tomsk.gov.ru/ 83825825136"
    },
    {
        "col1": "Томская область",
        "col2": 27,
        "col3": "Администрация Сайгинское сельское поселение",
        "col4": "636520 Томская обл., Верхнекетский район,  п. Сайга ул. Молодогвардейская, д. 5/ sasay@tomsk.gov.ru/ 83825836136"
    },
    {
        "col1": "Томская область",
        "col2": 28,
        "col3": "Ягоднинское сельское поселение",
        "col4": "636521 Томская обл., Верхнекетский район, п. Ягодное, Октябрьская,1 saber@tomsk.gov.ru/ 83825832280"
    },
    {
        "col1": "Томская область",
        "col2": 29,
        "col3": "Высоковского сельского поселения ",
        "col4": "636850 Томская область, Зырянский район, с.Зырянское, ул.Советская, д.10\n(адрес электронной почты: zirselo@tomsk.gov.ru; тел. 8(38243) 38149)\n"
    },
    {
        "col1": "Томская область",
        "col2": 30,
        "col3": "Дубровское сельское поселение",
        "col4": "636852 Томская область, Зырянский район, с.Дубровка, ул. Фрунзе, д.38\n (адрес электронной почты: dubrovka@tomsk.gov.ru; тел. 8(38243) 34131)\n"
    },
    {
        "col1": "Томская область",
        "col2": 31,
        "col3": "Зырянское сельское поселение",
        "col4": "636850 Томская область, Зырянский район, с.Зырянское, ул.Советская, д.10\n(адрес электронной почты: zirselo@tomsk.gov.ru; тел. 8(38243) 38149)\n"
    },
    {
        "col1": "Томская область",
        "col2": 32,
        "col3": "Михайловское сельское поселение",
        "col4": "636855 Томская область, Зырянский район, с.Михайловка, ул.Гончарова, д.44\n(адрес электронной почты: mihaylsp@tomsk.gov.ru; тел. 8(38243) 36222).\n"
    },
    {
        "col1": "Томская область",
        "col2": 33,
        "col3": "Чердатское сельское поселение",
        "col4": "636859 Томская область, Зырянский район, с.Чердаты, ул.Советская, 1а\n(адрес электронной почты: cherdsp@tomsk.gov.ru; тел. 8(38243) 32137)\n"
    },
    {
        "col1": "Томская область",
        "col2": 34,
        "col3": "Вертикосское сельское поселение",
        "col4": "636753, Томская область, Каргасокский район, с. Вертикос, ул. Молодежная,1/ mkuvertikos@yandex.ru/\n8(38253)  36-189;\n"
    },
    {
        "col1": "Томская область",
        "col2": 35,
        "col3": "Каргасокское сельское поселение",
        "col4": "636700, Томская область, Каргасокский район,  с. Каргасок, ул. Новая,1\nkargs@tomsk.gov.ru\n8 (38253)23 423\n"
    },
    {
        "col1": "Томская область",
        "col2": 36,
        "col3": "Нововасюганское сельское поселение",
        "col4": "636740, Томская область, Каргасокский район, с. Новый Васюган, ул. Советская,49/\nnovyjvasiugan@yandex.ru/\n 8 (38253) 29 284\n"
    },
    {
        "col1": "Томская область",
        "col2": 37,
        "col3": "Новоюгинское сельское           поселение",
        "col4": "636714,, Томская область, Каргасокский район,  с. Новоюгино, ул. Цетральная, 44-2/\nansp06@mail.ru/ \n 8 (38253) 37-132\n"
    },
    {
        "col1": "Томская область",
        "col2": 38,
        "col3": "Средневаюганское сельское поселение",
        "col4": " 636733, Томская область, Каргасокский район, с. Средний Васюган, ул. Гагарина,6/\nsvasugan@mail.ru/ \n8 (38253) 25-172\n"
    },
    {
        "col1": "Томская область",
        "col2": 39,
        "col3": "Среднетымское сельское поселение",
        "col4": " 636754, Томская область, Каргасокский район, п. Молодежный, ул. Школьная, 2/ sredtympos@yandex.ru/  8 (38253) 44-146                                                  "
    },
    {
        "col1": "Томская область",
        "col2": 40,
        "col3": "Сосновское сельское поселение",
        "col4": "636712, Томская область, Каргасокский  с.Сосновка, ул. Школьная, 18/ sosnovka.a@yandex.ru/\n 8 (38253) 38-144\n"
    },
    {
        "col1": "Томская область",
        "col2": 41,
        "col3": "Вороновское сельское поселение",
        "col4": "636171, Томская область, Кожевниковский район, с. Вороново, ул. Уткина, д. 17 \n voronovosp@mail.ru\n(838244) 31-169; (838244) 31-148\n"
    },
    {
        "col1": "Томская область",
        "col2": 42,
        "col3": "Кожевниковское сельское поселение",
        "col4": "636160, Томская область, Кожевниковский район, с. Кожевниково, ул. Гагарина, д. 24\n kozhev_posel@mail.ru\n(838244) 44-745; (838244) 21-961\n"
    },
    {
        "col1": "Томская область",
        "col2": 43,
        "col3": "Малиновское сельское поселение",
        "col4": "636176, Томская область, Кожевниковский район, с. Малиновка, ул. Школьная, д. 13 MalinovkaSP@mail.ru\n(838244) 53-146\n"
    },
    {
        "col1": "Томская область",
        "col2": 44,
        "col3": "Новопокровское сельское поселение",
        "col4": "636182, Томская область, Кожевниковский район, с. Новопокровка, ул. Садовая, д. 2а voronovosp@mail.ru\n(838244) 58-125, (838244) 58-134\n"
    },
    {
        "col1": "Томская область",
        "col2": 45,
        "col3": "Песочнодубровское сельское поселение",
        "col4": "636164, Томская область, Кожевниковский район, с. Песочнодубровка, ул. Молодежная, д. 37 \npesoch@tomsk.gov.ru\n(838244) 42-332\n"
    },
    {
        "col1": "Томская область",
        "col2": 46,
        "col3": "Староювалинское сельское поселение",
        "col4": "636165, Томская область, Кожевниковский район, с. Старая Ювала, ул. Ульяновская, д. 3а\n yuvala@mail.ru, stuval@tomsk.gov.ru\n(838244) 41-327; (838244) 41-162\n"
    },
    {
        "col1": "Томская область",
        "col2": 47,
        "col3": "Уртамское сельское поселение",
        "col4": "636170, Томская область, Кожевниковский район, с. Уртам, ул. Фрунзе, д. 33 \nurtam@tomsk.gov.ru\n(838244) 51-351\n"
    },
    {
        "col1": "Томская область",
        "col2": 48,
        "col3": "Чилинское сельское поселение",
        "col4": "636172, Томская область, Кожевниковский район, с. Чилино, ул. Кустарная, д. 1 \nchilino@mail.ru\nchilino@kozhevnikovo.gov70.ru\nlarisa.jul2015@yandex.ru\n8.6(838244) 35-407, (838244)35-332\n"
    },
    {
        "col1": "Томская область",
        "col2": 49,
        "col3": "Колпашевское городское поселение",
        "col4": "636460, Томская область, Колпашевский район, г. Колпашево, ул. Победы, д. 5\nkolpash@tomsk.gov.ru\n8 (38254) 5 82 62\n"
    },
    {
        "col1": "Томская область",
        "col2": 50,
        "col3": "Инкинское сельское поселение",
        "col4": "636443,Томская область, Колпашевский район,  д. Инкино, пер. Кооперативный 11\ninkino@tomsk.gov.ru\n8 (38254) 9-31-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 51,
        "col3": "Новогоренское сельское поселение",
        "col4": "636444, Томская область, Еолпашевский район, д. Новогорное, ул. Береговая 42, n-gorins@tomsk.gov.ru \n8 (38254) 9 41 36\n"
    },
    {
        "col1": "Томская область",
        "col2": 52,
        "col3": "Новоселовское сельское поселение",
        "col4": "636425, Томская область, Колпашевский район,  с. Новоселово, ул. Центральная 11/1\nn-selovs@tomsk.gov.ru\n8 (38254) 22 160\n"
    },
    {
        "col1": "Томская область",
        "col2": 53,
        "col3": "Саровское сельское поселение",
        "col4": "636431, Томская область, Колпашевский район, п. Большая Саровка, ул. Советская 35/2\nsarovka@tomsk.gov.ru\n8 (38254) 2-74-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 54,
        "col3": "Чажемтовское сельское поселение",
        "col4": "636423,Томская область, Колпашевский район,  с. Чажемто, ул. Ленина 24\nchagemto@tomsk.gov.ru, 8 (38254) 2 12 42\n"
    },
    {
        "col1": "Томская область",
        "col2": 55,
        "col3": "Володинское сельское поселение",
        "col4": "636310, Томская область, Кривошеинский  район,  с. Володино, ул. Советская, 31, volodino@tomsk.qov.ru, 8(38251) 45-433"
    },
    {
        "col1": "Томская область",
        "col2": 56,
        "col3": "Иштанское сельское поселение",
        "col4": "636312 Томская область Кривошеинский район с. Иштан, ул. Лесная 1А, ishtan00@yandex.ru, 8(38251) 43-495"
    },
    {
        "col1": "Томская область",
        "col2": 57,
        "col3": "Красноярское сельское поселение",
        "col4": "636320, Томская область, Кривошеинский район, с. Красный Яр, ул. Советская, 60, krasyar@tomsk.gov.ru, 8(38251) 31-330"
    },
    {
        "col1": "Томская область",
        "col2": 58,
        "col3": "Новокривошеинское сельское поселение",
        "col4": "636307, Томская область, Кривошеинский район, с. Новокривошеино, ул. Советская, 1, novokriv@yandex.ru, 8(38251) 47-433"
    },
    {
        "col1": "Томская область",
        "col2": 59,
        "col3": "Петровское сельское поселение",
        "col4": "636313, Томская область, Кривошеинский район, Петровка, ул. Комсомольская,12, реtrovka@tomsk.gov.ru, 8(38251) 44-416"
    },
    {
        "col1": "Томская область",
        "col2": 60,
        "col3": "Пудовское сельское поселение",
        "col4": "636316, Томская область, Кривошеинский район, с. Пудовка, ул. Центральная, 64, pudovka@tomsk.gov.ru, 8(38251) 46-431"
    },
    {
        "col1": "Томская область",
        "col2": 61,
        "col3": "Могочинское сельское поселение",
        "col4": "636340, Томская область, Молчановский район, с. Могочино, ул. Заводская, 3, +7 (38256) 3‒31‒32,  admogochino@sibmail.ru"
    },
    {
        "col1": "Томская область",
        "col2": 62,
        "col3": "Молчановское сельское поселение",
        "col4": "636330, Томская область, Молчановский район, с. Молчаново, ул. Димитрова, 51, т. 8 (38256) 21-5-86, ml-molch@tomsk.gov.ru "
    },
    {
        "col1": "Томская область",
        "col2": 63,
        "col3": "Наргинское сельское поселение",
        "col4": "636346, Томская область, Молчановский район, с. Нарга, ул. К. Маркса, 41, adm-narga@molchanovo.gov70.ru\n8 (256) 32-4-95\n"
    },
    {
        "col1": "Томская область",
        "col2": 64,
        "col3": "Суйгинское сельское поселение",
        "col4": "636345 Томская область Молчановский район, с. Суйга, ул. Комарова, 29, suiga@molchanovo.gov70"
    },
    {
        "col1": "Томская область",
        "col2": 65,
        "col3": "Тунгусовское  сельское поселение",
        "col4": "636353, Томская область, Молчановский район, с. Тунгусово, ул. Кнакиса, 5, +7 (382-56) 35-4-37,  tungusovosp@molchanovo.gov70.ru"
    },
    {
        "col1": "Томская область",
        "col2": 66,
        "col3": "Заводское сельское поселение",
        "col4": "636608, Томская область, Парабельский район\nп. Заводской, ул. 60 лет СССР, 19/\n8(38252)3-91-66, 8(38252)3-91-34/ zavodskoe@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 67,
        "col3": "Нарымское сельское поселение",
        "col4": "636611, Томская область,  Парабельский район, \nс. Нарым, ул. Сибирская, 2/\n8(38252)3-32-31, 8(38252)3-32-32/ narim@parabel.gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 68,
        "col3": "Новосельцевское сельское поселение",
        "col4": "636607, Томская область, Парабельский район,\n с. Новосельцево , ул. Шишкова, 5/ 8(382525)3-61-58, 8(38252)3-61-58/\nn-selcevo@parabel.gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 69,
        "col3": "Парабельское сельское поселение",
        "col4": "636600, Томская область, Парабельский рвйон, \nс. Парабель , ул. Свердлова, 5/ 8(382525)2-19-63, 8(38252)2-13-43/\n      Sp-parab@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 70,
        "col3": "Старицинское сельское поселение",
        "col4": "636626, Томская область, Парабельский район, \nс. Старица, ул. Советская, 58 «а»/ 8-913-848-19-09/ istomina-1k@yandex.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 71,
        "col3": "Комсомольское сельское поселение",
        "col4": "636942, Томская область, Первомайский район, с. Комсомольск, ул. Железнодорожная 40/1, volkova.azlk1214@yandex.ru, 8(38245)23-249"
    },
    {
        "col1": "Томская область",
        "col2": 72,
        "col3": "Куяновское сельское поселение",
        "col4": "636953, Томская область, Первомайский район, село Куяново, ул. Центральная, 18/1, kuspmail@mail.ru, тел. 8 (38245) 33-198"
    },
    {
        "col1": "Томская область",
        "col2": 73,
        "col3": "Новомариинское сельское поселение",
        "col4": "636935 Томская обл., Первомайский район, с. Новомариинка, ул. Центральная, 42, novosp@tomsk.gov.ru, 8 (38245) 35-123"
    },
    {
        "col1": "Томская область",
        "col2": 74,
        "col3": "Первомайское сельское поселение",
        "col4": "636930, Томская область, Первомайский район, с. Первомайское, ул. Советская, д.14, pmsp@tomsk.gov.ru, 8 (38245)21-060"
    },
    {
        "col1": "Томская область",
        "col2": 75,
        "col3": "Сергеевское сельское поселение",
        "col4": "Сергеевское сельское поселение: 636941, Томская область, Первомайский район, с. Сергеево, ул. Школьная д. 3, sergeevo@tomsk.gov.ru, 8 (38245) 43-320"
    },
    {
        "col1": "Томская область",
        "col2": 76,
        "col3": "Улу-Юльское сельское поселение",
        "col4": "636948, Томская область, Первомайский район, поселок Улу-Юл, улица 50 Лет Октября, д.5 oks4na5mir@yandex.ru, тел. 8 (38245) 44-123"
    },
    {
        "col1": "Томская область",
        "col2": 77,
        "col3": "Белоярское сельское поселение",
        "col4": "636909, Томская область, Тегульдетский район, \nп. Белый Яр, ул. Центральная, 2\nТелефон: +7 (382-46) 3-41-15,\nE-mail: belyarsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 78,
        "col3": "Берегаевское сельское поселение",
        "col4": "636911, Томская область, Тегульдетский район,\nп. Берегаево, ул. Ленинская, д. 17а\nТелефон: +7 (382-46) 3-31-89,\nE-mail: beregsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 79,
        "col3": "Тегульдетское сельское поселение",
        "col4": "636900, Томская область, Тегульдетский район, \nс. Тегульдет, ул. Ленина, 156\nТелефон: +7 (382–46) 2-19-25, 2-19-13,\nE-mail: tegsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 80,
        "col3": "Черноярское сельское поселение",
        "col4": "636902, Томская область, Тегульдетский район, \nп. Черный Яр, ул. Комсомольская, 7\nТелефон: +7 (382–46) 3-11-42,\nE-mail: blacksp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 81,
        "col3": "Богашевское сельское поселение",
        "col4": "634570, Томская область, Томский район, с. Богашево, ул.Советская, 6, / \nадрес электронной\nпочты: bogashevo-sp@tomsky.gov70.ru/\nномер телефона 931-269\n"
    },
    {
        "col1": "Томская область",
        "col2": 82,
        "col3": "Воронинское сельское поселение",
        "col4": "634532, Томская область, Томский район, д. Воронино, ул. Центральная, 74/ \nадрес электронной\nпочты: voronino-sp@tomsky.gov70.ru/\nномер телефона 950-274\n"
    },
    {
        "col1": "Томская область",
        "col2": 83,
        "col3": "Заречное сельское поселение",
        "col4": "634521, Томская область, Томский район,  с.Кафтанчиково, ул.Коммунистическая, д.86 \nадрес электронной\nпочты: zarechnoe-sp@tomsky.gov70.ru /\nномер телефона 961-200\n"
    },
    {
        "col1": "Томская область",
        "col2": 84,
        "col3": "Зональненское сельское поселение",
        "col4": "634507, Томская область, Томский район, \nп.Зональная, ул.Совхозная, 10 / \nадрес электронной\nпочты: zonalniy-sp@tomsky.gov70.ru /\nномер телефона 923-969\n"
    },
    {
        "col1": "Томская область",
        "col2": 85,
        "col3": "Зоркальцевское сельское поселение",
        "col4": "634515, Томская область, Томский район, с. Зоркальцево, ул. Совхозная, 14/ \nадрес электронной\nпочты: zorkalsp@gov70.ru/\nномер телефона 915-790\n"
    },
    {
        "col1": "Томская область",
        "col2": 86,
        "col3": "Итатское сельское поселение",
        "col4": "634540, Томская область, Томский район, с. Итатка, ул. Гагарина,1 / \nадрес электронной\nпочты: itatkasp@gov70.ru /\nномер телефона 959-323\n"
    },
    {
        "col1": "Томская область",
        "col2": 87,
        "col3": "Калтайское сельское поселение",
        "col4": "634523, Томская область, Томский район, с. Курлек, ул. Трактовая, 48  / \nадрес электронной\nпочты: kaltay-sp@tomsky.gov70.ru, kaltai.adm.fin@mail.ru /\nномер телефона 968-293\n"
    },
    {
        "col1": "Томская область",
        "col2": 88,
        "col3": "Копыловское сельское поселение",
        "col4": "634537, Томская область, п. Копылово, ул. Новая, 13/\nадрес электронной\nпочты: kopylovosp@gov70.ru/\nномер телефона 989-101\n"
    },
    {
        "col1": "Томская область",
        "col2": 89,
        "col3": "Корниловское сельское поселение",
        "col4": "634538, Томская область, Томский район, с.Корнилово,  ул.Гагарина, 29А,/ \nадрес электронной\nпочты: kornilovosp@gov70.ru/\nномер телефона 963-171\n"
    },
    {
        "col1": "Томская область",
        "col2": 90,
        "col3": "Малиновское сельское поселение",
        "col4": "634580, Томская область, Томский район, с. Малиновка, ул. Чулымская, 30 / \nадрес электронной\nпочты: malinovka-sp@tomsky.gov70.ru/\nномер телефона 920-144\n"
    },
    {
        "col1": "Томская область",
        "col2": 91,
        "col3": "Межениновское сельское поселение",
        "col4": "634520, Томская область, Томский район,  с.Межениновка, ул.Первомайская, 23, / \nадрес электронной\nпочты: mezheninsp@gov70.ru/\nномер телефона 969-725\n"
    },
    {
        "col1": "Томская область",
        "col2": 92,
        "col3": "Мирненское сельское поселение",
        "col4": "634539, Томская область, Томский район, п. Мирный, ул. Трудовая, 10 / \nадрес электронной\nпочты: mirniy-sp@tomsky.gov70.ru/\nномер телефона 955-198\n"
    },
    {
        "col1": "Томская область",
        "col2": 93,
        "col3": "Моряковское сельское поселение",
        "col4": "634516, Томская облатсь, Томский район, с. Моряковский Затон, ул. Советская, 27/ \nадрес электронной\nпочты: morzaton-sp@tomsky.gov70.ru/\nномер телефона 927-498\n"
    },
    {
        "col1": "Томская область",
        "col2": 94,
        "col3": "Наумовское сельское поселение",
        "col4": "634581, Томская область, Томский рай1он, с. Наумовка, ул. Советская, 7А/ \nадрес электронной\nпочты: naumovkasp@gov70.ru /\nномер телефона 969-339\n"
    },
    {
        "col1": "Томская область",
        "col2": 95,
        "col3": "Новорождественское сельское поселение",
        "col4": "634535, Томская область, Томский район, с.Новорождественское, ул. Советская, 62/ \nадрес электронной\nпочты: n-rozhdestven-sp@tomsky.gov70.ru/\nномер телефона 946-589\n"
    },
    {
        "col1": "Томская область",
        "col2": 96,
        "col3": "Октябрьское сельское поселение",
        "col4": "634583, Томская область, Томский район, с. Октябрьское, ул. Заводская,14/ адрес электронной\nпочты: oktyabr-sp@tomsky.gov70.ru /\nномер телефона 925-211\n"
    },
    {
        "col1": "Томская область",
        "col2": 97,
        "col3": "Рыболовское сельское поселение",
        "col4": "634518, Томская область, Томский район, с.Рыбалово, ул.Коммунистическая, 7,/ \nадрес электронной\nпочты: rybalovo-sp@tomsky.gov70.ru /\nномер телефона 919-131\n"
    },
    {
        "col1": "Томская область",
        "col2": 98,
        "col3": "Спасское сельское поселение",
        "col4": "пер.Новый, с.Вершинино, Томский район, Томская область, 634528, Томская область, Томский район, с. Вершинино, пер. Новый / \nадрес электронной\nпочты: spasskoe-sp@tomsky.gov70.ru /\nномер телефона 959-608\n"
    },
    {
        "col1": "Томская область",
        "col2": 99,
        "col3": "Турунтаевское сельское поселение",
        "col4": "634534, Томская область, Томский район, с. Турунтаево, ул. Новая, 2а / \nадрес электронной\nпочты: turuntaevo-sp@tomsky.gov70.ru /\nномер телефона 947-175\n"
    },
    {
        "col1": "Томская область",
        "col2": 100,
        "col3": "Коломинское сельское поселение",
        "col4": "636410, Томская область, Чаинский район, с. Коломинские Гривы, ул. Советская, 21/ kolomin@tomsk.gov.ru/ 8(38257) 45244"
    },
    {
        "col1": "Томская область",
        "col2": 101,
        "col3": "Подгорнское сельское поселение",
        "col4": "636400, Томская область, Чаинский район, \nс. Подгорное, ул. Ленинская, 4, стр. 1/ podgorns@tomsk.gov.ru/ 8(38257) 21102\n"
    },
    {
        "col1": "Томская область",
        "col2": 102,
        "col3": "Усть-Бакчарское сельское поселение",
        "col4": "636404, Томская область, Чаинский район, \nс. Усть-Бакчар, ул. Центральная, 17/ inna_novokreshenova@mail.ru/ \n8(38257) 35167\n"
    },
    {
        "col1": "Томская область",
        "col2": 103,
        "col3": "Чаинское сельское поселение",
        "col4": "636407,Томская область, Чаинский район, \nс. Чаинск, ул. Комсомольская, 14/ chainsksp@mail.ru/ 8(38257) 56119\n"
    },
    {
        "col1": "Томская область",
        "col2": 104,
        "col3": "Анастасьевское сельское поселение",
        "col4": "636149, Томская область. Шегарский район,\nс. Анастасьевка, пер. Школьный, д.2\nanastasevka05@mail.ru\n8(38247)3-91-37\n"
    },
    {
        "col1": "Томская область",
        "col2": 105,
        "col3": "Баткатское сельское поселение",
        "col4": "636141, Томская область, Шегарский район,\nс. Баткат, пер. Кооперативный, д.1\nadm-batkat@shegarsky.gov70.ru\n8(38247) 341-32, 8(38247)341-09\n"
    },
    {
        "col1": "Томская область",
        "col2": 106,
        "col3": "Побединское сеельское поселение ",
        "col4": "636143, п. Победа, Шегарский район,\nТомская область,\nул. Коммунистическая д.112а, помещение 2\nssamotoylov@mail.ru\npobeda@tomsk.gov.ru\n8(38247) 42-195, 8(38247) 42-264\n"
    },
    {
        "col1": "Томская область",
        "col2": 107,
        "col3": "Северное сельское поселение",
        "col4": "636156,Томская область, Шегарский район,\nс. Монастырка, ул. Советская, д.51а\nadm-severnoe@shegarsky.gov70.ru\n+7 (382-47) 2-97-84\n"
    },
    {
        "col1": "Томская область",
        "col2": 108,
        "col3": "Трубачевское сельское поселение",
        "col4": "636145, Томская область. Шегарский район,\nс. Трубачево, ул. Центральная, д.7\ntrubachevskoe@mail.ru\n8(38247) 38-116, 8(38247) 38-127\n"
    },
    {
        "col1": "Томская область",
        "col2": 109,
        "col3": "Шегарское сельское поселение",
        "col4": "636130,Томская область, Шегарский район,\nс.Мельниково, ул. Московская,д.13\nshegarka00@mail.ru\n8 (38247) 2-11-72\n8(38247) 2-37-29\n"
    },
    {
        "col1": "Томская область",
        "col2": 110,
        "col3": "Отдел опеки и попечительства Администрации города Кедрового",
        "col4": " 636615, Томская область,  г. Кедровый, 1-й мкр. ул., д.39/1,\nkedropeka@tomsk.gov.ru\n(38250)35-314\n"
    },
    {
        "col1": "Томская область",
        "col2": 111,
        "col3": "Администрация ЗАТО Северск",
        "col4": "636070, Томская область, г. Северск, пр. Коммунистический, 51\n8 (382-3) 77-23-23\nzato-seversk@gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 112,
        "col3": "Администрация городского округа Стрежевой Отдел жилищной политики",
        "col4": "636785, Томская область, г. Стрежевой, ул. Ермакова, д,46А, (38259) 51-560,                                     Koroleva@admstrj.romsk.ru"
    },
    {
        "col1": "Томская область",
        "col2": 113,
        "col3": "Администрация Кировского района",
        "col4": "634034, г. Томск, пр. Кирова, д. 11а\nmail@aks.admin.tomsk.ru\n+7 3822 56-40-95\n+7 3822 56-43-22\n"
    },
    {
        "col1": "Томская область",
        "col2": 114,
        "col3": "Администрация Ленинского района",
        "col4": "634009, г. Томск, ул. Карла Маркса, д. 34\nalradmin@alr.admin.tomsk.ru\n+7 3822 51-71-13\n+7 3822 51-71-43\n"
    },
    {
        "col1": "Томская область",
        "col2": 115,
        "col3": "Администрация Октябрьского района",
        "col4": "634003, г. Томск, ул. Пушкина, д. 17\nalomail@alo.admin.tomsk.ru\n+7 3822 65-94-93\n+7 3822 65-94-49\n"
    },
    {
        "col1": "Томская область",
        "col2": 116,
        "col3": "Администрация Советского района",
        "col4": "634012, г. Томск, ул. Елизаровых ,59\nkans@asr.admin.tomsk.ru\n+7 3822 54-45-25\n+7 3822 54-30-54\n"
    },
    {
        "col1": "Тульская область",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты Тульской области",
        "col4": "г. Тула, ул. Пушкинская. д. 29, тел. 8 (4872) 24-52-50, mintrud@tularegion.ru"
    },
    {
        "col1": "Тюменcкая область",
        "col2": 1,
        "col3": "Территориальные подразделения Департамента социального развития Тюменской области",
        "col4": "Территориальные подразделения Департамента социального развития Тюменской области\nhttps://soc.admtyumen.ru/OIGV/dsrto/about/structure/soc_zash.htm "
    },
    {
        "col1": "Тюменcкая область",
        "col2": 2,
        "col3": "Департамент социального развития Тюменской области",
        "col4": "\n625048, Тюменская обл., г. Тюмень, ул. Республики, д. 83а / dsoc@72to.ru / (3452) 50-21-68"
    },
    {
        "col1": "Удмуртская Республика",
        "col2": 1,
        "col3": "Управление социальной защиты населения  Удмуртской Республики  при  Министерстве социальной политики и труда Удмуртской Республики",
        "col4": "426033, Удмуртская Республика, г. Ижевск, ул. 30 Лет Победы, д. 20А; 8(3412) 22-15-90; uszn@msp-to.udmr.ru"
    },
    {
        "col1": "Ульяновская область\n",
        "col2": 1,
        "col3": "Министерство социального развития Ульяновской области",
        "col4": "432071, г.Ульяновск, ул.Федерации, д.60, (8422) 44-96-84, доб. 5268, 5210 z.praw@mail.ru"
    },
    {
        "col1": "Хабаровский край",
        "col2": 1,
        "col3": "Министерство социальной защиты Хабаровского края",
        "col4": "г. Хабаровск, ул. Фрунзе, д. 67, 680000. Электронная почта: mszn@khv.gov.ru, Тел.:8 (4212) 40-25-05"
    },
    {
        "col1": "Херсонская область",
        "col2": 1,
        "col3": "Администрация Алешкинского муниципального округа",
        "col4": "275100; Российская Федерация, Херсонская область, г. Алешки,              ул. Гвардейская, 24/ oleshki@khogov.ru/           +7 (990) 138-50-61"
    },
    {
        "col1": "Херсонская область",
        "col2": 2,
        "col3": "Администрация Великолепетихского муниципального округа",
        "col4": "Херсонская область, Великолепетихский р-н,                 пгт. Великая Лепетиха,                    ул. Соборная, д. 3а/ velikolepetikhskii_mo@khogov.ru/ +7(990)153-11-98"
    },
    {
        "col1": "Херсонская область",
        "col2": 3,
        "col3": "Администрация Верхнерогачикского муниципального округа",
        "col4": "274402;Российская Федерация, Херсонская обл., пгт. Верхний Рогачик, ул. Центральная, 48/  verhniyrogachik.vga@khogov.ru/     +7(990)072–32–45                    "
    },
    {
        "col1": "Херсонская область",
        "col2": 4,
        "col3": "Администрация Генического муниципального округа",
        "col4": "275502; Российская Федерация, Херсонская область, г. Геническ,    ул. Центральная, 5/ genichesk@khogov.ru/       +7(990)111-68-27"
    },
    {
        "col1": "Херсонская область",
        "col2": 5,
        "col3": "Администрация Голопристанского муниципального округа",
        "col4": "275600; Российская Федерация, Херсонская область, г. Голая Пристань, ул. 1 Мая, 41/ golapristan@khogov.ru/      +7(990)225-19-39"
    },
    {
        "col1": "Херсонская область",
        "col2": 6,
        "col3": "Администрация Горностаевского муниципального округа",
        "col4": "274601; Российская Федерация, Херсонская область,                       пгт. Горностаевка, ул. Покровская, 145/ gornastaevskaya_vga@khogov.ru/    80 (5544) 4-11-43"
    },
    {
        "col1": "Херсонская область",
        "col2": 7,
        "col3": "Администрация Ивановского муниципального округа",
        "col4": "275401; Российская Федерация, Херсонская область, пгт. Ивановка, ул. Ивановская, 34а/ ivanovka@khogov.ru/      +79900137991"
    },
    {
        "col1": "Херсонская область",
        "col2": 8,
        "col3": "Администрация Каланчакского муниципального округа",
        "col4": "275800; Российская Федерация, Херсонская область, пгт. Каланчак, ул. Херсонская, 1/ vga.kalanchak@khogov.ru/ +7(990)100–58–00"
    },
    {
        "col1": "Херсонская область",
        "col2": 9,
        "col3": "Администрация Каховского муниципального округа",
        "col4": "274803; Российская Федерация, Херсонская область, г. Каховка,     ул. Большая Куликовская, 103/ ivga.kakhovka@yandex.ru/ +79900674406 "
    },
    {
        "col1": "Херсонская область",
        "col2": 10,
        "col3": "Администрация Нижнесерогозского муниципального округа",
        "col4": "274701; Российская Федерация, Херсонская область, пгт. Нижние Серогозы, ул. Садовая, 7/ nizhneserogozsk@khogov.ru/            +7(990) 092-04-09"
    },
    {
        "col1": "Херсонская область",
        "col2": 11,
        "col3": "Администрация Новотроицкого муниципального округа",
        "col4": "275300; Российская Федерация, Херсонская область, пгт. Новотроицкое, ул. Соборная, 73/ Novotroitsk_admin@khogov.ru/ +7(990)159–17–58"
    },
    {
        "col1": "Херсонская область",
        "col2": 12,
        "col3": "Администрация Скадовского муниципального округа",
        "col4": "275700; Российская Федерация, Херсонская область, г. Скадовск, ул.Александровская, 34/ skadovsk@khogov.ru/ +7(990)104–18–19"
    },
    {
        "col1": "Херсонская область",
        "col2": 13,
        "col3": "Администрация Чаплынского муниципального округа",
        "col4": "275200; Российская Федерация, Херсонская область, Чаплынский район, пгт. Чаплынка, ул. Парковая, 40/ chaplvga@khogov.ru/ +7(990)111–35–94; +7(990)036–21–91"
    },
    {
        "col1": "Ханты-Мансийский автономны округ",
        "col2": 1,
        "col3": "Департамент социального развития \nХанты-Мансийского автономного округа – Югры\n",
        "col4": "628006, ул. Мира, 5, г. Ханты-Мансийск,\nХанты-Мансийский автономный округ - Югра \nSocprotect@admhmao.ru\nтелефон :8 (3467) 32-20-40 (доб. 3602, 3603, 3696)\n"
    },
    {
        "col1": "Челябинская область",
        "col2": 1,
        "col3": "Министерство социальных отношений Челябинской области",
        "col4": "454048, г. Челябинск, ул. Воровского, д. 30, тел. (351)232-39-28 (приемная), (351) 232-39-94, 264-07-90 (отдел организации работы по опеке и попечительству), opek@minsoc74.ru"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 1,
        "col3": "Министерство труда, занятости и социального развития Чеченской Республики",
        "col4": "ул. Деловая, 15, г. Грозный,\nЧеченская Республика, 364020\nтел: 8 (8712) 22-57-96; \nе-mail: mail@mtchr.ru."
    },
    {
        "col1": "Чувашская Республика",
        "col2": 1,
        "col3": "Алатырский муниципальный округ Чувашской Республики",
        "col4": "429826, Чувашская Республика, г. Алатырь, ул. Ленина, д. 29     8(83531) 2-24-08 alatr.priem@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 2,
        "col3": "Аликовский муниципальный округ Чувашской Республики",
        "col4": "429250, Чувашская Республика, Аликовский район, с. Аликово, \nул. Октябрьская, д. 21 8(83535) 22-3-82 alikov@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 3,
        "col3": "Батыревский муниципальный округ Чувашской Республики",
        "col4": "429350, Чувашская Республика, Батыревский район, с. Батырево, \nпр. Ленина, д. 5 8(83532) 6-24-20 batyr@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 4,
        "col3": "Вурнарский муниципальный округ Чувашской Республики",
        "col4": "429220, Чувашская Республика, Вурнарский район, пгт. Вурнары, \nул. Советская, д. 20 8(83537) 2-51-91 vurnar@cap.ru\n\n\n"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 5,
        "col3": "Ибресинский муниципальный округ Чувашской Республики",
        "col4": "429700, Чувашская Республика, Ибресинский район, п. Ибреси, \nул. Маресьева, д. 49  \n 8(83538) 2-12-52 ibresi@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 6,
        "col3": "Канашский муниципальный округ Чувашской Республики",
        "col4": "429330, г. Канаш, ул.30 лет Победы, д.87  8(83533) 2-24-90 kanash@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 7,
        "col3": "Козловский муниципальный округ Чувашской Республики",
        "col4": "429430, Чувашская Республика, Козловский район, г. Козловка, \nул. Ленина, д. 55  8(83534) 2-23-37 kozlov@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 8,
        "col3": "Комсомольский муниципальный округ Чувашской Республики",
        "col4": "429140, Чувашская Республика, Комсомольский район, \nс. Комсомольское, ул. Заводская, д. 57 8(83539) 5-11-43  komsml@cap.ru\n\n"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 9,
        "col3": "Красноармейский муниципальный округ Чувашской Республики",
        "col4": "429620, Чувашская Республика, Красноармейский район, \nс. Красноармейское, ул. Ленина, д. 35 8(83530) 2-18-41 adm.krarm@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 10,
        "col3": "Красночетайский муниципальный округ Чувашской Республики",
        "col4": "429040 Чувашская Республика, с. Красные Четаи, пл. Победы, д. 1 8(83551) 2-16-76 krchet-adm@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 11,
        "col3": "Мариинско-Посадский муниципальный округ Чувашской Республики",
        "col4": "429570, Чувашская Республика, г. Мариинский Посад,  \nул. Николаева, д. 47               8(83542) 2-14-42 marpos@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 12,
        "col3": "Моргаушский муниципальный округ Чувашской Республики",
        "col4": "429530, Чувашская Республика, Моргаушский район, \nс. Моргауши, ул. Мира, д. 6 8(83541) 62-2-39 morgau@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 13,
        "col3": "Порецкий муниципальный округ Чувашской Республики",
        "col4": "429020, Чувашская Республика, Порецкий район, с. Порецкое, \nул. Ленина, д. 3  8(83543) 2-19-53 porezk@cap.ru; porezk_org3@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 14,
        "col3": "Урмарский муниципальный округ Чувашской Республики",
        "col4": "429400, Чувашская Республика, Урмарский район, п. Урмары, \nул. Мира, д. 5  8(83544) 2-10-85 urmary@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 15,
        "col3": "Цивильский муниципальный округ Чувашской Республики",
        "col4": "429900, Чувашская Республика, Цивильский район, г. Цивильск, ул. Маяковского, \nд. 12  8(83545) 21-3-26 zivil@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 16,
        "col3": "Чебоксарский муниципальный округ Чувашской Республики",
        "col4": "429500, Чувашская Республика, Чебоксарский район, п. Кугеси, \nул. Советская, д. 25  8(83540) 2-30-93 chebs@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 17,
        "col3": "Шемуршинский муниципальный округ Чувашской Республики",
        "col4": "429370, Чувашская Республика, Шемуршинский район, \nс. Шемурша, ул. Советская, д. 8  8(83546)-2-31-07 shemur@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 18,
        "col3": "Шумерлинский муниципальный округ Чувашской Республики",
        "col4": "429122, Чувашская Республика, \nг. Шумерля, ул. Октябрьская, \nд. 24  (83536) 2-06-29 gshum@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 19,
        "col3": "Ядринский муниципальный округ Чувашской Республики",
        "col4": "429060, Чувашская Республика, Ядринский район, г. Ядрин, \nул. 30 лет Победы, д. 1   8(83547) 22-0-36 yadrin@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 20,
        "col3": "Яльчикский муниципальный округ Чувашской Республики",
        "col4": "429380, Чувашская Республика, Яльчикский район, с. Яльчики, \nул. Иванова, д. 16   8(83549) 2-56-12 yaltch@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 21,
        "col3": "Янтиковский муниципальный округ Чувашской Республики",
        "col4": "429290 Чувашская Республика, Янтиковский район, с. Янтиково, пр. Ленина, д. 13  (83548) 2-18-60 yantik@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 22,
        "col3": "г. Новочебоксарск Чувашской Республики",
        "col4": "429951 Чувашская Республика, \nг. Новочебоксарск,  \nул. Винокурова, д.14   8(8352) 73-81-24,\n73-33-88 nowch-doc5@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 23,
        "col3": "Адмимнистрация Калининского района г.Чебоксары",
        "col4": "428022, Чувашская Республика, г.Чебоксары, ул. 50 лет Октября, \nд. 10 а  8(8352) 23-44-28, \n23-44-27 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 24,
        "col3": "Администраация Ленинского района г.Чебоксары",
        "col4": "428000, Чувашская Республика, г.Чебоксары, ул. Гагарина, д. 22 а\n8(8352) 23-44-76, \n23-44-75, 23-40-17 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 25,
        "col3": "Администрация Московского района г. Чебоксары ",
        "col4": "428017, Чувашская Республика, г. Чебоксары, пр. Московский, \nд. 33 «а»\n8(8352) 23-52-18,\n23-52-33, 23-52-15, 23-52-29  gcheb@cap.ru"
    },
    {
        "col1": "Чукотский автномный округ",
        "col2": 1,
        "col3": "Департамент социальной политики Чукотского автономного округа",
        "col4": "689000, Чукотский автономный округ, г. Анадырь, ул. Беринга, д. 2, тел. (42722) 6-21-00, info@dsp.chukotka-gov.ru "
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 1,
        "col3": "Департамент образования Администрации города Новый Уренгой",
        "col4": "629300, Ямало-Ненецкий автономный округ, г. Новый Уренгой, ул. Индустриальная, д. 4\n+7(3494)22-16-75\n portal.edunur.ru\nedu@nur.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 2,
        "col3": "Департамент образования Администрации муниципального образования город Ноябрьск",
        "col4": "629807, Ямало-Ненецкий автономный округ, г. Ноябрьск, ул. 8 Марта, д. 6\n+7(3496)42-15-85\n  donoyabrsk.yanao.ru\ndo@noyabrsk.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 3,
        "col3": "Департамент образования Администрации муниципального образования город Салехард​",
        "col4": "629007, Ямало-Ненецкий автономный округ, г. Салехард, ул. Ямальская, д. 30\n+7(34922)3-21-00\nedu.shd.ru\ndo@slh.yanao.ru\n\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 4,
        "col3": "Департамент образования Администрации муниципального образования Надымский район",
        "col4": "629730, Ямало-Ненецкий автономный округ, Надымский район, г. Надым, ул. Зверева, д.12/2\n+7(3499)52-38-24\nnadymedu.ru\ndo@nadym.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 5,
        "col3": "Департамент образования Администрации муниципального образования Ямальский район",
        "col4": "629700, Ямало-Ненецкий автономный округ, Ямальский район, с. Яр-Сале, ул. Худи Сэроко, д.18\n+7(34996)3-08-11 \nдо-ямальский.рф\ndo@yam.yanao.ru"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 6,
        "col3": "Департамент образования Администрации Тазовского района",
        "col4": "629350, Ямало-Ненецкий автономный округ, Тазовский район, п. Тазовский, ул. Пиеттомина, д. 23\n+7(34940)2-11-52\ntaz-edu.ru\ninbox@taz-edu.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 7,
        "col3": "Департамент образования муниципального образования Пуровский район",
        "col4": "629850, Ямало-Ненецкий автономный округ, Пуровский район, г. Тарко-Сале, ул. Республики, д.25\n+7(34997)2-36-11, +7(34997)6-07-10\ndoapr@pur.yanao.ru info@purovskiydo.ru, \n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 8,
        "col3": "Управление образования Администрации города Губкинского",
        "col4": "629830, Ямало-Ненецкий автономный округ, г. Губкинский, 7 микрорайон, д. 2\n+7(34936)3-61-10\n www.uo-gub.ru\nGSSadykova@mogub.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 9,
        "col3": "Управление образования Администрации муниципального образования Красноселькупский район",
        "col4": "629380, Ямало-Ненецкий автономный округ, Красноселькупский район, с. Красноселькуп, ул. Полярная, д.15\n+7(34932)2-14-32\n uoks.ru\nuoks@krasnoselkupsky.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 10,
        "col3": "Управление образования Администрации муниципального образования Приуральский район",
        "col4": "629620, Ямало-Ненецкий автономный округ, Приуральский район, с. Аксарка, пер. Школьный, д.2\n+7(34993)2-22-11\npriurale.ru\nedu@priuralye.сom, edu@priuralye.yanao.ru\n \n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 11,
        "col3": "Управление образования Администрации муниципального образования Шурышкарский район",
        "col4": "629640, Ямало-Ненецкий автономный округ, Шурышкарский район, с. Мужи, ул. Истомина, д. 9А\n+7(34994)2-13-08, +7(34994)2-19-19\nwww.uomuzhi.ru\nuprobr@shur.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 12,
        "col3": "Управление образования города Лабытнанги",
        "col4": "629400, Ямало-Ненецкий автономный округ, г. Лабытнанги, ул. Первомайская, д. 29\n+734992)2-39-01, +7 (34992) 23416\n uolbt.yanao.ru\n mail@uo.lbt.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 13,
        "col3": "Управление образования муниципального образования город Муравленко",
        "col4": " 629602, Ямало-Ненецкий автономный округ, г. Муравленко, ул. Ленина, д.65\n7(34938)5-65-00\n www.uomur.org\n uo@muravlenko.yanao.ru\n"
    },
    {
        "col1": "Ярославская область",
        "col2": 1,
        "col3": "Министерство \nобразования Ярославской области",
        "col4": "г.Ярославль, \nул.Советская, д. 7, \n150000,\n(4852)40-18-95,\ndobr@yarregion.ru"
    }
]