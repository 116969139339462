import s from "./Cabinet.module.css";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Report2025_2 = () => {
  const [respData, setData] = useState({
    регион: "",
    p2: "да",
    p3: "-",
    p3_1: "",
    p4: "",
    p5: "",
    p6: "-",
    p7: "",
    p8: "-",
    p9: "",
    p10: "-",
    p11: "",
    p12: "-",
    p13: "",
    p14: "-",
    p15: "",
    p16: "-",
    p17: "",
    p18: "-",
    p19: "",
    p20: "-",
    p21: "-",
    p22: "",
    p23: "",
    p24: "",
    p25: "",
    p26: "",
    p27: "",
    p28: "",
    p29: "",
    p30: "да",
    p31: "-",
    p32: "",
    p33: 0,
    p34: 0,
    p36: 0,
    p38: 0,
    p39: 0,
    p40: 0,
    p41: 0,
    p42: 0,
    p43: "",
    p44: "да",
    p45: "-",
    p46: "",
    p47: "",
    p49: "-",
    p50: "",
    p51: "-",
    p52: "",
    p53: "-",
    p54: "",
    p55: "-",
    p56: "",
    p57: "-",
    p58: "",
    p59: "-",
    p60: "",
    p61: "-",
    p62: "",
    p63: "-",
    p64: "",
    p65: "-",
    p66: "",
    p67: "да",
    p68: "да",
    p69: "",
    p69_1: "",
    p70: 0,
    p72: 0,
    p73: 0,
    p74: "-",
    p75: "",
    p76: "",
    p77: "",
    p78: "",
    p79: "",
    p80: "",
    p82: 0,
    p83: 0,
    p84: 0,
    p85: 0,
    p86: 0,
    p87: 0,
    p88: 0,
    p89: 0,
    p90: 0,
    p91: 0,
    p92: 0,
    p93: 0,
    p94: 0,
    p95: "",
    p96: 0,
    p97: 0,
    p98: "",
    p99: 0,
    p101: 0,
    p102: 0,
    p103: 0,
    p104: 0,
    p105: 0,
    p106: 0,
    p107: 0,
    p108: 0,
    p109: 0,
    p110: 0,
    p111: 0,
    p112: "",
    p113: 0,
    p114: 0,
    p115: "",
    p116: 0,
    p118: 0,
    p119: 0,
    p120: 0,
    p121: 0,
    p122: 0,
    p123: 0,
    p124: 0,
    p125: 0,
    p126: 0,
    p127: 0,
    p128: 0,
    p129: 0,
    p130: 0,
    p131: 0,
    p132: 0,
    p134: "да",
    p135: "да",
    p136: "да",
    p137: "да",
    p140: "да",
    p141: "да",
    p142: "да",
    p143: "да",
    p144: "да",
    p146: "да",
    p147: "да",
    p148: "да",
    p149: "да",
    p150: "да",
    p151: "",
    p152: "да",
    p153: "да",
    p154: "",
    p156: "да",
    p157: "",
    p158: "да",
    p159: "",
    p160: "",
    p161: ""
  });
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user.id) {
        let config = {
          method: "GET",
          maxBodyLength: Infinity,
          url: `https://frcopeca.ru/api/sumform25?id=${user.id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        };
        try {
          const response = await axios.request(config);
          if (response.data[0]) {
            setData(response.data[0]);
          }
        } catch (error) {
          setData({
            регион: "",
            p2: "да",
            p3: "-",
            p3_1: "",
            p4: "",
            p5: "",
            p6: "-",
            p7: "",
            p8: "-",
            p9: "",
            p10: "-",
            p11: "",
            p12: "-",
            p13: "",
            p14: "-",
            p15: "",
            p16: "-",
            p17: "",
            p18: "-",
            p19: "",
            p20: "-",
            p21: "-",
            p22: "",
            p23: "",
            p24: "",
            p25: "",
            p26: "",
            p27: "",
            p28: "",
            p29: "",
            p30: "да",
            p31: "-",
            p32: "",
            p33: 0,
            p34: 0,
            p36: 0,
            p38: 0,
            p39: 0,
            p40: 0,
            p41: 0,
            p42: 0,
            p43: "",
            p44: "да",
            p45: "-",
            p46: "",
            p47: "",
            p49: "-",
            p50: "",
            p51: "-",
            p52: "",
            p53: "-",
            p54: "",
            p55: "-",
            p56: "",
            p57: "-",
            p58: "",
            p59: "-",
            p60: "",
            p61: "-",
            p62: "",
            p63: "-",
            p64: "",
            p65: "-",
            p66: "",
            p67: "да",
            p68: "да",
            p69: "",
            p69_1: "",
            p70: 0,
            p72: 0,
            p73: 0,
            p74: "-",
            p75: "",
            p76: "",
            p77: "",
            p78: "",
            p79: "",
            p80: "",
            p82: 0,
            p83: 0,
            p84: 0,
            p85: 0,
            p86: 0,
            p87: 0,
            p88: 0,
            p89: 0,
            p90: 0,
            p91: 0,
            p92: 0,
            p93: 0,
            p94: 0,
            p95: "",
            p96: 0,
            p97: 0,
            p98: "",
            p99: 0,
            p101: 0,
            p102: 0,
            p103: 0,
            p104: 0,
            p105: 0,
            p106: 0,
            p107: 0,
            p108: 0,
            p109: 0,
            p110: 0,
            p111: 0,
            p112: "",
            p113: 0,
            p114: 0,
            p115: "",
            p116: 0,
            p118: 0,
            p119: 0,
            p120: 0,
            p121: 0,
            p122: 0,
            p123: 0,
            p124: 0,
            p125: 0,
            p126: 0,
            p127: 0,
            p128: 0,
            p129: 0,
            p130: 0,
            p131: 0,
            p132: 0,
            p134: "да",
            p135: "да",
            p136: "да",
            p137: "да",
            p140: "да",
            p141: "да",
            p142: "да",
            p143: "да",
            p144: "да",
            p146: "да",
            p147: "да",
            p148: "да",
            p149: "да",
            p150: "да",
            p151: "",
            p152: "да",
            p153: "да",
            p154: "",
            p156: "да",
            p157: "",
            p158: "да",
            p159: "",
            p160: "",
            p161: ""
          });
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [user.id]);

  if (loading) {
    return <div className={s.main}>Загрузка...</div>;
  }

  return (
    <div className={s.main}>
      <h1>
        Форма для сбора информации об организации и осуществлении деятельности
        по подготовке к самостоятельной жизни воспитанников организаций для
        детей-сирот и детей, оставшихся без попечения родителей, сопровождению
        детей-сирот и детей, оставшихся без попечения родителей, и лиц из их
        числа по завершении пребывания в организации для детей-сирот и детей,
        оставшихся без попечения родителей, и в семье попечителя, включая
        мероприятия по привлечению граждан, желающих быть наставниками,
        взаимодействию с негосударственными некоммерческими организациями (по
        состоянию на 31 декабря 2024 г.)
      </h1>
      {respData.статус && (
        <div
          style={{
            paddingBottom: "15px",
            fontSize: "28px",
            fontWeight: "bold",
            color: "#000000",
            width: "90%",
            margin: "0 auto",
          }}
        >
          Статус: {respData.статус}
        </div>
      )}
      {respData.комментарии && (
        <div
          style={{
            paddingBottom: "15px",
            fontSize: "24px",
            fontWeight: "normal",
            color: "red",
            width: "90%",
            margin: "0 auto",
          }}
        >
          Комментарий от проверяющего:
          <br />
          {respData.комментарии}
        </div>
      )}
      <ReportForm respData={respData} />
      {/* <AddForm/> */}
    </div>
  );
};
export default Report2025_2;

const ReportForm = ({ respData }) => {
  return (
    <div>
      <Report2 respData={respData} />
    </div>
  );
};

const Report2 = ({ respData }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [err, setErr] = useState("");
  const [file, setFile] = useState(null);

  const readOnly = respData.статус === "На утверждении";
  const readOnlyRegion = respData.статус !== "" || respData.статус !== null;



  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        регион: respData.регион,
        p2: respData.p2,
        p3: respData.p3,
        p3_1: respData.p3_1,
        p4: respData.p4,
        p5: respData.p5,
        p6: respData.p6,
        p7: respData.p7,
        p8: respData.p8,
        p9: respData.p9,
        p10: respData.p10,
        p11: respData.p11,
        p12: respData.p12,
        p13: respData.p13,
        p14: respData.p14,
        p15: respData.p15,
        p16: respData.p16,
        p17: respData.p17,
        p18: respData.p18,
        p19: respData.p19,
        p20: respData.p20,
        p21: respData.p21,
        p22: respData.p22,
        p23: respData.p23,
        p24: respData.p24,
        p25: respData.p25,
        p26: respData.p26,
        p27: respData.p27,
        p28: respData.p28,
        p29: respData.p29,
        p30: respData.p30,
        p31: respData.p31,
        p32: respData.p32,
        p33: respData.p33,
        p34: respData.p34,
        p36: respData.p36,
        p38: respData.p38,
        p39: respData.p39,
        p40: respData.p40,
        p41: respData.p41,
        p42: respData.p42,
        p43: respData.p43,
        p44: respData.p44,
        p45: respData.p45,
        p46: respData.p46,
        p47: respData.p47,
        p49: respData.p49,
        p50: respData.p50,
        p51: respData.p51,
        p52: respData.p52,
        p53: respData.p53,
        p54: respData.p54,
        p55: respData.p55,
        p56: respData.p56,
        p57: respData.p57,
        p58: respData.p58,
        p59: respData.p59,
        p60: respData.p60,
        p61: respData.p61,
        p62: respData.p62,
        p63: respData.p63,
        p64: respData.p64,
        p65: respData.p65,
        p66: respData.p66,
        p67: respData.p67,
        p68: respData.p68,
        p69: respData.p69,
        p69_1: respData.p69_1,
        p70: respData.p70,
        p72: respData.p72,
        p73: respData.p73,
        p74: respData.p74,
        p75: respData.p75,
        p76: respData.p76,
        p77: respData.p77,
        p78: respData.p78,
        p79: respData.p79,
        p80: respData.p80,
        p82: respData.p82,
        p83: respData.p83,
        p84: respData.p84,
        p85: respData.p85,
        p86: respData.p86,
        p87: respData.p87,
        p88: respData.p88,
        p89: respData.p89,
        p90: respData.p90,
        p91: respData.p91,
        p92: respData.p92,
        p93: respData.p93,
        p94: respData.p94,
        p95: respData.p95,
        p96: respData.p96,
        p97: respData.p97,
        p98: respData.p98,
        p99: respData.p99,
        p101: respData.p101,
        p102: respData.p102,
        p103: respData.p103,
        p104: respData.p104,
        p105: respData.p105,
        p106: respData.p106,
        p107: respData.p107,
        p108: respData.p108,
        p109: respData.p109,
        p110: respData.p110,
        p111: respData.p111,
        p112: respData.p112,
        p113: respData.p113,
        p114: respData.p114,
        p115: respData.p115,
        p116: respData.p116,
        p118: respData.p118,
        p119: respData.p119,
        p120: respData.p120,
        p121: respData.p121,
        p122: respData.p122,
        p123: respData.p123,
        p124: respData.p124,
        p125: respData.p125,
        p126: respData.p126,
        p127: respData.p127,
        p128: respData.p128,
        p129: respData.p129,
        p130: respData.p130,
        p131: respData.p131,
        p132: respData.p132,
        p134: respData.p134,
        p135: respData.p135,
        p136: respData.p136,
        p137: respData.p137,
        p140: respData.p140,
        p141: respData.p141,
        p142: respData.p142,
        p143: respData.p143,
        p144: respData.p144,
        p146: respData.p146,
        p147: respData.p147,
        p148: respData.p148,
        p149: respData.p149,
        p150: respData.p150,
        p151: respData.p151,
        p152: respData.p152,
        p153: respData.p153,
        p154: respData.p154,
        p156: respData.p156,
        p157: respData.p157,
        p158: respData.p158,
        p159: respData.p159,
        p160: respData.p160,
        p161: respData.p161,
      }}
      onSubmit={(values) => {
        let data2 = new FormData();
        
        data2.append("регион", values.регион);
        data2.append("p2", (values.p2 === "" || values.p2 === "да" || values.p2 === "null" || values.p2 === null) ? "да" : "нет");
        data2.append("p3", values.p3 || "");
        data2.append("p3_1", values.p3_1 || "");
        data2.append("p4", values.p4 || "");
        data2.append("p5", values.p5 || "");
        data2.append("p6", values.p6 || "");
        data2.append("p7", values.p7 || "");
        data2.append("p8", values.p8 || "");
        data2.append("p9", values.p9 || "");
        data2.append("p10", values.p10 || "");
        data2.append("p11", values.p11 || "");
        data2.append("p12", values.p12 || "");
        data2.append("p13", values.p13 || "");
        data2.append("p14", values.p14 || "");
        data2.append("p15", values.p15 || "");
        data2.append("p16", values.p16 || "");
        data2.append("p17", values.p17 || "");
        data2.append("p18", values.p18 || "");
        data2.append("p19", values.p19 || "");
        data2.append("p20", values.p20 || "");
        data2.append("p21", values.p21 || "");
        data2.append("p22", values.p22 || "");
        data2.append("p23", values.p23 || "");
        data2.append("p24", values.p24 || "");
        data2.append("p25", values.p25 || "");
        data2.append("p26", values.p26 || "");
        data2.append("p27", values.p27 || "");
        data2.append("p28", values.p28 || "");
        data2.append("p29", values.p29 || "");
        data2.append("p30", (values.p30 === "" || values.p30 === "да" || values.p30 === "null" || values.p30 === null) ? "да" : "нет");
        data2.append("p31", values.p31 || "");
        data2.append("p32", values.p32 || "");
        data2.append("p33", values.p33 || "0");
        data2.append("p34", values.p34 || "0");
        data2.append("p36", values.p36 || "0");
        data2.append("p38", values.p38 || "0");
        data2.append("p39", values.p39 || "0");
        data2.append("p40", values.p40 || "0");
        data2.append("p41", values.p41 || "0");
        data2.append("p42", values.p42 || "0");
        data2.append("p43", values.p43 || "");
        data2.append("p44", (values.p44 === "" || values.p44 === "да" || values.p44 === "null" || values.p44 === null) ? "да" : "нет");
        data2.append("p45", values.p45 || "");
        data2.append("p46", values.p46 || "");
        data2.append("p47", values.p47 || "");
        data2.append("p49", values.p49 || "");
        data2.append("p50", values.p50 || "");
        data2.append("p51", values.p51 || "");
        data2.append("p52", values.p52 || "");
        data2.append("p53", values.p53 || "");
        data2.append("p54", values.p54 || "");
        data2.append("p55", values.p55 || "");
        data2.append("p56", values.p56 || "");
        data2.append("p57", values.p57 || "");
        data2.append("p58", values.p58 || "");
        data2.append("p59", values.p59 || "");
        data2.append("p60", values.p60 || "");
        data2.append("p61", values.p61 || "");
        data2.append("p62", values.p62 || "");
        data2.append("p63", values.p63 || "");
        data2.append("p64", values.p64 || "");
        data2.append("p65", values.p65 || "");
        data2.append("p66", values.p66 || "");
        data2.append("p67", (values.p67 === "" || values.p67 === "да" || values.p67 === "null" || values.p67 === null) ? "да" : "нет");
        data2.append("p68", (values.p68 === "" || values.p68 === "да" || values.p68 === "null" || values.p68 === null) ? "да" : "нет");
        data2.append("p69", values.p69 || "");
        data2.append("p69_1", values.p69_1 || "");
        data2.append("p70", values.p70 || "0");
        data2.append("p72", values.p72 || "0");
        data2.append("p73", values.p73 || "0");
        data2.append("p74", values.p74 || "");
        data2.append("p75", values.p75 || "");
        data2.append("p76", values.p76 || "");
        data2.append("p77", values.p77 || "");
        data2.append("p78", values.p78 || "");
        data2.append("p79", values.p79 || "");
        data2.append("p80", values.p80 || "");
        data2.append("p82", values.p82 || "0");
        data2.append("p83", values.p83 || "0");
        data2.append("p84", values.p84 || "0");
        data2.append("p85", values.p85 || "0");
        data2.append("p86", values.p86 || "0");
        data2.append("p87", values.p87 || "0");
        data2.append("p88", values.p88 || "0");
        data2.append("p89", values.p89 || "0");
        data2.append("p90", values.p90 || "0");
        data2.append("p91", values.p91 || "0");
        data2.append("p92", values.p92 || "0");
        data2.append("p93", values.p93 || "0");
        data2.append("p94", values.p94 || "0");
        data2.append("p95", values.p95 || "");
        data2.append("p96", values.p96 || "0");
        data2.append("p97", values.p97 || "0");
        data2.append("p98", values.p98 || "");
        data2.append("p99", values.p99 || "0");
        data2.append("p101", values.p101 || "0");
        data2.append("p102", values.p102 || "0");
        data2.append("p103", values.p103 || "0");
        data2.append("p104", values.p104 || "0");
        data2.append("p105", values.p105 || "0");
        data2.append("p106", values.p106 || "0");
        data2.append("p107", values.p107 || "0");
        data2.append("p108", values.p108 || "0");
        data2.append("p109", values.p109 || "0");
        data2.append("p110", values.p110 || "0");
        data2.append("p111", values.p111 || "0");
        data2.append("p112", values.p112 || "");
        data2.append("p113", values.p113 || "0");
        data2.append("p114", values.p114 || "0");
        data2.append("p115", values.p115 || "");
        data2.append("p116", values.p116 || "0");
        data2.append("p118", values.p118 || "0");
        data2.append("p119", values.p119 || "0");
        data2.append("p120", values.p120 || "0");
        data2.append("p121", values.p121 || "0");
        data2.append("p122", values.p122 || "0");
        data2.append("p123", values.p123 || "0");
        data2.append("p124", values.p124 || "0");
        data2.append("p125", values.p125 || "0");
        data2.append("p126", values.p126 || "0");
        data2.append("p127", values.p127 || "0");
        data2.append("p128", values.p128 || "0");
        data2.append("p129", values.p129 || "0");
        data2.append("p130", values.p130 || "0");
        data2.append("p131", values.p131 || "0");
        data2.append("p132", values.p132 || "0");
        data2.append("p134", (values.p134 === "" || values.p134 === "да" || values.p134 === "null" || values.p134 === null) ? "да" : "нет")
        data2.append("p135", (values.p135 === "" || values.p135 === "да" || values.p135 === "null" || values.p135 === null) ? "да" : "нет")
        data2.append("p136", (values.p136 === "" || values.p136 === "да" || values.p136 === "null" || values.p136 === null) ? "да" : "нет")
        data2.append("p137", (values.p137 === "" || values.p137 === "да" || values.p137 === "null" || values.p137 === null) ? "да" : "нет")
        data2.append("p140", (values.p140 === "" || values.p140 === "да" || values.p140 === "null" || values.p140 === null) ? "да" : "нет")
        data2.append("p141", (values.p141 === "" || values.p141 === "да" || values.p141 === "null" || values.p141 === null) ? "да" : "нет")
        data2.append("p142", (values.p142 === "" || values.p142 === "да" || values.p142 === "null" || values.p142 === null) ? "да" : "нет")
        data2.append("p143", (values.p143 === "" || values.p143 === "да" || values.p143 === "null" || values.p143 === null) ? "да" : "нет")
        data2.append("p144", (values.p144 === "" || values.p144 === "да" || values.p144 === "null" || values.p144 === null) ? "да" : "нет")
        data2.append("p146", (values.p146 === "" || values.p146 === "да" || values.p146 === "null" || values.p146 === null) ? "да" : "нет")
        data2.append("p147", (values.p147 === "" || values.p147 === "да" || values.p147 === "null" || values.p147 === null) ? "да" : "нет")
        data2.append("p148", (values.p148 === "" || values.p148 === "да" || values.p148 === "null" || values.p148 === null) ? "да" : "нет")
        data2.append("p149", (values.p149 === "" || values.p149 === "да" || values.p149 === "null" || values.p149 === null) ? "да" : "нет")
        data2.append("p150", (values.p150 === "" || values.p150 === "да" || values.p150 === "null" || values.p150 === null) ? "да" : "нет")
        data2.append("p151", values.p151 || "");
        data2.append("p152", (values.p152 === "" || values.p152 === "да" || values.p152 === "null" || values.p152 === null) ? "да" : "нет");
        data2.append("p153", (values.p153 === "" || values.p153 === "да" || values.p153 === "null" || values.p153 === null) ? "да" : "нет");
        data2.append("p154", values.p154 || "");
        data2.append("p156", (values.p156 === "" || values.p156 === "да" || values.p156 === "null" || values.p156 === null) ? "да" : "нет");
        data2.append("p157", values.p157);
        data2.append("p158", (values.p158 === "" || values.p158 === "да" || values.p158 === "null" || values.p158 === null) ? "да" : "нет");
        data2.append("p159", values.p159 || "");
        data2.append("p160", values.p160 || "");
        data2.append("p161", values.p161 || "");
        data2.append("статус", "На утверждении");
        data2.append("author", user.id);


        let config = {
          method: respData.регион ? "patch" : "POST",
          maxBodyLength: Infinity,

          url: `https://frcopeca.ru/api/sumform25`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
          data: data2,
        };

        axios
          .request(config)
          .then(() => {
            setErr("");
            alert("Форма успешно отправлена");
            navigate("/profile");
            console.log(JSON.parse(data2));
          })
          .catch((e) => {
            const errorMessage = e.response
              ? e.response.data?.message ||
              e.response.statusText ||
              "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
              : e.message || JSON.stringify(e);

            setErr(errorMessage);

            console.error("Ошибка:", e);
          });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className={s.reportForm}>
          <label>
            Для заполнения формы мониторинга используются понятия и сокращения:
            <br />
            организации для детей-сирот – организации для детей-сирот и детей,
            оставшихся без попечения родителей;
            <br />
            воспитанники организаций для детей-сирот – дети-сироты и дети,
            оставшиеся без попечения родителей, находящиеся под надзором в
            организации для детей-сирот, и дети, временно помещенные в
            организацию для детей-сирот по заявлению родителей, усыновителей
            либо опекунов или попечителей в случаях, предусмотренных законом, с
            учетом условий соглашения, заключенного между законными
            представителями, организацией для детей-сирот и органом опеки и
            попечительства о временном пребывании ребенка в организации для
            детей-сирот;
            <br />
            выпускники организаций для детей-сирот - лица в возрасте до 23 лет,
            которые были помещены под надзор в организации для детей-сирот на
            полное государственное обеспечение и завершили свое пребывание в
            данной организации в связи с поступлением на обучение в организации,
            осуществляющие образовательную деятельность, по основным
            профессиональным образовательным программам или основным программам
            профессионального обучения до достижения совершеннолетия либо в
            связи с достижением совершеннолетия либо в связи с приобретением
            дееспособности в полном объеме (вступление в брак или эмансипация) в
            соответствии с пунктом 2 статьи 21 или со статьей 27 Гражданского
            кодекса Российской Федерации;
            <br />
            выпускники семейных форм устройства – лица в возрасте до 23 лет,
            которые были переданы под опеку или попечительство, в приемную или
            патронатную семью, и попечительство в отношении которых прекращено
            по достижении ими совершеннолетия либо в связи с приобретением
            дееспособности в полном объеме (вступление в брак или эмансипация) в
            соответствии с пунктом 2 статьи 21 или со статьей 27 Гражданского
            кодекса Российской Федерации;
            <br />
            выпускники – выпускники организаций для детей-сирот и выпускники
            семейных форм устройства;
            <br />
            подготовка к самостоятельной жизни – процесс формирования у
            воспитанников организаций для детей-сирот готовности преодолевать
            возможные трудности в решении возникающих социальных проблем,
            ответственного отношения к своей жизни и приобретения ими социальных
            и бытовых навыков, необходимых для самостоятельного проживания;
            <br />
            сопровождение – система мер консультативной, психологической,
            педагогической, юридической, социальной и иной помощи и содействия,
            направленных в том числе на выявление и устранение причин, условий и
            последствий трудной жизненной ситуации и социальной дезадаптации
            выпускников, включая регулярное взаимодействие с выпускником
            специалиста по сопровождению по месту проживания, обучения,
            трудоустройства, в том числе с использованием информационных
            технологий;
            <br />
            НКО – негосударственные некоммерческие, в том числе общественные и
            религиозные организации, благотворительные фонды;
            <br />
            субъект РФ – субъект Российской Федерации;
            <br />
            выпускники, страдающие социально значимыми, хроническими и тяжелыми
            заболеваниями, при наличии которых оказывается социальная поддержка,
            – выпускники, страдающие туберкулезом, инфекциями, передающимися
            преимущественно половым путем, гепатитом B, гепатитом C, болезнями,
            вызванными вирусом иммунодефицита человека (ВИЧ), злокачественными
            новообразованиями, сахарным диабетом и другими социально значимыми
            заболеваниями, при наличии которых гражданам оказывается социальная
            поддержка (перечень социально значимых заболеваний утвержден
            постановлением Правительства Российской Федерации от 1 декабря 2004
            г. № 715 «Об утверждении перечня социально значимых заболеваний и
            перечня заболеваний, представляющих опасность для окружающих»);
            <br />
            выпускники с ОВЗ – выпускники с ограниченными возможностями
            здоровья;
            <br />
            стандарт подготовки к самостоятельной жизни – проект стандарта
            Минпросвещения России «Подготовка к самостоятельной жизни
            детей-сирот и детей, оставшихся без попечения родителей,
            (воспитанников) в организациях для детей-сирот и детей, оставшихся
            без попечения родителей»;
            <br />
            стандарт сопровождения выпускников – проекта стандарта
            Минпросвещения России «Сопровождение выпускников организаций для
            детей-сирот и семейных форм воспитания по завершении попечительства»
          </label>
          <p></p>
          <label htmlFor="регион">Субъект Российской Федерации</label>
          <Field
            readOnly={readOnlyRegion}
            id="регион"
            name="регион"
            as="textarea"
            required={true}
          />

          <label style={{ fontWeight: "bold" }}>
            Раздел 1. Сведения о применении стандарта подготовки к
            самостоятельной жизни (в соответствии с п. 4.2.1 стандарта
            подготовки к самостоятельной жизни наступление 14-летнего возраста у
            воспитанника является основанием для включения его в реализацию
            комплекса мер, направленного на подготовку к самостоятельной жизни)
          </label>

          <label htmlFor="p2">
            1. В субъекте РФ применяется стандарт подготовки к самостоятельной
            жизни
          </label>
          <Field as="select" id="p2" name="p2">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p3">
            1.2 при выборе ответа «да» в строке 1 укажите год начала применения
            стандарта подготовки к самостоятельной жизни
          </label>
          <Field as="select" id="p3" name="p3">
            <option value="-">-</option>
            <option value="с 2023 г.">с 2023 г.</option>
            <option value="с 2024 г.">с 2024 г.</option>
            <option value="с 2025 г.">с 2025 г.</option>
            <option value="иное">иное</option>
          </Field>

          <label htmlFor="p3_1">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p3_1" name="p3_1" as="textarea" />

          <label htmlFor="p4">
            1.3 при выборе ответа «нет» в строке 1 укажите причину
          </label>
          <div className={s.checkboxGroup}>
            <label>
              <Field
                type="checkbox"
                name="p4"
                value="наличие регионального стандарта"
                checked={values.p4?.split(';').includes("наличие регионального стандарта")}
                onChange={(e) => {
                  const currentValues = values.p4 ? values.p4.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p4', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p4', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              наличие регионального стандарта
            </label>
            <label>
              <Field
                type="checkbox"
                name="p4"
                value="имеются локальные программы"
                checked={values.p4?.split(';').includes("имеются локальные программы")}
                onChange={(e) => {
                  const currentValues = values.p4 ? values.p4.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p4', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p4', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              имеются локальные программы
            </label>
            <label>
              <Field
                type="checkbox"
                name="p4"
                value="проблемы практического применения"
                checked={values.p4?.split(';').includes("проблемы практического применения")}
                onChange={(e) => {
                  const currentValues = values.p4 ? values.p4.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p4', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p4', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              проблемы практического применения
            </label>
            <label>
              <Field
                type="checkbox"
                name="p4"
                value="иное"
                checked={values.p4?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p4 ? values.p4.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p4', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p4', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>

          <label htmlFor="p5">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p5" name="p5" as="textarea" />

          <label>
            2. При применении стандарта подготовки к самостоятельной жизни
            возникают вопросы, требующие разъяснений, по следующим его разделам:
          </label>

          <label htmlFor="p6">2.1 «Термины и определения»</label>
          <Field as="select" id="p6" name="p6">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p7">
            2.1.1 при выборе ответа «да» в строке 2.1 сформулируйте вопрос
          </label>
          <Field id="p7" name="p7" as="textarea" />

          <label htmlFor="p8">
            2.2 «Порядок и условия деятельности по подготовке воспитанников к
            самостоятельной жизни»
          </label>
          <Field as="select" id="p8" name="p8">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p9">
            2.2.1 при выборе ответа «да» в строке 2.2 сформулируйте вопрос
          </label>
          <Field id="p9" name="p9" as="textarea" />

          <label htmlFor="p10">
            2.3 «Содержание деятельности по подготовке воспитанников к
            самостоятельной жизни»
          </label>
          <Field as="select" id="p10" name="p10">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p11">
            2.3.1 при выборе ответа «да» в строке 2.3 сформулируйте вопрос
          </label>
          <Field id="p11" name="p11" as="textarea" />

          <label htmlFor="p12">
            2.4 «Межведомственное взаимодействие при организации и осуществлении
            деятельности по подготовке воспитанников к самостоятельной жизни»
          </label>
          <Field as="select" id="p12" name="p12">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p13">
            2.4.1 при выборе ответа «да» в строке 2.4 сформулируйте вопрос{" "}
          </label>
          <Field id="p13" name="p13" as="textarea" />

          <label htmlFor="p14">
            2.5 «Основные показатели качества подготовки воспитанников к
            самостоятельной жизни. Контроль качества осуществления деятельности
            по подготовке воспитанников к самостоятельной жизни»
          </label>
          <Field as="select" id="p14" name="p14">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p15">
            2.5.1 при выборе ответа «да» в строке 2.5 сформулируйте вопрос{" "}
          </label>
          <Field id="p15" name="p15" as="textarea" />

          <label htmlFor="p16">
            3. Возраст воспитанников организации для детей-сирот, с которого
            реализуется комплекс мер по подготовке к самостоятельной жизни (при
            применении стандарта подготовки к самостоятельной жизни)
          </label>
          <Field as="select" id="p16" name="p16">
            <option value="-">-</option>
            <option
              value="с 14 лет  или если ребенок более старшего возраста – с момента поступления в организацию для детей-сирот">
              с 14 лет или если ребенок более старшего возраста – с момента
              поступления в организацию для детей-сирот
            </option>
            <option value="иное">иное</option>
          </Field>

          <label htmlFor="p17">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p17" name="p17" as="textarea" />

          <label htmlFor="p18">
            4. Возраст воспитанников организации для детей-сирот с нарушениями
            ментального развития, с которого реализуется комплекс мер по
            подготовке к самостоятельной жизни (при применении стандарта
            подготовки к самостоятельной жизни)
          </label>
          <Field as="select" id="p18" name="p18">
            <option value="-">-</option>
            <option
              value="с 12 лет  или если ребенок более  старшего возраста –  с момента поступления в организацию для детей-сирот">
              с 12 лет или если ребенок более старшего возраста – с момента
              поступления в организацию для детей-сирот
            </option>
            <option value="иное">иное</option>
          </Field>

          <label htmlFor="p19">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p19" name="p19" as="textarea" />

          <label htmlFor="p20">
            5. В субъекте РФ стандарт подготовки к самостоятельной жизни
            применяется во всех организациях для детей-сирот, где воспитываются
            дети, достигшие 14 лет (достигшие 12 лет дети с ментальными
            нарушениями)
          </label>
          <Field as="select" id="p20" name="p20">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p21">
            6. В субъекте РФ проводится мониторинг эффективности работы по
            подготовке к самостоятельной жизни (далее - мониторинг
            эффективности) (при применении стандарта подготовки к
            самостоятельной жизни)
          </label>
          <Field as="select" id="p21" name="p21">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>


          <label htmlFor="p22">6.1 при выборе ответа «да» в строке 6 перечислите методы оценки</label>
          <div className={s.checkboxGroup}>

            <label>
              <Field
                type="checkbox"
                name="p22"
                value="сравнение текущих значений показателей с их целевыми значениями"
                checked={values.p22?.split(';').includes("сравнение текущих значений показателей с их целевыми значениями")}
                onChange={(e) => {
                  const currentValues = values.p22 ? values.p22.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p22', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p22', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              сравнение текущих значений показателей с их целевыми значениями
            </label>
            <label>
              <Field
                type="checkbox"
                name="p22"
                value="промежуточный срез"
                checked={values.p22?.split(';').includes("промежуточный срез")}
                onChange={(e) => {
                  const currentValues = values.p22 ? values.p22.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p22', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p22', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              промежуточный срез
            </label>

            <label>
              <Field
                type="checkbox"
                name="p22"
                value="иное"
                checked={values.p22?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p22 ? values.p22.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p22', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p22', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>


          <label htmlFor="p23">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p23" name="p23" as="textarea" />

          <label htmlFor="p24">
            6.2 при выборе ответа «нет» в строке 6 укажите причину
          </label>
          <div className={s.checkboxGroup}>
            <label>
              <Field
                type="checkbox"
                name="p24"
                value="проводить преждевременно"
                checked={values.p24?.split(';').includes("проводить преждевременно")}
                onChange={(e) => {
                  const currentValues = values.p24 ? values.p24.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p24', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p24', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              проводить преждевременно
            </label>
            <label>
              <Field
                type="checkbox"
                name="p24"
                value="нет инструментария"
                checked={values.p24?.split(';').includes("нет инструментария")}
                onChange={(e) => {
                  const currentValues = values.p24 ? values.p24.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p24', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p24', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              нет инструментария
            </label>
            <label>
              <Field
                type="checkbox"
                name="p24"
                value="планируется в 2025 г."
                checked={values.p24?.split(';').includes("планируется в 2025 г.")}
                onChange={(e) => {
                  const currentValues = values.p24 ? values.p24.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p24', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p24', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              планируется в 2025 г.
            </label>
            <label>
              <Field
                type="checkbox"
                name="p24"
                value="иное"
                checked={values.p24?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p24 ? values.p24.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p24', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p24', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>

          <label htmlFor="p25">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p25" name="p25" as="textarea" />

          <label htmlFor="p26">
            7. Выявленные проблемы при подготовке воспитанников к
            самостоятельной жизни по итогам мониторинга эффективности (за
            исключением детей с нарушениями ментального развития)
          </label>
          <div className={s.checkboxGroup}>
            <label>
              <Field
                type="checkbox"
                name="p26"
                value="сложность понимания материала воспитанниками"
                checked={values.p26?.split(';').includes("сложность понимания материала воспитанниками")}
                onChange={(e) => {
                  const currentValues = values.p26 ? values.p26.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p26', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p26', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              сложность понимания материала воспитанниками
            </label>
            <label>
              <Field
                type="checkbox"
                name="p26"
                value="сложность усвоения материала"
                checked={values.p26?.split(';').includes("сложность усвоения материала")}
                onChange={(e) => {
                  const currentValues = values.p26 ? values.p26.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p26', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p26', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              сложность усвоения материала
            </label>
            <label>
              <Field
                type="checkbox"
                name="p26"
                value="сложность реализации на практике полученных знаний (представлений)"
                checked={values.p26?.split(';').includes("сложность реализации на практике полученных знаний (представлений)")}
                onChange={(e) => {
                  const currentValues = values.p26 ? values.p26.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p26', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p26', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              сложность реализации на практике полученных знаний (представлений)
            </label>
            <label>
              <Field
                type="checkbox"
                name="p26"
                value="дефицит кадров"
                checked={values.p26?.split(';').includes("дефицит кадров")}
                onChange={(e) => {
                  const currentValues = values.p26 ? values.p26.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p26', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p26', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              дефицит кадров
            </label>
            <label>
              <Field
                type="checkbox"
                name="p26"
                value="отсутствие мотивации у воспитанников"
                checked={values.p26?.split(';').includes("отсутствие мотивации у воспитанников")}
                onChange={(e) => {
                  const currentValues = values.p26 ? values.p26.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p26', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p26', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              отсутствие мотивации у воспитанников
            </label>
            <label>
              <Field
                type="checkbox"
                name="p26"
                value="загруженность воспитанника"
                checked={values.p26?.split(';').includes("загруженность воспитанника")}
                onChange={(e) => {
                  const currentValues = values.p26 ? values.p26.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p26', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p26', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              загруженность воспитанника
            </label>
            <label>
              <Field
                type="checkbox"
                name="p26"
                value="иное"
                checked={values.p26?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p26 ? values.p26.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p26', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p26', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>

          <label htmlFor="p27">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p27" name="p27" as="textarea" />

          <label htmlFor="p28">
            8. Выявленные проблемы при подготовке воспитанников с нарушениями
            ментального развития к самостоятельной жизни по итогам мониторинга
            эффективности{" "}
          </label>
          <div className={s.checkboxGroup}>
            <label>
              <Field
                type="checkbox"
                name="p28"
                value="сложность понимания материала воспитанниками"
                checked={values.p28?.split(';').includes("сложность понимания материала воспитанниками")}
                onChange={(e) => {
                  const currentValues = values.p28 ? values.p28.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p28', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p28', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              сложность понимания материала воспитанниками
            </label>
            <label>
              <Field
                type="checkbox"
                name="p28"
                value="сложность усвоения материала"
                checked={values.p28?.split(';').includes("сложность усвоения материала")}
                onChange={(e) => {
                  const currentValues = values.p28 ? values.p28.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p28', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p28', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              сложность усвоения материала
            </label>
            <label>
              <Field
                type="checkbox"
                name="p28"
                value="сложность реализации на практике полученных знаний (представлений)"
                checked={values.p28?.split(';').includes("сложность реализации на практике полученных знаний (представлений)")}
                onChange={(e) => {
                  const currentValues = values.p28 ? values.p28.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p28', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p28', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              сложность реализации на практике полученных знаний (представлений)
            </label>
            <label>
              <Field
                type="checkbox"
                name="p28"
                value="дефицит кадров"
                checked={values.p28?.split(';').includes("дефицит кадров")}
                onChange={(e) => {
                  const currentValues = values.p28 ? values.p28.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p28', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p28', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              дефицит кадров
            </label>
            <label>
              <Field
                type="checkbox"
                name="p28"
                value="отсутствие мотивации у воспитанников"
                checked={values.p28?.split(';').includes("отсутствие мотивации у воспитанников")}
                onChange={(e) => {
                  const currentValues = values.p28 ? values.p28.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p28', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p28', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              отсутствие мотивации у воспитанников
            </label>
            <label>
              <Field
                type="checkbox"
                name="p28"
                value="загруженность воспитанника"
                checked={values.p28?.split(';').includes("загруженность воспитанника")}
                onChange={(e) => {
                  const currentValues = values.p28 ? values.p28.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p28', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p28', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              загруженность воспитанника
            </label>
            <label>
              <Field
                type="checkbox"
                name="p28"
                value="иное"
                checked={values.p28?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p28 ? values.p28.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p28', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p28', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>

          <label htmlFor="p29">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p29" name="p29" as="textarea" />

          <label htmlFor="p30">
            9. В субъекте РФ созданы условия для полузависимого или независимого
            проживания с целью подготовки к самостоятельной жизни («социальные»
            или «тренировочные» помещения, отделения и т.д.){" "}
          </label>
          <Field as="select" id="p30" name="p30">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p31">
            9.1 при выборе ответа «да» в строке 9, уточните период пользования
            воспитанником такими помещениями
          </label>
          <Field as="select" id="p31" name="p31">
            <option value="-">-</option>
            <option value="несколько часов в неделю">
              несколько часов в неделю
            </option>
            <option value="менее недели">менее недели</option>
            <option value="более месяца">более месяца</option>
            <option value="иное">иное</option>
          </Field>

          <label htmlFor="p32">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p32" name="p32" as="textarea" />

          <label htmlFor="p33">
            9.2 Количество помещений из строки 9, оборудованных в организациях
            для детей-сирот{" "}
          </label>
          <Field
            id="p33"
            name="p33"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p34">
            9.3 Количество помещений из строки 9, расположенных вне организаций
            для детей-сирот
          </label>
          <Field
            id="p34"
            name="p34"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label style={{ fontWeight: "bold" }}>
            Раздел 2. Сведения о применении стандарта сопровождения выпускников{" "}
          </label>

          <label htmlFor="p36">
            1. Общее количество организаций в субъекте РФ, осуществляющих
            деятельность по сопровождению выпускников, всего по состоянию на 31
            декабря 2024 г.
          </label>
          <Field
            id="p36"
            name="p36"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label> их них:</label>

          <label htmlFor="p38">
            1.1 организации для детей-сирот (из строки 1)
          </label>
          <Field
            id="p38"
            name="p38"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p39">
            1.2 центры социальной помощи семье и детям (из строки 1)
          </label>
          <Field
            id="p39"
            name="p39"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p40">
            1.3 комплексные центры социального обслуживания населения (из строки
            1)
          </label>
          <Field
            id="p40"
            name="p40"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p41">1.4 НКО (из строки 1)</label>
          <Field
            id="p41"
            name="p41"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p42">
            1.5 иные организации, оказывающие выпускникам консультативную,
            психологическую, педагогическую, юридическую, социальную и иную
            помощь (из строки 1)
          </label>
          <Field
            id="p42"
            name="p42"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p43">1.6 укажите организации из строки 1.5</label>
          <Field id="p43" name="p43" as="textarea" />

          <label htmlFor="p44">
            2. В субъекте РФ применяется стандарт сопровождения выпускников
          </label>
          <Field as="select" id="p44" name="p44">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p45">
            2.1 при выборе ответа «да» в строке 2 укажите год начала применения
            стандарта сопровождения выпускников
          </label>
          <Field as="select" id="p45" name="p45">
            <option value="-">-</option>
            <option value="с 2023 г.">с 2023 г.</option>
            <option value="с 2024 г.">с 2024 г.</option>
            <option value="с 2025 г.">с 2025 г.</option>
            <option value="иное">иное</option>
          </Field>

          <label htmlFor="p47">
            2.3 при выборе ответа «нет» в строке 2 укажите причину{" "}
          </label>
          <div className={s.checkboxGroup}>
            <label>
              <Field
                type="checkbox"
                name="p47"
                value="наличие регионального стандарта"
                checked={values.p47?.split(';').includes("наличие регионального стандарта")}
                onChange={(e) => {
                  const currentValues = values.p47 ? values.p47.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p47', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p47', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              наличие регионального стандарта
            </label>
            <label>
              <Field
                type="checkbox"
                name="p47"
                value="имеются локальные программы"
                checked={values.p47?.split(';').includes("имеются локальные программы")}
                onChange={(e) => {
                  const currentValues = values.p47 ? values.p47.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p47', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p47', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              имеются локальные программы
            </label>
            <label>
              <Field
                type="checkbox"
                name="p47"
                value="проблемы практического применения"
                checked={values.p47?.split(';').includes("проблемы практического применения")}
                onChange={(e) => {
                  const currentValues = values.p47 ? values.p47.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p47', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p47', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              проблемы практического применения
            </label>
            <label>
              <Field
                type="checkbox"
                name="p47"
                value="иное"
                checked={values.p47?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p47 ? values.p47.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p47', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p47', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>
          <label htmlFor="p46">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p46" name="p46" as="textarea" />
          <label>
            3. При применении стандарта сопровождения выпускников возникают
            вопросы, требующие разъяснений, по следующим разделам:{" "}
          </label>

          <label htmlFor="p49">3.1 «Термины и определения» </label>
          <Field as="select" id="p49" name="p49">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p50">
            3.1.1 при выборе ответа «да» в строке 3.1 сформулируйте вопрос{" "}
          </label>
          <Field id="p50" name="p50" as="textarea" />

          <label htmlFor="p51">3.2 «Получатели сопровождения»</label>
          <Field as="select" id="p51" name="p51">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p52">
            3.2.1 при выборе ответа «да» в строке 3.2 сформулируйте вопрос{" "}
          </label>
          <Field id="p52" name="p52" as="textarea" />

          <label htmlFor="p53">
            3.3 «Организации, осуществляющие сопровождение выпускников»
          </label>
          <Field as="select" id="p53" name="p53">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p54">
            3.3.1 при выборе ответа «да» в строке 3.3 сформулируйте вопрос{" "}
          </label>
          <Field id="p54" name="p54" as="textarea" />

          <label htmlFor="p55">
            3.4 «Содержание деятельности по сопровождению выпускников»
          </label>
          <Field as="select" id="p55" name="p55">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p56">
            3.4.1 при выборе ответа «да» в строке 3.4 сформулируйте вопрос{" "}
          </label>
          <Field id="p56" name="p56" as="textarea" />

          <label htmlFor="p57">
            3.5 «Организация сопровождения выпускников»
          </label>
          <Field as="select" id="p57" name="p57">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p58">
            3.5.1 при выборе ответа «да» в строке 3.5 сформулируйте вопрос{" "}
          </label>
          <Field id="p58" name="p58" as="textarea" />

          <label htmlFor="p59">
            3.6 «Сопровождение выпускников уязвимых категорий»
          </label>
          <Field as="select" id="p59" name="p59">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p60">
            3.6.1 при выборе ответа «да» в строке 3.6 сформулируйте вопрос
          </label>
          <Field id="p60" name="p60" as="textarea" />

          <label htmlFor="p61">
            3.7 «Кадры, обеспечивающие деятельность по сопровождению
            выпускников»
          </label>
          <Field as="select" id="p61" name="p61">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p62">
            3.7.1 при выборе ответа «да» в строке 3.7 сформулируйте вопрос
          </label>
          <Field id="p62" name="p62" as="textarea" />

          <label htmlFor="p63">
            3.8 «Межведомственное взаимодействие при организации и осуществлении
            деятельности по сопровождению выпускников»
          </label>
          <Field as="select" id="p63" name="p63">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p64">
            3.8.1 при выборе ответа «да» в строке 3.8 сформулируйте вопрос
          </label>
          <Field id="p64" name="p64" as="textarea" />

          <label htmlFor="p65">
            3.9 «Основные показатели доступности и качества сопровождения.
            Контроль качества сопровождения.»
          </label>
          <Field as="select" id="p65" name="p65">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p66">
            3.9.1 при выборе ответа «да» в строке 3.9 сформулируйте вопрос
          </label>
          <Field id="p66" name="p66" as="textarea" />

          <label htmlFor="p67">
            4. Для выпускников в возрасте до 18 лет, функции законных
            представителей которых осуществляют органы опеки и попечительства,
            сопровождение является обязательным{" "}
          </label>
          <Field as="select" id="p67" name="p67">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p68">
            5. В субъекте РФ осуществляется сопровождение выпускников старше 23
            лет
          </label>
          <Field as="select" id="p68" name="p68">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p69">
            5.1 при выборе ответа «да» в строке 5 укажите в каких случаях{" "}
          </label>
          <div className={s.checkboxGroup}>
            <label>
              <Field
                type="checkbox"
                name="p69"
                value="инвалидность"
                checked={values.p69?.split(';').includes("инвалидность")}
                onChange={(e) => {
                  const currentValues = values.p69 ? values.p69.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p69', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p69', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              инвалидность
            </label>
            <label>
              <Field
                type="checkbox"
                name="p69"
                value="отсутствие определенного места жительства"
                checked={values.p69?.split(';').includes("отсутствие определенного места жительства")}
                onChange={(e) => {
                  const currentValues = values.p69 ? values.p69.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p69', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p69', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              отсутствие определенного места жительства
            </label>
            <label>
              <Field
                type="checkbox"
                name="p69"
                value="неспособность к самообслуживанию в связи с болезнью / в период действия договора найма специализированного жилого помещения в целях содействия в преодолении трудной жизненной ситуации"
                checked={values.p69?.split(';').includes("неспособность к самообслуживанию в связи с болезнью / в период действия договора найма специализированного жилого помещения в целях содействия в преодолении трудной жизненной ситуации")}
                onChange={(e) => {
                  const currentValues = values.p69 ? values.p69.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p69', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p69', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              неспособность к самообслуживанию в связи с болезнью / в период действия договора найма
              специализированного жилого помещения в целях содействия в преодолении трудной жизненной ситуации
            </label>
            <label>
              <Field
                type="checkbox"
                name="p69"
                value="иное"
                checked={values.p69?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p69 ? values.p69.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p69', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p69', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>

          <label htmlFor="p69_1">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p69_1" name="p69_1" as="textarea" />

          <label htmlFor="p70">
            6. Численность выпускников старше 18 лет, не состоящих на
            сопровождении (не заключивших договор), которым оказана разовая
            помощь, в 2024 году
          </label>
          <Field
            id="p70"
            name="p70"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label>из них:</label>

          <label htmlFor="p72">
            6.1 выпускников организаций для детей-сирот{" "}
          </label>
          <Field
            id="p72"
            name="p72"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p73">6.2 выпускников семейных форм устройства </label>
          <Field
            id="p73"
            name="p73"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p74">
            7. В субъекте РФ осуществляется контроль качества сопровождения
            выпускников (при применении стандарта сопровождения выпускников)
          </label>
          <Field as="select" id="p74" name="p74">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p75">7.1 при выборе ответа «да» в строке 7 укажите, какими органами или
            организациями</label>
          <div className={s.checkboxGroup}>
            <label>
              <Field
                type="checkbox"
                name="p75"
                value="уполномоченные органы исполнительной власти субъектов РФ"
                checked={values.p75?.split(';').includes("уполномоченные органы исполнительной власти субъектов РФ")}
                onChange={(e) => {
                  const currentValues = values.p75 ? values.p75.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p75', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p75', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              уполномоченные органы исполнительной власти субъектов РФ
            </label>
            <label>
              <Field
                type="checkbox"
                name="p75"
                value="органы местного самоуправления"
                checked={values.p75?.split(';').includes("органы местного самоуправления")}
                onChange={(e) => {
                  const currentValues = values.p75 ? values.p75.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p75', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p75', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              органы местного самоуправления
            </label>
            <label>
              <Field
                type="checkbox"
                name="p75"
                value="организации, наделенные функциями координационного центра по сопровождению выпускников"
                checked={values.p75?.split(';').includes("организации, наделенные функциями координационного центра по сопровождению выпускников")}
                onChange={(e) => {
                  const currentValues = values.p75 ? values.p75.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p75', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p75', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              организации, наделенные функциями координационного центра по сопровождению выпускников
            </label>
            <label>
              <Field
                type="checkbox"
                name="p75"
                value="организации, осуществляющие сопровождение выпускников"
                checked={values.p75?.split(';').includes("организации, осуществляющие сопровождение выпускников")}
                onChange={(e) => {
                  const currentValues = values.p75 ? values.p75.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p75', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p75', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              организации, осуществляющие сопровождение выпускников
            </label>
            <label>
              <Field
                type="checkbox"
                name="p75"
                value="иное"
                checked={values.p75?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p75 ? values.p75.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p75', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p75', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>

          <label htmlFor="p76">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p76" name="p76" as="textarea" />

          <label htmlFor="p77">7.2 при выборе ответа «да» в строке 7, перечислите методы</label>
          <div className={s.checkboxGroup}>
            <label>
              <Field
                type="checkbox"
                name="p77"
                value="самоанализ"
                checked={values.p77?.split(';').includes("самоанализ")}
                onChange={(e) => {
                  const currentValues = values.p77 ? values.p77.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p77', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p77', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              самоанализ
            </label>
            <label>
              <Field
                type="checkbox"
                name="p77"
                value="экспертная оценка"
                checked={values.p77?.split(';').includes("экспертная оценка")}
                onChange={(e) => {
                  const currentValues = values.p77 ? values.p77.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p77', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p77', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              экспертная оценка
            </label>
            <label>
              <Field
                type="checkbox"
                name="p77"
                value="иное"
                checked={values.p77?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p77 ? values.p77.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p77', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p77', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>

          <label htmlFor="p78">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p78" name="p78" as="textarea" />

          <label htmlFor="p79">7.3 при выборе ответа «нет» в строке 7, укажите причины</label>
          <div className={s.checkboxGroup}>
            <label>
              <Field
                type="checkbox"
                name="p79"
                value="дефицит кадров"
                checked={values.p79?.split(';').includes("дефицит кадров")}
                onChange={(e) => {
                  const currentValues = values.p79 ? values.p79.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p79', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p79', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              дефицит кадров
            </label>
            <label>
              <Field
                type="checkbox"
                name="p79"
                value="загруженность специалистов"
                checked={values.p79?.split(';').includes("загруженность специалистов")}
                onChange={(e) => {
                  const currentValues = values.p79 ? values.p79.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p79', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p79', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              загруженность специалистов
            </label>
            <label>
              <Field
                type="checkbox"
                name="p79"
                value="планируется в 2025 г."
                checked={values.p79?.split(';').includes("планируется в 2025 г.")}
                onChange={(e) => {
                  const currentValues = values.p79 ? values.p79.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p79', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p79', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              планируется в 2025 г.
            </label>
            <label>
              <Field
                type="checkbox"
                name="p79"
                value="иное"
                checked={values.p79?.split(';').includes("иное")}
                onChange={(e) => {
                  const currentValues = values.p79 ? values.p79.split(';').filter(Boolean) : [];
                  if (e.target.checked) {
                    setFieldValue('p79', [...currentValues, e.target.value].join(';'));
                  } else {
                    setFieldValue('p79', currentValues.filter(value => value !== e.target.value).join(';'));
                  }
                }}
              />
              иное
            </label>
          </div>

          <label htmlFor="p80">
            При выборе ответа "иное" укажите комментарий
          </label>
          <Field id="p80" name="p80" as="textarea" />

          <label style={{ fontWeight: "bold" }}>
            Раздел 3. Сведения о выпускниках организаций для детей-сирот
          </label>

          <label htmlFor="p82">
            1. Общая численность выпускников организаций для детей-сирот в 2024
            году (по состоянию на 31 декабря) (сумма строк 1.1 и 1.2), из них:
          </label>
          <Field
            id="p82"
            name="p82"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p83">
            1.1 достигшие совершеннолетия либо признанные дееспособными в полном
            объеме (вступление в брак или эмансипация)
          </label>
          <Field
            id="p83"
            name="p83"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p84">1.2 не достигшие совершеннолетия</label>
          <Field
            id="p84"
            name="p84"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p85">
            1.3 обучающиеся по программам высшего образования (из строки 1){" "}
          </label>
          <Field
            id="p85"
            name="p85"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p86">
            1.4 обучающиеся по программам среднего профессионального образования
            (из строки 1){" "}
          </label>
          <Field
            id="p86"
            name="p86"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p87">
            1.5 обучающиеся по программам профессионального обучения (из строки
            1){" "}
          </label>
          <Field
            id="p87"
            name="p87"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p88">1.6 трудоустроенные (из строки 1) </label>
          <Field
            id="p88"
            name="p88"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p89">
            1.7 проходящие службу по призыву в Вооруженных Силах Российской
            Федерации (из строки 1){" "}
          </label>
          <Field
            id="p89"
            name="p89"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p90">
            1.8 участвующие в специальной военной операции (из строки 1){" "}
          </label>
          <Field
            id="p90"
            name="p90"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p91">
            1.9 отбывающие наказание в местах лишения свободы (из строки 1){" "}
          </label>
          <Field
            id="p91"
            name="p91"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p92">
            1.10 признанные недееспособными (из строки 1){" "}
          </label>
          <Field
            id="p92"
            name="p92"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p93">
            1.11 находящиеся в отпуске по беременности и родам или в отпуске по
            уходу за ребенком (из строки 1){" "}
          </label>
          <Field
            id="p93"
            name="p93"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p94">
            1.12 отчисленные из профессиональных образовательных организаций в
            2024 году (из строки 1)
          </label>
          <Field
            id="p94"
            name="p94"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p95">
            1.12.1 укажите причину отчисления (из строки 1.12)
          </label>
          <Field id="p95" name="p95" as="textarea" />

          <label htmlFor="p96">
            1.13 не работающие и не обучающиеся (из строки 1)
          </label>
          <Field
            id="p96"
            name="p96"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p97">1.14 иное (при наличии)</label>
          <Field
            id="p97"
            name="p97"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p98">
            1.14.1 уточните категорию выпускников из строки 1.14 (при наличии)
          </label>
          <Field id="p98" name="p98" as="textarea" />

          <label htmlFor="p99">
            1.15 число выпускников, проживающих в организации для детей-сирот
          </label>
          <Field
            id="p99"
            name="p99"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label style={{ fontWeight: "bold" }}>
            Раздел 4. Сведения о выпускниках семейных форм устройства{" "}
          </label>

          <label htmlFor="p101">
            1. Общая численность выпускников семейных форм устройства в 2024
            году (по состоянию на 31 декабря), из них:
          </label>
          <Field
            id="p101"
            name="p101"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p102">
            1.1 обучающиеся по программам высшего образования (из строки 1){" "}
          </label>
          <Field
            id="p102"
            name="p102"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p103">
            1.2 обучающиеся по программам среднего профессионального образования
            (из строки 1){" "}
          </label>
          <Field
            id="p103"
            name="p103"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p104">
            1.3 обучающиеся по программам профессионального обучения (из строки
            1){" "}
          </label>
          <Field
            id="p104"
            name="p104"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p105">1.4 трудоустроенные (из строки 1) </label>
          <Field
            id="p105"
            name="p105"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p106">
            1.5 проходящие службу по призыву в Вооруженных Силах Российской
            Федерации (из строки 1){" "}
          </label>
          <Field
            id="p106"
            name="p106"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p107">
            1.6 участвующие в специальной военной операции (из строки 1){" "}
          </label>
          <Field
            id="p107"
            name="p107"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p108">
            1.7 отбывающие наказание в местах лишения свободы (из строки 1){" "}
          </label>
          <Field
            id="p108"
            name="p108"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p109">
            1.8 признанные недееспособными (из строки 1){" "}
          </label>
          <Field
            id="p109"
            name="p109"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p110">
            1.9 находящиеся в отпуске по беременности и родам или в отпуске по
            уходу за ребенком (из строки 1){" "}
          </label>
          <Field
            id="p110"
            name="p110"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p111">
            1.10 отчисленные из профессиональных образовательных организаций в
            2024 году
          </label>
          <Field
            id="p111"
            name="p111"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p112">
            1.10.1 укажите причину отчисления (из строки 1.10)
          </label>
          <Field id="p112" name="p112" as="textarea" />

          <label htmlFor="p113">
            1.11 не работающие и не обучающиеся (из строки 1)
          </label>
          <Field
            id="p113"
            name="p113"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p114">1.12 иное (при наличии)</label>
          <Field
            id="p114"
            name="p114"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p115">
            1.12.1 уточните категорию выпускников из строки 1.12 (при наличии)
          </label>
          <Field id="p115" name="p115" as="textarea" />

          <label htmlFor="p116">
            1.13 число выпускников семейных форм устройства, проживающих в
            организации для детей-сирот
          </label>
          <Field
            id="p116"
            name="p116"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label style={{ fontWeight: "bold" }}>
            Раздел 5. Сведения о выпускниках, находящихся на сопровождении
          </label>

          <label htmlFor="p118">
            1. Общая численность выпускников, находящихся на сопровождении на 31
            декабря 2024 г. (заключивших договор), из них:
          </label>
          <Field
            id="p118"
            name="p118"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p119">
            1.1 несовершеннолетних выпускников (из строки 1)
          </label>
          <Field
            id="p119"
            name="p119"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p120">
            1.2 выпускников в возрасте 18 лет и старше (из строки 1)
          </label>
          <Field
            id="p120"
            name="p120"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p121">
            1.3 выпускников организаций для детей-сирот (из строки 1)
          </label>
          <Field
            id="p121"
            name="p121"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p122">
            1.4 выпускников семейных форм устройства (из строки 1)
          </label>
          <Field
            id="p122"
            name="p122"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p123">
            1.5 выпускников с ОВЗ, включая инвалидность (из строки 1)
          </label>
          <Field
            id="p123"
            name="p123"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p124">
            1.6 выпускников с умственной отсталостью (интеллектуальными
            нарушениями) (из строки 1)
          </label>
          <Field
            id="p124"
            name="p124"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p125">
            1.7 выпускников, страдающих социально значимыми, хроническими и
            тяжелыми заболеваниями, при наличии которых оказывается социальная
            поддержка (из строки 1)
          </label>
          <Field
            id="p125"
            name="p125"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p126">
            1.8 выпускников из числа несовершеннолетних беременных и одиноких
            молодых матерей (из строки 1)
          </label>
          <Field
            id="p126"
            name="p126"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p127">
            1.9 выпускников, совершивших уголовные преступления и/или
            находящихся под следствием, или имеющие условный срок или срок
            заключения в местах лишения свободы (из строки 1)
          </label>
          <Field
            id="p127"
            name="p127"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p128">
            1.10 выпускников, вернувшихся из специальных учебно-воспитательных
            учреждений всех типов (из строки 1)
          </label>
          <Field
            id="p128"
            name="p128"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p129">
            1.11 несовершеннолетних выпускников, состоящих на учете в органах и
            учреждениях системы профилактики безнадзорности и правонарушений
            несовершеннолетних (из строки 1)
          </label>
          <Field
            id="p129"
            name="p129"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p130">
            1.12 выпускников по окончании ими службы в Вооруженных Силах
            Российской Федерации, включая участников специальной военной
            операции (из строки 1)
          </label>
          <Field
            id="p130"
            name="p130"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p131">
            1.13 выпускников первично трудоустроенных (из строки 1)
          </label>
          <Field
            id="p131"
            name="p131"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label htmlFor="p132">
            1.14 выпускников в период получения ими профессионального
            образования (из строки 1)
          </label>
          <Field
            id="p132"
            name="p132"
            type="number"
            onWheel={(e) => e.target.blur()}
          />

          <label>
            2. Наличие в субъекте РФ централизованной информационной системы для
            учета и анализа данных:
          </label>

          <label htmlFor="p134">
            2.1 исключительно о выпускниках организаций для детей-сирот
          </label>
          <Field as="select" id="p134" name="p134">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p135">
            2.2 исключительно о выпускниках семейных форм устройства
          </label>
          <Field as="select" id="p135" name="p135">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p136">
            2.3 о выпускниках организаций для детей-сирот и выпускниках семейных
            форм устройства
          </label>
          <Field as="select" id="p136" name="p136">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p137">
            2.4 исключительно о выпускниках, находящихся на сопровождении по
            договору
          </label>
          <Field as="select" id="p137" name="p137">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label style={{ fontWeight: "bold" }}>
            Раздел 6. Реализация мероприятий по привлечению граждан, желающих
            быть наставниками воспитанников организаций для детей-сирот,
            выпускников, взаимодействию с НКО
          </label>

          <label>
            1. Наличие в субъекте РФ практик наставничества в отношении:
          </label>

          <label htmlFor="p140">
            1.1 воспитанников организаций для детей-сирот
          </label>
          <Field as="select" id="p140" name="p140">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p141">
            1.2 выпускников организаций для детей-сирот
          </label>
          <Field as="select" id="p141" name="p141">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p142">1.3 выпускников семейных форм устройства</label>
          <Field as="select" id="p142" name="p142">
            <option value="-">-</option>
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p143">
            1.4 несовершеннолетних подопечных, воспитывающихся в семьях опекунов
            (попечителей)
          </label>
          <Field as="select" id="p143" name="p143">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p144">
            2. Наличие утвержденной региональной программы наставничества
          </label>
          <Field as="select" id="p144" name="p144">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label>
            3. В субъекте РФ к гражданам, желающим быть наставниками
            воспитанников организаций для детей-сирот, предъявляются требования,
            связанные с:
          </label>

          <label htmlFor="p146">3.1 достижением определенного возраста</label>
          <Field as="select" id="p146" name="p146">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p147">
            3.2 наличием определенной разницы в возрасте с воспитанником
            организации для детей-сирот, в отношении которого осуществляется
            наставничество
          </label>
          <Field as="select" id="p147" name="p147">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p148">3.3 местом жительства </label>
          <Field as="select" id="p148" name="p148">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p149">
            3.4 прохождением наставником психологического тестирования
          </label>
          <Field as="select" id="p149" name="p149">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p150">
            3.5 прохождением программы подготовки наставников, реализуемой в
            субъекте РФ
          </label>
          <Field as="select" id="p150" name="p150">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p151">3.6 иные требования</label>
          <Field id="p151" name="p151" as="textarea" />

          <label htmlFor="p152">
            4. В субъекте РФ организована подготовка наставников,
          </label>
          <Field as="select" id="p152" name="p152">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p153">4.1 в том числе с участием НКО</label>
          <Field as="select" id="p153" name="p153">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p154">
            5. Организации, осуществляющие подготовку наставников (при наличии)
          </label>
          <Field id="p154" name="p154" as="textarea" />

          <label>6. Механизмы мотивации и поощрения наставников</label>

          <label htmlFor="p156">
            6.1 Имеются нематериальные формы поощрения наставников
          </label>
          <Field as="select" id="p156" name="p156">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p157">6.1.1 укажите формы из строки 6.1</label>
          <Field id="p157" name="p157" as="textarea" />

          <label htmlFor="p158">
            6.2 Имеются материальные формы поощрения наставников
          </label>
          <Field as="select" id="p158" name="p158">
            <option value="да">да</option>
            <option value="нет">нет</option>
          </Field>

          <label htmlFor="p159">6.2.1 укажите формы из строки 6.2</label>
          <Field
            id="p159"
            name="p159"
            as="textarea"
          />

          <label htmlFor="p160">7. Комментарии (при наличии)</label>
          <Field id="p160" name="p160" as="textarea" />

          <label htmlFor="p161">
            8. Контактные данные ответственного за заполнение формы (ФИО,
            должность, номер телефона, адрес электронной почты)
          </label>
          <Field id="p161" name="p161" as="textarea" required={true} />

          <p></p>
          <button type="submit">Отправить</button>
          <p></p>
          {respData.file && readOnly && (
            <a href={respData.file}>Скачать файл</a>
          )}
          <p></p>
          <div style={{ color: "red" }}>{err}</div>
        </Form>
      )}
    </Formik>
  );
};
