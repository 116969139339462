import s from "./NewsPages.module.css"
import img from "../../assets/News/News260325.jpg"
import {AdviceBlock, ShareBlock} from "./News170621";

const News260325 = () => {

    return (
        <div className={s.main}>
            <h1>
            3-4 апреля 2025 г. в г. Москве пройдет XIII Всероссийский съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов опеки и попечительства
            </h1>
            <div className={s.date}>
                3 – 4 апреля 2025 г
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <div>
                Минпросвещения России 3 – 4 апреля 2025 г. в Москве проводит ХIII Всероссийский съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов опеки и попечительства. 

                    <p></p>
                    К участию в Съезде приглашены более 300 делегатов из числа руководителей (заместителей руководителей) организаций для детей-сирот и представителей  исполнительных органов субъектов Российской Федерации, осуществляющих государственное управление в сфере опеки и попечительства в отношении несовершеннолетних. 

                    
                    <p></p>
                    Ключевой темой Съезда станет продвижение традиционных семейных ценностей, обеспечение и защита права детей жить и воспитываться в семье.
                    <p></p>
                    В программе Съезда запланированы лекции по актуальным вопросам, связанным с защитой прав и обеспечением интересов детей, находящихся в организациях для детей-сирот, обсуждение и обмен опытом, в том числе по вопросам, касающимся совместной работы организаций для детей-сирот и семьи в интересах ребенка, профессиональной помощи, направленной на укрепление детско-родительских отношений, создания безопасной среды для подростков, специфики воспитательной работы в организациях для детей-сирот.

                    <p></p>
                    Обеспечение проведения Съезда осуществляется ФГБУ «Центр защиты прав и интересов детей».

                </div>
                <ShareBlock/>
            </div>
            <AdviceBlock/>
        </div>
    )
}
export default News260325
