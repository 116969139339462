import s from "../Cabinet.module.css";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

const DynamicReportForm = () => {
    const [respData, setData] = useState({});
    const [formDefinition, setFormDefinition] = useState(null);
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            if (user.id) {
                try {
                    // Fetch form definition
                    const apiResponse = await axios.get(`https://frcopeca.ru/api/get-form/${id}/`);
                    setFormDefinition(apiResponse.data);
                    
                    // Fetch existing data if any
                    const dataConfig = {
                        method: "GET",
                        url: `https://frcopeca.ru${apiResponse.data.backend_url}?id=${user.id}`,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    };
                    
                    const dataResponse = await axios.request(dataConfig);
                    if (dataResponse.data[0]) {
                        setData(dataResponse.data[0]);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    setData({});
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading || !formDefinition) {
        return <div className={s.main}>Загрузка...</div>;
    }

    return (
        <div className={s.main}>
            <h1>{formDefinition.name}</h1>
            
            {formDefinition.description && (
                <div className={s.description} style={{ 
                    margin: "20px auto",
                    width: "90%",
                    whiteSpace: "pre-line"
                }}>
                    {formDefinition.description}
                </div>
            )}

            {respData.статус && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#000000",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Статус: {respData.статус}
                </div>
            )}
            {respData.комментарии && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "24px",
                        fontWeight: "normal",
                        color: "red",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Комментарий от проверяющего:
                    <br/>
                    {respData.комментарии}
                </div>
            )}
            <DynamicForm 
                formFields={formDefinition.form_structure.fields} 
                respData={respData}
                backendUrl={formDefinition.backend_url}
            />
        </div>
    );
};

const DynamicForm = ({ formFields, respData, backendUrl }) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [err, setErr] = useState("");
    const readOnly = respData.статус === "Опубликовано";
    const readOnlyRegion = respData.статус !== "" && respData.статус !== null;

    const generateInitialValues = () => {
        const initialValues = {
            регион: respData.регион || ""
        };
        formFields.forEach(field => {
            if (field.id) {
                initialValues['p' + field.id] = respData['p' + field.id] || "";
            }
        });
        return initialValues;
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={generateInitialValues()}
            onSubmit={(values) => {
                const formData = new FormData();
                
                Object.keys(values).forEach(key => {
                    if (values[key] instanceof File) {
                        formData.append(key, values[key]);
                    }
                    else if (values[key] !== null && values[key] !== undefined) {
                        formData.append(key, values[key]);
                    }
                });
                
                formData.append("статус", "На утверждении");
                formData.append("author", user.id);

                const config = {
                    method: respData.регион ? "patch" : "POST",
                    url: `https://frcopeca.ru${backendUrl}?author=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                    data: formData,
                };

                // Для отладки - проверяем содержимое FormData
                for (let pair of formData.entries()) {
                    console.log(pair[0] + ': ' + pair[1]); 
                }
               
                axios.request(config)
                    .then(() => {
                        setErr("");
                        navigate("/profile");
                    })
                    .catch((e) => {
                        const errorMessage = e.response
                            ? e.response.data?.message || e.response.statusText || "Ошибка на сервере"
                            : e.message || JSON.stringify(e);
                        setErr(errorMessage);
                        console.error("Error details:", e);
                    });
            }}
        >
            {({ values, setFieldValue }) => (
                <Form className={s.reportForm}>
                    <label htmlFor="регион">
                        Субъект Российской Федерации
                        <span className={s.required}>*</span>
                    </label>
                    <Field
                        as="textarea"
                        id="регион"
                        name="регион"
                        readOnly={readOnlyRegion}
                        required={true}
                    />

                    {formFields.map((field) => {
                        const fieldId = 'p' + field.id;

                        if (field.field_type === "абзац без текста") {
                            return (
                                <h2 key={fieldId} style={{ width: "100%", margin: "20px 0" }}>
                                    {field.name}
                                </h2>
                            );
                        }

                        return (
                            <React.Fragment key={fieldId}>
                                <label htmlFor={fieldId}>
                                    {field.number && <span className={s.fieldNumber}>{field.number}. </span>}
                                    {field.name}
                                    {field.required && <span className={s.required}>*</span>}
                                </label>

                                {field.field_type === "выбор из нескольких" && (
                                    <div className={s.checkboxGroup}>
                                        {field.choice_options
                                            .filter(option => option !== "—")
                                            .map((option, idx) => (
                                                <label key={idx}>
                                                    <Field
                                                        type="checkbox"
                                                        name={fieldId}
                                                        value={option}
                                                        checked={values[fieldId]?.split(';').includes(option)}
                                                        disabled={readOnly}
                                                        onChange={(e) => {
                                                            const currentValues = values[fieldId] ? 
                                                                values[fieldId].split(';').filter(Boolean) : [];
                                                            if (e.target.checked) {
                                                                setFieldValue(fieldId, 
                                                                    [...currentValues, e.target.value].join(';'));
                                                            } else {
                                                                setFieldValue(fieldId, 
                                                                    currentValues.filter(value => 
                                                                        value !== e.target.value).join(';'));
                                                            }
                                                        }}
                                                    />
                                                    {option}
                                                </label>
                                            ))}
                                    </div>
                                )}

                                {(field.field_type === "выбор из одного" || 
                                  field.field_type === "выпадающий список с выбором из одного") && (
                                    <Field
                                        as="select"
                                        id={fieldId}
                                        name={fieldId}
                                        disabled={readOnly}
                                        required={field.required}
                                    >
                                        <option value="">Выберите...</option>
                                        {field.choice_options
                                            .filter(option => option !== "—")
                                            .map((option, idx) => (
                                                <option key={idx} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                    </Field>
                                )}

                                {field.field_type === "число" && (
                                    <Field
                                        type="number"
                                        id={fieldId}
                                        name={fieldId}
                                        readOnly={readOnly}
                                        required={field.required}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                )}

                                {(field.field_type === "строка" || field.field_type === "текст") && (
                                    <Field
                                        as="textarea"
                                        id={fieldId}
                                        name={fieldId}
                                        readOnly={readOnly}
                                        required={field.required}
                                    />
                                )}

                                {field.field_type === "файл" && (
                                    <input
                                        type="file"
                                        id={fieldId}
                                        onChange={(event) => {
                                            setFieldValue(fieldId, event.currentTarget.files[0]);
                                        }}
                                        disabled={readOnly}
                                        required={field.required}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                    
                    <p></p>
                    <button type="submit" disabled={readOnly}>Отправить</button>
                    <p></p>
                    {respData.file && <a href={respData.file}>Скачать файл</a>}
                    <p></p>
                    <div style={{ color: "red" }}>{err}</div>
                </Form>
            )}
        </Formik>
    );
};

export default DynamicReportForm; 