import s from "./Files.module.css";
import { FileRef, LinkRef } from "./FZ";
import { useEffect, useState } from "react";
import axios from "axios";

const MetodMaterials = () => {
  const [respData, setData] = useState("");

  useEffect(() => {
    axios
      .get("https://frcopeca.ru/api/NPD")
      .then((res) => {
        setData(res);
      })
      .then(console.log(respData));
  }, []);

  return (
    <div className={s.main}>
      <div className={s.filePage}>
        <h2>Методические материалы</h2>
        <div className={s.buttonsList}>
          <FileRef
              text="Методические рекомендации по вопросам реализации жилищных прав детей-сирот, детей, оставшихся без попечения родителей, и лиц из числа детей-сирот и детей, оставшихся без попечения родителей, для специалистов органов опеки и попечительства"
              url="https://frcopeca.ru/media/media/behavior/МЕТОДИЧЕСКИЕ_РЕКОМЕНДАЦИИ.pdf"
          />
          <LinkRef
            url="/materials/rekomendacii-po-zapolneniu-ind-plana-razvitiya-rebenka"
            text="Рекомендации по заполнению индивидуального плана развития и жизнеустройства ребенка для специалистов организаций для детей-сирот и детей, оставшихся без попечения родителей, и органов опеки и попечительства"
          />
          <FileRef
              text="Методические рекомендации по вопросам защиты имущественных прав детей-сирот и детей, оставшихся без попечения родителей, проживающих в организациях социального обслуживания (детских домах-интернатах), предоставляющих социальные услуги в стационарной форме"
              url="https://frcopeca.ru/media/media/behavior/Методические_рекомендации_по_вопросам_защиты_имущественных_прав.pdf"
          />
          <FileRef
              text="Методические рекомендации по вопросам осуществления проверок органами опеки и попечительства в отношении детей-сирот и детей, оставшихся без попечения родителей, с психическими расстройствами, проживающих в организациях социального обслуживания (детских домах-интернатах), предоставляющих социальные услуги в стационарной форме"
              url="https://frcopeca.ru/media/media/behavior/Методические_рекомендации_по_вопросам_осуществления_проверок_органами.pdf"
          />
          <LinkRef
            url="/materials/sbornik-obrazcov-dokumentov-dlja-specialistov-organov-opeki-i-popechitelstva-v-otnoshenii-nesovershennoletnih-grazhdan"
            text="Сборник образцов документов для специалистов органов опеки и попечительства в отношении несовершеннолетних граждан"
          />
          <LinkRef
            url="/materials/metodicheskie-rekomendacii-o-pravilah-kommunikativnogo-povedenija-vkljuchaja-rechevuju-kommunikaciju-specialistov-organov-opeki-i-popechitelstva"
            text="Методические рекомендации о правилах коммуникативного поведения, включая речевую коммуникацию, специалистов органов опеки и попечительства"
          />
          <LinkRef
            url="/materials/metodicheskie-rekomendacii-po-okazaniju-semjam-s-detmi-nahodjashhimsja-v-socialno-opasnom-polozhenii-pedagogicheskoj-psihologicheskoj-i-juridicheskoj-pomoshhi-realizacii-programm-napravlennyh-na-podde"
            text="Методические рекомендации по оказанию семьям с детьми, находящимся в социально опасном положении, педагогической, психологической и юридической помощи, реализации программ, направленных на поддержку и укрепление родительства и детско-родительских отношений"
          />
          <LinkRef
            url="/materials/chek-list-dejstvij-pri-prinjatii-reshenija-organom-opeki-i-popechitelstva-ob-ustrojstve-rebenka-detej-ostavshegosja-bez-popechenija-roditelej-v-semju-na-vospitanie"
            text="Чек-лист действий при принятии решения органом опеки и попечительства об устройстве ребенка (детей), оставшегося без попечения родителей, в семью на воспитание"
          />
          <LinkRef
            url="/materials/metodicheskie-rekomendacii2023"
            text="Методические рекомендации по вопросам выдачи органами опеки и попечительства предварительных разрешений на осуществление имущественных прав ребенка"
          />
          <LinkRef
            url="/materials/reestr-programm"
            text="Реестр программ, направленных на укрепление родительства и детско-родительских отношений, организацию работы с несовершеннолетними и (или) семьями, признанными находящимися в социально опасном положении"
          />

          <FileRef
            text={respData ? respData.data[100].description : ""}
            url={respData ? respData.data[100].file : ""}
          />
          <FileRef
            text={respData ? respData.data[101].description : ""}
            url={respData ? respData.data[101].file : ""}
          />
          <FileRef
            text={respData ? respData.data[102].description : ""}
            url={respData ? respData.data[102].file : ""}
          />
          <FileRef
            text={respData ? respData.data[103].description : ""}
            url={respData ? respData.data[103].file : ""}
          />
          <FileRef
            text={respData ? respData.data[104].description : ""}
            url={respData ? respData.data[104].file : ""}
          />
          <FileRef
            text={respData ? respData.data[105].description : ""}
            url={respData ? respData.data[105].file : ""}
          />
          <FileRef
            text={respData ? respData.data[107].description : ""}
            url={respData ? respData.data[107].file : ""}
          />
          <LinkRef
            text="Лучшие региональные практики деятельности органов опеки и попечительства, а также по подготовке и психологическом обследованию лиц, желающих принять в свою семью ребенка, по сопровождению замещающих семей"
            url="/materials/best-regional-practices"
          />
          <FileRef
            text={respData ? respData.data[109].description : ""}
            url={respData ? respData.data[109].file : ""}
          />
          <FileRef
            text={respData ? respData.data[110].description : ""}
            url={respData ? respData.data[110].file : ""}
          />
          <FileRef
            text={respData ? respData.data[111].description : ""}
            url={respData ? respData.data[111].file : ""}
          />
          <FileRef
            text={respData ? respData.data[112].description : ""}
            url={respData ? respData.data[112].file : ""}
          />
          <FileRef
            text={respData ? respData.data[113].description : ""}
            url={respData ? respData.data[113].file : ""}
          />
          <FileRef
            text={respData ? respData.data[114].description : ""}
            url={respData ? respData.data[114].file : ""}
          />
          <FileRef
            text={respData ? respData.data[115].description : ""}
            url={respData ? respData.data[115].file : ""}
          />
          <FileRef
            text={respData ? respData.data[116].description : ""}
            url={respData ? respData.data[116].file : ""}
          />
          <FileRef
            text={respData ? respData.data[117].description : ""}
            url={respData ? respData.data[117].file : ""}
          />
          <FileRef
            text={respData ? respData.data[118].description : ""}
            url={respData ? respData.data[118].file : ""}
          />
          <FileRef
            text={respData ? respData.data[119].description : ""}
            url={respData ? respData.data[119].file : ""}
          />
          <FileRef
            text={respData ? respData.data[120].description : ""}
            url={respData ? respData.data[120].file : ""}
          />
          <FileRef
            text={respData ? respData.data[121].description : ""}
            url={respData ? respData.data[121].file : ""}
          />
          <FileRef
            text={respData ? respData.data[122].description : ""}
            url={respData ? respData.data[122].file : ""}
          />
          <FileRef
            text={respData ? respData.data[123].description : ""}
            url={respData ? respData.data[123].file : ""}
          />
          <FileRef
            text={respData ? respData.data[124].description : ""}
            url={respData ? respData.data[124].file : ""}
          />
          <FileRef
            text={respData ? respData.data[125].description : ""}
            url={respData ? respData.data[125].file : ""}
          />
          <FileRef
            text={respData ? respData.data[126].description : ""}
            url={respData ? respData.data[126].file : ""}
          />
          <FileRef
            text={respData ? respData.data[127].description : ""}
            url={respData ? respData.data[127].file : ""}
          />
          <FileRef
            text={respData ? respData.data[128].description : ""}
            url={respData ? respData.data[128].file : ""}
          />
          <FileRef
            text={respData ? respData.data[129].description : ""}
            url={respData ? respData.data[129].file : ""}
          />
          <FileRef
            text={respData ? respData.data[130].description : ""}
            url={respData ? respData.data[130].file : ""}
          />
          <FileRef
            text={respData ? respData.data[131].description : ""}
            url={respData ? respData.data[131].file : ""}
          />
          <FileRef
            text={respData ? respData.data[132].description : ""}
            url={respData ? respData.data[132].file : ""}
          />
        </div>
      </div>
    </div>
  );
};
export default MetodMaterials;
