export const navArrayEighth = [
    {
        "col1": "Алтайский край",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Амурская область",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Амурской области",
        "col4": "675000, Амурская область,\nг. Благовещенск,\nул. Шимановского, 8/ kuzikovata@mszn.amurobl.ru \n+7(4162) 200-783"
    },
    {
        "col1": "Архангельская область",
        "col2": 1,
        "col3": "Департамент по вопросам семьи, опеки и попечительства городского округа  «Город Архангельск»",
        "col4": "163000, г. Архангельск, пл. Ленина, д. 5 Телефон: 8 (8182) 60-74-39 , Факс: 8 (8182) 60-74-24, dzsp@arhcity.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 2,
        "col3": "Отдел опеки и попечительства администрации городского округа Архангельской области «Город Коряжма»",
        "col4": "165651, Архангельская область, \nг. Коряжма, ул. Ленина, д. 29,Телефон/факс: \n8 (818-50)3-03-08, 3-49-57, opeka1@koradm.ru\n\n"
    },
    {
        "col1": "Архангельская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства управления по социальным вопросам администрации городского округа Архангельской области «Котлас»",
        "col4": "165300, Архангельская область, г. Котлас, пл. Советов, д. 3,Телефон: 8(81837) 2-06-39, 2-05-57, oop50481@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 4,
        "col3": "Орган опеки и попечительства Муниципального учреждения «Управление образования и социальной сферы администрации Мирного»",
        "col4": "164170, Архангельская область, г. Мирный,     ул. Ленина, д. 33, Телефон, факс: 8(81834) 5-04-20, opekamirnyi@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 5,
        "col3": "Отдел опеки и попечительства управления социальной политики администрации городского округа Архангельской области «Город Новодвинск»",
        "col4": "164900, Архангельская область, г. Новодвинск, ул. Фронтовых бригад, д. 6, корп. 1, Телефон: 8(81852) 5-12-97, а.volkova@novadmin.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 6,
        "col3": "Отдел опеки и попечительства над несовершеннолетними Управления социального развития, опеки и попечительства городского округа Архангельской области «Северодвинск»",
        "col4": "164507, Архангельская область,г. Северодвинск, ул. Ломоносова, д. 41а,Телефон: 8(8184) 56-98-16, opekadeti@adm.severodvinsk.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства администрации Вельского муниципального района",
        "col4": "165150, Архангельская область, Вельский район, г. Вельск, ул. Гагарина, д. 37, Телефон/Факс: 8(81836) 6-40-59,opeka@velskmo.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 8,
        "col3": "Отдел опеки и попечительства Управления образования администрации Верхнетоемского муниципального округа Архангельской области ",
        "col4": "165500, Архангельская область, Верхнетоемский район, с. Верхняя Тойма, ул. Кировская, д. 6, Телефон, факс: 8(81854) 3-18-37, opekavt@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 9,
        "col3": "Отдел опеки и попечительства Управления образования и культуры администрации Вилегодского муниципального округа Архангельской области",
        "col4": "165680, Архангельская область, Вилегодский район, с. Ильинско-Подомское, ул. Советская, д. 34, Телефон, факс: 8(81843) 4-14-63, vilopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 10,
        "col3": "Отдел опеки и попечительства администрации Виноградовского муниципального округа Архангельской области",
        "col4": "164570, Архангельская область, Виноградовский район,п. Березник, ул. П. Виноградова, д. 83-А, Телефон, факс: 8(81831)      2-19-33, opeka@vmoao.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 11,
        "col3": "Отдел опеки и попечительства администрации Каргопольского муниципального округа Архангельской области",
        "col4": "164110, Архангельская область, Каргопольский район, г. Каргополь, ул. Победы, д. 12, Телефон, факс: 8(81841)2-26-30,                      2-18-45, kargopeka@mail.ru\n"
    },
    {
        "col1": "Архангельская область",
        "col2": 12,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Коношский муниципальный район»",
        "col4": "164010, Архангельская область, Коношский район, п. Коноша, ул. Советская, д. 76, Телефон, факс: 8 (81858)2-21-63, 8(81858)2-37-01, opeka.konosha@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 13,
        "col3": "Отдел опеки и попечительства Управления по социальной политике администрации Котласского муниципального округа Архангельской области",
        "col4": "165300, Архангельская область,  г. Котлас, пл. Советов, д. 9, Телефон: 8(81837) 2-17-20                     Факс: 8 (81837) 2-11-51, opekakr@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Красноборского муниципального округа Архангельской области",
        "col4": "165430, Архангельская область, Красноборский район, с. Красноборск, ул. Гагарина, д. 7-А, Телефон, факс: 8(81840) 3-15-57, oopkras@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 15,
        "col3": "Территориальный отдел по опеке и попечительству администрации муниципального образования «Ленский муниципальный район»",
        "col4": "165780, Архангельская область, Ленский район, с. Яренск, ул. Братьев Покровских, д. 19, Телефон, факс: 8(81859) 5-35-71, lenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства Управления образования администрации Лешуконского муниципального округа Архангельской области",
        "col4": "164670, Архангельская область, Лешуконский район, с. Лешуконское, ул. Красных Партизан,  д. 12, Телефон, факс: 8(81833) 3-00-13, leschopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 17,
        "col3": "Отдел опеки и попечительства управления образования администрации Мезенского муниципального округа Архангельской области",
        "col4": "164750, Архангельская область, Мезенский район, г. Мезень, пр. Советский, д. 51, Телефон, факс: 8(81848) 9-16-89, mezenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 18,
        "col3": "Отдел опеки и попечительства администрации Няндомского муниципального округа Архангельской области",
        "col4": "164200, Архангельская область, Няндомский район, г. Няндома,  ул. 60 лет Октября, д. 13, Телефон, факс: 8(818-38)6-21-68, nanopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 19,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Онежский муниципальный район»",
        "col4": "164840, Архангельская область, Онежский район, г. Онега, ул. Шаревского, д. 6, Телефон: 8 (81839) 7-03-68\nФакс: 8 (818-39) 7-04-21, opeka@onegaland.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 20,
        "col3": "Отдел по социальным вопросам, опеки и попечительства администрации Пинежского муниципального округа Архангельской области",
        "col4": "164600, Архангельская область, Пинежский район, с. Карпогоры, ул. Победы, д. 10-Б, Телефон, факс: 8 (81856)2-17-28, 2-13-11, pinopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 21,
        "col3": "Отдел опеки и попечительства администрации Плесецкого муниципального округа Архангельской области",
        "col4": "164262, Архангельская область, Плесецкий район, п. Плесецк, ул. Ленина, д. 33, Телефон: 8 (81832)7-35-94, 7-40-83     Факс: 8 (818-32) 7-28-98, oop-amoples@plesadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 22,
        "col3": "Отдел по опеке и попечительству администрации Приморского муниципального округа Архангельской области",
        "col4": "163002, г. Архангельск, пр. Ломоносова, д. 30, Телефон, факс :8(8182) 68-01-39, opeka@primadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 23,
        "col3": "Отдел опеки и попечительства администрации Устьянского муниципального округа Архангельской области",
        "col4": "165210, Архангельская область, Устьянский район, рп. Октябрьский, ул. Комсомольская,  д. 7, Телефон, факс: 8 (818-55)5-11-41, ustopekun@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 24,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних управления образования администрации Холмогорского муниципального округа Архангельской области",
        "col4": "164530, Архангельская область, Холмогорский район, с. Холмогоры, пл. Морозова, д. 10, Телефон: 8(818-30) 3-34-99, opeka-holmogory@rambler.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 25,
        "col3": "Отдел опеки и попечительства администрации Шенкурского муниципального округа Архангельской области",
        "col4": "165160, Архангельская область, Шенкурский район, г. Шенкурск, ул. Детгородок, д. 8, Телефон, факс: 8(818-51) 4-04-52, shenkopeka@mail.ru"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 1,
        "col3": "Служба жидищного надзора Астраханской области",
        "col4": "Почтовый адрес:414000, г. Астрахань, ул. Набережная 1 Мая, 75/48, адрес электронной почты: zhilnadzor_ao@mail.ru, тел. 8(8512) 24-50-22, 24-50-21 "
    },
    {
        "col1": "Белгородская область",
        "col2": 1,
        "col3": "Комитет строительства администрации Белгородского района \n",
        "col4": " 308007, Белгородская область, г.Белгород, ул. Шершнева, д. 1а,\ngilkombelrn@mail.ru,                       (4722) 26-13-46\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 2,
        "col3": "Управление социальной защиты населения администрации Борисовского района",
        "col4": "309340, Белгородская область, Борисовский район, п. Борисовка, пл. Ушакова, д.3, \nuszn@bo.belregion.ru,           (47246) 5-28-94 \n"
    },
    {
        "col1": "Белгородская область",
        "col2": 3,
        "col3": "Управление социальной защиты населения администрации Вейделевского района",
        "col4": "309720, Белгородская область, п.Вейделевка, ул. Мира, д.14, uszn@ve.belregion.ru\n (47237) 5-56-73\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 4,
        "col3": "Управление социальной защиты населения администрации Волоконовского района",
        "col4": "309650, Белгородская область, Волоконовский район, п.Волоконовка, ул. Комсомольская, д.25, \nvuszn@mail.ru, (47235) 5-13-68\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 5,
        "col3": "Администрация Ивнянского района",
        "col4": "309110, Белгородская область, Ивнянский район, п. Ивня, ул. Ленина, д. 20, \nfilshina_ev@iv.belregion.ru,\n(47243) 5-57-08\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 6,
        "col3": "Управление по строительству, транспорту, связи и ЖКХ администрации Корочанского района",
        "col4": "309210, Белгородская область, Корочанский район,\nг. Короча, пл. Васильева, д. 28\nkoradm_ugkh@bk.ru, (47231)5-56-74\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 7,
        "col3": "Отдел архитектуры управления строительства, транспорта  и ЖКХ администрации муниципального района «Красненский район»",
        "col4": "309870, Белгородская область, Красненский район, с. Красное, ул. Подгорная, д.4, (47262) 5-27-97, 5-28-56, arhitector@kr.belregion.ru uksigkh@kr.belregion.ru, "
    },
    {
        "col1": "Белгородская область",
        "col2": 8,
        "col3": "Жилищная комиссия при администрации Красногвардейского района Белгородской области",
        "col4": "309504, Белгородская область, Красногвардейский район, г. Бирюч, пл. Соборная, д. 1,\nu_gkh@bk.ru, (47247) 3-37-44, 3-10-62\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 9,
        "col3": "Управление социальной защиты населения администрации Краснояружского района",
        "col4": "309420, Белгородская область, Краснояружский район, п.Красная\nЯруга, ул, Парковая, д.З8А,\n (47263) 46-2-29,   USZN_Kr.Yaruga@mail.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 10,
        "col3": "Межведомственная комиссия при администрации Прохоровского района по оценке соответствия жилого помещения (многоквартирного дома) требованиям, установленным в Положении о признании помещения жилым помещением, жилого помещения непригодным для проживания, многоквартирного дома аварийным и подлежащим сносу или реконструкции, садового дома жилым домом и жилого дома садовым домом",
        "col4": "Белгородская область, Прохоровский район,\nп. Прохоровка, \nул. Советская, д.162,\ngil.polit@yandex.ru,                     (47242) 2-15-55\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 11,
        "col3": "Администрация муниципального района «Ракитянский район»",
        "col4": "309310, Белгородская область, Ракитянский район,\nп. Ракитное, пл. Советская, д.2,        (47245) 5-55-16, arakitnoe@ra.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 12,
        "col3": "Управление капитального строительства, транспорта, ЖКХ и топливно- энергетического комплекса администрации Ровеньского района",
        "col4": "309740 Белгородская область, Ровеньский район, п. Ровеньки, пер. Советский д.1, \nUks@ro.belregion.ru, (47238)5-53-65\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 13,
        "col3": "Администрация Чернянского района",
        "col4": "309560, Белгородская область, \nп. Чернянка, пл. Октябрьская, \nд. 1, adm@ch.belregion.ru,\n(47232) 5-57-80\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 14,
        "col3": "Жилищная комиссия при администрации Алексеевского муниципального округа",
        "col4": "309850, Белгородская область, Алексеевский муниципальный округ, г.Алексеевка, пл. Победы, д. 75,  (47234) 4-62-86,  opekaal@mail.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 15,
        "col3": "Межведомственная комиссия Валуйского муниципального округа по оценке и обследованию помещения в целях признания его жилым помещением, жилого помещения пригодным (непригодным) для проживания граждан, а также многоквартирного дома в целях признания его аварийным и подлежащим сносу или\nреконструкции",
        "col4": "309996, Белгородская область, Валуйский муниципальный округ,\nг. Валуйки, ул. М.Горького, д. 1, valgkh@mail.ru, (47236) 3-12-14, 3-24-43\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 16,
        "col3": "Управление  по строительству, транспорту, ЖКХ и ТЭК администрации Грайворонского муниципального округа",
        "col4": "309370, Белгородская область, Грайворонский район, г. Грайворон, ул. Комсомольская, д. 21,\npolina.davydenko.71@mail.ru, (47261)4-52-96\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 17,
        "col3": "Управление социальной защиты населения администрации\nНовооскольского муниципального округа\n",
        "col4": "309640, Белгородская область, Новооскольский муниципальный округ,\nг.Новый Оскол, ул. Гражданская, д.44,  uszn@no.belregion.ru, (47233) 4-65-14"
    },
    {
        "col1": "Белгородская область",
        "col2": 18,
        "col3": "Администрация Шебекинского муниципального округа",
        "col4": "309290, Белгородская область, Шебекинский муниципальный округ, г.Шебекино, пл. Центральная, д. 2,          (47248) 3-29-22, admshebekino@sh.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 19,
        "col3": "Алдминистрация Яковлевского муниципального округа ",
        "col4": "309070, Белгородская область, Яковлевский муниципальный округ, г.Строитель, ул. Ленина, д. 16, yakov@ya.belregion.ru, (47244) 6-93-93"
    },
    {
        "col1": "Белгородская область",
        "col2": 20,
        "col3": "Жилищное управление администрации города Белгорода\n",
        "col4": "308007, Белгородская область, г. Белгород, ул. Некрасова, д. 9/15, (4722) 26-84-92, 26-84-95, gil_otdel@mail.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 21,
        "col3": "Межведомственная комиссия Губкинского городского округа Белгородской области по рассмотрению вопросов о признании помещения жилым помещение, жилого помещения непригодным для проживания, многоквартирного дома аварийным и подлежащим сносу или реконструкции, садового дома жилым домом и жилого дома садовым домом",
        "col4": "309186, Белгородская область, Губкинский городской округ,\nг. Губкин, ул. Победы, д. 3, uzkk@gu.belregion.ru,\n(47241) 5-29-95\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 22,
        "col3": "Жилищное управление департамента жилищно-коммунального хозяйства администрации Старооскольского городского округа Белгородской области",
        "col4": "309504, Белгородская область, Старооскольский городской округ, г. Старый Оскол, ул. Комсомольская, д. 57,\n gu@so.belregion.ru, (4725) 44-52-87\n"
    },
    {
        "col1": "Брянская область",
        "col2": 1,
        "col3": "Управление Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека по Брянской области",
        "col4": "241050, Брянская область, г.Брянск, 2-ой Советский переулок, дом 5, литер А, uprav@32.rospotrebnadzor.ru, 8(4832)74-20-45"
    },
    {
        "col1": "Брянская область",
        "col2": 2,
        "col3": "Государственное бюджетное учреждение \"Брянскоблтехинвентаризация\"",
        "col4": "241007, Брянская область, г. Брянск, ул. Дуки, д. 48, aup@gupti.ru, 8(4832)64-86-99"
    },
    {
        "col1": "Владимирская область",
        "col2": 1,
        "col3": "Администрация г. Владимира",
        "col4": "600000, г. Владимир, ул. Горького, д. 36\n8 (4922) 35-33-33, 35-41-26 citizens@vladimir-city.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 2,
        "col3": "Администрация г. Гусь-Хрустальный Владимирской области",
        "col4": "601500, Владимирская обл., г. Гусь-Хрустальный, ул.Калинина, д. 1\n8 (49241) 2-45-53\npriem@gusadmin.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 3,
        "col3": "Администрация г. Коврова Владимирской области",
        "col4": "601900, Владимирская обл., г. Ковров, ул. Краснознаменная, д.6\n8 (49232) 3-11-35\ninfo@kovrov-gorod.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 4,
        "col3": "Администрация г. Мурома Владимирской области",
        "col4": "602267, Владимирская обл., г. Муром, пл.1100-летия, д.1\n8 (49234) 3-11-02\npost@murom.info "
    },
    {
        "col1": "Владимирская область",
        "col2": 5,
        "col3": "Администрация ЗАТО г. Радужный Владимирской области",
        "col4": "600910, Владимирская обл., г. Радужный, кв-л 1-й, д. 55\n8 (49254) 3-28-99\nraduga_oszn@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 6,
        "col3": "Администрация Александровского района",
        "col4": "601650, Владимирская обл., г. Александров, ул. Красной молодёжи, д.7\n8 (49244) 2-21-31\nalexan@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 7,
        "col3": "Администрация Вязниковского района",
        "col4": "601443, Владимирская обл., г. Вязники, ул. Комсомольская, д.1\n8 (49233) 2-51-38\nvyazn@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 8,
        "col3": "Администрация Гороховецкого района",
        "col4": "601481 Владимирская обл., г. Гороховец, ул. Ленина, д.93\n8 (49238) 2-15-65\nagr@gorohovec.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 9,
        "col3": "Администрация Гусь-Хрустального района",
        "col4": "601501, Владимирская обл., г. Гусь-Хрустальный, ул. Карла Либкнехта, д. 6\n8 (49241) 2-17-66\ngusr@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 10,
        "col3": "Администрация Камешковского района",
        "col4": "601300, Владимирская обл., г. Камешково, ул. Свердлова, д. 10\n8 (49248) 2-12-07\npost@admkam.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 11,
        "col3": "Администрация Киржачского района",
        "col4": "601010, Владимирская обл., г. Киржач, ул. Серегина, д. 7\n8 (49237) 2-01-77\ninfo@kirzhach.su "
    },
    {
        "col1": "Владимирская область",
        "col2": 12,
        "col3": "Администрация Ковровского района",
        "col4": "601900, Владимирская обл., г. Ковров, ул. Дегтярёва, д. 34.\n8 (49232) 2-16-22\nkovrr@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 13,
        "col3": "Администрация Кольчугинского района",
        "col4": "601785, Владимирская область, Кольчугинский район, г. Кольчугино, пл. Ленина, д. 2.\n8 (49245) 2-39-83\nkolch@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 14,
        "col3": "Администрация Петушинского района",
        "col4": "601144, Владимирская обл., г. Петушки, ул. Советская площадь, дом 5.\n8 (49243) 2-20-82, 2-15-04\ninfo@petushki.info "
    },
    {
        "col1": "Владимирская область",
        "col2": 15,
        "col3": "Администрация Селивановского района",
        "col4": "602332, Владимирская обл., Селивановский район, пос. Красная Горбатка, ул. Красноармейская д.12\n8 (49236) 2-10-81\ncentr@selivanovo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 16,
        "col3": "Администрация Судогодского района",
        "col4": "601351, Владимирская обл., г. Судогда, ул. Коммунистическая, зд. 1б\n8 (49235) 2-16-07\nadmsud@admsud.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 17,
        "col3": "Администрация Суздальского района",
        "col4": "601293, Владимирская обл., г. Суздаль, ул. Красная Площадь, д.1\n8 (49231) 2-13-45, 2-30-09\norg@suzdalregion.ru ; info@suzdalregion.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 18,
        "col3": "Администрация Юрьев-Польского района",
        "col4": "601800, Владимирская обл., г. Юрьев-Польский, ул. Шибанкова, д. 33\n8 (49246) 2-24-35\nyurier@avo.ru \n"
    },
    {
        "col1": "Владимирская область",
        "col2": 19,
        "col3": "Администрация Меленковского муниципального округа",
        "col4": "602102, Владимирская обл., Меленковский район, город Меленки, Красноармейская улица, 102.\n8 (49247) 2-20-94\nmelenky@melenky.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 20,
        "col3": "Администрация Собинского муниципального округа",
        "col4": "601204, Владимирская обл., Собинский район, г Собинка, ул. Садовая, д. 4\n8 (49242) 2-20-35\npost@sbnray.ru "
    },
    {
        "col1": "Волгоградская область",
        "col2": 1,
        "col3": "Администрация Алексеевского муниципального района Волгоградской области",
        "col4": "Волгоградская область, ст.Алексеевская, ул.Ленина, д.39, , телефон: 8 (8444) 63-18-31, 8 (8444) 63-22-43, E-mail: ra_alex@volganet.ru  "
    },
    {
        "col1": "Волгоградская область",
        "col2": 2,
        "col3": "Администрация Быковского муниципального района Волгоградской области",
        "col4": "Волгоградская область, р.п.Быково, ул.Советская, д.65, телефон: 8 (8449) 53-11-88, 8 (8442) 30-60-21, \nE-mail: ra_bykov@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 3,
        "col3": "Администрация Городищенского муниципального района Волгоградской области",
        "col4": "Волгоградская область, р.п. Городище, пл.40 лет Сталинградской битвы, д.1,  телефон: 8 (8446) 83-38-38, 8 (8446) 83-35-30 \nE-mail: ra_gorod@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 4,
        "col3": "Администрация Даниловского муниципального района Волгоградской области",
        "col4": "Волгоградская область, р.п. Даниловка, ул.Центральная, д.7,  \nтелефон: 8 (8446) 15-36-56, 8 (8446) 15-38-54 \nE-mail: ra_danil@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 5,
        "col3": "Администрация Дубовского муниципального района Волгоградской области ",
        "col4": "Волгоградская область, г.Дубовка, ул.Минина д.1, \nтелефон: 8 (8445) 83-11-60, 8 (8445) 83-11-60\nE-mail: ra_dubov@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 6,
        "col3": "Администрация Еланского муниципального района Волгоградской области",
        "col4": "Волгоградская область, р.п. Елань, ул.Ленинская, д.121, \nтелефон: 8 (8445) 25-44-55, 8 (8445) 25-60-66\nE-mail: ra_elan@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 7,
        "col3": "Администрация Жирновского муниципального района Волгоградской области",
        "col4": "Волгоградская область, г.Жирновск ул.Зои Космодемьянской, д.1, \nтелефон: 8 (8445) 45-25-23, 8 (8445) 45-11-13\nE-mail: ra_zhirn@volganet.ru \n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 8,
        "col3": "Администрация Иловлинского муниципального района Волгоградской области",
        "col4": "Волгоградская область, р.п. Иловля, ул. Будённого, д.7,  Телефон: 8 (8446) 75-14-00, 8 (8446) 75-20-88\nE-mail: ra_ilov@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 9,
        "col3": "Администрация Калачевского муниципального района Волгоградской области",
        "col4": "Волгоградская область,                       г. Калач-на-Дону, ул.Революционная, д.158, \nТелефон: 8 (8447) 23-17-22, 8 (8447) 23-13-35, 8 (8442) 30-61-64\nE-mail: ra_kalach@volganet.ru \n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 10,
        "col3": "Администрация Камышинского муниципального района Волгоградской области",
        "col4": "Волгоградская область, г.Камышин, ул.Набережная, д.7а, \nТелефон: 8 (8445) 74-83-00, 8(8445) 74-05-18\nE-mail: ra_kams@volganet.ru \n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 11,
        "col3": "Администрация Киквидзенского муниципального района Волгоградской области",
        "col4": " Волгоградская область, Станица Преображенская, ул.Мира, д.55,\nТелефон: 8 (8444) 53-18-35\nE-mail: ra_kikv@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 12,
        "col3": "Администрация Клетского муниципального района Волгоградской области",
        "col4": "Волгоградская область, Станица Клетская, ул.Буденного, д.20, \nТелефон: 8 (8446) 64-11-30\n E-mail ra_klet@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 13,
        "col3": "Администрация Котельниковского муниципального района волгоградской области",
        "col4": "Волгоградская область, г.Котельниково, ул.Ленина, д.9\nТелефон: 8 (8447) 63-31-96, 8 (8442) 30-62-61, 8 (8447) 63-35-8\nE-mail: ra_kotel@volganet.ru \n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 14,
        "col3": "Администрация Котовского муниципального района Волгоградской области",
        "col4": "Волгоградская область, г.Котово ул.Мира, д.122, \nТелефон: 8 (8445) 52-11-30\nE-mail: ra_kotov@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 15,
        "col3": "Администрация Кумылженского муниципального района Волгоградской области",
        "col4": "Волгоградская область, Станица Кумылженская, ул.Мира, д.18, \nТелефон: 8 (8446) 26-11-04\nE-mail: ra_kuml@volganet.ru \n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 16,
        "col3": "Администрация Ленинского муниципального района Волгоградской области",
        "col4": "Волгоградская область, г.Ленинск, ул.им.Ленина, д.209\nТелефон: 8 (8447) 84-21-42, 8 (8447) 84-16-71, 8 (8442) 30-63-02\nE-mail: ra_lenin@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 17,
        "col3": "Администрация Нехаевского муниципального района Волгоградской области",
        "col4": "Волгоградская область, станица Нехаевская, ул.Ленина, д.41\nТелефон: 8 (8444) 35-10-44, 8 (8444) 35-10-44\nE-mail: ra_nehaev@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 18,
        "col3": "Администрация Николаевского муниципального района Волгоградской области",
        "col4": "Волгоградская область, г.Николаевск, ул.Октябрьская, д.23а\nТелефон: 8 (84494) 6-12-50, 8 (84494) 6-32-91 \nE-mail: ra_nik@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 19,
        "col3": "Администрация Новоаннинского района Волгоградской области",
        "col4": "Волгоградская область, г.Новоаннинский, площадь Ленина, д.5\nТелефон: 8 (8444) 73-61-11 \nE-mail:ra_novan@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 20,
        "col3": "Администрация Новониколаевского муниципального района Волгоградской области",
        "col4": "Волгоградская область, р.п.Новониколаевский, ул.Народная, д.77.\nТелефон: 8 (8444) 46-10-74, 8 (8444) 46-12-80 \nE-mail: ra_novnik@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 21,
        "col3": "Администрация Октябрьского муниципального района Волгоградской области",
        "col4": "Волгоградская область, р.п.Октябрьский, ул.Центральная,д.24\nТелефон: 8 (8447) 56-16-31, 8 (8447) 56-15-41\nE-mail: ra_oct@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 22,
        "col3": "Администрация Ольховского муниципального района Волгоградской области",
        "col4": "Волгоградская область, с.Ольховка, ул.Комсомольская, д.7\nТелефон: 8 (8445) 62-12-50\nE-mail: ra_olhov@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 23,
        "col3": "Администрация Палласовского муниципального района Волгоградской области",
        "col4": "Волгоградская область, г.Палласовка, ул.Коммунистическая, д.2 Телефон: 8(8449) 26-29-86, 8 (8449) 26-15-56, 8(8449)26-13-76, E-mail: ra_pal@volganet.ru"
    },
    {
        "col1": "Волгоградская область",
        "col2": 24,
        "col3": "Администрация Руднянского муниципального района Волгоградской области ",
        "col4": "Волгоградская область,  р.п.Рудня, ул.Октябрьская, д.110, Телефон: 8(8442) 30-65-21, 8 (8445) 37-12-30, 8 (8445)37-12-30\nE-mail: ra_rudn@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 25,
        "col3": "Администрация Светлоярского муниципального района Волгоградской области ",
        "col4": "Волгоградская область,  р.п.Светлый Яр, ул.Спортивная, д.5\nТелефон: 8 (8447) 76-21-35, 8(8447)76-26-39\nE-mail: ra_svet@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 26,
        "col3": "Администрация Серафимовичского муниципального района Волгоградской области",
        "col4": "Волгоградская область,  г.Серафимович, ул.Октябрьская, д.67\nТелефон: 8 (8446) 44-44-42, 8 (8446) 44-33-52\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 27,
        "col3": "Администрация Среднеахтубинского муниципального района Волгоградской области",
        "col4": "Волгоградская область,  р.п.Средняя Ахтуба, ул.Ленина, д.65\nТелефон: 8 (8447) 95-13-89\nE-mail: ra_sredn@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 28,
        "col3": "Администрация Суровикинского муниципального района Волгоградской области",
        "col4": "Волгоградская область, г.Суровикино, ул.Ленина, д.64\nТелефон: 8 (8447) 32-21-60, 8 (8447) 32-21-60\nE-mail: ra_sur@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 29,
        "col3": "Администрация Старополтавского муниципального района Волгоградской области",
        "col4": "Волгоградская область,  с.Старая Полтавка, ул.Ленина, д.1а\nТелефон: 8 (8449) 34-38-86, 8 (8442) 30-66-04, 8 (8449) 34-35-40\nE-mail: ra_star@volganet.ru\n\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 30,
        "col3": "Администрация Урюпинского муниципального района Волгоградской области",
        "col4": "Волгоградская область,  г.Урюпинск, пл.Ленина, д.3\nТелефон. 8 (8444) 24-30-78, 8 (8444) 24-30-78, 8 (8444) 24-14-93 \nE-mail: ra_uryp@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 31,
        "col3": "Администрация Фроловского муниципального района Волгоградской области",
        "col4": "Волгоградская область, г.Фролово, ул.Фрунзе, д.87\nТелефон: 8 (8446) 52-49-95\nE-mail: ra_frol@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 32,
        "col3": "Администрация Чернышковского муниципального района Волгоградской области",
        "col4": "Волгоградская область, р.п.Чернышковский, ул.Советская, д.82\nТелефон: 8 (8447) 46-11-33\nE-mail: ra_cher@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 33,
        "col3": "Администрация городского округа город-герой Волгоград",
        "col4": "Волгоградская область,  г.Волгоград, ул.Володарского, д.5.\nТелефон: 8 (8442) 30-12-83\nE-mail: kancelyaria@volgadmin.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 34,
        "col3": "Администрация городского округа город Волжский Волгоградской области",
        "col4": "Волгоградская область,  г.Волжский, пр-т Ленина, д.21\nТелефон: 8 (8443) 42-12-45\nE-mail: ag_volj@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 35,
        "col3": "Администрация городского округа город Камышин Волгоградской области",
        "col4": "Волгоградская область,  г.Камышин, ул.Октябрьская, д.60\nТелефон: 8 (8445) 74-36-00, 8 (8445) 74-36-44\nE-mail: ag_kams@volganet.ru\n\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 36,
        "col3": "Администрация муниципального округа город Михайловка Волгоградской области",
        "col4": "Волгоградская область,  г.Михайловка, ул.Обороны, д.42а\nТелефон: 8 (8446) 32-13-52, 8 (8446) 32-12-53\nE-mail: ag_mih@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 37,
        "col3": "Администрация городского округа город Урюпинск Волгоградской области",
        "col4": "Волгоградская область,  г.Урюпинск, пл.Ленина, д.3\nТелефон: 8 (8444) 24-30-50, 8 (8444) 24-38-05\nE-mail: ag_uryp@volganet.ru\n"
    },
    {
        "col1": "Волгоградская область",
        "col2": 38,
        "col3": "Администрация городского округа город Фролово Волгоградской области",
        "col4": "Волгоградская область, г.Фроволо, ул.Революционная, д.12\nТелефон: 8 (8446) 52-35-20, 52-36-50, 8 (8446) 52-14-58, 30-66-72 \nE-mail: ag_frol@volganet.ru\n"
    },
    {
        "col1": "Вологодская область",
        "col2": 1,
        "col3": "Министерство строительства Вологодской области",
        "col4": "160000, г. Вологда, ул. Герцена, д. 27, sidorova.ev@depstroy.gov35.ru, \n(8172) 23-00-55 (доб. 1251)"
    },
    {
        "col1": "Воронежская область",
        "col2": 1,
        "col3": "Управление жилищных отношений администрации городского округа город Воронеж",
        "col4": "ул. Пирогова, 87, г. Воронеж, 394038,\n8(473) 239-81-49\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 2,
        "col3": "Борисоглебский городской округ",
        "col4": "ул. Свободы, 207,\nг. Борисоглебск, 397160,\nтел. 8(47354)6-20-18,\nboris@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 3,
        "col3": "Городской округ город Нововоронеж",
        "col4": "ул. Космонавтов, 4, \nг. Нововоронеж, 396070,\nтел. 8(47364)2-59-50, 8(47364)2-46-06, \nnvor@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 4,
        "col3": "Аннинский муниципальный район",
        "col4": "ул. Ленина, 28,пгт  Анна, 396250,\n тел. 8(47346)2-13-62, 8(47346)2-71-63,\nanna@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 5,
        "col3": "Бобровский муниципальный район",
        "col4": "ул. Кирова, 32а,г. Бобров, 397700,\n тел. 8(47350)4-15-70,    \nbobr1@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 6,
        "col3": "Богучарский муниципальный район",
        "col4": "ул. Кирова, 1,г. Богучар, 396790,\n тел. 8(47366)2-29-63,\nboguch@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 7,
        "col3": "Бутурлиновский муниципальный район",
        "col4": "пл. Воли, 43,г. Бутурлиновка, 397500,\nтел. 8(47361)2-47-95,\nbuturl@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 8,
        "col3": "Верхнемамонский муниципальный район",
        "col4": "пл. Ленина, 1,\nс. Верхний Мамон, 396460,\n тел. 8(47355)5-63-08,\nvmamon@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 9,
        "col3": "Верхнехавский муниципальный район",
        "col4": "ул. 50 лет Октября, 17а, \nс. Верхняя Хава, 396110\nтел. 8(47343)7-14-01,\nvhav@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 10,
        "col3": "Воробьевский муниципальный район",
        "col4": "пл. Свободы, 1,\nс. Воробьевка, 397570,\nтел. 8(47356)3-15-99\nvorob@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 11,
        "col3": "Грибановский муниципальный район",
        "col4": "ул. Центральная, 4,\nпгт  Грибановский, 397240,\n тел. 8(47348)3-03-54,\ngrib@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 12,
        "col3": "Калачеевский муниципальный район",
        "col4": "пл. Ленина, 8,г. Калач, 397600,\n тел. 8(47363)2-10-31,\nkalach@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 13,
        "col3": "Каменский муниципальный район",
        "col4": "пл. Ленина, 26,пгт  Каменка, 396510,\n тел. 8(47357)5-33-06,\nkamen@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 14,
        "col3": "Кантемировский муниципальный район",
        "col4": "ул. Победы, 17, \nр.п. Кантемировка, 396730,\nтел. 8(473-67)6-26-47,\nkantem@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 15,
        "col3": "Каширский муниципальный район",
        "col4": "ул. Олимпийская, 3,\nс. Каширское, 396350,\n тел. 8(47342)4-10-42,\nkashir@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 16,
        "col3": "Лискинский муниципальный район",
        "col4": "пр-т Ленина, 32,г. Лиски, 397900,\n тел. 8(47391)4-67-20,\nliski@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 17,
        "col3": "Нижнедевицкий муниципальный район",
        "col4": "пл. Ленина, 1,\nс. Нижнедевицк, 396870,\n тел. 8(47370)5-16-03,\nndev@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 18,
        "col3": "Новоусманский муниципальный район",
        "col4": "ул. Советская, 1, \nс. Новая Усмань, 396310,\nтел. 8(47341)5-59-41,\nnusm@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 19,
        "col3": "Новохоперский муниципальный район",
        "col4": "ул. Советская, 14,\nг. Новохопёрск, 397400,\n тел. 8(47353)3-15-32,\nnovohoper@govvrn.ru, \n"
    },
    {
        "col1": "Воронежская область",
        "col2": 20,
        "col3": "Ольховатский муниципальный район",
        "col4": "ул. Октябрьская, 64,\nр.п. Ольховатка, 396670,\n тел. 8(47395)40-7-63,\nolhov@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 21,
        "col3": "Острогожский муниципальный район",
        "col4": "ул. Ленина, 22,\nг. Острогожск, 397855,\n тел. 8(47375)4-22-80\nostro@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 22,
        "col3": "Павловский муниципальный район",
        "col4": "пр-т Революции, 8, \nг. Павловск, 396422,\nтел. 8(47362)2-53-14,\npavl@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 23,
        "col3": "Панинский муниципальный район",
        "col4": "ул. Советская, 2, р.п. Панино, 396140,\nтел. 8(47344)4-70-35\npanin@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 24,
        "col3": "Петропавловский муниципальный район",
        "col4": "ул. Победы, 28, \nс. Петропавловка, 397670,\nтел. 8(47365)2-18-08\nppavl@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 25,
        "col3": "Поворинский муниципальный район",
        "col4": "пл. Комсомольская, 3,\nг. Поворино, 397350,\n тел. 8(47376)4-20-34,\npovor@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 26,
        "col3": "Подгоренский муниципальный район",
        "col4": "ул. Первомайская, 58,\nпгт Подгоренский,396560,\n тел. 8(47394)5-54-42\npodgor@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 27,
        "col3": "Рамонский муниципальный район",
        "col4": "ул. 50 лет ВЛКСМ, 5,\nр.п. Рамонь, 396020,\n тел. 8(47340)2-15-59,\nramon@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 28,
        "col3": "Репьевский муниципальный район",
        "col4": "пл. Победы, 1, с. Репьёвка, 396370,\nтел. 8(47374)2-21-84\nrepev@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 29,
        "col3": "Россошанский муниципальный район",
        "col4": "пл. Ленина, 4,г. Россошь, 396650,\n тел. 8(47396)2-22-66, \nross@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 30,
        "col3": "Семилукский муниципальный район",
        "col4": "ул. Ленина, 11, г. Семилуки, 396901,\n тел. 8(47372)2-34-54,\nsemil@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 31,
        "col3": "Таловский муниципальный район",
        "col4": "ул. Советская, 132,\nр.п. Таловая, 397480,\n тел. 8(47352)2-16-58,\ntalovsk@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 32,
        "col3": "Терновский муниципальный район",
        "col4": "ул. Советская, 39, \nс. Терновка, 397110,\nтел. 8(47347)5-51-44, \nternov@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 33,
        "col3": "Хохольский муниципальный район",
        "col4": "ул. Ленина, 8, \nр.п. Хохольский, 396840,\nтел. 8(47371)4-15-47, \nhohol@govvrn.ru, admhohol@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 34,
        "col3": "Эртильский муниципальный район",
        "col4": "пл. Ленина, 1, г. Эртиль, 397030,\nтел. 8(47345)2-13-62,\nertil@govvrn.ru\n"
    },
    {
        "col1": "Еврейская автономная область",
        "col2": 1,
        "col3": "Департамент социальной защиты населения правительства Еврейской автономной области",
        "col4": "679000, Еврейская автономная область, г. Биробиджан, проспект 60 лет СССР, д. 12А, sobes@post.eao.ru, 8 (42622) 2 22 19"
    },
    {
        "col1": "Забайкальский край",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты населения Забайкальского края",
        "col4": "672000, Забайкальский край, г. Чита, ул. Курнатовского, д. 7,  pochta@minsz.e-zab.ru (только для служебной корреспонденции), 8(3022) 35 50 85 приемная"
    },
    {
        "col1": "Ивановская область",
        "col2": 1,
        "col3": "Администрация города Иванова",
        "col4": "153000, г. Иваново, \nпл. Революции, д. 6, \nтел. 8 (4932) 59-46-48, office@ivgoradm.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 2,
        "col3": "Администрация городского округа Вичуга",
        "col4": "155331, Ивановская область, \nг. Вичуга, ул. 50 лет Октября, \nд. 15,\nтел. 8 (49354) 2-10-11, \nadminvich@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 3,
        "col3": "Администрация городского округа Кинешма",
        "col4": "155800, г. Кинешма, \nул. им. Фрунзе, д. 4\nтел. 8 (49331) 5-30-50,\nmail@admkineshma.ru, admkineshma@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 4,
        "col3": "Администрация городского округа Кохма",
        "col4": "153510, Ивановская область, \nг. Кохма, ул. Советская, д. 23,\nтел. 8 (4932) 55-20-16, \nadm@kohma37.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 5,
        "col3": "Администрация городского округа Тейково",
        "col4": "155040, Ивановская область,\nг. Тейково, пл. Ленина, д. 4, \nтел. 8 (49343) 4-02-01,\nadmin_tei@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 6,
        "col3": "Администрация городского округа Шуя",
        "col4": "155900, Ивановская область, \nг. Шуя, ул. Советская, д. 48,\nтел. 8 (49351) 4-12-05, \nokrugshuya@ivreg.ru, admin@okrugshuya.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 7,
        "col3": "Администрация Верхнеландеховского муниципального района",
        "col4": "155210, Ивановская область, \nп. Верхний Ландех, \nул. Первомайская, д. 3,\nтел. 8 (49349) 2-14-22,\ninfo@vlandeh-admin.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 8,
        "col3": "Администрация Вичугского муниципального района",
        "col4": "155331, Ивановская область,\nг. Вичуга, переулок Широкий, д. 4, \nтел. 8 (49354) 2-44-54,\nadmvmr@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 9,
        "col3": "Администрация Гаврилово-Посадского муниципального района",
        "col4": "155000, Ивановская область,\nг. Гаврилов Посад, ул. Розы Люксембург, д. 3,\nтел. 8 (49355) 2-12-60,\nadm_gp37@ivreg.ru\n"
    },
    {
        "col1": "Ивановская область",
        "col2": 10,
        "col3": "Администрация Заволжского муниципального района",
        "col4": "155410, Ивановская область, г. Заволжск, ул. Мира, д. 7,\nтел. 8 (49333) 6-00-49,\n37zavadm@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 11,
        "col3": "Администрация Ивановского муниципального района",
        "col4": "153008, г. Иваново, ул. Постышева, д. 46,\nтел. 8 (4932) 30-03-96,\nadm.ivrn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 12,
        "col3": "Администрация Ильинского муниципального района",
        "col4": "155060, Ивановская область, \nп. Ильинское-Хованское, \nул. Советская, д. 2,\nтел. 8 (49353) 2-12-04,\nilin@admilinskoe.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 13,
        "col3": "Администрация Кинешемского муниципального района",
        "col4": "155800, г. Кинешма, ул. им. Ленина, д. 12,\nтел. 8 (49331) 5-51-05,\nroot@mrkineshma.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 14,
        "col3": "Администрация Комсомольского муниципального района",
        "col4": "155150, Ивановская область, \nг. Комсомольск, ул. 50 лет ВЛКСМ, д. 2,\nтел. 8 (49352) 2-11-78,\nadmin.komsomolsk@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 15,
        "col3": "Администрация Лежневского муниципального района",
        "col4": "155120, Ивановская область, \nп. Лежнево, \nул. Октябрьская, д. 32, \nтел. 8 (49357) 2-12-04, \ninfo@lezhnevo.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 16,
        "col3": "Администрация Лухского муниципального района",
        "col4": "155270, п. Лух, \nул. Октябрьская, д. 4\nтел. 8 (49344) 2-13-45,\ninfo.luh@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 17,
        "col3": "Администрация Палехского муниципального района",
        "col4": "155620, Ивановская область, \nп. Палех, \nул. Ленина, д. 1,\nтел. 8 (49334) 2-14-42,\npaleh@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 18,
        "col3": "Администрация Пестяковского муниципального района",
        "col4": "155650 Ивановская область, пос. Пестяки, ул. Ленина,\n д. 4,\nтел. 8 (49346) 2-10-15,\npestyaki@pestyaki.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 19,
        "col3": "Администрация Приволжского муниципального района",
        "col4": "155550, Ивановская область, \nг. Приволжск, пер. Коминтерновский, \nд. 2,\nтел. 8 (49339) 2-19-71,\nreception@privadmin.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 20,
        "col3": "Администрация Пучежского муниципального района",
        "col4": "155360, Ивановская область, \nг. Пучеж, \nул. Ленина, д. 27,\nтел. 8 (49345) 2-12-01,\npuchezh@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 21,
        "col3": "Администрация Родниковского муниципального района",
        "col4": "155250, Ивановская область, \nг. Родники, ул. Советская, \nд. 8,\nтел. 8 (49336) 2-17-57,\npost@rodniki-37.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 22,
        "col3": "Администрация Савинского муниципального района",
        "col4": "155710, Ивановская область, \nп. Савино, ул. Первомайская, \nд. 22,\nтел. 8 (49356) 9-12-04,\nadm-savino@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 23,
        "col3": "Администрация Тейковского муниципального района",
        "col4": "155040, Ивановская область, \nг. Тейково, ул. Октябрьская, \nд. 2А,\nтел. 8 (49343) 2-26-05,\npriem.teikovo.raion@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 24,
        "col3": "Администрация Фурмановского муниципального района",
        "col4": "155520, г. Фурманов, \nул. Социалистическая, д. 15,\nтел. 8 (49341) 2-17-66,\nfurmanov@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 25,
        "col3": "Администрация Шуйского муниципального района",
        "col4": "155900, Ивановская область, \nг. Шуя, \nпл. Ленина, д. 7,\nтел. 8 (49351) 3-26-34,\nadm-shr@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 26,
        "col3": "Администрация Южского муниципального района",
        "col4": "155630, Ивановская область, \nг. Южа, \nул. Пушкина, д. 1, \nтел. 8 (49347) 2-12-04,\nyuzhaadm@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 27,
        "col3": "Администрация Юрьевецкого муниципального района",
        "col4": "155453, Ивановская область, \nг. Юрьевец, ул. Советская, \nд. 37,\nтел. 8 (49337) 2-16-03,\nyurevets@ivreg.ru"
    },
    {
        "col1": "Иркутская область",
        "col2": 1,
        "col3": "Администрация Ангарского городского округа ",
        "col4": "Площадь Ленина 63-й квартал, д.2,г. Ангарск, Иркутская область,  665830;   Адрес электронной почты: ago@mail.angarsk-adm.ru , (39555) 22-4-04"
    },
    {
        "col1": "Иркутская область",
        "col2": 2,
        "col3": "Администрация Аларского района",
        "col4": "Советская улица, 49, пос. Кутулик,       Аларский район, Иркутская область, 669452, Адрес электронной почты: alaruprav@bk.ru, (39564) 37-1-37"
    },
    {
        "col1": "Иркутская область",
        "col2": 3,
        "col3": "Администрация Балаганского района\n",
        "col4": "666391, Иркутская область, Балаганский район, р.п. Балаганск, ул. Ангарская, 91, Адрес электронной почты: balagansk_admin@irmail.ru \n(39548) 50-1-75"
    },
    {
        "col1": "Иркутская область",
        "col2": 4,
        "col3": "Администрация Баяндаевского района \n",
        "col4": "669120, Иркутская область, с. Баяндай, ул. Бутунаева, д. 2; baynadm@yandex.ru \n(39537) 91-2-40"
    },
    {
        "col1": "Иркутская область",
        "col2": 5,
        "col3": "Администрация мо г. Бодайбо и Бодайбинского района \n",
        "col4": "666904, г. Бодайбо, ул. Урицкого, д. 33, bodaibo_mer@irmail.ru \n(39561) 51-0-55."
    },
    {
        "col1": "Иркутская область",
        "col2": 6,
        "col3": "Администрация муниципального образования Боханский район  ",
        "col4": "669311, Иркутская область, п. Бохан, ул. Ленина, д. 83; bohan_raion@mail.ru \n(39538) 25-1-72\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 7,
        "col3": "Администрация г. Братска ",
        "col4": " проспект Ленина, д. 37, 9-й \"А\" м-н, Центральный район, Братск, Иркутская область, 66570, adm@bratsk-city.ru \n(3953) 34-90-10\nканц: 34-91-06\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 8,
        "col3": "Администрация Братского района  \n",
        "col4": "665717,  г. Братск, Комсомольская улица, 29; amobraion@mail.ru \n(3953) 41-21-70"
    },
    {
        "col1": "Иркутская область",
        "col2": 9,
        "col3": "Администрация Жигаловского мо \n",
        "col4": "666402, Иркутская область, р.п. Жигалово, ул. Партизанская, 74; secretar@irmail.ru \n(39551) 32-1-69"
    },
    {
        "col1": "Иркутская область",
        "col2": 10,
        "col3": "Администрация Заларинского района ",
        "col4": "666322, Иркутская область, Заларинский район, посёлок Залари, улица Ленина, дом 103; zaladmin@irmail.ru \n(39552) 21-1-05"
    },
    {
        "col1": "Иркутская область",
        "col2": 11,
        "col3": "Администрация города Зимы ",
        "col4": "665390, Иркутская область, г. Зима, ул. Ленина, д. 5. mail@zimadm.ru \n(39554) 31-7-85.\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 12,
        "col3": "Администрация Зиминского района \n",
        "col4": "665390, Иркутская область, г. Зима, ул. Ленина, д. 5; admirzima@irmail.ru \n(39554) 31-3-54."
    },
    {
        "col1": "Иркутская область",
        "col2": 13,
        "col3": "Администрации города Иркутска \n",
        "col4": "664025, Иркутская область, г. Иркутск, ул. Ленина, д. 14; post@admirk.ru \n(3952) 52-00-25\nканц: 52-03-10"
    },
    {
        "col1": "Иркутская область",
        "col2": 14,
        "col3": "Администрация Иркутского района \n",
        "col4": "664511, Иркутская область, Иркутский район, с. Пивовариха, ул. Дачная, стр. 8; adm@irkraion.ru \n(3952) 71-80-80."
    },
    {
        "col1": "Иркутская область",
        "col2": 15,
        "col3": "Администрация Казачинско-Ленского района\n",
        "col4": "666511, Иркутская область, Казачинско-Ленский район, с. Казачинское, ул. Ленина, д. 10; adm-klr@yandex.ru \n(39562) 21-2-71"
    },
    {
        "col1": "Иркутская область",
        "col2": 16,
        "col3": "Администрация Катангского района \n",
        "col4": "666611, Иркутская область, Катангский район», село Ербогачен, ул.Комсомольская, д.6; admkat@yandex.ru \n(39560) 21-1-50"
    },
    {
        "col1": "Иркутская область",
        "col2": 17,
        "col3": "Администрация мо Качугского района \n",
        "col4": "666203 Иркутская область, п. Качуг, ул. Ленских Событий, 29 e-mail: kachugraion@govirk.ru      \nТел.: 8 (39540) 31-2-32"
    },
    {
        "col1": "Иркутская область",
        "col2": 18,
        "col3": "Администрации Киренского мо \n",
        "col4": "666703, Иркутская область, г. Киренск, ул. Красноармейская, д. 5; kirenskadm@yandex.ru \n(39568) 43-8-87"
    },
    {
        "col1": "Иркутская область",
        "col2": 19,
        "col3": "Администрация Куйтунского района  ",
        "col4": "665302, Иркутская область, Куйтунский район, р.п. Куйтун, ул. Карла Маркса, д. 18; kuitmer@mail.ru \n(39536) 52-2-95 (внутр.почта 1038)\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 20,
        "col3": "Администрации Мамско-Чуйского района\n",
        "col4": " 666811, Иркутская область, Мамско-Чуйский район, п. Мама, ул. Советская, д. 10; adm_mo_mama@mail.ru \n(39569) 21-8-85"
    },
    {
        "col1": "Иркутская область",
        "col2": 21,
        "col3": "Администрация Нижнеилимского Муниципального района \n",
        "col4": "665653, Иркутская область, Нижнеилимский район, город Железногорск-Илимский, кв-л 8, д.20; nilim-zeleznogorsk@yandex.ru \n(39566) 30-6-06 / 32-4-11 (канцелярия)"
    },
    {
        "col1": "Иркутская область",
        "col2": 22,
        "col3": "Администрация Нижнеудинского района  \n",
        "col4": "665106, г. Нижнеудинск, ул. Октябрьская, д. 1; тел. 8(39557)7-05-64, факс 8(39557)7-05-04,\ne-mail: nuradm@rambler.ru"
    },
    {
        "col1": "Иркутская область",
        "col2": 23,
        "col3": "Администрация Нукутского района \n",
        "col4": "ул. Ленина, 26, пос. Новонукутский, Нукутский район, Иркутская область, 669401; nukuti_econ@mail.ru  \n(39549) 21-1-46"
    },
    {
        "col1": "Иркутская область",
        "col2": 24,
        "col3": "Администрация Ольхонского районного мо",
        "col4": " 666130, Иркутская обл., Ольхонский район, с.Еланцы, ул.Пенкальского,14; 8 (3952) 436231, 8 (39558) 52-302, olkhon@mail.ru"
    },
    {
        "col1": "Иркутская область",
        "col2": 25,
        "col3": "Администрации Осинского муниципального района \n",
        "col4": "669200, Иркутская область, Осинский район, село Оса, ул. Свердлова, д. 59; osaadm@mail.ru \n(39539) 31-6-09."
    },
    {
        "col1": "Иркутская область",
        "col2": 26,
        "col3": "Администрации г. Саянска \n",
        "col4": "666304, Иркутская Область, г. Саянск, мкр. Олимпийский, д.30; admsayansk@irmail.ru \n(39553) 57-1-21\nканц: 56-89-1"
    },
    {
        "col1": "Иркутская область",
        "col2": 27,
        "col3": "Администрация города Свирска \n",
        "col4": "665420, Иркутская область, г. Свирск, ул. Ленина, д. 33; admin@svirsk.ru \n(39573) 22-9-75"
    },
    {
        "col1": "Иркутская область",
        "col2": 28,
        "col3": "Администрация Слюдянского района ",
        "col4": "665904, Иркутская область, м. р-н Слюдянский, г. п. Слюдянское, г. Слюдянка, ул. Ржанова, д. 2; referent@sludyanka.ru \n(39544) 51-2-00\nканц: доб. 112.\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 29,
        "col3": "Администрация Тайшетского района ",
        "col4": "665006, Иркутская область, г. Тайшет, ул. Октябрьская, д. 86/1; \nglava@inbox.ru (3956) 33-99-77.\n30. Администрация г. Тулуна - 665268, Иркутская область, г. Тулун, ул. Ленина, д. 99; tulun-mer@irmail.ru,  (39530) 21-6-00\n"
    },
    {
        "col1": "Иркутская область",
        "col2": 30,
        "col3": "Администрация мо Тулунский район ",
        "col4": "665 268 Иркутская область, г. Тулун, ул. Ленина, 75; tulunraion@govirk.ru, (39530) 40-9-25"
    },
    {
        "col1": "Иркутская область",
        "col2": 31,
        "col3": "Администрация Усольского района ",
        "col4": "665479 Иркутская область, Усольский муниципальный район, Белореченское муниципальное образование, рп.Белореченский, здание 100; usoleraion@govirk.ru (39543) 21-2-10"
    },
    {
        "col1": "Иркутская область",
        "col2": 32,
        "col3": "Администрации г. Усолье-Сибирское ",
        "col4": "665452, Иркутская область, г. Усолье-Сибирское, ул. Ватутина, д.10, admin-usolie@usolie-sibirskoe.ru, (39543) 63-3-40"
    },
    {
        "col1": "Иркутская область",
        "col2": 33,
        "col3": "Администрация города Усть-Илимска\n\n",
        "col4": "666683, Иркутская область, г. Усть-Илимск, ул. Героев Труда, 38\n8 (39535) 98225 office@ust-ilimsk.ru."
    },
    {
        "col1": "Иркутская область",
        "col2": 34,
        "col3": "Администрация мо «Усть-Илимский район» \n",
        "col4": "666671, Иркутская область, г. Усть-Илимск, ул. Комсомольская, 9, mail@ui-raion.ru (39535) 75-5-04.\n36. Администрация Усть-Кутского района: Иркутская область, г. Усть-Кут, ул. Халтурина, 52, ukmo@irmail.ru, (39565) 57-4-97"
    },
    {
        "col1": "Иркутская область",
        "col2": 35,
        "col3": "Администрация Усть-Удинского района ",
        "col4": "666352, Иркутская область, Усть-Удинский район, поселок Усть-Уда, Комсомольская улица, дом 19, ustuda_MO@bk.ru (39545) 31-3-75."
    },
    {
        "col1": "Иркутская область",
        "col2": 36,
        "col3": "Администрация г. Черемхово \n",
        "col4": "665415, Ференца Патаки, 6,                                г .Черемхово, Иркутская область, inform@admcher.ru (39546) 52-5-33\n(39546) 50-0-29 (канцелярия)."
    },
    {
        "col1": "Иркутская область",
        "col2": 37,
        "col3": "Администрация Черемховского районного муниципального образования\n",
        "col4": " 665429, Иркутская область, Черемховский район, с. Рысево, ул. Российская, д. 5, orgotdel-cher-raion@mail.ru \n(39537) 53-0-21."
    },
    {
        "col1": "Иркутская область",
        "col2": 38,
        "col3": "Администрация муниципального района “Чунский район” Иркутской области ",
        "col4": "Чунский район, р.п.Чунский, ул.Комарова, д. 11,  chunameria@mail.ru, (39567) 21-2-25"
    },
    {
        "col1": "Иркутская область",
        "col2": 39,
        "col3": "Администрация Шелеховского муниципального образования\n",
        "col4": "666034, г. Шелехов, ул. Ленина, 15, adm@sheladm.ru (39550) 41-3-35\nКанц: 4-18-54."
    },
    {
        "col1": "Иркутская область",
        "col2": 40,
        "col3": "Администрации Эхирит-Булагатского района \n",
        "col4": "669001, Иркутская область, п. Усть-Ордынский, ул. Балтахинова, д. 20, ehiritmo@irmail.ru (39541 3-12-70\nканц: 3-11-61."
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 1,
        "col3": "Министерство просвещения и науки Кабардино-Балкарской Республики",
        "col4": "Кабардино-Балкарская Республика, г. Нальчик,  ул. Кешокова, д. 43, 360051/minobrsc@kbr.ru, soczachita07@mail.ru/8(8662) 42-03-98"
    },
    {
        "col1": "Калининградская область",
        "col2": 1,
        "col3": "Министерство социальной политики Калининградской области",
        "col4": "236016, Калининградская обл., г. Калининград, ул. Клиническая, д. 63, social@gov39.ru, 8 (4012) 599-665, 599-699"
    },
    {
        "col1": "Калужская область",
        "col2": 1,
        "col3": "Управление Жилищно-коммунального хозяйства города Калуги ",
        "col4": "248001, ул. Достоевского, д. 49 А, г. Калуга, uzkh_kaluga@adm.kaluga.ru, +7 (4842) 70-11-43\n+7 (4842) 56-58-66\n "
    },
    {
        "col1": "Калужская область",
        "col2": 2,
        "col3": " Глава администрации муниципального района \"Бабынинский район\" ",
        "col4": "249210, Калужская область, Бабынинский район, поселок Бабынино, Новая ул., д.4,  ababyn@adm.kaluga.ru, +7(48448) 2-10-31\n+7 (48448) 2-17-29\n+7 (48448) 2-17-34\n"
    },
    {
        "col1": "Калужская область",
        "col2": 3,
        "col3": "Главе администрации муниципального района «Барятинский район»",
        "col4": "249650, Калужская область, Барятинский район, село Барятино, ул. Советская, д. 20, abaryat@adm.kaluga.ru, \n+7 (48454) 2-42-35\n+7 (48454) 2-42-42"
    },
    {
        "col1": "Калужская область",
        "col2": 4,
        "col3": "Глава администрации муниципального района \"Боровский район\" ",
        "col4": "249010, Калужская область, Боровский район, город Боровск, Советская ул., д. 4,  aborovsk@adm.kaluga.ru, +7 (48438) 4-11-44\n+7 (48438) 4-18-44\n+7 (48438) 4-12-44"
    },
    {
        "col1": "Калужская область",
        "col2": 5,
        "col3": "Глава администрации муниципального района \"Дзержинский район\"",
        "col4": "249832, Калужская область, Дзержинский район, город Кондрово, Центральная площадь, 1, adzerg@adm.kaluga.ru, +7 (48434) 3-32-14."
    },
    {
        "col1": "Калужская область",
        "col2": 6,
        "col3": " Глава администрации муниципального района \"Думиничский район\"",
        "col4": "249300, Калужская область, Думиничский район, поселок Думиничи, ул. Ленина, д.26, bulygin_sg@adm.kaluga.ru, \n+7 (48447) 9-16-05\n+7 (48447) 9-73-50"
    },
    {
        "col1": "Калужская область",
        "col2": 7,
        "col3": "Глава администрации муниципального района \"Жиздринский район\"",
        "col4": "249340, Калужская область, г. Жиздра, ул. Кустарева, д. 1/2, azhizdr@adm.kaluga.ru, +7 (48445) 2-12-91\n+7 (48445) 2-21-55\n+7 (48445) 2-15-66"
    },
    {
        "col1": "Калужская область",
        "col2": 8,
        "col3": "Глава администрации муниципального района \"Жуковский район\"",
        "col4": "249191, Калужская область, Жуковский район, город Жуков, ул Гурьянова, д. 31,  azhuk_org@adm.kaluga.ru, \n+7 (48432) 5-61-75"
    },
    {
        "col1": "Калужская область",
        "col2": 9,
        "col3": "Глава администрации муниципального района \"Износковский район\"",
        "col4": "249880, Калужская область, Износковский район, с. Износки, ул. Ленина, д.27, aiznosk@adm.kaluga.ru, +7 (48449) 4-55-63\n+7 (48449) 4-54-32\n+7 (48449) 4-54-05"
    },
    {
        "col1": "Калужская область",
        "col2": 10,
        "col3": "Глава Кировской районной администрации муниципального района \"Город Киров и Кировский район\"",
        "col4": "249440, Калужская область, г. Киров, ул. Пролетарская, д. 36, akirov@adm.kaluga.ru, +7 (48456) 5-22-11 "
    },
    {
        "col1": "Калужская область",
        "col2": 11,
        "col3": "Глава администрации муниципального района \"Козельский район\"",
        "col4": "249722, Калужская область, г. Козельск, ул. Большая Советская, д. 53, akozelsk@adm.kaluga.ru, +7 (48442) 2-24-26"
    },
    {
        "col1": "Калужская область",
        "col2": 12,
        "col3": "Глава администрации муниципального района \"Куйбышевский район\"",
        "col4": "249500, Калужская область, Куйбышевский район, п. Бетлица, ул. Ленина, д. 28,  akuibysh@adm.kaluga.ru, +7 (48457) 2-13-35\n+7 (48457) 2-16-66\n+7 (48457) 2-18-52"
    },
    {
        "col1": "Калужская область",
        "col2": 13,
        "col3": " Глава администрации муниципального района \"Город Людиново и Людиновский район\"",
        "col4": "249400, Калужская область, г. Людиново ул. Ленина, д. 20, perevalov@adm.kaluga.ru, +7 (48444) 6-28-61"
    },
    {
        "col1": "Калужская область",
        "col2": 14,
        "col3": "Глава Малоярославецкой районной администрации муниципального района \"Малоярославецкий район\"",
        "col4": "249096, г. Малоярославец, пл. Ленина, д. 1, amaloyar@adm.kaluga.ru, +7 (48431) 3-01-60\n+7 (48431) 2-14-66"
    },
    {
        "col1": "Калужская область",
        "col2": 15,
        "col3": "Глава администрации муниципального района \"Медынский район\" ",
        "col4": "249950, Калужская область, г. Медынь, ул. Луначарского, д. 47,  amedyn@adm.kaluga.ru, +7 (48433) 2-13-17 "
    },
    {
        "col1": "Калужская область",
        "col2": 16,
        "col3": "Глава администрации муниципального района \"Мещовский район\"",
        "col4": "249240, Калужская область, Мещовский район, город Мещовск, пр-кт Революции, д.55,  amesovsk@adm.kaluga.ru, +7 (48446) 9-23-59"
    },
    {
        "col1": "Калужская область",
        "col2": 17,
        "col3": "Глава администрации муниципального района \"Мосальский район\"",
        "col4": "249930, Калужская область, г. Мосальск, ул. Советская, д. 16, mosalskadm@rambler.ru и amosal@adm.kaluga.ru, +7 (48452) 2-15-24\n+7 (48452) 2-14-73\n+7 (48452) 2-10-66"
    },
    {
        "col1": "Калужская область",
        "col2": 18,
        "col3": "Глава Администрации города Обнинска",
        "col4": "249037, Калужская область, г. Обнинск, пл. Преображения, д.1,  aobninsk@adm.kaluga.ru, +7 (484) 395-83-10\n+7 (484) 395-80-80\n+7 (484) 395-82-00"
    },
    {
        "col1": "Калужская область",
        "col2": 19,
        "col3": "Глава администрации муниципального района \"Перемышльский район\"",
        "col4": "249130, Калужская область, с. Перемышль, пл. Свободы, д. 4,  aperemyshl@adm.kaluga.ru, +7 (48441) 3-15-36\n+7 (48441) 3-17-66"
    },
    {
        "col1": "Калужская область",
        "col2": 20,
        "col3": "Главы администрации муниципального района \"Спас-Деменский район\"",
        "col4": "249610, Калужская область, Спас-Деменский район, город Спас-Деменск, Советская ул., д.99, aspdem@adm.kaluga.ru, +7 (48455) 2-25-30\n+7 (48455) 2-18-88\n+7 (48455) 2-14-40"
    },
    {
        "col1": "Калужская область",
        "col2": 21,
        "col3": "Глава администрации муниципального района \"Сухиничский район\"",
        "col4": "249270, Калужская область, Сухиничский р-н, г. Сухиничи, ул. Ленина, д.56а,  asuhin@adm.kaluga.ru, +7 (48451) 5-31-87\n+7 (48451) 5-32-39"
    },
    {
        "col1": "Калужская область",
        "col2": 22,
        "col3": "Глава администрации муниципального района \"Тарусский район\"",
        "col4": "249100, Калужская область, г. Таруса, пл. Ленина, д. 3,  tarusa@adm.kaluga.ru, +7 (48435) 2-51-30\n+7 (48435) 2-54-37\n+7 (48435) 2-54-31"
    },
    {
        "col1": "Калужская область",
        "col2": 23,
        "col3": "Глава администрации муниципального района \"Ульяновский район\"",
        "col4": "249750, Калужская область, Ульяновский район, село Ульяново, ул. Большая Советская, д. 93,  aulian@adm.kaluga.ru, +7 (48443) 2-18-02\n+7 (48443) 2-15-12\n+7 (48443) 2-16-90"
    },
    {
        "col1": "Калужская область",
        "col2": 24,
        "col3": "Глава администрации муниципального района \"Ферзиковский район\"",
        "col4": "249800, Калужская область, Ферзиковский район, поселок Ферзиково, ул. Карпова, д.25,  aferzik@adm.kaluga.ru, \n+7 (48437) 3-11-41"
    },
    {
        "col1": "Калужская область",
        "col2": 25,
        "col3": "глава администрации муниципального района \"Хвастовичский район",
        "col4": "249360, Калужская область, Хвастовичский район, село Хвастовичи, ул. Ленина, д. 23,  ahvast@adm.kaluga.ru, +7 (48453) 9-14-30\n+7 (48453) 9-17-38\n+7 (48453) 9-13-53"
    },
    {
        "col1": "Калужская область",
        "col2": 26,
        "col3": "глава администрации муниципального района \"Юхновский район\"",
        "col4": "249910, Калужская область, Юхновский район, город Юхнов, ул. К. Маркса, д. 6,  ayuhn@adm.kaluga.ru, +7 (48436) 2-18-00\n+7 (48436) 2-12-36\n+7 (48436) 2-13-39"
    },
    {
        "col1": "Камчатский край",
        "col2": 1,
        "col3": "Министерство социального благополучия и семейной политики Камчатского края",
        "col4": "683003, Камчатский край,\nг. Петропавловск-Камчатский,\nул. Ленинградская 118,\nтел. +7 (4152) 42-83-55\nЭл. почта: minsrt@kamgov.ru"
    },
    {
        "col1": "Карачаево - Черкесская Республика",
        "col2": 1,
        "col3": "Министерство труда и социального развития Карачаево-Черкесской Республики",
        "col4": "369000, КЧР, г.Черкесск, ул. Комсомольская, 23,\nmtisr@mail.ru   8 878 226 67 48"
    },
    {
        "col1": "Кемеровская область",
        "col2": 1,
        "col3": "Министерство образования Кузбасса",
        "col4": "650064 г.Кемерово, проспект Советский, д.58, deti@ruobr.ru, 8-3842-36-27-78, 8-961-703-64-84"
    },
    {
        "col1": "Кировская область",
        "col2": 1,
        "col3": "Администрация Арбажского муниципального округа",
        "col4": "ул. Пионерская, д. 2, пгт Арбаж, Кировская область, 612180, (83330) 2-12-32, admarbazh@mail.ru; (83330) 2-15-57, opeka.arbazh@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 2,
        "col3": "Администрации Афанасьевского муниципального округа Кировской области",
        "col4": "613060 Кировская область, Афанасьевский район, пгт Афанасьево, ул.Красных Партизан, д.5, admafan@kirovreg.ru 883331 2-19-51"
    },
    {
        "col1": "Кировская область",
        "col2": 3,
        "col3": "Администрация Белохолуницкого муниципального района",
        "col4": "613200 Кировская область г.Белая Холуница ул.Глазырина, д.6/bh_opeka@mail.ru/88336442427"
    },
    {
        "col1": "Кировская область",
        "col2": 4,
        "col3": "Администрация Богородского муниципального округа",
        "col4": "612470, Кировская область, Богородский район, пгт Богородское, ул. Советская, д. 43, 883321251, admbogo@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 5,
        "col3": "Администрация Верхнекамского муниципального округа",
        "col4": "612820 Кировская область, г. Кирс, ул. Кирова, д. 16 883339 2-33-72 imushavr@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 6,
        "col3": "Администрация Верхошижемского муниципального образования",
        "col4": "613310, Кировская область, пгт Верхошижемье, ул. Комсомольская, д. 2, 89123374172, opekashishma@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 7,
        "col3": "Администрация Вятскополянского муниципального района Кировской области",
        "col4": "612964, Кировская область, г.Вятские Поляны, ул.Гагарина, д.28, admvyatp@kirovreg.ru, 89005294510"
    },
    {
        "col1": "Кировская область",
        "col2": 8,
        "col3": "Администрация муниципального образования Даровской муниципальный район Кировскойобласти",
        "col4": "612140, Кировская область, Даровской район, пгт Даровской, ул. Набережная, д. 8, 88333621906, admdaro@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 9,
        "col3": "Администрация Зуевского района Кировской области",
        "col4": "612412, Кировская область г. Зуевка, ул. Опалева, д. 65, 8 (83337) 25461, azr@zrko.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 10,
        "col3": "Администрация Кикнурского муниципального округа",
        "col4": "612300, Кировская область, Кикнурский район, пгт Кикнур, ул. Советская, д. 36, kiknur-opeka@mail.ru, (83341)5-14-79"
    },
    {
        "col1": "Кировская область",
        "col2": 11,
        "col3": "Администрация Кильмезского муниципального района",
        "col4": "613570 Кировская область, пгт Кильмезь, ул.Советская, д.79, opekakilmez2017@yandex.ru (83338)22036"
    },
    {
        "col1": "Кировская область",
        "col2": 12,
        "col3": "Администрация Кирово-Чепецкого района",
        "col4": "613040 Кировская область, ул. Перовмайская, д. 6, 8(83361)49150, mailbox@admkchr.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 13,
        "col3": "Администрация Котельничского района ",
        "col4": "612600, Кировская область, г. Котельнич, ул. Карла Маркса, д. 16, kotelnich_rayon@mail.ru 8(83342)4-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 14,
        "col3": "Администрация Куменского района",
        "col4": "613400 Кировская область, Куменский район, ул. Кирова, д.11, 8 (83343)2-12-50, adm@kumensky.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 15,
        "col3": "Администрация Лебяжского муницильного округа",
        "col4": "613500, Кировская область, Лебяжский район, пгт Лебяжье, ул.Комсомольская, д.5, leb_opeka@bk.ru, 88334420942"
    },
    {
        "col1": "Кировская область",
        "col2": 16,
        "col3": "Администрация муниципального образования Лузский муниципальный округ Кировской области",
        "col4": "613980, Кировская область, г. Луза, ул. Ленина, д. 35, admluza@kirovreg.ru, 883346-5-18-04"
    },
    {
        "col1": "Кировская область",
        "col2": 17,
        "col3": "Администрация Малмыжского района",
        "col4": "612920, Кировская область, г. Малмыж, ул. Чернышевского, д. 2а opekamalm@yandex.ru (83347) 2-28-18"
    },
    {
        "col1": "Кировская область",
        "col2": 18,
        "col3": "Администрация Мурашинского муниципального округа",
        "col4": "613711, Кировская область, г. Мураши, ул. Карла Маркса, д. 28, opeka_murashi@mail.ru, 8(83348)2-28-86"
    },
    {
        "col1": "Кировская область",
        "col2": 19,
        "col3": "Администрация Нагорского района",
        "col4": "613260, Кировская область пгт. Нагорск ул. Леушина д. 21 (83349) 2-16-70 admnago@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 20,
        "col3": "Администрация Немского муниципального округа ",
        "col4": "613470 Кировская область ул. Советская д. 36 т. 8(83350)2-22-85. opeka.nema@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 21,
        "col3": "Администрация Нолинского района",
        "col4": "613440, Кировская область, г.Нолинск, ул.Спартака, д.36, opekanolinsk@mail.ru, 88336821180"
    },
    {
        "col1": "Кировская область",
        "col2": 22,
        "col3": "Администрация Омутнинского района",
        "col4": "612740, Кировская область, г. Омутнинск, ул. Комсомольская, д. 9, admomut@kirovreg.ru, 88335221251"
    },
    {
        "col1": "Кировская область",
        "col2": 23,
        "col3": "Управление образования администрации Опаринского МО",
        "col4": "613810, Кировская область, пгт. Опарино, ул. Первомайская, 14 opeka.oparino@yandex.ru (83353) 2-11-02"
    },
    {
        "col1": "Кировская область",
        "col2": 24,
        "col3": "Администрация Орловского района",
        "col4": "612270, Кировская обл, Г.Орлов, ул. Ст.Халтурина, д. 18 orlovopeka@mail.ru (83365) 2-16-45"
    },
    {
        "col1": "Кировская область",
        "col2": 25,
        "col3": "Администрация Пижанского муниципального округа ",
        "col4": "613380, Кировская область, Пижанский район, пгт. Пижанка, ул. Труда, 25 opp4325@yandex.ru 8(83355)22172"
    },
    {
        "col1": "Кировская область",
        "col2": 26,
        "col3": "Администрация Подосиновского района Кировской области",
        "col4": "613930, Кировская область, Подосиновский район, пгт Подосиновец,ул. Советская д. 77, podopeka@yandex.ru, 8(83351) 2-16-54"
    },
    {
        "col1": "Кировская область",
        "col2": 27,
        "col3": "Администрация Санчурского Муниципального округа",
        "col4": "Кировская область, Санчурский район, пгт. Санчурск, ул. Р. Люксембург, д.6А, 612370 тел. 883357 2-13-21 , admsanch@kiroveg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 28,
        "col3": "Администрация Свечинского муниципального округа",
        "col4": "612040, Кировская область, пгт Свеча, ул. Октябрьская, д.20, Тел: 8(83358)2-23-35, Эл.п.: sv_opeka@mail.ru, admsvec@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 29,
        "col3": "администрация Слободского муниципального района",
        "col4": "613150Кировская область г. Слободской ул. Советская д.86 тел 88336241252 admslob@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 30,
        "col3": "Администрация Советского муниципального района, ",
        "col4": "613340,Кировская область, Советский район, г. Советск, ул. Кирова,д.5 (83375)2"
    },
    {
        "col1": "Кировская область",
        "col2": 31,
        "col3": "Администрация Сунского муниципального района ",
        "col4": "612450, Кировская область, Сунский район, пгт Суна, ул.Большевиков, д.1, 8(83369)33694 sunaopeka@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 32,
        "col3": "Администрация Тужинского муниципального района",
        "col4": "612200, Кировская область, Тужинский район, пгт Тужа, ул. Горького, д. 5, ruotuzha43@yandex.ru 8(83340)2-16-81"
    },
    {
        "col1": "Кировская область",
        "col2": 33,
        "col3": "администрация Унинского муниципального округа",
        "col4": "612540 Кировская область, Унинский район, пгт.Уни, ул.Ленина, д.17 admunin@kirovreg.ru 8833592-14- 65"
    },
    {
        "col1": "Кировская область",
        "col2": 34,
        "col3": "Администрация Уржумского муниципального района",
        "col4": "  613530, г.Уржум, ул.Рокина, д. 13, 9536724559, ruo.opeka.@ mаil.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 35,
        "col3": "Администрация Фаленского муниципального округа",
        "col4": "612500, Кировская область, Фаленский район, пгт Фаленки, ул. Свободы,д 65, 8833322-11-30 kd81123@yandex.ru, 89536922268 opekafalenki@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 36,
        "col3": "Администрация Шабалинского муниципального района",
        "col4": "612020, ул. Советская, д.33, пгт Ленинское, Шабалинского района, Кировской области\nТелефон: (83345) 20158\nShabalino@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 37,
        "col3": "администрация Юрьянсокого района",
        "col4": "613600 Кировская область, Юрьянский район, пгт Юрья, ул. Ленина, 46, admjurja@kirovred.ru 883366 2-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 38,
        "col3": "Администрация Яранского района",
        "col4": "г.Яранск, ул.Кирова, д.10 612260, 883367-2-06-59 opeka-yaransk@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 39,
        "col3": "Администрация ЗАТО Первомайский",
        "col4": "613648,ЗАТО Первомайский ул. Волкова, 14 , admzatopervomaysk@mail.ru, 8(833266)2-42-31"
    },
    {
        "col1": "Кировская область",
        "col2": 40,
        "col3": "Администрация города Вятские Поляны",
        "col4": "612964, Кировская область, г. Вятские Поляны, ул. Гагарина 28а 8(83334) 61162 opekavp@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 41,
        "col3": "Администрация муниципального образования городской округ \"Город Кирово-Чепецк\" Кировской области",
        "col4": "613040, Кировская область, г. Кирово-Чепецк, ул. Первомайская, д. 6, 8(83361) 4-50-50, admcher@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 42,
        "col3": "Управление образования города Котельнича администрации городского округа города Котельнича",
        "col4": "\n\n612600, Кировская область, г. Котельнич, ул.Ленина, 26,               8 (83342) 4-15-72, yokotel@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 43,
        "col3": "Администрация города Слободского",
        "col4": "613150, г. Слободской, ул. Советская, д.86, тел. 8(8332) 25-53-49, slob-adm@rambler.ru."
    },
    {
        "col1": "Кировская область",
        "col2": 44,
        "col3": "Администрация города Кирова",
        "col4": "610000, Кировская область, г. Киров, ул. Воровского, д. 39, opeka@admkirov.ru, 8(8332)54-64-12, 54-00-42, 57-94-67, 54-09-68, 54-77-48"
    },
    {
        "col1": "Кировская область",
        "col2": 45,
        "col3": "Администрация Оричевского района",
        "col4": "612080, Кировская область, Оричевский район, пгт Оричи, ул. К. Маркса, д. 12 ( 883354)2-11-75, adm@orichi-rayon.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 1,
        "col3": "администрация Антроповского муниципального округа Костромской области",
        "col4": "157260, Костромская область, Антроповский район, п. Антропово, ул.Октябрьская, д. 12, (49430) 3-52-03, antropovo@ kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 2,
        "col3": "администрация Буйского муниципального района Костромской области ",
        "col4": "157000, Костромская область, пл.Революции, 13, (49435) 4-51-40, bui@ kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 3,
        "col3": "администрация Вохомского муниципального района Костромской области ",
        "col4": "157760, Костромская область, Вохомский район, п. Вохма, ул.Советская, 39, (49450) 2-13-34, vohma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 4,
        "col3": "администрация Галичского муниципального района Костромской области ",
        "col4": "157201, Костромская область, Галичский район, г. Галич, пл.Революции, 23а, (49437) 2-21-34, galich@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 5,
        "col3": "администрация Кадыйского муниципального округа Костромской области ",
        "col4": "157980, Костромская область, Кадыйский район, п.Кадый, ул.Центральная, 3, (49442) 3-40-08, kadiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 6,
        "col3": "администрация Кологривского муниципального округа Костромской области ",
        "col4": "157400, Костромская область, Кологривский район, г. Кологрив, ул. Набережная р.Киченки, 13, (49443)5-27-49, kologriv@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 7,
        "col3": "администрация Костромского муниципального района Костромской области ",
        "col4": "156961, Костромская область, г. Кострома, ул. М. Новикова, 7, (4942) 55-02-02, kosrn@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 8,
        "col3": "администрация Красносельского муниципального района Костромской области ",
        "col4": "157940, Костромская область, п. Красное-на-Волге, Красная пл., 15, (49432) 2-19-46, krasnoe@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 9,
        "col3": "администрация Макарьевского муниципального округа Костромской области ",
        "col4": "157460, Костромская область, Макарьевский район, г. Макарьев, пл. Революции, 8, (49445) 55-1-31 makarev@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 10,
        "col3": "администрация Мантуровского муниципального округа Костромской области ",
        "col4": "157300, Костромская область, Мантуровский район, г. Мантурово, ул.Центральная, д.5, (49446) 2-04-50 gorod_manturovo@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 11,
        "col3": "администрация Межевского муниципального округа Костромской области  ",
        "col4": "157420, Костромская область, Межевской район, с. Георгиевское, ул. Октябрьская, 39, (49447) 5-23-19 mezha@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 12,
        "col3": "администрация города Нерехта и Нерехтского муниципального района Костромской области                        ",
        "col4": "157800, Костромская область, Нерехтский район, г. Нерехта, ул.Победы, 1,  (49431) 7-62-03 nerehta@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 13,
        "col3": "администрация Нейского муниципального округа Костромской области ",
        "col4": "157330, Костромская область, Нейский район, г. Нея, ул.Соловьева, 6, (49444) 3-19-14 neya@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 14,
        "col3": "администрация Октябрьского муниципального округа Костромской области ",
        "col4": "157780, Костромская область, октябрьский район, с. Боговарово, ул. Победы, 37, (49451)2-11-86, oktyabrskiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 15,
        "col3": "администрация Островского муниципального округа Костромской области ",
        "col4": "157900, Костромская область, Островский район, п. Островское, ул. Советская, 56, (49438) 2-72-95, ostrovskiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 16,
        "col3": "администрация Павинского муниципального округа Костромской области ",
        "col4": "157650, Костромская область, Павинский район, с. Павино, ул.Октябрьская, 15, (49439) 2-11-63  pavino@ kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 17,
        "col3": "администрация Парфеньевского муниципального округа Костромской области",
        "col4": "157270, Костромская область, Парфеньевский район, с.Парфеньево, ул. Маркова, 17,  (49440) 2-41-52, parfenyevo@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 18,
        "col3": "администрация Поназыревского муниципального округа Костромской области ",
        "col4": "157580, Костромская область, Поназыревский район, п. Поназырево, ул. Свободы, 1, (49448)2-11-67, ponazyrevo@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 19,
        "col3": "администрация Пыщугского муниципального округа Костромской области ",
        "col4": "157630, Костромская область, Пыщугский район, с. Пыщуг, ул.Чкалова, 6, (49452) 2-77-81, pyshchug@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 20,
        "col3": "администрация Солигаличского муниципального округа Костромской области ",
        "col4": "157170, Костромская область, Солигаличский район, г. Солигалич, ул. Коммунистическая, 1, (49436)5-12-74 soligalich@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 21,
        "col3": "администрация Судиславского муниципального района Костромской области ",
        "col4": "157860, Костромская область, Судиславский район, п. Судиславль, ул. Советская, 2, (49433) 9-84-76 sudislavl@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 22,
        "col3": "администрация Сусанинского муниципального района Костромской области ",
        "col4": "157080, Костромская область, Сусанинский район, ул. Ленина, 2а, (49434) 9-03-33 susanino@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 23,
        "col3": "администрация Чухломского муниципального района",
        "col4": "157130, Костромская область, Чухломский район, г. Чухлома, пл.Революции, 11,  (49441) 2-12-13, chuhloma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 24,
        "col3": "администрация Шарьинского муниципального района Костромской области ",
        "col4": "157500, Костромская область, Шарьинский район, г. Шарья, ул. Октябрьская, 21, (49449) 5-03-50, sharya@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 25,
        "col3": "администрация городского округа город Кострома Костромской области ",
        "col4": "156000, Костромская область, г. Кострома, ул. Советская, 1, (4942) 31-39-32, 31-53-41, gorod_kostroma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 26,
        "col3": "администрация городского округа город Буй Костромской области",
        "col4": "157000, Костромская область, г. Буй, пл.Революции, 12,  (49435) 4-45-03, доб.118,   gorod_buy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 27,
        "col3": "администрация городского округа город Волгореченск Костромской области",
        "col4": "156901, Костромская область, г. Волгореченск, ул. Имени 50-летия Ленинского Комсомола, 4,  (49453) 5-25-02, gorod_volgorechensk@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 28,
        "col3": "администрация городского округа город Галич Костромской области ",
        "col4": "157201, Костромская область, г. Галич, пл. Революции, 23а, (49437) 2-17-20, gorod_galich@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 29,
        "col3": "администрация городского округа город Шарья Костромской области",
        "col4": "157500, Костромская область, г. Шарья, ул. Октябрьская, 21, (49449) 5-89-32 gorod_sharya@ kostroma.gov.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 1,
        "col3": "Управление Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека по Краснодарскому краю",
        "col4": "350000, г. Краснодар, ул. Рашпилевская, д. 100, +7 (861) 255-11-54, upravlenie@kubanrpn.ru"
    },
    {
        "col1": "Красноярский край",
        "col2": 1,
        "col3": "Министерство строительства и жилищно-коммунального хозяйства Красноярского края",
        "col4": "660075, Красноярский край, г.Красноярск, ул.Заводская, д.14\npriem@krasminstroy.ru, 8 (391) 211-05-39, 8 (391) 211-12-55"
    },
    {
        "col1": "Курганская область",
        "col2": 1,
        "col3": "Администрация города Кургана",
        "col4": "640000, г. Курган, пл.им. В.И. Ленина,1, тел. (3522) 42-88-01; mail@kurgan-city.ru ; 45t00902@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 2,
        "col3": "Администрация города Шадринска",
        "col4": "641884, Курганская область, г. Шадринск, ул. Свердлова, д.59, тел. (35253) 63212; public@shadrinsk-city.ru; 45t02602@kurganobl.ru 26"
    },
    {
        "col1": "Курганская область",
        "col2": 3,
        "col3": "Администрация Альменевского муниципального округа Курганской области ",
        "col4": "641130, Курганская область, с. Альменево, пл. Комсомола, 1, тел. (35242) 9-87-41; 45t00102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 4,
        "col3": "Администрация Белозерского муниципального округа Курганской области ",
        "col4": "641330, Курганская область, с.Белозерское, ул. К.Маркса, д.16, тел.(35232) 2-90-70; belozeradm@mail.ru; 45t00202@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 5,
        "col3": "Администрация Варгашинского муниципального округа Курганской области",
        "col4": "641230, область Курганская, район Варгашинский, р.п.Варгаши, улица Чкалова, д. 22, тел. (35233) 2-21-55;  45t00302@kurganobl.ru  "
    },
    {
        "col1": "Курганская область",
        "col2": 6,
        "col3": "Администрация Далматовского муниципального округа Курганской области",
        "col4": "641730,  Курганская обл., Далматовский р-он, г. Далматово, ул. Советская, 187, тел. (35252) 3-81-30; radm@dalmatovo.ru; 45t00402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 7,
        "col3": "Администрация  Звериноголовского муниципального округа Курганской области",
        "col4": "641480, Курганская область, с. Звериноголовское, ул. Чапаева, 41, тел. (35240) 2-15-05; 45t00502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 8,
        "col3": "Администрация Каргапольского муниципального округа Курганской области",
        "col4": "641920, Курганская область, р.п. Каргаполье, ул. Калинина, д.35, тел. (35256) 2-16-30;  45t00602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 9,
        "col3": "Администрация Катайского района муниципального округа Курганской области",
        "col4": "641700, Курганская область, г.Катайск, ул. Ленина, д.200, тел. (35251)21545; katadmin@mail.ru ; 45t00702@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 10,
        "col3": "Администрация Кетовского муниципального округа Курганской области",
        "col4": "641310, Курганская область, с.Кетово, ул. Космонавтов, д.39, тел. (35231) 23541; AdmKetr@mail.ru; 45t00802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 11,
        "col3": "Администрация Куртамышского муниципального округа Курганской области",
        "col4": "641430, Курганская область, г. Куртамыш, ул. XХII партсъезда, д.40, тел. (35249)2-18-10; kurtamysh-gorod@yandex.ru; 45t01002@kurganobl.ru 10"
    },
    {
        "col1": "Курганская область",
        "col2": 12,
        "col3": "Администрация Лебяжьевского муниципального округа Курганской области",
        "col4": "641500, Курганская область, р.п. Лебяжье, ул. Пушкина, д.14, тел. (35237) 9-08-78; 45t01102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 13,
        "col3": "Администрация Макушинского муниципального округа Курганской области",
        "col4": "641600, Курганская область, г. Макушино, ул. Ленина, д.85, тел. (35236) 2-06-42; 45t01202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 14,
        "col3": "Администрация Мишкинского муниципального округа Курганской области",
        "col4": "641040, Курганская область, р.п. Мишкино, ул. Ленина, д.30, тел. (35247)2-28-01; 45t01302@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 15,
        "col3": "Администрация Мокроусовского муниципального округа Курганской области",
        "col4": "641530, Курганская область, с. Мокроусово, ул. Советская, д.31, тел. (35234)9-77-41; admmokr@mail.ru; 45t01402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 16,
        "col3": "Администрация Петуховского муниципального округа Курганской области",
        "col4": "641640, Курганская область, г. Петухово, ул. К. Маркса, 27, тел. (35235) 3-84-98; admpr@bk.ru; 45t01502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 17,
        "col3": "Администрация Половинского муниципального округа Курганской области",
        "col4": "641780, Курганская область, с. Половинное, ул. Победы, д.9, тел.(35238) 9-15-33; 45t01602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 18,
        "col3": "Администрация Притобольного муниципального округа Курганской области",
        "col4": "641400, Курганская область, с. Глядянское, ул.Красноармейская, д.19, (3522) 42-89-80 доб. 224;  45t01702@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 19,
        "col3": "Администрация Сафакулевского муниципального округа Курганской области",
        "col4": "641080, Курганская область, с. Сафакулево, ул. Куйбышева, д.35; тел. (35243) 2-93-71; safakulevo@bk.ru; 45t01802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 20,
        "col3": "Администрация Целинного муниципального округа Курганской области",
        "col4": "641150, Курганская область, с. Целинное, ул. Советская, д.66, тел. (35241)2-10-43;  45t01902@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 21,
        "col3": "Администрация Частоозерского муниципального округа Курганской области ",
        "col4": "641570, Курганская область, с. Частоозерское, ул. Октябрьская, д.126, (3522) 42-84-45; 45t02002@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 22,
        "col3": "Администрация Шадринского муниципального округа Курганской области",
        "col4": "641870, Курганская область, г. Шадринск ул. Р. Люксембург, д.10, тел. (35253) 7-62-43; 45t02102@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 23,
        "col3": "Администрация Шатровского муниципального округа Курганской области",
        "col4": "641960, Курганская область, с. Шатрово, ул. Федосеева, д.53, тел. (35257) 9-13-98; 45t02202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 24,
        "col3": "Администрация Шумихинского муниципального округа Курганской области",
        "col4": "641100, Курганская область, г. Шумиха, ул. Кирова, д.12, тел. (35245)2-11-67; 45t02302@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 25,
        "col3": "Администрация Щучанского муниципального округа Курганской области",
        "col4": "641010 Курганская область, г. Щучье, ул. Победы, 1, тел. (35244) 3-69-67; 45t02402@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 26,
        "col3": "Администрация Юргамышского муниципального округа Курганской области",
        "col4": "641200 Курганская область, р.п. Юргамыш, ул. Ленина, 43, тел.(35248) 9-26-91; 45t02502@kurganobl.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 1,
        "col3": "Администрация Беловского района Курской области",
        "col4": "307910 Курская область, Беловский р-он, сл.Белая, Советская площадь, 1, тел.: 8(47149)2-11-40, e-mail: oop.belaya@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 2,
        "col3": "Администрация Большесолдатского района Курской области ",
        "col4": "305007, г. Курск, ул. Красная площадь, д.6, подъезд 3, каб. 247; тел.: 8(4712) 51-09-89, e-mail: petina6464@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 3,
        "col3": "Отдел опеки и попечительства Администрации Глушковского района Курской области \n",
        "col4": "305000 г. Курск, ул. Ендовищенская 8а, тел.:8(4712)44-62-20, e-mail:opeka-gl.307450@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 4,
        "col3": "Администрация Горшеченского района Курской области \n",
        "col4": "306800, Курская обл., Горшеченский р-н, рп. Горшечное, ул. Кирова, д. 18, тел. 8(47133)2-13-75, e-mail: oszn_gr@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 5,
        "col3": "Управление  образования, опеки и попечительства Администрации Дмитриевского района Курской области\n",
        "col4": "307500 г. Дмитриев, ул. Ленина, д. 79, тел. 8(47150) 2-23-06, e-mail: opeka_46@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 6,
        "col3": "Администрация Железногорского района Курской области \n",
        "col4": "301170, г Железногорск, ул. Ленина, д.52, каб. 124. тел., факс 8 (47148)3-50-48, e-mail: 35048@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 7,
        "col3": "Отдел образования, опеки и попечительства Администрации Золотухинского района Курской области \n",
        "col4": "306020 Золотухинский район, Курская область, Золотухинский район, п. Золотухино, ул. пер. Лесной, д. 5, тел.: 8(47151)2-13-68, e-mail:  opekazolotuhino@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 8,
        "col3": "Муниципальное образование «Касторенский муниципальный район Курской области» \n",
        "col4": "306700, Курская область, Касторенский район, п.Касторное, ул. 50 лет октября, д.6, тел.8(47157)2-19-52, e-mail: otdelpoopeke46@.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 9,
        "col3": "Администрация Конышевского района Курской области \n",
        "col4": "307620, Курская область, п. Конышевка, ул.Ленина, д.19, тел. 8(47156)2-13-55, e-mail:opeka4609@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 10,
        "col3": "Отдел опеки и попечительства управления по образованию, опеки и попечительству администрации Кореневского района \n",
        "col4": "305044, г. Курск, ул. Союзная, 29а,\nтел.: 8(4712) 34-28-98, e-mail: korenevo-opeka@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 11,
        "col3": "Администрация Курского района Курской области ",
        "col4": "305001, г. Курск, ул. Белинского, 21, тел. 8(4712)54-66-09, otdopeki.kurskrn@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 12,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 13,
        "col3": "Администрация Льговского района Курской области \n",
        "col4": "307750, Курская область, г. Льгов, ул. Красная Площадь, д. 4 б, тел.: 8(47140)2-13-31, opeka-lgov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 14,
        "col3": "Администрация Медвенского района Курской области \n",
        "col4": "307030, Курская область, пос.Медвенка, ул.Советская, д.20, тел.: 8(47146)4-19-76, e-mail: medwenka@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 15,
        "col3": "\nАдминистрация Мантуровского района Курской области \n",
        "col4": "307000, Курская область, Мантуровский район, с. Мантурово, ул. Ленина, д.13 тел.: 8(4715) 521286,\ne-mail: opeka@smanturovo.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 16,
        "col3": "Администрация Обоянского района Курской области\n \n",
        "col4": "306230 Курская область, г. Обоянь, ул. Шмидта, д.6, 8(47141)2-22–55, e-mail oboyan-opeka@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 17,
        "col3": "Администрация Октябрьского района Курской области \n",
        "col4": "307200, Курская область,Октябрьский район, п. Прямицыно, ул. Октябрьская, 134, тел.: 8(47142) 2-13-36, 2-13-07,2-11-57, 2-16-31, e-mail:opekaoct@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 18,
        "col3": "Администрация Пристенского района Курской области \n",
        "col4": "306200, Курская область, Пристенский район, п. Пристень, ул. Ленина, дом 5, тел.: 8(47134)2-10-51 факс  e-mail: opekapristen@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 19,
        "col3": "Отдел по опеке и попечительству Администрации Поныровского района Курской области \n\n",
        "col4": "306000, Курская область, Поныровский район, п. Поныри, ул. Ленина, д. 14, \nтел.: 8(47135)2-11-07, \ne-mail: ponyri.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 20,
        "col3": "Администрация Рыльского района Курской области \n",
        "col4": "307370, Курская область, г. Рыльск, ул. К. Либкнехта, д. 23А тел. 8(47152)2-19-98, e-mail: opeka.rylsk@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 21,
        "col3": "Отдел опеки и попечительства Администрации Советского района Курской области )\n",
        "col4": "306600 Курская область, Советский район, п. Кшенский, ул. Пролетарская, д.45, тел.: 8(4712) 2-22-32, факс 8(4712)2-22-32, e-mail: opeka.sovr@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 22,
        "col3": "Администрация Солнцевского района Курской области\n",
        "col4": " 306120, Курская область, Солнцевский район, п. Солнцево, ул. Ленина, д. 44, тел.: 8(47154 2-22-36, e-mail: adm4622@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 23,
        "col3": "Управление образования Администрации Суджанского района Курской области \n",
        "col4": "305004, г. Курск, ул. Садовая, д.31,3-этаж, каб.39, тел.: 8-951-339-92-30, e-mail: sudjanskruo23@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 24,
        "col3": "Отдел по опеке и попечительству Администрации Тимского района Курской области \n",
        "col4": "307060, Курская обл., Тимский район, п. Тим, ул. Кирова 51, тел.: 8(47153)2-37-39, e-mail:opeka.admtim@rkursk.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 25,
        "col3": "Отдел по опеке и попечительству Управления образования Администрации Фатежского района Курской области \n",
        "col4": "307100, Курская область, Фатежский район, г. Фатеж, ул. Урицкого, 45, тел.: 8(47144)2-13-90, e-mail:opekafafezh89@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 26,
        "col3": "Администрация Хомутовского района Курской области \n",
        "col4": "307540, Курская область, п. Хомутовка, ул. Память Ильича, д.85, тел: 8(471-37)2-15-06, e-mail: opeka.hom@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 27,
        "col3": "Отдел по опеке и попечительству Администрации Черемисиновского района Курского района\n",
        "col4": "306440, п. Черемисиново, ул. Советская, д. 4, тел.: 8(47159)2-10-75, e-mail: opeka.tcheremisinovo@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 28,
        "col3": "Администрация Щигровского района Курской области \n",
        "col4": "306530 Курская область, г. Щигры, ул. Октябрьская, д. 35; тел.: 8 (47145) 4-16-48, e-mail: shigri-opeca@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 29,
        "col3": "\nКомитет социальной защиты населения города Курска \n",
        "col4": "305007, г. Курск, ул. Пигорева, д. 2/17, тел.: (4712)70-70-35, e-mail: komsoz@kurskadmin.ru (почта отдела komitetsoc_det@mail.ru)"
    },
    {
        "col1": "Курская область\n",
        "col2": 30,
        "col3": "Администрация города Железногорска \n",
        "col4": "307170 Курская область, г. Железногорск, ул. Ленина, д. 52, тел. 8(47148)7-64-37, e-mail: zhel.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 31,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района Курской области \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, тел.: 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 32,
        "col3": "Администрация города Льгова Курской области\n\n",
        "col4": "307750 Курская область город Льгов, ул. Красная площадь, д.13\nТел. 8(74140)2-30-13, e-mail: shigri-opeca@mail.ru, admlov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 33,
        "col3": "Отдел по опеке и попечительству администрации города Щигры",
        "col4": " 306530, Курская область, г. Щигры, ул. Спортивная,1 8(47145)4-97-27, opeka-shigry@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 1,
        "col3": "администрация Бокситогорского муниципального района Ленинградской области",
        "col4": " адрес: 187650, Ленинградская область, г. Бокситогорск, ул. Социалистическая, д.9, телефон: 8(81366) 211-31,электронная почта: adm@boksitogorsk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 2,
        "col3": "комитет образования администрации Волосовского муниципального района Ленинградской области.",
        "col4": " Адрес: 188410, Ленинградская область, г. Волосово, ул. Краснофлотская, дом 6, телефон: 8(81373)22114, электронная почта:  volosovoopeka@inbox.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 3,
        "col3": "управление по опеке и попечительству администрации Волховского муниципального района Ленинградской области",
        "col4": " адрес: 187406 Ленинградская область, г. Волхов, пр. Державина, д. 60, телефон: 8(81363)72705, электронная почта: opekavolhov@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 4,
        "col3": "комитет по опеке и попечительству администрации Всеволожского муниципального района Ленинградской области",
        "col4": "адрес: 188640, Ленинградская область, г. Всеволожск, Всеволожский проспект д. 12, телефон:  8(81370)20020, 8(81370)31741, электронная почта: vsevopeka@mail.ru, people@vsevreg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 5,
        "col3": "управление опеки и попечительства администрации  муниципального образования \"Выборгский район\" Ленинградской области",
        "col4": "адрес:188800, Ленинградская область, \nг. Выборг, ул. Красноармейская д. 16, телефон: 8(81378)26353, \n 8(81378)20078, электронная почта: opeka.vbg@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 6,
        "col3": "администрация Гатчинского муниципального округа Ленинградской области",
        "col4": " адрес: 188300, Ленинградская область, г. Гатчина, ул. Карла Маркса, д.44, телефон: 8(81371)93100; 8 (81371) 95309, электронная почта: info@gmolo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 7,
        "col3": "отдел опеки и попечительства администрации МО Кингисеппский муниципальный район» Ленинградская область",
        "col4": " адрес: 188480, Ленинградская область, г. Кингисепп, проспект Карла Маркса, д.2а, телефон: 8(81375)4-88-40, электронная почта: adm@kingisepplo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 8,
        "col3": "администрация Киришского муниципального района Ленинградской области",
        "col4": " адрес: 187110, Ленинградская область, г. Кириши, улица Советская, дом 20, телефон: 8(81368) 609-00, электронная почта: admkir@admkir.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 9,
        "col3": "администрация Кировского муниципального района Ленинградской области",
        "col4": " адрес: 187342, Ленинградская область, Кировский район, г. Кировск, ул. Новая, д. 1, телефон: 8(813-62) 28-196, электронная почта: adm_kmr@kirovsk-reg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 10,
        "col3": "сектор опеки и попечительства отдела образования Администрации Лодейнопольского муниципального района Ленинградской области",
        "col4": "адрес: 187700, 187700, Ленинградская область, г. Лодейное Поле, пр. Ленина, д.16, телефон: 8(81364)39511, 8(81364)39822, lodpole-opeka@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 11,
        "col3": "отдел по опеке и попечительству администрации Ломоносовского муниципального района Ленинградской области",
        "col4": " адрес: 198412, г. Ломоносов, ул. Владимирская, дом 19/15, телефон: 8(81242)30030, электронная почта: lmn-reg@lomonosovlo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 12,
        "col3": "администрация Лужского муниципального района Ленинградской области",
        "col4": " адрес: 188230, Ленинградская область, г. Луга, пр. Кирова, д. 73, телефон: 8(81372) 2-23-06, Электронная почта: admin@adm.luga.ru , admluga@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 13,
        "col3": "администрация Подпорожского муниципального района Ленинградской области",
        "col4": " адрес: 187780, Ленинградская область, г. Подпорожье, пр. Ленина, д. 3, телефон: 8(81365)59003, электронная почта: reception@podadm.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 14,
        "col3": "администрация Приозерского муниципального района Ленинградской области",
        "col4": " адрес: 188760, Ленинградская область,  г. Приозерск, ул. Ленина, д.10, телефон: 8(81379)36973, Электронная почта: info@admpriozersk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 15,
        "col3": "администрация Сланцевского муниципального района Ленинградской области",
        "col4": " адрес: 188560, Ленинградская область, г. Сланцы, пер. Почтовый, д. 3, телефон: 8(81374)23273, Электронная почта: slanmo@slanmo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 16,
        "col3": "администрация Сосновоборского городского округа Ленинградской области",
        "col4": " адрес: 188540, Ленинградская область, г. Сосновый Бор, ул. Ленинградская, 46, телефон: 8(81369)26222, электронная почта: admsb@sbor.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 17,
        "col3": "комитет социальной защиты населения администрации Тихвинского района  (отдел опеки и попечительства)",
        "col4": " адрес: 187553, Ленинградская область, город Тихвин, 1 микрорайон, дом 2, телефон:  8(81367) 56250, 8(81367) 70294, электронная почта opeka.tikhvin@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 18,
        "col3": "комитет образования администрации Тосненского муниципального района Ленинградской области",
        "col4": "адрес: 187000, Ленинградская область,г. Тосно, ул. Советская, д. 10а, телефон: 8(81361)28305, электронная почта Opeka1960@yandex.ru"
    },
    {
        "col1": "Липецкая область",
        "col2": 1,
        "col3": "Министерство жилищно-коммунального хозяйства Липецкой области",
        "col4": "398001, г. Липецк,         \nул. Советская, д. 3\ne-mail: mhcs@admlr.lipetsk.ru\n +7(4742) 22-20-61"
    },
    {
        "col1": "Магаданская область",
        "col2": 1,
        "col3": "межведомственная комиссия по признанию помещения жилым помещением, жилого помещения непригодным для проживания и многоквартирного дома аварийным и подлежащим сносу или реконструкции",
        "col4": "г. Магадан, \nул. Горького, д. 19А\nopeka@magadangorod.ru\n62-06-62\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 2,
        "col3": "отдел архитектуры и градостроительства Управления по по вопросам обеспечения жизнедеятельности населения Администрации Ольского муниципального округа Магаданской области",
        "col4": "685910, Магаданская область. Ольский район, поселок Ола, площадь Ленина дом № 4, arxiola@ola49.ru, 8 (41341) 23088"
    },
    {
        "col1": "Магаданская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства\nадминистрации\nОмсукчанского\nмуниципального округа",
        "col4": "6868410, п. Омсукчан, ул. Ленина, д. 19, info@omsukchan-adm.ru, 8(41346)91681"
    },
    {
        "col1": "Магаданская область",
        "col2": 4,
        "col3": "Администрация Среднеканского муниципального округа Магаданской области (Управление экономики и развития)",
        "col4": "Магаданская область, Среднеканский район, поселок Сеймчан, ул. Ленина, дом 9. amosred@mail.ru    8-413-47-9-42-59 (приемная), 8-413-47-9-44-46 (экономика)"
    },
    {
        "col1": "Магаданская область",
        "col2": 5,
        "col3": "Отдел  опеки и попечительства управления образования\nадминистрации\nСеверо-Эвенского муниципального округа",
        "col4": "686430, Магаданская область, п. Эвенск, ул. Курилова, д. 16, 8 (413-48) 2-23-74, uoobrazevensk@mail.ru"
    },
    {
        "col1": "Магаданская область",
        "col2": 6,
        "col3": "Управление городского хозяйства и жизнедеятельности населения Администрации Сусуманского муниципального округа Магаданской области",
        "col4": "686314, Магаданская область. Сусуманский район, г. Сусуман, д. 17 ssmadm@mail.ru, 8(41345)22025"
    },
    {
        "col1": "Магаданская область",
        "col2": 7,
        "col3": "Управление жилищно-коммунального хозяйства, дорожной деятельности и благоустройства  администрации Тенькинского муниципального  округа Магаданской области",
        "col4": "ул. Горняцкая, д. 37, п. Усть-Омчуг, Тенькинский район,\nМагаданская область, 686050,\nтел. 8(41344)3-04-07,\nE-mail: moust-home@yandex.ru\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 8,
        "col3": "Администрации Хасынского муниципального округа Магаданской области \nОтдел опеки и попечительства\n",
        "col4": "686110 Магаданская область, Хасынский район, п. Палатка, ул. Ленина, д.76 -mail: priem_hasyn@49gov.ru\n84134292774\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 9,
        "col3": "Управление жилищно-коммунального хозяйства администрации Ягоднинского муниципального округа Магаданской области",
        "col4": "686230, поселок Ягодное, Ягоднинский район, Магаданская область, ул. Школьная, д. 9 \nе-mail: zhkh.yagodnoe@49gov.ru\nтел. (8 413 43) 2-35-05\n"
    },
    {
        "col1": "Московская область",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Мурманская область",
        "col2": 1,
        "col3": "Администрация города Апатиты",
        "col4": "пл. Ленина., д. 1, г. Апатиты, Мурманская область, 184209, (8 81555)  6 02 36, opeka-deti@apatity-city.ru  "
    },
    {
        "col1": "Мурманская область",
        "col2": 2,
        "col3": "Администрация ЗАТО Александровск",
        "col4": "ул. Флотская, д. 9, ЗАТО Александровск, Мурманская область, 184682, (881539) 5 23 22, ZarenkovaLV@zato-a.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 3,
        "col3": "Администрация ЗАТО пос. Видяево",
        "col4": "ул. Центральная, д. 8, ЗАТО Видяево, Мурманская область, 184372, (881553) 5 66 74, opeka@zatovid.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 4,
        "col3": "Администрация ЗАТО город Заозерск",
        "col4": "ул. Генерала Чумаченко, д. 4,                   г. Заозерск, Мурманская область, 184310,\nopekazaozersk@mail.ru,\n8(81556) 3 15 12"
    },
    {
        "col1": "Мурманская область",
        "col2": 5,
        "col3": "Администрация Кандалакшского района",
        "col4": "ул. Первомайская, д. 34, г. Кандалакша, Мурманская область, 184040, (8 815 33) 9 24 00, 9 30 11 \nopeka-kanda@yandex.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 6,
        "col3": "Администрация города Кировска",
        "col4": "пр. Ленина, д. 16, г. Кировск, Мурманская область, 184250, (881531) 5 52 75, gnevysheva.nv@gov.kirovsk.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 7,
        "col3": "Администрация Ковдорского муниципального округа",
        "col4": "пл. Ленина., д. 1, г. Ковдор, Мурманская область, 184141, (8 81535) 5 02 42 доб. 227, i.iosifova@kovadm.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 8,
        "col3": "Администрация Кольского района",
        "col4": "Советский пр., д. 50, г. Кола, Мурманская область, 184381, (81553) 3 61 82, opeka_deti@akolr.gov-murman.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 9,
        "col3": "Администрация Ловозерского района",
        "col4": "ул. Советская, д. 10, с. Ловозеро, Мурманская область, 184592, (8 815 38) 40 111, opeca-lov@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 10,
        "col3": "Администрация города Мончегорска",
        "col4": "ул. Железнодорожников, д. 6, г. Мончегорск, Мурманская область, 184511,  (881536) 3 18 90, opd@edumonch.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 11,
        "col3": "Администрация города Мурманска",
        "col4": "ул. Комсомольская, д. 10, г. Мурманск, 183036, (8 8152)  45-06-24, 45-61-71, kio@citymurmansk.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 12,
        "col3": "Администрация муниципального округа город Оленегорск с подведомственной территорией",
        "col4": "ул. Строительная, д. 52,   г. Оленегорск, Мурманская область, 184530, (881552) 50 300;                             kumi@admol.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 13,
        "col3": "Администрации ЗАТО г. Островной",
        "col4": "пл. Жертв Интервенции, д. 1,                     г. Островной, Мурманская обл. 184640,  (881558) 5 00 12, admzato@zato-ostrov.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 14,
        "col3": "Администрация Печенгского муниципального округа",
        "col4": "ул. Победы, д. 1, п.г.т. Никель, Печенгский район, Мурманская область, 184421, \nтелефон (81554) 5-19-40, 5-05-72;  obrpechenga@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 15,
        "col3": "Администрация города Полярные Зори",
        "col4": "ул. Ломоносова, д. 4, г. Полярные Зори, Мурманская область, 184230, (881532) 7 12 24, opeka@pz-city.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 16,
        "col3": "Администрация ЗАТО г. Североморск ",
        "col4": "пр. Советский, д. 50, г. Кола, Мурманская область, 184381, (8 81553)  3 61 82, 3 34 29, opeka-deti@akolr.gov-murman.ru   "
    },
    {
        "col1": "Мурманская область",
        "col2": 17,
        "col3": "Администрация Терского района",
        "col4": "пгт. Умба Терского района, Мурманская область, 184703, (8 81555)  6-71-12, ter.obrazovanie@mail.ru "
    },
    {
        "col1": "Ненецкий автономный округ",
        "col2": 1,
        "col3": "Департамент здравоохранения, труда и социальной защиты населения Ненецкого автономного округа",
        "col4": "ул. Смидовича, д.25, г. Нарьян-Мар, Ненецкий автономный округ, 166000; e-mail: m, тел. 8(81853) 2-13-68edsoc@adm-nao.ru"
    },
    {
        "col1": "Нижегородская область",
        "col2": 1,
        "col3": "Министерство социальной политики Нижегородской области",
        "col4": " г. Нижний Новгород, Кремль, корп. 14   603082, minsocium@nobl.ru          (831) 422-28-80"
    },
    {
        "col1": "Новгородская область",
        "col2": 1,
        "col3": "Комитет образования Администрации Батецкого муниципального округа",
        "col4": "Новгородская область, п.Батецкий, ул.Советская, д.39а, 175000; адрес электронной почты: gkomobr@mail.ru; 8(81661)22401 (доб.222)"
    },
    {
        "col1": "Новгородская область",
        "col2": 2,
        "col3": " Отдел по жилищным вопросам комитета жилищно-коммунального, дорожного хозяйства, строительства, транспорта и охраны окружающей среды Администрации Боровичского муниципального района",
        "col4": "Новгородская область, г.Боровичи, ул.Коммунарная, д.48, 174411; адрес электронной почты:jil@boradmin.ru; 8(81664)91213"
    },
    {
        "col1": "Новгородская область",
        "col2": 3,
        "col3": "Комитет жилищно-коммунального и дорожного хозяйства Администрации  Валдайского муниципального района",
        "col4": " Новгородская область, г.Валдай, пр.Комсомольский, д.19/21, 175400, адрес электронной почты: admin@valdayadm.ru;  8(81666)22516"
    },
    {
        "col1": "Новгородская область",
        "col2": 4,
        "col3": "Администрация Волотовского муниципального округа",
        "col4": "Новгородская область, п.Волот, ул.Комсомольская, д.38, 175100; адрес электронной почты: adm.volot@mail.ru; 8(81662)61325"
    },
    {
        "col1": "Новгородская область",
        "col2": 5,
        "col3": "Администрация  Демянского муниципального округа",
        "col4": "Новгородская область, п.Демянск, ул.Ленина, д.7, 175310, адрес электронной почты:priemnaya@dem-admin.ru; 8(81651)44012 (доб.6400)"
    },
    {
        "col1": "Новгородская область",
        "col2": 6,
        "col3": "Комитет образования Администрации Крестецкого муниципального округа",
        "col4": "Новгородская область, п.Крестцы, Советская площадь, д.1, 175460; адрес электронной почты: obraz@adm-krestcy.ru; 8(81659)54654"
    },
    {
        "col1": "Новгородская область",
        "col2": 7,
        "col3": "Администрация Любытинского муниципального района",
        "col4": "Новгородская область, п.Любытино, ул.Советов, д.29, 174760; адрес электронной почты:opeka.lyubytino@yandex.ru; 8(81668)61359"
    },
    {
        "col1": "Новгородская область",
        "col2": 8,
        "col3": "Администрация Маловишерского муниципального района",
        "col4": " Новгородская область, г.Малая Вишера, ул.Володарского, д.14,174260; адрес электронной почты: o.o.m.r@mail.ru; 8(81660)31934"
    },
    {
        "col1": "Новгородская область",
        "col2": 9,
        "col3": "Администрация Маревского муниципального округа",
        "col4": "Новгородская область, с. Марёво, ул. Советов, д. 27, адрес электронной почты: marevoobr@yandex.ru; 8(81663)21450 (доб.6843)"
    },
    {
        "col1": "Новгородская область",
        "col2": 10,
        "col3": "Комитет образования и культуры Администрации Мошенского муниципального округа",
        "col4": "Новгородская область, Мошенской  муниципальный округ, с. Мошенское, ул.Советская, д.5; адрес электронной почты:mosh-adm@yandex.ru;8(81653)61146"
    },
    {
        "col1": "Новгородская область",
        "col2": 11,
        "col3": "Комитет образовани Администрации Окуловского муниципального района",
        "col4": "Новгородская область, г.Окуловка, ул.Кирова, д.9, 174350; адрес электронной почты: komobr@okuladm.ru; 8(81657)21272; 8(81657)22604"
    },
    {
        "col1": "Новгородская область",
        "col2": 12,
        "col3": "Администрация Новгородского муниципального района (комитет по управлению муниципальным имуществом Администрации Новгородского муниципального района)",
        "col4": "г.Великий Новгород, ул.Большая Московская, д.60,173004, адрес электронной почты:AdmNovRay@yandex.ru, 8(8162)943612"
    },
    {
        "col1": "Новгородская область",
        "col2": 13,
        "col3": "Администрация Парфинского муниципального района",
        "col4": "Новгородская область, Парфинский муниципальный  район, п.Парфино, ул.К.Маркса,д.60, 175130; адрес электронной почты:parvrono@yandex.ru; 8(81650)6-30-42"
    },
    {
        "col1": "Новгородская область",
        "col2": 14,
        "col3": "Администрация Пестовского муниципального района",
        "col4": "Новгородская область, Пестовский муниципальный округ, г.Пестово, ул.Советская, д.10, 174510; адрес электронной почты:admin@adm-pestovo.ru; 8(81669)57466"
    },
    {
        "col1": "Новгородская область",
        "col2": 15,
        "col3": "Администрация Поддорского муниципального района",
        "col4": "Новгородская область, Поддорский муниципальный район, с.Поддорье, ул.Полевая, д.17, 175260; адрес электронной почты:obr@admpoddore.ru, 8(81658)71692"
    },
    {
        "col1": "Новгородская область",
        "col2": 16,
        "col3": "Администрация Солецкого муниципального района",
        "col4": "Новгородская область, г.Сольцы, пл.Победы, д.3, 175040; адрес электронной почты: soleco@adminsoltcy.ru; 8(81655)30502"
    },
    {
        "col1": "Новгородская область",
        "col2": 17,
        "col3": "Администрация Старорусского муниципального района",
        "col4": "Новгородская область, г.Старая Русса, ул.Советская Набережная, д.1,175202; адрес электронной почты: pochta@admrussa.ru; 8(81652)22335"
    },
    {
        "col1": "Новгородская область",
        "col2": 18,
        "col3": "Администрация Хвойнинского муниципального округа",
        "col4": "Новгородская область, Хвойнинский муниципальный округ, п.Хвойная, ул.Советская, д.4, 174580; адрес электронной почты: ko5317@yandex.ru, 8(81667)50395"
    },
    {
        "col1": "Новгородская область",
        "col2": 19,
        "col3": "Отдел образования Администрации Холмского муниципального округа",
        "col4": "Новгородская область, Холмский муниципальный округ, г.Холм, пл.Победы, д.4, к.3, 175270; адрес электронной почты:opeka53cholm2025@mail.ru? 8(81654)59162"
    },
    {
        "col1": "Новгородская область",
        "col2": 20,
        "col3": "Администрация Чудовского муниципального района",
        "col4": "Новгородская область, г.Чудово, ул.Некрасова, д.24а, 174210, адрес электронной почты: komobr@adminchudovo.ru, 8(81665)54858"
    },
    {
        "col1": "Новгородская область",
        "col2": 21,
        "col3": "Администрация Шимского муниципального района",
        "col4": "Новгородская область, Шимский муниципальный район, п.Шимск, ул.Новгородская, д.21,174150; адрес электронной почты:isveshenie@mail.ru; 8(81656)54636"
    },
    {
        "col1": "Новгородская область",
        "col2": 22,
        "col3": "Администрация Великого Новгорода ( управление по жилищным вопросам и социальной поддержке граждан Администрации Великого Новгорода)",
        "col4": "Новгородская область, г.Великий Новгород, ул.Стратилатовская,д.3; адрес электронной почты: shse@adm.nov.ru, 8(8162)778239"
    },
    {
        "col1": "Новосибирская область",
        "col2": 1,
        "col3": "администрация Баганского района Новосибирской области ",
        "col4": "Новосибирская область, Баганский район, с. Баган ул.М. Горького д.28, admbagan@nso.ru,                           8-383-53-21-387"
    },
    {
        "col1": "Новосибирская область",
        "col2": 2,
        "col3": "администрация Барабинского района Новосибирской области",
        "col4": "632334, Новосибирская область, г. Барабинск, ул. Ульяновская, 66; эл. почта: glv_admbar@nso.ru; тел.:  8-383-61-258-08"
    },
    {
        "col1": "Новосибирская область",
        "col2": 3,
        "col3": "администрация Болотнинского района Новосибирской области",
        "col4": "633340, Новосибирская область, Болотнинский район, город Болотное, улица Советская, 9, тел. 8-383-49-22-730, 8-383-49-22-136, bolotnoe-adm@yandex.ru, maer_en@nso.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 4,
        "col3": "Администрация Венгеровского района Новосибирской области",
        "col4": "632241, Новосибирская область, Венгеровский район, с.Венгерово, ул.Ленина, д.68/       pradmvengr@yandex.ru/           (383 69) 21-592"
    },
    {
        "col1": "Новосибирская область",
        "col2": 5,
        "col3": "Администрация Доволенского района Новосибирской  области",
        "col4": "383-54-20-508, opeka-dovol@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 6,
        "col3": "администрация Здвинского района Новосибирской области",
        "col4": "632951, Новосибирская область, Здвинский район, с. Здвинск, ул. Мира, д.13, admn-zdv@yandex.ru,    8 383 63 21 278"
    },
    {
        "col1": "Новосибирская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства администрации Искитимского района Новосибимрской области",
        "col4": "633209, Новосибирская область, г. Искитим, ул. Пушкина, д. 57а, эл. почта : opeka_29@mail.ru? тел. 8 (38343) 4-22-60"
    },
    {
        "col1": "Новосибирская область",
        "col2": 8,
        "col3": "Администрация Карасукского муниципального округа Новосибирской области",
        "col4": "632868, НСО, г. Карасук, ул. Октябрьская, дом 39, E-mail: radm-karasuk@nso.ru. Тел. 8(383)55 33-135, 8(383)55 32-256"
    },
    {
        "col1": "Новосибирская область",
        "col2": 9,
        "col3": "администрация Каргатского района Новосибирской области",
        "col4": "632402 Новосибирская обьласть, Каргатский район,город Каргат, улица Советская, дом 122 тел.83836523326, электронная почта - anyusheva_mv@nso.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 10,
        "col3": "Администрация Колыванского района Новосибирской области ",
        "col4": "633161, Новосибирская область, Колыванский район, р.п. Колывань, ул. Ленина, д. 79, тел. 8 383 52 51 284,        эл. почта koluvan-adm@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 11,
        "col3": "администрация Коченевского районаНовосибирской области",
        "col4": "632640, Новосибирская область, р.п. Коченево,          ул. Октябрьская, 43/kochadm@nso.ru/            8(383) 5125406"
    },
    {
        "col1": "Новосибирская область",
        "col2": 12,
        "col3": "Администрация Кочковского района Новосибирской области",
        "col4": "632491, Новосибирская область, Кочковский район, с. Кочки, ул. Революционная, 11, admkck@mail.ru, kochkiopeka@mail.ru, 8(383)56-22-300"
    },
    {
        "col1": "Новосибирская область",
        "col2": 13,
        "col3": "администрация Краснозерского района Новосибирской области",
        "col4": "Новосибирская область, р.п.Краснозерское, ул.Чкалова 5.632902. 83835742398"
    },
    {
        "col1": "Новосибирская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Куйбышевского муниципального района Новосибирской области",
        "col4": "632380,г. Куйбышев, ул. Куйбышева, 12             Тел.8(383 62)51-989               e-mai:      kainsk_opeka@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 15,
        "col3": "Администрация Купинского района Новосибирской области",
        "col4": "632735, Новосибирская область, Купинский район, г.Купино, ул. Железнодорожная, 42-а ( отдел опеки и попечительства) Тел: 8-383-58-20-997; opekakup@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства  администрации Кыштовского района Новосибирской области",
        "col4": "632270 ,Новосибирская область,Кыштовский район,с.Кыштовка,ул.Ленина,д.38\\opeka-kyshtovka@yandex.ru\\83837121 174"
    },
    {
        "col1": "Новосибирская область",
        "col2": 17,
        "col3": "администрация Маслянинского муниципального округа Новосибирской области",
        "col4": "633564, Новосибирская область, Маслянинский район, р.п. Маслянино, улица Коммунистическая, дом 1а,  8-383-47-21-418   admmsl@yandex.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 18,
        "col3": "Администрация Мошковского района Новосибирской области",
        "col4": "633131, Новосибирская область, Мошковский район, р.п. Мошково, ул. Советская д. 9                                                                тел. 838322900, электронная почта: oopadm@rambler.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 19,
        "col3": "Управление опеки и попечительства ",
        "col4": "г.Новосибирск, ул.Фабричная, д.55, 6 этаж, оф.602, 20opeka13@mail.ru, 373-45-87"
    },
    {
        "col1": "Новосибирская область",
        "col2": 20,
        "col3": "Администрация Ордынского района Новосибирской области",
        "col4": "633261, Новосибирская область, Ордынский район, р.п. Ордынское, пр. Революции, 17 /ordopeka@mail.ru/8-383-59-23-673"
    },
    {
        "col1": "Новосибирская область",
        "col2": 21,
        "col3": "Администрация Северного района Новосибирской области",
        "col4": "632080, Новосибирская обл., Северный р-н, с. Северное, ул Ленина, д.14 тел: 8(38360)21818 sevadmn@yandex.ru  "
    },
    {
        "col1": "Новосибирская область",
        "col2": 22,
        "col3": "администрация Сузунского района",
        "col4": "Новосибирская область, Сузунский район, р.п. Сузун, ул. Ленина, 51                           adm@suzunadm.ru              (38346)22550"
    },
    {
        "col1": "Новосибирская область",
        "col2": 23,
        "col3": "отдел опеки и попечительства администрации Татарского муниципального округа Новосибирской области",
        "col4": "632122, Новосибирская область, г. Татарск, ул. Ленина, 56, opekatat@mail.ru, 89383 64) 2-62-54"
    },
    {
        "col1": "Новосибирская область",
        "col2": 24,
        "col3": "администрация Тогучинского района",
        "col4": "633456, Новосибирская область, г. Тогучин, ул. Садовая, д.9б/ togopeka@mail.ru/8 383 40 24886"
    },
    {
        "col1": "Новосибирская область",
        "col2": 25,
        "col3": "администрация Убинского района Новосибирской области",
        "col4": "632520 Новосибирская область, Убинский район, с. Убинское, ул. Ленина, 23 тел. 8 (38366) 21-527                            8 (38366) 21-131  ubinadm@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 26,
        "col3": "администрация Усть-Таркского района Новосибирской области",
        "col4": "632160, Новосибирская область, Усть-Таркский район, с. Усть-Тарка, ул. Иванова, 7;   ustopeka@nso.ru/83837223574 (ООиП)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 27,
        "col3": "Администрация Чановского района Новосибирской области",
        "col4": "632201, НСО,р.п.Чаны, ул.Советская, д.118\nт.21-185, факс 21-657\nchany-adm@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 28,
        "col3": "Администрация Черепановского района Новосибирской области",
        "col4": "633520, Новосибирская область, Черепановский район,г.Черепаново, ул.Партизанская, 12, Email:orgotd@nso.ru,           т.8(383 45)21368,                      8(383 45)22206,                          8(383 45)24538"
    },
    {
        "col1": "Новосибирская область",
        "col2": 29,
        "col3": "Администрация Чистоозерного района \nНовосибирской области ",
        "col4": "632720, Новосибирская область,\nр.п. Чистоозерное, ул. Покрышкина, 11\ngln68@nso.ru, opekachst@yandex.ru\n8 383 68 91 735   "
    },
    {
        "col1": "Новосибирская область",
        "col2": 30,
        "col3": "администрация Чулымского района",
        "col4": "632551,Новосибирская область, Чулымский район, г. Чулым, ул. Чулымская, 43 (chladm@nso.ru. Тел.83835021656)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 31,
        "col3": "Админитсрация города Бердска",
        "col4": "633010, Новосибирская область, г.Бердск, ул. М.Горького, 7 oopberdsk@nso.ru  8(38341)29297"
    },
    {
        "col1": "Новосибирская область",
        "col2": 32,
        "col3": "Администрация города Искитима Новосибирской области",
        "col4": " \n633209, Новосибирская область, город Искитим, ул.Пушкина, 51.\npri_iskadm@mail.ru\n83834324566\n"
    },
    {
        "col1": "Новосибирская область",
        "col2": 33,
        "col3": "Администрация рабочего поселка Кольцово",
        "col4": "рабочий поселок Кольцово, проспект Никольский, \n здание 1, офис 400\nНовосибирская область, 630559\nтелефон: 336-15-93, \nE-mail: ooip@kolcovo.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 34,
        "col3": "Администрация города Оби Новосибирской области",
        "col4": "633102, Новосибирская область, ул. Авиационная, д.12, т. 8(38373)51640, email: adm_nso@mail.ru"
    },
    {
        "col1": "Омская область\n",
        "col2": 1,
        "col3": "Министерство строительства Омской области",
        "col4": "Некрасова ул., 3/1, \nг. Омск, 644099 \ne-mail: minstroy@omskportal.ru\nТел.: (3812)77-03-84"
    },
    {
        "col1": "Оренбургская область",
        "col2": 1,
        "col3": "министерство социального развития Оренбургской области",
        "col4": "460006, Оренбургская область, город Оренбург, Терешковой улица, 33 szn@mail.orb.ru  8(3532) 77-33-38"
    },
    {
        "col1": "Оренбургская область",
        "col2": 2,
        "col3": "Абдулинский муниципальный округ:",
        "col4": " Оренбургская область, г. Абдулино, ул. Коммунистическая, 276, 8 (35355) 2-52-47, ab@mail.orb.ru. "
    },
    {
        "col1": "Оренбургская область",
        "col2": 3,
        "col3": "город Бугуруслан: ",
        "col4": "Оренбургская обл., г. Бугуруслан, ул. Ленинградская, 47, 8 (35352) 3-33-69, bg@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 4,
        "col3": "город Бузулук: ",
        "col4": "Оренбургская область, г. Бузулук, ул.Ленина, 10, 8 (35342) 3-51-35, adm@buzuluk-town.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 5,
        "col3": " Гайский муниципальный округ: ",
        "col4": "Оренбургская область, г. Гай, ул. Ленина, 41, 8 (35362) 4-20-33. goradm@esoo.ru, gy@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 6,
        "col3": " Кувандыкский муниципальный округ",
        "col4": " Оренбургская область, г. Кувандык, ул. Оренбургская, 20, 8 (35361) 2-37-13, 2-22-20, ku@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 7,
        "col3": " город Медногорск:",
        "col4": " Оренбургская область, г. Медногорск, ул. Советская, 37, 8 (35379) 3-26-86, mo@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 8,
        "col3": "город Новотроицк: ",
        "col4": "Оренбургская область, г. Новотроицк, ул. Советская, 80, 8 (35376) 2-01-01, adm-nvk@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 9,
        "col3": " город Оренбург: ",
        "col4": "Оренбургская область, г. Оренбург, ул.Советская, 60, 8 (3532) 98-70-10, general@admin.orenburg.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 10,
        "col3": "город Орск:",
        "col4": " Оренбургская область, г. Орск, проспект Ленина, 29, 8 (3537) 25-30-90, uprava@orsk-adm.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 11,
        "col3": "Соль-Илецкий муниципальный округ",
        "col4": ": Оренбургская область, г.Соль-Илецк, ул. Карла Маркса, 6, 8 (35336) 2-32-32, si@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 12,
        "col3": "Сорочинский муниципальный округ:",
        "col4": " г. Сорочинск, ул.Советская, 1, 8 (35346) 4-11-51, admsor@esoo.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 13,
        "col3": " Ясненский муниципальный округ: ",
        "col4": "Оренбургская область, г. Ясный, ул. Ленина, 17, 8 (35368) 2-13-00, ys@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 14,
        "col3": "Адамовский район:",
        "col4": " Оренбургская область, Адамовский район, п.Адамовка, ул. Советская, 81, 8 (35365) 2-13-38, ad@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 15,
        "col3": "Акбулакский район:",
        "col4": " Оренбургская область, п. Акбулак, ул. Комсомольская, 15, 8 (35335) 2-11-40, ak@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 16,
        "col3": "Александровский район: ",
        "col4": "Оренбургская область, р-н Александровский, с. Александровка, ул. Мичурина, 49, 8 (35359) 2-11-02, al@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 17,
        "col3": "Асекеевский район: ",
        "col4": "Оренбургская область, с. Асекеево, ул. Чапаева, 28, 8 (35351) 2-00-83,  as@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 18,
        "col3": "Беляевский район:",
        "col4": " Оренбургская область, Беляевский район, с.Беляевка, ул. Советская, 46Б, 8 (35334) 2-17-25, be@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 19,
        "col3": "Бугурусланский район: ",
        "col4": "Оренбургская область, г. Бугуруслан, ул.Московская, 52а, 8 (35352) 2-39-16, bu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 20,
        "col3": "Бузулукский район: ",
        "col4": "Оренбургская область, г. Бузулук, ул. Ленина, 10, 8 (35342) 7-42-00, bz@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 21,
        "col3": "Грачевский район:",
        "col4": " Оренбургская область, с. Грачёвка, ул.Майская, 22, 8 (35344) 2-10-60, ge@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 22,
        "col3": " Домбаровский район: ",
        "col4": "Оренбургская область, Домбаровский район, п. Домбаровский, ул. 40 лет Октября, 20, 8 (35367) 2-24-52, dm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 23,
        "col3": "Илекский район: ",
        "col4": "Оренбургская область, Илекский район, с. Илек, ул. Октябрьская, 23, 8 (35337) 2-14-64, il@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 24,
        "col3": " Кваркенский район: ",
        "col4": "Оренбургская область, Кваркенский район, с. Кваркено, ул. 1-я Целинная, 18, 8 (35364) 2-17-63, kv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 25,
        "col3": " Красногвардейский район: ",
        "col4": "Оренбургская область, Красногвардейский район, с.Плешаново, ул.Мира, 5, 8 (35345) 3-14-44, 3-00-45, ko@mail.orb.ru"
    },
    {
        "col1": "Оренбургская область",
        "col2": 26,
        "col3": "Курманаевский район: ",
        "col4": "Оренбургская область, с. Курманаевка, пл. имени В. И.Ленина, 1, 8 (35341) 2-13-91, km@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 27,
        "col3": " Матвеевский район:",
        "col4": " Оренбургская обл., с. Матвеевка, ул. Комсомольская, 18, 8 (35356) 2-10-66, ma@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 28,
        "col3": "Новоорский район:",
        "col4": " Оренбургская  область, Новоорский район, п. Новоорск, ул. Рабочая, 1, 8 (35363) 7-70-01, 7-70-02, no@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 29,
        "col3": " Новосергиевский район: ",
        "col4": "Оренбургская область, п. Новосергиевка, ул. Краснопартизанская, 20, 8 (35339) 2-39-39, ns@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 30,
        "col3": " Октябрьский район: ",
        "col4": "Оренбургская область, с. Октябрьское, ул. Луначарского, 45, 8 (35330) 2-14-34, ok@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 31,
        "col3": " Оренбургский район:",
        "col4": " Оренбургская область, г. Оренбург, ул.Ст. Разина, 211, 8 (3532) 56-12-21, 77-28-91, or@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 32,
        "col3": " Первомайский район: ",
        "col4": "Оренбургская область, Первомайский район, п. Первомайский, ул. Советская, 33а, 8 (35348) 4-13-40, 4-13-42, pm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 33,
        "col3": "Переволоцкий район:",
        "col4": " Оренбургская область, п. Переволоцкий, ул. Ленинская, 76, 8 (35338) 32-2-79, pr@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 34,
        "col3": "Пономаревский район: ",
        "col4": "Оренбургская область, Пономарёвский район, с. Пономарёвка, ул. Советская, 32, 8 (35357) 21-4-50, 21-3-34, pn@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 35,
        "col3": " Сакмарский район: ",
        "col4": "Оренбургская область, с. Сакмара, ул. Советская, 25, 8 (35331) 2-11-30, 2-17-77, sk@mail.orb.ru,"
    },
    {
        "col1": "Оренбургская область",
        "col2": 36,
        "col3": "Саракташский район:",
        "col4": " Оренбургская область, п. Саракташ, ул.Вокзальная, 12, 8 (35333) 6-02-50, sa@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 37,
        "col3": " Светлинский район:",
        "col4": " Оренбургская обл., Светлинский р- н, п. Светлый, ул. Советская, 22, 8 (35366) 2-14-55, 2-13-56, sv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 38,
        "col3": " Северный район:",
        "col4": " Оренбургская область, с. Северное, ул. Советская, 24, 8 (35354) 2-17-75, 2-12-00, se@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 39,
        "col3": " Ташлинский район: ",
        "col4": "Оренбургская область, с. Ташла, ул. Довженко, 46, 8 (35347) 2-14-82, 2-13-09, tl@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 40,
        "col3": "Тоцкий район:",
        "col4": " Оренбургская область, с. Тоцкое, ул. Красная Площадь, 1, 8 (35349) 2-14-34, to@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 41,
        "col3": "Тюльганский район:",
        "col4": " Оренбургская область, Тюльганский район, п.Тюльган, ул. Ленина, 23, 8 (35332) 2-11-34, tu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 42,
        "col3": "Шарлыкский район:",
        "col4": " Оренбургская область, с. Шарлык, ул.Советская, 40, 8 (35358) 2-14-50, sl@mail.orb.ru."
    },
    {
        "col1": "Орловская область",
        "col2": 1,
        "col3": "Департамент жилищно-коммунального хозяйства, топливно-энергетического комплекса и энергосбережения Орловской области",
        "col4": "302040, г. Орёл, ул. Максима Горького, д. 45, djkh@adm.orel.ru, 8-4862-59-69-25"
    },
    {
        "col1": "Пензенская область",
        "col2": 1,
        "col3": "Управление ЖКХ г.Пензы",
        "col4": "город Пенза, Некрасова, 34, 4. e-mail:ygkh@yandex.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 2,
        "col3": "Администрация города Заречного Пензенской области",
        "col4": "Пензенская область, город Заречный, пр. 30-летия Победы, д. 27, e-mail: adm@zarechny.zato.ru, тел.: 8(8412) 652953"
    },
    {
        "col1": "Пензенская область",
        "col2": 3,
        "col3": "Комитет по управлению имуществом г. Кузнецка Пензенской области",
        "col4": "Пензенская область, город Кузнецк, ул. Ленина, д.186,e-mail:kumi-kuz.ru, 884157(33122)"
    },
    {
        "col1": "Пензенская область",
        "col2": 4,
        "col3": "Администрация Башмаковского района Пензенской области",
        "col4": "Пензенская область, р.п. Башмаково, ул. Советская, 17, baschm_adm@sura.ru 884143(4-13-40)"
    },
    {
        "col1": "Пензенская область",
        "col2": 5,
        "col3": "Администрация Бековского района Пензенской области",
        "col4": "Пензенская область, р.п. Беково, ул. Первомайская, д. 3а, E-mail: bekov_adm@sura.ru, тел.(8-841-41) 2-15-40"
    },
    {
        "col1": "Пензенская область",
        "col2": 6,
        "col3": "Администрация Белинского района Пензенской области",
        "col4": "Пензенская область, г. Белинский, площадь Комсомольская, д. 19, e-mail: belinsk_adm@mail.ru,тел.: 8(84153)21240"
    },
    {
        "col1": "Пензенская область",
        "col2": 7,
        "col3": "Администрация Бессоновского района Пензенской области",
        "col4": "Пензенская область, с. Бессоновка, ул. Коммунистическая, д. 2, e-mail: besson_adm@mail.ru,тел.: 8(84140) 28640"
    },
    {
        "col1": "Пензенская область",
        "col2": 8,
        "col3": "Администрация Вадинского района Пензенской области",
        "col4": "Пензенская область, Вадинский район, с. Вадинск, ул. Площадь Ленина, д.16, vadinsk_adm@sura.ru.8(84142)21169"
    },
    {
        "col1": "Пензенская область",
        "col2": 9,
        "col3": "Администрация Городищенского района Пензенской области",
        "col4": "г.Городище,ул.Комсомольская,д.40,gorodis_adm@sura.ru,88415832260"
    },
    {
        "col1": "Пензенская область",
        "col2": 10,
        "col3": "Администрация Земетчинского района Пензенской области",
        "col4": "Пензенская область, р.п. Земетчино, пл. Победы, 4, zemet_adm@sura.ru 84155(21350)"
    },
    {
        "col1": "Пензенская область",
        "col2": 11,
        "col3": "Администрация Иссинского района Пензенской области",
        "col4": "Администрация Иссинского района Пензенской области , р.п. Исса, ул Черокманова 21,issa_adm@sura.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 12,
        "col3": "Администрация Каменского района Пензенской области",
        "col4": "Пензенская область, Каменский район г. Каменка, ул. Суворова, 33, E-mail: kamr_adm@sura.ru, тел.: 8(84156) 2-12-40)"
    },
    {
        "col1": "Пензенская область",
        "col2": 13,
        "col3": "Администрация Камешкирского района Пензенской области",
        "col4": "442450 Пензенская область, Камешкирский район, с. Русский Камешкир, ул. Радищева, д. 15/ kamesh_adm@sura_ru/ 8(84145) 21478"
    },
    {
        "col1": "Пензенская область",
        "col2": 14,
        "col3": "Администрация Колышлейского района Пензенской области",
        "col4": "Пензенская область, Колышлейский район, р.п. Колышлей, ул. Московская, д.20, E-mail: kolish_adm@sura.ru, тел.: (84146) 2-13-40"
    },
    {
        "col1": "Пензенская область",
        "col2": 15,
        "col3": "Администрация Кузнецкого района Пензенской области",
        "col4": "Пензенская область, г. Кузнецк, ул. Комсомольская, д. 53, kuzr_adm@sura.ru, тел.: 8(84157)3-06-77"
    },
    {
        "col1": "Пензенская область",
        "col2": 16,
        "col3": "Администрация Лопатинского района Пензенской области",
        "col4": "lopatin_adm@sura.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 17,
        "col3": "Администрация Лунинского района Пензенской области",
        "col4": "Пензенская область Лунинский район р.п. Лунино ул. Юбилейная д. 39, (841-61)3-13-90, luninadm@yandex.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 18,
        "col3": "Администрация Малосердобинского района",
        "col4": "442800, Пензенская область, Малосердобинский район, село Малая Сердоба, Ленинская ул., д.42"
    },
    {
        "col1": "Пензенская область",
        "col2": 19,
        "col3": "Администрация Мокшанского района Пензенской области",
        "col4": "442370, Пензенская область, р.п. Мокшан, ул. Поцелуева, д. 1/mokshan_adm@sura.ru/8(84150)2-75-59"
    },
    {
        "col1": "Пензенская область",
        "col2": 20,
        "col3": "Администрация Наровчатского района Пензенской области",
        "col4": "с. Наровчат, площадь Ленина, д. 4, narovch_adm@sura.ru, 88416321171"
    },
    {
        "col1": "Пензенская область",
        "col2": 21,
        "col3": "Администрация Неверкинского района",
        "col4": "Пензенская область Неверкинский район с. Неверкино ул. Куйбышева, 9, эл.почта: neverk_adm@sura.ru, т. 8(84164)20249"
    },
    {
        "col1": "Пензенская область",
        "col2": 22,
        "col3": "Администрация Нижнеломовского района Пензенской области",
        "col4": "Пензенская область, Нижнеломовский район, ул. Розы Люксембург, д.4, E-mail: n.lomovadm@yandex.ru, тел.: (841-54)4-47-68"
    },
    {
        "col1": "Пензенская область",
        "col2": 23,
        "col3": "Администрация Никольского района ПЕнзенской области",
        "col4": "442680, Пензенская область, Никольский район, г.Никольск, ул.Московская, д.2, nikadm@nikrn.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 24,
        "col3": "Администрация Пачелмского района Пензенской области",
        "col4": "Пензенская область, р.п.Пачелма, ул.Драгунова, д8,pachelm_adm@sura.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 25,
        "col3": "Администрация Пензенского района Пензенской области",
        "col4": "440400, Пензенская область, Пензенский район, с. Кондоль, ул. Нагорная, д.6, тел.:(884147)55034,                        kondol-adm@yandex.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 26,
        "col3": "Администрация Сердобского района Пензенской области",
        "col4": "Пензенская область, Сердобский район, г.Сердобск, ул.Ленина, д.90, E-mail: serdr_adm@sura.ru, тел.: (84167) 2-35-42"
    },
    {
        "col1": "Пензенская область",
        "col2": 27,
        "col3": "Администрация Сосновоборского района Пензенской области",
        "col4": "(84168)2-13-40, Пензенская область, р.п. Сосновоборск, ул. Ленина, д. 67, sosnov_adm@sura.ru \n"
    },
    {
        "col1": "Пензенская область",
        "col2": 28,
        "col3": "Администрация Спасского района Пензенской области",
        "col4": "Пензенская область, г.Спасск, Советская площадь, д.36 spassk-ray-adm@yandex.ru 8-84151-31231"
    },
    {
        "col1": "Пензенская область",
        "col2": 29,
        "col3": "Администрация Тамалинского района Пензенской области",
        "col4": "р.п.Тамала ул Советская дом 20 tamala_adm@sura.ru 88416921430"
    },
    {
        "col1": "Пензенская область",
        "col2": 30,
        "col3": "Администрация Шемышейского района Пензенской области",
        "col4": "442430 Пензенская область, Шемышейский район, р.п. Шемышейка, ул. Ленина, д.47.,т.: (8-841-59) 2-17-80. Е-mail: shem_adm@sura.ru"
    },
    {
        "col1": "Пермский край",
        "col2": 1,
        "col3": "Министерство строительства Пермского края",
        "col4": "ул. Ленина, д. 51, г. Пермь,\nПермский край, 614000\nEmail: info@msa.permkrai.ru\nтел.: 8 (342) 235-10-85, 8 (342) 235-14-78\n"
    },
    {
        "col1": "Пермский край",
        "col2": 2,
        "col3": "1. администрация Александровского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 20 а, г. Александровск, Александровский МО,Пермский край, 618320 Email: administration@aleksandrovsk.permkrai.ru   тел.: 8 (342) 743 53 77"
    },
    {
        "col1": "Пермский край",
        "col2": 3,
        "col3": "2. администрация Бардымского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 14, с. Барда, Бардымский МО, Пермский край, 618150  Admin-barda@rambler.ru  8 (342) 922 06 10"
    },
    {
        "col1": "Пермский край",
        "col2": 4,
        "col3": "3. администрация Берёзовского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 33, с. Березовка, Берёзовский МО, Пермский край, 617565 \nadministration@berezovka.permkrai.ru \n 8 (342) 513 11 33"
    },
    {
        "col1": "Пермский край",
        "col2": 5,
        "col3": "4. администрация Большесосновского муниципального округа Пермского края;",
        "col4": "ул. Ворошилова, д. 2, с. Большая Соснова, Большесосновский МО, Пермский край, 617072  administration@bsn.permkrai.ru  тел.: 8 (342) 572 73 73"
    },
    {
        "col1": "Пермский край",
        "col2": 6,
        "col3": "5. администрация Юсьвинского муниципального округа Пермского края;",
        "col4": "ул. Красноармейская, д. 14, с. Юсьва, Юсьвинский МО, Пермский край, 619170  \nadministration@yusva.permkrai.ru\nтел.: 8 (342) 462 71 35"
    },
    {
        "col1": "Пермский край",
        "col2": 7,
        "col3": "6. администрация Юрлинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 15, с. Юрла, Юрлинский МО, Пермский край, 619200\nadministration@yurla.permkrai.ru\nтел.: 8 (342) 942 11 72"
    },
    {
        "col1": "Пермский край",
        "col2": 8,
        "col3": "7. администрация Гайнского муниципального округа Пермского края;",
        "col4": "ул. Кашина, д. 41, пос. Гайны, Гайнский МО, Пермский край, 619650\nGainy_adm@mail.ru\nтел.: 8 (342) 452 11 98"
    },
    {
        "col1": "Пермский край",
        "col2": 9,
        "col3": "8. администрация Губахинского муниципального округа Пермского края;",
        "col4": "ул. Никонова, д. 44, г. Губаха, Губахинский МО, Пермский край, 618250\nadmin-gubaha@mail.ru\n8 (342) 484 19 59, 8 (342) 484 04 80\n"
    },
    {
        "col1": "Пермский край",
        "col2": 10,
        "col3": "9. администрация Еловского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 32, с. Елово, Еловский МО, Пермский край, 618170\nadministration@elovo.permkrai.ru\n 8 (342) 963 11 07"
    },
    {
        "col1": "Пермский край",
        "col2": 11,
        "col3": "10. администрация Карагайского муниципального округа;",
        "col4": "ул. Ленина, д. 5, с. Карагай, Карагайский МО, Пермский край, 617210\nadministration@karagay.permkrai.ru\n8 (342) 973 16 35"
    },
    {
        "col1": "Пермский край",
        "col2": 12,
        "col3": "11. администрация Кишертского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 40, с. Усть-Кишерть, Кишертский МО, Пермский край, 617593\nadministration@kishert.permkrai.ru\n8 (342) 522 14 35, 8 (342) 522 14 03"
    },
    {
        "col1": "Пермский край",
        "col2": 13,
        "col3": "12. администрация Косинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 66, с. Коса, Косинский МО, Пермский край, 619430\nadministration@kosa.permkrai.ru\n8 (342) 982 12 32"
    },
    {
        "col1": "Пермский край",
        "col2": 14,
        "col3": "13. администрация Кочевского муниципального округа Пермского края;",
        "col4": "ул. Калинина, д. 5, с. Кочево, Кочевский МО, Пермский край, 619317\nadmKochevo@list.ru\n8 (342) 939 12 86"
    },
    {
        "col1": "Пермский край",
        "col2": 15,
        "col3": "14. администрация Кудымкарского муниципального округа Пермского края;",
        "col4": "ул. Лихачева, д. 54, г. Кудымкар, Кудымкарский МО, Пермский край, 618994\nadministration@kudymkar.permkrai.ru\n8 (342) 604 52 43"
    },
    {
        "col1": "Пермский край",
        "col2": 16,
        "col3": "15. администрация Куединского муниципального округа Пермского края;",
        "col4": " ул. Гагарина, д. 25, пос. Куеда, Куединский МО, Пермский край, 617700\nadministration@kueda.permkrai.ru\n8 (342) 623 55 65, 8 (342) 623 55 68"
    },
    {
        "col1": "Пермский край",
        "col2": 17,
        "col3": "16. администрация Кунгурского муниципального округа Пермского края;",
        "col4": " ул. Советская, д. 26, г. Кунгур,\nКунгурский МО, Пермский край, 617470\nkungrad59@mail.ru\n8 (342) 712 42 83"
    },
    {
        "col1": "Пермский край",
        "col2": 18,
        "col3": "17. администрация Октябрьского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 57, раб.пос. Октябрьский, Октябрьский МО, Пермский край, 617860\nadministration@oktyabrskiy.permkrai.ru\n8 (342) 662 19 78, 8 (342) 662 14 75"
    },
    {
        "col1": "Пермский край",
        "col2": 19,
        "col3": "18. администрация Ординского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 12, с. Орда, Ординский МО, Пермский край, 617500\nadministration@orda.permkrai.ru\n8 (342) 582 01 49"
    },
    {
        "col1": "Пермский край",
        "col2": 20,
        "col3": "19. администрация Осинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 25, г. Оса, Осинский МО, Пермский край, 61812\nadministration@osa.permkrai.ru\n8 (342) 914 65 65"
    },
    {
        "col1": "Пермский край",
        "col2": 21,
        "col3": "20. администрация Оханского муниципального округа Пермского края;",
        "col4": "ул. Куйбышева, д. 35, г. Оханск, Оханский МО, Пермский край, 618100\nadministration@okhansk.permkrai.ru\n8 (342) 793 13 51"
    },
    {
        "col1": "Пермский край",
        "col2": 22,
        "col3": "21. администрация Очерского городского округа Пермского края;",
        "col4": "ул. Ленина, д. 41, г. Очер, Очерский МО, Пермский край, 617140\nadministration@oсher.permkrai.ru\n8 (342) 783 10 90"
    },
    {
        "col1": "Пермский край",
        "col2": 23,
        "col3": "22. администрация Пермского муниципального округа Пермского края;",
        "col4": "ул. Верхне-Муллинская, д. 71, г. Пермь, Пермский край, 614065\nadministration@permsky.permkrai.ru\n8 (342) 296 21 51"
    },
    {
        "col1": "Пермский край",
        "col2": 24,
        "col3": "23. администрация Сивинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 66, с. Сива, Сивинский МО, Пермский край, 617240\nadministration@siva.permkrai.ru\n8 (342) 772 13 00"
    },
    {
        "col1": "Пермский край",
        "col2": 25,
        "col3": "24. администрация Уинского муниципального округа Пермского края;",
        "col4": "ул. Октябрьская, д. 1, с. Уинское,\nУинский МО, Пермский край, 617520\nEmail: administration@uinsk.permkrai.ru\nтел.: 8 (342) 592 31 97\n"
    },
    {
        "col1": "Пермский край",
        "col2": 26,
        "col3": "25. администрация Частинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 40, с. Частые, Частинский МО, Пермский край, 617170\nEmail: administration@chastye.permkrai.ru.\nтел.: 8 (342) 682 14 34"
    },
    {
        "col1": "Пермский край",
        "col2": 27,
        "col3": "26. администрация Чусовского муниципального округа Пермского края;",
        "col4": "ул. Сивкова, д. 8б, г. Чусовой, Чусовского МО, Пермский край, 618204\nEmail: administration@chusovoy.permkrai.ru.\nтел.: 8 (342) 563 69 10\n"
    },
    {
        "col1": "Пермский край",
        "col2": 28,
        "col3": "27. администрация Кизеловского муниципального округа Пермского края;",
        "col4": "ул. Луначарского, д. 19, г. Кизел,\nКизеловский МО, Пермский край, 618350\nEmail: administration@kizel.permkrai.ru.\nтел.: 8 (342) 554 46 52\n"
    },
    {
        "col1": "Пермский край",
        "col2": 29,
        "col3": "28. администрация Добрянского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 14, г. Добрянка,\nДобрянский МО, Пермский край, 618740\nadministration@dobryanka.permkrai.ru 8 (342) 652 93 68\n"
    },
    {
        "col1": "Пермский край",
        "col2": 30,
        "col3": "29. администрация городского округа ЗАТО «Звездный»;",
        "col4": "ул. Ленина, д. 11А, пос. Звёздный,\nПермский край, 614575\nEmail: administration@zvezdny.permkrai.ru.\nтел.: 8 (342) 297 06 37\n"
    },
    {
        "col1": "Пермский край",
        "col2": 31,
        "col3": "30. администрация Красновишерского муниципального округа Пермского края;",
        "col4": "ул. Джержинского, д. 6а, г. Красновишерск, Красновишерский МО, Пермский край, 618592\nadministration@krasnovishersk.permkrai.ru.\n8 (342) 433 03 27\n"
    },
    {
        "col1": "Пермский край",
        "col2": 32,
        "col3": "31. администрация Краснокамского муниципального округа Пермского края;",
        "col4": "пр-т Маяковского, д. 11, г. Краснокамск, Краснокамский МО, Пермский край, 617060\nEmail: administration@ krasnokamsk.permkrai.ru\nтел.: 8 (342) 734 44 54\n"
    },
    {
        "col1": "Пермский край",
        "col2": 33,
        "col3": "32. администрация Лысьвенского городского округа Пермского края;",
        "col4": "пр-т Победы, д. 38, г. Лысьва, Лысьвенский МО, Пермский край, 618900\nEmail: administration@lysva.permkrai.ru.\nтел.: 8 (342) 496 07 06\n"
    },
    {
        "col1": "Пермский край",
        "col2": 34,
        "col3": "33. администрация муниципального округа «город Березники» Пермского края;",
        "col4": "пл. Советская, д. 1, г. Березники,\nПермский край, 618417\nEmail: administration@berezniki.permkrai.ru\nТелефон: 8 (342) 426 21 15\n"
    },
    {
        "col1": "Пермский край",
        "col2": 35,
        "col3": "34. администрация Суксунского муниципального округа Пермского края;",
        "col4": "ул. К. Маркса, д. 4, пос. Суксун,\nСуксунский МО, Пермский край, 617560\nadministration@suksun.permkrai.ru.\n8 (342) 753 15 75, 8 (342) 753 12 65\n"
    },
    {
        "col1": "Пермский край",
        "col2": 36,
        "col3": "35. администрация Нытвенского муниципального округа Пермского края;",
        "col4": "ул. Либкнехта, д. 2а, г. Нытва,\nНытвенский МО, Пермский край, 617000\nadministration@nytva.permkrai.ru.\nтел.: 8 (342) 723 08 56\n"
    },
    {
        "col1": "Пермский край",
        "col2": 37,
        "col3": "36. администрация Пермского городского округа Пермского края;",
        "col4": "ул. Ленина, д. 23, г. Пермь,\nПермский ГО, Пермский край, 614000\nEmail: main@gorodperm.ru.\nтел.: (342) 217 33 17\n"
    },
    {
        "col1": "Пермский край",
        "col2": 38,
        "col3": "37. администрация Соликамского муниципального округа Пермского края;",
        "col4": "ул. 20 лет Победы, д. 106, г. Соликамск,\nСоликамский МО, Пермский край, 618554\nEmail: administration@solikamsk.permkrai.ru.\nтел.: 8 (342) 537 03 05\n"
    },
    {
        "col1": "Пермский край",
        "col2": 39,
        "col3": "38. администрация Чайковского городского округа Пермского края;",
        "col4": "ул. Ленина, д. 37, г. Чайковский,\nЧайковский ГО, Пермский край, 617760\nadministration@chaykovsky.permkrai.ru.\nтел.: 8 (342) 413 25 07\n"
    },
    {
        "col1": "Пермский край",
        "col2": 40,
        "col3": "39. администрация Чердынского муниципального округа Пермского края;",
        "col4": "ул. Соборная, д. 18а, г. Чердынь,\nЧердынский МО, Пермский край, 618601\nadministration@cherdyn.permkrai.ru.\nтел.: 8 (342) 402 82 01\n"
    },
    {
        "col1": "Пермский край",
        "col2": 41,
        "col3": "40. администрация Чернушинского муниципального округа Пермского края;",
        "col4": "ул. Юбилейная, д. 9, г. Чернушка, Чернушинский МО, Пермский край, 617830\nadministration@chernushka.permkrai.ru.\nтел.: 8 (342) 614 43 95\n"
    },
    {
        "col1": "Пермский край",
        "col2": 42,
        "col3": "41. администрация Ильинского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 7, пос. Ильинский,\nИльинский ГО, Пермский край, 617020\nadministration@ilinsky.permkrai.ru.\nтел.: 8 (342) 769 16 08\n"
    },
    {
        "col1": "Пермский край",
        "col2": 43,
        "col3": "42. администрация Горнозаводского муниципального округа Пермского края;",
        "col4": "ул. Кирова, д. 65, г. Горнозаводск, Горнозаводский МО, Пермский край, 618820\nadministration@gornozavodsk.permkrai.ru.\nтел.: 8 (342) 694 14 55\n"
    },
    {
        "col1": "Пермский край",
        "col2": 44,
        "col3": "43. администрация Верещагинского муниципального округа Пермского края.",
        "col4": "ул. Ленина, д. 26, г. Верещагино, Верещагинский МО, Пермский край, 617120\nadministration@vereschagino.permkrai.ru.\nтел.: 8 (342) 543 33 50, 8 (342) 543 33 55\n"
    },
    {
        "col1": "Приморский край",
        "col2": 1,
        "col3": "Отдел по учету и распределению жилья управления жизнеобеспечения администрации Арсеньевского городского округа",
        "col4": "692337, Приморский край, г. Арсеньев, ул. Ленинская, 8 (каб.213) zhilotdel@ars.town 8(42361) 3-75-538;\n(42361) 3-75-54"
    },
    {
        "col1": "Приморский край",
        "col2": 2,
        "col3": "Отдел учета и распределения жилья администрации Артемовского городского округа",
        "col4": "692760, Приморский край, г. Артём, ул. Кирова, дом 48 / zhilotdel_artem@mail.ru / тел. 8 42337 42466"
    },
    {
        "col1": "Приморский край",
        "col2": 3,
        "col3": "управление жизнеобеспечения администрации городского округа Большой Камень",
        "col4": "692806, Приморский край, г. Большой Камень, ул. Карла Маркса, д. 4,  ugo@bkadmin.ru, 8(42335)5-83-35"
    },
    {
        "col1": "Приморский край",
        "col2": 4,
        "col3": "Администрации города Владивостока      (Управление содержания жилищного фонда)",
        "col4": "г. Владивосток,   Океанский проспект, д. 20.   (прием документов по адресу: г. Владивосток,  ул. Ильичева, д. 15, каб. 45а, тел.  8(423)2-614-041, 8(423)2-614-042\n8(423)2-614-198     dom@vlc.ru  )"
    },
    {
        "col1": "Приморский край",
        "col2": 5,
        "col3": "Отдел жизнеобеспечения администрации Дальнегорского городского округа ",
        "col4": "3-23-00 dgopriem@mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 6,
        "col3": "МКУ \"Управление ЖКХ Дальнереченского городского округа\"",
        "col4": "г. Дальнереченск, ул. Победы, д.13, zizneob@dalnerokrug.ru тел.842356 25-1-82"
    },
    {
        "col1": "Приморский край",
        "col2": 7,
        "col3": "управление имущественных отношений администрации Лесозаводского муниципального округа",
        "col4": "г. Лесозаводск, ул. Будника, д. 119, каб. 301 , kuialgo@mo-lgo.ru 8(42355)29050"
    },
    {
        "col1": "Приморский край",
        "col2": 8,
        "col3": "Управление жилищно-коммунального хозяйства администрации находкинского городского округа",
        "col4": "г. Находка, Находкинский проспект, 14, gkh@nakhodka-city.ru, 8(4236) 69-21-64"
    },
    {
        "col1": "Приморский край",
        "col2": 9,
        "col3": "Администрация муницапального  округа город Партизанск Приморского края",
        "col4": "692864, Приморский край, г. Партизанск, ул. Ленинская, д. 26 \"А\", pgo@partizansk.org.ru 824236360742"
    },
    {
        "col1": "Приморский край",
        "col2": 10,
        "col3": "Администрация городского округа Спасск-Дальний   Управление ЖКХ ",
        "col4": "г.Спасск-Дальний ул.Борисова, д.17 gkh@spasskd.ru 84235220993"
    },
    {
        "col1": "Приморский край",
        "col2": 11,
        "col3": "Администрация Уссурийского городского округа Приморского края",
        "col4": "692519 Приморский край,г. Уссурийск, ул. Ленина,   д. 101,   тел: 8(4234)32-26-31, эл. адрес:admin1@adm-ussuriisk.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 21,
        "col3": "Отдел опеки и попечительства администрации городского округа ЗАТО Фокино",
        "col4": "692880, г. Фокино, \nул. Постникова, д. 17\nТел.: 8(42339) 2-43-55"
    },
    {
        "col1": "Приморский край",
        "col2": 13,
        "col3": "Администрация Анучинского муниципального округа",
        "col4": "692300, Приморский край, Анучинский район, с.Анучино, ул. Лазо, д.6.anuchinsky@mail.ru,   Тел.: 8(42362) 91-6-78"
    },
    {
        "col1": "Приморский край",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Дальнереченского муниципального района",
        "col4": "692132, г. Дальнереченск, ул. Ленина,90/ opeka_dmr@mail.ru /  8 42(356) 32157"
    },
    {
        "col1": "Приморский край",
        "col2": 15,
        "col3": "Администрация Кавалеровского муниципального округа отдел опеки и попечительства Управления образования",
        "col4": "Приморский край, Кавалеровский район, п. Кавалерово, ул. Арсеньева, д. 111 тел. 8(42375)9-11-10 opeka1700@mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 16,
        "col3": "Администрация Кировского муниципального района",
        "col4": "692091, Приморский край, Кировский район,                     пгт. Кировский,                         ул. Советская, д. 57, kirovsky_mr@mail.ru,                          8-42354-21703  "
    },
    {
        "col1": "Приморский край",
        "col2": 17,
        "col3": "Администрация Красноармейского МО",
        "col4": "692171, Приморский край, Красноармейский район, с. Новопокровка, ул. Советская, 74, opeka19@mail.ru, 8(42359)22559"
    },
    {
        "col1": "Приморский край",
        "col2": 18,
        "col3": "Отдел архитектуры, градостроительства, земельных и имущественных отношений администрации Лазовского муниципального округа ",
        "col4": "692980 Приморский край, Лазовский район, с. Лазо, ул. Некрасовская, 31, 8(42377)20481, lazo.44@.mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 19,
        "col3": "Главный специалист 1 разряда по обеспечению детей-сирот, детей, оставшихся без попечения родителей лиц, из числа детей-сирот и детей, оставшихся без попечения родителей жилыми помещениями администрации Михайловского муниципального округа.",
        "col4": "Зелинская Наталья Александровна, тел.8(42346)2-58-46 ,   692651,с. Михайловка, ул.Красноармейская,д.16, каб.212,  Е-mail: mihadmsirota@mikhprim.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 20,
        "col3": "Управление жизнеобеспечения администрации Надеждинского муниципального района",
        "col4": "692481, Приморский край Надеждинский район,            с. Вольно - Надеждинское,  ул. Пушкина д. 57А          тел.: 8(42334) 2-05-45"
    },
    {
        "col1": "Приморский край",
        "col2": 22,
        "col3": "Упавление ЖКХ и экономического и градостроительного развития",
        "col4": "692460 пгт Ольга, ул. Ленинская, д.8  otdel_jkh_olga@mail.ru 8 (42376)91205"
    },
    {
        "col1": "Приморский край",
        "col2": 23,
        "col3": "Администрация Партизанского муниципального округа ",
        "col4": "692962, Приморский край, Партизанский район, с.Владимиро-Александровское, ул.Комсомольская 45а, 84236521825, opeka_pmr@rambler.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 24,
        "col3": "Администрация Пограничного МО, отдел имущественных отношений и землепользования",
        "col4": "692582 п. Пограничный Приморского края, ул. Советская, 31.  84234522774. pogr_property@mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 25,
        "col3": "Жилищный отдел администрации Пожарского муниципального округа",
        "col4": "692001, Приморский край, Пожарский район,               пгт Лучегорск, общественный центр, д. 1, каб. 17    elenaklim3636@mail.ru              8 (42357) 33 9 94"
    },
    {
        "col1": "Приморский край",
        "col2": 26,
        "col3": "Отдел жилищно-коммунального хозяйства администрации Спасского муниципального района",
        "col4": "692245 Приморский край, г. Спасск-Дальний, ул. Ленинская, 27, адрес электронной почты: spasskmr-gkh@yandex.ru, тел. (842352) 2-19-59"
    },
    {
        "col1": "Приморский край",
        "col2": 27,
        "col3": "отдел жизнеобеспечения и развития инфраструктуры администрации Тернейского муниципального округа",
        "col4": "отдел жизнеобеспечения и развития инфраструктуры, 693150 Приморский край, Тернейский муниципальный округ пгт Терней, ул. Ивановская, д. 2; gkxkubik@mail.ru, 8(42374)31440"
    },
    {
        "col1": "Приморский край",
        "col2": 28,
        "col3": "Администрация Ханкайского муниципального округа, Отдел социальной и молодежной политики",
        "col4": "Приморский край, Ханкайскйи район, с. Камень-Рыболов, ул. Кирова, д.8, 692684; ahmo@hankayski.ru, 8(42349)97293"
    },
    {
        "col1": "Приморский край",
        "col2": 29,
        "col3": "Управление жизнеобеспечение администрации Хасанского муниципального округа ПК",
        "col4": "Приморский край, Хасанский муниципальный округ, пгт Славянка, ул. Молодежная, д. 1, каб. 228.  hasan-zo@mail.ru   *942331)48-3-57"
    },
    {
        "col1": "Приморский край",
        "col2": 30,
        "col3": "Администрация Хорольского муниципального округа Приморского края",
        "col4": "692254, Приморский край, Хорольский район,с.Хороль, ул.Ленинская, 51 / admhmr@khorol.ru/ +7 (42347) 2-24-01"
    },
    {
        "col1": "Приморский край",
        "col2": 31,
        "col3": "Администрация Черниговского МО (Отдел опеки и попечительства, отдел градостроительства)",
        "col4": "692372, с. Черниговка, ул. Буденного, 23, info@chernigovka.org, 8 (42351) 25-7-89"
    },
    {
        "col1": "Приморский край",
        "col2": 32,
        "col3": "Администрация Чугуевского муниципального округа",
        "col4": "692623, Приморский край, Чугуевский район, с. Чугуевка, ул. 50 лет Октября, д. 193, e-mail vmpgkx@mail.ru, телефон (42372) 21-4-48, тел./факс (42372) 21-3-87"
    },
    {
        "col1": "Приморский край",
        "col2": 33,
        "col3": "Управление имущественных и земельных отношений Администрации Шкотовского муниципального округа",
        "col4": "г. Большой Камень, ул. Карла Маркса, 4. kymi@bk.ru. 8 42 335 5-00-30"
    },
    {
        "col1": "Приморский край",
        "col2": 34,
        "col3": "Администрация Яковлевского муниципального округа",
        "col4": "692361 приморский край, Яковлевский район, с. Яковлевка, пер. Почтовый д. 5, tooipyakovlevka@mail.ru тел. 8(423711) 97441 "
    },
    {
        "col1": "Псковская область",
        "col2": 1,
        "col3": "Территориальный отдел г. Великие Луки",
        "col4": "180000, г. Псков, ул. Вокзальная, д. 12\npskov.opc@socal.pskov.ru\n(8112) 72-01-05"
    },
    {
        "col1": "Псковская область",
        "col2": 2,
        "col3": "Территориальный отдел Бежаницкого района",
        "col4": "182840, р.п. Бежаницы, ул. Комсомольская, д. 1\nbezhanicy@social.pskov.ru\n(81141) 2-19-82"
    },
    {
        "col1": "Псковская область",
        "col2": 3,
        "col3": "Территориальный отдел Гдовского района",
        "col4": "181600, г. Гдов, ул. Ленина, д. 2/9 \ngdov@social.pskov.ru\n(81131) 2-12-77"
    },
    {
        "col1": "Псковская область",
        "col2": 4,
        "col3": "Территориальный отдел Дедовичского района",
        "col4": "182710, р.п. Дедовичи, пл. Советов, д. 7\ndedovichi@social.pskov.ru\n(81136) 9-34-01 (факс), 9-30-77"
    },
    {
        "col1": "Псковская область",
        "col2": 5,
        "col3": "Территориальный отдел Дновского района",
        "col4": "182670, г. Дно, ул. К.Маркса, д. 16\ndno@social.pskov.ru\n(81135) 2-58-56 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 6,
        "col3": "Территориальный отдел Красногородского района",
        "col4": "182370, р.п. Красногородск, ул. Школьная, д. 3\nkrasnogorodsk@social.pskov.ru\n(81137) 2-12-42 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 7,
        "col3": "Территориальный отдел Куньинского района",
        "col4": "182010, р.п. Кунья, ул. Советская, д. 17\nkunja@social.pskov.ru\n(81149) 2-18-94 (факс), 2-22-41"
    },
    {
        "col1": "Псковская область",
        "col2": 8,
        "col3": "Территориальный отдел Локнянского района",
        "col4": "182900, р.п. Локня, ул. Первомайская, д. 3\nloknja@social.pskov.ru\n(81139) 2-22-69, 2-18-71 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 9,
        "col3": "Территориальный отдел Невельского района",
        "col4": "182510, г. Невель, пл. Карла Маркса, д. 1\nnevel@social.pskov.ru\n(81151) 2-12-98 (факс), 2-15-21"
    },
    {
        "col1": "Псковская область",
        "col2": 10,
        "col3": "Территориальный отдел Новосокольнического района",
        "col4": "182200, г. Новосокольники, ул. Тихмянова, д. 25\nnovosokolniki@social.pskov.ru\n(81144) 2-23-33 (факс), 2-22-44"
    },
    {
        "col1": "Псковская область",
        "col2": 11,
        "col3": "Территориальный отдел Опочецкого района",
        "col4": "182330, г. Опочка, ул. Ленина, д. 11/17\nopochka@social.pskov.ru\n(81138) 2-25-16, 2-10-53"
    },
    {
        "col1": "Псковская область",
        "col2": 12,
        "col3": "Территориальный отдел Островского района",
        "col4": "181350, г. Остров, ул. 25 Октября, д. 31\nostrov@social.pskov.ru\n(81152) 3-29-60 (факс), 3-13-21"
    },
    {
        "col1": "Псковская область",
        "col2": 13,
        "col3": "Территориальный отдел Палкинского района",
        "col4": "182270, р.п. Палкино, ул. Островского, д. 23\npalkino@social.pskov.ru\n(81145) 2-18-51"
    },
    {
        "col1": "Псковская область",
        "col2": 14,
        "col3": "Территориальный отдел\nПечорского района",
        "col4": "181500, г. Печоры, ул. Свободы, д. 34\npechory@social.pskov.ru\n(81148) 2-17-17, 2-29-49 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 15,
        "col3": "Территориальный отдел Плюсского района",
        "col4": "181000, р.п. Плюсса, ул. Комсомольская, д. 1а\npljussa@social.pskov.ru\n(81133) 2-17-67 (факс), 2-23-34"
    },
    {
        "col1": "Псковская область",
        "col2": 16,
        "col3": "Территориальный отдел Порховского района",
        "col4": "182620, г. Порхов, пр. Ленина, д. 14\nporhov@social.pskov.ru\n(81134) 2-46-09 (факс), 2-46-06"
    },
    {
        "col1": "Псковская область",
        "col2": 17,
        "col3": "Территориальный отдел Псковского района",
        "col4": "180006, г. Псков, ул. Школьная, д. 26\npskovrajon@social.pskov.ru\n(8112) 72-07-55, 72-07-60 (факс), 72-06-85"
    },
    {
        "col1": "Псковская область",
        "col2": 18,
        "col3": "Территориальный отдел Пустошкинского района",
        "col4": "182300, г. Пустошка, ул. Первомайская, д. 21\npustoshka@social.pskov.ru\n(81142) 2-14-81 (факс), 2-12-53"
    },
    {
        "col1": "Псковская область",
        "col2": 19,
        "col3": "Территориальный отдел Пушкиногорского района",
        "col4": "181370, р.п. Пушкинские Горы, ул. Ленина, д. 6\npushgory@social.pskov.ru\n(81146) 2-20-94 (факс), 2-13-05"
    },
    {
        "col1": "Псковская область",
        "col2": 20,
        "col3": "Территориальный отдел Пыталовского района",
        "col4": "181410, г. Пыталово, ул. Красноармейская, д. 37\npytalovo@social.pskov.ru\n(81147) 2-21-54"
    },
    {
        "col1": "Псковская область",
        "col2": 21,
        "col3": "Территориальный отдел Себежского района",
        "col4": "182250, г. Себеж, ул. 7 Ноября, д. 2\nsebezh@social.pskov.ru\n(81140) 2-15-26, 2-22-48\n"
    },
    {
        "col1": "Псковская область",
        "col2": 22,
        "col3": "Территориальный отдел Струго-Красненского района",
        "col4": "181110, р.п. Струги Красные, ул. Советская, д. 7а\nstrugikrasnye@social.pskov.ru\n(81132) 5-15-94 (факс), 5-19-39"
    },
    {
        "col1": "Псковская область",
        "col2": 23,
        "col3": "Территориальный отдел Усвятского района",
        "col4": "182570, р.п. Усвяты, ул. К.Маркса, д. 20\nusvjaty@social.pskov.ru\n(81150) 2-16-74, 2-16-30\n"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 1,
        "col3": "1) \"Городской округ \"город Майкоп\"",
        "col4": "385000, г. Майкоп, ул. Краснооктябрьская, 21,\n тел.: 8(8772) 52-27-61,\ne-mail: priemn@maikop.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 2,
        "col3": "2) \"Городской округ \"Город Адыгейск\" Республики Адыгея\";",
        "col4": " 385200, г. Адыгейск, \nпр-т им. В. И. Ленина, 31,\nтел.: 8(87772) 9-16-90,\nтел.: 8(87772) 9-14-05, е-mail: admadig@mail.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 3,
        "col3": "3) \"Гиагинский муниципальный район Республики Адыгея\";",
        "col4": "ст. Гиагинская, \nул. Кооперативная, 35.\nтел.: +7 (87779) 3-09-30, доб. 111,\nе-mail: giaginskadmin@mail.r"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 4,
        "col3": "4) \"Кошехабльский муниципальный район Республики Адыгея\";",
        "col4": "385400, а. Кошехабль,\nул. Дружбы народов, 58\nТел.: (877-70) 9-28-00, \nе-mail: kosadmin@radnet.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 5,
        "col3": "5) \"Красногвардейский муниципальный район Республики Адыгея\";",
        "col4": " с. Красногвардейское, \nул. Чапаева, 93, тел. +7 (87778)5-34-61,\n e-mail: radnet2005@mail.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 6,
        "col3": "6) \"Майкопский муниципальный район Республики Адыгея\";",
        "col4": "385730, п. Тульский, \nул. Советская, 42,\nтел./факс: (877-77) 5-11-51,\nе-mail: adm@egov01.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 7,
        "col3": "7) \"Тахтамукайский муниципальный район Республики Адыгея\";",
        "col4": "385100, а. Тахтамукай, \nул. Гагарина, 2, Тел.: (877-71) 9-62-90,\nе-mail: tahtamukay@mail.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 8,
        "col3": "8) \"Теучежский муниципальный район Республики Адыгея\";",
        "col4": "385230, а. Понежукай, ул.Октябрьская, 33, тел.: 8(877-72) 9-77-09;\nе-mail: teuch adm ady@mail.ru тел"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 9,
        "col3": "9) \"Шовгеновский муниципальный район Республики Адыгея\"",
        "col4": "а. Хакуринохабль, \nул, Шовгенова, 9, тел.: 8(87773)9-21-74,\nе-mail: shovadm@adygheya.gov.ru"
    },
    {
        "col1": "Республика Алтай",
        "col2": 1,
        "col3": "Министерство труда, социального развития и занятости населения Республики Алтай",
        "col4": "Республика Алтай, г. Горно - Алтайск, ул. Северная, 10, mintrud@altaigov.ru, 8(38822)47732"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 1,
        "col3": "Администрация муниципального района Абзелиловский район Республики Башкортостан (межведомственная комиссия, отдел опеки и попечительства)",
        "col4": "453620, Республика Башкортостан, Абзелиловский район, с.Аскарово, ул.Ленина, д.41, adm01@bashkortostan.ru, 8(34772)2-15-10"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 2,
        "col3": "Администрация МР Альшеевский район РБ",
        "col4": "452120, Республика Башкортостан, Альшеевский район,ул. Ленина, д. 113, adm02@bashkortostan.ru, 8(34754)3-15-88"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 3,
        "col3": "Администрация МР Архангельский район РБ(Отдел по строительству и ЖКХ)",
        "col4": "453030, Республика Башкортостан, Архангельский район, с. Архангельское, ул. Н.Крупская, д.2, тел 834774 -2 -13-76, 03.sjp@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 4,
        "col3": "Администрация МР Аскинский район РБ",
        "col4": "452880, Республика Башкортостан, Аскинский район, с. Аскино, ул. Советская, д.21, adm04@bashkortostan.ru +7(347)712-17-65"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 5,
        "col3": "Администрация МР Аургазинский район РБ",
        "col4": "453480 РБ, Аургазинский район, с. Толбазы, ул. Ленина, д.84, alfya@yandex.ru 8(34745)2-19-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 6,
        "col3": "Администрация ГП г.Баймак МР Баймакский район РБ",
        "col4": "Администрация ГП г.Баймак МР Баймакский район РБ телефон: (34751) 2-22-49\nЭлектронная почта: admgpbaimak@mail.ru\nАдрес: 453630, Республика Башкортостан, г. Баймак, ул. М.Горького, 26"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 7,
        "col3": "Администрация муниципального района Бакалинский район Республики Башкортостан",
        "col4": "452650, РБ, Бакалинский район, с. Бакалы, ул. Ленина, д. 91 adm06@bashkortostan.ru тел. (34742) 3-29-42"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 8,
        "col3": "Администрация \nмуниципального района \nБалтачевский район\nРеспублики Башкортостан",
        "col4": "452980, Республика \nБашкортостан, \nс. Старобалтачево,\nул. Советская, д. 48,\nadm07@bashkortostan.ru/\n+7 (34753) 2-19-61"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 9,
        "col3": "Администрация МР Белебеевский район РБ (отдел строительства и дорожного хозяйства)",
        "col4": "452000,РБ, г. Белебей, ул. Красная, д. 116, 45.oks@bashkortostan.ru, 8 (34786) 4-27-06"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 10,
        "col3": "Администрации муниципального района Белокатайский район (Отдел опеки и попечительства)",
        "col4": "РБ, Белокатайский район, с. Новобелокатай, ул. Советская, д. 116 adm08@bashkortostan.ru 8(34750)22772"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 11,
        "col3": "Администрации муниципального района Белорецкий район (Отдел капитального строительства и ремонта)",
        "col4": "РБ, г. Белорецк, ул. Ленина, д. 71, adm46@bashkortostan.ru, +7 (34792) 4-57-67"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 12,
        "col3": "Администрация муниципального района Бижбулякский район ( Отдел строительства архитектуры и ЖКХ )",
        "col4": "РБ, Бижбулякский район, с.Бижбуляк, ул.Победы, д.13,adm09@bashkortostan.ru, тел: 8347-43 21554"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 13,
        "col3": "Администрация МР Бирский район РБ (сектор по жилищным вопросам)",
        "col4": "РБ, г. Бирск, ул. Курбатова, д. 63, jilotdel47@mail.ru, 8(347)8444037"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 14,
        "col3": "Администрация МР (отдел жилищно-коммунального хозяйства и благоустройства)",
        "col4": "РБ, г. Благовещенск, ул. Седова, д. 96, blagokc@yandex.ru, +73476621665"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 15,
        "col3": "Администрация муниципального района Благоварский район Республики Башкортостан (отдел жизнеобеспечения, строительства, архитектуры, транспорта и связи)",
        "col4": "452740, Республика Башкортостан, Благоварский район, с.Языково, ул.Ленина, д.37, adm10@bashkortostan.ru/83474722083"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 16,
        "col3": "Администрация МР\nБуздякский район\nРеспублики Башкортостан \n(отдел жизнеобеспечения, \nстроительства, архитектуры, \nтранспорта и связи)",
        "col4": "452710, \nРеспублика Башкортостан , \nс.Буздяк, ул. Ленина, 4\nadm11@bashkortostan.ru,\n+7347730404"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 17,
        "col3": "Администрация МР Бураевский район РБ (сектор опеки и попечительства)",
        "col4": "452960 Республика Башкортостан с.Бураево, ул. Ленина,66 adm12@bashkortostan.ru +73475621734"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 18,
        "col3": "Администрация МР Бурзянский район Республики Башкортостан (межведомственная комиссия, отдел опеки и попечительства)",
        "col4": "Администрация МР Бурзянский район РБ, 453580, Бурзянский район, с.Старосубхангулово, ул.Ленина, д.61 8(34755)35240, adm13@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 19,
        "col3": "Администрация МР Гафурийский район Республики Башкортостан (межведемственная комиссия)",
        "col4": "453050, Гафурийский район,\nс. Красноусольский, ул. Октябрьская, E-mail: adm14@bashkortostan.ru, тел.(34740)2-10-69"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 20,
        "col3": "Администрация МР Давлекановский район РБ",
        "col4": "453400, Республика Башкортостан, Давлекановский район, г. Давлеканово, ул. Красная Площадь, д. 9, adm49@bashkortostan.ru, 83476832525"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 21,
        "col3": "Администрация муниципального района Дуванский район Республики Башкортостан",
        "col4": "452530, Республика Башкортостан, Дуванский район, с. Месягутово, ул. И. Усова, д.6 adm15@bashkortostan.ru 83479832341"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 22,
        "col3": "Администрация муниципального района Дюртюлинский район (сектор по жилищным вопросам)",
        "col4": "452320, Республика Башкортостан, г.Дюртюли, ул. Чеверева, 41"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 23,
        "col3": "Администрация муниципального района Ермекеевский район Республики Башкортостан (отдел архитектуры и ЖКХ)",
        "col4": "452190, Ермекеевский район, с. Ермекеево, ул. Ленина, 15; 16.gkh@bashkortostan.ru; тел.: 83474122144"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 24,
        "col3": "Администрация муниципального района Зианчуринский район Республики Башкортостан",
        "col4": "453380, Зианчуринский район, с. Исянгулово, ул. Советская, 3; adm17@bashkortostan.ru; тел.: 83478521416"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 25,
        "col3": "Администрация МР Зилаирский район РБ (Отдел строительства и ЖКХ)",
        "col4": "453680, Республика Башкортостан, Зилаирский район, с. Зилаир, ул. Ленина, 70, adm18@bashkortostan.ru, 8(347552)2-23-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 26,
        "col3": "Администрация муниципального района Иглинский район Республики Башкортостан\n(отдел архитектуры и градостроительства)",
        "col4": "452410, Республика Башкортостан, Иглинский район, с. Иглино, ул. Свердлова, д.13 arhi_iglino@mail.ru тел. 8(43795)2-29-94"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 27,
        "col3": "Администрация муниципального района Илишевский район Республики Башкортостан",
        "col4": "452260, Республика Башкортостан, Илишевский район, с. Верхнеяркеево, ул. Красноармейская, д.35 adm20@bashkortostan.ru 83476251538"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 28,
        "col3": "Администрация муниципального района Ишимбайский район Республики Башкортостан",
        "col4": "453200, Республика Башкортостан, г. Ишимбай, пр. Лениная, д.60, adm51@bashkortostan.ru, 83479461830"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 29,
        "col3": "Администрация муниципального района Калтасинский район Республики Башкортостан (отдел опеки и попечительства)",
        "col4": "452860, Республика Башкортостан, Калтасинский район, с. Калтасы, ул. Карла Маркса, д. 82. kaltasyorgopeki@mail.ru, тел. 3477942224"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 30,
        "col3": "Сектор ЖКХ Администрации МР Караидельский район РБ",
        "col4": "452360, РБ, Караидельский район, с.Караидель, ул.Ленина, д.24, gilotdel.karaidel@mail.ru, 8(34744)2-12-44"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 31,
        "col3": "Администрация муниципального района Кармаскалинский район Республики Башкортостан( отдел по вопросам жизнеобеспечения, строительству, архитектуре, промышленности, траспорту)",
        "col4": "453020,Республика Башкортостан, Кармаскалинский район, с.Кармаскалы, ул.Садовая, 23, 23.glarh@bashkortostan.ru,83476522276"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 32,
        "col3": "Администрация муниципального района Кигинский район Республики Башкортостан",
        "col4": "452500, Республика Башкортостан, Кигинский район, с. Верхние Киги, ул. Салавата д. 2, adm24@bashkortostan.ru, 83474837171"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 33,
        "col3": "Администрация муниципального района Краснокамский район Республики Башкортостан",
        "col4": "452930, Республика Башкортостан, Краснокамский район, с. Николо- Березовка, ул. Карла Маркса, д.3,adm25@bashkortostan.ru,83475977600"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 34,
        "col3": "Администрации муниципального района Кугарчинский район Республики Башкортостан (отдел по вопросам жизнеобеспечения, строительству, архитектуре, промышленности, транспорту и связи)",
        "col4": "453330, Республика Башкортостан, Кугарчинский район, с.Мраково, ул. Ленина, д.15, adm26@bashkortostan.ru, 83478921059."
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 35,
        "col3": "Администрация муниципального района Кушнаренковский район Республики Башкортостан",
        "col4": "452230, РБ, Кушнаренковский район, с.Кушнаренково, ул.Октябрьская, 69, Zhilkom-kush@mail.ru, т. 8(34780) 57553"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 36,
        "col3": "Администрация МР Куюргазинский район РБ",
        "col4": "453360, Республика Башкортостан, Куюргазинский район, с.Ермолаево. пр-кт Мира, д. 10, тел. 83475761150, adm28@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 37,
        "col3": "Администрация муниципального района Мелеузовский район Республики Башкортостан / строительный отдел",
        "col4": "453850, Республика Башкортостан, г. Мелеуз, ул. Воровского, д. 11, e-mail: adm54@bashkortostan.ru, т. +7(34764) 3-14-42 / 453850, Республика Башкортостан, г. Мелеуз, ул. Воровского, д. 4, e-mail: so@meleuz.bashkortostan.ru? n/ +7(34764)3-19-50"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 38,
        "col3": "Администрация муниципального района Мечетлинский район Республики Башкортостан",
        "col4": "452550, Республика Башкортостан, Мечетлинский район, с. Большеустьикинское, ул. Ленина, д. 20, adm29@bashkortostan.ru 8(34770)2-02-99"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 39,
        "col3": "Администрация муниципального района Мишкинский район Республики Башкортостан",
        "col4": "452340, РБ, Мишкинский район, с.Мишкино, ул.Ленина, 89, adm30@bashkortostan.ru, т. 8(34749) 21801"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 40,
        "col3": "Администрация муниципального района Миякинский район РБ",
        "col4": "452080, Республика Башкортостан, Миякинский район, с. Киргиз-Мияки, ул. Ленина, д.26.adm31@bashkortostan.ru 8(34788)2-12-01, 2-13-78"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 41,
        "col3": "Администрация муниципального района Нуримановский район РБ",
        "col4": "452440, Республика Башкортостан, Нуримановский район, с. Красная Горка, ул. Советская, д.62, +7(34776)2-23-35,adm32@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 42,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 43,
        "col3": "Администрация муниципального района Стерлибашевский район РБ (отдел жизнеобеспечения АМР Стерлибашевский район РБ)",
        "col4": "453180, Стерлибашево, ул.Карла Маркса,97\nТел.2-20-31, факс 2-20-03, adm34@bashkortostan.ru\n"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 44,
        "col3": "Администрация муниципального района Стерлитамакский район Республики Башкортостан",
        "col4": "453124, Республика Башкортостан, г.Стерлитамак, ул. Карла Маркса, д. 118 adm35@bashkortostan.ru 8 3473 25 10 03"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 45,
        "col3": "Администрация муниципального района Татышлинский район РБ",
        "col4": "452830, РБ Татышлинский район, с. Верхние Татышлы, ул. Ленина, д.71 adm36@bashkortostan.ru 8(34778)2-11-56"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 46,
        "col3": "Межведомственная комиссия при Администрации городского поселения город Туймазы муниципального района Туймазинский район РБ",
        "col4": "452750, РБ, г. Туймазы, ул. Островского, д. 52, 60.gp@bashkortostan.ru тел./факс: 8(34782)5-29-22"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 47,
        "col3": "Администрация муниципального района Уфимский район Республики Башкортостан (межведомственная комиссия, назначенная постановлением администрации муниципального района Уфимский район Республики Башкортостан от 22.03.2024 № 338)",
        "col4": "г. Уфа, ул. Цюрупы, 6, (347)272-59-29\nSadykov.BT@bashkortostan.ru Республика Башкортостан, Уфимский район, с. Михайловка, ул. Ленина, д.46, mail@hozuprav.ru, (347) 270-23-05"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 48,
        "col3": "Администрация муниципального района Учалинский район Республики Башкортостан (жилищный отдел)",
        "col4": "453700, Республика Башкортостан, Учалинский район, г. Учалы, ул. К. Маркса, д. 20, 61.zhilish@bashkortostan.ru, тел.: 6(34791)6-21-90"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 49,
        "col3": "Администрация муниципального района Федоровский район Республики Башкортостан",
        "col4": "453280, Республика Башкортостан, Федоровский район, с. Федоровка, ул. Ленина, д.48, 38.abdrashitov.rt@bashkortostan.ru, 8 (34746) 2-73-92"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 50,
        "col3": "Администрация муниципального района Хайбуллинский район Республики Башкортостан",
        "col4": "453800, Республика Башкортостан, Хайбуллинский район, с. Акъяр, пр. С.Юлаева, д. 45, e-mail: 39.housing@bashkortostan.ru, тел.: +7(34758)2-14-08"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 51,
        "col3": "Администрация муниципального района Чекмагушевский район Республики Башкортостан",
        "col4": "452200 Республика Башкортостан, Чекмагушевский район, с. Чекмагуш, ул.Ленина, д.55, e-mail: 40.jilotdel2@bashkortostan.ru 8(34796)3-22-17"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 52,
        "col3": "Администрация муниципального района Чишминский район рБ",
        "col4": "452170, р.п.Чишмы, ул.Кирова, д.50, тел (834797) 2-12-22, adm41@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 53,
        "col3": "Администрации сельских поселений Шаранский район",
        "col4": "452630, РБ, Шаранский район, с.Шаран, ул. Пролетарская. д. 1 тел 34769 2-11-30, 2-23-11 adm42@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 54,
        "col3": "Администрация муниципального районаЯнаульский район(отдел по опеке ипопечительству)",
        "col4": "452800, РБ, г.Янаул, пер.Малышева, д.4Б, ms,opeka@bk,ru, тел.:8(34760)51824"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 55,
        "col3": "Администрация ГО г.Агидель РБ",
        "col4": "г.Агидель Цветочный бульвар,д.2 43.jil@bashkortostan.ru 28215"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 56,
        "col3": "Администрация городского округа город Кумертау Республики Башкортостан (Жилищный отдел)",
        "col4": "453300, Республика Башкортостан, г.Кумертау, ул.Ленина, д.18, \nadm52@bashkortostan.ru (52.house@bashkortostan.ru), 83476143861"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 57,
        "col3": "Администрация ЗАТО Межгорье Республики Башкортостан (Отдел по учету и распределению жилья Администрации ЗАТО Межгорье Республики Башкортостан)",
        "col4": "453571, Республика Башкортостан, г. Межгорье, ул. 40 лет Победы, д. 60, adm53@bashkoptostan.ru , тел. 8(34781)2-24-77, 8(34781)2-15-00"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 58,
        "col3": "Администрация городского округа город Нефтекамск Республики Башкортостан",
        "col4": "452680, г. Нефтекамск, пр-кт. Комсомольский, д. 25, тел. 8(34783)4-92-46, adm55@bashkortostan.ru; отдел опеки и попечительства - г. Нефтекамск, ул. Ленина, д. 13 А, тел. 8(34783)4-03-48, neft_opeka@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 59,
        "col3": "Управление земельно-имущественных отношений и жилищной политики адмнистрации ГО г.Октябрьский (УЗИО, жилищный отдел )",
        "col4": "452600, г. Октябрьский, ул. Чапаева, д. 13, тел. 8(34767)7-26-41, okt-komjilpol@yandex.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 60,
        "col3": "Администрация городского округа город Салават Республики Башкортостан (отдел строительства, транспорта и связи)",
        "col4": "453261, г. Салават, ул. Ленина, д. 2 , adm57@bashkortostan.ru тел. 8(3476)35-35-35"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 61,
        "col3": "Администрация г.о.г. Сибай(Отдел опеки и попечительства)",
        "col4": "453830,Республика Башкортостан,город Сибай,улица Ленина,д.12,каб.203;т.34775 2 35 53"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 62,
        "col3": "Администрация городского округа город Стерлитамак Республики Башкортостан",
        "col4": "453100, г.Стерлитамак, пр-кт Октября, д.32, adn59@bashkortostan.ru, 8(3473) 24-18-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 63,
        "col3": "Администрация городского округа город Уфа Республики Башкортостан (Межведомственная комиссия)",
        "col4": "450098, Республика Башкортостан, г. Уфа, проспект Октября, д. 120, mvkgoufa@mail.ru, 8(347)235-52-00"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 1,
        "col3": "Администрация Железнодорожного района г. Улан-Удэ",
        "col4": "670002, Республика Бурятия, г. Улан-Удэ, ул. Октябрьская, 2/ agdr@ulan-ude-eg.ru/ (8-3012) 44-85-25\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 2,
        "col3": "Администрация  Октябрьского района г. Улан-Удэ, ",
        "col4": "670047, Республика Бурятия, г. Улан-Удэ, ул. Павлова, 1/ aоr@ulan-ude-eg.ru/ (8-3012) 37-02-24\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 3,
        "col3": "Администрация Советского района г. Улан-Удэ",
        "col4": " 670000, Республика Бурятия, г. Улан-Удэ, ул. Советская, 23/ 670000, Республика Бурятия, г. Улан-Удэ, ул. Советская, 23/ asr@ulan-ude-eg.ru/ (8-3012) 21-56-46\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 4,
        "col3": "Администрация Баргузинского района",
        "col4": " 671610, Республика Бурятия, Баргузинский район, п. Баргузин, ул. Дзержинского, 24/ admbrg@icm.buryatia.ru/ (8-231) (8-30131) 4-13-22\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 5,
        "col3": "Администрация Баунтовского района",
        "col4": " 671510, Республика Бурятия, Баунтовский район, c. Багдарин, ул. Ленина, 22/ admbnt@icm.buryatia.ru/ (8-253) (8-30153) 4-13-22\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 6,
        "col3": "Администрация Бичурского района",
        "col4": " 671360, Республика Бурятия, Бичурский район, с. Бичура, ул. Советская, 43/ admbich@icm.buryatia.ru/ (8-233) (8-30133) 5-15-18\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 7,
        "col3": "Администрация Джидинского района",
        "col4": " 671920, Республика Бурятия, Джидинский район, с. Петропавловка, ул. Терешковой, 8/ admdzd@icm.buryatia.ru/ (8-234) (8-30134) 9-14-20\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 8,
        "col3": "Администрация Еравнинского района",
        "col4": " 672430, Республика Бурятия, Еравнинский район, с. Сосново-Озерское, ул. Первомайская, 113/ admeravna@icm.buryatia.ru/  (8-235) (8-30135) 2-14-45\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 9,
        "col3": "Администрация Заиграевского район",
        "col4": "Заиграевского района 671310, Республика Бурятия, Заиграевский район, п. Заиграево, ул. Октябрьская, 4/ admzgr@icm.buryatia.ru/ (8-236) (8-30136) 4-22-40\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 10,
        "col3": "Администрация Закаменского района",
        "col4": "671930, Республика Бурятия, Закаменский район, г. Закаменск, ул. Ленина, 17/ admzakam@icm.buryatia.ru/ (8-237) (8-30137) 4-44-53\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 11,
        "col3": "Администрация Иволгинского района",
        "col4": " 670045, Республика Бурятия, Иволгинский район, п. Иволгинск, ул. Ленина, 18а / admivl@icm.buryatia.ru/ (8-240) (8-30140) 2-13-51\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 12,
        "col3": "Администрация Кабанского района",
        "col4": "\n 671200, Республика Бурятия, Кабанский район, с. Кабанск, ул. Кирова, 10/ admkab@icm.buryatia.ru/ (8-238) (8-30138) 4-15-36\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 13,
        "col3": "Администрация Кижингинского района",
        "col4": " 671450, Республика Бурятия, Кижингинский район, с. Кижинга, ул. Коммунистическая, 14/ admkzn@icm.buryatia.ru/ (8-241) (8-30141) 3-26-47  "
    },
    {
        "col1": "Республика Бурятия",
        "col2": 14,
        "col3": "Администрация Курумканского района",
        "col4": " 671613, Республика Бурятия, Курумканский район, с. Курумкан, ул. Булдакова, 13/ admkrm@icm.buryatia.ru/ (8-249) (8-30149) 4-15-15\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 15,
        "col3": "Администрация Кяхтинского района",
        "col4": " 671840, Республика Бурятия, Кяхтинский район, г. Кяхта, ул. Ленина, 33/ admkht@icm.buryatia.ru/ (8-242) (8-30142) 9-14-78\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 16,
        "col3": "Администрация Муйского района",
        "col4": " 671517, Республика Бурятия, Муйский район, п. Таксимо, ул. Советская, 10/ admmsk@icm.buryatia.ru/ (8-239) (8-30139) 5-54-33\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 17,
        "col3": "Администрация Мухоршибирского района",
        "col4": " 671340, Республика Бурятия, Мухоршибирский район, с. Мухоршибирь, ул. Доржиева, 38/ admmhr@icm.buryatia.ru/ (8-243) (8-30143) 2-11-63\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 18,
        "col3": "Администрация Окинского района ",
        "col4": "671030, Республика Бурятия, Окинский район, с. Орлик, ул. Советская, 12/ admoka@icm.buryatia.ru  / (8-250) (8-30150) 5-11-64\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 19,
        "col3": "Администрация Прибайкальского района ",
        "col4": "671260, Республика Бурятия, Прибайкальский район, с. Турунтаево, ул. Комарова, 14/ admprb@icm.buryatia.ru/ (8-244) (8-30144) 5-11-63\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 20,
        "col3": "Администрация г. Северобайкальска",
        "col4": " 671701, Республика Бурятия, г. Северобайкальск, пр. Ленинградский, 7/ admsevbk@icm.buryatia.ru/  (8-239) (8-30139) 2-23-19\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 21,
        "col3": "Администрация Северобайкальского района ",
        "col4": "671710, Республика Бурятия, Северобайкальский район, п. Нижнеангарск, ул. Ленина, 119/ admnrd@icm.buryatia.ru/ (8-230) (8-30130) 4-74-48\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 22,
        "col3": "Администрация Селенгинского района",
        "col4": " 671160, Республика Бурятия, Селенгинский район, г. Гусиноозерск, ул. Пушкина, 12/ admsel@icm.buryatia.ru / (8-245) (8-30145) 4-12-81\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 23,
        "col3": "Администрация Тарбагатайского района",
        "col4": " 671112, Республика Бурятия, Тарбагатайский район, с. Тарбагатай, ул. Школьная, 1/ admtrb@icm.buryatia.ru/ (8-246) (8-30146) 5-53-67\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 24,
        "col3": "Администрация Тункинского района",
        "col4": " 670820, Республика Бурятия, Тункинский район, с. Кырен, ул. Ленина, 107/ admtnk@icm.buryatia.ru / (8-247) (8-30147) 9-15-39\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 25,
        "col3": "Адмиистрайия Хоринского района",
        "col4": " 671410, Республика Бурятия, Хоринский район, с. Хоринск, ул. Первомайская, 41/ admhrn@icm.buryatia.ru/ (8-248) (8-30148) 2-21-63  "
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 1,
        "col3": "Министерство строительства, архитектуры и жилищно-коммунального хозяйства Республики Дагестан,",
        "col4": "г. Махачкала пр. Шамиля, 58; minstroi@e-dag.ru + 7 (8722) 56-46-46, "
    },
    {
        "col1": "Республика Ингушетия",
        "col2": 1,
        "col3": "Минстрой Ингушетии",
        "col4": "(8734) 55-12-35; minstroyri@mail.ru; 386001 Республика Ингушетия  г. Магас, пр-т И. Зязикова, 10 а"
    },
    {
        "col1": "Республика Калмыкия",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Республика Карелия",
        "col2": 1,
        "col3": "Администрация Беломорского муниципального округа",
        "col4": "186500, г. Беломорск, ул. Ленинская, д. 9; (81437) 5-10-50 (приемная), e-mail: belkaradm@belomorsk-mo.ru; (81437) 5-15-84, e-mail: belopeka@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/belomorsk/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 2,
        "col3": "Администрация Калевальского национального муниципального района",
        "col4": "186910, Республика Карелия, пгт. Калевала, ул. Советская, д. 11; (81454) 4-11-05 (приемная); (81454) 4-18-77; e-mail: kalevadm@onego.ru; anna.kenieva@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/kaleval a/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 3,
        "col3": "Администрация Кемского муниципального района",
        "col4": "186610, г. Кемь, просп. Пролетарский, д. 30; (81458) 7-04-33 (приемная); e-mail: admikem@mail.ru; 8-964-317-80-17, (81458) 2-20-03, e-mail: soc.opeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/kem/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 4,
        "col3": "Администрация Кондопожского муниципального района",
        "col4": "186220, г. Кондопога, пл. Ленина, д. 1; (81451) 7-94-52 (приемная); e-mail: kondadm@kmr10.ru; (81451) 7-99-79, 8-964-317-83-58; paukova@kmr10.ru; https://gov.karelia.ru/power/local_govern ment/kondopoga/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 5,
        "col3": "Администрация Костомукшского городского округа",
        "col4": "186931, г. Костомукша ул. Строителей, д. 5; (81459) 5-10-10 (приемная); e-mail: adm-kos@msu.kostomuksha-rk.ru; 8-911-660-89-59, 8-911-660-15-68, 8-911-430-25-52 e-mail: opeka2kgo@list.ru; https://gov.karelia.ru/power/local_gov ernment/kostomuksha/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 6,
        "col3": "Администрация Лахденпохского муниципального района",
        "col4": "186730, г. Лахденпохья, ул. Советская, д.7-А; (81450) 4-54-98 (приемная), e-mail: amcylah@onego.ru; (81450) 46-3-92;’ e-mail: bratchikova-mn@lah-mr.ru; https://gov.karelia.ru/power/local_gov ernment/lah/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 7,
        "col3": "Администрация Лоухского муниципального района",
        "col4": "186660, п. Лоухи, ул. Советская, д. 27; (81439) 5-10-17 (приемная); e-mail: louhiadm@yandex.ru; (81439) 5-17-43, e-mail: opeca.louhi@mail.ru; https://gov.karelia.ru/power/local_gov ernment/louhiadm/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 8,
        "col3": "Администрация Медвежьегорского муниципального района",
        "col4": "186300, г. Медвежьегорск ул. Кирова, д. 7; (81434) 5-15-55 (приемная); e-mail: medgora.msu@onego.ru (81434) 5-26-80, 8-902-772-70-15, e-mail: medgora.opeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/medgora/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 9,
        "col3": "Администрация Муезерского муниципального района",
        "col4": "186960, п. Муезерский, ул. Октябрьская, д. 28; (81455) 3-36-30 (приемная); e-mail: mueadmin@inbox.ru; 8-921-526-60-80, (81455) 338-49, e-mail: mue.opeka@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/muezersky/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 10,
        "col3": "Администрация Олонецкого национального муниципального района",
        "col4": "186000, г. Олонец, ул. Свирских дивизий, д. 1; (81436) 4-15-06; e-mail: administr@onego.ru; 8-964-317-81-16; e-mail: olonopeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/olon/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 11,
        "col3": "Администрация Петрозаводского городского округа",
        "col4": "185001, г. Петрозаводск, просп. Ленина, д. 2; (8142) 71-33-00 (приемная), e-mail: adm@petrozavodsk-mo.ru; (8142) 71-35-52, 71-34-37, 71-34-38, 71-34-89; e-mail: opeka@petrozavodsk-mo.ru; https://gov.karelia.ru/power/local_gov ernment/petrozavodsk/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 12,
        "col3": "Администрация Питкярантского муниципального округа",
        "col4": "186810, г. Питкяранта, ул. Ленина, д. 43; (81433) 4-11-53 (приемная); e-mail: pitkaranta@onego.ru; 8-964-317-50-95; e-mail: opeka.apmr@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/pit/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 13,
        "col3": "Администрация Прионежского муниципального района",
        "col4": "185005, г. Петрозаводск, ул. Правды, д. 14, (8142) 57-81-68 (приемная), e-mail: adm@prionego.ru; 8-902-770-15-04, 8-902-770-12-45; e-mail: ivanova@prionego.ru; https://gov.karelia.ru/power/local_gov ernment/prione go/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 14,
        "col3": "Администрация Пряжинского национального муниципального района",
        "col4": "186601, п. Пряжа, ул. Советская, д. 61; (81456) 3-12-08 (приемная); e-mail: priagad@onego.ru; (81456) 3-21-86, e-mail: opekapr@bk.ru; https://gov.karelia.ru/power/local_gov ernment/pryazha/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 15,
        "col3": "Администрация Пудожского муниципального района",
        "col4": "186150, г. Пудож, ул. Ленина, д. 90; (81452) 5-17-33 (приемная); e-mail: pudogadm@pudogadm.ru; (81452) 5-10-31, e-mail: opeka.karelia@yandex.ru https://gov.karelia.ru/power/local_gov ernment/pudog/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 16,
        "col3": "Администрация Сегежского муниципального округа",
        "col4": "186420, г. Сегежа, ул. Ленина, д. 9-А; (81431) 4-24-24 (приемная), e-mail: ud1@segadmin.onego.ru; (81431) 4-25-74, e-mail: opeka-seg@yandex.ru https://gov.karelia.ru/power/local_gov ernment/seg/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 17,
        "col3": "Администрация Сортавальского муниципального округа",
        "col4": "186750, г. Сортавала, ул. Кирова, д. 11; (81430) 4-53-42 (приемная), e-mail: sort_org_otd@onego.ru; (81430) 4-81-85, e-mail: opeka.sortavala@mail.ru https://gov.karelia.ru/power/local_gov ernment/ sortavala/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 18,
        "col3": "Администрация Суоярвского муниципального округа",
        "col4": "186870, г. Суоярви, ул. Шельшакова, д. 6; (81457) 5-14-50 (приемная), e-mail: suodistrict@onego.ru; (81457) 5-18-17, 8-964-317-83-16, e-mail: opeka suo@mail.ru; https://gov.karelia.ru/power/local_gov ernment/ suoj arvi/"
    },
    {
        "col1": "Республика Коми",
        "col2": 1,
        "col3": "Управление жилищно-коммунального хозяйства муниципальное образование городского округа «Сыктывкар»",
        "col4": "ул. Бабушкина, д. 22,\n г. Сыктывкар,\n Республика Коми, 167000\n8 (8212) 245-232 "
    },
    {
        "col1": "Республика Коми",
        "col2": 2,
        "col3": "Муниципальный округ «Ухта» Республики Коми",
        "col4": "проезд Пионергорский, д. 2, г. Ухта,  Республика Коми, 169300\nkumiuht@mail.ru,\n8 (8216) 746341"
    },
    {
        "col1": "Республика Коми",
        "col2": 3,
        "col3": "Муниципальный округ «Воркута»",
        "col4": "пл. Центральная, д.7,\n г. Воркута, Республика Коми, 169900\njilotdelpechora@mail.ru,\n8 (82142) 72327 "
    },
    {
        "col1": "Республика Коми",
        "col2": 4,
        "col3": "Муниципальный округ «Инта» Республики Коми",
        "col4": "ул. Горького, д. 16, г. Инта,  Республика Коми, 169840, \nna.docis@inta.rkomi.ru,\n8 (82145) 62941"
    },
    {
        "col1": "Республика Коми",
        "col2": 5,
        "col3": "Муниципальное образование муниципального района «Печора»",
        "col4": "Печорский проспект, д. 46,\n г. Печора,  Республика Коми,169600,\njilotdelpechora@mail.ru,\n8 (82142) 72327                                     8 (82142) 73813"
    },
    {
        "col1": "Республика Коми",
        "col2": 6,
        "col3": "Муниципальный округ «Усинск» Республики Коми",
        "col4": "ул. Ленина, д. 13, г. Усинск, Республика Коми, 169710,\ni.s.kuzmina@usinsk.rkomi.ru,\n8 (82144) 28130 (доп. 136)\n"
    },
    {
        "col1": "Республика Коми",
        "col2": 7,
        "col3": "Муниципальный округ «Вуктыл» Республики Коми",
        "col4": " ул. Комсомольская д. 14, \nг. Вуктыл, Республика Коми, 169570,\nsocialpolitika@mail.ru,\n8 (82146) 22262 (доб.26)"
    },
    {
        "col1": "Республика Коми",
        "col2": 8,
        "col3": "Муниципальное образование муниципального района  «Сосногорск»",
        "col4": "ул. Куратова, д.2, г. Сосногорск, Республика Коми,   169500,\nkui.sosn@mail.ru,\n8 (82149) 54471"
    },
    {
        "col1": "Республика Коми",
        "col2": 9,
        "col3": "Муниципальный район Сыктывдинский Республики Коми",
        "col4": "ул. Домны Каликовой, д.62, село Выльгорт, Республика Коми,  168220, Сыктывдинский район, \ne.i.lytkina@syktyvdin.rkomi.ru,\n8 (82130) 72118"
    },
    {
        "col1": "Республика Коми",
        "col2": 10,
        "col3": "Муниципальное образование муниципального района «Сысольский»",
        "col4": "ул. Советская, д. 35, с. Визинга, Сысольский район,Республика Коми,  168100,\ngp@sysola.rkomi.ru,\n8 (82131) 91596 "
    },
    {
        "col1": "Республика Коми",
        "col2": 11,
        "col3": "Муниципальный район «Койгородский»",
        "col4": "Мира ул., д.7, с. Койгородок, Республика Коми,168170,\nremizova.valentinka@mail.ru,\n8 (82132) 91630"
    },
    {
        "col1": "Республика Коми",
        "col2": 12,
        "col3": "Муниципальный район \"Прилузский\" Республики Коми",
        "col4": "ул. Мира, д. 76, с. Объячево, Прилузский район,  Республика Коми,  168130,\nm.y.tugolukova@priluzie.rkomi.ru,\n8 (82133) 22561  "
    },
    {
        "col1": "Республика Коми",
        "col2": 13,
        "col3": "Муниципальный район «Корткеросский» Республики Коми",
        "col4": "ул. Советская, д. 225, село Корткерос, Корткеросский район, Республика Коми,   168020,\ngkhkortkeros@mail.ru,\n8(82136) 92495"
    },
    {
        "col1": "Республика Коми",
        "col2": 14,
        "col3": "Муниципальное образование муниципального района «Усть-Куломский»",
        "col4": "ул. Советская, д. 37, с. Усть-Кулом, Усть-Куломский р-н, Республика Коми, 168060\n\nosp.uk@yandex.ru\n\n8 8213 79 35 57"
    },
    {
        "col1": "Республика Коми",
        "col2": 15,
        "col3": "Муниципальное образование муниципального района «Троицко-Печорский»",
        "col4": "ул. Ленина, д. 2,  пгт Троицко-Печорск, Республика Коми, 169420,\notdel.zemel1@mail.ru,\n8 (82138) 97509 "
    },
    {
        "col1": "Республика Коми",
        "col2": 16,
        "col3": "Муниципальное образование муниципального района «Усть-Вымский»",
        "col4": "ул. Центральная, д. 112,\n  с. Айкино,Усть-Вымский р-н,   Республика Коми, 169040,                 ust-vym-adm@mail.ru\nadm.upravleniezhilishnoi@yandex.ru,\n8 (82134) 28213"
    },
    {
        "col1": "Республика Коми",
        "col2": 17,
        "col3": "Муниципальный округ «Княжпогостский»",
        "col4": "ул. Дзержинского, д.81,\n г. Емва,Республика Коми, 168200,\nuoemva@yandex.ru,\n8 (82139) 23325 "
    },
    {
        "col1": "Республика Коми",
        "col2": 18,
        "col3": "Муниципальное образование муниципального района «Удорский»",
        "col4": "ул. Центральная, д. 144, с. Кослан,Удорский район, Республика Коми,169240,\nzv@udora.rkomi.ru,\n8 (82135) 33034"
    },
    {
        "col1": "Республика Коми",
        "col2": 19,
        "col3": "Муниципальное образование муниципального района «Ижемский»",
        "col4": "ул. Советская, д. 45, с. Ижма,Ижемский район, Республика Коми,169460, \njkhiks@mail.ru,\n8 (82140) 94107 доб. 105  "
    },
    {
        "col1": "Республика Коми",
        "col2": 20,
        "col3": "Муниципальное образование муниципального района «Усть-Цилемский»",
        "col4": "ул. Новый квартал, д.11а, с. Усть-Цильма, Республика Коми, 169480,\nadmin@ust-cilma.rkomi.ru (резерв e.n.ostashova@ust-cilma.rkomi.ru),\n8 (82141) 91255"
    },
    {
        "col1": "Республика Крым",
        "col2": 1,
        "col3": "Министерство жилищной политики и государственного строительного надзора Республики Крым",
        "col4": "295013, Республика Крым, г. Симферополь, ул. Севастопольская, д. 45 mgsn@mgsn.rk.gov.ru\nТелефон +7(3652)60-58-99"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 1,
        "col3": "Администрация городского округа «Город Йошкар-Ола»",
        "col4": "424001, г. Йошкар-Ола, Ленинский проспект, 27 / admiola@i-ola.ru / (8362) 41-40-37"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 2,
        "col3": " Администрация городского округа \"Город Волжск\"",
        "col4": "425000, г. Волжск, Коммунистическая ул., д.1 / volzhsk.adm@mari-el.gov.ru / (83631) 6-22-58"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 3,
        "col3": "Администрация городского округа «Город Козьмодемьянск»",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / kozm.adm@mari-el.gov.ru / (83632) 7-12-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 4,
        "col3": "Администрация Волжского муниципального района",
        "col4": "425000, г. Волжск, ул. Советская, д. 29 / admrvolzsk@mail.ru / (83631) 6-35-75"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 5,
        "col3": "Администрация Горномарийского муниципального района",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / gorn.adm@mari-el.gov.ru; adm-gorn2006@yandex.ru / (83632) 7-11-53"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 6,
        "col3": "Администрация Звениговского муниципального района",
        "col4": "425060, г. Звенигово, ул. Ленина, д. 39 / zven.adm@mari-el.gov.ru / (83645) 7-17-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 7,
        "col3": "Килемарский муниципальный округ Республики Марий Эл",
        "col4": "425270, Килемарский район, пгт Килемары, ул. Садовая 55 / adm-kilemary@yandex.ru / (83643) 2-14-35"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 8,
        "col3": "Администрация Куженерского муниципального района",
        "col4": "425550, п. Куженер, ул. Кирова 14 / kuzhen.adm@mari-el.gov.ru / (83637) 9-15-80"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 9,
        "col3": "Администрация Мари-Турекского муниципального района",
        "col4": "425500, п. Мари-Турек, ул.Парковая, д. 7 / mturek.adm@mari-el.gov.ru / (83634) 9-71-00"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 10,
        "col3": "Администрация Медведевского муниципального района",
        "col4": "425200, Медведевский район, пгт Медведево, Советская ул., д.20 / MED_ADM@MAIL.RU / (8362) 58-28-50"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 11,
        "col3": "Администрация Моркинского муниципального района",
        "col4": "425120, пос. Морки, ул.Советская, 14 / morki.adm@mari-el.gov.ru / (83635) 9-16-12"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 12,
        "col3": "Администрация Новоторъяльского муниципального района",
        "col4": "425430, п. Новый Торъял, ул. Культуры, д.33 / admtoryal@mail.ru / (83636) 9-15-51"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 13,
        "col3": "Администрация Оршанского муниципального района",
        "col4": "425250, пгт. Оршанка, ул. Советская, 109 / orshan.adm@mari-el.gov.ru / (83641) 2-34-41"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 14,
        "col3": "Администрация Параньгинского муниципального района",
        "col4": "425570, Параньгинский район, пгт Параньга, ул. Новая, д. 4 / paranga.adm@mari-el.gov.ru / (83639)4-72-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 15,
        "col3": "Администрация Сернурского муниципального района",
        "col4": "425450, Сернурский район, пгт. Сернур, ул. Комсомольская, д. 10 / sernur12_adm@mail.ru / (83633) 9-73-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 16,
        "col3": "Администрация Советского муниципального района",
        "col4": "425400, пгт. Советский, ул. Свердлова, д. 8 / sovetsk_adm@mail.ru / (83638) 9-43-15"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 17,
        "col3": "Администрация Юринского муниципального района",
        "col4": "425370, Юринский район, пгт.Юрино, ул. Красная площадь, д.23 / yurino.adm@mari-el.gov.ru / (83644) 3-22-24"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 1,
        "col3": "Управление Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека по Республике Мордовия",
        "col4": " г. Саранск, ул. Дальняя, д. 7, тел. 8(8342)24-07-29, эл. адрес: sen@moris.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 1,
        "col3": "Муниципальный район «Абыйский улус (район)» ",
        "col4": " 678890, Республика Саха (Якутия), пгт. Белая Гора, ул. И.Н.Ефимова, д. 2\n+7 (411) 592-12-59 abijadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 2,
        "col3": "Муниципальный район «Алданский район» ",
        "col4": " 678901, Республика Саха (Якутия), г. Алдан, ул. Ленина, 19\n8(41145) 35-184  aldanray@rambler.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 3,
        "col3": "Муниципальный район «Аллаиховский улус (район)» ",
        "col4": "678800, Республика Саха (Якутия), Аллаиховский у., 1 поселок Чокурдах, ул. им.Ленина, д.8\n8(41158) 21-820  admglava@alla.sakha.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 4,
        "col3": "Муниципальный район «Анабарский национальный (долгано-эвенкийский) улус (район)» ",
        "col4": "678440, Республика Саха (Якутия), Анабарский улус, с. Саскылах, ул. Октябрьская, 10\n+7(4116) 821-458  anadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 5,
        "col3": "Муниципальный район «Амгинский улус (район)»",
        "col4": "678600, Республика Саха (Якутия), Амгинский улус, c. Амга, ул. Партизанская, 69\n+7(4114) 241-455  amga_adm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 6,
        "col3": "Муниципальный район «Булунский улус» ",
        "col4": "678400 Республика саха (Якутия), Булунский улус, п. Тикси, ул. Трусова, 6\n8(41167) 53-533  Org.bulun@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 7,
        "col3": "Муниципальный район «Верхневилюйский улус (район)» ",
        "col4": "678230, Республика Саха (Якутия), Верхневилюйский улус, с.Верхневилюйск, ул.Героя Васильева,2\n+7(4113) 341-237   admvervil@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 8,
        "col3": "Муниципальный район «Верхнеколымский улус (район)» ",
        "col4": "678770, Республика Саха (Якутия), Верхнеколымский улус, п.Зырянка, ул.Водников, д.7\n+7 (411) 552-56-63  vkoladm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 9,
        "col3": "Муниципальное образование «Верхоянский район» ",
        "col4": "678500, Республика Саха (Якутия), Верхоянский улус, п.Батагай, ул.Ленина, д.15\n8(41165) 21005  verhadm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 10,
        "col3": "Муниципальный район «Вилюйский улус (район)»  ",
        "col4": "678200, Республика Саха (Якутия), Вилюйский улус, г.Вилюйск, ул.Ленина, д. 49\n8(41132) 42-435  viluadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 11,
        "col3": "Муниципальный район «Горный улус»  ",
        "col4": "678030 Республика Саха (Якутия), Горный улус, с.Бердигестях, ул. Ленина д.8\n+7(4113) 141-184  gornay_admin@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 12,
        "col3": "Муниципальный район «Жиганский национальный эвенкийский район» ",
        "col4": "678330, Республика Саха (Якутия), Жиганский район, с. Жиганск, улица Аммосова, дом 28\n+7(4116) 421-937  zhiganskadm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 13,
        "col3": "Муниципальный район «Кобяйский улус (район)»",
        "col4": "678300, Республика Саха (Якутия), Кобяйский улус (район), п.Сангар, ул.Ленина, д. 55\n8 (41163) 22-184   amokobylys@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 14,
        "col3": "Муниципальное образование «Ленский район» ",
        "col4": "678144, Республика Саха (Якутия), Ленский район, г.Ленск, ул.Ленина, д. 65\n+7(4113) 742-304  admin@lenskrayon.ru, amolenskiyrayon@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 15,
        "col3": "Муниципальный район «Мегино-Кангаласский улус» ",
        "col4": "678080, Республика Саха (Якутия), Мегино-Кангаласский улус, п. Нижний Бестях, ул. Ленина, 36, корпус 2\n+7(4114) 347-895  adm-mk@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 16,
        "col3": "Муниципальный район «Мирнинский район» ",
        "col4": "678175, Республика Саха (Якутия), Мирнинский улус., г.Мирный, ул.Ленина, д.19\n8(41136) 36-133  kpu-mirny@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 17,
        "col3": "Муниципальное образование «Момский район»",
        "col4": "678860 Республика Саха (Якутия) Момский район, с.Хонуу ул.Молодежная 39\n8(41150) 21028  momadmin@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 18,
        "col3": "Муниципальное образование «Намский улус» ",
        "col4": "678380, Республика Саха (Якутия), Намский улус,  с.Намцы, ул.Октябрьская, д. 1 \n8(4112) 21-184  namsadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 19,
        "col3": "Муниципальный район «Нерюнгринский район» ",
        "col4": "678960, Республика Саха (Якутия),  г.Нерюнгри, пр.Дружбы народов, 21\n+7 (41147) 4-16-40  cityhall@neruadmin.ru "
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 20,
        "col3": "Муниципальный район «Нижнеколымский район» ",
        "col4": "678830 Республика Саха (Якутия), Нижнеколымский район, п.Черский, ул.Ойунского, д. 1\n+7(4115) 722-620  admnkol@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 21,
        "col3": "Муниципальный район «Нюрбинский район» ",
        "col4": "678450, Республика Саха (Якутия), Нюрбинский район, г.Нюрба, ул.Ленина, 31\n+7(4113) 423-553  nurbadm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 22,
        "col3": "Муниципальный район «Оймяконский улус» ",
        "col4": " 678730, Республика Саха (Якутия), пгт. Усть-Нера, ул. Ленина, д. 2, к. А\n+7 (41154) 2-13-00  ojmaadm@inbox.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 23,
        "col3": "Муниципальный район «Олекминский район» Республики Саха (Якутия)",
        "col4": "678100, Республика Саха (Якутия), Олекминский район, г. Олекминск, ул. Молодежная, 10\n+7(41138) 41-460   olekadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 24,
        "col3": "Муниципальный район «Оленекский эвенкийский национальный район» ",
        "col4": " 678480, Республика Саха (Якутия), с. Оленек, ул. Октябрьская, д. 24\n+7 (411) 692-31-82  olenekskiy-rayon.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 25,
        "col3": "Муниципальный район «Среднеколымский улус (район)» ",
        "col4": "678790, Республика Саха (Якутия) Среднеколымский улус (район), г.Среднеколымск, ул.Ярославского, дом 11\n+7(4115) 641-152  skoladm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 26,
        "col3": "Муниципальный район «Сунтарский улус (район)» ",
        "col4": "678290, Сунтарский улус, село Сунтар, ул. Ленина, д. 26\n+7(41135) 22-018  Adm_suntar@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 27,
        "col3": "Муниципальный район «Таттинский улус» ",
        "col4": "678650 Республика Саха (Якутия), Таттинский улус (район), с. Ытык-Кюель, ул. Ленина, 17\n+7(4115) 241-339  5241339@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 28,
        "col3": "Муниципальный район «Томпонский район» ",
        "col4": "678720, Респ. Саха, п. Хандыга, ул. Е.Д.Кычкина, д.1\n+7(4115) 341-880  mash_buro_tompo@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 29,
        "col3": "Муниципальный район «Усть-Алданский улус (район)» Республики Саха (Якутия)",
        "col4": "678350 Республика Саха (Якутия) МО \"Усть-Алданский улус (район)\" с. Борогонцы, ул. Ленина, 31\n+7(411) 614-3184  ualdadm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 30,
        "col3": "Муниципальный район «Усть-Майский улус (район)» Республики Саха (Якутия)",
        "col4": "678620, РЕСПУБЛИКА САХА (ЯКУТИЯ) , УСТЬ-МАЙСКИЙ УЛУС, П. УСТЬ-МАЯ, УЛ. ГОРЬКОГО, 41\n8(41141) 4-27-35  ustmaya-msu@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 31,
        "col3": "Муниципальный район «Усть-Янский улус (район)» Республики Саха (Якутия)",
        "col4": "678540, Республика Саха (Якутия), Усть-Янский у., пгт Депутатский, мкр Арктика, д. 28\n8(41166) 28408   jur_sluzhba@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 32,
        "col3": "Муниципальный район «Хангаласский улус» Республики Саха (Якутия)",
        "col4": "678000, Республика Саха (Якутия), Хангаласский улус г.Покровск, ул. Орджоникидзе, 26\n+7(411) 444-1157   adm@xangalas.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 33,
        "col3": "муниципальный район «Чурапчинский улус» Республики Саха (Якутия)",
        "col4": "678670, ЧУРАПЧИНСКИЙ УЛУС, с. Чурапча, ул. Ленина, 41 \n+7(4115) 141-184  admchurapcha@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 34,
        "col3": "Муниципальный район «Эвено-Бытантайский национальный улус (район)» Республики Саха (Якутия)",
        "col4": "678580, ЭВЕНО-БЫТАНТАЙСКИЙ НАЦИОНАЛЬНЫЙ УЛУС, С БАТАГАЙ-АЛЫТА, \n+7 (41126) 2-10-25  evenadm@mail.ru\n"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 35,
        "col3": "Муниципальное образование Городской округ «Жатай» Республики Саха (Якутия)",
        "col4": "677902, п. Жатай, Республика Саха (Якутия), ул. Северная, д. 29\n8 (411) 242-65-08  gojatay@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 36,
        "col3": "городской округ «город Якутск»",
        "col4": " 677000, г. Якутск, пр. Ленина, 15 \n40-80-76, 40-80-74   yakutsk@yakadm.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 1,
        "col3": "Министерство труда и социального развития Республики Северная Осетия-Алания ",
        "col4": "РСО-Алания, 372025, г.Владикавказ, ул. Бутырина, 29 Отдел по вопросам опеки и попечительства, тел.: 8-8672-534984, 8-8672-535822. Emai: opeka@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Татарстан",
        "col2": 1,
        "col3": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Mon@tatar.ru, 8843 294-95-66",
        "col4": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Svetlana.Begunova@tatar.ru, 8843 294-95-67, С.Г.Бегунова"
    },
    {
        "col1": "Республика Тыва",
        "col2": 1,
        "col3": "Министерство образования Республики Тыва",
        "col4": "Республика Тыва, \nг. Кызыл, ул. Ленина, 39,  \nopeka.2022@mail.ru\nраб. номер \n8 (39422) 3-00-21\n"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 1,
        "col3": "Администрация города Абакана",
        "col4": "ул. Щетинкина, д. 10, г. Абакан, 655017, тел.: 8 (3902) 223-791, e-mail: abakanadm@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 2,
        "col3": "Администрация города Абазы",
        "col4": "ул. Ленина, д. 20а, г. Абаза, 655750, тел.: 8 (39047) 2-30-61, e-mail: abm-abaza@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 3,
        "col3": "Администрация города Саяногорска",
        "col4": "микр., Советский, д. 1, г. Саяногорск, 655602, тел.: 8 (39042) 2-02-00, e-mail: sayanogorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 4,
        "col3": "Администрация города Сорска",
        "col4": "ул. Кирова, д. 3, г. Сорск, 655111, тел.: 8 (39033) 2-43-55, e-mail: aosorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 5,
        "col3": "Администрация города Черногорска",
        "col4": "ул. Советская, д. 66, г. Черногорск, 655154, тел.: 8 (39031) 2-25-72, e-mail: adm.ch.22572@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 6,
        "col3": "Администрация Алтайского района",
        "col4": "ул. Ленина, д. 74, с. Белый Яр, Алтайский район,  тел.: 8 (39041) 2-12-53, e-mail: altay@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 7,
        "col3": "Администрация Аскизского района",
        "col4": "ул. Суворова, д. 2, с. Аскиз, Аскизский район, 655700,  тел.: 8 (39045) 9-13-31, e-mail: mo-askiz@mail.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 8,
        "col3": "Администрация Бейского района",
        "col4": "ул. Площадь Советов, д. 20, с. Бея, Бейский район, 655770,  тел.: 8 (39044) 3-20-00, e-mail: mo-beya@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 9,
        "col3": "Администрация Боградского района",
        "col4": "ул. Новая, д. 10, с. Боград, Боградский район, 655340,  тел.: 8 (39034) 9-12-56, e-mail: admbograd@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 10,
        "col3": "Администрация Орджоникидзевского района",
        "col4": "ул. Кирова, д. 16, с. Копьево, Орджоникидзевский район, 655250,  тел.: 8 (39036) 2-17-04, e-mail: amo-ordgo@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 11,
        "col3": "Администрция Таштыпского района",
        "col4": "ул. Ленина, д. 35, с. Таштып, Таштыпский район, 655740,  тел.: 8 (39046) 2-14-42, e-mail: amotash@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 12,
        "col3": "Администрация Усть-Абаканского района",
        "col4": "ул. Рабочая, д. 9, с. Усть-Абакан, Усть-Абаканский район, 655100,  тел.: 8 (39032) 2-16-56, e-mail: oo_ua@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 13,
        "col3": "Администрацитя Ширинского района",
        "col4": "ул. Октябрьская, д. 79, с. Шира, Ширинский район, 655200,  тел.: 8 (39035) 9-12-10, e-mail: adminshira@r-19.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 1,
        "col3": "Администрация Азовского района  (Отдел архитектуры и градостроительства)",
        "col4": "346780, Россия, Ростовская область, г. Азов, ул. Московская, д. 58, +7(86342)4-05- 81   aar-doc@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 2,
        "col3": "Администрация Аксайского района",
        "col4": "346720, г. Аксай, ул. Карла Либкнехта,132    region@aksayland.ru  "
    },
    {
        "col1": "Ростовская область",
        "col2": 3,
        "col3": "Администрация Багаевского района",
        "col4": "346610 Ростовская область, Багаевский район, ст.Багаевская, ул.Подройкина, д.10, тел. 8(86357) 33-2-44,  е-mail: bagadm@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 4,
        "col3": "Межведомственная комиссия при Администрации Белокалитвинского района  по обследованию объектов капитального строительства",
        "col4": "347042, Ростовская область, г. Белая Калитва, ул. Чернышевского, д. 8, ops-bk@yandex.ru, 8(86383)2-22-02"
    },
    {
        "col1": "Ростовская область",
        "col2": 5,
        "col3": "Администрация Боковского района",
        "col4": "346250 Ростовская область, Боковский район, станица Боковская, переулок Теличенко, 32/ bokovsk@donland.ru/ 8(86382) 3-14-53 "
    },
    {
        "col1": "Ростовская область",
        "col2": 6,
        "col3": "Администрация Верхнедонского района ",
        "col4": "346170, ул. Матросова, д. 12, ст-ца Казанская, Верхнедонской район, Ростовская область, kazanka@donland.ru 88636431262"
    },
    {
        "col1": "Ростовская область",
        "col2": 7,
        "col3": "Администрация Веселовского района (Сектор строительства, газо-электроснабжения, транспорта и связи Администрации Веселовского района)",
        "col4": "347781, Ростовская область, Веселовский район, п. Веселый, пер. Комсомольский, д.61 E-mail: str-otd.204@yandex.ru 8(86358)6-12-37"
    },
    {
        "col1": "Ростовская область",
        "col2": 8,
        "col3": "Администрация Волгодонского района",
        "col4": "347350, Ростовская область, Волгодонской район, ст. Романовская, ул. Почтовая, 13, телефон 88639470130, электронная почта: volgraion@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 9,
        "col3": "Администрация Дубовского района Ростовская область",
        "col4": "347410, Ростовская область, Дубовский район, с. Дубовское, пл. Павших Борцов,1, Adm_dub@donland.ru, 8(86377)2-02-70 доб. 129"
    },
    {
        "col1": "Ростовская область",
        "col2": 10,
        "col3": "Администрация Егорлыкского райрна Ростовской области",
        "col4": "347661, Ростовская область, Егорлыкский район, ст. Егорлыкская, ул. Мира, 90, admegorlyk@donland.ru +7(86370)21-3-15"
    },
    {
        "col1": "Ростовская область",
        "col2": 11,
        "col3": "Администрация Заветинского района",
        "col4": "347430 Ростовская область, Заветинский район,село Заветное, ул. Ломоносова, д.24,  zavetnoe61@donland.ru, +7 (86378) 2-14-00"
    },
    {
        "col1": "Ростовская область",
        "col2": 12,
        "col3": "Администрация Зерноградского района  (Сектор архитектуры и территориального развития Администрации Зерноградского района)",
        "col4": "347740, Ростовская область, г. Зерноград, ул. Мира, 16, zernoland@donland.ru, 886359 41-5-76"
    },
    {
        "col1": "Ростовская область",
        "col2": 13,
        "col3": "Администрация Зимовниковского района",
        "col4": "ул. Ленина, 114, п. Зимовники, Ростовская область, 347460 adm@zima.donpac.ru 8(86376)3-16-98"
    },
    {
        "col1": "Ростовская область",
        "col2": 14,
        "col3": "Администрация Кагальницкого района",
        "col4": "347700, Ростовская обл., Кагальницкий р-он., ст. Кагальницкая, ул. Калинина, д. 101, тел.: 8(86345)96-1-40, kaglobsh@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 15,
        "col3": "Отдел образования Администрации Каменского района ",
        "col4": " Ростовская область, Каменский район, рп. Глубокий ул. Ленина, д. 38,      glubray@mail.ru, 8(86365)-95-2-54; "
    },
    {
        "col1": "Ростовская область",
        "col2": 16,
        "col3": "Администрация Кашарского района (Отдел по вопросам муниципального хозяйтва)",
        "col4": "346200, Ростовская область, Кашарский район, сл. Кашары, ул. Ленина, д. 58, эл.почта:mhkashary@donland.ru, тел: 8-863-88-2-14-59"
    },
    {
        "col1": "Ростовская область",
        "col2": 17,
        "col3": "Администрация Константиновского района",
        "col4": "347250 Ростовская область, г. Константиновск, ул. 25 Октября 70, адрес электронной почты:  adm_konst@donland.ru. Номер телефона приемной Главы Администрации (863 93) 2-16-62"
    },
    {
        "col1": "Ростовская область",
        "col2": 18,
        "col3": "Управление земельно-имущественных отношений и муниципального заказа Красносулинского района ",
        "col4": "Ростовская область, г. Красный Сулин, ул. Победы 4,  uzioimzks@yandex.ru, 8(863)67-5-24-37"
    },
    {
        "col1": "Ростовская область",
        "col2": 19,
        "col3": "Администрация Куйбышевского района  Ростовской области",
        "col4": "346940 Ростовская область, Куйбышевский район,  село Куйбышево, улица Куйбышевская, 24, adkura@ donland.ru\nтел: 31-7-07\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 20,
        "col3": "Администрация Мартыновского района Ростовской области",
        "col4": "346660, Ростовская область, Мартыновский район, сл. Большая Мартыновка, ул. Советская, дом № 61/adm@martadmin.ru/88639521550; 88639521035 "
    },
    {
        "col1": "Ростовская область",
        "col2": 21,
        "col3": "Администрация Матвеево-Курганского района ",
        "col4": "346970, Ростовская область, Матвеево-Курганский район, п. Матвеев Курган, ул. 1-я Пятилетка, 108, matveevkurgan@donland.ru, тел. 8(86341) 31639, 8(86341)31839"
    },
    {
        "col1": "Ростовская область",
        "col2": 22,
        "col3": "Администрация \nМиллеровского района",
        "col4": "Ленина ул., д. 6, г. Миллерово, \nРостовская обл., 346130\nТел.: +7 (86385) 2-68-69,\nфакс: +7 (86385) 2-68-63\nE-mail: admil@donland.ru\nhttps://millerovo.donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 23,
        "col3": "Администрация Милютинского района",
        "col4": "347120, Ростовская область, ст.Милютинская, ул.Павших Героев, 46, miladm@donland.ru,  8-863-89-2-14-00 "
    },
    {
        "col1": "Ростовская область",
        "col2": 24,
        "col3": "отдела ЖКХ, энергетики, строительства, транспорта, жилищных программ Администрации Морозовского района",
        "col4": "347210,Ростовская область, г.Морозовск, ул.Ленина, д.204, adm37@morozov.donpac.ru 88638450472"
    },
    {
        "col1": "Ростовская область",
        "col2": 25,
        "col3": "Администрация Мясниковского района",
        "col4": "346800 Ростовская область, Мясниковский район,с. Чалтырь, ул. Ленина, 33/org.otdel26@mail.ru/88634922502"
    },
    {
        "col1": "Ростовская область",
        "col2": 26,
        "col3": "Межведомственная комиссия по признанию помещенияжилым помещением, жилого помещения пригодным (непригодным)для проживания граждан и многоквартирного дома аварийными подлежащим сносу или реконструкции",
        "col4": "Ростовская область, Неклиновский район, с. Покровское, пер. Парковый, 1/neklradm@yandex.ru/8(863 47) 2-12-42"
    },
    {
        "col1": "Ростовская область",
        "col2": 27,
        "col3": "Отдел муниципального хозяйства и градостроительства",
        "col4": "347140, Ростовская область, Обливский район, ст. Обливская, ул. Ленина, д. 61, obliwadm @mail.ru, 8(86396)21-0-01"
    },
    {
        "col1": "Ростовская область",
        "col2": 28,
        "col3": "Сектор по организации строительства и создания условий для жилищного строительства Администрации Октябрьского района",
        "col4": "346480, Ростовская область, Октябрьский район, р.п.Каменоломни, ул. 40-лет Октября, д. 80. Электронный адрес: gilstroi_okt@mail.ru. Тел. 8 (86360) 2-01-13"
    },
    {
        "col1": "Ростовская область",
        "col2": 29,
        "col3": "Администрация Орловского района ",
        "col4": "347510, Ростовская обл,Орловсий р-н., п.Орловский, ул.Пионерская, д.75,    orlovkastroy@yandex.ru, (886375) 31-4-80"
    },
    {
        "col1": "Ростовская область",
        "col2": 30,
        "col3": "Администрация Песчанокопского района Ростовской области",
        "col4": "347570, Ростовская область, Песчанокопский район, с. Песчанокопское, ул. Суворова,4 admin273@donland.ru, +7(86373)9-13-98"
    },
    {
        "col1": "Ростовская область",
        "col2": 31,
        "col3": "Администрация Пролетарского района Ростовская область",
        "col4": "347540, Ростовская область, г.Пролетарск, ул. Пионерска, д.120 А, vovenkolybov@yandex.ru 88637499019"
    },
    {
        "col1": "Ростовская область",
        "col2": 32,
        "col3": "Отдел координации отраслей ЖКХ, строительства, транспорта и связи Администрации Ремонтненского района",
        "col4": "347480,Ростовская область, Ремонтненский район, с.Ремонтное, ул.Ленинская, 69, +7(86379)31-3-64,arh_31364@mail.ru "
    },
    {
        "col1": "Ростовская область",
        "col2": 33,
        "col3": "Отдел архитектуры Администрации Родионово-Несветайского района",
        "col4": "346580, Ростовская обл., Родионово-Несветайский          р-н, сл. Родионово-Несветайская, ул. Пушкинская, 34,                    эл. почта adminrod61@donland.ru        8(86340)30-9-41"
    },
    {
        "col1": "Ростовская область",
        "col2": 34,
        "col3": "Отдел строительства и капитального ремонта Администрации Сальского района",
        "col4": "Ростовская область, г. Сальск, ул. Ленина, 22;                                                adm-otdel401@yandex.ru    886372 5-26-08   "
    },
    {
        "col1": "Ростовская область",
        "col2": 35,
        "col3": "Администрация Семикаракорского района Ростовской области",
        "col4": "3466030, Ростовская область,  г.Семикаракорск, проспект В.А. Закруткина,79,  roo@semikar.donpac.ru, 86356 4-11-82"
    },
    {
        "col1": "Ростовская область",
        "col2": 36,
        "col3": "Администрация Советского района",
        "col4": "347180, Ростовская область, Советский район, ст. Советская, ул. Орджоникидзе, д. 14, sov_adm.ro@mail.ru,  тел. 88636323200"
    },
    {
        "col1": "Ростовская область",
        "col2": 37,
        "col3": "Cектор по организации \nкапитального строительства \nи координации работ отраслей \nпромышленности, ЖКХ, \nтранспорта и связи \nАдминистрации \nТарасовского района",
        "col4": "346050 Ростовская область\n Тарасовский район \nп. Тарасовский \nпер. Почтовый дом 5 \n 32004@mail.ru\n 8 (863 86) 32-0-04"
    },
    {
        "col1": "Ростовская область",
        "col2": 38,
        "col3": "Комиссии по проверке условий жизни лиц из числа детей-сирот и детей, оставшихся без попечения родителей, граждан ранее относившихся к категории лиц из числа детей-сирот и детей, оставшихся без попечения родителей",
        "col4": "347060, Ростовская область, Тацинский район, станица Тацинская, ул. Ленина, д.43 +7 (86397) 2-12-97 admtacina@tacina.donpac.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 39,
        "col3": "Администрация Усть-Донецкого района",
        "col4": "346550, Ростовская область, Усть-Донецкий район, р.п. Усть-Донецкий, ул. Ленина, д.11, ustland@donland.ru, 8(86351)-9-11-57"
    },
    {
        "col1": "Ростовская область",
        "col2": 40,
        "col3": "Сектор координации работы жилищно-коммунального хозяйства, благоустройства, топливно-энергетических ресурсов и жилищной политики  Администрации Целинского района, Комиссия по обследованию жилых помещений приобретаемых, для детей-сирот и детей, оставшихся без попечения родителей, лиц из числа детей-сирот и детей, оставшихся без попечения родителей.",
        "col4": "347760, Ростовская область, Целинский район, п.Целина, ул.2-я линия, 105,   адрес электронной почты celinaraion@donland.ru,       k91987@yandex.ru               тел.8 (86371) 9 19 89"
    },
    {
        "col1": "Ростовская область",
        "col2": 41,
        "col3": " Администрация Цимлянского района",
        "col4": "347320 Ростовская область, Цимлянский район, г. Цимлянск, ул. Ленина, 24. arh_sektor@bk.ru, тел. 8(86391)5-03-72"
    },
    {
        "col1": "Ростовская область",
        "col2": 42,
        "col3": "Администрация Чертковского района",
        "col4": "346000, Ростовская область, Чертковский р-н, п.Чертково, ул.Петровского, д.115/ra-217@donland.ru/(86387)21722"
    },
    {
        "col1": "Ростовская область",
        "col2": 43,
        "col3": " Администрация Шолоховского района Ростовской области",
        "col4": "Ростовская облать, Шолоховский район, ст. Вешенская, ул. Шолохова, 54, ra-211@veshki.donpac.ru, 88635322776 "
    },
    {
        "col1": "Ростовская область",
        "col2": 44,
        "col3": "Отдел по строительству и архитектуре Администрации города Азова",
        "col4": "Ростовская область, город Азов, ул. Московская, 19 / azovarh@yandex.ru / +7 (86342) 4-19-62"
    },
    {
        "col1": "Ростовская область",
        "col2": 45,
        "col3": "Администрация города Батайска",
        "col4": "346880, Ростовская область, город Батайск, пл. Ленина, 3,       тел. (886354) 5-06-88,                      эл. Otris@bk.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 46,
        "col3": "Жилищный отдел МКУ \"Департамент строительства\"",
        "col4": "Ленина, ул.д.95, г.Волгодонск, Ростовская область, 347360, т/факс (8639)21-20-54/ 21-24-70, адрес электронной почты: ds@vlgd61.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 47,
        "col3": "Муниципальное казенное учреждение города Гуково \"Управление Капитального строительства и жилищно-коммунального хозяйства\" ",
        "col4": "347871 Ростовская область, г. Гуково, ул. Мира, д.42,     mku61@donland.ru, 8(86361)5-28-95"
    },
    {
        "col1": "Ростовская область",
        "col2": 48,
        "col3": "Администрация города Донецка",
        "col4": "346330, Ростовская область, г.Донецк, пр-кт Мира, 39   adm-don@donland.ru  +7(86368) 2-33-15"
    },
    {
        "col1": "Ростовская область",
        "col2": 49,
        "col3": "Отдел имущественных и земельных отношений Администрации города Зверево",
        "col4": "346311 Ростовская область, г. Зверево, ул. Обухова ,д. 14 oizogzverevo@yandex.ru 88635543309"
    },
    {
        "col1": "Ростовская область",
        "col2": 50,
        "col3": "Администрация город Каменск-Шахтинский",
        "col4": "347800, Ростовская область, г. Каменск-Шахтинский, ул. Ленина 74 E-mail: abmkam@donland.ru 8 (86365) 7-49-63"
    },
    {
        "col1": "Ростовская область",
        "col2": 51,
        "col3": "Департамент строительства и городского развития Администрации города",
        "col4": "346400, Ротовская область, г. Новочеркассск, ул. Дворцовая, д. 8. Эл. почта: mkudsigr@mail.ru, тел. 8(86352)53169"
    },
    {
        "col1": "Ростовская область",
        "col2": 52,
        "col3": "Управление образования Администрации города Новошахтинска",
        "col4": " 346918, Ростовская область, г. Нвошахтинск, ул. Советская, 21, oo_novoshakhtinsk@rostobr.ru, 8 (86369) 2-05-68"
    },
    {
        "col1": "Ростовская область",
        "col2": 53,
        "col3": "Администрация города Таганрога\n",
        "col4": "347900, Ростовская область, г. Таганрог, ул. Петровская, д. 73 / adm@tagancity.ru /  +7 (8634) 312-800"
    },
    {
        "col1": "Ростовская область",
        "col2": 54,
        "col3": "Управление реализации\nбюджетных программ\nв сфере жилищного строительства\nАдминистрации города Шахты\n",
        "col4": "346500, Ростовская область, г.Шахты,     пр-кт.Карла Маркса, 67,          urbp@shakhty-gorod.ru,           8 (8636) 22-14-73"
    },
    {
        "col1": "Ростовская область",
        "col2": 55,
        "col3": "Департамент жилищно-коммунального хозяйства и энергетики города Ростова-на-Дону ",
        "col4": "344007 г. Ростов-на-Дону, ул. Социалистическая, 77           8(863) 240-35-02          adm.dgkhe@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 56,
        "col3": "Департамент жилищно-коммунального хозяйства и энергетики города Ростова-на-Дону ",
        "col4": "344007 г. Ростов-на-Дону, ул. Социалистическая, 77           8(863) 240-35-02          adm.dgkhe@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 57,
        "col3": "Департамент жилищно-коммунального хозяйства и энергетики города Ростова-на-Дону ",
        "col4": "344007 г. Ростов-на-Дону, ул. Социалистическая, 77           8(863) 240-35-02          adm.dgkhe@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 58,
        "col3": "Департамент жилищно-коммунального хозяйства и энергетики города Ростова-на-Дону ",
        "col4": "344007 г. Ростов-на-Дону, ул. Социалистическая, 77           8(863) 240-35-02          adm.dgkhe@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 59,
        "col3": "Департамент жилищно-коммунального хозяйства и энергетики города Ростова-на-Дону ",
        "col4": "344007 г. Ростов-на-Дону, ул. Социалистическая, 77           8(863) 240-35-02          adm.dgkhe@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 60,
        "col3": "Департамент жилищно-коммунального хозяйства и энергетики города Ростова-на-Дону ",
        "col4": "344007 г. Ростов-на-Дону, ул. Социалистическая, 77           8(863) 240-35-02          adm.dgkhe@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 61,
        "col3": "Департамент жилищно-коммунального хозяйства и энергетики города Ростова-на-Дону ",
        "col4": "344007 г. Ростов-на-Дону, ул. Социалистическая, 77           8(863) 240-35-02          adm.dgkhe@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 62,
        "col3": "Департамент жилищно-коммунального хозяйства и энергетики города Ростова-на-Дону ",
        "col4": "344007 г. Ростов-на-Дону, ул. Социалистическая, 77           8(863) 240-35-02          adm.dgkhe@mail.ru"
    },
    {
        "col1": "Рязанская область",
        "col2": 1,
        "col3": "Администрация Александро-Невского муниципального района Рязанской области",
        "col4": "391240, Рязанская область, р.п. Александро-Невский, ул. Советская, д. 9; aleksandro-nevskij.r@ryazan.gov.ru, +7 (491-58) 2-23-93"
    },
    {
        "col1": "Рязанская область",
        "col2": 2,
        "col3": "Администрация Ермишинского муниципального района Рязанской области",
        "col4": "391660, Рязанская область, р.п. Ермишь, пл. Ленина, д. 58, ermish@ryazan.gov.ru, +7 (491-44) 2-10-35"
    },
    {
        "col1": "Рязанская область",
        "col2": 3,
        "col3": "Администрация Захаровского муниципального района Рязанской области",
        "col4": "391740, Рязанская область, с. Захарово, ул. Центральная, д. 88, zaxarovo@ryazan.gov.ru, +7 (49153)51-3-26"
    },
    {
        "col1": "Рязанская область",
        "col2": 4,
        "col3": "Администрация Кадомского муниципального района Рязанской области",
        "col4": "391670, Рязанская область, р.п. Кадом, ул. Ленина, д. 37, kadom@ryazan.gov.ru, +7 (491-39) 5-16-90"
    },
    {
        "col1": "Рязанская область",
        "col2": 5,
        "col3": "Администрация Клепиковского муниципального района Рязанской области",
        "col4": "391030, Рязанская область, г. Спас-Клепики, пл. Ленина, д. 1, klepiki@ryazan.gov.ru, +7 (491-42) 2-62-51"
    },
    {
        "col1": "Рязанская область",
        "col2": 6,
        "col3": "Администрация Кораблинского муниципального округа Рязанской области ",
        "col4": "391200, Рязанская область, г. Кораблино, ул. Маяковского, д. 17, korablino@ryazan.gov.ru, +7 (49143) 5-04-25"
    },
    {
        "col1": "Рязанская область",
        "col2": 7,
        "col3": "Администрация Касимовского муниципального округа Рязанской области ",
        "col4": "391300, Рязанская область, г. Касимов, ул. Ленина, д. 9 А, kasimov@ryazan.gov.ru, +7 (49131) 2-24-21\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 8,
        "col3": "Администрация Милославского муниципального района Рязанской области ",
        "col4": "391770, Рязанская область, р.п. Милославское, ул. Ленина, д. 6, miloslavskoe@ryazan.gov.ru, +7 (4912) 20-28-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 9,
        "col3": "Администрация Михайловского муниципального округа Рязанской области ",
        "col4": "391710, Рязанская область, г. Михайлов, пл. Освобождения, д. 1, mixajlov@ryazan.gov.ru, +7 (49130) 2-12-32"
    },
    {
        "col1": "Рязанская область",
        "col2": 10,
        "col3": "Администрация Пителинского муниципального округа Рязанской области ",
        "col4": "391630, Рязанская область, р.п. Пителино, пл. Советская, д. 8, pitelino@ryazan.gov.ru, +7 (49145) 64141"
    },
    {
        "col1": "Рязанская область",
        "col2": 11,
        "col3": "Администрация Пронского муниципального района Рязанской области ",
        "col4": "391140, Рязанская область, р.п. Пронск, пл. Горького, д. 1, pronsk@ryazan.gov.ru, +7 (49155) 3-15-73"
    },
    {
        "col1": "Рязанская область",
        "col2": 12,
        "col3": "Администрация Путятинского муниципального округа Рязанской области ",
        "col4": "391480, Рязанская область, с. Путятино, ул. Ворошилова, д. 56, putyatino@ryazan.gov.ru, +7 (49146) 2-17-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 13,
        "col3": "Администрация Рыбновского муниципального района Рязанской области ",
        "col4": "391110, Рязанская область, г. Рыбное, пл. Ленина, д. 9, rybnoe@ryazan.gov.ru, +7(49137)50-3-42"
    },
    {
        "col1": "Рязанская область",
        "col2": 14,
        "col3": "Администрация Ряжского муниципального округа Рязанской области ",
        "col4": "391964, г. Ряжск, ул. Советская, д. 8, ryazhsk@ryazan.gov.ru, +7 (49132) 2-23-83"
    },
    {
        "col1": "Рязанская область",
        "col2": 15,
        "col3": "Администрация Рязанского муниципального района Рязанской области ",
        "col4": "390047, г. Рязань (п. Соколовка), ул. Связи, д. 14, admryazanr@ryazan.gov.ru, +7 (4912) 28-91-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 16,
        "col3": "Администрация Сапожковского муниципального района Рязанской области ",
        "col4": "391940, Рязанская область, р.п. Сапожок, ул. 50 лет Октября, д. 25, sapozhok@ryazan.gov.ru, +7(49152) 2-11-50"
    },
    {
        "col1": "Рязанская область",
        "col2": 17,
        "col3": "Администрация Сараевского муниципального района  Рязанской области  ",
        "col4": "391870, Рязанская область, р.п. Сараи, ул. Ленина, д. 157, sarai@ryazan.gov.ru, +7 (49148) 3-18-55"
    },
    {
        "col1": "Рязанская область",
        "col2": 18,
        "col3": "Администрация Сасовского муниципального округа Рязанской области ",
        "col4": "391430, Рязанская область, г. Сасово, ул. Садовая, д. 22, sasovo@ryazan.gov.ru, +7 (49133) 5-12-20, +7 (49133) 5-16-10"
    },
    {
        "col1": "Рязанская область",
        "col2": 19,
        "col3": "Администрация Скопинского муниципального района Рязанской области ",
        "col4": "391800, Рязанская область, г. Скопин, пл. Ленина, д. 22 а, skopinr@ryazan.gov.ru, +7 (49156) 2-00-45"
    },
    {
        "col1": "Рязанская область",
        "col2": 20,
        "col3": "Администрация Спасского муниципального района Рязанской области ",
        "col4": "391050, Рязанская область, г. Спасск-Рязанский, ул. Ленина, д. 48, spassk@ryazan.gov.ru, +7 (49135) 3-36-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 21,
        "col3": "Администрация Старожиловского муниципального района Рязанской области ",
        "col4": "391170, Рязанская область, р.п. Старожилово, ул. Толстого, д. 9, starozhilovo@ryazan.gov.ru, +7 (491-51) 2-22-52"
    },
    {
        "col1": "Рязанская область",
        "col2": 22,
        "col3": "Администрация Ухоловского муниципального района Рязанской области ",
        "col4": "391920, Рязанская область, р.п. Ухолово, ул. Ленина, д. 20, ukholovo-adm@ryazan.gov.ru, +7 (4915) 45-12-94"
    },
    {
        "col1": "Рязанская область",
        "col2": 23,
        "col3": "Администрация Чучковского муниципального района Рязанской области",
        "col4": "391420, Рязанская область, р.п. Чучково, пл. Ленина, д. 1, chuchkovo@ryazan.gov.ru, +7 (491-38) 7-62-25\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 24,
        "col3": "Администрация Шацкого муниципального района Рязанской области ",
        "col4": "391550, Рязанская область, г. Шацк, ул. Красная площадь, д. 5, shack@ryazan.gov.ru, +7 (49147) 2-14-52"
    },
    {
        "col1": "Рязанская область",
        "col2": 25,
        "col3": "Администрация Шиловского муниципального района Рязанской области ",
        "col4": "391500, Рязанская область, р.п. Шилово, ул. Советская, д. 14 А, shilovo@ryazan.gov.ru, +7 (491-36) 2-11-80"
    },
    {
        "col1": "Рязанская область",
        "col2": 26,
        "col3": "Администрация городского округа г. Рязани",
        "col4": "390000, г. Рязань, ул. Радищева, д. 28,ryazan@ryazan.gov.ru,+7 (4912) 20-04-12\n\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 27,
        "col3": "Администрация городского округа г. Скопин",
        "col4": "391800, Рязанская область, г. Скопин, ул. Ленина, д. 9, skopin.adm@yandex.ru, +7 (49156) 2-06-57"
    },
    {
        "col1": "Самарская область",
        "col2": 1,
        "col3": "Департамент управления имуществом городского округа Самара;",
        "col4": "43010, г. Самара, ул. Красноармейская, д. 17/samara@dyu.su/8 846 977 77 99 (доб. 6285)"
    },
    {
        "col1": "Самарская область",
        "col2": 2,
        "col3": "Администрация городского округа Тольятти",
        "col4": "г.Тольятти, ул.Белорусская, 33, каб.910, 912, dumi@tgl.ru, 54-37-00"
    },
    {
        "col1": "Самарская область",
        "col2": 3,
        "col3": "Жилищный отдел КЖКХ Администрации г.о. Сызрань",
        "col4": "Самарская область, г. Сызрань, ул. Ульяновская, д.61,каб.2, jil_otdel@bk.ru, 8 (846 4) 98-66-53"
    },
    {
        "col1": "Самарская область",
        "col2": 4,
        "col3": "Департамент городского хозяйства администрации городского округа Новокуйбышевск",
        "col4": "г. Новокуйбышевск, ул. Миронова, д. 2, adm@nvkb.ru, 88463563386"
    },
    {
        "col1": "Самарская область",
        "col2": 5,
        "col3": "Администрация городского округа Чапаевск (жилищный  отдел)",
        "col4": "г.Чапаевск, ул. Пролетарская, 1, gilotdel@bk.ru, 8(84639)20317"
    },
    {
        "col1": "Самарская область",
        "col2": 6,
        "col3": "Администрация городского округа Отрадный Самарской области",
        "col4": "г. Отрадный, ул.Отрадная, д.15, dom@otradny.org, 8(84661)22439"
    },
    {
        "col1": "Самарская область",
        "col2": 7,
        "col3": "Отдел по жилищным вопросам комитета по управлению муниципальным имуществом администрации городского округа Жигулевск",
        "col4": "445350, г. Жигулевск, ул. Пушкина, д. 17, Poliynskova.Irina@yandeх.ru; zhilotd@admzhg.ru, 8(84862)2-48-93, 2-42-31"
    },
    {
        "col1": "Самарская область",
        "col2": 8,
        "col3": "Служба по учету и распределению жилья Администрации г.о.Октябрьск",
        "col4": "г.Октябрьск, ул.Ленина, 54, oktyabrsk-go@mail.ru, 8(84646)21576"
    },
    {
        "col1": "Самарская область",
        "col2": 9,
        "col3": "Администрация городского округа Кинель Самарской области в лице комитета по управлению муниципальным имуществом городского округа Кинель Самарской области",
        "col4": "г. Кинель, ул. Мира, д.42а, kumikinel@mail.ru, 8(84663) 6-17-78"
    },
    {
        "col1": "Самарская область",
        "col2": 10,
        "col3": "Администрация городского округа Похвистнево Самарской области",
        "col4": "446450, Самарская обл., г. Похвистнево, ул. Лермонтова, 16, pohgor@samtel.ru, 8(84656)25812 8(84656)23466 "
    },
    {
        "col1": "Самарская область",
        "col2": 11,
        "col3": "Муниципальное казенное учреждение «Комитет по управлению муниципальным имуществом Администрации муниципального района Алексеевский Самарской области» ",
        "col4": "Самарская область, Алексеевский район, с.Алексеевка, ул.Советская,7, al_kumi@mail.ru, 8(84671)22377"
    },
    {
        "col1": "Самарская область",
        "col2": 12,
        "col3": "Комитет по управлению муниципальным имуществом администрации муниципального района Безенчукский Самарской области",
        "col4": "Самарская обл, Безенчукский р-н, пгт Безенчук, ул Нефтяников, д 11, kumibez@mail.ru, 88467623308"
    },
    {
        "col1": "Самарская область",
        "col2": 13,
        "col3": "Отдел по управлению муниципальным имуществом Администрации муниципального района Богатовский",
        "col4": "с.Богатое, ул. Комсомольская, д.13, kumi.bogatoe@yandex.ru, 8(84666)2-16-65"
    },
    {
        "col1": "Самарская область",
        "col2": 14,
        "col3": "Муниципальный район Большеглушицкий Самарской области",
        "col4": "446180, Самарская область, Большеглушицкий район, с. Большая Глушица, ул Гагарина, д. 91, bg@admbg.org, 8-846-73-2-16-33"
    },
    {
        "col1": "Самарская область",
        "col2": 15,
        "col3": "Администрация Большечерниговского района Самарской области",
        "col4": "с. Большая Черниговка, ул. Полевая, д.77, kumichernig@yandex.ru, 88467221140"
    },
    {
        "col1": "Самарская область",
        "col2": 16,
        "col3": "Муниципальное казенное учреждение \"Комитет по управлению муниципальным имуществом администрации муниципального района Борский Самарской области\"",
        "col4": "Самарская область  Борский район с.Борское  ул.Октябрьская, д. 57, kumi-bor@yandex.ru, 8(84667)1862"
    },
    {
        "col1": "Самарская область",
        "col2": 17,
        "col3": "Администрация муниципального района Волжский Самарской области",
        "col4": "443045, г. Самара, ул. Дыбенко, д. 12 Б, каб. 110, vr@v-adm63.ru, 8 846 260 33 35"
    },
    {
        "col1": "Самарская область",
        "col2": 18,
        "col3": "Комитет по управлению муниципальной собственностью муниципального района Елховский Самарской области",
        "col4": "Самарская область, Елховский район, с. Елховка, ул. М.Заводского, д. 41, kums-elh@yandex.ru, 88465833637"
    },
    {
        "col1": "Самарская область",
        "col2": 19,
        "col3": "Комитет по управлению муниципальным имуществом Администрации м.р.Исаклинский",
        "col4": "с.Исаклы, ул.Куйбышевская, 75А, kumiisakly@mail.ru, (84654)2-14-12"
    },
    {
        "col1": "Самарская область",
        "col2": 20,
        "col3": "Комитет по управлению муниципальным имуществом Администрации муниципального района Камышлинский",
        "col4": "Самарская область, Камышлинский р-н, с.Камышла, ул.Победы 80, kumiasiya@mail.ru, 88466433247,     88466433683,"
    },
    {
        "col1": "Самарская область",
        "col2": 21,
        "col3": "Администрация муниципального района Кинельский Самарской области, Комитет по управлению муниципальным имуществом муниципального района Кинельский Самарской области",
        "col4": "446433, Самарская область, г. Кинель, ул. Ленина, 36, radmin@kinel.ru, tishina@kinel.ru, kumi@kinel.ru, 88466321176, 884663 21145, 884663 21665"
    },
    {
        "col1": "Самарская область",
        "col2": 22,
        "col3": "Отдел по жилищной политике комитета по управлению имуществом Кинель-Черкасского района",
        "col4": "446350, Самарская область, Кинель-Черкасский район, с. Кинель-Черкассы, ул. Красноармейская,69., jil_otdel@bk.ru, 88466041175"
    },
    {
        "col1": "Самарская область",
        "col2": 23,
        "col3": "МУ \"Комитет по управлению муниципальным имуществом\" муниципального района Клявлинский",
        "col4": "Самарская область, Клявлинский район, ст.Клявлино, пр.Ленина, 9, kumi.klyav@yandex.ru, 8846 53 2-11-78, 8 846 2-15-65"
    },
    {
        "col1": "Самарская область",
        "col2": 24,
        "col3": "Комитет управления муниципальным имуществом администрации муниципального района Кошкинский Самарской области",
        "col4": "Самарская обл., Кошкинский р-н, с. Кошки, ул. Советская, д. 32, kumikoshki@yandex.ru, 8(84650)2-34-52"
    },
    {
        "col1": "Самарская область",
        "col2": 25,
        "col3": "Администрация муниципального района Красноармейский Самарской области",
        "col4": "446140,Самарская область, Красноармейский район, с. Красноармейское, пл. Центральная, д. 12, KUMIKR@yandex.ru, 8(846)7521095"
    },
    {
        "col1": "Самарская область",
        "col2": 26,
        "col3": "Комитет по управлению муниципальной собственностью администрации м.р. Красноярский Самарской области",
        "col4": "Самарская область, Красноярский район, с.Красный Яр, ул. Комсомольская, д.92 А, oiokymc.kryar@mail.ru, 8 (846 57) 2-19-51"
    },
    {
        "col1": "Самарская область",
        "col2": 27,
        "col3": "Администрация муниципального района Нефтегорский",
        "col4": "Самарская обл., г. Нефтегорск, ул.Ленина, 2, neftadm@yandex.ru, 88467021543, 88467022108"
    },
    {
        "col1": "Самарская область",
        "col2": 28,
        "col3": "Администрация муниципального района Пестравский Самарской области",
        "col4": "Самарская область, Пестравский район, село Пестравка, ул. Крайнюковская, д. 84, yapestravsky@yandex.ru, 8(84674) 21233"
    },
    {
        "col1": "Самарская область",
        "col2": 29,
        "col3": "Администрация м.р. Похвистневский, Комитет по управлению муниципальным имуществом",
        "col4": "446450, Самарская область, г. Похвистнево, ул. Ленинградская, д.9, gfdtk@samtel.ru,  zilye_pohr@mail.ru, 88465621354, 88465621248"
    },
    {
        "col1": "Самарская область",
        "col2": 30,
        "col3": "Муниципальное казенное учреждение \"Комитет по управлению муниципальным имущством администрации муниципального района Приволжский Самарской области\"",
        "col4": "Самарская область, Приволжский район, с. Приволжье, ул. Парковая, д. 14, mkukumi.pv@yandex.ru, 8(846 47)92273"
    },
    {
        "col1": "Самарская область",
        "col2": 31,
        "col3": "Жилищное управление администрации муниципального района Сергиевский Самарской области",
        "col4": "446540, Самарская область, Сергиевский район, с. Сергиевск, ул. Ленина, д.15А, каб. 3,4, got_adm@mail.ru, (884655)22337, (884655)21545"
    },
    {
        "col1": "Самарская область",
        "col2": 32,
        "col3": "МКУ \"Комитет по управлению муницпальным имуществом администрации муницпального района Ставролький Самарской области\"",
        "col4": "Самарская область, город Тольятти, пл.Свободы,9, stavr-ur@mail.ru, stavr-kumi@mail.ru, 8(8482)283310, 8(8482)281567"
    },
    {
        "col1": "Самарская область",
        "col2": 33,
        "col3": "МКУ \"Управление социального развития администрации Сызранского района\"",
        "col4": "Самарская обл., Сызранский район, п. Варламово, ул. Советская, д. 14, ussofis.varlamovo@yandex.ru , 8(8464)998256"
    },
    {
        "col1": "Самарская область",
        "col2": 34,
        "col3": "Муниципальное казенное учреждение Комитет по управлению муниципальным имуществом муниципального района Хворостянский Самарской области",
        "col4": "Самарская область, Хворостянский район, с. Хворостянка, пл. Плясункова, д. 10, каб. 107, hvor-kymi@yandex.ru , 8(84677)91278"
    },
    {
        "col1": "Самарская область",
        "col2": 35,
        "col3": "МКУ Комитет по управлению муниципальным имуществом администрация муниципального района Челно-Вершинский",
        "col4": "446840, Самарская область, Челно-Вершинский район, с. Челно-Вершины, ул. Советская, д. 12, kumi.chv@mail.ru, 88465121475"
    },
    {
        "col1": "Самарская область",
        "col2": 36,
        "col3": "Комитет по управлению имуществом, архитектуры, капитального строительства и жилищно-коммунального хозяйства администрации муниципального района Шенталинский",
        "col4": "446910, Самарская область, Шенталинский район, ж/д ст. Шентала, ул. Советская, д.33, ivanov@shentala.su, 8 (846)52-2-15-36"
    },
    {
        "col1": "Самарская область",
        "col2": 37,
        "col3": "Администрация муниципального района Шигонский Самарской области ",
        "col4": "Самарская область, с. Шигоны, пл. Ленина 1, adm@shigony.samregion.ru, (884648)21556"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 1,
        "col3": "Жилищный комитет",
        "col4": "пл. Островского, д. 11,       Санкт-Петербург, 191023,  gavrilyukom@gk.gov.spb.ru  (812)576-02-20"
    },
    {
        "col1": "Саратовская область",
        "col2": 1,
        "col3": "Администрация Александрово-Гайского МР ",
        "col4": "Саратовская область, Александрово-Гайский р-н, с Александров Гай, ул. Красного Бойца, д. 50, 8 (845 78 ) 2-20-35  orgalgai@mail.ru"
    },
    {
        "col1": "Саратовская область",
        "col2": 2,
        "col3": "Администрация Аркадакского МР",
        "col4": "Саратовская обл, р-н. Аркадакский, г. Аркадак, ул. Ленина, д. 25, omoarkadak@yandex.ru  8 (84542) 4-14-93"
    },
    {
        "col1": "Саратовская область",
        "col2": 3,
        "col3": "Администрация Аткарского МР",
        "col4": "г. Аткарск, ул. Советская, д. 64  uprdel64@yandex.ru  8 (84552) 3-32-22"
    },
    {
        "col1": "Саратовская область",
        "col2": 4,
        "col3": "Администрация Базарно-Карабулакского МР",
        "col4": "Саратовская область, р.п. Базарный Карабулак, ул. Ленина, д.126В  glava_omo@bk.ru   8 (84591) 7-15-81"
    },
    {
        "col1": "Саратовская область",
        "col2": 5,
        "col3": "Администрация Балаковского МР",
        "col4": "Саратовская обл., г. Балаково, ул. Трнавская, 12  admbal@bk.ru  +7 (8453) 32-49-49"
    },
    {
        "col1": "Саратовская область",
        "col2": 6,
        "col3": "Администрация Балашовского МР",
        "col4": "412300, Саратовская область, г. Балашов, ул. Советская, д. 178  delo_bmr@mail.ru  8 (84545) 4-24-12"
    },
    {
        "col1": "Саратовская область",
        "col2": 7,
        "col3": "Администрация Балтайского МР",
        "col4": "412630, Саратовская область, с.Балтай, ул.Ленина, д.78  orgotdelBMR@yandex.ru  8 (84592) 2- 22-58"
    },
    {
        "col1": "Саратовская область",
        "col2": 8,
        "col3": " Администрация Вольского МР",
        "col4": "412900, Саратовская область, г.Вольск, ул.Октябрьская, 114  volskadm@mail.ru  8(84593)7-20-17"
    },
    {
        "col1": "Саратовская область",
        "col2": 9,
        "col3": "Администрация Воскресенского МР",
        "col4": "Саратовская обл., Воскресенский р-он, с. Воскресенское, ул. Шеина, 34   adm-voskres@yandex.ru  +7 (84568) 2-24-64"
    },
    {
        "col1": "Саратовская область",
        "col2": 10,
        "col3": "Администрация Дергачёвского МР",
        "col4": "Саратовская область, Дергачевский район, р.п.Дергачи, площадь М.Горького, 4.  DergachiAMR@yandex.ru   (8 845 63) 2-91-30"
    },
    {
        "col1": "Саратовская область",
        "col2": 11,
        "col3": " Администрация Духовницкого МР",
        "col4": "Улица Ленина, 27  рп. Духовницкое, Духовницкий район, Саратовская область, 413900  duhovnitskoe.sarmo.ru  +7 (84573) 2‒14‒89"
    },
    {
        "col1": "Саратовская область",
        "col2": 12,
        "col3": "Администрация Екатериновского МР ",
        "col4": "412120, Саратовская область, Екатериновский район, рабочий поселок Екатериновка, ул. 50 лет Октября, д.90   aemr@mail.ru  8 (84554) 2-13-90"
    },
    {
        "col1": "Саратовская область",
        "col2": 13,
        "col3": " Администрация Ершовского МР",
        "col4": "413503, Саратовская область,  г. Ершов, ул. Интернациональная, 7    g.p.a72@yandex.ru   (884564) 5-26-26"
    },
    {
        "col1": "Саратовская область",
        "col2": 14,
        "col3": "Администрация Ивантеевского МР",
        "col4": "413950, Саратовская обл., с.Ивантеевка, ул.Советская, д.14   iva_omo@mail.ru   8 (84579) 5-16-33"
    },
    {
        "col1": "Саратовская область",
        "col2": 15,
        "col3": "Администрация Калининского МР",
        "col4": "Саратовская область, г. Калининск, ул. Коллективная, 61  admkalinmr@yandex.ru  +7 (84549) -3-13-08"
    },
    {
        "col1": "Саратовская область",
        "col2": 16,
        "col3": "Администрация Красноармейского МО ",
        "col4": "412800 г. Саратовская область, г. Красноармейск, улица Ленина, 62  org.kmr@mail.ru  +7 (84550) 2-27-23"
    },
    {
        "col1": "Саратовская область",
        "col2": 17,
        "col3": "Администрация Краснокутского МР",
        "col4": "413235 Саратовская область, г. Красный Кут, проспект Победы, д. 1  kr-kyt64@yandex.ru  78456051495"
    },
    {
        "col1": "Саратовская область",
        "col2": 18,
        "col3": " Администрация Краснопартизанского МР",
        "col4": "413540, Саратовская область, Краснопартизанский район, п. Горный, улица Чапаевская, д.30   partizanmr@mail.ru   +7 (84577) 2-14-32, 2-14-44."
    },
    {
        "col1": "Саратовская область",
        "col2": 19,
        "col3": "Администрация Лысогорского МР",
        "col4": "412860, Саратовская область, р.п. Лысые Горы пл. 50 лет Октября, д.3 abbant-45@yandex.ru  (84551) 2-10-34"
    },
    {
        "col1": "Саратовская область",
        "col2": 20,
        "col3": "Администрация Марксовского МР",
        "col4": "413090, Саратовская область, г. Маркс, пр. Ленина, д. 18   marksadm@mail.ru  8(84567)5-55-55"
    },
    {
        "col1": "Саратовская область",
        "col2": 21,
        "col3": "Администрация Новобурасского МР",
        "col4": "412580, Саратовская область, р. п. Новые Бурасы,ул. Советская, д. 3 apparatnb@mail.ru  (845 57) 2-16-70"
    },
    {
        "col1": "Саратовская область",
        "col2": 22,
        "col3": "Администрация Новоузенского МР",
        "col4": "г.Новоузенск, ул. Советская, 24  ud1094@mail.ru  8 (84562) 2-15-82"
    },
    {
        "col1": "Саратовская область",
        "col2": 23,
        "col3": "Администрация Озинского МР",
        "col4": " 413623, Саратовская область, Озинский район, р.п. Озинки, ул. Советская, д. 49  nadya.ionina2014@yandex.ru  8 (84576) 4-13-56"
    },
    {
        "col1": "Саратовская область",
        "col2": 24,
        "col3": "Администрация Перелюбского МР",
        "col4": "413750, Саратовская область, Перелюбский район, с.Перелюб, ул. Ленина д.96   perelyb_admin@mail.ru  8 (84575) 2-13-84"
    },
    {
        "col1": "Саратовская область",
        "col2": 25,
        "col3": "Администрация Петровского МР",
        "col4": "412540 Саратовская обл., г.Петровск, ул.Панфилова, 55  mail@petrovsk64.ru  +7 (84555) 27133"
    },
    {
        "col1": "Саратовская область",
        "col2": 26,
        "col3": "Администрация Питерского МР",
        "col4": "413320, Саратовская обл., с. Питерка, ул. Им. Ленина,101  adm1011@yandex.ru  8 (84561) 2-14-44"
    },
    {
        "col1": "Саратовская область",
        "col2": 27,
        "col3": "Администрация Пугачёвского МР",
        "col4": "413720, Саратовская область, г. Пугачев, ул. Пушкинская, д. 280  apmr.info@mail.ru  8 (845-74) 2-38-30"
    },
    {
        "col1": "Саратовская область",
        "col2": 28,
        "col3": "Администрация Ровенского МР ",
        "col4": "413270, Саратовская область, Ровенский район, рабочий поселок Ровное, Советская ул., д.28  rovnoe2@mail.ru  8 (845) 962-11-43"
    },
    {
        "col1": "Саратовская область",
        "col2": 29,
        "col3": "Администрация Романовского МР",
        "col4": "412270, Саратовская область, р.п.Романовка, ул.Народная, 10  \nromanovka-administracija@rambler.ru  8 (84544) 4-02-54"
    },
    {
        "col1": "Саратовская область",
        "col2": 30,
        "col3": "Администрация Ртищевского МР",
        "col4": "412031, Саратовская область, г. Ртищево, ул. Красная, д. 6  rtishevo.sarmo.ru  +7 (84540) 4-20-10"
    },
    {
        "col1": "Саратовская область",
        "col2": 31,
        "col3": " Администрация Самойловского МР",
        "col4": "Саратовская область Самойловский район р.п. Самойловка ул. Красная площадь д.10  samoyl_admin@mail.ru  8 (845 48) 2-13-49"
    },
    {
        "col1": "Саратовская область",
        "col2": 32,
        "col3": " Администрация Советского МР ",
        "col4": "413210, Саратовская область, Советский район, р.п. Степное, ул. 50 лет Победы, д. 3  \nsovorgotdel@mail.ru  8(84566)5-00-49"
    },
    {
        "col1": "Саратовская область",
        "col2": 33,
        "col3": "Администрация Татищевского МР",
        "col4": "412170 Саратовская область, р.п. Татищево, ул. Советская 13  admtat@tatishevomr.ru 8 (84558) 4-00-44"
    },
    {
        "col1": "Саратовская область",
        "col2": 34,
        "col3": "Администрация Турковского МР",
        "col4": "412070, Саратовская область, рп. Турки, ул. Советская, 26   orgturki@yandex.ru  (845 43) 2 - 11 - 00"
    },
    {
        "col1": "Саратовская область",
        "col2": 35,
        "col3": "Администрация Фёдоровского МР",
        "col4": "Саратовская область, Фёдоровский район, рп.Мокроус, ул.Центральная, д.55  mo27fedor@mail.ru  88456550038"
    },
    {
        "col1": "Саратовская область",
        "col2": 36,
        "col3": "Администрация Хвалынского МР",
        "col4": " Саратовская область, Хвалынск, Революционная улица, 110А   admin412780@mail.ru  +7 (84595) 2-10-30"
    },
    {
        "col1": "Саратовская область",
        "col2": 37,
        "col3": "Администрация Энгельсского МР",
        "col4": "Администрация Энгельсского муниципального района engels@engels-city.ru (8453) 55-76-23"
    },
    {
        "col1": "Саратовская область",
        "col2": 38,
        "col3": "МО поселок Михайловский ",
        "col4": "Саратовская область, посёлок Михайловский, улица 60 лет Победы, 6  zato@mihailovski.ru\n+7 (84577) 2-22-09"
    },
    {
        "col1": "Саратовская область",
        "col2": 39,
        "col3": "Администрация Городской округ ЗАТО Светлый ",
        "col4": "412163, Россия, Саратовская область, п. Светлый, ул. Кузнецова, д.6 \"А zato_svetly@mail.ru  +7 (84558) 4-30-00"
    },
    {
        "col1": "Саратовская область",
        "col2": 40,
        "col3": " МО г. Шиханы Саратовской области ",
        "col4": "412950, Саратовская область, г. Шиханы, ул. Ленина, 12  shihadm@mail.ru  +7 (84593) 40-3-52"
    },
    {
        "col1": "Саратовская область",
        "col2": 41,
        "col3": " МО \"Город Саратов\"",
        "col4": "410031, г. Саратов, ул. Первомайская, д. 78  komitet@admsaratov.ru  +7(8452)26-02-49"
    },
    {
        "col1": "Сахалинская область",
        "col2": 1,
        "col3": "Управление социальной политики Александровск-Сахалинского муниципального округа",
        "col4": "694420, Сахалинская область, г. Александровск-Сахалинский, ул. Ленина, д. 4, тел.: 8 (42434) 4-34-55, e-mail: opeka_Alex@bk.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 2,
        "col3": "Администрация Анивского муниципального округа ",
        "col4": "694030, Сахалинская область, Анивский район, г. Анива, ул. Калинина, д. 57, тел.: 8 (42441) 42059,   e-mail: aniva@sakhalin.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства администрации муниципального образования Долинский муниципальный округ",
        "col4": "694051, Сахалинская область, г. Долинск, ул. Комсомольская, д. 37, e-mail: dolinsk@sakhalin.gov.ru, тел.: 8 (42442) 2-68-22\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 4,
        "col3": "Отдел опеки и попечительства департамента социального развития администрации \nКорсаковского муниципального округа\n",
        "col4": "694020, Сахалинская область, г. Корсаков, ул. Советская, д. 41,  e-mail: opeka_akgo@sakhalin.gov.ru, тел.: 8 (42435) 4-05-41 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 5,
        "col3": "Администрация Курильского муниципального округа",
        "col4": "694530, Сахалинская область, г. Курильск, ул. Приморское шоссе, д. 5/1,\nтел.: (42454) 42467, e-mail: kurilsk@sakhalin.gov.ru \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 6,
        "col3": "Администрация Макаровского муниципального образования ",
        "col4": "694140, Сахалинская обл., г. Макаров, ул. 50 лет Октября, д. 32, тел.: 8 (42443) 9-01-48, e-mail:  makarov@sakhalin.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 7,
        "col3": "Отдел образования администрации Невельского муниципального округа",
        "col4": "694740, Сахалинская область, г. Невельск, ул. Советская, д. 55, тел.: 8 (42436) 6-10-18, 8 (42436) 6-02-45, e-mail: y.petrova@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 8,
        "col3": "Отдел ЖК и ДХ администрации муниципального образования Ногликский муниципальный округ ",
        "col4": "694450, Сахалинская область, пгт. Ноглики, ул. Советская, д. 15, e-mail: o.nesterova@sakhalin.gov.ru, тел.: 8 (42444) 9-15-21\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 9,
        "col3": "Департамент социального развития администрации Охинского муниципального округа \n",
        "col4": "694490, Сахалинская область, г. Оха, ул. Ленина, д. 8, e-mail: uprobrsek@sakhalin.gov.ru, okhaopeka@mail.ru, тел.: 8 (42437) 3-06-14\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 10,
        "col3": "Жилищный отдел Администрации Поронайского муниципального округа ",
        "col4": "694240, Сахалинская область, г. Поронайск, ул. Октябрьская, д. 61А, e-mail: poronayisk@list.ru,  тел.: 8 (42431) 5-60-03 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 11,
        "col3": "Отдел образования,  социальной  и молодежной политики, культуры и спорта администрации Северо-Курильского муниципального округа",
        "col4": "694550, Сахалинская область, г. Северо-Курильск, ул. Сахалинская, д. 34 А, e-mail: Opeka-skgo@yandex.ru, тел.: 8 (42453) 4-22-13 \n\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 12,
        "col3": "Управление образования администрации Смирныховского муниципального округа ",
        "col4": "694350, Сахалинская область, пгт. Смирных, ул. Маяковского, д. 7, тел.: 8 (42452) 42-8-68, e-mail: opeka_smirnih@mail.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 13,
        "col3": "Комитет по управлению муниципальной собственностью Томаринского муниципального округа\n\n",
        "col4": "694820, Сахалинская область, г. Томари, ул. Калинина, д. 49 А, e-mail: tomari@adm-tomari.ru,\nтел.: 8 (42446) 2-67-66\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 14,
        "col3": "Администрация Тымовского муниципального округа  ",
        "col4": "694400, Сахалинская область,Тымовский район, пгт. Тымовское, ул. Кировская, д. 70, e-mail: kums-tymovsk@sakhalin.gov.ru,  тел.: 8 (42447) 9-11-01\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 15,
        "col3": "Комитет по управлению муниципальной собственностью Углегорского муниципального округа \n\n",
        "col4": "694920, Сахалинская область, г. Углегорск, ул. Свободная, д. 1, тел.: (42432) 46-1-02, e-mail: uglegorsk@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства администрации Холмского муниципального округа ",
        "col4": "694620, Сахалинская область, г. Холмск, ул. Советская, д. 66, e-mail: kholmsk.oop@sakhalih.gov.ru, тел.: 8(42433)2-16-89 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 17,
        "col3": "Департамент по управлению муниципальной собственностью Южно-Курильского  муниципального округа ",
        "col4": "694500, Сахалинская область, пгт. Южно-Курильск, пл. Ленина, д. 1, e-mail: kums.adm@mail.ru, тел.: 8 (42424) 9-60-01\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 18,
        "col3": "Департамент образования администрации города Южно-Сахалинска\n\n",
        "col4": "693000, г. Южно-Сахалинск, ул. Амурская, д. 88, e-mail: yusgo.do@sakhalin.gov.ru, тел.: 8 (4242) 3-12-680"
    },
    {
        "col1": "Свердловская область",
        "col2": 1,
        "col3": "Департамент государственного жилищного и строительного надзора Свердловской области",
        "col4": "620004, г. Екатеринбург, ул. Малышева, д. 101 / nadzor@egov66.ru / 8 (343) 312-00-32"
    },
    {
        "col1": "Свердловская область",
        "col2": 2,
        "col3": "Администрация муниципального округа муниципального образования Алапаевское Свердловской области",
        "col4": "624600, г. Алапаевск, ул. Р.Люксембург, 31 / 34243@alapaevskoe.ru / 8 (34346) 2-10-20"
    },
    {
        "col1": "Свердловская область",
        "col2": 3,
        "col3": "Администрация муниципального округа Муниципального образования город Алапаевск Свердловской области",
        "col4": "624600 г. Алапаевск, ул. Ленина, 18 / mo-alapaevsk@mail.ru / 8 (34346) 2-10-10"
    },
    {
        "col1": "Свердловская область",
        "col2": 4,
        "col3": "Администрация Артемовского муниципального округа Свердловской области",
        "col4": "623780, г. Артемовский, пл. Советов, 3 / adm@artemovsky66.ru / 8 (34363) 2-41-51"
    },
    {
        "col1": "Свердловская область",
        "col2": 5,
        "col3": "Администрация Артинского муниципального округа Свердловской области",
        "col4": "623340, р.п. Арти, ул. Ленина, 100 / arti_adm@mail.ru / 8 (34391) 2-19-04"
    },
    {
        "col1": "Свердловская область",
        "col2": 6,
        "col3": "Администрация Асбестовского муниципального округа Свердловской области",
        "col4": "624261, г. Асбест, ул. Уральская, 73 / adminasb@mail.ru / 8 (34365) 7-56-25"
    },
    {
        "col1": "Свердловская область",
        "col2": 7,
        "col3": "Администрация Ачитского муниципального округа Свердловской области",
        "col4": "623230, р.п. Ачит, ул. Кривозубова, 2 / admachit@mail.ru / 8 (34391) 7-16-60"
    },
    {
        "col1": "Свердловская область",
        "col2": 8,
        "col3": "Администрация Белоярского муниципального округа Свердловской области",
        "col4": "624030, п. Белоярский, ул. Ленина, 263 / beloyarka@mail.ru / 8 (34377) 2-20-92"
    },
    {
        "col1": "Свердловская область",
        "col2": 9,
        "col3": "Администрация Березовского муниципального округа Свердловской области",
        "col4": "623701, г. Березовский, ул. Театральная, 9 / adm@admbgo.ru / 8 (34369) 4-33-00"
    },
    {
        "col1": "Свердловская область",
        "col2": 10,
        "col3": "Администрация Бисертского муниципального округа Свердловской области",
        "col4": "623050, р.п. Бисерть, ул. Октябрьская, 1 / bisert.go@egov66.ru / 8 (34398) 6-24-18"
    },
    {
        "col1": "Свердловская область",
        "col2": 11,
        "col3": "Администрация муниципального образования муниципального округа Богданович Свердловской области",
        "col4": "623530, г. Богданович, ул. Советская, 3 / gobogd@gobogdanovich.ru / 8 (34376) 5-22-62"
    },
    {
        "col1": "Свердловская область",
        "col2": 12,
        "col3": "Администрация Верхнесалдинского муниципального округа Свердловской области",
        "col4": "624760, г. Верхняя Салда, ул. Энгельса,46 / admin@v-salda.ru / 8 (34345) 5-03-06"
    },
    {
        "col1": "Свердловская область",
        "col2": 13,
        "col3": "Администрация муниципального округа Верхний Тагил Свердловской области",
        "col4": "624162, г. Верхний Тагил, ул. Жуковского,13 / v-tagil@mail.ru / 8 (34357) 2-47-92"
    },
    {
        "col1": "Свердловская область",
        "col2": 14,
        "col3": "Администрация муниципального округа Верхотурский Свердловской области",
        "col4": "624380, г. Верхотурье, ул. Советская, 4 / adm-verchotury@mail.ru / 8 (34389) 2-27-03"
    },
    {
        "col1": "Свердловская область",
        "col2": 15,
        "col3": "Администрация Волчанского муниципального округа Свердловской области",
        "col4": "624940, г. Волчанск, ул. Уральского Комсомола, 1 / volchansk@list.ru / 8 (34383) 5-21-00"
    },
    {
        "col1": "Свердловская область",
        "col2": 16,
        "col3": "Администрация Гаринского муниципального округа Свердловской области",
        "col4": "624910, р. п. Гари, ул. Комсомольская, 52 / gari_admin@mail.ru / 8 (34387) 2-14-22; 2-11-81"
    },
    {
        "col1": "Свердловская область",
        "col2": 17,
        "col3": "Администрация муниципального округа Горноуральский Свердловской области",
        "col4": "622001, г. Нижний Тагил, ул. Красноармейская,46 / prigorod@palladant.ru / 8 (3435) 25-57-92"
    },
    {
        "col1": "Свердловская область",
        "col2": 18,
        "col3": "Администрация муниципального округа Дегтярск Свердловской области",
        "col4": "623271, г. Дегтярск, ул. Калинина, 50 / opo@degtyarsk.ru / 8 (34397) 6-01-39"
    },
    {
        "col1": "Свердловская область",
        "col2": 19,
        "col3": "Администрация муниципального округа Заречный Свердловской области",
        "col4": "624250, г. Заречный, ул. Невского, 3 / gsp_zar@mail.ru / 8 (34377)-3-17-05"
    },
    {
        "col1": "Свердловская область",
        "col2": 20,
        "col3": "Администрация Ивдельского муниципального округа Свердловской области",
        "col4": "624590, г. Ивдель, ул. Ворошилова, 2 / AdmIvdl@rambler.ru / 8 (34386) 2-17-49, 2-14-44"
    },
    {
        "col1": "Свердловская область",
        "col2": 21,
        "col3": "Администрация муниципального округа Ирбитского муниципального образования Свердловской области",
        "col4": "623850, г. Ирбит, ул. Революции, 16 / mail@irbitskoemo.ru / 8 (34355) 6-29-48"
    },
    {
        "col1": "Свердловская область",
        "col2": 22,
        "col3": "Администрация Каменского муниципального округа Свердловской области",
        "col4": "623428, г. Каменск-Уральский, пр. Победы, 38а / admkgoso@mail.ru / 8 (3439) 32-52-57"
    },
    {
        "col1": "Свердловская область",
        "col2": 23,
        "col3": "Администрация муниципального округа Карпинск Свердловской области",
        "col4": "624930, г. Карпинск, ул. Мира, 63 / karpinsk-org.otdel@mail.ru / 8 (34383) 2-20-30 "
    },
    {
        "col1": "Свердловская область",
        "col2": 24,
        "col3": "Администрация Качканарского муниципального округа Свердловской области",
        "col4": "624356, г. Качканар, ул. Свердлова, 8 / mail@kgo66.ru / 8 (34341) 6-97-12"
    },
    {
        "col1": "Свердловская область",
        "col2": 25,
        "col3": "Администрация Кировградского муниципального округа Свердловской области",
        "col4": "624140, г. Кировград, ул. Свердлова, 44 / Kirovgrad@inbox.ru / 8 (34357) 6-02-26"
    },
    {
        "col1": "Свердловская область",
        "col2": 26,
        "col3": "Администрация муниципального округа Краснотурьинск Свердловской области",
        "col4": "624440, г. Краснотурьинск, ул. Молодежная, 1 / krasnt@krasnoturinsk-adm.ru / 8 (34384) 9-89-02 "
    },
    {
        "col1": "Свердловская область",
        "col2": 27,
        "col3": "Администрация муниципального округа Красноуральск Свердловской области",
        "col4": "624330, г. Красноуральск, ул. Победы, 1 / admkrur@rambler.ru / 8 (34343) 2-11-25"
    },
    {
        "col1": "Свердловская область",
        "col2": 28,
        "col3": "Администрация городского округа Красноуфимск Свердловской области",
        "col4": "623300, г. Красноуфимск, ул. Советская, 25 / admin@krasnoufimsk.ru / 8 (34394) 5-07-20"
    },
    {
        "col1": "Свердловская область",
        "col2": 29,
        "col3": "Администрация Кушвинского муниципального округа Свердловской области",
        "col4": "624300, г. Кушва, ул. Красноармейская,16 / kushva.go@egov66.ru / 8 (34344) 2-57-11; 2-52-28"
    },
    {
        "col1": "Свердловская область",
        "col2": 30,
        "col3": "Администрация Малышевского муниципального округа Свердловской области",
        "col4": "624286, р.п. Малышева, ул. Пионерская, 27 / adm_malisheva@mail.ru / 8 (34365) 5-36-96"
    },
    {
        "col1": "Свердловская область",
        "col2": 31,
        "col3": "Администрация муниципального округа Махнёвского муниципального образования Свердловской области",
        "col4": "624621, п.г.т. Махнево, ул. Победы, 34 / admmahnevo@yandex.ru / 8 (34346) 7-64-45"
    },
    {
        "col1": "Свердловская область",
        "col2": 32,
        "col3": "Администрация Невьянского муниципального округа Свердловской области",
        "col4": "624170, г. Невьянск, ул. Кирова, 1 / adngo@nvnsk.ru / 8 (34356) 4-25-10; 4-25-12"
    },
    {
        "col1": "Свердловская область",
        "col2": 33,
        "col3": "Администрация Нижнетуринского муниципального округа Свердловской области",
        "col4": "624350, г. Нижняя Тура, ул. 40 лет Октября, 2а / ntura.adm@egov66.ru / 8 (34342) 2-77-11, 2-77-22"
    },
    {
        "col1": "Свердловская область",
        "col2": 34,
        "col3": "Администрация муниципального округа города Нижний Тагил Свердловской области",
        "col4": "622034, г. Нижний Тагил, ул. Пархоменко, 1А / odo.ntagil@mail.ru / 8 (3435) 41-00-11"
    },
    {
        "col1": "Свердловская область",
        "col2": 35,
        "col3": "Администрация муниципального округа Нижняя Салда Свердловской области",
        "col4": "624742, г. Нижняя Салда, ул. Фрунзе, 2 / admin_nsalda@mail.ru / 8 (34345) 3-25-80"
    },
    {
        "col1": "Свердловская область",
        "col2": 36,
        "col3": "Администрация Новолялинского муниципального округа Свердловской области",
        "col4": "624400, г. Новая Ляля, ул. Ленина, 27 / nl.go@egov66.ru / 8 (34388) 2-21-00"
    },
    {
        "col1": "Свердловская область",
        "col2": 37,
        "col3": "Администрация муниципального округа Пелым Свердловской области",
        "col4": "624582, п. Пелым, ул. Карла Маркса, 5 / admin_pel@mail.ru / 8 (34386) 4-52-05, 4-53-92"
    },
    {
        "col1": "Свердловская область",
        "col2": 38,
        "col3": "Администрация муниципального округа Первоуральск Свердловской области",
        "col4": "23109, г. Первоуральск, ул. Ватутина, 41 / prvadm@prvadm.ru / 8 (3439) 64-85-40"
    },
    {
        "col1": "Свердловская область",
        "col2": 39,
        "col3": "Администрация Полевского муниципального округа Свердловской области",
        "col4": "623388, г. Полевской, ул. Свердлова, 19 / MO_Polevsk@rambler.ru / 8 (34350) 5-40-01"
    },
    {
        "col1": "Свердловская область",
        "col2": 40,
        "col3": "Администрация Пышминского муниципального округа Свердловской области",
        "col4": "623550, р.п. Пышма, ул. 1 Мая, 2 / pischma@rambler.ru / 8 (34372) 2-11-64"
    },
    {
        "col1": "Свердловская область",
        "col2": 41,
        "col3": "Администрация муниципального округа Ревда Свердловской области",
        "col4": "623281, г. Ревда, ул. Цветников, 21 / pochta@admrevda.ru / 8 (34397) 3-07-34"
    },
    {
        "col1": "Свердловская область",
        "col2": 42,
        "col3": "Администрация Режевского муниципального округа Свердловской области",
        "col4": "623750, г. Реж, ул. Красноармейская, 16 / rezh.go@egov66.ru / 8 (34364) 3-20-24"
    },
    {
        "col1": "Свердловская область",
        "col2": 43,
        "col3": "Администрация Североуральского муниципального округа Свердловской области",
        "col4": "624480, г. Североуральск, ул. Чайковского, 15 / s-uralsk_adm.org@mail.ru / 8 (34380) 2-44-65; 2-08-68"
    },
    {
        "col1": "Свердловская область",
        "col2": 44,
        "col3": "Администрация Серовского муниципального округа Свердловской области",
        "col4": "624992, г. Серов, ул. Ленина, 140 / info@adm-serov.ru / 8 (34385) 7-57-47"
    },
    {
        "col1": "Свердловская область",
        "col2": 45,
        "col3": "Администрация Сосьвинского муниципального округа Свердловской области",
        "col4": "624971, Серовский район, р.п. Сосьва, ул. Толмачева, 45 / serovregion@yandex.ru / 8 (34385) 4-44-44"
    },
    {
        "col1": "Свердловская область",
        "col2": 46,
        "col3": "Администрация муниципального округа Среднеуральск Свердловской области",
        "col4": "624070, г. Среднеуральск, ул. Уральская, 26 / sredneuralsk@egov66.ru / 8 (34368) 7-38-95"
    },
    {
        "col1": "Свердловская область",
        "col2": 47,
        "col3": "Администрация муниципального округа Староуткинск Свердловской области",
        "col4": "623036, р.п. Староуткинск, ул. Советская, 1а / staroutkinsk@mail.ru / 8 (34358) 5-51-42"
    },
    {
        "col1": "Свердловская область",
        "col2": 48,
        "col3": "Администрация муниципального округа Сухой Лог Свердловской области",
        "col4": "624800, г. Сухой Лог, ул. Кирова, 7а / goslog@rambler.ru / 8 (34373) 4-36-60"
    },
    {
        "col1": "Свердловская область",
        "col2": 49,
        "col3": "Администрация Сысертского муниципального округа Свердловской области",
        "col4": "624022, г. Сысерть, ул. Ленина, 35 / adm_sgo@mail.ru / 8 (34374) 6-00-10"
    },
    {
        "col1": "Свердловская область",
        "col2": 50,
        "col3": "Администрация Тавдинского муниципального округа Свердловской области",
        "col4": "623950, г. Тавда, ул. Кирова, 118 / tavda@gov66.ru / 8 (34360) 5-00-00"
    },
    {
        "col1": "Свердловская область",
        "col2": 51,
        "col3": "Администрация Талицкого муниципального округа Свердловской области",
        "col4": "623640, г. Талица, ул. Луначарского, 59 / atalgo@mail.ru / 8 (34371) 2-11-52"
    },
    {
        "col1": "Свердловская область",
        "col2": 52,
        "col3": "Администрация Тугулымского муниципального округа Свердловской области",
        "col4": "623650, р.п. Тугулым, ул. 50 лет Октября, 1 / admTugulym@yandex.ru / 8 (34367) 2-23-16"
    },
    {
        "col1": "Свердловская область",
        "col2": 53,
        "col3": "Администрация Туринского муниципального округа Свердловской области",
        "col4": "623900, г. Туринск, ул. Советская, 10 / turinsk-adm@yandex.ru / 8 (34349) 2-11-82; 2-16-82 "
    },
    {
        "col1": "Свердловская область",
        "col2": 54,
        "col3": "Администрация Шалинского муниципального округа Свердловской области",
        "col4": "623030, р.п. Шаля, ул. Орджоникидзе, 5 / admin_shgo@mail.ru / 8 (34358) 2-25-01"
    },
    {
        "col1": "Свердловская область",
        "col2": 55,
        "col3": "Администрация Новоуральского городского округа Свердловской области",
        "col4": "624130, г. Новоуральск, ул. Мичурина, 33 / gendep@adm-ngo.ru / 8 (34370) 9-88-21"
    },
    {
        "col1": "Свердловская область",
        "col2": 56,
        "col3": "Администрация городского округа «Город Лесной» Свердловской области",
        "col4": "624200, г. Лесной, ул. Карла Маркса, 8 / admles@gorodlesnoy.ru / 8 (34342) 6-88-01, 6-88-38"
    },
    {
        "col1": "Свердловская область",
        "col2": 57,
        "col3": "Администрация городского округа ЗАТО Свободный Свердловской области",
        "col4": "624790, пос. Свободный, ул. Майского, 67 / adm_zato_svobod@mail.ru / 8 (34345) 5-84-80"
    },
    {
        "col1": "Свердловская область",
        "col2": 58,
        "col3": "Администрация городского округа ЗАТО Уральский Свердловской области",
        "col4": "624054, Белоярский район, п. Уральский, ул. Капитана Флёрова, 105 / zato_uralsky@mail.ru / 8 (34377) 3-59-00"
    },
    {
        "col1": "Свердловская область",
        "col2": 59,
        "col3": "Администрация городского округа муниципального образования «город Екатеринбург»",
        "col4": "620014, г. Екатеринбург, пл. Ленина, 24а / glava@ekadm.ru / 8 (343) 376-43-93"
    },
    {
        "col1": "Свердловская область",
        "col2": 60,
        "col3": "Администрация Арамильского городского округа Свердловской области",
        "col4": "8 (343) 385-32-81 / adm@aramilgo.ru / 8 (343) 385-32-8"
    },
    {
        "col1": "Свердловская область",
        "col2": 61,
        "col3": "Администрация городского округа Верх-Нейвинский Свердловской области",
        "col4": "624170, р.п. Верх-Нейвинский, пл. Революции, 3 / wnadm@mail.ru / 8 (34370) 4-65-05; 5-51-75"
    },
    {
        "col1": "Свердловская область",
        "col2": 62,
        "col3": "Администрация городского округа Верхнее Дуброво Свердловской области",
        "col4": "624053, р. п. Верхнее Дуброво, ул. М.Горького, 27 / Adm_ VDubr@mail.ru / 8 (34377) 5-31-92, 5-32-54"
    },
    {
        "col1": "Свердловская область",
        "col2": 63,
        "col3": "Администрация городского округа Верхняя Пышма Свердловской области",
        "col4": "624097, г. Верхняя Пышма, пр-кт. Успенский, зд. 115 / kontakt@movp.ru / 8 (34368)-4-04-80"
    },
    {
        "col1": "Свердловская область",
        "col2": 64,
        "col3": "Администрация городского округа Верхняя Тура Свердловской области",
        "col4": "624320, г. Верхняя Тура, ул. Иканина, 77 / admintura@yandex.ru / 8 (34344) 2-82-90"
    },
    {
        "col1": "Свердловская область",
        "col2": 65,
        "col3": "Администрация городского округа «город Ирбит» Свердловской области",
        "col4": "623850, г. Ирбит, ул. Революции, 16 / adminhozirbit@mail.ru / 8 (34355) 6-31-72"
    },
    {
        "col1": "Свердловская область",
        "col2": 66,
        "col3": "Администрация Каменск-Уральского городского округа Свердловской области",
        "col4": "623400, г. Каменск-Уральский, ул. Ленина, д. 32 / glava@admnet.kamensktel.ru / 8 (3439) 39-69-55"
    },
    {
        "col1": "Свердловская область",
        "col2": 67,
        "col3": "Администрация Камышловского городского округа Свердловской области",
        "col4": "624860, г. Камышлов, ул. Свердлова, 41 / kamGO@mail.ru / 8 (34375) 2-33-32"
    },
    {
        "col1": "Свердловская область",
        "col2": 68,
        "col3": "Администрация городского округа Красноуфимск Свердловской области",
        "col4": "623300, г. Красноуфимск, ул. Советская, 25 / admin@krasnoufimsk.ru / 8 (34394) 5-07-20 "
    },
    {
        "col1": "Свердловская область",
        "col2": 69,
        "col3": "Администрация городского округа Рефтинский Свердловской области",
        "col4": "624285, р. п. Рефтинский, ул. Гагарина, 10 / reft@goreftinsky.ru / 8 (34365) 3-50-01"
    },
    {
        "col1": "Свердловская область",
        "col2": 70,
        "col3": "Администрация Байкаловского муниципального района Свердловской области",
        "col4": "623870, с. Байкалово, ул. Революции, 25 / baykalovo@mail.ru / 8 (34362) 2-01-51"
    },
    {
        "col1": "Свердловская область",
        "col2": 71,
        "col3": "Администрация Камышловского муниципального района Свердловской области",
        "col4": "624860, г. Камышлов, ул. Свердлова, 41 / adm_kammr@mail.ru / 8 (34375) 2-40-80; 2-31-82"
    },
    {
        "col1": "Свердловская область",
        "col2": 72,
        "col3": "Администрация Нижнесергинского муниципального района Свердловской области",
        "col4": "623090, г. Нижние Серги, ул. Титова, 39 / nsergigp@mail.ru / 8 (34398) 2-80-10"
    },
    {
        "col1": "Свердловская область",
        "col2": 73,
        "col3": "Администрация Слободо-Туринского муниципального района Свердловской области",
        "col4": "623930, с. Туринская Слобода, ул. Ленина, 1 / slturadmin@mail.ru / 8 (34361) 2-10-51"
    },
    {
        "col1": "Свердловская область",
        "col2": 74,
        "col3": "Администрация Таборинского муниципального района Свердловской области",
        "col4": "623990, с. Таборы, ул. Советская, 4 / tabory.mr@egov66.ru / 8 (34347) 2-11-72"
    },
    {
        "col1": "Севастополь",
        "col2": 1,
        "col3": "Департамент городского хозяйства города Севастополя. в случае приобретения получателем выплаты жилого помещения в ином субъекте Российской Федерации запрос направляется уполномоченному органу соответствующего субъекта Российской Федерации",
        "col4": "г. Севастополь, ул. Генерала Петрова, 15, 299011 тел. +7 (8692) 55-08-41, эл. почта: depgh@sev.gov.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 1,
        "col3": "Управление образования Администрнациии муниципального образования \"Сафоновский муниципальный округ\" Смоленской области",
        "col4": "215500, Смоленская область, г. Сафоново, ул. Ленина, д. 3  т. 8(48142)4-10-06"
    },
    {
        "col1": "Смоленская область",
        "col2": 2,
        "col3": "Администрация муниципального образования \"Темкинский муниципальный округ\" Смоленской области",
        "col4": "215350, Смоленская область, с.Темкино, ул.Советск4ая, д.27, Temkino_OBR@admin-smolensk.ru,               8(48136)2-14-07"
    },
    {
        "col1": "Смоленская область",
        "col2": 3,
        "col3": "Администрация муниципального образования \"Шумячский муниципальный округ\" Смоленской области",
        "col4": "216410 Смоленская область, п.Шумячи, ул.Школьна,д.1  shumichi@admin-smolensk.ru  4-12-65 "
    },
    {
        "col1": "Смоленская область",
        "col2": 4,
        "col3": "Администрация муниципального образования \"Ельнинский муниципальный округ\" Смоленской области",
        "col4": "216330 Смоленская область, г. Ельня, ул. Советская, д. 23; e-mail admin_elnia@admin-smolensk.ru; тел. (8 481 46) 4-18-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 5,
        "col3": "Администрация муниципального образования \"Новодугинский мунициплаьный округ\" Смоленской области",
        "col4": "215240, Смоленская область, Новодугинский район, с. Новодугино, ул. 30 лет Победы, д. 2     amo-novodug@yandex.ru   (8 481 38)  2-20-31"
    },
    {
        "col1": "Смоленская область",
        "col2": 6,
        "col3": "Администрация муниципального образования \"Сычевский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, г. Сычевка, ул. Пушкина, д.25,\nSychevka.opeka@gmail.com                                   8(48130)4-12-77\n"
    },
    {
        "col1": "Смоленская область",
        "col2": 7,
        "col3": " Администрация муниципального образования \"Велижский муниципальный округ\" Смоленской области",
        "col4": " г.Велиж, пл. Дзержинского, д.7, тел.4-15-33 эл. Почта:velizh@admin-smolensk,ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 8,
        "col3": "Администрация муниципального образования \"Ершичский муниципальный округ\" Смоленской области",
        "col4": "216580, Смоленская область, с. Ершичи, ул. Советская, д.22, тел. 8(48155) 2-16-60, эл.почта <ershadm@yandex.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 9,
        "col3": "Комитет территориального развития, строительства и жилищно-коммунального хозяйства Администрации муниципального образования \"Глинковский муниципальный округ\" Смоленской области",
        "col4": "216320, Смоленская область, Глинковский район, с. Глинка, ул. Ленина, дл. 8, glinka@admin-smolensk.ru 8(481-65) 2-13-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 10,
        "col3": "Управление жилищно-коммунального хозяйства Администрации города Смоленска",
        "col4": "214000, г. Смоленск, ул. Дзержинского, д. 11 /(84812)384754/ ujkh@smoladmin.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 11,
        "col3": "Администрация муниципального образования \"Угранский муниципальный округ\" Смоленской области",
        "col4": "215430, Смоленская область, село Угра, ул. Ленина, д. 38 ugra@admin-smolensk.ru? 8(48137)4-12-90"
    },
    {
        "col1": "Смоленская область",
        "col2": 12,
        "col3": "Администрация МО \"Духовщинский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, город Духовщина, улица М.Горького, 23, 84816641707, duhovshina.opeka@yandex.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 13,
        "col3": "Администрация муниципального образования \"Хиславичский муниципальный округ\" Смоленской области",
        "col4": "216620, Смоленская область, п. Хиславичи, ул. Советская, д. 23, адрес электронной почты: hislav@admin-smolensk.ru, тел. 8(48140) 2-20-20"
    },
    {
        "col1": "Смоленская область",
        "col2": 14,
        "col3": "Администрация муниципального образования \"Починковский муниципальный округ\" Смоленской области",
        "col4": "216450, Смоленская область,       г. Починок, ул. Советская,             д. 1/potch@admin-smolensk.ru/      8 (48149)4-11-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 15,
        "col3": "Администрация муниципального образования \"Гагаринский муниципальный округ\" Смоленской области",
        "col4": "215010, Смоленская область, г. Гагарин, ул. Советская, д. 8; e-mail:gagarin-komitet@mail.ru, opeka.gagarin@yandex.ru; конт. тел. 8(48135)6-40-61, 6-40-68"
    },
    {
        "col1": "Смоленская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства управления  по образованию и молодежной политике Администрации муниципального образования \"Ярцевский муниципальный округ\" Смоленской области",
        "col4": "8-48143-7-10-30 opeka-yarcevo@mail.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 17,
        "col3": "Администрация МО  \"Демидовский муниципальный округ \" Смоленской области\n ",
        "col4": "216240, Смоленская область, г.Демидов, ул.Коммунистическая, д.10,  https: demidov@admin-smolensk.ru.                     Т.(48147) 4-11-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 18,
        "col3": "Администрация МО «город Десногорск» Смоленской области  ",
        "col4": "г. Десногорск, 2 мкр., стр. 1 / эл. почта: kiizo@yandex.ru/ 8-48153-7-44-60, 848153-7-23-08"
    },
    {
        "col1": "Смоленская область",
        "col2": 19,
        "col3": "Администрация МО \"Монастырщинский муниципальный округ\" Смоленской области",
        "col4": "216130, Смоленская область, п. Монастырщина, ул. 1-я Краснинская, д. 14.                   E-mail: monast@admin-smolensk.ru, т. 8 (48148) 4-18-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 20,
        "col3": "Администрация МО \"Краснинский муниципальный округ",
        "col4": "пгт. Красный,ул. Карла-Маркса, д.16,krasniy@admin-smolensk.ru, 84814541449"
    },
    {
        "col1": "Смоленская область",
        "col2": 21,
        "col3": "Администрация МО \"Дорогобужский муниципальный округ\"",
        "col4": "г. Дорогобуж, ул. Кутузова, д.1  dorgp@mail.ru                      8 48144 41749"
    },
    {
        "col1": "Смоленская область",
        "col2": 22,
        "col3": "Администрация МО \"Вяземский муниципальный округ\" Смоленской области",
        "col4": " 215110,  Смоленская область, г. Вязьма, ул. 25 Октября, д. 11, 8 (48131) 4–11–50, office@admin-smolensk.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 23,
        "col3": "Администрации МО \"Смоленский муниципальный округ\" Смоленской области",
        "col4": "тел./факс  8 (4812) 55-53-89\nе-mail: smolray@admin-smolensk.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 24,
        "col3": "Администрация МО \"Кардымовский муниципальный округ\" Смоленской области",
        "col4": "215850, Смоленская область, пгт. Кардымово, ул. Красноармейская, д.  7/ roo@kardymovo.ru/ 8 (48167) 4-21-56"
    },
    {
        "col1": "Смоленская область",
        "col2": 25,
        "col3": "Администрация МО \"Рославльский муниципальный округ\" Смоленской области ",
        "col4": "216500, Смоленская область, г. Рославль, площадь Ленина, д. 1, 8-48134-6-84-52, administrator@roslavl.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 26,
        "col3": "Отдел по образованию Администрации МО  \"Холм-Жирковский муниципальный округ\" Смоленской области",
        "col4": "215650\nСмоленская область, п.г.т.Холм-Жирковский , ул. Нахимовская, д.9\nE-mail: moholm@yandex.ru moholm@admin-smolensk.ru\nтел./факс:  8 (48139) 2-10-63"
    },
    {
        "col1": "Смоленская область",
        "col2": 27,
        "col3": "Управление жилищно-коммунального хозяйства Администрация муниципального образования \"Руднянский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, г.Рудня, ул.Киреева, д.93; rud_adm@admin-smolensk.ru; Телефон: (48141) 5-20-36"
    },
    {
        "col1": "Ставропольский край",
        "col2": 1,
        "col3": "отсутствует",
        "col4": "отсутствует"
    },
    {
        "col1": "Тамбовская область",
        "col2": 1,
        "col3": "отсутствует",
        "col4": "отсутствует"
    },
    {
        "col1": "Тверская область",
        "col2": 1,
        "col3": "органы местного самоуправления Администрация города Твери",
        "col4": "г. Тверь, ул. Советская, д. 11  ‎+7 (4822) 36-10-76 tveradm@adm.tver.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 2,
        "col3": "Администрация Вышневолоцкого городского округа ",
        "col4": "Тверская область,г. Вышний Волочек, Казанский проспект, д. 17,    +7 (48233) 6-41-36  admvgo@v-volok.ru  "
    },
    {
        "col1": "Тверская область",
        "col2": 3,
        "col3": "Администрация города Торжка",
        "col4": "Тверская область, г. Торжок, Новгородская наб., д.1а       8(48251) 9-12-46   admtorzhok@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 4,
        "col3": "Администрация Андреапольского муниципального округа",
        "col4": "Тверская область, г. Андреаполь, пл. Ленина, д.2    +7 (48267) 3-14-51     admandreapol@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 5,
        "col3": "Администрация Бельского муниципального округа",
        "col4": "Тверская область, г. Белый, площадь Победы, д.4   8(482508) 2-25-32    belraion@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 6,
        "col3": "Администрация Весьегонского муниципального округа",
        "col4": " Тверская область,г. Весьегонск, ул. Коммунистическая, д. 16  +7 (482 64) 2-13-05"
    },
    {
        "col1": "Тверская область",
        "col2": 7,
        "col3": "Администрация Жарковского муниципального округа",
        "col4": "Тверская область, п. Жарковский, ул.Советская, д. 54  8(48273)21503 admzharki@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 8,
        "col3": "Администрация Зубцовского муниципального округа",
        "col4": "г Зубцов, пер Образцова, д. 1а  8 (48262) 2-25-51    priemnaya@adminzubcov.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 9,
        "col3": "Администрация Калязинского  муниципального округа",
        "col4": "г. Калязин, ул. Центральная, д. 1  8(48249) 2-19-61  gorod1775@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 10,
        "col3": "Администрация Кесовогорского муниципального округа",
        "col4": "Тверская область, пгт Кесова Гора, ул Московская, д. 6  +7 (48274) 2-11-01   kesovogorskiy_okrug"
    },
    {
        "col1": "Тверская область",
        "col2": 11,
        "col3": "Администрация Конаковского муниципального округа",
        "col4": "Тверская область,г. Конаково, ул. Энергетиков, дом 13.    8(48242)49-777 konadm@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 12,
        "col3": "Администрация Кувшиновского муниципального округа",
        "col4": " Тверская область, г. Кувшиново, ул. Советская, 33   8 (48 257) 4 41 44   admkuvshinovo@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 13,
        "col3": "Администрация Лихославльского муниципального округа",
        "col4": "Тверская область,г. Лихославль, ул. Первомайская, д. 6          8 (48261) 3-59-41      adm@lihoslavl69.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 14,
        "col3": "Администрация Молоковского муниципального округа",
        "col4": "Тверская область, пгт Молоково, ул. Ленина,д.13    8 (48275) 2-13-61     molokovskiy_reg@tverreg.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 15,
        "col3": "Администрация Оленинского муниципального округа",
        "col4": "Тверская область,Оленинский муниципальный округ, пгт. Оленино, ул. Октябрьская, д. 7     8(48258) 2-14-02, 2-13-36     oleninoadm@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 16,
        "col3": "Администрация Пеновского муниципального округа",
        "col4": "Тверская область, п. Пено, ул. 249 Стр.дивизии, д.33    8(48230) 2-39-58    penoraion@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 17,
        "col3": "Администрация Селижаровского муниципального округа",
        "col4": "Тверская область, пгт Селижарово, ул. Ленина, д.12    8(48269) 225-80     selmuno@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 18,
        "col3": "Администрация Спировского муниципального округа",
        "col4": "Тверская область, п.Спирово, пл.Советская, д.5   8(48276) 2-11-39     spirovo-raion@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 19,
        "col3": "Администрация Торжокского района",
        "col4": "Тверская область,г. Торжок, ул. Луначарского, д. 2   8(48251) 9-22-50   torzhokadm@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 20,
        "col3": "Администрация Удомельского городского округа",
        "col4": "Тверская область,г. Удомля, ул. Попова, д.22  8 (48255) 5 43 35  udom_okrug@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 21,
        "col3": "Администрация Ржевского муниципального округа",
        "col4": "Тверская область,г. Ржев, ул. Партизанская, д.33    8(48232) 2-09-15   info@городржев.рф"
    },
    {
        "col1": "Тверская область",
        "col2": 22,
        "col3": "Администрация Кимрского муниципального округа",
        "col4": "Тверская область, г. Кимры,ул. Кирова д.18   8(48236) 2-16-66    admin@adm-kimry.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 23,
        "col3": "Администрация Бежецкого муниципального округа",
        "col4": "Тверская область,г. Бежецк, Первомайский переулок, дом 21   8 (48231) 2-15 -81  admbezh@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 24,
        "col3": "Администрация Бологовского муниципального округа",
        "col4": "Тверская область,г. Бологое, ул.Кирова, 13   +7 (48238) 2-22-11     rbologoe@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 25,
        "col3": "Администрация Западнодвинского муниципального округа",
        "col4": "Тверская область,г. Западная Двина, ул. Кирова, д. 10    8(48265) 2-17-30    westerndvina.22@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 26,
        "col3": "Администрация Калининского муниципального округа",
        "col4": "г.Тверь, набережная реки Лазури, д.3  8 (4822) 45-38-39   mail@kalinin-mo.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 27,
        "col3": "Администрация Кашинского городского округа",
        "col4": " Тверская область,г. Кашин, ул. Анатолия Луначарского, дом 20  8 (48234) 2-06-68, kashin@tvobl.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 28,
        "col3": "Администрация Краснохолмского муниципального округа",
        "col4": "Тверская область,г. Красный Холм, пл. Карла Маркса, д. 10,  8(48237)2 23 21  adm.krholm@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 29,
        "col3": "Администрация Лесного муниципального округа",
        "col4": "Тверская область, село Лесное, ул. Советская, д.9   8(48271)2-11-45   adminlesnoe69@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 30,
        "col3": "Администрация Максатихинского муниципального округа",
        "col4": "Тверская область,Максатихинский муниципальный округ, пгт. Максатиха, ул. Красноармейская, д. 5.   +7 (48 253) 2-13-12.   maksatiha@tvobl.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 31,
        "col3": "Администрация Нелидовского городского округа",
        "col4": "Тверская обл., г. Нелидово, пл. Ленина, 3  8 (48266) 53444   gorodnelidovo@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 32,
        "col3": "Администрация Осташковского городского округа",
        "col4": " Тверская область,г.Осташков, Ленинский пр-т, д.46, помещение 1   8(48235)5-16-65  аdmostregion@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 33,
        "col3": "Администрация Рамешковского муниципального округа",
        "col4": "Тверская область, пгт Рамешки, ул. Советская, д.20 8(48244)2-13-04   admrameshki@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 34,
        "col3": "Администрация Сандовского мунипального округа",
        "col4": "Тверская область, п.Сандово, ул.Советская, д.11    8 (48272) 2-11-45  sandovo@tvobl.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 35,
        "col3": "Администрация Сонковского муниципального округа",
        "col4": "Тверская область, пгт. Сонково, пр-т Ленина, д. 24   84824621354   admsonkovo@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 36,
        "col3": "Администрация Старицкого муниципального округа",
        "col4": "Тверская область,г. Старица, ул. Советская, д. 6.    8(48263)21-623   starica_adm@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 37,
        "col3": "Администрация Торопецкого муниципального округа",
        "col4": "Тверская область,г. Торопец, ул.Октябрьская, д. 53   8(48268)2-12-97   Toropec69adm@yandex.ru "
    },
    {
        "col1": "Тверская область",
        "col2": 38,
        "col3": "Администрация Фировского муниципального округа",
        "col4": "Тверская область,пос. Фирово ул. Советская, д.21   8(48-239)3-19-80  glavafirovo@mail.ru"
    },
    {
        "col1": "Томская область",
        "col2": 1,
        "col3": "Отдел опеки и попечительства Администрации Александровского района Томской области",
        "col4": "636760, Томская область, Александровский район,\n с. Александровское, \nул. Ленина, д. 7\n8 (382 55) 2-46-76\nAlsOpeka@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 2,
        "col3": "Асиновское городское поселение",
        "col4": "636840, Томская область, г. Асино, ул.  имени Ленина, 40/ adminpos@asino.tomsknet.ru/\n8(38241)22498,\n8(38241)21748\n"
    },
    {
        "col1": "Томская область",
        "col2": 3,
        "col3": " Большедороховское сельское поселение",
        "col4": "636803, Томская область, Асиновский район, с. Больше-Дорохово, ул. Центральная, д. 26/\nb-dorokhovo-sp@asino.gov70.ru\n/ 8(38241)47205\n"
    },
    {
        "col1": "Томская область",
        "col2": 4,
        "col3": "Батуринское сельское поселение",
        "col4": "636820 Томская область, Асиновский район, с.Батурино, ул. Клубная ,34/\nbaturino-sp@asino.gov70.ru/\n8(38241)41155\n"
    },
    {
        "col1": "Томская область",
        "col2": 5,
        "col3": "Новокусковское сельское поселение",
        "col4": "636810 Томская обл., Асиновский р-он, с.Ново-Кусково, ул.Школьная, д.55/ nkselp@mail.tomsknet.ru/\n8(38241)45045;\n8(38241) 45001\n"
    },
    {
        "col1": "Томская область",
        "col2": 6,
        "col3": "Новиковское сельское поселение",
        "col4": "636830 Томская область Асиновский район с. Новиковка ул. Советская д.14 /\nadm-novikovka@asino.gov70.ru\n/8(38241)44166; \n8(38241)44220\n"
    },
    {
        "col1": "Томская область",
        "col2": 7,
        "col3": "Новониколаевское сельское поселение",
        "col4": "636813 Томская область Асиновский район, с.Новониколаевка, ул. Школьная, д.30, / n-nikolaevka-sp@asino.gov70.ru/ 8(38)24142159; 8(38)24142117"
    },
    {
        "col1": "Томская область",
        "col2": 8,
        "col3": "Ягодное сельское поселение",
        "col4": "636806 Томская обл., Асиновский р-он, с.Ягодное, ул.Школьная, д.1 Г/ yagodnoe-sp@asino.gov70.ru/\n8 (38241)43537\n"
    },
    {
        "col1": "Томская область",
        "col2": 9,
        "col3": "Бакчарское сельское поселение",
        "col4": "636200. Томская область, Бакчарский район, с. Бакчар ул. Ленина д. 53, \n baksp@tomsk.gov.ru, 8(38249)\n22-284\n"
    },
    {
        "col1": "Томская область",
        "col2": 10,
        "col3": "Вавиловское сельское поселение",
        "col4": "636202, Томская область, Бакчарский район, с. Вавиловка, ул. Центральная д.2, vavilsp@tomsk.gov.ru, 8 (38249) 37-295"
    },
    {
        "col1": "Томская область",
        "col2": 11,
        "col3": "Парбигское сельское поселение",
        "col4": "636220,Томская область, Бакчарский район, с. Парбиг, ул. Советская д. 55, parbigsp@tomsk.gov.ru, 8 (38249) 44-384"
    },
    {
        "col1": "Томская область",
        "col2": 12,
        "col3": "Высокоярское сельское поселение",
        "col4": "636225, Томская область, Бакчарский район, с. Высокий Яр, пер. Трактовый, д. 7, vsp.06@mail.ru, 8 (38249) 38-124"
    },
    {
        "col1": "Томская область",
        "col2": 13,
        "col3": "Плотниковское сельское поселение",
        "col4": "636210, Томская область, Бакчарский район, с. Плотниково, ул. Школьная д. 4, plotsp@tomsk.gov.ru, 8 (38249) 39-436"
    },
    {
        "col1": "Томская область",
        "col2": 14,
        "col3": "Поротниковское сельское поселение",
        "col4": "636213, Томская область, Бакчарский район, с Поротниково, ул. Советская, д.19, portsp@tomsk.gov.ru, 8 (38249) 36-138"
    },
    {
        "col1": "Томская область",
        "col2": 15,
        "col3": "Белоярское городское поселение",
        "col4": "636500,Томская область, Верхнекетский район, р.п.Белый Яр, ул.Гагарина,47стр.1, upravdel.admbel@mail.ru тел.8-38258-2-56-82"
    },
    {
        "col1": "Томская область",
        "col2": 16,
        "col3": "Катайгинское сельское поселение",
        "col4": "636518, Томская область, Верхнекетский район, п.Катайга, ул.Кирова, д.39\"А\"  \n (38258) 3-31-38\n sakat@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 17,
        "col3": "Клюквинское сельское поселение",
        "col4": "636511, Томская область, Верхнекетский район, п. Клюквинка, ул. Центральная, д. 13\n8 (38258) 24-136\nsaklk@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 18,
        "col3": " Макзырское сельское поселение",
        "col4": "636511, Томская область, Верхнекетский район, п. Лисица, ул. Таежная, д. 16\n8 (38258) 35-148\nmsadm@seversk.tomsknet.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 19,
        "col3": "Орловское сельское поселения",
        "col4": "636513, Томская область, Верхнекетский район, п.Центральный, пер.Школьный, д.11.\n (38258) 37-2-26,  saorl@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 20,
        "col3": "Палочкинское сельское поселение",
        "col4": "636506, Томская область, Верхнекетский район, село Палочка, ул. Молодёжная, д. 26\n palsp@tomsk.gov.ru\n 8 (38 )258 -34-136\n"
    },
    {
        "col1": "Томская область",
        "col2": 21,
        "col3": "Степановское сельское поселение",
        "col4": "636516 Томская обл., Верхнекетский р-н п. Степановка, пер.Аптечный,д.4 sastp@tomsk.gov.ru/ 83825825136"
    },
    {
        "col1": "Томская область",
        "col2": 22,
        "col3": "Администрация Сайгинское сельское поселение",
        "col4": "636520 Томская обл., Верхнекетский район,  п. Сайга ул. Молодогвардейская, д. 5/ sasay@tomsk.gov.ru/ 83825836136"
    },
    {
        "col1": "Томская область",
        "col2": 23,
        "col3": "Ягоднинское сельское поселение",
        "col4": "636521 Томская обл., Верхнекетский район, п. Ягодное, Октябрьская,1 saber@tomsk.gov.ru/ 83825832280"
    },
    {
        "col1": "Томская область",
        "col2": 24,
        "col3": "Высоковского сельского поселения ",
        "col4": "636850 Томская область, Зырянский район, с.Зырянское, ул.Советская, д.10\n(адрес электронной почты: zirselo@tomsk.gov.ru; тел. 8(38243) 38149)\n"
    },
    {
        "col1": "Томская область",
        "col2": 25,
        "col3": "Дубровское сельское поселение",
        "col4": "636852 Томская область, Зырянский район, с.Дубровка, ул. Фрунзе, д.38\n (адрес электронной почты: dubrovka@tomsk.gov.ru; тел. 8(38243) 34131)\n"
    },
    {
        "col1": "Томская область",
        "col2": 26,
        "col3": "Зырянское сельское поселение",
        "col4": "636850 Томская область, Зырянский район, с.Зырянское, ул.Советская, д.10\n(адрес электронной почты: zirselo@tomsk.gov.ru; тел. 8(38243) 38149)\n"
    },
    {
        "col1": "Томская область",
        "col2": 27,
        "col3": "Михайловское сельское поселение",
        "col4": "636855 Томская область, Зырянский район, с.Михайловка, ул.Гончарова, д.44\n(адрес электронной почты: mihaylsp@tomsk.gov.ru; тел. 8(38243) 36222).\n"
    },
    {
        "col1": "Томская область",
        "col2": 28,
        "col3": "Чердатское сельское поселение",
        "col4": "636859 Томская область, Зырянский район, с.Чердаты, ул.Советская, 1а\n(адрес электронной почты: cherdsp@tomsk.gov.ru; тел. 8(38243) 32137)\n"
    },
    {
        "col1": "Томская область",
        "col2": 29,
        "col3": "Вертикосское сельское поселение",
        "col4": "636753, Томская область, Каргасокский район, с. Вертикос, ул. Молодежная,1/ mkuvertikos@yandex.ru/\n8(38253)  36-189;\n"
    },
    {
        "col1": "Томская область",
        "col2": 30,
        "col3": "Каргасокское сельское поселение",
        "col4": "636700, Томская область, Каргасокский район,  с. Каргасок, ул. Новая,1\nkargs@tomsk.gov.ru\n8 (38253)23 423\n"
    },
    {
        "col1": "Томская область",
        "col2": 31,
        "col3": "Нововасюганское сельское поселение",
        "col4": "636740, Томская область, Каргасокский район, с. Новый Васюган, ул. Советская,49/\nnovyjvasiugan@yandex.ru/\n 8 (38253) 29 284\n"
    },
    {
        "col1": "Томская область",
        "col2": 32,
        "col3": "Новоюгинское сельское           поселение",
        "col4": "636714,, Томская область, Каргасокский район,  с. Новоюгино, ул. Цетральная, 44-2/\nansp06@mail.ru/ \n 8 (38253) 37-132\n"
    },
    {
        "col1": "Томская область",
        "col2": 33,
        "col3": "Средневаюганское сельское поселение",
        "col4": " 636733, Томская область, Каргасокский район, с. Средний Васюган, ул. Гагарина,6/\nsvasugan@mail.ru/ \n8 (38253) 25-172\n"
    },
    {
        "col1": "Томская область",
        "col2": 34,
        "col3": "Среднетымское сельское поселение",
        "col4": " 636754, Томская область, Каргасокский район, п. Молодежный, ул. Школьная, 2/ sredtympos@yandex.ru/  8 (38253) 44-146                                                  "
    },
    {
        "col1": "Томская область",
        "col2": 35,
        "col3": "Сосновское сельское поселение",
        "col4": "636712, Томская область, Каргасокский  с.Сосновка, ул. Школьная, 18/ sosnovka.a@yandex.ru/\n 8 (38253) 38-144\n"
    },
    {
        "col1": "Томская область",
        "col2": 36,
        "col3": "Вороновское сельское поселение",
        "col4": "636171, Томская область, Кожевниковский район, с. Вороново, ул. Уткина, д. 17 \n voronovosp@mail.ru\n(838244) 31-169; (838244) 31-148\n"
    },
    {
        "col1": "Томская область",
        "col2": 37,
        "col3": "Кожевниковское сельское поселение",
        "col4": "636160, Томская область, Кожевниковский район, с. Кожевниково, ул. Гагарина, д. 24\n kozhev_posel@mail.ru\n(838244) 44-745; (838244) 21-961\n"
    },
    {
        "col1": "Томская область",
        "col2": 38,
        "col3": "Малиновское сельское поселение",
        "col4": "636176, Томская область, Кожевниковский район, с. Малиновка, ул. Школьная, д. 13 MalinovkaSP@mail.ru\n(838244) 53-146\n"
    },
    {
        "col1": "Томская область",
        "col2": 39,
        "col3": "Новопокровское сельское поселение",
        "col4": "636182, Томская область, Кожевниковский район, с. Новопокровка, ул. Садовая, д. 2а voronovosp@mail.ru\n(838244) 58-125, (838244) 58-134\n"
    },
    {
        "col1": "Томская область",
        "col2": 40,
        "col3": "Песочнодубровское сельское поселение",
        "col4": "636164, Томская область, Кожевниковский район, с. Песочнодубровка, ул. Молодежная, д. 37 \npesoch@tomsk.gov.ru\n(838244) 42-332\n"
    },
    {
        "col1": "Томская область",
        "col2": 41,
        "col3": "Староювалинское сельское поселение",
        "col4": "636165, Томская область, Кожевниковский район, с. Старая Ювала, ул. Ульяновская, д. 3а\n yuvala@mail.ru, stuval@tomsk.gov.ru\n(838244) 41-327; (838244) 41-162\n"
    },
    {
        "col1": "Томская область",
        "col2": 42,
        "col3": "Уртамское сельское поселение",
        "col4": "636170, Томская область, Кожевниковский район, с. Уртам, ул. Фрунзе, д. 33 \nurtam@tomsk.gov.ru\n(838244) 51-351\n"
    },
    {
        "col1": "Томская область",
        "col2": 43,
        "col3": "Чилинское сельское поселение",
        "col4": "636172, Томская область, Кожевниковский район, с. Чилино, ул. Кустарная, д. 1 \nchilino@mail.ru\nchilino@kozhevnikovo.gov70.ru\nlarisa.jul2015@yandex.ru\n8.6(838244) 35-407, (838244)35-332\n"
    },
    {
        "col1": "Томская область",
        "col2": 44,
        "col3": "Колпашевское городское поселение",
        "col4": "636460, Томская область, Колпашевский район, г. Колпашево, ул. Победы, д. 5\nkolpash@tomsk.gov.ru\n8 (38254) 5 82 62\n"
    },
    {
        "col1": "Томская область",
        "col2": 45,
        "col3": "Инкинское сельское поселение",
        "col4": "636443,Томская область, Колпашевский район,  д. Инкино, пер. Кооперативный 11\ninkino@tomsk.gov.ru\n8 (38254) 9-31-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 46,
        "col3": "Новогоренское сельское поселение",
        "col4": "636444, Томская область, Еолпашевский район, д. Новогорное, ул. Береговая 42, n-gorins@tomsk.gov.ru \n8 (38254) 9 41 36\n"
    },
    {
        "col1": "Томская область",
        "col2": 47,
        "col3": "Новоселовское сельское поселение",
        "col4": "636425, Томская область, Колпашевский район,  с. Новоселово, ул. Центральная 11/1\nn-selovs@tomsk.gov.ru\n8 (38254) 22 160\n"
    },
    {
        "col1": "Томская область",
        "col2": 48,
        "col3": "Саровское сельское поселение",
        "col4": "636431, Томская область, Колпашевский район, п. Большая Саровка, ул. Советская 35/2\nsarovka@tomsk.gov.ru\n8 (38254) 2-74-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 49,
        "col3": "Чажемтовское сельское поселение",
        "col4": "636423,Томская область, Колпашевский район,  с. Чажемто, ул. Ленина 24\nchagemto@tomsk.gov.ru, 8 (38254) 2 12 42\n"
    },
    {
        "col1": "Томская область",
        "col2": 50,
        "col3": "Володинское сельское поселение",
        "col4": "636310, Томская область, Кривошеинский  район,  с. Володино, ул. Советская, 31, volodino@tomsk.qov.ru, 8(38251) 45-433"
    },
    {
        "col1": "Томская область",
        "col2": 51,
        "col3": "Иштанское сельское поселение",
        "col4": "636312 Томская область Кривошеинский район с. Иштан, ул. Лесная 1А, ishtan00@yandex.ru, 8(38251) 43-495"
    },
    {
        "col1": "Томская область",
        "col2": 52,
        "col3": "Красноярское сельское поселение",
        "col4": "636320, Томская область, Кривошеинский район, с. Красный Яр, ул. Советская, 60, krasyar@tomsk.gov.ru, 8(38251) 31-330"
    },
    {
        "col1": "Томская область",
        "col2": 53,
        "col3": "Новокривошеинское сельское поселение",
        "col4": "636307, Томская область, Кривошеинский район, с. Новокривошеино, ул. Советская, 1, novokriv@yandex.ru, 8(38251) 47-433"
    },
    {
        "col1": "Томская область",
        "col2": 54,
        "col3": "Петровское сельское поселение",
        "col4": "636313, Томская область, Кривошеинский район, Петровка, ул. Комсомольская,12, реtrovka@tomsk.gov.ru, 8(38251) 44-416"
    },
    {
        "col1": "Томская область",
        "col2": 55,
        "col3": "Могочинское сельское поселение",
        "col4": "636340, Томская область, Молчановский район, с. Могочино, ул. Заводская, 3, +7 (38256) 3‒31‒32,  admogochino@sibmail.ru"
    },
    {
        "col1": "Томская область",
        "col2": 56,
        "col3": "Молчановское сельское поселение",
        "col4": "636330, Томская область, Молчановский район, с. Молчаново, ул. Димитрова, 51, т. 8 (38256) 21-5-86, ml-molch@tomsk.gov.ru "
    },
    {
        "col1": "Томская область",
        "col2": 57,
        "col3": "Наргинское сельское поселение",
        "col4": "636346, Томская область, Молчановский район, с. Нарга, ул. К. Маркса, 41, adm-narga@molchanovo.gov70.ru\n8 (256) 32-4-95\n"
    },
    {
        "col1": "Томская область",
        "col2": 58,
        "col3": "Суйгинское сельское поселение",
        "col4": "636345 Томская область Молчановский район, с. Суйга, ул. Комарова, 29, suiga@molchanovo.gov70"
    },
    {
        "col1": "Томская область",
        "col2": 59,
        "col3": "Тунгусовское  сельское поселение",
        "col4": "636353, Томская область, Молчановский район, с. Тунгусово, ул. Кнакиса, 5, +7 (382-56) 35-4-37,  tungusovosp@molchanovo.gov70.ru"
    },
    {
        "col1": "Томская область",
        "col2": 60,
        "col3": "Заводское сельское поселение",
        "col4": "636608, Томская область, Парабельский район\nп. Заводской, ул. 60 лет СССР, 19/\n8(38252)3-91-66, 8(38252)3-91-34/ zavodskoe@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 61,
        "col3": "Нарымское сельское поселение",
        "col4": "636611, Томская область,  Парабельский район, \nс. Нарым, ул. Сибирская, 2/\n8(38252)3-32-31, 8(38252)3-32-32/ narim@parabel.gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 62,
        "col3": "Новосельцевское сельское поселение",
        "col4": "636607, Томская область, Парабельский район,\n с. Новосельцево , ул. Шишкова, 5/ 8(382525)3-61-58, 8(38252)3-61-58/\nn-selcevo@parabel.gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 63,
        "col3": "Парабельское сельское поселение",
        "col4": "636600, Томская область, Парабельский рвйон, \nс. Парабель , ул. Свердлова, 5/ 8(382525)2-19-63, 8(38252)2-13-43/\n      Sp-parab@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 64,
        "col3": "Старицинское сельское поселение",
        "col4": "636626, Томская область, Парабельский район, \nс. Старица, ул. Советская, 58 «а»/ 8-913-848-19-09/ istomina-1k@yandex.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 65,
        "col3": "Комсомольское сельское поселение",
        "col4": "636942, Томская область, Первомайский район, с. Комсомольск, ул. Железнодорожная 40/1, volkova.azlk1214@yandex.ru, 8(38245)23-249"
    },
    {
        "col1": "Томская область",
        "col2": 66,
        "col3": "Куяновское сельское поселение",
        "col4": "636953, Томская область, Первомайский район, село Куяново, ул. Центральная, 18/1, kuspmail@mail.ru, тел. 8 (38245) 33-198"
    },
    {
        "col1": "Томская область",
        "col2": 67,
        "col3": "Новомариинское сельское поселение",
        "col4": "636935 Томская обл., Первомайский район, с. Новомариинка, ул. Центральная, 42, novosp@tomsk.gov.ru, 8 (38245) 35-123"
    },
    {
        "col1": "Томская область",
        "col2": 68,
        "col3": "Первомайское сельское поселение",
        "col4": "636930, Томская область, Первомайский район, с. Первомайское, ул. Советская, д.14, pmsp@tomsk.gov.ru, 8 (38245)21-060"
    },
    {
        "col1": "Томская область",
        "col2": 69,
        "col3": "Сергеевское сельское поселение",
        "col4": "Сергеевское сельское поселение: 636941, Томская область, Первомайский район, с. Сергеево, ул. Школьная д. 3, sergeevo@tomsk.gov.ru, 8 (38245) 43-320"
    },
    {
        "col1": "Томская область",
        "col2": 70,
        "col3": "Улу-Юльское сельское поселение",
        "col4": "636948, Томская область, Первомайский район, поселок Улу-Юл, улица 50 Лет Октября, д.5 oks4na5mir@yandex.ru, тел. 8 (38245) 44-123"
    },
    {
        "col1": "Томская область",
        "col2": 71,
        "col3": "Белоярское сельское поселение",
        "col4": "636909, Томская область, Тегульдетский район, \nп. Белый Яр, ул. Центральная, 2\nТелефон: +7 (382-46) 3-41-15,\nE-mail: belyarsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 72,
        "col3": "Берегаевское сельское поселение",
        "col4": "636911, Томская область, Тегульдетский район,\nп. Берегаево, ул. Ленинская, д. 17а\nТелефон: +7 (382-46) 3-31-89,\nE-mail: beregsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 73,
        "col3": "Тегульдетское сельское поселение",
        "col4": "636900, Томская область, Тегульдетский район, \nс. Тегульдет, ул. Ленина, 156\nТелефон: +7 (382–46) 2-19-25, 2-19-13,\nE-mail: tegsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 74,
        "col3": "Черноярское сельское поселение",
        "col4": "636902, Томская область, Тегульдетский район, \nп. Черный Яр, ул. Комсомольская, 7\nТелефон: +7 (382–46) 3-11-42,\nE-mail: blacksp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 75,
        "col3": "Богашевское сельское поселение",
        "col4": "634570, Томская область, Томский район, с. Богашево, ул.Советская, 6, / \nадрес электронной\nпочты: bogashevo-sp@tomsky.gov70.ru/\nномер телефона 931-269\n"
    },
    {
        "col1": "Томская область",
        "col2": 76,
        "col3": "Воронинское сельское поселение",
        "col4": "634532, Томская область, Томский район, д. Воронино, ул. Центральная, 74/ \nадрес электронной\nпочты: voronino-sp@tomsky.gov70.ru/\nномер телефона 950-274\n"
    },
    {
        "col1": "Томская область",
        "col2": 77,
        "col3": "Заречное сельское поселение",
        "col4": "634521, Томская область, Томский район,  с.Кафтанчиково, ул.Коммунистическая, д.86 \nадрес электронной\nпочты: zarechnoe-sp@tomsky.gov70.ru /\nномер телефона 961-200\n"
    },
    {
        "col1": "Томская область",
        "col2": 78,
        "col3": "Зональненское сельское поселение",
        "col4": "634507, Томская область, Томский район, \nп.Зональная, ул.Совхозная, 10 / \nадрес электронной\nпочты: zonalniy-sp@tomsky.gov70.ru /\nномер телефона 923-969\n"
    },
    {
        "col1": "Томская область",
        "col2": 79,
        "col3": "Зоркальцевское сельское поселение",
        "col4": "634515, Томская область, Томский район, с. Зоркальцево, ул. Совхозная, 14/ \nадрес электронной\nпочты: zorkalsp@gov70.ru/\nномер телефона 915-790\n"
    },
    {
        "col1": "Томская область",
        "col2": 80,
        "col3": "Итатское сельское поселение",
        "col4": "634540, Томская область, Томский район, с. Итатка, ул. Гагарина,1 / \nадрес электронной\nпочты: itatkasp@gov70.ru /\nномер телефона 959-323\n"
    },
    {
        "col1": "Томская область",
        "col2": 81,
        "col3": "Калтайское сельское поселение",
        "col4": "634523, Томская область, Томский район, с. Курлек, ул. Трактовая, 48  / \nадрес электронной\nпочты: kaltay-sp@tomsky.gov70.ru, kaltai.adm.fin@mail.ru /\nномер телефона 968-293\n"
    },
    {
        "col1": "Томская область",
        "col2": 82,
        "col3": "Копыловское сельское поселение",
        "col4": "634537, Томская область, п. Копылово, ул. Новая, 13/\nадрес электронной\nпочты: kopylovosp@gov70.ru/\nномер телефона 989-101\n"
    },
    {
        "col1": "Томская область",
        "col2": 83,
        "col3": "Корниловское сельское поселение",
        "col4": "634538, Томская область, Томский район, с.Корнилово,  ул.Гагарина, 29А,/ \nадрес электронной\nпочты: kornilovosp@gov70.ru/\nномер телефона 963-171\n"
    },
    {
        "col1": "Томская область",
        "col2": 84,
        "col3": "Малиновское сельское поселение",
        "col4": "634580, Томская область, Томский район, с. Малиновка, ул. Чулымская, 30 / \nадрес электронной\nпочты: malinovka-sp@tomsky.gov70.ru/\nномер телефона 920-144\n"
    },
    {
        "col1": "Томская область",
        "col2": 85,
        "col3": "Межениновское сельское поселение",
        "col4": "634520, Томская область, Томский район,  с.Межениновка, ул.Первомайская, 23, / \nадрес электронной\nпочты: mezheninsp@gov70.ru/\nномер телефона 969-725\n"
    },
    {
        "col1": "Томская область",
        "col2": 86,
        "col3": "Мирненское сельское поселение",
        "col4": "634539, Томская область, Томский район, п. Мирный, ул. Трудовая, 10 / \nадрес электронной\nпочты: mirniy-sp@tomsky.gov70.ru/\nномер телефона 955-198\n"
    },
    {
        "col1": "Томская область",
        "col2": 87,
        "col3": "Моряковское сельское поселение",
        "col4": "634516, Томская облатсь, Томский район, с. Моряковский Затон, ул. Советская, 27/ \nадрес электронной\nпочты: morzaton-sp@tomsky.gov70.ru/\nномер телефона 927-498\n"
    },
    {
        "col1": "Томская область",
        "col2": 88,
        "col3": "Наумовское сельское поселение",
        "col4": "634581, Томская область, Томский рай1он, с. Наумовка, ул. Советская, 7А/ \nадрес электронной\nпочты: naumovkasp@gov70.ru /\nномер телефона 969-339\n"
    },
    {
        "col1": "Томская область",
        "col2": 89,
        "col3": "Новорождественское сельское поселение",
        "col4": "634535, Томская область, Томский район, с.Новорождественское, ул. Советская, 62/ \nадрес электронной\nпочты: n-rozhdestven-sp@tomsky.gov70.ru/\nномер телефона 946-589\n"
    },
    {
        "col1": "Томская область",
        "col2": 90,
        "col3": "Октябрьское сельское поселение",
        "col4": "634583, Томская область, Томский район, с. Октябрьское, ул. Заводская,14/ адрес электронной\nпочты: oktyabr-sp@tomsky.gov70.ru /\nномер телефона 925-211\n"
    },
    {
        "col1": "Томская область",
        "col2": 91,
        "col3": "Рыболовское сельское поселение",
        "col4": "634518, Томская область, Томский район, с.Рыбалово, ул.Коммунистическая, 7,/ \nадрес электронной\nпочты: rybalovo-sp@tomsky.gov70.ru /\nномер телефона 919-131\n"
    },
    {
        "col1": "Томская область",
        "col2": 92,
        "col3": "Спасское сельское поселение",
        "col4": "пер.Новый, с.Вершинино, Томский район, Томская область, 634528, Томская область, Томский район, с. Вершинино, пер. Новый / \nадрес электронной\nпочты: spasskoe-sp@tomsky.gov70.ru /\nномер телефона 959-608\n"
    },
    {
        "col1": "Томская область",
        "col2": 93,
        "col3": "Турунтаевское сельское поселение",
        "col4": "634534, Томская область, Томский район, с. Турунтаево, ул. Новая, 2а / \nадрес электронной\nпочты: turuntaevo-sp@tomsky.gov70.ru /\nномер телефона 947-175\n"
    },
    {
        "col1": "Томская область",
        "col2": 94,
        "col3": "Коломинское сельское поселение",
        "col4": "636410, Томская область, Чаинский район, с. Коломинские Гривы, ул. Советская, 21/ kolomin@tomsk.gov.ru/ 8(38257) 45244"
    },
    {
        "col1": "Томская область",
        "col2": 95,
        "col3": "Подгорнское сельское поселение",
        "col4": "636400, Томская область, Чаинский район, \nс. Подгорное, ул. Ленинская, 4, стр. 1/ podgorns@tomsk.gov.ru/ 8(38257) 21102\n"
    },
    {
        "col1": "Томская область",
        "col2": 96,
        "col3": "Усть-Бакчарское сельское поселение",
        "col4": "636404, Томская область, Чаинский район, \nс. Усть-Бакчар, ул. Центральная, 17/ inna_novokreshenova@mail.ru/ \n8(38257) 35167\n"
    },
    {
        "col1": "Томская область",
        "col2": 97,
        "col3": "Чаинское сельское поселение",
        "col4": "636407,Томская область, Чаинский район, \nс. Чаинск, ул. Комсомольская, 14/ chainsksp@mail.ru/ 8(38257) 56119\n"
    },
    {
        "col1": "Томская область",
        "col2": 98,
        "col3": "Анастасьевское сельское поселение",
        "col4": "636149, Томская область. Шегарский район,\nс. Анастасьевка, пер. Школьный, д.2\nanastasevka05@mail.ru\n8(38247)3-91-37\n"
    },
    {
        "col1": "Томская область",
        "col2": 99,
        "col3": "Баткатское сельское поселение",
        "col4": "636141, Томская область, Шегарский район,\nс. Баткат, пер. Кооперативный, д.1\nadm-batkat@shegarsky.gov70.ru\n8(38247) 341-32, 8(38247)341-09\n"
    },
    {
        "col1": "Томская область",
        "col2": 100,
        "col3": "Побединское сеельское поселение ",
        "col4": "636143, п. Победа, Шегарский район,\nТомская область,\nул. Коммунистическая д.112а, помещение 2\nssamotoylov@mail.ru\npobeda@tomsk.gov.ru\n8(38247) 42-195, 8(38247) 42-264\n"
    },
    {
        "col1": "Томская область",
        "col2": 101,
        "col3": "Северное сельское поселение",
        "col4": "636156,Томская область, Шегарский район,\nс. Монастырка, ул. Советская, д.51а\nadm-severnoe@shegarsky.gov70.ru\n+7 (382-47) 2-97-84\n"
    },
    {
        "col1": "Томская область",
        "col2": 102,
        "col3": "Трубачевское сельское поселение",
        "col4": "636145, Томская область. Шегарский район,\nс. Трубачево, ул. Центральная, д.7\ntrubachevskoe@mail.ru\n8(38247) 38-116, 8(38247) 38-127\n"
    },
    {
        "col1": "Томская область",
        "col2": 103,
        "col3": "Шегарское сельское поселение",
        "col4": "636130,Томская область, Шегарский район,\nс.Мельниково, ул. Московская,д.13\nshegarka00@mail.ru\n8 (38247) 2-11-72\n8(38247) 2-37-29\n"
    },
    {
        "col1": "Томская область",
        "col2": 104,
        "col3": "Отдел опеки и попечительства Администрации города Кедрового",
        "col4": " 636615, Томская область,  г. Кедровый, 1-й мкр. ул., д.39/1,\nkedropeka@tomsk.gov.ru\n(38250)35-314\n"
    },
    {
        "col1": "Томская область",
        "col2": 105,
        "col3": "Администрация ЗАТО Северск",
        "col4": "636070, Томская область, г. Северск, пр. Коммунистический, 51\n8 (382-3) 77-23-23\nzato-seversk@gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 106,
        "col3": "Администрация городского округа Стрежевой Отдел жилищной политики",
        "col4": "636785, Томская область, г. Стрежевой, ул. Ермакова, д,46А,                                               8 (38259) 51-560,                                     Koroleva@admstrj.romsk.ru"
    },
    {
        "col1": "Томская область",
        "col2": 107,
        "col3": "Департамент Жилищной Политики администрации Города Томска ",
        "col4": " 634050, г. Томск, пр-т Ленина, д. 73\nmaildzp@admin.tomsk.ru\n+7 3822 90-82-11\n+7 3822 90-82-19\n"
    },
    {
        "col1": "Тульская область",
        "col2": 1,
        "col3": "Государственное учреждение Тульской области \"Центр технического надзора эксплуатации зданий и сооружений министерства труда и социальной защиты Тульской области\"",
        "col4": "г. Тула, ул. Епифанская, д. 189, тел. 70-14-47, ctnz@tularegion.ru"
    },
    {
        "col1": "Тюменcкая область",
        "col2": 1,
        "col3": "Территориальные подразделения Департамента социального развития Тюменской области",
        "col4": "Территориальные подразделения Департамента социального развития Тюменской области\nhttps://soc.admtyumen.ru/OIGV/dsrto/about/structure/soc_zash.htm "
    },
    {
        "col1": "Тюменcкая область",
        "col2": 2,
        "col3": "Департамент социального развития Тюменской области",
        "col4": "\n625048, Тюменская обл., г. Тюмень, ул. Республики, д. 83а / dsoc@72to.ru / (3452) 50-21-68"
    },
    {
        "col1": "Удмуртская Республика",
        "col2": 1,
        "col3": "Министерство строительства, жилищно-коммунального хозяйства и энергетики Удмуртской Республики",
        "col4": "426069, Удмуртская Республика, город Ижевск, улица Песочная, д.9, адрес электронной почты: mail@mst.udmr.ru, телефон: +7 (3412) 59-88-52 "
    },
    {
        "col1": "Ульяновская область\n",
        "col2": 1,
        "col3": "Министерство жилищно-коммунального хозяйства и строительства Ульяновской области",
        "col4": "432017, г. Ульяновск, ул. Спасская, д.8, (8422) 58-40-57 cun173@mail.ru"
    },
    {
        "col1": "Хабаровский край",
        "col2": 1,
        "col3": "администрация городского округа \"город Хабаровск\"",
        "col4": "########"
    },
    {
        "col1": "Хабаровский край",
        "col2": 2,
        "col3": "администрация городского округа\n\"Город Комсомольск-на-Амуре\"",
        "col4": "########"
    },
    {
        "col1": "Хабаровский край",
        "col2": 3,
        "col3": "администрация Амурского муниципального района",
        "col4": "682640 г. Амурск, пр. Мира, 11, г. Амурск. E-mail:  admin@amursk-rayon.ru. Тел.: 8-4214-2-04-28"
    },
    {
        "col1": "Хабаровский край",
        "col2": 4,
        "col3": "администрация Аяно-Майского муниципального района",
        "col4": "682571 с. Аян, ул. Советская, 8. E-mail:  sistadmin123@mail.ru. Тел.: 8-42147-21-3-30"
    },
    {
        "col1": "Хабаровский край",
        "col2": 5,
        "col3": "администрация Бикинского муниципального округа",
        "col4": "682970 г. Бикин, пер. Советский, 2. E-mail:  info@bikinadm.ru. Тел.: 8-42155-21-2-80"
    },
    {
        "col1": "Хабаровский край",
        "col2": 6,
        "col3": "администрация Ванинкого муниципального района",
        "col4": "682860 рп. Ванино, площадь Мира, 1. E-mail:  uprav@vanino.org. Тел.: 8-42137-5-51-02"
    },
    {
        "col1": "Хабаровский край",
        "col2": 7,
        "col3": "администрация Верхнебуреинского муниципального района",
        "col4": "682030 п. Чегомын, ул. Центральная, 49. E-mail:  admvbr_orgotdel@mail.ru. Тел.: 8-42149-5-17-62"
    },
    {
        "col1": "Хабаровский край",
        "col2": 8,
        "col3": "администрация Вяземского муниципального района",
        "col4": "682950 г. Вяземский, ул. Коммунистическая, 8. E-mail:  vzmadmin@vzm.kht.ru. Тел.:  8-42153-3-10-35"
    },
    {
        "col1": "Хабаровский край",
        "col2": 9,
        "col3": "администрация Комсомольского муниципального района",
        "col4": "########"
    },
    {
        "col1": "Хабаровский край",
        "col2": 10,
        "col3": "администрация муниципального района имени Лазо",
        "col4": "682910 рп. Переяславка, ул. Октябрьская, 35. E-mail:  adm-lazo@mail.ru. Тел.: 8-42154-21-6-36"
    },
    {
        "col1": "Хабаровский край",
        "col2": 11,
        "col3": "администрация Нанайского муниципального района",
        "col4": "682350 с. Троицкое, ул. Калинина, 102. E-mail:  nanai-adm@mail.ru. Тел.: 8-42156-4-15-85"
    },
    {
        "col1": "Хабаровский край",
        "col2": 12,
        "col3": "администрация Николаевского муниципального района",
        "col4": "682460 г. Николаевск-на-Амуре, ул. Советская, 73. E-mail:  nikoladm@mail.ru. Тел.: 8-42135-2-22-36"
    },
    {
        "col1": "Хабаровский край",
        "col2": 13,
        "col3": "администрация Охотского муниципального округа",
        "col4": "682480 рп. Охотск, ул. Ленина, 16. E-mail:  admohotsk@yandex.ru. Тел.:  8-42141-9-14-72"
    },
    {
        "col1": "Хабаровский край",
        "col2": 14,
        "col3": "администрация муниципального района имени Полины Осипенко",
        "col4": "682380 с. Им. П. Осипенко, ул. Амгуньская, 72. E-mail:  im.p.osipenko@mail.ru. Тел.: 8-42144-21-4-57"
    },
    {
        "col1": "Хабаровский край",
        "col2": 15,
        "col3": "администрация Советко-Гаванского муниципального района",
        "col4": "682800 г. Советская Гавань, ул. Ленина, 3. E-mail:  adm@asgmr.ru. Тел.: 8-42138-45-1-12"
    },
    {
        "col1": "Хабаровский край",
        "col2": 16,
        "col3": "администрация Солнечного муниципального района",
        "col4": "682711 рп. Солнечный, ул. Ленина, 23. E-mail:  Asr@solnraion.ru. Тел.: 8-42146-2-61-00"
    },
    {
        "col1": "Хабаровский край",
        "col2": 17,
        "col3": "администрация Тугуро-Чумиканского муниципального района",
        "col4": "682560 с. Чумикан, пер. Советский, 3. E-mail:  chumikan@chumikan.khv.ru. Тел.: 8-42143-91-3-84"
    },
    {
        "col1": "Хабаровский край",
        "col2": 18,
        "col3": "администрация Ульчского муниципального района",
        "col4": "682400 с. Богородское, ул. 30 лет Победы, 54. E-mail:  master@adminulchi.ru. Тел.: 8-42151-5-14-59"
    },
    {
        "col1": "Хабаровский край",
        "col2": 19,
        "col3": "администрация Хабаровского муниципального района",
        "col4": "########"
    },
    {
        "col1": "Херсонская область",
        "col2": 1,
        "col3": "Администрация Алешкинского муниципального округа\n",
        "col4": "275100; Российская Федерация, Херсонская область,  г. Алешки, ул. Гвардейская, 24/ oleshki@khogov.ru/         +7 (990) 138-50-61"
    },
    {
        "col1": "Херсонская область",
        "col2": 2,
        "col3": "Администрация Великолепетихского муниципального округа\n",
        "col4": "Херсонская область, Великолепетихский р-н,                 пгт. Великая Лепетиха,                    ул. Соборная, д. 3а/ velikolepetikhskii_mo@khogov.ru/ +7(990)153-11-98"
    },
    {
        "col1": "Херсонская область",
        "col2": 3,
        "col3": "Администрация Верхнерогачикского муниципального округа",
        "col4": "274402;Российская Федерация, Херсонская обл., пгт. Верхний Рогачик, ул. Центральная, 48/  verhniyrogachik.vga@khogov.ru/     +7(990)072–32–45    "
    },
    {
        "col1": "Херсонская область",
        "col2": 4,
        "col3": "Администрация                                              Генического муниципального округа\n",
        "col4": "275502; Российская Федерация, Херсонская область, г. Геническ,    ул. Центральная, 5/ genichesk@khogov.ru/       +7(990)111-68-27"
    },
    {
        "col1": "Херсонская область",
        "col2": 5,
        "col3": "Администрация Голопристанского         муниципального округа\n",
        "col4": "275600; Российская Федерация, Херсонская область, г. Голая Пристань, ул. 1 Мая, 41/ golapristan@khogov.ru/      +7(990)225-19-39\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 6,
        "col3": "Администрация Горностаевского муниципального округа\n",
        "col4": "274601; Российская Федерация, Херсонская область,                       пгт. Горностаевка, ул. Покровская, 145/ gornastaevskaya_vga@khogov.ru/   80 (5544) 4-11-43"
    },
    {
        "col1": "Херсонская область",
        "col2": 7,
        "col3": "Администрация Ивановского муниципального округа\n",
        "col4": "275401; Российская Федерация, Херсонская область, пгт. Ивановка, ул. Ивановская, 34а/ ivanovka@khogov.ru/      +79900137991\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 8,
        "col3": "Администрация Каланчакского муниципального округа",
        "col4": "275800; Российская Федерация, Херсонская область, пгт. Каланчак, ул. Херсонская, 1/ vga.kalanchak@khogov.ru/ +7(990)100–58–00\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 9,
        "col3": "Администрация Каховского муниципального округа\n",
        "col4": "274803; Российская Федерация, Херсонская область, г. Каховка,     ул. Большая Куликовская, 103/ ivga.kakhovka@yandex.ru/ +79900674406 "
    },
    {
        "col1": "Херсонская область",
        "col2": 10,
        "col3": "Администрация Нижнесерогозского муниципального округа\n",
        "col4": "274701; Российская Федерация, Херсонская область, пгт. Нижние Серогозы, ул. Садовая, 7/ nizhneserogozsk@khogov.ru/            +7 (990) 092-04-09\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 11,
        "col3": "Администрация Новотроицкого муниципального округа",
        "col4": "275300; Российская Федерация, Херсонская область, пгт. Новотроицкое, ул. Соборная, 73/ Novotroitsk_admin@khogov.ru/ +7(990)159–17–58\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 12,
        "col3": "Администрация Скадовского муниципального округа",
        "col4": "275700; Российская Федерация, Херсонская область, г. Скадовск, ул.Александровская, 34/ skadovsk@khogov.ru/ +7(990)104–18–19\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 13,
        "col3": "Администрация Чаплынского муниципального округа",
        "col4": "275200; Российская Федерация, Херсонская область, Чаплынский район, пгт. Чаплынка, ул. Парковая, 40/ chaplvga@khogov.ru/ +7(990)111–35–94; +7(990)036–21–91\n"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 1,
        "col3": "Департамент строительства и архитектуры Ханты-Мансийского автономного округа - Югры\n",
        "col4": "628011, ул. Мира, 18, г. Ханты-Мансийск, \nХанты-Мансийский автономный округ – Югра, \n8 (3467) 300-376 доб.3504,\nds@admhmao.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 1,
        "col3": "Администрация Агаповского мунципального района Челябинской области",
        "col4": "457400, Челябинская область, Агаповский район, с.Агаповка, ул.Дорожная, д.32а, тел. 8(35140)2-19-00, agapovka74@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 2,
        "col3": "Администрация Аргаяшского муницпрпального района Челябинской области",
        "col4": "456880, Челябинская область, Аргаяшский муниципальный район, село Аргаяш, улица 8 Марта, дом 38, argayash@gov74.ru, тел. (35131) 2-00-29\nkui@argayash.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 3,
        "col3": "Комитет по управлению имуществом администрации Ашинского муниципального района Челябинской области",
        "col4": "456010, Челябинская область, Ашинский муниципальный район, г.Аша, ул.Толстого, д. 10, 8(35159)35303, kumiamr@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 4,
        "col3": "Администрация Брединского мунципального района Челябинской области",
        "col4": "457310, Челябинская область, Брединский район, п. Бреды ул. Гербанова, д.40 тел: (35141)3-41-00; bredy74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 5,
        "col3": "Управление по имущественной политике и координации деятельности в сфере государственных и муниципальных услуг администрации Варненского муниципального района ",
        "col4": "457200, Челябинская область, м. р-н Варненский, с. п. Варненское, с. Варна, ул. Советская, д. 94, офис 10 тел.+7 (35142) 2-21-40"
    },
    {
        "col1": "Челябинская область",
        "col2": 6,
        "col3": "Управление имуществом и земельными отношениями администрации Верхнеуральского муниципального района ",
        "col4": "457670, Челябинская область, Верхнеуральский район, г. Верхнеуральск, ул. Еремина, 43 verhneuralsk@qov74.ru 8(35143) 2-23-35"
    },
    {
        "col1": "Челябинская область",
        "col2": 7,
        "col3": "Администрация Верхнеуфалейского городского округа",
        "col4": "ул. Ленина 188, г. Верхний Уфалей Челябинской области, 456800\nE.mail - ufaley@gov74.ru\nтелефон. 3-10-05; факс: 3-20-00 - Единая Дежурно-диспетчерская служба Верхнеуфалейского городского округа"
    },
    {
        "col1": "Челябинская область",
        "col2": 8,
        "col3": "Администрация Еманжелинского муниципального района Челябинской области",
        "col4": "456580 Челябинская область, г.Еманжелинск, ул.Мира, д. 18, 835138-2-19-28, 21928@admemr.ru "
    },
    {
        "col1": "Челябинская область",
        "col2": 9,
        "col3": "Управление социальной защиты населения администрации Еткульского муниципального района",
        "col4": "456560, Челябинская область,Еткульский район,с.Еткуль, ул.Ленина, д.33, uszn10@minsoc74.ru, 8(35145)2-21-43"
    },
    {
        "col1": "Челябинская область",
        "col2": 10,
        "col3": "Администрация Златоустовского городского округа Челябинской области, МКУ \"Управление жилищно-комунальным хозяйством\"",
        "col4": "456200 г.Златоуст, ул. Таганайская, 1, zgogkh@yandex.ru, 8-3513-671-672 доб.220"
    },
    {
        "col1": "Челябинская область",
        "col2": 11,
        "col3": "Администрация Карабашского городского округа",
        "col4": "456143, Челябинская область, г. Карабаш, ул. Металлургов, д. 3, каб. 216 8(35153)2-49-44 imkarabash@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 12,
        "col3": "Администрация Карталинского муниципального района",
        "col4": "457351, Челябинская область, город Карталы, улица Ленина, дом 1, architektura.kmr@mail.ru, 8(35133)22805"
    },
    {
        "col1": "Челябинская область",
        "col2": 13,
        "col3": "Управление строительства и инфрастуктуры администрации Каслинского муниципального района",
        "col4": "456830, Челябинская область, г. Касли, ул. Ленина, д. 55, 83514922007 admkasli@yandex.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 14,
        "col3": "Администрация Катав-Ивановского городского поселения, Администрация Юрюзанского городского поселения ",
        "col4": "456110, Челябинская область, г. Катав-Ивановск, ул. Ленина, д. 16, kativ111mail.ru 8 35147 2-04-24; 456120 Челябинская область, г. Юрюзань, ул. Зайцева, д. 9Б, uruzan@katavivan.dov74.ru 8 35147 2-56-83"
    },
    {
        "col1": "Челябинская область",
        "col2": 15,
        "col3": "Администрация Кизильского муниципального района Челябинской области",
        "col4": "457610, Челябинская область, Кизильский район, с. Кизильское, ул. Советская, 65, kizil@gov74.ru 8(35155) 3-04-02"
    },
    {
        "col1": "Челябинская область",
        "col2": 16,
        "col3": "Администрация Копейского городского округа Челябинской области",
        "col4": "456618, Челябинская область, г. Копейск, ул. Ленина, д. 52, (351)394-01-45 kopeysk@akgo74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 17,
        "col3": "Начальнику Управления муниципального имущества и земельных отношений",
        "col4": "456550, Челябинская область, город Коркино, ул. 30 лет ВЛКСМ, 27А"
    },
    {
        "col1": "Челябинская область",
        "col2": 18,
        "col3": "Управление социальной защиты населения администрации Красноармейского муниципального района Челябинской области",
        "col4": "456660, Челябинская область, Красноармейский райо, с. Миасское, ул. Спортивная, д. 8а, тел. 8 351 729-98-83, uszn25_opek@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 19,
        "col3": "Управление имущественных и земельных отношений Администрации Кунашакского муниципального района",
        "col4": "456730, Челябинская обл., Кунашакский район, с.Кунашак ул. Ленина д.103 каб.23, 83514820061@mail.ru, тел. 8(35148)2-00-61"
    },
    {
        "col1": "Челябинская область",
        "col2": 20,
        "col3": "Управление социальной защиты населения Кусинского муниципального района",
        "col4": "455044, Челябинская область, г. Куса, ул. Ленина, д. 8"
    },
    {
        "col1": "Челябинская область",
        "col2": 21,
        "col3": "Администрация Кыштымского городского округа Челябинской области",
        "col4": "456870, Челябинская область, г. Кыштым, площадь Карла Маркса, д.1, kyshtym@gov74.ru тел. 8(35151) 4-31-15, 4-05-50"
    },
    {
        "col1": "Челябинская область",
        "col2": 22,
        "col3": "Администрация Локомотивного городского округа Челябинской области",
        "col4": "457390, Челябинская область, п.Локомотивный, ул.Мира, д.60, lokomotivny.ru тел. 8(351) 5-67-75"
    },
    {
        "col1": "Челябинская область",
        "col2": 23,
        "col3": "Управление транспорта и коммунального хозяйства администрации города Магнитогорска",
        "col4": "455044, Челябинская область, г. Магнитогорск, пр. Ленина, 72/transport@magnitogorsk.ru/8 (3519) 49-85-65"
    },
    {
        "col1": "Челябинская область",
        "col2": 24,
        "col3": "Отдел приватизации и жилищных вопросов Управления имущественных отношений Администрации миасского городского округа Челябинской области",
        "col4": "456300, Челябинская область, г.Миасс, ул.Романенко, д.50а, каб.204, valetov.so@miass.gov74.ru, тел.8(3513)26-42-62 (доб.3)"
    },
    {
        "col1": "Челябинская область",
        "col2": 25,
        "col3": "Управление социальной защиты населения Нагайбакского муниципального района",
        "col4": "457650, Челябинская область, гс. Фершампенуаз, ул. Труда 64/1, uszn34@mincos74,ru 8 (3519) 22149, 8(35157)22261"
    },
    {
        "col1": "Челябинская область",
        "col2": 26,
        "col3": "Управление социальной защиты населения Нязепетровского муниципального округа",
        "col4": "456970, Челябинская область, г. Нязепетровск, ул. Свердлова, д.74 А. uszn27_opeka@minsoc74.ru 8(35156)32026"
    },
    {
        "col1": "Челябинская область",
        "col2": 27,
        "col3": "Управление архитектуры и градостроительства администрации Озерского городского округа",
        "col4": "456780, Челябинская область, г. Озерск, пр. Ленина, д. 62, 8 (35130) 2-61-97, ujkh@ozerskadm.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 28,
        "col3": "Управление по муниципальному имуществу, земельным отношениям и развитию сельского хозяйства администрации Октябрьского муниципального района Челябинской области",
        "col4": "457170, Челябинская область, Октябрьский район, село Октябрьское, ул.Ленина, д.36 8 (35158) 5-34-53, 5-24-46,5-20-08 umizosh@okt174.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 29,
        "col3": "Управление социальной защиты населения Пластовского муниципального округа",
        "col4": "457020, Челябинская область, г. Пласт, ул. Чайковского, 1а, uszn29@mincos74.ru 8 (351)-60-2-13-53; 8 (351) -60-2-07-37"
    },
    {
        "col1": "Челябинская область",
        "col2": 30,
        "col3": "Управление социальной защиты населения администрации Саткинского муниципального округа",
        "col4": "456910, Челябинская область, г. Сатка, ул. Индустриальная, д.20, uszn31@minsoc74.ru, 8(35161)41168"
    },
    {
        "col1": "Челябинская область",
        "col2": 31,
        "col3": "Муниципальное казённое учреждение \"Управление социальной защиты населения города Снежинска\"",
        "col4": "456770, Челябинская область, г. Снежинск, ул. Транспортная, д. № 5, uszn93@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 32,
        "col3": "Управление социальной защиты населения администрации Сосновского муниципального района Челябинской области",
        "col4": "456510 Челябинская область, Сосновский район, с. Долгодеревенское, ул. Свердловская, д. 2В, н.п. 7 8(35144)45-300"
    },
    {
        "col1": "Челябинская область",
        "col2": 33,
        "col3": "Администрация города Трехгорного Челябинской области",
        "col4": "456080, Челябинская область, г. Трехгорный, ул. Мира, д. 6, adm@trg.gov74.ru, 8(35191)62052"
    },
    {
        "col1": "Челябинская область",
        "col2": 34,
        "col3": "Управление социальной защиты населения администрации грода Троицка Челябинской области",
        "col4": "457100, Челябинскаобласть, г. Троицк, ул. Фрунзе, д. 2, uszn32@minsoc74.ru, 83516322724"
    },
    {
        "col1": "Челябинская область",
        "col2": 35,
        "col3": "Управление социальной защиты населения Троицкого муниципального района",
        "col4": "457100, Челябинская область, г. Троицк, ул. 30 лет ВЛКСМ, д.12 uszn61@minsoc74.ru, 8(35163)2-09-72"
    },
    {
        "col1": "Челябинская область",
        "col2": 36,
        "col3": "Отдел жилищной политики администрации Увельского муниципального района",
        "col4": "457000, Челябинская область, Увельский район, п.Увельский, ул. Советская, д.24, otdgilpolitikiuvelka@mail.ru, 8(35166)3-25-89"
    },
    {
        "col1": "Челябинская область",
        "col2": 37,
        "col3": "Администрация Уйского муниципального района",
        "col4": "456470, Челябинская область, Уйский район, с.Уйское, ул. Балмасова,д. 6, uyskiy@mail.ru, 8 (35165) 3-10-62"
    },
    {
        "col1": "Челябинская область",
        "col2": 38,
        "col3": "Администраця Усть-Катавского городского округа",
        "col4": "456043, Челябинская область, г. Усть-Катав, ул. Ленина, д. 47а   \nadm-pressa@yandex.ru\n8 (35167)2-52-34"
    },
    {
        "col1": "Челябинская область",
        "col2": 39,
        "col3": "Управление жилищно-коммунального хозяйства администрации Чебаркульского городского округа",
        "col4": "456440, Челябинская область, г. Чебаркуль, ул. Ленина, д. 15, тел. 8(351-68)2-44-98, chebgkx@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 40,
        "col3": "Управление строительства, ЖКХ и архитектуры администрации Чебаркульского муниципального района",
        "col4": "Челябинская область, г. Чебаркуль, ул. Электростальская, 34Б arhgrad.chmr@mail.ru Телефон: 8 (35168) 2-77-48 "
    },
    {
        "col1": "Челябинская область",
        "col2": 41,
        "col3": "Управление жилищно-комунального\n хозяйства города Челябинска",
        "col4": "454092, Челябинская область  \nг. Челябинск, \nул. Воровского, д. 5-а, \nэл. почта: zkh@cheladmin.ru,\n+7 (351) 263 48 91"
    },
    {
        "col1": "Челябинская область",
        "col2": 42,
        "col3": "Управление жилищно-комунального\n хозяйства города Челябинска",
        "col4": "454092, Челябинская область  \nг. Челябинск, \nул. Воровского, д. 5-а, \nэл. почта: zkh@cheladmin.ru,\n+7 (351) 263 48 92"
    },
    {
        "col1": "Челябинская область",
        "col2": 43,
        "col3": "Управление жилищно-комунального\n хозяйства города Челябинска",
        "col4": "454092, Челябинская область  \nг. Челябинск, \nул. Воровского, д. 5-а, \nэл. почта: zkh@cheladmin.ru,\n+7 (351) 263 48 93"
    },
    {
        "col1": "Челябинская область",
        "col2": 44,
        "col3": "Управление жилищно-комунального\n хозяйства города Челябинска",
        "col4": "454092, Челябинская область  \nг. Челябинск, \nул. Воровского, д. 5-а, \nэл. почта: zkh@cheladmin.ru,\n+7 (351) 263 48 94"
    },
    {
        "col1": "Челябинская область",
        "col2": 45,
        "col3": "Управление жилищно-комунального\n хозяйства города Челябинска",
        "col4": "454092, Челябинская область  \nг. Челябинск, \nул. Воровского, д. 5-а, \nэл. почта: zkh@cheladmin.ru,\n+7 (351) 263 48 95"
    },
    {
        "col1": "Челябинская область",
        "col2": 46,
        "col3": "Управление жилищно-комунального\n хозяйства города Челябинска",
        "col4": "454092, Челябинская область  \nг. Челябинск, \nул. Воровского, д. 5-а, \nэл. почта: zkh@cheladmin.ru,\n+7 (351) 263 48 96"
    },
    {
        "col1": "Челябинская область",
        "col2": 47,
        "col3": "Управление жилищно-комунального\n хозяйства города Челябинска",
        "col4": "454092, Челябинская область  \nг. Челябинск, \nул. Воровского, д. 5-а, \nэл. почта: zkh@cheladmin.ru,\n+7 (351) 263 48 97"
    },
    {
        "col1": "Челябинская область",
        "col2": 48,
        "col3": "Управление жилищно-комунального\n хозяйства города Челябинска",
        "col4": "454092, Челябинская область  \nг. Челябинск, \nул. Воровского, д. 5-а, \nэл. почта: zkh@cheladmin.ru,\n+7 (351) 263 48 98"
    },
    {
        "col1": "Челябинская область",
        "col2": 49,
        "col3": "Администрация Чесменского муниципального района Челябинской области",
        "col4": "457220 Челябинская область, Чесменский район, с. Чесма, ул. Советская, д. 47, тел 8(35169)2-11-32, эл. адрес: adm_chesma74@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 50,
        "col3": "Администрация Южноуральского городского округа",
        "col4": "457040, Челябинская область, г. Южноуральск, ул. Космонавтов, д. 14, эл. почта:"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 1,
        "col3": "МУ «Управление образования Ачхой-Мартановского муниципального района»",
        "col4": "366613, Чеченская Республика, Ачхой-Мартановский район, г. Ачхой-Мартан, ул. Х. Нурадилова, д. 127\nтелефон: 8 (8714) 22 22 90;\nадрес электронной почты: uo-achhoy@mail.ru"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 2,
        "col3": "Администрация Веденского муниципального района",
        "col4": "366337, Чеченская Республика, с. Ведено, пер. А.-Х. Кадырова, д. 5. телефон: 8 (87134) 222-11;\nадрес электронной почты: vedeno_admin@mail.ru"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 3,
        "col3": "Администрация Гудермесского муниципального района",
        "col4": " 366208, Гудермесский муниципальный район,\nг. Гудермес, пр. Кадырова, 17;\nтелефон: 8 (8715) 22 30 00;\nадрес электронной почты: gudermes.95@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 4,
        "col3": "Администрация Грозненского муниципального района",
        "col4": "366012, Грозненский муниципальный район,\nс. Толстой-Юрт, ул. А. Кадырова, 10;                                                              телефон: 8 (8712) 29-53-19\nадрес электронной почты: adm@grozraion.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 5,
        "col3": "МУ «Итум-Калинский Районный отдел образования»",
        "col4": "366404, Чеченская Республика, Итум-Калинский район, с. Итум-Кали, ул. А. Д. Имадаева, д. 15.                           телефон: 8 (8716) 42-22-29; 8 (928) 888-29-99\n\nадрес электронной почты: uo-itumkal@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 6,
        "col3": "Администрация Курчалоевского муниципального района",
        "col4": "366314, Чеченская Республика, Курчалоевский район, г. Курчалой, проспект им. Ахмат-Хаджи Кадырова, 50                                                                телефон: 8 (8715) 52-23-18,\nадрес электронной почты: akmr@admin-kmr.org"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 7,
        "col3": "Администрация Надтеречного муниципального района",
        "col4": "366831, Чеченская Республика, Надтеречный район, с. Знаменское, ул. А. Кадырова, д. 37                                           телефон: 8 (8713) 22 27 52;\nадрес электронной почты:  tercyist@надтеречный.рф"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 8,
        "col3": "МУ «Наурское районное управление образования»",
        "col4": "366128, ст. Наурская, Наурский район, ул. Лермонтова, 36                                                                  телефон 8 (8714) 32 24 91;\nадрес электронной почты:  uo-naur@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 9,
        "col3": "Администрация Ножай-Юртовского муниципального района",
        "col4": "366241, с. Ножай-Юрт, Ножай-Юртовский район, ул. А.А. Кадырова, 6;\nтелефон: 8 (8714) 82 22 57;\nадрес электронной почты: mail@nojay-urt.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 10,
        "col3": "Администрация Серноводского муниципального района",
        "col4": "366701, с. Серноводское, Серноводский район, ул. Зурабова, 6;\nтелефон: 8 (8715) 42 22 25;\nадрес электронной почты: sunjaadm85@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 11,
        "col3": "Администрация Урус-Мартановского\nмуниципального района",
        "col4": "366500, Урус-Мартановский муниципальный район, г. Урус-Мартан, ул. С-Э.М. Асхабова, 1 А;\nтелефон: 8 (8714) 52 23 35;\nадрес электронной почты: admurus@u-martan.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 12,
        "col3": "МУ «Управление образования Шалинского муниципального района»",
        "col4": "366300, Шалинский муниципальный район г. Шали, ул. Учительская, 3;\nтелефон: 8 (8714) 62 27 06;\nадрес электронной почты: ruoshali@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 13,
        "col3": "Администрация Шаройского муниципального района",
        "col4": "366413, с. Химой, Шаройский муниципальный район, ул. М. Мусалова, 5;\nтелефон: 8 (8712) 22 54 69;\nадрес электронной почты: admisharoi@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 14,
        "col3": "Администрация Шатойского муниципального района",
        "col4": "366400, Шатойский муниципальный район, с. Шатой, ул. Кооперативная, 1\nтелефон: 8(8713) 52-22-29\nадрес электронной почты admin@shatoy-chr.ru"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 15,
        "col3": "Администрация Шелковского муниципального района",
        "col4": "366108, ст. Шелковская, Шелковской район, ул. Советская, 37;\n8 (8713) 62 22 03;\nshelkovskoy-rn@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 16,
        "col3": "МУ «Департамент образования  г. Аргуна»",
        "col4": "366310, г. Аргун, ул. Аксактемирова, 5;\nтелефон: 8 (8714) 72 22 08;\nадрес электронной почты: argun_oo@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 17,
        "col3": "МУ «Департамент образования Мэрии г. Грозного»",
        "col4": "364068, г. Грозный, ул. Пролетарская, 63;\nтелефон: 8 (8712) 22 21 16;\nадрес электронной почты: uo-groznydep@mail.ru;"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 1,
        "col3": "Алатырский муниципальный округ Чувашской Республики",
        "col4": "429826, Чувашская Республика, г. Алатырь, ул. Ленина, д. 29     8(83531) 2-24-08 alatr.priem@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 2,
        "col3": "Аликовский муниципальный округ Чувашской Республики",
        "col4": "429250, Чувашская Республика, Аликовский район, с. Аликово, \nул. Октябрьская, д. 21 8(83535) 22-3-82 alikov@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 3,
        "col3": "Батыревский муниципальный округ Чувашской Республики",
        "col4": "429350, Чувашская Республика, Батыревский район, с. Батырево, \nпр. Ленина, д. 5 8(83532) 6-24-20 batyr@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 4,
        "col3": "Вурнарский муниципальный округ Чувашской Республики",
        "col4": "429220, Чувашская Республика, Вурнарский район, пгт. Вурнары, \nул. Советская, д. 20 8(83537) 2-51-91 vurnar@cap.ru\n\n\n"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 5,
        "col3": "Ибресинский муниципальный округ Чувашской Республики",
        "col4": "429700, Чувашская Республика, Ибресинский район, п. Ибреси, \nул. Маресьева, д. 49  \n 8(83538) 2-12-52 ibresi@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 6,
        "col3": "Канашский муниципальный округ Чувашской Республики",
        "col4": "429330, г. Канаш, ул.30 лет Победы, д.87  8(83533) 2-24-90 kanash@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 7,
        "col3": "Козловский муниципальный округ Чувашской Республики",
        "col4": "429430, Чувашская Республика, Козловский район, г. Козловка, \nул. Ленина, д. 55  8(83534) 2-23-37 kozlov@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 8,
        "col3": "Комсомольский муниципальный округ Чувашской Республики",
        "col4": "429140, Чувашская Республика, Комсомольский район, \nс. Комсомольское, ул. Заводская, д. 57 8(83539) 5-11-43  komsml@cap.ru\n\n"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 9,
        "col3": "Красноармейский муниципальный округ Чувашской Республики",
        "col4": "429620, Чувашская Республика, Красноармейский район, \nс. Красноармейское, ул. Ленина, д. 35 8(83530) 2-18-41 adm.krarm@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 10,
        "col3": "Красночетайский муниципальный округ Чувашской Республики",
        "col4": "429040 Чувашская Республика, с. Красные Четаи, пл. Победы, д. 1 8(83551) 2-16-76 krchet-adm@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 11,
        "col3": "Мариинско-Посадский муниципальный округ Чувашской Республики",
        "col4": "429570, Чувашская Республика, г. Мариинский Посад,  \nул. Николаева, д. 47               8(83542) 2-14-42 marpos@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 12,
        "col3": "Моргаушский муниципальный округ Чувашской Республики",
        "col4": "429530, Чувашская Республика, Моргаушский район, \nс. Моргауши, ул. Мира, д. 6 8(83541) 62-2-39 morgau@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 13,
        "col3": "Порецкий муниципальный округ Чувашской Республики",
        "col4": "429020, Чувашская Республика, Порецкий район, с. Порецкое, \nул. Ленина, д. 3  8(83543) 2-19-53 porezk@cap.ru; porezk_org3@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 14,
        "col3": "Урмарский муниципальный округ Чувашской Республики",
        "col4": "429400, Чувашская Республика, Урмарский район, п. Урмары, \nул. Мира, д. 5  8(83544) 2-10-85 urmary@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 15,
        "col3": "Цивильский муниципальный округ Чувашской Республики",
        "col4": "429900, Чувашская Республика, Цивильский район, г. Цивильск, ул. Маяковского, \nд. 12  8(83545) 21-3-26 zivil@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 16,
        "col3": "Чебоксарский муниципальный округ Чувашской Республики",
        "col4": "429500, Чувашская Республика, Чебоксарский район, п. Кугеси, \nул. Советская, д. 25  8(83540) 2-30-93 chebs@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 17,
        "col3": "Шемуршинский муниципальный округ Чувашской Республики",
        "col4": "429370, Чувашская Республика, Шемуршинский район, \nс. Шемурша, ул. Советская, д. 8  8(83546)-2-31-07 shemur@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 18,
        "col3": "Шумерлинский муниципальный округ Чувашской Республики",
        "col4": "429122, Чувашская Республика, \nг. Шумерля, ул. Октябрьская, \nд. 24  (83536) 2-06-29 gshum@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 19,
        "col3": "Ядринский муниципальный округ Чувашской Республики",
        "col4": "429060, Чувашская Республика, Ядринский район, г. Ядрин, \nул. 30 лет Победы, д. 1   8(83547) 22-0-36 yadrin@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 20,
        "col3": "Яльчикский муниципальный округ Чувашской Республики",
        "col4": "429380, Чувашская Республика, Яльчикский район, с. Яльчики, \nул. Иванова, д. 16   8(83549) 2-56-12 yaltch@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 21,
        "col3": "Янтиковский муниципальный округ Чувашской Республики",
        "col4": "429290 Чувашская Республика, Янтиковский район, с. Янтиково, пр. Ленина, д. 13  (83548) 2-18-60 yantik@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 22,
        "col3": "г. Новочебоксарск Чувашской Республики",
        "col4": "429951 Чувашская Республика, \nг. Новочебоксарск,  \nул. Винокурова, д.14   8(8352) 73-81-24,\n73-33-88 nowch-doc5@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 23,
        "col3": "Адмимнистрация Калининского района г.Чебоксары",
        "col4": "428022, Чувашская Республика, г.Чебоксары, ул. 50 лет Октября, \nд. 10 а  8(8352) 23-44-28, \n23-44-27 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 24,
        "col3": "Администраация Ленинского района г.Чебоксары",
        "col4": "428000, Чувашская Республика, г.Чебоксары, ул. Гагарина, д. 22 а\n8(8352) 23-44-76, \n23-44-75, 23-40-17 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 25,
        "col3": "Администрация Московского района г. Чебоксары ",
        "col4": "428017, Чувашская Республика, г. Чебоксары, пр. Московский, \nд. 33 «а»\n8(8352) 23-52-18,\n23-52-33, 23-52-15, 23-52-29  gcheb@cap.ru"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 1,
        "col3": "Администрация городского округа Анадырь",
        "col4": "Администрация городского округа Анадырь (689000, Чукотский автономный округ, г. Анадырь, ул. Рультытегина, д. 1, тел. (42722) 6-36-00, precedent@rambler.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 2,
        "col3": "Администрация Анадырского муниципального района ",
        "col4": "Администрация Анадырского муниципального района (689000, Чукотский автономный округ, г. Анадырь, ул. Южная, д. 15, тел. (42722) 6-49-01, anareg@chukotnet.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 3,
        "col3": "Администрация Билибинского муниципального района",
        "col4": "Администрация Билибинского муниципального района (689450, Чукотский автономный округ, г. Билибино, ул. Курчатова, д. 6, тел. (42738) 2-35-01, info@bilchao.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 4,
        "col3": "Администрация муниципального округа Эгвекинот ",
        "col4": "Администрация муниципального округа Эгвекинот (689202, Чукотский автономный округ, пгт Эгвекинот, ул. Ленина, д. 9, тел. (42734) 2-31-14, admin@go-egvekinot.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 5,
        "col3": "Администрация Провиденского муниципального округа",
        "col4": "Администрация Провиденского муниципального округа (689251, Чукотский автономный округ, пгт Провидения, ул. Набережная Дежнева, д. 8-а, "
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 6,
        "col3": "Администрация муниципального округа Певек",
        "col4": "Администрация муниципального округа Певек (689400, Чукотский автономный округ, г. Певек, ул. Обручева, д. 29, тел. (42737) 4-21-42, chaunadmin@mail.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 7,
        "col3": "Администрация Чукотского муниципального района ",
        "col4": "Администрация Чукотского муниципального района (689300, Чукотский автономный округ, с. Лаврентия, ул. Советская, д. 15, тел. (42736) 2-28-56, od@chukotraion.ru)."
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 1,
        "col3": "Департамент строительства и жилищной политики Ямало-Ненецкого автономного округа",
        "col4": "629008, ЯНАО, г. Салехард, ул. Ямальская, д. 11 г, 8 (34922) 7-73-88, depstroy@yanao.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 1,
        "col3": "Департамент городского хозяйства мэрии города Ярославль",
        "col4": "150001, г. Ярославль, ул. Большая Федоровская, д. 43 \nТелефон  (4852) 40-39 -02, (4852) 40-39-46\ne-mail: city-yaroslavl.ru\n"
    },
    {
        "col1": "Ярославская область",
        "col2": 2,
        "col3": "Администрация города Переславля-Залесского",
        "col4": "152020, г. Переславль-Залесский, Ярославская область, Народная пл., д.1 \nТелефон (48535) 3-28-37, (48535) 35070\nе-mail: gorod.pereslavl@yarregion.ru \n152020, г. Переславль-Залесский, Ярославская область, Народная пл., д.1 \nТелефон (48535) 3-28-37, (48535) 35070\nе-mail: gorod.pereslavl@yarregion.ru "
    },
    {
        "col1": "Ярославская область",
        "col2": 3,
        "col3": "МКУ городского округа город Рыбинск «Жилкомцентр»",
        "col4": "152901, г. Рыбинск, Ярославская область, \nул. Крестовая, д. 49 \nТелефон: (4855) 25-01-25, (4855) 25-01-19\ne-mail:  gks@rybadm.ru "
    },
    {
        "col1": "Ярославская область",
        "col2": 4,
        "col3": "Администрация Большесельского муниципального района",
        "col4": "152360, с. Большое Село, Ярославская область, пл. Советская, д. 9 \nТелефон: (48542) 2-14-07, (48542) 2-93-00\ne-mail: admin.b.selo@yarregion.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 5,
        "col3": "Администрация Борисоглебского муниципального района ",
        "col4": "152170, рабочий посёлок Борисоглебский, Ярославская область, Транспортная улица, д. 1 \nТелефон:  (48539) 2-25-53\ne-mail: okrug.borisogleb@yarregion.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 6,
        "col3": "Администрация Брейтовского муниципального района",
        "col4": "152760, с. Брейтово, Ярославская область, \nул. Советская, д. 2 \nТелефон: (48545) 2-14-82\ne-mail: adm.bmr@yarregion.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 7,
        "col3": "Администрация городского поселения Гаврилов-Ям",
        "col4": "152240, г. Гаврилов-Ям, Ярославская область, ул. Кирова, д. 1а \nТелефон: (48534) 2-32-86\ne-mail: gp.gavyam@yarregion.ru\n"
    },
    {
        "col1": "Ярославская область",
        "col2": 8,
        "col3": "Администрация Даниловского муниципального района",
        "col4": "152072, г. Данилов, \nЯрославская область, \nпл. Соборная, д. 14а, \nТелефон:  (48538) 5-11-45 \ne-mail: admr@danilovmr.ru "
    },
    {
        "col1": "Ярославская область",
        "col2": 9,
        "col3": "Администрация\nЛюбимского муниципального района \n",
        "col4": "152470, г. Любим, Ярославская область, \nул. Трефолева, д. 10\nТелефон: (48543) 2-13-44\ne-mail: admin.lubim@yarregion.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 10,
        "col3": "Администрация Мышкинского муниципального района",
        "col4": "152830, г. Мышкин, Ярославская область, \nпл. Успенская, д. 4, \nТелефон: (48544) 2-14-45\ne-mail: mail.mmr@yarregion.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 11,
        "col3": "Администрация Некоузского муниципального района",
        "col4": "152730, село Новый Некоуз, \nНекоузский район, \nЯрославская область,\n ул. Кооперативная, д. 12 \nТелефон: (48547) 2-16-65 \ne-mail: adm-nmr@yandex.ru\n"
    },
    {
        "col1": "Ярославская область",
        "col2": 12,
        "col3": "Администрация Некрасовского муниципального района",
        "col4": "152260, п. Некрасовское, Ярославская область, \nул. Набережная, д. 37 \nТелефон: (48531) 4-11-68, 4-13-48\ne-mail: adm.nekr@yarregion.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 13,
        "col3": "Администрация Первомайского муниципального района ",
        "col4": "152430, п. Пречистое, \nПервомайский район, \nЯрославская область, \nул. Ярославская, д. 90\nТелефон: (48549) 2-12-48\ne-ail: admin@pervomay.adm.yar.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 14,
        "col3": "Администрация Переславль-Залесского муниципального округа",
        "col4": "152020, г. Переславль-Залесский, \nЯрославская область, Народная пл., д. 1 \nТел.: (48535) 3-28-37\nе-mail: gorod.pereslavl@yarregion.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 15,
        "col3": "Администрация Пошехонского муниципального района",
        "col4": "152850, г. Пошехонье, \nЯрославская область, \nпл. Свободы, д. 9 \nТелефон: (48546) 2-28-43\nе-mail: adminposh@yarregion.ru\n"
    },
    {
        "col1": "Ярославская область",
        "col2": 16,
        "col3": "Администрация Ростовского муниципального района ",
        "col4": "152151, г. Ростов, Ярославская область, Советская площадь, д. 15 \nТелефон: (48536) 6-34-85 \ne-mail: admin@rostov.adm.yar.ru\n"
    },
    {
        "col1": "Ярославская область",
        "col2": 17,
        "col3": "Администрация Рыбинского муниципального района",
        "col4": "152903, г. Рыбинск, ул. Братьев Орловых, д. 1а, \nТелефон: (4855) 21-34-63\ne-mail: glava@admrmr.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 18,
        "col3": "Администрация Тутаевского муниципального района ",
        "col4": "152303, г. Тутаев, ул. Романовская, д. 35 \nТелефон:  (48533) 212-78\nе-mail: mail@admtmr.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 19,
        "col3": "Администрация Угличского муниципального района ",
        "col4": "152615, г. Углич, Ярославская область, \nпл. Успенская, д. 2 \nТелефон:  (48532)2-20-60, 2-12-35 \nе-mail: adm@uglich.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 20,
        "col3": "Администрация Ярославского муниципального района",
        "col4": "150003, г. Ярославль, ул. Зои Космодемьянской, д. 10а\nТелефон: (4852) 72-13-19\nе-mail: yarobl.yamr@yarregion.ru\n"
    }
]
