export const navArraySixth = [
    {
        "col1": "Алтайский край",
        "col2": 1,
        "col3": "Министерство социальной защиты Алтайского края",
        "col4": "Алтайский край, г. Барнаул, ул. Партизанская, 69 / asp@aksp.ru / 8-800-100-00-01"
    },
    {
        "col1": "Амурская область",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Амурской области",
        "col4": "675000, Амурская область,\nг. Благовещенск,\nул. Шимановского, 8/ julikks@mszn.amurobl.ru/ \n+7(4162) 990-276"
    },
    {
        "col1": "Архангельская область",
        "col2": 1,
        "col3": "Департамент по вопросам семьи, опеки и попечительства городского округа                            «Город Архангельск»",
        "col4": "163000, г. Архангельск, пл. Ленина, д. 5 Телефон: 8 (8182) 60-74-39 , Факс: 8 (8182) 60-74-24, dzsp@arhcity.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 2,
        "col3": "Отдел опеки и попечительства администрации городского округа Архангельской области «Город Коряжма»",
        "col4": "165651, Архангельская область, \nг. Коряжма, ул. Ленина, д. 29,Телефон/факс: \n8 (818-50)3-03-08, 3-49-57, opeka1@koradm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства управления по социальным вопросам администрации городского округа Архангельской области «Котлас»",
        "col4": "165300, Архангельская область, г. Котлас, пл. Советов, д. 3,Телефон: 8(81837) 2-06-39, 2-05-57, oop50481@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 4,
        "col3": "Орган опеки и попечительства Муниципального учреждения «Управление образования и социальной сферы администрации Мирного»",
        "col4": "164170, Архангельская область, г. Мирный,     ул. Ленина, д. 33, Телефон, факс: 8(81834) 5-04-20, opekamirnyi@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 5,
        "col3": "Отдел опеки и попечительства управления социальной политики администрации городского округа Архангельской области «Город Новодвинск»",
        "col4": "164900, Архангельская область, г. Новодвинск, ул. Фронтовых бригад, д. 6, корп. 1, Телефон: 8(81852) 5-12-97, а.volkova@novadmin.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 6,
        "col3": "Отдел опеки и попечительства над несовершеннолетними Управления социального развития, опеки и попечительства городского округа Архангельской области «Северодвинск»",
        "col4": "164507, Архангельская область,г. Северодвинск, ул. Ломоносова, д. 41а,Телефон: 8(8184) 56-98-16, opekadeti@adm.severodvinsk.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства администрации Вельского муниципального района",
        "col4": "165150, Архангельская область, Вельский район, г. Вельск, ул. Гагарина, д. 37, Телефон/Факс: 8(81836) 6-40-59,opeka@velskmo.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 8,
        "col3": "Отдел опеки и попечительства Управления образования администрации Верхнетоемского муниципального округа Архангельской области ",
        "col4": "165500, Архангельская область, Верхнетоемский район, с. Верхняя Тойма, ул. Кировская, д. 6, Телефон, факс: 8(81854) 3-18-37, opekavt@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 9,
        "col3": "Отдел опеки и попечительства Управления образования и культуры администрации Вилегодского муниципального округа Архангельской области",
        "col4": "165680, Архангельская область, Вилегодский район, с. Ильинско-Подомское, ул. Советская, д. 34, Телефон, факс: 8(81843) 4-14-63, vilopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 10,
        "col3": "Отдел опеки и попечительства администрации Виноградовского муниципального округа Архангельской области",
        "col4": "164570, Архангельская область, Виноградовский район,п. Березник, ул. П. Виноградова, д. 83-А, Телефон, факс: 8(81831)      2-19-33, opeka@vmoao.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 11,
        "col3": "Отдел опеки и попечительства администрации Каргопольского муниципального округа Архангельской области",
        "col4": "164110, Архангельская область, Каргопольский район, г. Каргополь, ул. Победы, д. 12, Телефон, факс: 8(81841)2-26-30,                      2-18-45, kargopeka@mail.ru\n"
    },
    {
        "col1": "Архангельская область",
        "col2": 12,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Коношский муниципальный район»",
        "col4": "164010, Архангельская область, Коношский район, п. Коноша, ул. Советская, д. 76, Телефон, факс: 8 (81858)2-21-63, 8(81858)2-37-01, opeka.konosha@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 13,
        "col3": "Отдел опеки и попечительства Управления по социальной политике администрации Котласского муниципального округа Архангельской области",
        "col4": "165300, Архангельская область,  г. Котлас, пл. Советов, д. 9, Телефон: 8(81837) 2-17-20                     Факс: 8 (81837) 2-11-51, opekakr@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Красноборского муниципального округа Архангельской области",
        "col4": "165430, Архангельская область, Красноборский район, с. Красноборск, ул. Гагарина, д. 7-А, Телефон, факс: 8(81840) 3-15-57, oopkras@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 15,
        "col3": "Территориальный отдел по опеке и попечительству администрации муниципального образования «Ленский муниципальный район»",
        "col4": "165780, Архангельская область, Ленский район, с. Яренск, ул. Братьев Покровских, д. 19, Телефон, факс: 8(81859) 5-35-71, lenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства Управления образования администрации Лешуконского муниципального округа Архангельской области",
        "col4": "164670, Архангельская область, Лешуконский район, с. Лешуконское, ул. Красных Партизан,  д. 12, Телефон, факс: 8(81833) 3-00-13, leschopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 17,
        "col3": "Отдел опеки и попечительства управления образования администрации Мезенского муниципального округа Архангельской области",
        "col4": "164750, Архангельская область, Мезенский район, г. Мезень, пр. Советский, д. 51, Телефон, факс: 8(81848) 9-16-89, mezenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 18,
        "col3": "Отдел опеки и попечительства администрации Няндомского муниципального округа Архангельской области",
        "col4": "164200, Архангельская область, Няндомский район, г. Няндома,  ул. 60 лет Октября, д. 13, Телефон, факс: 8(818-38)6-21-68, nanopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 19,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Онежский муниципальный район»",
        "col4": "164840, Архангельская область, Онежский район, г. Онега, ул. Шаревского, д. 6, Телефон: 8 (81839) 7-03-68\nФакс: 8 (818-39) 7-04-21, opeka@onegaland.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 20,
        "col3": "Отдел по социальным вопросам, опеки и попечительства администрации Пинежского муниципального округа Архангельской области",
        "col4": "164600, Архангельская область, Пинежский район, с. Карпогоры, ул. Победы, д. 10-Б, Телефон, факс: 8 (81856)2-17-28, 2-13-11, pinopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 21,
        "col3": "Отдел опеки и попечительства администрации Плесецкого муниципального округа Архангельской области",
        "col4": "164262, Архангельская область, Плесецкий район, п. Плесецк, ул. Ленина, д. 33, Телефон: 8 (81832)7-35-94, 7-40-83     Факс: 8 (818-32) 7-28-98, oop-amoples@plesadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 22,
        "col3": "Отдел по опеке и попечительству администрации Приморского муниципального округа Архангельской области",
        "col4": "163002, г. Архангельск, пр. Ломоносова, д. 30, Телефон, факс :8(8182) 68-01-39, opeka@primadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 23,
        "col3": "Отдел опеки и попечительства администрации Устьянского муниципального округа Архангельской области",
        "col4": "165210, Архангельская область, Устьянский район, рп. Октябрьский, ул. Комсомольская,  д. 7, Телефон, факс: 8 (818-55)5-11-41, ustopekun@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 24,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних управления образования администрации Холмогорского муниципального округа Архангельской области",
        "col4": "164530, Архангельская область, Холмогорский район, с. Холмогоры, пл. Морозова, д. 10, Телефон: 8(818-30) 3-34-99, opeka-holmogory@rambler.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 25,
        "col3": "Отдел опеки и попечительства администрации Шенкурского муниципального округа Архангельской области",
        "col4": "165160, Архангельская область, Шенкурский район, г. Шенкурск, ул. Детгородок, д. 8, Телефон, факс: 8(818-51) 4-04-52, shenkopeka@mail.ru"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 1,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Кировского района города Астрахани»",
        "col4": "414014, г. Астрахань, ул. Костина, 2,\n8(8512) 51-00-52, 8(8512) 51-00-47, 8(8512) 51-00-48, 8(8512) 51-00-55,\nадрес электронной почты: : cspn-kir@mail.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 2,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Ленинского района города Астрахани»",
        "col4": "414052, г. Астрахань, ул. Ботвина, 14 Б,\n8(8512) 52-01-90, 8(8512) 52-01-91;\nадрес электронной почты: : info@lensoc.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 3,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Советского района города Астрахани»",
        "col4": "414018, г. Астрахань, ул. Адм. Нахимова, 66 Г\n8(8512) 51-40-33, 8(8512) 51-40-85,\nадрес электронной почты: : cspnsov@astrobl.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 4,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Трусовского района города Астрахани»",
        "col4": "414006, г. Астрахань, ул. Пирогова, 53 / ул. Печенегская, 34\n56-27-05;\nадрес электронной почты: : umcrit-trus@mail.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 5,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Ахтубинского района»",
        "col4": "416501, Астраханская обл., Ахтубинский район, г. Ахтубинск, ул. Сталинградская, 4\n8(85141) 5-29-39 \nадрес электронной почты: : cspn-ahtub@astrobl.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 6,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения ЗАТО Знаменск»",
        "col4": "416548 Астраханская обл., г. Знаменск, ул. Первомайская, 14 А,\n8(85140) 2-41-42, 8(85140) 2-24-29, 8(85140) 2-25-74, 8(85140) 2-44-79\nадрес электронной почты: : omsrit_znam@mail.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 7,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Володарского района»",
        "col4": "416170, Астраханская обл., Володарский р-он, п. Володарский, ул. Театральная, 4,\n8(85142) 9-18-58, 8(85142) 9-15-40\nадрес электронной почты: : volcspn@astrobl.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 8,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Енотаевского района»",
        "col4": "416200, Астраханская обл., Енотаевский р-он, с. Енотаевка, ул. Чапаева/Советская, 10/66\n8(85143) 91-0-83, 8(85143) 92-9-84;\nадрес электронной почты: : minsocenot@mail.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 9,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Икрянинского района»",
        "col4": "416370, Астраханская обл. Икрянинский р-он, с. Икряное, ул. Школьная, 25А\n8(85144) 2-02-99, 8(85144) 2-12-04, 8(85144) 2-05-97, 8(85144) 2-22-97, 8(85144) 2-19-43;\nадрес электронной почты: : ikr-mcrit@yandex.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 10,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Камызякского района»",
        "col4": "416340, Астраханская обл. Камызякский р-он, г. Камызяк, ул. Ленина, 11\n8(85145) 9-12-46, 8(85145) 9-10-41,\nадрес электронной почты: : soczashita_kam@mail.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 11,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Лиманского района»",
        "col4": "416410, Астраханская обл., Лиманский р-он, р.п. Лиман, ул. Героев, 117\n8(85147) 2-13-39, 8(85147) 2-28-66,\nадрес электронной почты: : limomsr@mail.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 12,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Наримановского района»",
        "col4": "416111, Астраханская обл., Наримановский р-он, г. Нариманов, пр. Строителей, 5,\n8(85171) 62-2-60, 8(85171) 70-2-08, 8(85171) 61-3-38, 8(85171) 70-2-10, 8(85171) 70-2-09\nадрес электронной почты: : tis_nar@mail.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 13,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Приволжского района»",
        "col4": "416450, Астраханская обл., Приволжский р-он, с. Началово, ул. Майская, 6 В,\n8(8512) 22-02-81, 8(8512) 22-02-83, 8(8512) 22-02-85\nадрес электронной почты: : cspnpriv@astrobl.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 14,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Харабалинского района»",
        "col4": "416010, Астраханская обл., Харабалинский р-он, г. Харабали, ул. Гагарина, 118 «А»\n8(85148) 5-80-81, 8(85148) 5-80-95, 8(85148) 5-80-98, 8(85148) 5-95-06, 8(85148) 5-80-97\nадрес электронной почты: : harcspn@astrobl.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 15,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Черноярского района»",
        "col4": "416230, Астраханская обл., Черноярский р-он, с. Черный Яр, ул. Жукова, 1А\n8(85149) 2-05-99, 8(85149) 2-20-65;\nадрес электронной почты: : cspn-chern@astrobl.ru\n"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 16,
        "col3": "Государственное казенное учреждение Астраханской области «Центр социальной поддержки населения Красноярского района»",
        "col4": "416150, Астраханская обл., Красноярский р-он, с. Красный Яр, ул. Н. Островского, 10\n8(85146) 9-13-20\nадрес электронной почты: : zspnkrjr@astrobl.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 1,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся \nбез попечения родителей, и лиц из их числа комитета социальной политики администрации Белгородского района ",
        "col4": " 308501, Белгородская область, Белгородский район, \nп. Дубовое, ул. Лунная, д. 4а, usznbr@mail.ru, \n(4722) 25-70-66"
    },
    {
        "col1": "Белгородская область",
        "col2": 2,
        "col3": "Управление социальной защиты населения администрации Борисовского района",
        "col4": "309340, Белгородская область, Борисовский район, п. Борисовка, пл. Ушакова, д.3, \nuszn@bo.belregion.ru,           (47246) 5-28-94 \n"
    },
    {
        "col1": "Белгородская область",
        "col2": 3,
        "col3": "Управление социальной защиты населения администрации Вейделевского района",
        "col4": "309720, Белгородская область, Вейделевский район, п. Вейделевка, ул. Мира, д.14\ne-mail: uszn@ve.belregion.ru\n(47237) 5-56-73\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 4,
        "col3": "Управление социальной защиты населения администрации Волоконовского района",
        "col4": "309650, Белгородская область,Волоконовский район, п.Волоконовка, ул. Комсомольская, д.25, \nvuszn@mail.ru, (47235) 5-13-68\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 5,
        "col3": "Администрация Ивнянского района",
        "col4": "309110, Белгородская область, Ивнянский район, п. Ивня, ул. Ленина, д. 20, \nfilshina_ev@iv.belregion.ru,\n(47243) 5-57-08\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 6,
        "col3": "Районная межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "309210, Белгородская область, Корочанский район,\nг. Короча, пл. Васильева, д. 13  opeka_kor@mail.ru,  \n(47231)5-54-76\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 7,
        "col3": "Отдел социальной защиты населения администрации муниципального района «Красненский район» ",
        "col4": "309870, Белгородская область, Красненский район, с.Красное, ул. Подгорная, д.3, (47262) 5-25-94, 5-23-95, oszn@kr.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 8,
        "col3": "Жилищная комиссия при администрации Красногвардейского района Белгородской области",
        "col4": "309504, Белгородская область, Красногвардейский район, г. Бирюч, пл. Соборная, д. 1,\nu_gkh@bk.ru, (47247) 3-37-44, 3-10-62\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 9,
        "col3": "Управление социальной защиты населения администрации Краснояружского района",
        "col4": "309420, Белгородская область, Краснояружский район, п. Красная\nЯруга, ул, Парковая, д.З8А,\n (47263) 46-2-29,   USZN_Kr.Yaruga@mail.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 10,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "Белгородская область, Прохоровский район,\nп. Прохоровка, \nул. Советская, 57а, \nuszn@pr.belregion.ru, \n(47242) 2-16-77\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 11,
        "col3": "Администрация муниципального района «Ракитянский район»",
        "col4": "309310, Белгородская область, Ракитянский район,\nп. Ракитное, пл. Советская, д.2,        (47245) 5-55-16, arakitnoe@ra.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 12,
        "col3": "Районная межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "309740, Белгородская область, Ровеньский район, п.Ровеньки, ул. Шевченко, д.8, \nrov_oszn@mail.ru, (47238)5-52-90\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 13,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без родительского попечения, и лиц из их числа",
        "col4": "309560, Белгородская область,  Чернянский район,\nп. Чернянка, пл. Октябрьская, д. 6, uszn@ch.belregion.ru, \n(47232) 5-51-65\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 14,
        "col3": "Управление социальной защиты населения администрации\nАлексеевского муниципального округа\n",
        "col4": "309850, Белгородская область, Алексеевский муниципальный округ, г.Алексеевка, пл. Победы, д. 75,  (47234) 4-62-86,  opekaal@mail.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 15,
        "col3": "Межведомственная комиссия по\nрешению жилищных вопросов\nдетей-сирот, детей, оставшихся\nбез попечения родителей, и лиц\nиз их числа",
        "col4": "309996, Белгородская область, Валуйский муниципальный округ,\nг. Валуйки, ул. М.Горького, д. 4, valuszn@mail.ru,              (47236) 3-69-58, 3-19-10"
    },
    {
        "col1": "Белгородская область",
        "col2": 16,
        "col3": "Управление социальной защиты населения администрации Грайворонского муниципального округа",
        "col4": "309370 Белгородская область, Грайворонский район, г. Грайворон, ул. Ленина, д. 47, (47261)4-62-99\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 17,
        "col3": "Управление социальной защиты населения администрации\nНовооскольского муниципального округа\n",
        "col4": "309640, Белгородская область, Новооскольский муниципальный округ,\nг.Новый Оскол, ул. Гражданская, д.44,  uszn@no.belregion.ru, (47233) 4-65-14"
    },
    {
        "col1": "Белгородская область",
        "col2": 18,
        "col3": "Администрация Шебекинского муниципального округа",
        "col4": "309290, Белгородская область, Шебекинский муниципальный округ, г.Шебекино, пл. Центральная, д. 2,          (47248) 3-29-22, admshebekino@sh.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 19,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "309070, Белгородская область, Яковлевский муниципальный округ, г.Строитель, пер.Октябрьский, д.1, yakovuszn@yandex.ru, (47244)  5-08-45"
    },
    {
        "col1": "Белгородская область",
        "col2": 20,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "308000, Белгородская область, г. Белгород, ул.\nКнязя Трубецкого, д. 62, (4722) 33-35-60, 52-35-85, socbel@beladm.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 21,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа\n",
        "col4": "309186, Белгородская область, Губкинский городской округ,\nг. Губкин, ул. Победы, д. 3, socpolitika@yandex.ru,\n(47241) 5-25-69\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 22,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "Адрес: 309500, Белгородская область, Старооскольский городской округ,\nг. Старый Оскол, мкр. Интернациональный, д. 15, \n(4725) 24-91-63, uszn@so.belregion.ru\n"
    },
    {
        "col1": "Брянская область",
        "col2": 1,
        "col3": "Брянская городская администрация",
        "col4": "241050, Брянская область, г. Брянск,                 пр-т Ленина, д. 35, goradm@bga32.ru,  8(4832) 66-40-44"
    },
    {
        "col1": "Брянская область",
        "col2": 2,
        "col3": "Клинцовская городская администрация",
        "col4": "243140, Брянская область, г. Клинцы,             ул. Октябрьская, д. 42, klintsi@mail.ru, 8(48336) 4-03-28"
    },
    {
        "col1": "Брянская область",
        "col2": 3,
        "col3": "Новозыбковская городская администрация",
        "col4": "243020, Брянская область, г. Новозыбков,        пл. Октябрьской Революции, д. 2, novozibkovadm@mail.ru, 8(48343)5-69-46"
    },
    {
        "col1": "Брянская область",
        "col2": 4,
        "col3": "Администрация города Сельцо ",
        "col4": "241550, Брянская область, г. Сельцо,              ул. Горького, д. 14, selco32@mail.ru, 8(4832)97-44-17"
    },
    {
        "col1": "Брянская область",
        "col2": 5,
        "col3": "Администрация Стародубского муниципального округа",
        "col4": "243240, Брянская область, г. Стародуб,           ул. Свердлова, д. 4, stadmo2008@yandex.ru, 8(48348) 2-24-22"
    },
    {
        "col1": "Брянская область",
        "col2": 6,
        "col3": "Администрация города Фокино",
        "col4": "242610, Брянская область, г. Фокино,              ул. Ленина, д. 13, g_fokino@mail.ru, 8(483333)4-79-60"
    },
    {
        "col1": "Брянская область",
        "col2": 7,
        "col3": "Администрация Брасовского муниципального района",
        "col4": "242300, Брянская область, Брасовский район,    п. Локоть, пр-т Ленина, д. 2. edds.bras@yandex.ru, 8(48354) 9-11-42"
    },
    {
        "col1": "Брянская область",
        "col2": 8,
        "col3": "Админситрация Брянского муниципального района",
        "col4": "241525, Брянская область, Брянский район,      с. Глинищево, ул. П.М. Яшенина, д. 9, admbr@mail.ru, 8(4832)94-16-70"
    },
    {
        "col1": "Брянская область",
        "col2": 9,
        "col3": "Администрация Выгоничского муниципального  района",
        "col4": "243361, Брянская область, Выгоничский район, п. Выгоничи, ул. Ленина, д. 51, adminwr@mail.ru, 8(48341)2-14-52"
    },
    {
        "col1": "Брянская область",
        "col2": 10,
        "col3": "Администрация Гордеевского муниципального  района",
        "col4": "243650, Брянская область, Гордеевский район, с. Гордеевка, ул. Победы, д. 10, admorgotdel@mail.ru, 8(48340) 2-14-46"
    },
    {
        "col1": "Брянская область",
        "col2": 11,
        "col3": "Администрация Дубровского муниципального района ",
        "col4": "242750, Брянская область, Дубровский район, п. Дубровка, ул. Победы, д. 18, dbr-orgotdel@yandex.ru, 8(48332)9-15-25"
    },
    {
        "col1": "Брянская область",
        "col2": 12,
        "col3": "Администрация Дятьковского муниципального района",
        "col4": "242600, Брянская область, Дятьковский район, г. Дятьково, д. 141-а, radmdtk@mail.ru, 8(48333) 3-22-03"
    },
    {
        "col1": "Брянская область",
        "col2": 13,
        "col3": "Администрация Жирятинского муниципального района",
        "col4": "242030, Брянская область, Жирятинский район, с. Жирятино, ул. Мира, д. 10, adm@juratino.ru, 8 (48344) 3-06-06"
    },
    {
        "col1": "Брянская область",
        "col2": 14,
        "col3": "Администрация Жуковского муниципального округа",
        "col4": "242700, Брянская область, Жуковский муниципальный округ, г. Жуковка,                 ул. Октябрьская, д. 1, adm@zh32.ru, 8 (48334) 3-26-71"
    },
    {
        "col1": "Брянская область",
        "col2": 15,
        "col3": "Администрация Злынковского муниципального района",
        "col4": "243600, Брянская область, Злынковский район, г. Злынка, пл. Свободы, д. 35, RAZLYNKA2@MAIL.RU, 8 (48358) 2-10-31"
    },
    {
        "col1": "Брянская область",
        "col2": 16,
        "col3": "Администрация Карачевского муниципального района",
        "col4": "242500, Брянская область, Карачевский район, г. Карачев, ул. Советская, д. 64, inbox@karadmin.ru, 8(48335) 2-33-91"
    },
    {
        "col1": "Брянская область",
        "col2": 17,
        "col3": "Администрация Клетнянского муниципального района",
        "col4": "242820, Брянская область, Клетнянский район, п. Клетня, ул. Ленина, д. 92, klet-2007@yandex.ru, 8(48338) 9-15-61"
    },
    {
        "col1": "Брянская область",
        "col2": 18,
        "col3": "Администрация Климовского муниципального района",
        "col4": "243040, Брянская область, Климовский район, пгт. Климово, пл. Ленина, д. 1, kladm@inbox.ru, 8 (48347) 2-13-38"
    },
    {
        "col1": "Брянская область",
        "col2": 19,
        "col3": "Администрация Клинцовского муниципального района",
        "col4": "243140, Брянская область, г. Клинцы,              ул. Октябрьская, д. 42, priymnay@mail.ru, 8(48336) 4-05-12"
    },
    {
        "col1": "Брянская область",
        "col2": 20,
        "col3": "Администрация Комаричского муниципального района",
        "col4": "242400, Брянская область, Комаричский район, п. Комаричи, ул. Советская, д. 21, adminkom@mail.ru, 8(48355) 9-15-98"
    },
    {
        "col1": "Брянская область",
        "col2": 21,
        "col3": "Администрация Красногорского муниципального района",
        "col4": "243160, Брянская область, Красногорский район, пгт. Красная Гора, ул. Первомайская,    д. 6, krgadm@yandex.ru, 8(48346) 9-17-76"
    },
    {
        "col1": "Брянская область",
        "col2": 22,
        "col3": "Администрация Мглинского муниципального района",
        "col4": "243220, Брянская область, Мглинский район,   г. Мглин, пл. Советская, д. 6, mgladm@mail.ru, 8 (48339) 2-13-45"
    },
    {
        "col1": "Брянская область",
        "col2": 23,
        "col3": "Администрация Навлинского муниципального района",
        "col4": "242130, Брянская область, Навлинский район, п. Навля, ул. Красных Партизан, д. 21, priem-nvl@yandex.ru, 8 (48342) 2-26-91"
    },
    {
        "col1": "Брянская область",
        "col2": 24,
        "col3": "Администрация Погарского муниципального района",
        "col4": "243550, Брянская область, Погарский район, пгт. Погар, ул. Ленина, д.1, admin@pogaradm.ru, 8(48342) 2-12-80"
    },
    {
        "col1": "Брянская область",
        "col2": 25,
        "col3": "Администрация Почепского муниципального района",
        "col4": "243400, Брянская область, Почепский район,    г. Почеп, пл. Октябрьская, д. 3а, orgotdel74@mail.ru, 8(48345) 3-02-52"
    },
    {
        "col1": "Брянская область",
        "col2": 26,
        "col3": "Администрация Рогнединского муниципального района",
        "col4": "242770, Брянская область, Рогнединский район, п. Рогнедино, ул. Ленина, д. 29, admrrognedino@mail.ru, 8(48331) 2-13-53"
    },
    {
        "col1": "Брянская область",
        "col2": 27,
        "col3": "Администрация Севского муниципального района",
        "col4": "242440, Брянская область, Севский район,         г. Севск, ул. Розы Люксембург, д. 50, admsevsk@mail.ru, 8(48356) 9-14-33"
    },
    {
        "col1": "Брянская область",
        "col2": 28,
        "col3": "Администрация Суземского муниципального района",
        "col4": "242190, Брянская область, Суземский район,    п. Суземка, пл. Ленина, д. 1, info@adminsuzemka.ru, 8(48353) 2-18-34"
    },
    {
        "col1": "Брянская область",
        "col2": 29,
        "col3": "Администрация Суражского муниципального района",
        "col4": "243500, Брянская область, Суражский район,   г. Сураж, ул. Ленина, д. 40, 84833021434@mail.ru, 8(48330) 2-14-34"
    },
    {
        "col1": "Брянская область",
        "col2": 30,
        "col3": "Администрация Трубчевскогоского муниципального района",
        "col4": "242221, Брянская область, Трубчевский район, г. Трубчевск, ул. Брянская, д. 59,  raisovet-trubchevsk@yandex.ru, 8(48352) 2-22-81"
    },
    {
        "col1": "Брянская область",
        "col2": 31,
        "col3": "Администрация Унечского муниципального района",
        "col4": "243300, Брянская область, Унечский район,      г. Унеча, пл. Ленина, д. 1, unradm@mail.ru, 8 (48351) 2-10-32"
    },
    {
        "col1": "Владимирская область",
        "col2": 1,
        "col3": "Администрация г. Владимира",
        "col4": "600000, г. Владимир, ул. Горького, д. 36\n8 (4922) 35-33-33, 35-41-26 citizens@vladimir-city.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 2,
        "col3": "Администрация г. Гусь-Хрустальный Владимирской области",
        "col4": "601500, Владимирская обл., г. Гусь-Хрустальный, ул.Калинина, д. 1\n8 (49241) 2-45-53\npriem@gusadmin.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 3,
        "col3": "Администрация г. Коврова Владимирской области",
        "col4": "601900, Владимирская обл., г. Ковров, ул. Краснознаменная, д.6\n8 (49232) 3-11-35\ninfo@kovrov-gorod.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 4,
        "col3": "Администрация г. Мурома Владимирской области",
        "col4": "602267, Владимирская обл., г. Муром, пл.1100-летия, д.1\n8 (49234) 3-11-02\npost@murom.info "
    },
    {
        "col1": "Владимирская область",
        "col2": 5,
        "col3": "Администрация ЗАТО г. Радужный Владимирской области",
        "col4": "600910, Владимирская обл., г. Радужный, кв-л 1-й, д. 55\n8 (49254) 3-28-99\nraduga_oszn@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 6,
        "col3": "Администрация Александровского района",
        "col4": "601650, Владимирская обл., г. Александров, ул. Красной молодёжи, д.7\n8 (49244) 2-21-31\nalexan@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 7,
        "col3": "Администрация Вязниковского района",
        "col4": "601443, Владимирская обл., г. Вязники, ул. Комсомольская, д.1\n8 (49233) 2-51-38\nvyazn@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 8,
        "col3": "Администрация Гороховецкого района",
        "col4": "601481 Владимирская обл., г. Гороховец, ул. Ленина, д.93\n8 (49238) 2-15-65\nagr@gorohovec.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 9,
        "col3": "Администрация Гусь-Хрустального района",
        "col4": "601501, Владимирская обл., г. Гусь-Хрустальный, ул. Карла Либкнехта, д. 6\n8 (49241) 2-17-66\ngusr@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 10,
        "col3": "Администрация Камешковского района",
        "col4": "601300, Владимирская обл., г. Камешково, ул. Свердлова, д. 10\n8 (49248) 2-12-07\npost@admkam.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 11,
        "col3": "Администрация Киржачского района",
        "col4": "601010, Владимирская обл., г. Киржач, ул. Серегина, д. 7\n8 (49237) 2-01-77\ninfo@kirzhach.su "
    },
    {
        "col1": "Владимирская область",
        "col2": 12,
        "col3": "Администрация Ковровского района",
        "col4": "601900, Владимирская обл., г. Ковров, ул. Дегтярёва, д. 34.\n8 (49232) 2-16-22\nkovrr@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 13,
        "col3": "Администрация Кольчугинского района",
        "col4": "601785, Владимирская область, Кольчугинский район, г. Кольчугино, пл. Ленина, д. 2.\n8 (49245) 2-39-83\nkolch@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 14,
        "col3": "Администрация Петушинского района",
        "col4": "601144, Владимирская обл., г. Петушки, ул. Советская площадь, дом 5.\n8 (49243) 2-20-82, 2-15-04\ninfo@petushki.info "
    },
    {
        "col1": "Владимирская область",
        "col2": 15,
        "col3": "Администрация Селивановского района",
        "col4": "602332, Владимирская обл., Селивановский район, пос. Красная Горбатка, ул. Красноармейская д.12\n8 (49236) 2-10-81\ncentr@selivanovo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 16,
        "col3": "Администрация Судогодского района",
        "col4": "601351, Владимирская обл., г. Судогда, ул. Коммунистическая, зд. 1б\n8 (49235) 2-16-07\nadmsud@admsud.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 17,
        "col3": "Администрация Суздальского района",
        "col4": "601293, Владимирская обл., г. Суздаль, ул. Красная Площадь, д.1\n8 (49231) 2-13-45, 2-30-09\norg@suzdalregion.ru ; info@suzdalregion.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 18,
        "col3": "Администрация Юрьев-Польского района",
        "col4": "601800, Владимирская обл., г. Юрьев-Польский, ул. Шибанкова, д. 33\n8 (49246) 2-24-35\nyurier@avo.ru \n"
    },
    {
        "col1": "Владимирская область",
        "col2": 19,
        "col3": "Администрация Меленковского муниципального округа",
        "col4": "602102, Владимирская обл., Меленковский район, город Меленки, Красноармейская улица, 102.\n8 (49247) 2-20-94\nmelenky@melenky.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 20,
        "col3": "Администрация Собинского муниципального округа",
        "col4": "601204, Владимирская обл., Собинский район, г Собинка, ул. Садовая, д. 4\n8 (49242) 2-20-35\npost@sbnray.ru "
    },
    {
        "col1": "Волгоградская область",
        "col2": 1,
        "col3": "Комитет социальной защиты населения Волгоградской области",
        "col4": "400087, Волгоград, ул.Новороссийская, д.41, телефоны: (8442) 30-80-80, 30-82-13, 30-82-14, \nuszn@volganet.ru\n"
    },
    {
        "col1": "Вологодская область",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Вологодской области",
        "col4": "160001, г. Вологда, ул. Благовещенская, д. 9,  knyazevaem@socium.gov35.ru, (8172) 23-01-38 (доб. 6092)"
    },
    {
        "col1": "Воронежская область",
        "col2": 1,
        "col3": "Казенное учреждение Воронежской области «Управление  социальной защиты населения Аннинского района»\n\n",
        "col4": "396250, Воронежская область, пгт. Анна, ул. Ленина, д. 26\n8(47346)2-14-53\nuszn-anna@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 2,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Бобровского  района»\n\n\n",
        "col4": "397700, Воронежская область, г. Бобров, ул. Им. Кирова, д. 67\n8(47350)4-17-06\nuszn-bobrov@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 3,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Богучарского  района»\n\n",
        "col4": "396790, Воронежская область, г. Богучар, пл. Ленина, д. 1\n8(47366)2-19-58\nuszn-boguch@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 4,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Борисоглебского  района»\n\n",
        "col4": "397160, Воронежская область,  г. Борисоглебск,\nул. Свободы, д. 185\n8(47354)6-11-76\nuszn-borisogl@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 5,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Бутурлиновского района»\n\n",
        "col4": "397500, Воронежская область, г. Бутурлиновка, ул. Ленина, д. 55\n8(47361)2-13-32\nuszn-buturl@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 6,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Верхнемамонского  района»\n\n",
        "col4": "396460, Воронежская область, Верхнемамонский район, \nс. Верхний Мамон, пл. Ленина, д. 2\n8(47355)5-65-42\nuszn-vmamon@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 7,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Верхнехавского  район»\n",
        "col4": "396110, Воронежская область, с. Верхняя Хава,                             ул. 50 лет Октября, д. 44\n8(47343)7-25-12        \nuszn-vhava@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 8,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Воробьевского района»\n\n",
        "col4": "397570, Воронежская область, Воробьевский район,с. Воробьевка, ул. Горького, д. 50\n8(47356)3-11-08\nuszn-vorob@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 9,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Грибановского  района»\n\n",
        "col4": "397240, Воронежская область, п.г.т.  Грибановский,                    ул. Центральная, д. 15\n8(47348)3-01-91  \nuszn-gribanov@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 10,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Калачеевского района»\n\n",
        "col4": "397600, Воронежская область, г. Калач, пл. Ленина, д. 13\n8(47363)2-21-34\nuszn-kalach@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 11,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Каменского  района»\n\n",
        "col4": "396510, Воронежская область, пгт Каменка, ул. Мира, д. 29\n8(47357)5-22-39\nuszn-kamen@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 12,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Каширского  района»\n\n",
        "col4": "396350, Воронежская область, с. Каширское, ул. Гагарина, д. 2а \n8(47342)4-10-92\nuszn-kashir@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 13,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Кантемировского  района»\n\n",
        "col4": "396730, Воронежская область, р.п. Кантемировка, ул. Мира, д. 1\n8(47367)6-16-70\nuszn-kantem@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 14,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Лискинского  района»\n\n",
        "col4": "397900, Воронежская область, г. Лиски, ул. Тельмана, д. 35\n8(47391)4-50-72\nuszn-liski@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 15,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Нижнедивицкого района»\n\n",
        "col4": "396870, Воронежская область, с. Нижнедевицк, пл. Ленина, д. 1\n8(47370)5-17-94\nuszn-ndevick@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 16,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения г. Нововоронежа»\n\n",
        "col4": "396073, Воронежская область,\n г. Нововоронеж,\nул. Первомайская, д. 1\n8(47364)2-56-61\nuszn-nvoron@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 17,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Новоусманского района»\n\n\n",
        "col4": "396310, Воронежская область, с. Новая Усмань,\nул. Крупской, д. 5а \n8(47341)5-52-47\nuszn-nusman@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 18,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Новохоперского района»\n\n",
        "col4": "397400, Воронежская область, г. Новохоперск, ул. Ленина, д. 10\n8(47353)3-15-61\nuszn-nhoper@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 19,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Ольховатского  района»\n\n",
        "col4": "396670, Воронежская область, р.п. Ольховатка, \nпер. Горького, д. 7\n8(47395)4-08-35\nuszn-olhovat@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 20,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Острогожского района»\n",
        "col4": "397855, Воронежская область, г. Острогожск,\n улл. Крамского, д. 17\n8(47375)4-23-45\nuszn-ostrog@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 21,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Павловского  района»\n",
        "col4": "396422, Воронежская область, г. Павловск, пр. Революции, д. 6\n8(47362)2-42-23\nuszn-pavlovsk@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 22,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Панинского  района»",
        "col4": "396140, Воронежская область, р.п. Панино, ул. Советская, д. 11\n8(47344)4-78-02\n uszn-panin@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 23,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Петропавловского  района»\nКУВО «Управление социальной защиты населения Петропавловского района»\n\n",
        "col4": "КУВО «Управление социальной защиты населения Петропавловского района»\n8(47365)2-10-73\nuszn-petropavl@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 24,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Поворинского района»\n\n\n\n",
        "col4": "397350, Воронежская область, г. Поворино, ул. Чехова, д. 4а \n8(47376)4-23-91\nuszn-povorin@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 25,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Подгоренского района»\n\n\n",
        "col4": "396560, Воронежская область, пгт Подгоренский,\nул. Первомайская, д. 60\n8(47394)5-41-24\nuszn-podgor@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 26,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения» Рамонского района»\n\n",
        "col4": "396020, Воронежская область, р.п. Рамонь, ул. Школьная, д. 20\n8(47340)2-21-45\nuszn-ramon@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 27,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Репьевского  района»\n\n",
        "col4": "396370, Воронежская область, с. Репьевка, ул. Ушанева, д. 3\n8(47374)2-27-20\nuszn-repyev@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 28,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Россошанского района»\n\n",
        "col4": "396650, Воронежская область, г. Россошь, ул. Жуковского, д. 4\n8(47396)2-02-21\nuszn-rossosh@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 29,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Семилукского  района»\n\n",
        "col4": "396901, Воронежская область, г. Семилуки, ул. 9 Января, д. 13\n8(47372)2-25-42\nuszn-semil@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 30,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Таловского  района»\n\n",
        "col4": "397480, Воронежская область, р.п. Таловая, ул. Советская, д. 135\n8(47352)2-16-70\nuszn-talov@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 31,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Терновского  района»\n\n",
        "col4": "397110, Воронежская область, с. Терновка, ул. Советская, д. 37\n8(47347)5-51-73\nuszn-ternov@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 32,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Хохольского  района»\n\n",
        "col4": "396840, Воронежская область, р.п.Хохольский,\nул. Школьная, д. 4\n8(47371)4-14-72\nuszn-hohol@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 33,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Эртильского района»\n\n",
        "col4": "397030, Воронежская область, г. Эртиль, ул. Степная, д. 1а\n8(47345)2-14-53\nuszn-ertil@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 34,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Железнодорожного района  г. Воронежа»\n\n",
        "col4": "394063, г. Воронеж, Ленинский проспект, д. 167 \n8(473)223-09-44\nuszn-zsheldor@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 35,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Коминтерновского района  г. Воронежа»\n\n",
        "col4": "394049, г. Воронеж, ул. Елецкая, д. 4\n8 (473)234-37-27\nuszn-komint@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 36,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Левобережного   района  г. Воронежа»\n",
        "col4": "394004, г.Воронеж, Ленинский пр-кт, д. 24/1\n8 (473)249-51-36\nuszn-levober@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 37,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Ленинского   района г. Воронежа»\n\n",
        "col4": "394006, г. Воронеж, ул. Краснознаменная, д. 10 \n8 (473)278-21-04\nuszn-lenin@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 38,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Советского   района г. Воронежа»\n\n",
        "col4": "394051, г. Воронеж, ул. Пеше-Стрелецкая, д. 143\n8 (473)263-40-67\nuszn-sovet@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 39,
        "col3": "Казенное учреждение Воронежской области «Управление социальной защиты населения Центрального  района г. Воронежа»\n\n",
        "col4": "394000, г. Воронеж, ул. Никитинская, д. 8а \n8 (473)235-54-57\nuszn-centr@govvrn.ru\n"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 1,
        "col3": "Администрация Амвросиевского муниципального округа Донецкой Народной Республики ",
        "col4": "287304, г. Амвросиевка, \nул. Ленина, д. 24, amvr.ra@mail.ru, \n+7 (856 59) 2 30 35"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 2,
        "col3": "Администрация Володарского муниципального округа Донецкой Народной Республики ",
        "col4": "287000,  пгт. Володарское, ул. Ленина, д.87,\n info-volod@volodarskoe.glavadnr.ru,\n+79490350712"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 3,
        "col3": "Администрация Волновахского муниципального округа Донецкой Народной Республики",
        "col4": "285700, г. Волноваха, \nул. Героя России Владимира Жоги, д. 1; info-voln@volnovaha.glavadnr.ru; +79496203191"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 4,
        "col3": "Администрация городского округа Горловка Донецкой Народной Республики ",
        "col4": "284646,\nг. Горловка, пр-т Победы, д. 67, admin@gorlovka.gov-dpr.ru, \n+7 (8564) 52-05-26 "
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 5,
        "col3": "Администрация городского округа Дебальцево Донецкой Народной Республики ",
        "col4": " 284701, г. Дебальцево, \nул. Ленина, 12, e-mail: gorod.debaltsevo@mail.ru, \n+7(85649) 2-48-48"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 6,
        "col3": "Администрация городского округа Докучаевск Донецкой Народной Республики ",
        "col4": "285740, г. Докучаевск, \nул. Александра Владимировича Захарченко, 22, okrugdokuchaevsk@docuch.gov-dpr.ru, \n+7(85675)3 11 41 "
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 7,
        "col3": "Администрация городского округа Донецк Донецкой Народной Республики ",
        "col4": "283050,\nг. Донецк, ул. Артема, \nд. 98, donetskoffice@yandex.ru, \n+7 (856)338-09-55, "
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 8,
        "col3": "Администрация городского округа Енакиево Донецкой Народной Республики ",
        "col4": "286430, г. Енакиево, \nпл. Ленина, 7, \nupravleniyeopekienakievo@mail.ru, +78565291483"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 9,
        "col3": "Администрация городского округа Иловайск Донецкой Народной Республики   ",
        "col4": "286793, г. Иловайск, \nул. Шевченко, д. 139;  admin@ilovaisk.gov-dpr.ru, +78565720684, +78565720691"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 10,
        "col3": "Администрация Краснолиманского муниципального округа Донецкой Народной Республики ",
        "col4": "Юридический адрес: \n284406, г. Красный Лиман, \nул. Фрунзе, д. 46, \nфактический адрес: 286430, г. Енакиево, \nул. Щербакова, д. 114,\n adm-krasniyliman@mail.ru, \n+79495594969"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 11,
        "col3": "Администрация городского округа Макеевка Донецкой Народной Республики ",
        "col4": "286157, г. Макеевка, площадь Советская, д. 1, admingor@makeevka.ugletele.com, \n+78563223188"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 12,
        "col3": "Администрации городского округа Мариуполь Донецкой Народной Республики ",
        "col4": "287537, г. Мариуполь, \nул. Громовой, 63 adm@mariupol.gov-dpr.ru, \n+ 79495009339 "
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 13,
        "col3": "Администрация Мангушского муниципального округа Донецкой Народной Республики ",
        "col4": "287400, пгт. Мангуш, \nул. Ленина, д. 72; m.o.mangush.oopdnr@mail.ru \n+7 (949) 713-91-07"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 14,
        "col3": "Администрация муниципального образования Новоазовского муниципального округа Донецкой Народной Республики",
        "col4": "287600, г. Новоазовск, \nул. Ленина, д. 6, \nnovorosnovo@mail.ru,\n+7 (856) 963 17 05"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 15,
        "col3": "Администрация  городского округа Снежное Донецкой Народной Республики ",
        "col4": "286500, г. Снежное, ул. Ленина, д. 32 snezhnoe.opeka@yandex.ru, +79493506065"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 16,
        "col3": "Администрация Старобешевского муниципального окруна Донецкой Народной Республики",
        "col4": "287200, пгт. Старобешево, ул. Советская, 43;  starobeshevo@admstb.gov-dpr.ru;\n+78565321809"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 17,
        "col3": "Администрация Тельмановского муниципального округа Донецкой Народной Республики ",
        "col4": "287100, пгт. Тельманово, ул. Ленина, 142, adm@telmanovo.gov-dpr.ru, +7(856) 795 11 80"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 18,
        "col3": "Администрация городского округа Торез Донецкой Народной Республики ",
        "col4": "286600,  г. Торез, \nул. Пионерская, д. 3; torvuk@mail.ru; +7856(54)32246"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 19,
        "col3": "Администрация городского округа Харцызск Донецкой Народной Республики ",
        "col4": "286709, г. Харцызск, \nул. Краснознаменская, 87А, goradmin@khartsyz.ugletele.com, \n+7 (85657) 4 27 36, (приемная), \n+7 (85657) 4 25 46"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 20,
        "col3": "Администрация Шахтерского муниципального округа Донецкой Народной Републики ",
        "col4": "286211 г. Шахтерск,\n ул. Ленина, д. 4, е-mail: info@shahtersk.gov-dpr.ru,\n тел. (085655) 4-20-73"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 21,
        "col3": "Администрация Ясиноватского муниципального округа Донецкой Народной Республики",
        "col4": "286000, г. Ясиноватая, \nул. Орджоникидзе, д. 147, \nE-mail:  info@yasinovataya.gov-dpr.ru,\nтел. +7 (85636) 4-25-14"
    },
    {
        "col1": "Еврейская автономная область",
        "col2": 1,
        "col3": "Департамент социальной защиты населения правительства Еврейской автономной области",
        "col4": "679000, Еврейская автономная область, г. Биробиджан, проспект 60 лет СССР, д. 12А, \nsobes@post.eao.ru, \n8 (42622) 2 22 19"
    },
    {
        "col1": "Забайкальский край",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты населения Забайкальского края",
        "col4": "672000, Забайкальский край, г. Чита, ул. Курнатовского, д. 7,  pochta@minsz.e-zab.ru (только для служебной корреспонденции), 8(3022) 35 50 85 приемная"
    },
    {
        "col1": "Ивановская область",
        "col2": 1,
        "col3": "Территориальное управление социальной защиты населения по городу Вичуга и Вичугскому муниципальному району",
        "col4": "155331, Ивановская область, г. Вичуга, \nул. 50 лет Октября, д. 13,\nтел. 8 (49354) 2-03-81,\n vichuga_szn@ivreg.ru,\n "
    },
    {
        "col1": "Ивановская область",
        "col2": 2,
        "col3": "Территориальное управление социальной защиты населения по городу Иванов",
        "col4": "153003, г. Иваново, \nул. Строительная, д. 5,\nтел. 8 (4932) 48-25-45\nivanovo_szn@ivreg.ru\n"
    },
    {
        "col1": "Ивановская область",
        "col2": 3,
        "col3": "Территориальное управление социальной защиты населения по г. Кинешма и Кинешемскому муниципальному району",
        "col4": "155800, Ивановская область, \nг. Кинешма, ул. им. Фрунзе, д. 6,\nтел. 8 (49331) 5-74-70,\nkineshma_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 4,
        "col3": "Территориальное управление социальной защиты населения по городскому округу Кохма и Ивановскому муниципальному району",
        "col4": "153003, г. Иваново, \nул. Строительная, д. 5,\nтел 8 (4932) 48-22-92,\n ivrn_szn@ivreg.ru\n"
    },
    {
        "col1": "Ивановская область",
        "col2": 5,
        "col3": "Территориальное управление социальной защиты населения по Пучежскому и Верхнеландеховскому муниципальным районам",
        "col4": "155360, Ивановская область, г. Пучеж,\n ул. Ленина, д. 27,\nтел. 8 (49345) 2-11-36,\npucheg_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 6,
        "col3": "Территориальное управление социальной защиты населения по городскому округу Тейково и Тейковскому муниципальному району",
        "col4": "155040, Ивановская область, г. Тейково, ул. Октябрьская д. 2а,\nтел. 8 (49343) 4-00-72\nteikovo_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 7,
        "col3": "Территориальное управление социальной защиты населения по Фурмановскому муниципальному району",
        "col4": " 155520, Ивановская область, \nг. Фурманов, ул. Колосова, д. 25,\nтел. 8 (49341) 2-22-90,\nfurmanov_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 8,
        "col3": "Территориальное управление социальной защиты населения по городскому округу Шуя и Шуйскому муниципальному району",
        "col4": "155900 Ивановская область, г. Шуя, \nул. Ленина, д. 32,\nтел. 8 (49351) 4-18-13\nshuja_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 9,
        "col3": "Территориальное управление социальной защиты населения по Гаврилово-Посадскому муниципальному району",
        "col4": "155000, Ивановская область, г. Гаврилов Посад, ул. 3 Интернационала, д. 10,\nтел. 8 (49355) 2–15–43,\ngavposad_szn@ivreg.ru\n"
    },
    {
        "col1": "Ивановская область",
        "col2": 10,
        "col3": "Территориальное управление социальной защиты населения по Заволжскому муниципальному району",
        "col4": "155410, Ивановская область, \nг. Заволжск, ул. Социалистическая, \nд. 24,\nтел. 8 (49333) 2-10-44,\n zavoljsk_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 11,
        "col3": "Территориальное управление социальной защиты населения по Ильинскому муниципальному району",
        "col4": "155060, Ивановская область, \nп. Ильинское-Хованское, ул.Советская, д. 2,\nтел. 8 (49353) 2-16-5,\niljin_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 12,
        "col3": "Территориальное управление социальной защиты населения по Комсомольскому муниципальному району",
        "col4": "155150 Ивановская область, \nг. Комсомольск, пер. Торговый, д. 2\nтел. 8 (49352) 4-19-54, komsomolsk_szn@ivreg.ru\n "
    },
    {
        "col1": "Ивановская область",
        "col2": 13,
        "col3": "Территориальное управление социальной защиты населения по Лежневскому муниципальному району",
        "col4": "155120, Ивановская область, п. Лежнево, ул. Октябрьская, д. 32, \nтел. 8 (49357) 2-28-15,\nlegnevo_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 14,
        "col3": "Территориальное управление социальной защиты населения  по Лухскому муниципальному району",
        "col4": "155270, Ивановская область, п. Лух, \nул. Первомайская, д. 101,\nтел. 8 (49344) 2-12-85,\nluh_szn@ivreg.ru "
    },
    {
        "col1": "Ивановская область",
        "col2": 15,
        "col3": "Территориальное управление социальной защиты населения по  Палехскому муниципальному району",
        "col4": "155620, Ивановская область, п. Палех, \nул. Ленина, д. 1,\nтел. 8 (49334) 2-19-41,\npaleh_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 16,
        "col3": "Территориальное управление социальной защиты населения по Пестяковскому муниципальному району",
        "col4": "155650, Ивановская область, п. Пестяки, ул. Карла Маркса, д. 20,\nтел. 8 (49346) 2-14-72, pestyaki_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 17,
        "col3": "Территориальное управление социальной защиты населения по Приволжскому муниципальному району",
        "col4": "155550 Ивановская обл., г. Приволжск, пер. Коминтерновский, д. 2,\nтел. 8 (49339) 4-18-09,\nprivoljsk_szn@ivreg.ru  \n\n"
    },
    {
        "col1": "Ивановская область",
        "col2": 18,
        "col3": "Территориальное управление социальной защиты населения по Родниковскому муниципальному району",
        "col4": "155250 Ивановская область, г. Родники, ул. Советская, д. 10,\nтел. (49336) 2-23-66, 2-14-11, rodniki_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 19,
        "col3": "Территориальное управление социальной защиты населения по Савинскому муниципальному району",
        "col4": "155710, Ивановская область, п. Савино, ул. Первомайская, д. 12,\nтел. 8 (49356) 9-16-65,\nsavino_szn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 20,
        "col3": "Территориальное управление социальной защиты населения по Южскому муниципальному району",
        "col4": "155630, Ивановская область, г. Южа, \nул. Советская, д. 23,\nтел. 8 (49347) 2-13-85, \nuga_szn@ivreg.ru "
    },
    {
        "col1": "Ивановская область",
        "col2": 21,
        "col3": "Территориальное управление социальной защиты населения по Юрьевецкому муниципальному району",
        "col4": "155453, Ивановская обл., г. Юрьевец, \nул. Советская, д. 33,\nтелефон: (49337) 2-12-91,\nurevec_szn@ivreg.ru"
    },
    {
        "col1": "Иркутская область",
        "col2": 1,
        "col3": "Министерство социального развития, опеки и попечительства Иркутской области",
        "col4": "почтовый адрес:664025, Иркутская область, г. Иркутск, ул. Канадзавы, д. 2, Адрес электронной почты:obl_sobes@sobes.admirk.ru, номер телефона : 3952333331; 3952253307"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 1,
        "col3": "отдел опеки и попечительства местной администрации городского округа Нальчик Кабардино-Балкарской Республики",
        "col4": "360000, Кабардино-Балкарская Республика, г. Нальчик, ул. Шогенцукова, д. 17, nalchik_opeka@mail.ru, 8(8662)424985 "
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 2,
        "col3": "отдел опеки и попечительства муниципального казенного учреждения \"Департамент образования городского округа Баксан\" Кабардино-Балкарской Республики",
        "col4": " Кабардино-Балкарская Республика,  г. Баксан,          ул. Буденного, д. 91, opekabaksan@yandex.ru, 8(86634)41254"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 3,
        "col3": "отдел опеки и попечительства местной администрации городского округа Прохладный Кабардино-Балкарской Республики",
        "col4": " 361000, Кабардино-Балкарская Республика,             г. Прохладный, ул. Головко, д. 345, opekaprohladny@yandex.ru, 8(86631)42567"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 4,
        "col3": "отдел опеки и попечительства МУ \"Управление образования\" местной администрации Эльбрусского муниципального района Кабардино-Балкарской Республики",
        "col4": "361624, Кабардино-Балкарская Республика, Эльбрусский район,  г. Тырныауз,  пр-т Эльбрусский, 39 (1-й этаж), opekaelbrys@mail.ru, 8(86638)43752"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 5,
        "col3": "отдел опеки и попечительства МКУ \"Управление образования и молодежной политики Черекского муниципального района\" Кабардино-Балкарской Республики ",
        "col4": " Кабардино-Балкарская Республика, Черекский район, г.п. Кашхатау, ул. Мечиева, д. 108,  cherek-opeka@yandex.ru, 8(86636)41020"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 6,
        "col3": "отдел опеки и попечительства МКУ \"Управление образования местной администрации Чегемского муниципального района\" Кабардино-Балкарской Республики",
        "col4": "361401, Кабардино-Балкарская Республика, Чегемский  район, г.п. Чегем, ул. Баксанское шоссе, 26, opeka-chegem@yandex.ru, 8(86630)41048"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 7,
        "col3": "отдел опеки и попечительства МКУ \"Управление образования местной администрации Урванского муниципального района Кабардино-Балкарской Республики\" ",
        "col4": "361336, Кабардино-Балкарская Республика, Урванский район, г.п.Нарткала, ул. Ленина, д.35, urvanopeka@mail.ru, 8(86635)43062 "
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 8,
        "col3": "отдел по вопросам  опеки и попечительства Управления образования местной администрации Терского муниципального района  Кабардино-Балкарской Республики",
        "col4": "361201, Кабардино-Балкарская Республика, Терский район, г. Терек, улица Ленина, 15, opekaterek@mail.ru, 8(86632)41847"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 9,
        "col3": "отдел  опеки и попечительства местной администрации Прохладненского муниципального района  Кабардино-Балкарской Республики",
        "col4": "361045, Кабардино-Балкарская Республика,              г. Прохладный,                        ул. Гагарина, 47, a.v.kazakova@mail.ru, 8(86631)75948"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 10,
        "col3": "отдел  опеки и попечительства местной администрации Лескенского муниципального района  Кабардино-Балкарской Республики",
        "col4": "361350, Кабардино-Балкарская Республика, Лескенский район, с.п. Анзорей, ул. Шинахова, д.1a, lesken.opeka@mail.ru          8(86639) 95363, 95411"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 11,
        "col3": "отдел  опеки и попечительства МКУ \"Управление образования\" местной администрации Зольского муниципального района  Кабардино-Балкарской Республики",
        "col4": "361701, Кабардино-Балкарская Республика, Зольский район, г.п. Залукокоаже,  ул. имени И.Ц. Котова, д.16, zol.opeka@mail.ru         8(86637) 41544"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 12,
        "col3": "отдел  опеки и попечительства МУ \"Управление образования местной администрации Баксанского муниципального района\"  Кабардино-Балкарской Республики",
        "col4": "361535, Кабардино-Балкарская Республика,  г.Баксан, ул.Революционная, б/н, opekabaksruo@mail.ru, 8(86634) 21075"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 13,
        "col3": "отдел  опеки и попечительства МУ \"Управление образования местной администрации Майского муниципального района\"  Кабардино-Балкарской Республики",
        "col4": "361112, Кабардино-Балкарская Республика,            г. Майский ул. Ленина, 27/2, opeka-may@mail.ru, 8(86633) 21701"
    },
    {
        "col1": "Калининградская область",
        "col2": 1,
        "col3": "Министерство социальной политики Калининградской области",
        "col4": "236016, Калининградская обл., г. Калининград, ул. Клиническая, д. 63, social@gov39.ru, 8 (4012) 599-665, 599-699"
    },
    {
        "col1": "Калужская область",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты Калужской области ",
        "col4": "\n248016 г. Калуга, ул. Пролетарская, д. 111, mintrud.admoblkaluga.ru, 719-140, 719-176"
    },
    {
        "col1": "Камчатский край",
        "col2": 1,
        "col3": "Министерство социального благополучия и семейной политики Камчатского края",
        "col4": "683003, Камчатский край,\nг. Петропавловск-Камчатский,\nул. Ленинградская 118,\nтел. +7 (4152) 42-83-55\nЭл. почта: minsrt@kamgov.ru"
    },
    {
        "col1": "Карачаево - Черкесская Республика",
        "col2": 1,
        "col3": "Министерство труда и социального развития Карачаево-Черкесской Республики",
        "col4": "369000, КЧР, г.Черкесск, ул. Комсомольская, 23,\nmtisr@mail.ru   8 878 226 67 48"
    },
    {
        "col1": "Кемеровская область",
        "col2": 1,
        "col3": "Министерство образования Кузбасса",
        "col4": "650064 г.Кемерово, проспект Советский, д.58, deti@ruobr.ru, 8-3842-36-27-78, 8-961-703-64-84"
    },
    {
        "col1": "Кировская область",
        "col2": 1,
        "col3": "Администрация Арбажского муниципального округа",
        "col4": "ул. Пионерская, д. 2, пгт Арбаж, Кировская область, 612180, (83330) 2-12-32, admarbazh@mail.ru; (83330) 2-15-57, opeka.arbazh@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 2,
        "col3": "Администрации Афанасьевского муниципального округа Кировской области",
        "col4": "613060 Кировская область, Афанасьевский район, пгт Афанасьево, ул.Красных Партизан, д.5, admafan@kirovreg.ru 883331 2-19-51"
    },
    {
        "col1": "Кировская область",
        "col2": 3,
        "col3": "Администрация Белохолуницкого муниципального района",
        "col4": "613200 Кировская область г.Белая Холуница ул.Глазырина, д.6/bh_opeka@mail.ru/88336442427"
    },
    {
        "col1": "Кировская область",
        "col2": 4,
        "col3": "Администрация Богородского муниципального округа",
        "col4": "612470, Кировская область, Богородский район, пгт Богородское, ул. Советская, д. 43, 883321251, admbogo@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 5,
        "col3": "Администрация Верхнекамского муниципального округа",
        "col4": "612820 Кировская область, г. Кирс, ул. Кирова, д. 16 883339 2-33-72 imushavr@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 6,
        "col3": "Администрация Верхошижемского муниципального образования",
        "col4": "613310, Кировская область, пгт Верхошижемье, ул. Комсомольская, д. 2, 89123374172, opekashishma@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 7,
        "col3": "Администрация Вятскополянского муниципального района Кировской области",
        "col4": "612964, Кировская область, г.Вятские Поляны, ул.Гагарина, д.28, admvyatp@kirovreg.ru, 89005294510"
    },
    {
        "col1": "Кировская область",
        "col2": 8,
        "col3": "Администрация муниципального образования Даровской муниципальный район Кировскойобласти",
        "col4": "612140, Кировская область, Даровской район, пгт Даровской, ул. Набережная, д. 8, 88333621906, admdaro@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 9,
        "col3": "Администрация Зуевского района Кировской области",
        "col4": "612412, Кировская область г. Зуевка, ул. Опалева, д. 65, 8 (83337) 25461, azr@zrko.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 10,
        "col3": "Администрация Кикнурского муниципального округа",
        "col4": "612300, Кировская область, Кикнурский район, пгт Кикнур, ул. Советская, д. 36, kiknur-opeka@mail.ru, (83341)5-14-79"
    },
    {
        "col1": "Кировская область",
        "col2": 11,
        "col3": "Администрация Кильмезского муниципального района",
        "col4": "613570 Кировская область, пгт Кильмезь, ул.Советская, д.79, opekakilmez2017@yandex.ru (83338)22036"
    },
    {
        "col1": "Кировская область",
        "col2": 12,
        "col3": "Администрация Кирово-Чепецкого района",
        "col4": "613040 Кировская область, ул. Перовмайская, д. 6, 8(83361)49150, mailbox@admkchr.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 13,
        "col3": "Администрация Котельничского района ",
        "col4": "612600, Кировская область, г. Котельнич, ул. Карла Маркса, д. 16, kotelnich_rayon@mail.ru 8(83342)4-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 14,
        "col3": "Администрация Куменского района",
        "col4": "613400 Кировская область, Куменский район, ул. Кирова, д.11, 8 (83343)2-12-50, adm@kumensky.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 15,
        "col3": "Администрация Лебяжского муницильного округа",
        "col4": "613500, Кировская область, Лебяжский район, пгт Лебяжье, ул.Комсомольская, д.5, leb_opeka@bk.ru, 88334420942"
    },
    {
        "col1": "Кировская область",
        "col2": 16,
        "col3": "Администрация муниципального образования Лузский муниципальный округ Кировской области",
        "col4": "613980, Кировская область, г. Луза, ул. Ленина, д. 35, admluza@kirovreg.ru, 883346-5-18-04"
    },
    {
        "col1": "Кировская область",
        "col2": 17,
        "col3": "Администрация Малмыжского района",
        "col4": "612920, Кировская область, г. Малмыж, ул. Чернышевского, д. 2а opekamalm@yandex.ru (83347) 2-28-18"
    },
    {
        "col1": "Кировская область",
        "col2": 18,
        "col3": "Администрация Мурашинского муниципального округа",
        "col4": "613711, Кировская область, г. Мураши, ул. Карла Маркса, д. 28, opeka_murashi@mail.ru, 8(83348)2-28-86"
    },
    {
        "col1": "Кировская область",
        "col2": 19,
        "col3": "Администрация Нагорского района",
        "col4": "613260, Кировская область пгт. Нагорск ул. Леушина д. 21 (83349) 2-16-70, admnago@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 20,
        "col3": "Администрация Немского муниципального округа ",
        "col4": "613470 Кировская область ул. Советская д. 36 т. 8(83350)2-22-85. opeka.nema@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 21,
        "col3": "Администрация Нолинского района",
        "col4": "613440, Кировская область, г.Нолинск, ул.Спартака, д.36, opekanolinsk@mail.ru, 88336821180"
    },
    {
        "col1": "Кировская область",
        "col2": 22,
        "col3": "Администрация Омутнинского района",
        "col4": "612740, Кировская область, г. Омутнинск, ул. Комсомольская, д. 9, admomut@kirovreg.ru, 88335221251"
    },
    {
        "col1": "Кировская область",
        "col2": 23,
        "col3": "Управление образования администрации Опаринского МО",
        "col4": "613810, Кировская область, пгт. Опарино, ул. Первомайская, 14 opeka.oparino@yandex.ru (83353) 2-11-02"
    },
    {
        "col1": "Кировская область",
        "col2": 24,
        "col3": "Администрация Орловского района",
        "col4": "612270, Кировская обл, Г.Орлов, ул. Ст.Халтурина, д. 18 orlovopeka@mail.ru (83365) 2-16-45"
    },
    {
        "col1": "Кировская область",
        "col2": 25,
        "col3": "Администрация Пижанского муниципального округа ",
        "col4": "613380, Кировская область, Пижанский район, пгт. Пижанка, ул. Труда, 25 opp4325@yandex.ru 8(83355)22172"
    },
    {
        "col1": "Кировская область",
        "col2": 26,
        "col3": "Администрация Подосиновского района Кировской области",
        "col4": "613930, Кировская область, Подосиновский район, пгт Подосиновец,ул. Советская д. 77, podopeka@yandex.ru, 8(83351) 2-16-54"
    },
    {
        "col1": "Кировская область",
        "col2": 27,
        "col3": "Администрация Санчурского Муниципального округа",
        "col4": "Кировская область, Санчурский район, пгт. Санчурск, ул. Р. Люксембург, д.6А, 612370 тел. 883357 2-13-21 , admsanch@kiroveg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 28,
        "col3": "Управление социальной политики администрации Свечинского муниципального округа",
        "col4": "612040, Кировская область, пгт Свеча, ул. Октябрьская, д.20, Тел: 8(83358)2-23-35, Эл.п.: sv_opeka@mail.ru, admsvec@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 29,
        "col3": "администрация Слободского муниципального района",
        "col4": "613150Кировская область г. Слободской ул. Советская д.86 тел 88336241252 admslob@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 30,
        "col3": "Администрация Советского муниципального района, ",
        "col4": "613340,Кировская область, Советский район, г. Советск, ул. Кирова,д.5 (83375)2"
    },
    {
        "col1": "Кировская область",
        "col2": 31,
        "col3": "Администрация Сунского муниципального района ",
        "col4": "612450, Кировская область, Сунский район, пгт Суна, ул.Большевиков, д.1, 8(83369)33694 sunaopeka@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 32,
        "col3": "Администрация Тужинского муниципального района",
        "col4": "612200, Кировская область, Тужинский район, пгт Тужа, ул. Горького, д. 5, ruotuzha43@yandex.ru, 8(83340)2-16-81"
    },
    {
        "col1": "Кировская область",
        "col2": 33,
        "col3": "администрация Унинского муниципального округа",
        "col4": "612540 Кировская область, Унинский район, пгт.Уни, ул.Ленина, д.17 admunin@kirovreg.ru 8833592-14- 65"
    },
    {
        "col1": "Кировская область",
        "col2": 34,
        "col3": "Администрация Уржумского муниципального района",
        "col4": "  613530, г.Уржум, ул.Рокина, д. 13, 9536724559, ruo.opeka.@ mаil.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 35,
        "col3": "Администрация Фаленского муниципального округа",
        "col4": "612500, Кировская область, Фаленский район, пгт Фаленки, ул. Свободы,д 65, 8833322-11-30 kd81123@yandex.ru, 89536922268 opekafalenki@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 36,
        "col3": "Районное управление образования администрации Шабалинского района",
        "col4": "612020, ул. Советская, д.33, пгт Ленинское, Шабалинского района, Кировской области\nТелефон: (83345) 20158 Shabalino@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 37,
        "col3": "администрация Юрьянского района",
        "col4": "613600 Кировская область, Юрьянский район, пгт Юрья, ул. Ленина, 46, admjurja@kirovred.ru 883366 2-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 38,
        "col3": "Администрация Яранского района",
        "col4": "г.Яранск, ул.Кирова, д.10 612260, 883367-2-06-59 opeka-yaransk@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 39,
        "col3": "Администрация ЗАТО Первомайский",
        "col4": "613648,ЗАТО Первомайский ул. Волкова, 14 , admzatopervomaysk@mail.ru, 8(833266)2-42-31"
    },
    {
        "col1": "Кировская область",
        "col2": 40,
        "col3": "Администрация города Вятские Поляны",
        "col4": "612964, Кировская область, г. Вятские Поляны, ул. Гагарина 28а 8(83334) 61162 opekavp@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 41,
        "col3": "Администрация муниципального образования городской округ \"Город Кирово-Чепецк\" Кировской области",
        "col4": "613040, Кировская область, г. Кирово-Чепецк, ул. Первомайская, д. 6, 8(83361) 4-50-50 admcher@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 42,
        "col3": "Управление образования города Котельнича администрации городского округа города Котельнича",
        "col4": "612600, Кировская область, г. Котельнич, ул.Ленина, 26,               8 (83342) 4-15-72, yokotel@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 43,
        "col3": "Администрация города Слободского",
        "col4": "613150, г. Слободской, ул. Советская, д.86, тел. 8(8332) 25-53-49, slob-adm@rambler.ru."
    },
    {
        "col1": "Кировская область",
        "col2": 44,
        "col3": "Администрация города Кирова",
        "col4": "610000, Кировская область, г. Киров, ул. Воровского, д. 39, opeka@admkirov.ru, 8(8332)54-64-12, 54-00-42, 57-94-67, 54-09-68, 54-77-48"
    },
    {
        "col1": "Кировская область",
        "col2": 45,
        "col3": "Администрация Оричевского района",
        "col4": "612080, Кировская область, Оричевский район, пгт Оричи, ул. К. Маркса, д. 12 ( 883354)2-11-75, adm@orichi-rayon.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 1,
        "col3": "администрация Антроповского муниципального округа Костромской области",
        "col4": "157260, Костромская область, Антроповский район, п. Антропово, ул.Октябрьская, д. 12, (49430) 3-52-03, antropovo@ kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 2,
        "col3": "администрация Буйского муниципального района Костромской области ",
        "col4": "157000, Костромская область, пл.Революции, 13, (49435) 4-51-40, bui@ kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 3,
        "col3": "администрация Вохомского муниципального района Костромской области ",
        "col4": "157760, Костромская область, Вохомский район, п. Вохма, ул.Советская, 39, (49450) 2-13-34, vohma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 4,
        "col3": "администрация Галичского муниципального района Костромской области ",
        "col4": "157201, Костромская область, Галичский район, г. Галич, пл.Революции, 23а, (49437) 2-21-34, galich@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 5,
        "col3": "администрация Кадыйского муниципального округа Костромской области ",
        "col4": "157980, Костромская область, Кадыйский район, п.Кадый, ул.Центральная, 3, (49442) 3-40-08, kadiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 6,
        "col3": "администрация Кологривского муниципального округа Костромской области ",
        "col4": "157400, Костромская область, Кологривский район, г. Кологрив, ул. Набережная р.Киченки, 13, (49443)5-27-49, kologriv@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 7,
        "col3": "администрация Костромского муниципального района Костромской области ",
        "col4": "156961, Костромская область, г. Кострома, ул. М. Новикова, 7, (4942) 55-02-02, kosrn@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 8,
        "col3": "администрация Красносельского муниципального района Костромской области ",
        "col4": "157940, Костромская область, п. Красное-на-Волге, Красная пл., 15, (49432) 2-19-46, krasnoe@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 9,
        "col3": "администрация Макарьевского муниципального округа Костромской области ",
        "col4": "157460, Костромская область, Макарьевский район, г. Макарьев, пл. Революции, 8, (49445) 55-1-31 makarev@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 10,
        "col3": "администрация Мантуровского муниципального округа Костромской области ",
        "col4": "157300, Костромская область, Мантуровский район, г. Мантурово, ул.Центральная, д.5, (49446) 2-04-50 gorod_manturovo@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 11,
        "col3": "администрация Межевского муниципального округа Костромской области  ",
        "col4": "157420, Костромская область, Межевской район, с. Георгиевское, ул. Октябрьская, 39, (49447) 5-23-19 mezha@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 12,
        "col3": "администрация города Нерехта и Нерехтского муниципального района Костромской области                        ",
        "col4": "157800, Костромская область, Нерехтский район, г. Нерехта, ул.Победы, 1,  (49431) 7-62-03 nerehta@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 13,
        "col3": "администрация Нейского муниципального округа Костромской области ",
        "col4": "157330, Костромская область, Нейский район, г. Нея, ул.Соловьева, 6, (49444) 3-19-14 neya@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 14,
        "col3": "администрация Октябрьского муниципального округа Костромской области ",
        "col4": "157780, Костромская область, октябрьский район, с. Боговарово, ул. Победы, 37, (49451)2-11-86, oktyabrskiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 15,
        "col3": "администрация Островского муниципального округа Костромской области ",
        "col4": "157900, Костромская область, Островский район, п. Островское, ул. Советская, 56, (49438) 2-72-95, ostrovskiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 16,
        "col3": "администрация Павинского муниципального округа Костромской области ",
        "col4": "157650, Костромская область, Павинский район, с. Павино, ул.Октябрьская, 15, (49439) 2-11-63  pavino@ kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 17,
        "col3": "администрация Парфеньевского муниципального округа Костромской области",
        "col4": "157270, Костромская область, Парфеньевский район, с.Парфеньево, ул. Маркова, 17,  (49440) 2-41-52, parfenyevo@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 18,
        "col3": "администрация Поназыревского муниципального округа Костромской области ",
        "col4": "157580, Костромская область, Поназыревский район, п. Поназырево, ул. Свободы, 1, (49448)2-11-67, ponazyrevo@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 19,
        "col3": "администрация Пыщугского муниципального округа Костромской области ",
        "col4": "157630, Костромская область, Пыщугский район, с. Пыщуг, ул.Чкалова, 6, (49452) 2-77-81, pyshchug@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 20,
        "col3": "администрация Солигаличского муниципального округа Костромской области ",
        "col4": "157170, Костромская область, Солигаличский район, г. Солигалич, ул. Коммунистическая, 1, (49436)5-12-74 soligalich@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 21,
        "col3": "администрация Судиславского муниципального района Костромской области ",
        "col4": "157860, Костромская область, Судиславский район, п. Судиславль, ул. Советская, 2, (49433) 9-84-76 sudislavl@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 22,
        "col3": "администрация Сусанинского муниципального района Костромской области ",
        "col4": "157080, Костромская область, Сусанинский район, ул. Ленина, 2а, (49434) 9-03-33 susanino@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 23,
        "col3": "администрация Чухломского муниципального района",
        "col4": "157130, Костромская область, Чухломский район, г. Чухлома, пл.Революции, 11,  (49441) 2-12-13, chuhloma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 24,
        "col3": "администрация Шарьинского муниципального района Костромской области ",
        "col4": "157500, Костромская область, Шарьинский район, г. Шарья, ул. Октябрьская, 21, (49449) 5-03-50, sharya@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 25,
        "col3": "администрация городского округа город Кострома Костромской области ",
        "col4": "156000, Костромская область, г. Кострома, ул. Советская, 1, (4942) 31-39-32, 31-53-41, gorod_kostroma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 26,
        "col3": "администрация городского округа город Буй Костромской области",
        "col4": "157000, Костромская область, г. Буй, пл.Революции, 12,  (49435) 4-45-03, доб.118,   gorod_buy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 27,
        "col3": "администрация городского округа город Волгореченск Костромской области",
        "col4": "156901, Костромская область, г. Волгореченск, ул. Имени 50-летия Ленинского Комсомола, 4,  (49453) 5-25-02, gorod_volgorechensk@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 28,
        "col3": "администрация городского округа город Галич Костромской области ",
        "col4": "157201, Костромская область, г. Галич, пл. Революции, 23а, (49437) 2-17-20, gorod_galich@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 29,
        "col3": "администрация городского округа город Шарья Костромской области",
        "col4": "157500, Костромская область, г. Шарья, ул. Октябрьская, 21, (49449) 5-89-32 gorod_sharya@ kostroma.gov.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 1,
        "col3": "Сектор по жилищным вопросам администрации муниципального образования город Армавир",
        "col4": "352900, г. Армавир, ул. К. Либкнехта, 52,  +7 (861) 372-7470, 373-27-80 gladysheva_arm@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 2,
        "col3": "Отдел по учету граждан в качестве нуждающихся в жилых помещениях правового управления администрации муниципального образования город-курорт Геленджик",
        "col4": "353460, г. Геленджик, ул. Революционная, д. 1, каб. 229, 232, 8 (86141) 2-08-56, 8 (86141) 2-08-31, pul@gelendzhik.org"
    },
    {
        "col1": "Краснодарский край",
        "col2": 3,
        "col3": "Управление по жилищным вопросам администрации муниципального образования город Краснодар ",
        "col4": "350015, г. Краснодар, ул. Кузнечная, 6, 8 (861) 218-92-41, housing@krd.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 4,
        "col3": "Отдел жилищного учета администрации муниципального образования город Новороссийск",
        "col4": "353900, г. Новороссйск, ул. Губернская, д. 1, 8(8617) 64-64-91, 8(8617) 64-58-56, ozhu_nvrsk@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 5,
        "col3": "Департамент городского хозяйства администрации муниципального образования городской округ город-курорт Сочи Краснодарского края",
        "col4": "354000, г.-к. Сочи, Островского, 35/11, каб.5 и 12, 8 (862) 266-06-06 (доб. 1750), dgh@sochiadm.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 6,
        "col3": "Управление имущественных отношений администрации муниципального образования город-курорт Анапа",
        "col4": "353440, г. Анапа Пушкина, д. 28, 8 (861-33) 5-29-68                                          8 (918) 939-02-29, go@anapa.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 7,
        "col3": "Отдел по учету и работе с гражданами, нуждающимися в жилье администрации муниципального образования город Горячий Ключ",
        "col4": "353290, г. Горячий Ключ, ул. Ленина, д. 191, 8 (861-59) 3-84-49 (доб.237) \n8 (988) 522-65-33, adm342@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 8,
        "col3": "Отдел топливно-энергетического комплекса, жилищно-коммунального хозяйства, транспорта и связи администрации муниципального образования Ленинградский район",
        "col4": "353740 ст. Ленинградская, ул. Чернышевского, д.179, 8 (861-45) 3-85-60, otdelgkh-len@mail.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 9,
        "col3": "Отдел жилищно-коммунального хозяйстваи капитального строительства администрации муниципального образования Приморско-Ахтарский район",
        "col4": "353860 г. Приморско-Ахтарск,                               ул. 50 лет Октября, 63, 8 861-43-2-10-67, oks-ahtari@ya.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 10,
        "col3": "Управление ЖКХ и ТЭК администрации муниципального образования Туапсинский район",
        "col4": "352800 г. Туапсе, ул. Свободы, д. 3, 7 861 672-15-01, +7 861 672-58-44, +7 918 335-96-69,  okops@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 11,
        "col3": "Управление жилищно-коммунального хозяйства, транспорта и связи администрации муниципального образования Абинский район",
        "col4": "353320, г. Абинск, ул. Советов,128а,+7 861 505-15-98 +7 861 504-46-96 +7 861 505-21-92 ab_munzak@mail.ru abin_usgt@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 12,
        "col3": "Отдел ЖКХ, топливно-энергетического комплекса, дорожного хозяйства и трпанспорта, промышленности и связи администрации муниципального образования Апшеронский район",
        "col4": "352690, г.Апшеронск, ул. Коммунистическая, д.17, 8(861)522-58-15, apsheronskgkh1@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 13,
        "col3": "Отдел по управлению муниципальным имуществом администрации муниципального образования Белоглинский район",
        "col4": "353000, с. Белая Глина, ул. Красная, д. 160, каб. 305, 8 (861-54) 7-29-86, oumi@belaya-glina.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 14,
        "col3": "Управление промышленности, транспорта, строительства и ЖКХ администрации муниципального образования Белореченский район",
        "col4": "Белореченск, Ленина 66/1, каб. 11, 8(86155) 3-34-13, belorechensk@mo.krasnodar.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 15,
        "col3": "Отдел имущественных отношений администрации муниципального образования Брюховецкий район",
        "col4": "352750, ст-ца Брюховецкая ул Красная, д. 211, 8 (861-56) 3-20-56, brukhovezk@mo.krasnodar.ru, ms04@diok.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 16,
        "col3": "Сектор по социальным вопросам администрации муниципального образования Выселковский район",
        "col4": "353100, ст-ца Выселки Ленина ул., д. 37,  8(86157)73442, admvsp@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 17,
        "col3": "Отдел топливно-энергетического клмплекса и жилищно-коммунального хозяйства управления топливно-энергетического комплекса, жилищно-коммунального хозяйства и транспорта администрации муниципального образования Гулькевичский район",
        "col4": " 352190, г. Гулькевичи, ул. Советская, 14, 8 (861-60) 5-18-16, 8 (918) 951-26-16, otdel.zhkh@mogulk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 18,
        "col3": "администрация муниципального образования Динской район",
        "col4": "353200 ст-ца Динская, ул. Красная, 55, 8-(86162) 6-17-02, dinskaya@mo.krasnodar.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 19,
        "col3": "Управление жилищно-коммунального хозяйства и капитального строительства администрации муниципального образования Ейский район",
        "col4": "353680, г. Ейск, ул. Победы, д. 108, 8(86132)2-32-24, gkh@yeiskraion.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 20,
        "col3": "Управление имущественных отношений администрации муниципального образования Кавказский район",
        "col4": "352380, г. Кропоткин, пер. Братский/ул. Гоголя, д. 18/44, 8 +7 (86138) 6-26-23 +7 (86138) 6-41-23 uio.kavkazraion@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 21,
        "col3": "Управление градостроительства и благоустройства администрации муниципального образования Калининский район",
        "col4": "353780 ст. Калининская, \nул. Ленина, д. 147, +7 861 632-13-04  +7 861 632-28-47\n+7 861 632-22-24, mucb_stat@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 22,
        "col3": "Управление строительства администрации муниципального образования Каневской район",
        "col4": "353730 ст. Каневская,                                                                    ул. Вокзальная, 32, +7 861 644-05-37, +7 861 644-53-27, +7 861 647-53-07 orgkh@kanevskadm.ru, stori@kanevskadm.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 23,
        "col3": "Отдел жилищно-коммунального хозяйства, транспорта и связи администрации муниципального образования Кореновский район",
        "col4": "353181, г. Кореновск, ул. Суворова, 1А, +7 (86142) 4-18-49, +7 (86142) 4-71-03, +7 (86142) 4-51-51, GKHKorenovsk@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 24,
        "col3": "Управление муниципальной собственностью администрации муниципального образования Красноармейский район",
        "col4": "353800, станица Полтавская, ул. Красная, д. 122, +7 (86165) 3-32-11, +7 (86165) 3-25-30, +7 (86165) 3-23-30, komitetums@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 25,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Крыловский район",
        "col4": "352080,ст-ца Крыловская, ул. Орджоникидзе, 43, тел. раб. 8(86161)35-7-19 e-mail: semyakril@rambler.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 26,
        "col3": "Управление опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Крымский район",
        "col4": "353389, г. Крымск, ул. Вишневая, д. 2, 8(86131) 2-05-10, 2-16-11, 2-17-11, 2-17-10, krymsk_usd@bk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 27,
        "col3": "Управление имущественных отношений администрации муниципального образования Курганинский район",
        "col4": " 352430, г. Курганинск, ул. Ленина, 27, +7 861 472-44-54, +7 616 226-18-05, +7 861 472-10-67, kurg_adm@mail.ru, dinsk_zem@bk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 28,
        "col3": "Управление муниципальным имуществом администрации муниципального образования Кущевский район",
        "col4": " 352030, ст-ца Кущевская, пер. им. Б.Е.Москвича, д.67, +7 (86168) 5-70-98, kushzakaz@yandex.ru\nms17@diok.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 29,
        "col3": "Управление по опеке и попечительству в отношении несовершеннолетних администрации муниципального образования Лабинский район",
        "col4": "352500, г.Лабинск, ул.Константинова 6, раб.тел. 8(86169) 3-12-19,  3-34-76, 3-56-28 e-mail: family@labinskadmin.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 30,
        "col3": "Управление имущественных и зенмельных отношений администрации муниципального образования Мостовский район",
        "col4": "352570, пгт Мостовской, ул. Горького , д. 140, 8-861-92-5-13 42  ms20@diok.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 31,
        "col3": "Управление имущественных отношений администрации муниципального образования Новокубанский район",
        "col4": "352240 г. Новокубанск,                                      ул.Первомайская, 151, +7 (86195) 4‒15‒78, yuonr@mo.krasnodar.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 32,
        "col3": "Отдел по социальным вопросам администрации муниципального образования Новопокровский район",
        "col4": "353020, ст. Новопокровская, ул. Ленина, 110, 8 (86149) 7-11-32, novpos@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 33,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Отрадненский район",
        "col4": "352292, ст. Отрадная, ул. Первомайская. д.20, раб.факс 8(86144) 3-36-98, 3-35-98 e-mail: detstvo-2008@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 34,
        "col3": "Администрация муниципального образования Павловский район",
        "col4": "352040, ст-ца Павловская, ул. Пушкина, 260  8 (86191) 3-31-00, pavlovsk@mo.krasnodar.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 35,
        "col3": "Управление экономики, инвестиций и прогнозирования администрации муниципального образования Северский район",
        "col4": "353240, ст-ца Северская, Ленина, 69, +7 861 662-17-51, oeir@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 36,
        "col3": "Управление жизнеобеспечения, транспорта и связи администрации муниципального образования Славянский район",
        "col4": "353560, Славянск-на-Кубани, , Крупская ул., 217 а, 8 (861-46) 3-20-41, slav_dorhoz@mail.ru slavgkh@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 37,
        "col3": "Управление по вопросам строительства, архитектуры и жилищно-коммунального хозяйства администрации муниципального образования Староминский район",
        "col4": "353600, ст-ца Староминская, ул. В. Петренко, д. 5, +7 861 535-71-22, +7 861 534-31-06, +7 861 535-71-46, starcba@yandex.ru, 8615357185@mail.ru, 8615357122@rambler.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 38,
        "col3": "Отдел по ЖКХ, транспорту и связи управления по ЖКХ, строительству, архитектуре администрации муниципального образования Тбилисский район",
        "col4": "352360, ст-ца Тбилисская, ул. Первомайская, 17,  +7 (86158) 3-11-65, tbl_gkh@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 39,
        "col3": "Управление опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Темрюкский район",
        "col4": "353500, г.Темрюк, ул.Ленина, 63, раб. тел. 8 (86148) 5-29-71, e-mail:opeka-temruk@yandex.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 40,
        "col3": "Отдел ЖКХ, транспорта, связи администрации муниципального образования Тимашевский район",
        "col4": "352708, г. Тимашевск, ул. Красная 100, 3, +7 861 30 5-15-19, ostsgkh@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 41,
        "col3": "Управление жилищных отношений администрации муниципального образования Тихорецкий район",
        "col4": "352120, г.Тихорецк, ул.Октябрьская, 38, 8(861-96) 7-16-35 upr-gil@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 42,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Успенский район",
        "col4": "352452, с. Успенское,  ул. Калинина, д.76    раб./факс 8(86140)5-56-97, 5-81-19   e-mail: usp_otdelopeki@mail.ru  "
    },
    {
        "col1": "Краснодарский край",
        "col2": 43,
        "col3": "Управление ипо вопросам земельных отношений и учета муниципальной собственности администрации муниципального образования Усть-Лабинский район",
        "col4": "352330  г.Усть-Лабинск,\nЛенина ул., д. 33 8- 861-35 5-27-51 oms.uzoms@yandex.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 44,
        "col3": "Отдел по вопросам строительства жилищно-комунального хозяйства и транспорта администрации муниципального образования Щербиновский район",
        "col4": "353620, ст. Старощербиновская, ул. Советов, 68, +7 (86151) 7-82-86, scherbinovsky@mo.krasnodar.ru"
    },
    {
        "col1": "Красноярский край",
        "col2": 1,
        "col3": "Министерство социальной политики Красноярского края",
        "col4": "60049, Красноярский край, г. Коасноярск, пр. Мира, д. 34, szn@m.szn24.ru, 8 391 229-16-00"
    },
    {
        "col1": "Курганская область",
        "col2": 1,
        "col3": "Администрация города Кургана",
        "col4": "640000, г. Курган, пл.им. В.И. Ленина,1, тел. (3522) 42-88-01; mail@kurgan-city.ru ; 45t00902@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 2,
        "col3": "Администрация города Шадринска",
        "col4": "641884, Курганская область, г. Шадринск, ул. Свердлова, д.59, тел. (35253) 63212; public@shadrinsk-city.ru; 45t02602@kurganobl.ru 26"
    },
    {
        "col1": "Курганская область",
        "col2": 3,
        "col3": "Администрация Альменевского муниципального округа Курганской области ",
        "col4": "641130, Курганская область, с. Альменево, пл. Комсомола, 1, тел. (35242) 9-87-41; 45t00102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 4,
        "col3": "Администрация Белозерского муниципального округа Курганской области ",
        "col4": "641330, Курганская область, с.Белозерское, ул. К.Маркса, д.16, тел.(35232) 2-90-70; belozeradm@mail.ru; 45t00202@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 5,
        "col3": "Администрация Варгашинского муниципального округа Курганской области",
        "col4": "641230, область Курганская, район Варгашинский, р.п.Варгаши, улица Чкалова, д. 22, тел. (35233) 2-21-55;  45t00302@kurganobl.ru  "
    },
    {
        "col1": "Курганская область",
        "col2": 6,
        "col3": "Администрация Далматовского муниципального округа Курганской области",
        "col4": "641730,  Курганская обл., Далматовский р-он, г. Далматово, ул. Советская, 187, тел. (35252) 3-81-30; radm@dalmatovo.ru; 45t00402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 7,
        "col3": "Администрация  Звериноголовского муниципального округа Курганской области",
        "col4": "641480, Курганская область, с. Звериноголовское, ул. Чапаева, 41, тел. (35240) 2-15-05; 45t00502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 8,
        "col3": "Администрация Каргапольского муниципального округа Курганской области",
        "col4": "641920, Курганская область, р.п. Каргаполье, ул. Калинина, д.35, тел. (35256) 2-16-30;  45t00602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 9,
        "col3": "Администрация Катайского района муниципального округа Курганской области",
        "col4": "641700, Курганская область, г.Катайск, ул. Ленина, д.200, тел. (35251)21545; katadmin@mail.ru ; 45t00702@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 10,
        "col3": "Администрация Кетовского муниципального округа Курганской области",
        "col4": "641310, Курганская область, с.Кетово, ул. Космонавтов, д.39, тел. (35231) 23541; AdmKetr@mail.ru; 45t00802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 11,
        "col3": "Администрация Куртамышского муниципального округа Курганской области",
        "col4": "641430, Курганская область, г. Куртамыш, ул. XХII партсъезда, д.40, тел. (35249)2-18-10; kurtamysh-gorod@yandex.ru; 45t01002@kurganobl.ru 10"
    },
    {
        "col1": "Курганская область",
        "col2": 12,
        "col3": "Администрация Лебяжьевского муниципального округа Курганской области",
        "col4": "641500, Курганская область, р.п. Лебяжье, ул. Пушкина, д.14, тел. (35237) 9-08-78; 45t01102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 13,
        "col3": "Администрация Макушинского муниципального округа Курганской области",
        "col4": "641600, Курганская область, г. Макушино, ул. Ленина, д.85, тел. (35236) 2-06-42; 45t01202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 14,
        "col3": "Администрация Мишкинского муниципального округа Курганской области",
        "col4": "641040, Курганская область, р.п. Мишкино, ул. Ленина, д.30, тел. (35247)2-28-01; 45t01302@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 15,
        "col3": "Администрация Мокроусовского муниципального округа Курганской области",
        "col4": "641530, Курганская область, с. Мокроусово, ул. Советская, д.31, тел. (35234)9-77-41; admmokr@mail.ru; 45t01402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 16,
        "col3": "Администрация Петуховского муниципального округа Курганской области",
        "col4": "641640, Курганская область, г. Петухово, ул. К. Маркса, 27, тел. (35235) 3-84-98; admpr@bk.ru; 45t01502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 17,
        "col3": "Администрация Половинского муниципального округа Курганской области",
        "col4": "641780, Курганская область, с. Половинное, ул. Победы, д.9, тел.(35238) 9-15-33; 45t01602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 18,
        "col3": "Администрация Притобольного муниципального округа Курганской области",
        "col4": "641400, Курганская область, с. Глядянское, ул.Красноармейская, д.19, (3522) 42-89-80 доб. 224;  45t01702@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 19,
        "col3": "Администрация Сафакулевского муниципального округа Курганской области",
        "col4": "641080, Курганская область, с. Сафакулево, ул. Куйбышева, д.35; тел. (35243) 2-93-71; safakulevo@bk.ru; 45t01802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 20,
        "col3": "Администрация Целинного муниципального округа Курганской области",
        "col4": "641150, Курганская область, с. Целинное, ул. Советская, д.66, тел. (35241)2-10-43;  45t01902@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 21,
        "col3": "Администрация Частоозерского муниципального округа Курганской области ",
        "col4": "641570, Курганская область, с. Частоозерское, ул. Октябрьская, д.126, (3522) 42-84-45; 45t02002@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 22,
        "col3": "Администрация Шадринского муниципального округа Курганской области",
        "col4": "641870, Курганская область, г. Шадринск ул. Р. Люксембург, д.10, тел. (35253) 7-62-43; 45t02102@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 23,
        "col3": "Администрация Шатровского муниципального округа Курганской области",
        "col4": "641960, Курганская область, с. Шатрово, ул. Федосеева, д.53, тел. (35257) 9-13-98; 45t02202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 24,
        "col3": "Администрация Шумихинского муниципального округа Курганской области",
        "col4": "641100, Курганская область, г. Шумиха, ул. Кирова, д.12, тел. (35245)2-11-67; 45t02302@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 25,
        "col3": "Администрация Щучанского муниципального округа Курганской области",
        "col4": "641010 Курганская область, г. Щучье, ул. Победы, 1, тел. (35244) 3-69-67; 45t02402@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 26,
        "col3": "Администрация Юргамышского муниципального округа Курганской области",
        "col4": "641200 Курганская область, р.п. Юргамыш, ул. Ленина, 43, тел.(35248) 9-26-91; 45t02502@kurganobl.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 1,
        "col3": "Администрация Беловского района Курской области",
        "col4": "307910 Курская область, Беловский р-он, сл.Белая, Советская площадь, 1, тел.: 8(47149)2-11-40, e-mail: oop.belaya@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 2,
        "col3": "Администрация Большесолдатского района Курской области ",
        "col4": "305007, г. Курск, ул. Красная площадь, д.6, подъезд 3, каб. 247; тел.: 8(4712) 51-09-89, e-mail: petina6464@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 3,
        "col3": "Отдел опеки и попечительства Администрации Глушковского района Курской области \n",
        "col4": "305000 г. Курск, ул. Ендовищенская 8а, тел.:8(4712)44-62-20, e-mail:opeka-gl.307450@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 4,
        "col3": "Администрация Горшеченского района Курской области \n",
        "col4": "306800, Курская обл., Горшеченский р-н, рп. Горшечное, ул. Кирова, д. 18, тел. 8(47133)2-13-75, e-mail: oszn_gr@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 5,
        "col3": "Управление  образования, опеки и попечительства Администрации Дмитриевского района Курской области\n",
        "col4": "307500 г. Дмитриев, ул. Ленина, д. 79, тел. 8(47150) 2-23-06, e-mail: opeka_46@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 6,
        "col3": "Администрация Железногорского района Курской области \n",
        "col4": "301170, г Железногорск, ул. Ленина, д.52, каб. 124. тел., факс 8 (47148)3-50-48, e-mail: 35048@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 7,
        "col3": "Отдел образования, опеки и попечительства Администрации Золотухинского района Курской области \n",
        "col4": "306020 Золотухинский район, Курская область, Золотухинский район, п. Золотухино, ул. пер. Лесной, д. 5, тел.: 8(47151)2-13-68, e-mail:  opekazolotuhino@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 8,
        "col3": "Муниципальное образование «Касторенский муниципальный район Курской области» \n",
        "col4": "306700, Курская область, Касторенский район, п.Касторное, ул. 50 лет октября, д.6, тел.8(47157)2-19-52, e-mail: otdelpoopeke46@.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 9,
        "col3": "Администрация Конышевского района Курской области \n",
        "col4": "307620, Курская область, п. Конышевка, ул.Ленина, д.19, тел. 8(47156)2-13-55, e-mail:opeka4609@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 10,
        "col3": "Отдел опеки и попечительства управления по образованию, опеки и попечительству администрации Кореневского района \n",
        "col4": "305044, г. Курск, ул. Союзная, 29а,\nтел.: 8(4712) 34-28-98, e-mail: korenevo-opeka@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 11,
        "col3": "Администрация Курского района Курской области ",
        "col4": "305001, г. Курск, ул. Белинского, 21, тел. 8(4712)54-66-09, otdopeki.kurskrn@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 12,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 13,
        "col3": "Администрация Льговского района Курской области \n",
        "col4": "307750, Курская область, г. Льгов, ул. Красная Площадь, д. 4 б, тел.: 8(47140)2-13-31, opeka-lgov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 14,
        "col3": "Администрация Медвенского района Курской области \n",
        "col4": "307030, Курская область, пос.Медвенка, ул.Советская, д.20, тел.: 8(47146)4-19-76, e-mail: medwenka@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 15,
        "col3": "\nАдминистрация Мантуровского района Курской области \n",
        "col4": "307000, Курская область, Мантуровский район, с. Мантурово, ул. Ленина, д.13 тел.: 8(4715) 521286,\ne-mail: opeka@smanturovo.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 16,
        "col3": "Администрация Обоянского района Курской области\n \n",
        "col4": "306230 Курская область, г. Обоянь, ул. Шмидта, д.6, 8(47141)2-22–55, e-mail oboyan-opeka@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 17,
        "col3": "Администрация Октябрьского района Курской области \n",
        "col4": "307200, Курская область,Октябрьский район, п. Прямицыно, ул. Октябрьская, 134, тел.: 8(47142) 2-13-36, 2-13-07,2-11-57, 2-16-31, e-mail:opekaoct@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 18,
        "col3": "Администрация Пристенского района Курской области \n",
        "col4": "306200, Курская область, Пристенский район, п. Пристень, ул. Ленина, дом 5, тел.: 8(47134)2-10-51 факс  e-mail: opekapristen@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 19,
        "col3": "Отдел по опеке и попечительству Администрации Поныровского района Курской области \n\n",
        "col4": "306000, Курская область, Поныровский район, п. Поныри, ул. Ленина, д. 14, \nтел.: 8(47135)2-11-07, \ne-mail: ponyri.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 20,
        "col3": "Администрация Рыльского района Курской области \n",
        "col4": "307370, Курская область, г. Рыльск, ул. К. Либкнехта, д. 23А тел. 8(47152)2-19-98, e-mail: opeka.rylsk@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 21,
        "col3": "Отдел опеки и попечительства Администрации Советского района Курской области )\n",
        "col4": "306600 Курская область, Советский район, п. Кшенский, ул. Пролетарская, д.45, тел.: 8(4712) 2-22-32, факс 8(4712)2-22-32, e-mail: opeka.sovr@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 22,
        "col3": "Администрация Солнцевского района Курской области\n",
        "col4": " 306120, Курская область, Солнцевский район, п. Солнцево, ул. Ленина, д. 44, тел.: 8(47154 2-22-36, e-mail: adm4622@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 23,
        "col3": "Управление образования Администрации Суджанского района Курской области \n",
        "col4": "305004, г. Курск, ул. Садовая, д.31,3-этаж, каб.39, тел.: 8-951-339-92-30, e-mail: sudjanskruo23@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 24,
        "col3": "Отдел по опеке и попечительству Администрации Тимского района Курской области \n",
        "col4": "307060, Курская обл., Тимский район, п. Тим, ул. Кирова 51, тел.: 8(47153)2-37-39, e-mail:opeka.admtim@rkursk.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 25,
        "col3": "Отдел по опеке и попечительству Управления образования Администрации Фатежского района Курской области \n",
        "col4": "307100, Курская область, Фатежский район, г. Фатеж, ул. Урицкого, 45, тел.: 8(47144)2-13-90, e-mail:opekafafezh89@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 26,
        "col3": "Администрация Хомутовского района Курской области \n",
        "col4": "307540, Курская область, п. Хомутовка, ул. Память Ильича, д.85, тел: 8(471-37)2-15-06, e-mail: opeka.hom@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 27,
        "col3": "Отдел по опеке и попечительству Администрации Черемисиновского района Курского района\n",
        "col4": "306440, п. Черемисиново, ул. Советская, д. 4, тел.: 8(47159)2-10-75, e-mail: opeka.tcheremisinovo@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 28,
        "col3": "Администрация Щигровского района Курской области \n",
        "col4": "306530 Курская область, г. Щигры, ул. Октябрьская, д. 35; тел.: 8 (47145) 4-16-48, e-mail: shigri-opeca@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 29,
        "col3": "\nКомитет социальной защиты населения города Курска \n",
        "col4": "305007, г. Курск, ул. Пигорева, д. 2/17, тел.: (4712)70-70-35, e-mail: komsoz@kurskadmin.ru (почта отдела komitetsoc_det@mail.ru)"
    },
    {
        "col1": "Курская область\n",
        "col2": 30,
        "col3": "Администрация города Железногорска \n",
        "col4": "307170 Курская область, г. Железногорск, ул. Ленина, д. 52, тел. 8(47148)7-64-37, e-mail: zhel.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 31,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района Курской области \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, тел.: 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 32,
        "col3": "Администрация города Льгова Курской области\n\n",
        "col4": "307750 Курская область город Льгов, ул. Красная площадь, д.13\nТел. 8(74140)2-30-13, e-mail: shigri-opeca@mail.ru, admlov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 33,
        "col3": "Отдел по опеке и попечительству администрации города Щигры",
        "col4": " 306530, Курская область, г. Щигры, ул. Спортивная,1 8(47145)4-97-27, opeka-shigry@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 1,
        "col3": "администрация Бокситогорского муниципального района Ленинградской области",
        "col4": " адрес: 187650, Ленинградская область, г. Бокситогорск, ул. Социалистическая, д.9, телефон: 8(81366) 211-31,электронная почта: adm@boksitogorsk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 2,
        "col3": "комитет образования администрации Волосовского муниципального района Ленинградской области.",
        "col4": " Адрес: 188410, Ленинградская область, г. Волосово, ул. Краснофлотская, дом 6, телефон: 8(81373)22114, электронная почта:  volosovoopeka@inbox.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 3,
        "col3": "управление по опеке и попечительству администрации Волховского муниципального района Ленинградской области",
        "col4": " адрес: 187406 Ленинградская область, г. Волхов, пр. Державина, д. 60, телефон: 8(81363)72705, электронная почта: opekavolhov@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 4,
        "col3": "комитет по опеке и попечительству администрации Всеволожского муниципального района Ленинградской области",
        "col4": "адрес: 188640, Ленинградская область, г. Всеволожск, Всеволожский проспект д. 12, телефон:  8(81370)20020, 8(81370)31741, электронная почта: vsevopeka@mail.ru, people@vsevreg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 5,
        "col3": "управление опеки и попечительства администрации  муниципального образования \"Выборгский район\" Ленинградской области",
        "col4": "адрес:188800, Ленинградская область, \nг. Выборг, ул. Красноармейская д. 16, телефон: 8(81378)26353, \n 8(81378)20078, электронная почта: opeka.vbg@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 6,
        "col3": "администрация Гатчинского муниципального округа Ленинградской области",
        "col4": " адрес: 188300, Ленинградская область, г. Гатчина, ул. Карла Маркса, д.44, телефон: 8(81371)93100; 8 (81371) 95309, электронная почта: info@gmolo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 7,
        "col3": "отдел опеки и попечительства администрации МО Кингисеппский муниципальный район» Ленинградская область",
        "col4": " адрес: 188480, Ленинградская область, г. Кингисепп, проспект Карла Маркса, д.2а, телефон: 8(81375)4-88-40, электронная почта: adm@kingisepplo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 8,
        "col3": "администрация Киришского муниципального района Ленинградской области",
        "col4": " адрес: 187110, Ленинградская область, г. Кириши, улица Советская, дом 20, телефон: 8(81368) 609-00, электронная почта: admkir@admkir.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 9,
        "col3": "администрация Кировского муниципального района Ленинградской области",
        "col4": " адрес: 187342, Ленинградская область, Кировский район, г. Кировск, ул. Новая, д. 1, телефон: 8(813-62) 28-196, электронная почта: adm_kmr@kirovsk-reg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 10,
        "col3": "сектор опеки и попечительства отдела образования Администрации Лодейнопольского муниципального района Ленинградской области",
        "col4": "адрес: 187700, 187700, Ленинградская область, г. Лодейное Поле, пр. Ленина, д.16, телефон: 8(81364)39511, 8(81364)39822, lodpole-opeka@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 11,
        "col3": "отдел по опеке и попечительству администрации Ломоносовского муниципального района Ленинградской области",
        "col4": " адрес: 198412, г. Ломоносов, ул. Владимирская, дом 19/15, телефон: 8(81242)30030, электронная почта: lmn-reg@lomonosovlo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 12,
        "col3": "администрация Лужского муниципального района Ленинградской области",
        "col4": " адрес: 188230, Ленинградская область, г. Луга, пр. Кирова, д. 73, телефон: 8(81372) 2-23-06, Электронная почта: admin@adm.luga.ru , admluga@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 13,
        "col3": "администрация Подпорожского муниципального района Ленинградской области",
        "col4": " адрес: 187780, Ленинградская область, г. Подпорожье, пр. Ленина, д. 3, телефон: 8(81365)59003, электронная почта: reception@podadm.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 14,
        "col3": "администрация Приозерского муниципального района Ленинградской области",
        "col4": " адрес: 188760, Ленинградская область,  г. Приозерск, ул. Ленина, д.10, телефон: 8(81379)36973, Электронная почта: info@admpriozersk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 15,
        "col3": "администрация Сланцевского муниципального района Ленинградской области",
        "col4": " адрес: 188560, Ленинградская область, г. Сланцы, пер. Почтовый, д. 3, телефон: 8(81374)23273, Электронная почта: slanmo@slanmo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 16,
        "col3": "администрация Сосновоборского городского округа Ленинградской области",
        "col4": " адрес: 188540, Ленинградская область, г. Сосновый Бор, ул. Ленинградская, 46, телефон: 8(81369)26222, электронная почта: admsb@sbor.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 17,
        "col3": "комитет социальной защиты населения администрации Тихвинского района  (отдел опеки и попечительства)",
        "col4": " адрес: 187553, Ленинградская область, город Тихвин, 1 микрорайон, дом 2, телефон:  8(81367) 56250, 8(81367) 70294, электронная почта opeka.tikhvin@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 18,
        "col3": "комитет образования администрации Тосненского муниципального района Ленинградской области",
        "col4": "адрес: 187000, Ленинградская область,г. Тосно, ул. Советская, д. 10а, телефон: 8(81361)28305, электронная почта Opeka1960@yandex.ru"
    },
    {
        "col1": "Липецкая область",
        "col2": 1,
        "col3": "Министерство социальной политики Липецкой области",
        "col4": "398050, г.Липецк, \nул. Плеханова, д. 33\nsozan@admlr.lipetsk.ru\n+7 (4742) 25 25 25"
    },
    {
        "col1": "Магаданская область",
        "col2": 1,
        "col3": "Отдел опеки и попечительства департамента образования мэрии города Магадана",
        "col4": "г. Магадан, \nул. Горького, д. 19А\nopeka@magadangorod.ru\n62-06-62\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 2,
        "col3": "Администрация Ольского муниципального округа Магаданской области",
        "col4": "685910, Магаданская область. Ольский район, поселок Ола, площадь Ленина дом № 4, priemnaya@ola49.ru, 8(41341)25567"
    },
    {
        "col1": "Магаданская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства\nадминистрации\nОмсукчанского\nмуниципального округа",
        "col4": "6868410, п. Омсукчан, ул. Ленина, д. 19, info@omsukchan-adm.ru, 8(41346)91681"
    },
    {
        "col1": "Магаданская область",
        "col2": 4,
        "col3": "Администрация Среднеканского муниципального округа Магаданской области (Управление экономики и развития)",
        "col4": "Магаданская область, Среднеканский район, поселок Сеймчан, ул. Ленина, дом 9. amosred@mail.ru    8-413-47-9-42-59 (приемная), 8-413-47-9-44-46 (экономика)"
    },
    {
        "col1": "Магаданская область",
        "col2": 5,
        "col3": "Отдел  опеки и попечительства управления образования\nадминистрации\nСеверо-Эвенского муниципального округа",
        "col4": "686430, Магаданская область, п. Эвенск, ул. Курилова, д. 16, 8 (413-48) 2-23-74, uoobrazevensk@mail.ru"
    },
    {
        "col1": "Магаданская область",
        "col2": 6,
        "col3": "Администрация Сусуманского муниципального округа Магаданской области",
        "col4": "686314, Магаданская область. Сусуманский район, г. Сусуман, д. 17 ssmadm@mail.ru, 8(41345)22025"
    },
    {
        "col1": "Магаданская область",
        "col2": 7,
        "col3": "Управление образования администрации Тенькинского муниципального округа Магаданской области",
        "col4": "ул. Мира, д. 11, п. Усть-Омчуг, Тенькинский район, Магаданская область, 686050,\nтел. 8(41344)3-04-44, \n E-mail: Obrazovanietenka@49gov.ru\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 8,
        "col3": "Администрации Хасынского муниципального округа Магаданской области \nОтдел опеки и попечительства\n",
        "col4": "686110 Магаданская область, Хасынский район, п. Палатка, ул. Ленина, д.76 -mail: priem_hasyn@49gov.ru\n84134292774\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 9,
        "col3": "Отдел опеки и попечительства   управления образования администрации Ягоднинского муниципального округа Магаданской области",
        "col4": "686230, поселок Ягодное, Ягоднинский район, Магаданская область, ул. Школьная, д. 9 \nе-mail: yagodnoeopeka@mail.ru \nтел. (8 413 43) 2-24-57\n"
    },
    {
        "col1": "Московская область",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Мурманская область",
        "col2": 1,
        "col3": "Администрация города Апатиты",
        "col4": "пл. Ленина., д. 1, г. Апатиты, Мурманская область, 184209, (8 81555)  6 02 36, opeka-deti@apatity-city.ru  "
    },
    {
        "col1": "Мурманская область",
        "col2": 2,
        "col3": "Администрация ЗАТО Александровск",
        "col4": "ул. Флотская, д. 9, ЗАТО Александровск, Мурманская область, 184682, (881539) 5 23 22, ZarenkovaLV@zato-a.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 3,
        "col3": "Администрация ЗАТО пос. Видяево",
        "col4": "ул. Центральная, д. 8, ЗАТО Видяево, Мурманская область, 184372, (881553) 5 66 74, opeka@zatovid.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 4,
        "col3": "Администрация ЗАТО город Заозерск",
        "col4": "ул. Генерала Чумаченко, д. 4,                   г. Заозерск, Мурманская область, 184310,\nopekazaozersk@mail.ru,\n8(81556) 3 15 12"
    },
    {
        "col1": "Мурманская область",
        "col2": 5,
        "col3": "Администрация Кандалакшского района",
        "col4": "ул. Первомайская, д. 34, г. Кандалакша, Мурманская область, 184040, (8 815 33) 9 24 00, 9 30 11 \nopeka-kanda@yandex.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 6,
        "col3": "Администрация города Кировска",
        "col4": "пр. Ленина, д. 16, г. Кировск, Мурманская область, 184250, (881531) 5 52 75, gnevysheva.nv@gov.kirovsk.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 7,
        "col3": "Администрация Ковдорского муниципального округа",
        "col4": "пл. Ленина., д. 1, г. Ковдор, Мурманская область, 184141, (8 81535) 5 02 42 доб. 227, i.iosifova@kovadm.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 8,
        "col3": "Администрация Кольского района",
        "col4": "Советский пр., д. 50, г. Кола, Мурманская область, 184381, (81553) 3 61 82, opeka_deti@akolr.gov-murman.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 9,
        "col3": "Администрация Ловозерского района",
        "col4": "ул. Советская, д. 10, с. Ловозеро, Мурманская область, 184592, (8 815 38) 40 111, opeca-lov@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 10,
        "col3": "Администрация города Мончегорска",
        "col4": "ул. Железнодорожников, д. 6, г. Мончегорск, Мурманская область, 184511,  (881536) 3 18 90, opd@edumonch.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 11,
        "col3": "Администрация города Мурманска",
        "col4": "ул. Комсомольская, д. 10, г. Мурманск, 183036, (8 8152)  45-06-24, 45-61-71, kio@citymurmansk.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 12,
        "col3": "Администрация муниципального округа город Оленегорск с подведомственной территорией",
        "col4": "ул. Мира, д. 38, г. Оленегорск, Мурманская область, 184530, (8 815 52) 51 000, opeka-olen@admol.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 13,
        "col3": "Администрации ЗАТО г. Островной",
        "col4": "пл. Жертв Интервенции, д. 1,                     г. Островной, Мурманская обл. 184640,  (881558) 5 00 12, admzato@zato-ostrov.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 14,
        "col3": "Администрация Печенгского муниципального округа",
        "col4": "ул. Победы, д. 1, п.г.т. Никель, Печенгский район, Мурманская область, 184421, \nтелефон (81554) 5-19-40, 5-05-72;  obrpechenga@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 15,
        "col3": "Администрация города Полярные Зори",
        "col4": "ул. Ломоносова, д. 4, г. Полярные Зори, Мурманская область, 184230, (881532) 7 12 24, opeka@pz-city.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 16,
        "col3": "Администрация ЗАТО г. Североморск ",
        "col4": "пр. Советский, д. 50, г. Кола, Мурманская область, 184381, (8 81553)  3 61 82, 3 34 29, opeka-deti@akolr.gov-murman.ru   "
    },
    {
        "col1": "Мурманская область",
        "col2": 17,
        "col3": "Администрация Терского района",
        "col4": "пгт. Умба Терского района, Мурманская область, 184703, (8 81555)  6-71-12, ter.obrazovanie@mail.ru "
    },
    {
        "col1": "Ненецкий автономный округ",
        "col2": 1,
        "col3": "Департамент здравоохранения, труда и социальной защиты населения Ненецкого автономного округа",
        "col4": "ул. Смидовича, д.25, г. Нарьян-Мар, Ненецкий автономный округ, 166000; e-mail: m, тел. 8(81853) 2-13-68edsoc@adm-nao.ru"
    },
    {
        "col1": "Нижегородская область",
        "col2": 1,
        "col3": "Администрация Ардатовского муниципального округа",
        "col4": "Администрация Ардатовского муниципального округа  \nул. Ленина, д.28, р.п. Ардатов, 607130 arono@mts-nn.ru        8(83179)5-02-56        \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 2,
        "col3": "Администрация Балахнинского муниципального округа",
        "col4": "Администрация Балахнинского муниципального округа ул. Лесопильная, дом 24, г. Балахна, 606403, opeka@adm.bal.nnov.ru 8(83144)68299 "
    },
    {
        "col1": "Нижегородская область",
        "col2": 3,
        "col3": "Администрация Богородского муниципального округа ",
        "col4": "Администрация Богородского муниципального округа Нижегородской области ул. Ленина, д. 206, г. Богородск, 607600, opekabog@yandex.ru 8(83170)23871        "
    },
    {
        "col1": "Нижегородская область",
        "col2": 4,
        "col3": "Администрация Большеболдинского муниципального округа ",
        "col4": "Администрация Большеболдинского муниципального округа Нижегородской области ул. Пушкинская, д.144, с. Б.Болдино, 607940, opekaboldino@yandex.ru 8(83138)2-26-21"
    },
    {
        "col1": "Нижегородская область",
        "col2": 5,
        "col3": "Администрация Большемурашкинского муниципального округа",
        "col4": "Администрация Большемурашкинского муниципального округа      ул. Свободы, д. 86, р.п. Б.Мурашкино, 606360 detimolkdn@mail.ru 8(83167)5-11-32"
    },
    {
        "col1": "Нижегородская область",
        "col2": 6,
        "col3": "Администрация городского округа г. Бор",
        "col4": "Администрация городского округа г. Бор ул. Ленина, д. 97, г. Бор, Нижегородская область, 606440, opekabor@yandex.ru 8(831)5991478"
    },
    {
        "col1": "Нижегородская область",
        "col2": 7,
        "col3": "Администрация Бутурлинского муниципального округа",
        "col4": "Администрация Бутурлинского муниципального округа ул. Ленина, д. 106, п.г.т. Бутурлино, 607440, opeka-buturlino@mail.ru 8(831)7251813"
    },
    {
        "col1": "Нижегородская область",
        "col2": 8,
        "col3": "Администрация\nВадского муниципального округа",
        "col4": "Администрация\nВадского муниципального округа ул. 1 Мая, д. 41, с. Вад, 606380, opeka-vad@yandex.ru 8(831)4043042\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 9,
        "col3": "Администрация Варнавинского муниципального округа ",
        "col4": "Администрация Варнавинского муниципального округа    пл. Советская, д.2, р.п. Варнавино, 606760, ruo_varnavino@mail.ru        8 (831) 583 52 44      "
    },
    {
        "col1": "Нижегородская область",
        "col2": 10,
        "col3": "Администрации Вачского муниципального округа",
        "col4": "Администрации Вачского муниципального округа ул.Советская, дом 26б, р.п. Вача, 606150, vachaopeka52@yandex.ru 8(831)7361977"
    },
    {
        "col1": "Нижегородская область",
        "col2": 11,
        "col3": "Администрация Ветлужского муниципального округа ",
        "col4": "Администрация Ветлужского муниципального округа Нижегородской области ул.Ленина, дом 42, г.Ветлуга, 606860, vetl-opeka@mail.ru 8(83150)235-04"
    },
    {
        "col1": "Нижегородская область",
        "col2": 12,
        "col3": "Администрация Вознесенского муниципального округа",
        "col4": "Администрация Вознесенского муниципального округа ул. Советская, д. 33, р.п. Вознесенское, 607340, paramonova-opeka@yandex.ru 8(83178)631-88 "
    },
    {
        "col1": "Нижегородская область",
        "col2": 13,
        "col3": "Администрация Володарского муниципального округа",
        "col4": "Администрация Володарского муниципального округа \nул. Клубная, дом 4, г. Володарск, 606070, opeka-volodarsk@mail.ru 8(831)3649202\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 14,
        "col3": "Администрация городского округа Воротынский",
        "col4": "Администрация городского округа Воротынский пл. Советская, дом 6, р.п. Воротынец, 606260 opeka@adm.vrt.nnov.ru 8(831)6420151"
    },
    {
        "col1": "Нижегородская область",
        "col2": 15,
        "col3": "Администрация Воскресенского муниципального округа",
        "col4": "Администрация Воскресенского муниципального округа \nул. Ленина, д.105, р.п. Воскресенское, 606730 opeka-vosk@yandex.ru 8(83163)922-85\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 16,
        "col3": "Администрация городского округа г. Выкса ",
        "col4": "Администрация городского округа г. Выкса \nг.Выкса, Красная площадь, дом 1, 607060, uo@vyksa-okrug.ru 8(831)7734244 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 17,
        "col3": "Администрация Гагинского муниципального округа ",
        "col4": "Администрация Гагинского муниципального округа \nул. Ленина, д. 44, с. Гагино, 607870, opekagag@yandex.ru 8(831)9521472\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 18,
        "col3": "Администрация Городецкого муниципального округа",
        "col4": "Администрация Городецкого муниципального округа\nпл. Пролетарская, д. 30, г. Городец, 606500, gorodec_opeka@mail.ru 8(8316) 9-1766  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 19,
        "col3": "Администрация Дальнеконстантиновского муниципального округа",
        "col4": "Администрация Дальнеконстантиновского муниципального округа\nул. Советская, д. 103, р.п. Дальнее Константиново, 606310, opekadk@yandex.ru \n8(831)6852218"
    },
    {
        "col1": "Нижегородская область",
        "col2": 20,
        "col3": "Администрация Дивеевского муниципального округа ",
        "col4": "Администрация Дивеевского муниципального округа \nул.Октябрьская, д.10, с.Дивеево, 607320, roo.adm.diveevo@mail.ru 8(83134)45460\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 21,
        "col3": "Администрация Княгининского муниципального округа",
        "col4": "Администрация Княгининского муниципального округа\nул.Свободы, д.45, г. Княгинино, 606340, rukavisnikova.tatyana@mail.ru 8(831)6641046\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 22,
        "col3": "Администрация Ковернинского муниципального округа",
        "col4": "Администрация Ковернинского муниципального округа\nул. Карла Маркса, д. 4, р.п. Ковернино, 606570, roo@adm.kvr.nnov.ru 8(831)5722568\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 23,
        "col3": "Администрация Краснобаковского муниципального округа",
        "col4": "Администрация Краснобаковского муниципального округа \nул. Свободы, д. 104, р.п. Красные Баки, 606710, opeka-krbaki@mailru 8(831)5622531\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 24,
        "col3": "Администрация Краснооктябрьского муниципального округа",
        "col4": "Администрация Краснооктябрьского муниципального округа \nул. Кооперативная, дом 39, село Уразовка, 607530, obkroktyabr@mail.ru  8(831) 265 38 13 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 25,
        "col3": "Администрация Кстовского района",
        "col4": "Администрация Кстовского района\nпл. Ленина, дом 4, г. Кстово, 607650, opeka.kctadm@mail.ru 8(831)4539214\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 26,
        "col3": "Администрация городского округа г. Кулебаки",
        "col4": "Администрация городского округа г. Кулебаки\nул. Воровского, д. 43а, г. Кулебаки, 607010, opeka_kulebaki@bk.ru 8(831)7653062\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 27,
        "col3": "Администрация Лукояновского муниципального округа",
        "col4": "Администрация Лукояновского муниципального округа \nул. Коммуны, д. 38, г. Лукоянов, Нижегородской области, 607800, luk-opeka@yandex.ru 8(831)9642153\n\n\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 28,
        "col3": "Администрация Лысковского муниципального округа ",
        "col4": "Администрация Лысковского муниципального округа \nул. Ленина, д. 23, г. Лысково, 606210, lysopeka@mail.ru 8(831)495-38-83   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 29,
        "col3": "Администрация городского округа Навашинский",
        "col4": "Администрация городского округа Навашинский  \nул. 1 Мая, д. 6, г. Навашино, 607102, opekanavashino@yandex.ru 8(831)7557367\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 30,
        "col3": "Администрация Павловского муниципального округа ",
        "col4": "Администрация Павловского муниципального округа \nул.Ленина, д.27, г.Павлово, 606100, detiopekapavlovo@mail.ru 8(831)7121595\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 31,
        "col3": "Администрация городского округа г. Первомайск",
        "col4": "Администрация городского округа г. Первомайск\nул. Ульянова, дом 2, г. Первомайск, 607760, perv_rono@mail.ru 8(831)3921472\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 32,
        "col3": "Администрация городского округа Перевозский",
        "col4": "Администрация городского округа Перевозский\nпр. Советский, д. 8, г.Перевоз, 607400, opekapvz@yandex.ru 8(831)485-28-41   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 33,
        "col3": "Администрация Пильнинского муниципального округа ",
        "col4": "Администрация Пильнинского муниципального округа \nул. Урицкого, д. 16, р.п. Пильна, 607490, uomps@mail.ru 8(831)9251539  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 34,
        "col3": "Администрации Починковского муниципального округа",
        "col4": "Администрации Починковского муниципального округа \nпл.Ленина, д.9, с.Починки, 607910, karpaeva.lena@mail.ru 8(831)9750043\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 35,
        "col3": "Администрация городского округа Семеновский",
        "col4": "Администрация городского округа Семеновский\nул.1-е Мая, д.1, г. Семенов, 606650, kdn@semenov.nnov.ru 8(83162)5-27-97\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 36,
        "col3": "Администрация\nСергачского муниципального округа",
        "col4": "Администрация\nСергачского муниципального округа \nул. Советская, дом 30, г. Сергач, 607510, opeka-grishina@mail.ru 8(831)9152911\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 37,
        "col3": "Администрация Сеченовского муниципального округа ",
        "col4": "Администрация Сеченовского муниципального округа \nпл. Советская, д. 4, с. Сеченово, 607580,  komolova-anna@mail.ru 8(831)93-5-24-08\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 38,
        "col3": "Администрация городского округа Сокольский",
        "col4": "Администрация городского округа Сокольский\nул. Набережная, д. 9, п. Сокольское, 606670, detstvo.sokolskoe@mail.ru 8(831)372-07-73 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 39,
        "col3": "Администрация Сосновского муниципального округа",
        "col4": "Администрация Сосновского муниципального округа \nул. Ленина, д. 27, р.п. Сосновское, д.27, 606170, opekasosn@yandex.ru 8(83174) 2-63-27\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 40,
        "col3": "Администрация Спасского муниципального округа",
        "col4": "Администрация Спасского муниципального округа\nпл. Революции, д. 71, с. Спасское, 606280, spasopeka@yandex.ru 8(831)652-60-15\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 41,
        "col3": "Администрация Тонкинского муниципального округа ",
        "col4": "Администрация Тонкинского муниципального округа  \nул. Ленина, д.1, р.п. Тонкино, 606970, roo_tonkino2002@mail.ru   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 42,
        "col3": "Администрация Тоншаевского муниципального округа",
        "col4": "Администрация Тоншаевского муниципального округа \nул. Свердлова, дом 2а, р.п. Тоншаево, 606950, ton_opeka@rambler.ru 8(831)512-22-95\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 43,
        "col3": "Администрация Уренского муниципального округа",
        "col4": "Администрация Уренского муниципального округа \nул. Советская, д. 26, г. Урень, 606800, uren.ruo@mail.ru    8(831)542-11-63\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 44,
        "col3": "Администрации городского округа г. Чкаловск",
        "col4": "Администрации городского округа             г. Чкаловск\nпл. Комсомольская, д.2,    г. Чкаловск, 606540,  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 45,
        "col3": "Администрация\nШарангского муниципального округа",
        "col4": "Администрация\nШарангского муниципального округа \nул. Свободы, дом 2, пгт. Шаранга, 606840, roo_shar2003@mail.ru 8(831)5521763           \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 46,
        "col3": "Администрация\nШатковского муниципального округа",
        "col4": "Администрация\nШатковского муниципального округа \nул. Федеративная, д.4, р.п. Шатки, 607700, opeka0803@mail.ru 8(831)904-31-34\n \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 47,
        "col3": "Администрация городского округа г. Шахунья Нижегородской области ",
        "col4": "Администрация городского округа г. Шахунья Нижегородской области \nпл. Советская, д. 1, г. Шахунья, 606910, opekashah.52@mail.ru 8(831)5226655 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 48,
        "col3": "Администрация городского округа г. Арзамас",
        "col4": "Администрация городского округа г. Арзамас \nул. Советская, дом 10, г. Арзамас 607220, arzopeka@rambler.ru 8(831)4775738 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 49,
        "col3": "Администрация г. Дзержинска",
        "col4": "Администрация                  г. Дзержинска\nпл. Ленина, д. 5, г. Дзержинск, 606032, opeka-dz@mail.ru 8(831)3397212\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 50,
        "col3": "Администрации г. Саров ",
        "col4": "администрации г. Саров \nул. Гагарина, дом 6, г. Саров, 607188,nud@adm-sarov.ru 8(831)3099124\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 51,
        "col3": "Департамент образования администрации города Нижнего Новгорода (Отдел опеки и попечительства)",
        "col4": "Департамент образования администрации города Нижнего Новгорода (Отдел опеки и попечительства)\nул. Большая Покровская, д.15, г. Н.Новгород, 603005, muhanova@admgor.nnov.ru8(831)435-69-83  \n"
    },
    {
        "col1": "Новгородская область",
        "col2": 1,
        "col3": "Комитет образования Администрации Батецкого муниципального округа",
        "col4": "Новгородская область, п.Батецкий, ул.Советская, д.39а, 175000; адрес электронной почты: gkomobr@mail.ru; 8(81661)22401 (доб.222)"
    },
    {
        "col1": "Новгородская область",
        "col2": 2,
        "col3": " Отдел по жилищным вопросам комитета жилищно-коммунального, дорожного хозяйства, строительства, транспорта и охраны окружающей среды Администрации Боровичского муниципального района",
        "col4": "Новгородская область, г.Боровичи, ул.Коммунарная, д.48, 174411; адрес электронной почты:jil@boradmin.ru; 8(81664)91213"
    },
    {
        "col1": "Новгородская область",
        "col2": 3,
        "col3": "Комитет образования Администрации Валдайского муниципального района",
        "col4": " Новгородская область, г.Валдай, пр.Комсомольский, д.19/21, 175400, адрес электронной почты: valdobr20@mail.ru;  8(81666)23654"
    },
    {
        "col1": "Новгородская область",
        "col2": 4,
        "col3": "Администрация Волотовского муниципального округа",
        "col4": "Новгородская область, п.Волот, ул.Комсомольская, д.38, 175100; адрес электронной почты: adm.volot@mail.ru; 8(81662)61325"
    },
    {
        "col1": "Новгородская область",
        "col2": 5,
        "col3": "Администрация  Демянского муниципального округа",
        "col4": "Новгородская область, п.Демянск, ул.Ленина, д.7, 175310, адрес электронной почты:priemnaya@dem-admin.ru; 8(81651)44012 (доб.6400)"
    },
    {
        "col1": "Новгородская область",
        "col2": 6,
        "col3": "Комитет образования Администрации Крестецкого муниципального округа",
        "col4": "Новгородская область, п.Крестцы, Советская площадь, д.1, 175460; адрес электронной почты: obraz@adm-krestcy.ru; 8(81659)54654"
    },
    {
        "col1": "Новгородская область",
        "col2": 7,
        "col3": "Администрация Любытинского муниципального района",
        "col4": "Новгородская область, п.Любытино, ул.Советов, д.29, 174760; адрес электронной почты:opeka.lyubytino@yandex.ru; 8(81668)61359"
    },
    {
        "col1": "Новгородская область",
        "col2": 8,
        "col3": "Администрация Маловишерского муниципального района",
        "col4": " Новгородская область, г.Малая Вишера, ул.Володарского, д.14,174260; адрес электронной почты: o.o.m.r@mail.ru; 8(81660)31934"
    },
    {
        "col1": "Новгородская область",
        "col2": 9,
        "col3": "Администрация Маревского муниципального округа",
        "col4": "Новгородская область, с. Марёво, ул. Советов, д. 27, адрес электронной почты: marevoobr@yandex.ru; 8(81663)21450 (доб.6843)"
    },
    {
        "col1": "Новгородская область",
        "col2": 10,
        "col3": "Комитет образования и культуры Администрации Мошенского муниципального округа",
        "col4": "Новгородская область, Мошенской муниципальный округ, с. Мошенское, ул.Советская, д.5; адрес электронной почты:mosh-adm@yandex.ru;8(81653)61146"
    },
    {
        "col1": "Новгородская область",
        "col2": 11,
        "col3": "Комитет образовани Администрации Окуловского муниципального района",
        "col4": "Новгородская область, г.Окуловка, ул.Кирова, д.9, 174350; адрес электронной почты: komobr@okuladm.ru; 8(81657)21272; 8(81657)22604"
    },
    {
        "col1": "Новгородская область",
        "col2": 12,
        "col3": "Администрация Новгородского муниципального района (комитет по управлению муниципальным имуществом Администрации Новгородского муниципального района)",
        "col4": "г.Великий Новгород, ул.Большая Московская, д.60,173004, адрес электронной почты:AdmNovRay@yandex.ru, 8(8162)943612"
    },
    {
        "col1": "Новгородская область",
        "col2": 13,
        "col3": "Администрация Парфинского муниципального района",
        "col4": "Новгородская область, Парфинский муниципальный  район, п.Парфино, ул.К.Маркса,д.60, 175130; адрес электронной почты:parvrono@yandex.ru; 8(81650)6-30-42"
    },
    {
        "col1": "Новгородская область",
        "col2": 14,
        "col3": "Администрация Пестовского муниципального района",
        "col4": "Новгородская область, Пестовский муниципальный округ, г.Пестово, ул.Советская, д.10, 174510; адрес электронной почты: opeka.pestovo@yandex.ru; 8(81669)51634"
    },
    {
        "col1": "Новгородская область",
        "col2": 15,
        "col3": "Администрация Поддорского муниципального района",
        "col4": "Новгородская область, Поддорский муниципальный район, с.Поддорье, ул.Полевая, д.17, 175260; адрес электронной почты:obr@admpoddore.ru, 8(81658)71692"
    },
    {
        "col1": "Новгородская область",
        "col2": 16,
        "col3": "Администрация Солецкого муниципального района",
        "col4": "Новгородская область, г.Сольцы, пл.Победы, д.3, 175040; адрес электронной почты: soleco@adminsoltcy.ru; 8(81655)31269"
    },
    {
        "col1": "Новгородская область",
        "col2": 17,
        "col3": "Администрация Старорусского муниципального района",
        "col4": "Новгородская область, г.Старая Русса, ул.Советская Набережная, д.1,175202; адрес электронной почты: pochta@admrussa.ru; 8(81652)22335"
    },
    {
        "col1": "Новгородская область",
        "col2": 18,
        "col3": "Администрация Хвойнинского муниципального округа",
        "col4": "Новгородская область, Хвойнинский муниципальный округ, п.Хвойная, ул.Советская, д.4, 174580; адрес электронной почты: ko5317@yandex.ru, 8(81667)50395"
    },
    {
        "col1": "Новгородская область",
        "col2": 19,
        "col3": "Отдел образования Администрации Холмского муниципального округа",
        "col4": "Новгородская область, Холмский муниципальный округ, г.Холм, пл.Победы, д.4, к.3, 175270; адрес электронной почты:opeka53cholm2025@mail.ru? 8(81654)59162"
    },
    {
        "col1": "Новгородская область",
        "col2": 20,
        "col3": "Администрация Чудовского муниципального района",
        "col4": "Новгородская область, г.Чудово, ул.Некрасова, д.24а, 174210, адрес электронной почты: komobr@adminchudovo.ru, 8(81665)54858"
    },
    {
        "col1": "Новгородская область",
        "col2": 21,
        "col3": "Отдел социальной защиты Шимского муниципального района ГОКУ \"Центр по организации социального обслуживания и предоставления социальных выплат\"",
        "col4": "Новгородская область, Шимский муниципальный район, п.Шимск, ул.Новгородская, д.21, 174150, адрес электронной почты: shimsk_ksz@mail.ru,8(81656)54246"
    },
    {
        "col1": "Новгородская область",
        "col2": 22,
        "col3": "Администрация Великого Новгорода ( управление по жилищным вопросам и социальной поддержке граждан Администрации Великого Новгорода)",
        "col4": "Новгородская область, г.Великий Новгород, ул.Стратилатовская,д.3; адрес электронной почты: shse@adm.nov.ru, 8(8162)778239"
    },
    {
        "col1": "Новосибирская область",
        "col2": 1,
        "col3": "администрация Баганского района Новосибирской области отдел опеки и попечительства ",
        "col4": "Новосибирская область, Баганский район, с. Баган ул.М. Горького д.21                                   8-383-53-22-383"
    },
    {
        "col1": "Новосибирская область",
        "col2": 2,
        "col3": "администрация Барабинского района Новосибирской области",
        "col4": "632334, Новосибирская область, г. Барабинск, ул. Ульяновская, 66; эл. почта: glv_admbar@nso.ru; тел.:  8-383-61-258-08"
    },
    {
        "col1": "Новосибирская область",
        "col2": 3,
        "col3": "администрация Болотнинского района Новосибирской области",
        "col4": "633340, Новосибирская область, Болотнинский район, город Болотное, улица Советская, 9, тел. 8-383-49-22-730, 8-383-49-22-136, bolotnoe-adm@yandex.ru, maer_en@nso.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 4,
        "col3": "Администрация Венгеровского района Новосибирской области",
        "col4": "632241, Новосибирская область, Венгеровский район, с.Венгерово, ул.Ленина, д.68/       pradmvengr@yandex.ru/           (383 69) 21-592"
    },
    {
        "col1": "Новосибирская область",
        "col2": 5,
        "col3": "Администрация Доволенского района Новосибирской  области",
        "col4": "383-54-20-508, opeka-dovol@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 6,
        "col3": "администрация Здвинского района Новосибирской области",
        "col4": "632951, Новосибирская область, Здвинский район, с. Здвинск, ул. Мира, д.13, admn-zdv@yandex.ru,    8 383 63 21 278"
    },
    {
        "col1": "Новосибирская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства администрации Искитимского района Новосибимрской области",
        "col4": "633209, Новосибирская область, г. Искитим, ул. Пушкина, д. 57а, эл. почта : opeka_29@mail.ru? тел. 8 (38343) 4-22-60"
    },
    {
        "col1": "Новосибирская область",
        "col2": 8,
        "col3": "Администрация Карасукского муниципального округа Новосибирской области",
        "col4": "632868, НСО, г. Карасук, ул. Октябрьская, дом 39, E-mail: radm-karasuk@nso.ru. Тел. 8(383)55 33-135, 8(383)55 32-256"
    },
    {
        "col1": "Новосибирская область",
        "col2": 9,
        "col3": "администрация Каргатского района Новосибирской области",
        "col4": "632402 Новосибирская обьласть, Каргатский район,город Каргат, улица Советская, дом 122 тел.83836523326, электронная почта - anyusheva_mv@nso.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 10,
        "col3": "Администрация Колыванского района Новосибирской области ",
        "col4": "633161, Новосибирская область, Колыванский район, р.п. Колывань, ул. Ленина, д. 79, тел. 8 383 52 51 284,        эл. почта koluvan-adm@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 11,
        "col3": "администрация Коченевского районаНовосибирской области",
        "col4": "632640, Новосибирская область, р.п. Коченево,          ул. Октябрьская, 43/kochadm@nso.ru/            8(383) 5125406"
    },
    {
        "col1": "Новосибирская область",
        "col2": 12,
        "col3": "Администрация Кочковского района Новосибирской области",
        "col4": "632491, Новосибирская область, Кочковский район, с. Кочки, ул. Революционная, 11, admkck@mail.ru, kochkiopeka@mail.ru, 8(383)56-22-300"
    },
    {
        "col1": "Новосибирская область",
        "col2": 13,
        "col3": "администрация Краснозерского района Новосибирской области",
        "col4": "Новосибирская область, р.п.Краснозерское, ул.Чкалова 5.632902. 83835742398"
    },
    {
        "col1": "Новосибирская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Куйбышевского муниципального района Новосибирской области",
        "col4": "632380,г. Куйбышев, ул. Куйбышева, 12             Тел.8(383 62)51-462              e-mai:      kainsk_opeka@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 15,
        "col3": "Администрация Купинского района Новосибирской области",
        "col4": "632735, Новосибирская область, Купинский район, г.Купино, ул. Железнодорожная, 42-а ( отдел опеки и попечительства) Тел: 8-383-58-20-997; opekakup@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства  администрации Кыштовского района Новосибирской области",
        "col4": "632270 ,Новосибирская область,Кыштовский район,с.Кыштовка,ул.Ленина,д.38\\opeka-kyshtovka@yandex.ru\\83837121 174"
    },
    {
        "col1": "Новосибирская область",
        "col2": 17,
        "col3": "администрация Маслянинского муниципального округа Новосибирской области",
        "col4": "633564, Новосибирская область, Маслянинский район, р.п. Маслянино, улица Коммунистическая, дом 1а,  8-383-47-21-418   admmsl@yandex.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 18,
        "col3": "Администрация Мошковского района Новосибирской области",
        "col4": "633131, Новосибирская область, Мошковский район, р.п. Мошково, ул. Советская д. 9                                                                тел. 838322900, электронная почта: oopadm@rambler.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 19,
        "col3": "Администрация Новосибирского района Новосибирской области",
        "col4": "г.Новосибирск, ул.Фабричная, д.55, 6 этаж, оф.602, 20opeka13@mail.ru, 373-45-87"
    },
    {
        "col1": "Новосибирская область",
        "col2": 20,
        "col3": "Администрация Ордынского района Новосибирской области",
        "col4": "633261, Новосибирская область, Ордынский район, р.п. Ордынское, пр. Революции, 17 /ordopeka@mail.ru/8-383-59-23-673"
    },
    {
        "col1": "Новосибирская область",
        "col2": 21,
        "col3": "Администрация Северного района Новосибирской области",
        "col4": "632080, Новосибирская обл., Северный р-н, с. Северное, ул Ленина, д.14 тел: 8(38360)21818 sevadmn@yandex.ru  "
    },
    {
        "col1": "Новосибирская область",
        "col2": 22,
        "col3": "администрация Сузунского районаНовосибирской области",
        "col4": "Новосибирская область, Сузунский район, р.п. Сузун, ул. Ленина, 51                           adm@suzunadm.ru              (38346)22550"
    },
    {
        "col1": "Новосибирская область",
        "col2": 23,
        "col3": "отдел опеки и попечительства администрации Татарского муниципального округа Новосибирской области",
        "col4": "632122, Новосибирская область, г. Татарск, ул. Ленина, 56, opekatat@mail.ru, 89383 64) 2-62-54"
    },
    {
        "col1": "Новосибирская область",
        "col2": 24,
        "col3": "администрация Тогучинского района",
        "col4": "633456, Новосибирская область, г. Тогучин, ул. Садовая, д.9б/ togopeka@mail.ru/8 383 40  24886"
    },
    {
        "col1": "Новосибирская область",
        "col2": 25,
        "col3": "администрация Убинского района Новосибирской области",
        "col4": "632520 Новосибирская область, Убинский район, с. Убинское, ул. Ленина, 23 тел. 8 (38366) 21-527                            8 (38366) 21-131  ubinadm@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 26,
        "col3": "администрация Усть-Таркского района Новосибирской области",
        "col4": "632160, Новосибирская область, Усть-Таркский район, с. Усть-Тарка, ул. Иванова, 7;   ustopeka@nso.ru/83837223574 (ООиП)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 27,
        "col3": "Администрация Чановского района Новосибирской области",
        "col4": "632201, НСО,р.п.Чаны, ул.Советская, д.118\nт.21-185, факс 21-657\nchany-adm@mail.ru\n\n"
    },
    {
        "col1": "Новосибирская область",
        "col2": 28,
        "col3": "Администрация Черепановского района Новосибирской области",
        "col4": "633520, Новосибирская область, Черепановский район,г.Черепаново, ул.Партизанская, 12,    Email:orgotd@nso.ru,            т.8(383 45)21368,                      8(383 45)22206,                          8(383 45)24538"
    },
    {
        "col1": "Новосибирская область",
        "col2": 29,
        "col3": "Администрация Чистоозерного района \nНовосибирской области ",
        "col4": "632720, Новосибирская область,\nр.п. Чистоозерное, ул. Покрышкина, 11\ngln68@nso.ru, opekachst@yandex.ru\n8 383 68 91 735   "
    },
    {
        "col1": "Новосибирская область",
        "col2": 30,
        "col3": "администрация Чулымского района",
        "col4": "632551,Новосибирская область, Чулымский район, г. Чулым, ул. Чулымская, 43 (chladm@nso.ru. Тел.83835021656)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 31,
        "col3": "Админитсрация города Бердска",
        "col4": "633010, Новосибирская область, г.Бердск, ул. М.Горького, 7 oopberdsk@nso.ru  8(38341)29297"
    },
    {
        "col1": "Новосибирская область",
        "col2": 32,
        "col3": "Администрация города Искитима Новосибирской области",
        "col4": " \n633209, Новосибирская область, город Искитим, ул.Пушкина, 51.\npri_iskadm@mail.ru\n83834324566\n"
    },
    {
        "col1": "Новосибирская область",
        "col2": 33,
        "col3": "Администрация рабочего поселка Кольцово",
        "col4": "рабочий поселок Кольцово, проспект Никольский, \n здание 1, офис 400\nНовосибирская область, 630559\nтелефон: 336-15-93, \nE-mail: ooip@kolcovo.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 34,
        "col3": "Администрация города Оби Новосибирской области",
        "col4": "633102, Новосибирская область, ул. Авиационная, д.12, т. 8(38373)56110, email: adm_nso@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 35,
        "col3": "администрации райнов (округа по районам) г. Новосибирска ",
        "col4": "г. Новосибирск, ул. Петухова, 18  8 (383)227-49-36   EASafronova@admnsk.ru"
    },
    {
        "col1": "Омская область\n",
        "col2": 1,
        "col3": "Министерство труда и социального развития Омской области",
        "col4": "Яковлева ул., д. 6, Омск, 644007 E-mail: post@mintrud.omskportal.ru \nтел. (3812) 25-25-01, факс (3812) 24-23-34"
    },
    {
        "col1": "Оренбургская область",
        "col2": 1,
        "col3": "министерство социального развития Оренбургской области",
        "col4": "460006, Оренбургская область, город Оренбург, Терешковой улица, 33 szn@mail.orb.ru  8(3532) 77-33-38"
    },
    {
        "col1": "Оренбургская область",
        "col2": 2,
        "col3": "Абдулинский муниципальный округ:",
        "col4": " Оренбургская область, г. Абдулино, ул. Коммунистическая, 276, 8 (35355) 2-52-47, ab@mail.orb.ru. "
    },
    {
        "col1": "Оренбургская область",
        "col2": 3,
        "col3": "город Бугуруслан: ",
        "col4": "Оренбургская обл., г. Бугуруслан, ул. Ленинградская, 47, 8 (35352) 3-33-69, bg@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 4,
        "col3": "город Бузулук: ",
        "col4": "Оренбургская область, г. Бузулук, ул.Ленина, 10, 8 (35342) 3-51-35, adm@buzuluk-town.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 5,
        "col3": " Гайский муниципальный округ: ",
        "col4": "Оренбургская область, г. Гай, ул. Ленина, 41, 8 (35362) 4-20-33. goradm@esoo.ru, gy@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 6,
        "col3": " Кувандыкский муниципальный округ",
        "col4": " Оренбургская область, г. Кувандык, ул. Оренбургская, 20, 8 (35361) 2-37-13, 2-22-20, ku@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 7,
        "col3": " город Медногорск:",
        "col4": " Оренбургская область, г. Медногорск, ул. Советская, 37, 8 (35379) 3-26-86, mo@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 8,
        "col3": "город Новотроицк: ",
        "col4": "Оренбургская область, г. Новотроицк, ул. Советская, 80, 8 (35376) 2-01-01, adm-nvk@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 9,
        "col3": " город Оренбург: ",
        "col4": "Оренбургская область, г. Оренбург, ул.Советская, 60, 8 (3532) 98-70-10, general@admin.orenburg.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 10,
        "col3": "город Орск:",
        "col4": " Оренбургская область, г. Орск, проспект Ленина, 29, 8 (3537) 25-30-90, uprava@orsk-adm.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 11,
        "col3": "Соль-Илецкий муниципальный округ",
        "col4": ": Оренбургская область, г.Соль-Илецк, ул. Карла Маркса, 6, 8 (35336) 2-32-32, si@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 12,
        "col3": "Сорочинский муниципальный округ:",
        "col4": " г. Сорочинск, ул.Советская, 1, 8 (35346) 4-11-51, admsor@esoo.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 13,
        "col3": " Ясненский муниципальный округ: ",
        "col4": "Оренбургская область, г. Ясный, ул. Ленина, 17, 8 (35368) 2-13-00, ys@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 14,
        "col3": "Адамовский район:",
        "col4": " Оренбургская область, Адамовский район, п.Адамовка, ул. Советская, 81, 8 (35365) 2-13-38, ad@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 15,
        "col3": "Акбулакский район:",
        "col4": " Оренбургская область, п. Акбулак, ул. Комсомольская, 15, 8 (35335) 2-11-40, ak@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 16,
        "col3": "Александровский район: ",
        "col4": "Оренбургская область, р-н Александровский, с. Александровка, ул. Мичурина, 49, 8 (35359) 2-11-02, al@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 17,
        "col3": "Асекеевский район: ",
        "col4": "Оренбургская область, с. Асекеево, ул. Чапаева, 28, 8 (35351) 2-00-83,  as@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 18,
        "col3": "Беляевский район:",
        "col4": " Оренбургская область, Беляевский район, с.Беляевка, ул. Советская, 46Б, 8 (35334) 2-17-25, be@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 19,
        "col3": "Бугурусланский район: ",
        "col4": "Оренбургская область, г. Бугуруслан, ул.Московская, 52а, 8 (35352) 2-39-16, bu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 20,
        "col3": "Бузулукский район: ",
        "col4": "Оренбургская область, г. Бузулук, ул. Ленина, 10, 8 (35342) 7-42-00, bz@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 21,
        "col3": "Грачевский район:",
        "col4": " Оренбургская область, с. Грачёвка, ул.Майская, 22, 8 (35344) 2-10-60, ge@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 22,
        "col3": " Домбаровский район: ",
        "col4": "Оренбургская область, Домбаровский район, п. Домбаровский, ул. 40 лет Октября, 20, 8 (35367) 2-24-52, dm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 23,
        "col3": "Илекский район: ",
        "col4": "Оренбургская область, Илекский район, с. Илек, ул. Октябрьская, 23, 8 (35337) 2-14-64, il@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 24,
        "col3": " Кваркенский район: ",
        "col4": "Оренбургская область, Кваркенский район, с. Кваркено, ул. 1-я Целинная, 18, 8 (35364) 2-17-63, kv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 25,
        "col3": " Красногвардейский район: ",
        "col4": "Оренбургская область, Красногвардейский район, с.Плешаново, ул.Мира, 5, 8 (35345) 3-14-44, 3-00-45, ko@mail.orb.ru"
    },
    {
        "col1": "Оренбургская область",
        "col2": 26,
        "col3": "Курманаевский район: ",
        "col4": "Оренбургская область, с. Курманаевка, пл. имени В. И.Ленина, 1, 8 (35341) 2-13-91, km@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 27,
        "col3": " Матвеевский район:",
        "col4": " Оренбургская обл., с. Матвеевка, ул. Комсомольская, 18, 8 (35356) 2-10-66, ma@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 28,
        "col3": "Новоорский район:",
        "col4": " Оренбургская  область, Новоорский район, п. Новоорск, ул. Рабочая, 1, 8 (35363) 7-70-01, 7-70-02, no@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 29,
        "col3": " Новосергиевский район: ",
        "col4": "Оренбургская область, п. Новосергиевка, ул. Краснопартизанская, 20, 8 (35339) 2-39-39, ns@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 30,
        "col3": " Октябрьский район: ",
        "col4": "Оренбургская область, с. Октябрьское, ул. Луначарского, 45, 8 (35330) 2-14-34, ok@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 31,
        "col3": " Оренбургский район:",
        "col4": " Оренбургская область, г. Оренбург, ул.Ст. Разина, 211, 8 (3532) 56-12-21, 77-28-91, or@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 32,
        "col3": " Первомайский район: ",
        "col4": "Оренбургская область, Первомайский район, п. Первомайский, ул. Советская, 33а, 8 (35348) 4-13-40, 4-13-42, pm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 33,
        "col3": "Переволоцкий район:",
        "col4": " Оренбургская область, п. Переволоцкий, ул. Ленинская, 76, 8 (35338) 32-2-79, pr@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 34,
        "col3": "Пономаревский район: ",
        "col4": "Оренбургская область, Пономарёвский район, с. Пономарёвка, ул. Советская, 32, 8 (35357) 21-4-50, 21-3-34, pn@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 35,
        "col3": " Сакмарский район: ",
        "col4": "Оренбургская область, с. Сакмара, ул. Советская, 25, 8 (35331) 2-11-30, 2-17-77, sk@mail.orb.ru,"
    },
    {
        "col1": "Оренбургская область",
        "col2": 36,
        "col3": "Саракташский район:",
        "col4": " Оренбургская область, п. Саракташ, ул.Вокзальная, 12, 8 (35333) 6-02-50, sa@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 37,
        "col3": " Светлинский район:",
        "col4": " Оренбургская обл., Светлинский р- н, п. Светлый, ул. Советская, 22, 8 (35366) 2-14-55, 2-13-56, sv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 38,
        "col3": " Северный район:",
        "col4": " Оренбургская область, с. Северное, ул. Советская, 24, 8 (35354) 2-17-75, 2-12-00, se@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 39,
        "col3": " Ташлинский район: ",
        "col4": "Оренбургская область, с. Ташла, ул. Довженко, 46, 8 (35347) 2-14-82, 2-13-09, tl@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 40,
        "col3": "Тоцкий район:",
        "col4": " Оренбургская область, с. Тоцкое, ул. Красная Площадь, 1, 8 (35349) 2-14-34, to@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 41,
        "col3": "Тюльганский район:",
        "col4": " Оренбургская область, Тюльганский район, п.Тюльган, ул. Ленина, 23, 8 (35332) 2-11-34, tu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 42,
        "col3": "Шарлыкский район:",
        "col4": " Оренбургская область, с. Шарлык, ул.Советская, 40, 8 (35358) 2-14-50, sl@mail.orb.ru."
    },
    {
        "col1": "Орловская область",
        "col2": 1,
        "col3": "Казенное учреждение Орловской области \"Областной центр социальной защиты населения\"\n",
        "col4": "302028, г. Орёл, ул. Пушкина, д.22, (4862) 54-07-26,  oo_ku_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 2,
        "col3": "Филиал по городу Ливны",
        "col4": "303850, Орловская область, г. Ливны, ул. Свердлова, д. 65, 8 (486 77) 7-17-71 8 (486 77) 7-28-48, oo_livny_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 3,
        "col3": "Филиал по городу Мценску",
        "col4": "303030, Орловская область, г. Мценск, Микрорайон 1,  д.1, 8 (486 46) 2-38-94, 8 (486 46) 2-39-55, 8 (486 46) 2-39-17, oo_msnk_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 4,
        "col3": "Филиал по Болховскому району",
        "col4": "303140, Орловская область, г. Болхов, ул. Ленина, д. 41, 8(48640)24854, oo_bol_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 5,
        "col3": "Филиал по Верховскому району",
        "col4": "303720, Орловская область, п.Верховье,  ул. Советская, д. 23, 8 (486 75) 2-23-10,\noo_glr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 6,
        "col3": "Филиал по Глазуновскому району",
        "col4": "303340, Орловская область,\n п. Глазуновка, ул. Ленина, д. 120, 8 (48675) 2-12-09, glazunr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 7,
        "col3": "Филиал по Дмитровскому району",
        "col4": "303240, Орловская область, г.Дмитровск, ул. Красная,  103-а,  8 (486 49) 2-03-47, oo_dm_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 8,
        "col3": "Филиал по Должанскому району",
        "col4": "303760, Орловская область, пгт.Долгое, ул. Свердлова, д. 34в, 8 (486 72) 2-13-34, oo_dol_cszn@orel-region.ru\n"
    },
    {
        "col1": "Орловская область",
        "col2": 9,
        "col3": "Филиал по Залегощенскому району",
        "col4": "303560, Орловская область, пгт Залегощь, ул. М. Горького, д. 20,  8 (486 48) 2-21-08, \noo_zlr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 10,
        "col3": "Филиал по Знаменскому району",
        "col4": "303100, Орловская область, Знаменский район, с. Знаменское, ул. Совхозная, д. 29, 8 (486 62) 2-13-24, oo_znamr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 11,
        "col3": "Филиал по Колпнянскому району",
        "col4": "303410, Орловская область, Колпнянский район, п.г.т. Колпна, ул. Интернациональная, 2, 8 (486 74) 2-16-11, oo_kol_cszn@orel-region.ru\n"
    },
    {
        "col1": "Орловская область",
        "col2": 12,
        "col3": "Филиал по Корсаковскому району",
        "col4": "303580, Орловская область, Корсаковский район, с. Корсаково,ул. Советская, д.15, 8 (486 67) 2-15-01, oo_kor_cszn@orel-region.ru\n"
    },
    {
        "col1": "Орловская область",
        "col2": 13,
        "col3": "Филиал по Краснозоренскому району",
        "col4": "303650, Орловская область, Краснозоренский район, п. Красная Заря, ул. Гагарина,  42, 8 (486 63) 2-15-47 email: oo_krz_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 14,
        "col3": "Филиал по Кромскому району",
        "col4": "303200, Орловская обл., Кромской р-н, пгт. Кромы, пер. Ленинский, д. 9,  8(486 43) 2-15-90, oo_krom_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 15,
        "col3": "Филиал по Ливенскому району",
        "col4": "303857, Орловская область, г. Ливны,  ул. Воронежская,  21, 8 (486 77) 2-24-71, oo_livr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 16,
        "col3": "Филиал по Малоархангельскому району",
        "col4": "303370, Орловская область, г. Малоархангельск, ул. К.Маркса, 77,8 (486 79) 2-34-46,  oo_mal_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 17,
        "col3": "Филиал по Мценскому району",
        "col4": "303030, Орловская область\nг.Мценск, ул. Красноармейская, д. 13, 8 (486 46) 7-43-35, oo_mskr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 18,
        "col3": "Филиал по Новодеревеньковскому району",
        "col4": "303620, Орловская область, п. Хомутово, ул. Комсомольская, д. 3, 8 (486 78) 2-14-78, oo_nvdr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 19,
        "col3": "Филиал по Новосильскому району",
        "col4": "303500, Орловская область, г. Новосиль, ул. Карла Маркса, д. 43а, 8 (486 73) 2-13-51, oo_nvslr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 20,
        "col3": "Филиал по Орловскому району",
        "col4": "302004, г.Орел, ул. 3-я Курская, д. 20, 8 (4862) 59-88-40, 8 (4862) 59-88-38,\noo_orlr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 21,
        "col3": "Филиал по Покровскому району",
        "col4": "303170, Орловская область,\nпгт. Покровское, пер. Больничный, д. 1, 8 (486 64) 2-23-36, oo_pk_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 22,
        "col3": "Филиал по Свердловскому району",
        "col4": "303320, Орловская область, пос. Змиёвка, ул. Чапаева, д. 10, 8 (486 45) 2-11-73, oo_svrd_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 23,
        "col3": "Филиал по Сосковскому району",
        "col4": "303980, Орловская область, Сосковский район, с. Сосково, ул. Советская, д. 23,  8 (486 65) 2-12-69, oo_sosr_cszn@orel-region.ru\n"
    },
    {
        "col1": "Орловская область",
        "col2": 24,
        "col3": "Филиал по Троснянскому району",
        "col4": "303450, Орловская область, Троснянский района, село Тросна, ул. Москавская, д. 29, 8 (486 66) 2-12-98, oo_tr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 25,
        "col3": "Филиал по Урицкому району",
        "col4": "303900, Орловская область, п. Нарышкино, ул. Чапаева, д. 11, 8 (486 47) 2-04-17 , oo_ur_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 26,
        "col3": "Филиал по Хотынецкому  району",
        "col4": "303930, Орловская область, Хотынецкий район, пгт. Хотынец, ул. Ленина, д. 42, 8 (486 42) 2-21-77, oo_hotr_cszn@orel-region.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 27,
        "col3": "Филиал по Шаблыкинскому району",
        "col4": "303260, Орловская область, Шаблыкинский район, пгт. Шаблыкино, ул. Ленина, д. 21, 8 (486 44) 2-23-21, oo_shbr_cszn@orel-region.ru\n"
    },
    {
        "col1": "Пензенская область",
        "col2": 1,
        "col3": "Министерство труда, социальной защиты и демографии  Пензенской области",
        "col4": "город Пенза, Некрасова, 24, e-mail:mintrud58@mintrud58.ru, тел.: (88412) 20-20-10"
    },
    {
        "col1": "Пермский край",
        "col2": 1,
        "col3": "1. Межрайонное территориальное управление № 1 Министерства труда и социального развития Пермского края;",
        "col4": "1. ул. Советская, д. 16, г. Оханск, Оханский МО, Пермский край, 618100\nEmail: ohansk-mtu1@msr.permkrai.ru\nтел.: 8 (342) 793 29 44\n"
    },
    {
        "col1": "Пермский край",
        "col2": 2,
        "col3": "2. Межрайонное территориальное управление № 2 Министерства труда и социального развития Пермского края;",
        "col4": "2. ул. Октябрьская, д. 90, г. Верещагино,\nВерещагинский МО, Пермский край, 617120\nEmail: info-mtu2@msr.permkrai.ru\nтел.: 8 (342) 543 47 65\n"
    },
    {
        "col1": "Пермский край",
        "col2": 3,
        "col3": "3. Межрайонное территориальное управление № 3 Министерства труда и социального развития Пермского края;",
        "col4": "3. ул. Вокзальная, д. 4, с. Усть-Кишерть,\nКишертский МО, Пермский край, 617600\nEmail: kish-mtu3@msr.permkrai.ru\nтел.: 8 (342) 529 71 05, 8 (342) 529 71 01\n"
    },
    {
        "col1": "Пермский край",
        "col2": 4,
        "col3": "4. Межрайонное территориальное управление № 4 Министерства труда и социального развития Пермского края;",
        "col4": "4. ул. Нефтяников, д. 1, г. Чернушка, Чернушинский МО, Пермский край, 617830\nEmail: info-mtu4@msr.permkrai.ru\nтел.: 8 (342) 614 20 09\n"
    },
    {
        "col1": "Пермский край",
        "col2": 5,
        "col3": "5. Межрайонное территориальное управление № 5 Министерства труда и социального развития Пермского края;",
        "col4": "5. ул. К.Маркса, д. 31, г. Оса, Осинский МО,\nПермский край, 618122\nEmail: info-mtu5@msr.permkrai.ru\nтел.: 8 (342) 914 31 09\n"
    },
    {
        "col1": "Пермский край",
        "col2": 6,
        "col3": "6. Межрайонное территориальное управление № 6 Министерства труда и социального развития Пермского края;",
        "col4": "6. ул. Пятилетки, д. 46, г. Березники,\nПермский край, 618419\nEmail: info-mtu6@msr.permkrai.ru\nтел.: 8 (342) 420 43 19\n"
    },
    {
        "col1": "Пермский край",
        "col2": 7,
        "col3": "7. Межрайонное территориальное управление № 7 Министерства труда и социального развития Пермского края;",
        "col4": "7.  ул. Советская, д. 48, г. Соликамск,\nПермский край, 618551\nEmail: info-mtu7@msr.permkrai.ru\nтел.: 8 (342) 534 33 83, 8 (342) 534 30 28\n"
    },
    {
        "col1": "Пермский край",
        "col2": 8,
        "col3": "8. Территориальное управление Министерства труда и социального развития Пермского края по Александровскому муниципальному округу и городскому округу «город Кизел»",
        "col4": "8. ул. Машиностроителей, д. 1, г. Александровск, Александровский МО, Пермский край, 618320\nEmail: alextu@msr.permkrai.ru\nтел.: 8 (342) 743 59 47\n"
    },
    {
        "col1": "Пермский край",
        "col2": 9,
        "col3": "9. Территориальное управление Министерства труда и социального развития Пермского края по городу Перми",
        "col4": "9. ул. Бульвар Гагарина, д. 10, г. Пермь,\nПермский край, 614077\nEmail: info-tuperm@msr.permkrai.ru\nтел.: 8 (342) 212 80 61\n"
    },
    {
        "col1": "Пермский край",
        "col2": 10,
        "col3": "10. Территориальное управление Министерства труда и социального развития Пермского края по Губахинскому муниципальному округу",
        "col4": "10. пр. Ленина, д. 36, г. Губаха, Губахинский МО, Пермский край, 618250,\nEmail: gubahatu@msr.permkrai.ru\nтел.: 8 (342) 484 79 27\n"
    },
    {
        "col1": "Пермский край",
        "col2": 11,
        "col3": "11. Территориальное управление Министерства труда и социального развития Пермского края по Коми-Пермяцкому округу",
        "col4": "11. ул. 50 лет Октября, д. 14, г. Кудымкар, Кудымкарский МО, Пермский край, 619000\nEmail: tukpo@msr.permkrai.ru\nтел.: 8 (342) 604 32 73, 8 (342) 604 16 15\n"
    },
    {
        "col1": "Пермский край",
        "col2": 12,
        "col3": "12. Территориальное управление Министерства труда и социального развития Пермского края по Краснокамскому и Нытвенскому городским округам",
        "col4": "12. пр. Маяковского, д. 8, г. Краснокамск, Краснокамский МО, Пермский край, 617060\nEmail: tukrasn@msr.permkrai.ru\nтел.: 8 (342) 734-56-56\n"
    },
    {
        "col1": "Пермский край",
        "col2": 13,
        "col3": "13. Территориальное управление Министерства труда и социального развития Пермского края по Кунгурскому муниципальному округу",
        "col4": "13. ул. Карла Маркса, д. 10, г. Кунгур,\nКунгурский МО, Пермский край, 617470\nEmail: kungur@msr.permkrai.ru\nтел.: (342) 712-01-01\n"
    },
    {
        "col1": "Пермский край",
        "col2": 14,
        "col3": "14. Территориальное управление Министерства труда и социального развития Пермского края по Лысьвенскому городскому округу",
        "col4": "14. ул. Мира, д. 26, г. Лысьва, Лысьвенский МО, Пермский край, 618900\nEmail: lysvatu@msr.permkrai.ru\nтел.: 8 (342) 493 01 02, 8 (342) 493 01 50\n"
    },
    {
        "col1": "Пермский край",
        "col2": 15,
        "col3": "15. Территориальное управление Министерства труда и социального развития Пермского края по Пермскому муниципальному округу и Добрянскому городскому округу",
        "col4": "15. ул. Верхнемуллинская, д. 74а, г. Пермь,\nПермский край, 614065\nEmail: permsky-tuprm@msr.permkrai.ru\nтел.: 8 (342) 296 23 58, 8 (342) 296 21 08\n"
    },
    {
        "col1": "Пермский край",
        "col2": 16,
        "col3": "16. Территориальное управление Министерства труда и социального развития Пермского края по Чайковскому городскому округу",
        "col4": "16. ул. Мира, д. 2/2, г. Чайковский, Чайковский ГО, Пермский край, 617763\nEmail: chaiktu@msr.permkrai.ru\nтел.: 8 (342) 413 49 19, 8 (342) 413 23 57\n"
    },
    {
        "col1": "Пермский край",
        "col2": 17,
        "col3": "17. Территориальное управление Министерства труда и социального развития Пермского края по Чусовскому и Горнозаводскому городским округам",
        "col4": "17. ул. Ленина, д. 32а, г. Чусовой, Чусовской МО, Пермский край, 618200\nEmail: chusovoitu@msr.permkrai.ru\nтел.: 8 (342) 566-08-20\n"
    },
    {
        "col1": "Приморский край",
        "col2": 1,
        "col3": "Отдел по учету и распределению жилья управления жизнеобеспечения администрации Арсеньевского городского округа",
        "col4": "692337, Приморский край, г. Арсеньев, ул. Ленинская, 8 (каб.213) zhilotdel@ars.town 8(42361) 3-75-538;\n(42361) 3-75-54"
    },
    {
        "col1": "Приморский край",
        "col2": 2,
        "col3": "Управление опеки и попечительства администрации Артемовского городского округа",
        "col4": "692760, Приморский край, г. Артём, ул. Кирова, дом 42 / opeka@artemokrug.ru / тел. 8 42337 32280"
    },
    {
        "col1": "Приморский край",
        "col2": 3,
        "col3": "отдел опеки и попечительства администрации городского округа Большой Камень",
        "col4": "692806, Приморский край, г. Большой Камень, ул. Карла Маркса, д. 4,  opeka-bkamen@mail.ru, 8(42335)4-08-71"
    },
    {
        "col1": "Приморский край",
        "col2": 4,
        "col3": "Администрации города Владивостока      (Управление опеки и попечительства) ",
        "col4": "г. Владивосток,           Океанский проспект, д. 20.  (прием документов по адресу: г. Владивосток, Океанский проспект, д. 7, каб.308,  тел. 8(423)264-92-51, 8(423)264-92-50, opeka@vlc.ru )"
    },
    {
        "col1": "Приморский край",
        "col2": 5,
        "col3": "Управление образования администрации Дальнегорского городского округа",
        "col4": "3-23-42 dalnegorsk-uo@yandex.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 6,
        "col3": "МКУ \"Управление ЖКХ Дальнереченского городского округа\"",
        "col4": "г. Дальнереченск, ул. Победы, д.13, zizneob@dalnerokrug.ru тел.842356 25-1-82"
    },
    {
        "col1": "Приморский край",
        "col2": 7,
        "col3": "управление имущественных отношений администрации Лесозаводского муниципального округа",
        "col4": "г. Лесозаводск, ул. Будника, д. 119, каб. 301kuialgo@mo-lgo.ru   8(42355)29050"
    },
    {
        "col1": "Приморский край",
        "col2": 8,
        "col3": "Управление опеки и попечительства администрации Находкинского городского округа",
        "col4": "692922, г.Находка, ул. Пограничная, д.10а, офис 400-403, тел. 8(4236)69-91-74, 69-91-79"
    },
    {
        "col1": "Приморский край",
        "col2": 9,
        "col3": "Администрация муницапального  округа город Партизанск Приморского края",
        "col4": "692864, Приморский край, г. Партизанск, ул. Ленинская, д. 26 \"А\", pgo@partizansk.org.ru 824236360742"
    },
    {
        "col1": "Приморский край",
        "col2": 10,
        "col3": "Администрация городского округа Спасск-Дальний                                                               Управление ЖКХ ",
        "col4": "г.Спасск-Дальний ул.Борисова, д.17 gkh@spasskd.ru 84235220993"
    },
    {
        "col1": "Приморский край",
        "col2": 11,
        "col3": "Администрация Уссурийского городского округа Приморского края",
        "col4": "692519 Приморский край,г. Уссурийск, ул. Ленина,   д. 101,   тел: 8(4234)32-26-31, эл. адрес:admin1@adm-ussuriisk.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 21,
        "col3": "Отдел опеки и попечительства администрации городского округа ЗАТО Фокино",
        "col4": "692880, г. Фокино, \nул. Постникова, д. 17\nТел.: 8(42339) 2-43-55"
    },
    {
        "col1": "Приморский край",
        "col2": 13,
        "col3": "Администрация Анучинского муниципального округа",
        "col4": "692300, Приморский край, Анучинский район, с.Анучино, ул. Лазо, д.6.anuchinsky@mail.ru,   Тел.: 8(42362) 91-6-78"
    },
    {
        "col1": "Приморский край",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Дальнереченского муниципального района",
        "col4": "692132, г. Дальнереченск, ул. Ленина,90/ opeka_dmr@mail.ru /  8 42(356) 32157"
    },
    {
        "col1": "Приморский край",
        "col2": 15,
        "col3": "Администрация Кавалеровского муниципального округа отдел опеки и попечительства Управления образования",
        "col4": "Приморский край, Кавалеровский район, п. Кавалерово, ул. Арсеньева, д. 111 тел. 8(42375)9-11-10 opeka1700@mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 16,
        "col3": "Администрация Кировского муниципального района",
        "col4": "692091, Приморский край, Кировский район,                  пгт. Кировский, ул. Советская, д. 57 А, каб. 306 kiropeka@mail.ru,                      8-42354-21567 специалист Молостова Ольга Владимировна"
    },
    {
        "col1": "Приморский край",
        "col2": 17,
        "col3": "Администрация Красноармейского МО",
        "col4": "692171, Приморский край, Красноармейский район, с. Новопокровка, ул. Советская, 74, opeka19@mail.ru, 8(42359)22559"
    },
    {
        "col1": "Приморский край",
        "col2": 18,
        "col3": "Отдел опеки и попечительства администрации Лазовского муниципального округа",
        "col4": "692980 Приморский край, Лазовский район, с. Лазо, ул. Некрасовская, 31, 8(42377)20517, opekalazo@yandex.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 19,
        "col3": "Отдел опеки и попечительства администрации Михайловского муниципального округа",
        "col4": "Мальцев Юрий  Винидиктович, начальник отдела опеки и попечительства,  тел. 8(42346)24451, 692651,с. Михайловка, ул.Красноармейская,д.16,  E-mail: opeka@mikhprim.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 20,
        "col3": "Отдел опеки и попечительства администрации Надеждинского муниципального района",
        "col4": "692491, Приморский край Надеждинский район,            п. Новый                              ул. Первомайская д.43      тел.: 8 (42334) 3-86-36              эл. почта: opeka22@mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 22,
        "col3": "Отдел опеки и попечительства администрации Ольгинского муниципального округа",
        "col4": "692460 пгт Ольга, ул. Ленинская, д.8 opeka24@bk.ru  8 (42376)92214"
    },
    {
        "col1": "Приморский край",
        "col2": 23,
        "col3": "Администрация Партизанского муниципального округа ",
        "col4": "692962, Приморский край, Партизанский район, с.Владимиро-Александровское, ул.Комсомольская 45а, 84236521825, opeka_pmr@rambler.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 24,
        "col3": "Администрация Пограничного МО. Комиссия по выявлению обстоятельств, свидетельствующих о необходимости оказания таким лицам содействия в преодолении трудной жизненной ситуации",
        "col4": "692582 п. Пограничный Приморского края, ул. Советская, 29. opeka-pogranichnyi@mail.ru. 84234523262"
    },
    {
        "col1": "Приморский край",
        "col2": 25,
        "col3": "Отдел опеки и попечительства администрации Пожарского муниципального округа",
        "col4": "692001, Приморский край, Пожарский район,               пгт Лучегорск, 4 микрорайон, д. 2, каб. 518    pozopeka7000@mail.ru         8 (42357) 33 9 71"
    },
    {
        "col1": "Приморский край",
        "col2": 26,
        "col3": "Отдел по реализации отдельных государственных полномочий по обеспечению жилыми помещениями детей-сирот, детей, оставшихся без попечения родителей, лиц из числа  детей-сирот и детей, оставшихся без попечения родителей, администрации Спасского муниципального района",
        "col4": "692245 Приморский край, г. Спасск-Дальний, ул. Ленинская, 27, адрес электронной почты: spasskmr-opeka-dom@yandex.ru, тел. 8(42352) 3-45-67"
    },
    {
        "col1": "Приморский край",
        "col2": 27,
        "col3": "отдел опеки и попечительства администрации Тернейского муниципального округа",
        "col4": "отдел опеки и попечительства , 692150 Приморский край, Тернейский муниципальный округ, пгт Терней, ул. Партизанская, д. 52, каб. 2; opeka.terneiski@yandex.ru; 8(42374)31207"
    },
    {
        "col1": "Приморский край",
        "col2": 28,
        "col3": "Администрация Ханкайского муниципального округа",
        "col4": "Приморский край, Ханкайский район, с. Камень-Рыболов, ул. Кирова, д.8, 692684; ahmo@hankayski.ru, 8(42349)97796"
    },
    {
        "col1": "Приморский край",
        "col2": 29,
        "col3": "Отдел опеки и попечительства администрации Хасанского муниципального округа ПК",
        "col4": "Приморский край, Хасанский муниципальный округ, пгт Славянка, ул. Молодежная, д. 1, каб. 2. hasanskai_opeka@mail.ru 8(42331)46-04"
    },
    {
        "col1": "Приморский край",
        "col2": 30,
        "col3": "Администрация Хорольского муниципального округа Приморского края",
        "col4": "692254, Приморский край, Хорольский район,с.Хороль, ул.Ленинская, 51 / admhmr@khorol.ru/ +7 (42347) 2-24-01"
    },
    {
        "col1": "Приморский край",
        "col2": 31,
        "col3": "Администрация Черниговского МО",
        "col4": "692372, с. Черниговка, ул. Буденного, 23, opeka32@mail.ru, 8 (42351) 25-2-58"
    },
    {
        "col1": "Приморский край",
        "col2": 32,
        "col3": "Администрация Чугуевского муниципального округа",
        "col4": "692623, Приморский край, Чугуевский район, с. Чугуевка, ул. 50 лет Октября, д. 193, e-mail chuguevskymo@mail.ru, телефон (42372) 22-3-03, тел./факс (42372) 22-6-01"
    },
    {
        "col1": "Приморский край",
        "col2": 33,
        "col3": "Управление имущественных и земельных отношений Администрации Шкотовского муниципального округа",
        "col4": "г. Большой Камень, ул. Карла Маркса, 4. kymi@bk.ru. 8 42 335  5-00-30"
    },
    {
        "col1": "Приморский край",
        "col2": 34,
        "col3": "Администрация Яковлевского муниципального округа",
        "col4": "692361 приморский край, Яковлевский район, с. Яковлевка, пер. Почтовый д. 5, tooipyakovlevka@mail.ru тел. 8(423711) 97441 "
    },
    {
        "col1": "Псковская область",
        "col2": 1,
        "col3": "Территориальные подразделения Комитета по социальной защите Псковской области",
        "col4": "-"    
    },
    {
        "col1": "Псковская область",
        "col2": 2,
        "col3": "Территориальный отдел г. Великие Луки",
        "col4": "180000, г. Псков, ул. Вокзальная, д. 12\npskov.opc@socal.pskov.ru\n(8112) 72-01-05"
    },
    {
        "col1": "Псковская область",
        "col2": 3,
        "col3": "Территориальный отдел Бежаницкого района",
        "col4": "182840, р.п. Бежаницы, ул. Комсомольская, д. 1\nbezhanicy@social.pskov.ru\n(81141) 2-19-82"
    },
    {
        "col1": "Псковская область",
        "col2": 4,
        "col3": "Территориальный отдел Гдовского района",
        "col4": "181600, г. Гдов, ул. Ленина, д. 2/9 \ngdov@social.pskov.ru\n(81131) 2-12-77"
    },
    {
        "col1": "Псковская область",
        "col2": 5,
        "col3": "Территориальный отдел Дедовичского района",
        "col4": "182710, р.п. Дедовичи, пл. Советов, д. 7\ndedovichi@social.pskov.ru\n(81136) 9-34-01 (факс), 9-30-77"
    },
    {
        "col1": "Псковская область",
        "col2": 6,
        "col3": "Территориальный отдел Дновского района",
        "col4": "182670, г. Дно, ул. К.Маркса, д. 16\ndno@social.pskov.ru\n(81135) 2-58-56 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 7,
        "col3": "Территориальный отдел Красногородского района",
        "col4": "182370, р.п. Красногородск, ул. Школьная, д. 3\nkrasnogorodsk@social.pskov.ru\n(81137) 2-12-42 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 8,
        "col3": "Территориальный отдел Куньинского района",
        "col4": "182010, р.п. Кунья, ул. Советская, д. 17\nkunja@social.pskov.ru\n(81149) 2-18-94 (факс), 2-22-41"
    },
    {
        "col1": "Псковская область",
        "col2": 9,
        "col3": "Территориальный отдел Локнянского района",
        "col4": "182900, р.п. Локня, ул. Первомайская, д. 3\nloknja@social.pskov.ru\n(81139) 2-22-69, 2-18-71 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 10,
        "col3": "Территориальный отдел Невельского района",
        "col4": "182510, г. Невель, пл. Карла Маркса, д. 1\nnevel@social.pskov.ru\n(81151) 2-12-98 (факс), 2-15-21"
    },
    {
        "col1": "Псковская область",
        "col2": 11,
        "col3": "Территориальный отдел Новосокольнического района",
        "col4": "182200, г. Новосокольники, ул. Тихмянова, д. 25\nnovosokolniki@social.pskov.ru\n(81144) 2-23-33 (факс), 2-22-44"
    },
    {
        "col1": "Псковская область",
        "col2": 12,
        "col3": "Территориальный отдел Опочецкого района",
        "col4": "182330, г. Опочка, ул. Ленина, д. 11/17\nopochka@social.pskov.ru\n(81138) 2-25-16, 2-10-53"
    },
    {
        "col1": "Псковская область",
        "col2": 13,
        "col3": "Территориальный отдел Островского района",
        "col4": "181350, г. Остров, ул. 25 Октября, д. 31\nostrov@social.pskov.ru\n(81152) 3-29-60 (факс), 3-13-21"
    },
    {
        "col1": "Псковская область",
        "col2": 14,
        "col3": "Территориальный отдел Палкинского района",
        "col4": "182270, р.п. Палкино, ул. Островского, д. 23\npalkino@social.pskov.ru\n(81145) 2-18-51"
    },
    {
        "col1": "Псковская область",
        "col2": 15,
        "col3": "Территориальный отдел\nПечорского района",
        "col4": "181500, г. Печоры, ул. Свободы, д. 34\npechory@social.pskov.ru\n(81148) 2-17-17, 2-29-49 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 16,
        "col3": "Территориальный отдел Плюсского района",
        "col4": "181000, р.п. Плюсса, ул. Комсомольская, д. 1а\npljussa@social.pskov.ru\n(81133) 2-17-67 (факс), 2-23-34"
    },
    {
        "col1": "Псковская область",
        "col2": 17,
        "col3": "Территориальный отдел Порховского района",
        "col4": "182620, г. Порхов, пр. Ленина, д. 14\nporhov@social.pskov.ru\n(81134) 2-46-09 (факс), 2-46-06"
    },
    {
        "col1": "Псковская область",
        "col2": 18,
        "col3": "Территориальный отдел Псковского района",
        "col4": "180006, г. Псков, ул. Школьная, д. 26\npskovrajon@social.pskov.ru\n(8112) 72-07-55, 72-07-60 (факс), 72-06-85"
    },
    {
        "col1": "Псковская область",
        "col2": 19,
        "col3": "Территориальный отдел Пустошкинского района",
        "col4": "182300, г. Пустошка, ул. Первомайская, д. 21\npustoshka@social.pskov.ru\n(81142) 2-14-81 (факс), 2-12-53"
    },
    {
        "col1": "Псковская область",
        "col2": 20,
        "col3": "Территориальный отдел Пушкиногорского района",
        "col4": "181370, р.п. Пушкинские Горы, ул. Ленина, д. 6\npushgory@social.pskov.ru\n(81146) 2-20-94 (факс), 2-13-05"
    },
    {
        "col1": "Псковская область",
        "col2": 21,
        "col3": "Территориальный отдел Пыталовского района",
        "col4": "181410, г. Пыталово, ул. Красноармейская, д. 37\npytalovo@social.pskov.ru\n(81147) 2-21-54"
    },
    {
        "col1": "Псковская область",
        "col2": 22,
        "col3": "Территориальный отдел Себежского района",
        "col4": "182250, г. Себеж, ул. 7 Ноября, д. 2\nsebezh@social.pskov.ru\n(81140) 2-15-26, 2-22-48\n"
    },
    {
        "col1": "Псковская область",
        "col2": 23,
        "col3": "Территориальный отдел Струго-Красненского района",
        "col4": "181110, р.п. Струги Красные, ул. Советская, д. 7а\nstrugikrasnye@social.pskov.ru\n(81132) 5-15-94 (факс), 5-19-39"
    },
    {
        "col1": "Псковская область",
        "col2": 24,
        "col3": "Территориальный отдел Усвятского района",
        "col4": "182570, р.п. Усвяты, ул. К.Маркса, д. 20\nusvjaty@social.pskov.ru\n(81150) 2-16-74, 2-16-30\n"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 1,
        "col3": " \"Городской округ \"город Майкоп\"",
        "col4": "385000, г. Майкоп, ул. Краснооктябрьская, 21,\n тел.: 8(8772) 52-27-61,\ne-mail: priemn@maikop.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 2,
        "col3": " \"Городской округ \"Город Адыгейск\" Республики Адыгея\";",
        "col4": " 385200, г. Адыгейск, \nпр-т им. В. И. Ленина, 31,\nтел.: 8(87772) 9-16-90,\nтел.: 8(87772) 9-14-05, е-mail: admadig@mail.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 3,
        "col3": " \"Гиагинский муниципальный район Республики Адыгея\";",
        "col4": "ст. Гиагинская, \nул. Кооперативная, 35.\nтел.: +7 (87779) 3-09-30, доб. 111,\nе-mail: giaginskadmin@mail.r"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 4,
        "col3": " \"Кошехабльский муниципальный район Республики Адыгея\";",
        "col4": "385400, а. Кошехабль,\nул. Дружбы народов, 58\nТел.: (877-70) 9-28-00, \nе-mail: kosadmin@radnet.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 5,
        "col3": " \"Красногвардейский муниципальный район Республики Адыгея\";",
        "col4": " с. Красногвардейское, \nул. Чапаева, 93, тел. +7 (87778)5-34-61,\n e-mail: radnet2005@mail.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 6,
        "col3": " \"Майкопский муниципальный район Республики Адыгея\";",
        "col4": "385730, п. Тульский, \nул. Советская, 42,\nтел./факс: (877-77) 5-11-51,\nе-mail: adm@egov01.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 7,
        "col3": " \"Тахтамукайский муниципальный район Республики Адыгея\";",
        "col4": "385100, а. Тахтамукай, \nул. Гагарина, 2, Тел.: (877-71) 9-62-90,\nе-mail: tahtamukay@mail.ru"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 8,
        "col3": " \"Теучежский муниципальный район Республики Адыгея\";",
        "col4": "385230, а. Понежукай, ул.Октябрьская, 33, тел.: 8(877-72) 9-77-09;\nе-mail: teuch adm ady@mail.ru тел"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 9,
        "col3": " \"Шовгеновский муниципальный район Республики Адыгея\"",
        "col4": "а. Хакуринохабль, \nул, Шовгенова, 9, тел.: 8(87773)9-21-74,\nе-mail: shovadm@adygheya.gov.ru"
    },
    {
        "col1": "Республика Алтай",
        "col2": 1,
        "col3": "Министерство труда, социального развития и занятости населения Республики Алтай",
        "col4": "Республика Алтай, г. Горно - Алтайск, ул. Северная, 10, mintrud@altaigov.ru, 8(38822)47732"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 1,
        "col3": "Администрация муниципального района Абзелиловский район Республики Башкортостан (межведомственная комиссия, отдел опеки и попечительства)",
        "col4": "453620, Республика Башкортостан, Абзелиловский район, с.Аскарово, ул.Ленина, д.41, adm01@bashkortostan.ru, 8(34772)2-15-10"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 2,
        "col3": "Администрация МР Альшеевский район РБ (межведомственная комиссия, сектор по опеке и попечительству)",
        "col4": "452120, Республика Башкортостан, Альшеевский район, с. Раевский, ул. Ленина, д. 113, adm02@bashkortostan.ru, 8(34754)3-15-88, 8(34754)3-15-57"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 3,
        "col3": "Администрация МР Архангельский район РБ(Отдел по строительству и ЖКХ)",
        "col4": "453030, Республика Башкортостан, Архангельский район, с. Архангельское, ул. Н.Крупская, д.2, тел 834774 -2 -13-76,03.sjp@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 4,
        "col3": "Администрация МР Аскинский район РБ",
        "col4": "452880, Республика Башкортостан, Аскинский район, с. Аскино, ул. Советская, д.21, adm04@bashkortostan.ru +7(347)712-17-65"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 5,
        "col3": "Администрация МР Аургазинский район РБ (межведомственная комиссия отдел опеки и попечительства",
        "col4": "4534745, РБ, Аургазинский район, с. Толбазы, ул. Ленина, д.86, aur-roo-opeka@yandex.ru/ 8(34745)2-02-85"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 6,
        "col3": "Администрация МР Баймакский районРБ (межведомственная комиссия)",
        "col4": "453630, Республика Башкортостан, г.Баймак, пр.С.Юлаева,36 adm44@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 7,
        "col3": "Администрация муниципального района Бакалинский район Республики Башкортостан (межведомственная комиссия)",
        "col4": "452650, РБ, Бакалинский район, с. Бакалы, ул. Ленина, д. 91 adm06@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 8,
        "col3": "Администрация \nмуниципального района \nБалтачевский район\nРеспублики Башкортостан",
        "col4": "452980, Республика \nБашкортостан, \nс. Старобалтачево,\nул. Советская, д. 48,\nadm07@bashkortostan.ru/\n+7 (34753) 2-19-61"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 9,
        "col3": "Администрация МР Белебеевчкий район РБ(отдел опеки и попеительства)",
        "col4": "452000,РБ, г. Белебей, ул. Красная, д. 116, 45.opek@bashkortostan.ru, 8 (34786) 5-11-74"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 10,
        "col3": "Администрации муниципального района Белокатайский район (Отдел опеки и попечительства)",
        "col4": "РБ, Белокатайский район, с. Новобелокатай, ул. Советская, д. 116 adm08@bashkortostan.ru 8(34750)22772"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 11,
        "col3": "Администрация муниципального района Белорецкий район ",
        "col4": "РБ, г. Белорецк, ул. Ленина, д. 71, adm46@bashkortostan.ru, +7 (34792) 3-08-06, 3-25-69"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 12,
        "col3": "Администрация муниципального района Бижбулякский район РБ (Сектор опеки и попечительства )",
        "col4": "РБ, Бижбулякский район, с.Бижбуляк, ул.Победы, д.13, тел: 8347-43 22369"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 13,
        "col3": "Администрация МР Бирский район РБ (отдел опеки и попечительства)",
        "col4": "РБ, г. Бирск, ул. Курбатова, д. 63, opeka47@mail.ru, 8(347)8422807"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 14,
        "col3": "Администрация Муниципального района Благовещенский район Республики Башкортостан (Отдел опеки и попечительства)",
        "col4": "РБ, г. Благовещенск, ул. Седова, д. 96,opeka_blagov@mail.ru, тел. 83476622157"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 15,
        "col3": "Администрация муниципального района Благоварский район Республики Башкортостан (Отдел опеки и попечительства)",
        "col4": "452740, Республика Башкортостан, Благоварский район, с.Языково, ул.Ленина, д.37, blagovar_opeka@mail.ru/83474722793"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 16,
        "col3": "Администрация МР\nБуздякский район РБ\n(сектор опеки и попечительства)",
        "col4": "452710, Р\nеспублика Башкортостан , \nс.Буздяк, ул. Ленина, 7 \nbuzdyakopeka@yandex.ru, \n+73477331941"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 17,
        "col3": "Администрация МР Бураевский район РБ (сектор опеки и попечительства)",
        "col4": "452960 Республика Башкортостан с.Бураево, ул. Ленина,66 adm12@bashkortostan.ru +73475621734"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 18,
        "col3": "Администрация МР Бурзянский район Республики Башкортостан (межведомственная комиссия, отдел опеки и попечительства)",
        "col4": "Администрация МР Бурзянский район РБ, 453580, Бурзянский район, с.Старосубхангулово, ул.Ленина, д.61 8(34755)35240, adm13@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 19,
        "col3": "Администрация МР Гафурийский район Республики Башкортостан (межведомственная комиссия)",
        "col4": "453050, Гафурийский район,\nс. Красноусольский, ул. Октябрьская, adm14@bashkortostan.ru, тел.(34740)2-78-48"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 20,
        "col3": "Администрация МР Давлекановский район РБ",
        "col4": "453400, Республика Башкортостан, Давлекановский район, г. Давлеканово, ул. Красная Площадь, д. 9, adm49@bashkortostan.ru, 83476832525"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 21,
        "col3": "Администрация муниципального района Дуванский район Республики Башкортостан",
        "col4": "452530, Республика Башкортостан, Дуванский район, с. Месягутово, ул. И. Усова, д.6 adm15@bashkortostan.ru 83479832341"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 22,
        "col3": "Администрация муниципального района Дюртюлинский район  ",
        "col4": "452320, Республика Башкортостан, г. Дюртюли, ул. Чеверева, д.41, 50.opeka@bashkortostan.ru 83478722678"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 23,
        "col3": "Администрация муниципального района Ермекеевский район Республики Башкортостан (сектор опеки и попечительства)",
        "col4": "452190, Ермекеевский район, с. Ермекеево, ул. Ленина, 15; erm_opeka@mail.ru; тел.: 83474127137"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 24,
        "col3": "Администрация муниципального района Зианчуринский район Республики Башкортостан",
        "col4": "453380, Зианчуринский район, с. Исянгулово, ул. Советская, 3; adm17@bashkortostan.ru; тел.: 83478521416"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 25,
        "col3": "Администрация МР Зилаирский район РБ (Межведомственная комиссия)",
        "col4": "453680, Республика Башкортостан, Зилаирский район, с. Зилаир, ул. Ленина, 70, adm18@bashkortostan.ru, 8(347552)2-23-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 26,
        "col3": "Администрация муниципального района Иглинский район Республики Башкортостан\n(Межведомственная комиссия, отдел опеки и попечительства)",
        "col4": "452410, Республика Башкортостан, Иглинский район, с. Иглино, ул. Ленина, д.79 iglino-opeka@yandex.ru тел. 8(43795)2-34-92"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 27,
        "col3": "Администрация муниципального района Илишевский район Республики Башкортостан",
        "col4": "452260, Республика Башкортостан, Илишевский район, с. Верхнеяркеево, ул. Красноармейская, д.35 adm20@bashkortostan.ru 83476251538"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 28,
        "col3": "Администрация муниципального района Ишимбайский район Республики Башкортостан",
        "col4": "453200, Республика Башкортостан, г. Ишимбай, пр. Лениная, д.60, adm51@bashkortostan.ru, 83479461830"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 29,
        "col3": "Администрация муниципального района Калтасинский район Республики Башкортостан (отдел опеки и попечительства)",
        "col4": "452860, Республика Башкортостан, Калтасинский район, с. Калтасы, ул. Карла Маркса, д. 82. kaltasyorgopeki@mail.ru, тел. 3477942224"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 30,
        "col3": "Органы опеки и попечительства Администрации МР Караидельский район РБ",
        "col4": "452360, РБ, Караидельский район, с.Караидель, ул.Ленина, д.24, opeka.karaidel@mail.ru, 8(34744)2-00-15"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 31,
        "col3": "Администрация муниципального района Кармаскалинский район, Республики Башкортостан ( отдел опеки и попечительства, межведомственная комиссия)",
        "col4": "453020, Республика Башкортостан, Кармаскалинский район, с.Кармаскалы, ул.Садовая,23, opeka.karm@mail.ru,83476521189"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 32,
        "col3": "Администрация муниципального района Кигинский район Республики Башкортостан",
        "col4": "452500, Республика Башкортостан, Кигинский район, с. Верхние Киги, ул. Салавата д. 2, adm24@bashkortostan.ru, 83474837171"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 33,
        "col3": "Администрация муниципального района Краснокамский район Республики Башкортостан",
        "col4": "452930, Республика Башкортостан, Краснокамский район, с. Николо- Березовка, ул. Карла Маркса, д.3,adm25@bashkortostan.ru,83475977600"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 34,
        "col3": "Администрация муниципального района Кугарчинский район Республики Башкортостан  ",
        "col4": "453330, Республика Башкортостан, Кугарчинский район, с. Мраково, ул. Ленина, д.15, adm26@bashkortostan.ru, 83478921059."
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 35,
        "col3": "Администрация муниципального района Кушнаренковский район Республики Башкортостан",
        "col4": "452230, РБ, Кушнаренковский район, с.Кушнаренково, ул.Октябрьская, 69, Zhilkom-kush@mail.ru, т. 8(34780) 57553"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 36,
        "col3": "Администрация МР Куюргазинский район РБ",
        "col4": "453360, Республика Башкортостан, Куюргазинский район, с. Ермолаево, пр-кт Мира, д. 10, тел. 83475761150,adm28@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 37,
        "col3": "Администрация муниципального района Мелеузовский район Республики Башкортостан / отдел опеки и попечительства",
        "col4": "453850, Республика Башкортостан, г. Мелеуз, ул. Воровского, д. 11, e-mail: adm54@bashkortostan.ru, т. +7(34764) 3-14-42 / 453850, Республика Башкортостан, г. Мелеуз, ул. Ленина, д. 150, e-mail: opeka@meleuz.bashkortostan.ru +7(34764)3-07-89"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 38,
        "col3": "Администрация муниципального района Мечетлинский район Республики Башкортостан",
        "col4": "452550, Республика Башкортостан, Мечетлинский район, с. Большеустьикинское, ул. Ленина, д. 20, adm29@bashkortostan.ru 8(34770)2-02-99"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 39,
        "col3": "Администрация муниципального района Мишкинский район Республики Башкортостан",
        "col4": "452340, РБ, Мишкинский район, с.Мишкино, ул.Ленина, 89, adm30@bashkortostan.ru, т. 8(34749) 21801"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 40,
        "col3": "Администрация МР Миякинский раойн РБ (межведомственная комиссия/сектор по опеке и попечителсьтву)",
        "col4": "452080, Республика Башкортостан, Миякинский район, с. Киргиз-Мияки, ул. Ленина, д.26. adm31@bashkortostan.ru 8(34788)2-12-01, 2-13-78"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 41,
        "col3": "Администрация МР Нуримановский район РБ",
        "col4": "452440, Республика Башкортостан, Нуримановский район, с. Красная Горка, ул. Советская, д.62, +7(34776)2-23-35,adm32@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 42,
        "col3": "Администрация муниципального района Салаватский район Республики Башкортостан",
        "col4": "-"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 43,
        "col3": "Администрация муниципального района Стерлибашевский район РБ (отдел жизнеобеспечения АМР Стерлибашевский район РБ)",
        "col4": "453180, Стерлибашево, ул.Карла Маркса,97\nТел.2-20-31, факс 2-20-03, adm34@bashkortostan.ru\n"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 44,
        "col3": "Администрация муниципального района Стерлитамакский район Республики Башкортостан (орган опеки и попечительства)",
        "col4": "453124, Республика Башкортостан, г.Стерлитамак, ул.Худайбердина, д.16 35.opeka@bashkortostan.ru 8 3473 25 93 21"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 45,
        "col3": "Администрация муниципального района Татышлинский район (сектор по опеке и попечительству)",
        "col4": "452830, РБ Татышлинский район, с. Верхние Татышлы, ул. Ленина, д.75 орека-tatroo@yandex.ru 8(34778)2-11-33"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 46,
        "col3": "Администрация муниципального района Туймазинский район РБ (МВК по установлению факта невозможности (возможности) проживания детей-сирот, отдел опеки и попечительства Управления образования АМР Туймазинский район РБ)",
        "col4": "452750, РБ, г. Туймазы, Пл. Октября, д. 1, opeka02@mail.ru тел.: 8(34782)5-25-74, 5-18-50"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 47,
        "col3": "Администрация муниципального района Уфимский район Республики Башкортостан",
        "col4": "г. Уфа, ул. Достоевского, 98, opeka_ur@mail.ru, (347)246-14-02"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 48,
        "col3": "Администрация муниципального района Учалинский район Республики Башкортостан (рабочкая комиссия при жилищно-бытовой комиссии администрации)",
        "col4": "453700, Республика Башкортостан, Учалинский район, г. Учалы, ул. К. Маркса, д. 20, 61.opeka@bashkortostan.ru, тел.: 6(34791)6-16-17"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 49,
        "col3": "Администрация муниципального района Федоровский район Республики Башкортостан",
        "col4": "453280, Республика Башкортостан, Федоровский район, с. Федоровка, ул. Ленина, д. 48, opeka.fedorovka@mail.ru, 8 (34746) 2-73-21"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 50,
        "col3": "Администрация муниципального района Хайбуллинский район Республики Башкортостан",
        "col4": "453800, Республика Башкортостан, Хайбуллинский район, с. Акъяр, пр. С.Юлаева, д. 45, e-mail: haibopeka@mail.ru, тел.: +7(34758)2-26-90"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 51,
        "col3": "Администрация муниципального района Чекмагушевский район Республики Башкортостан",
        "col4": "452200 Республика Башкортостан, Чекмагушевский район, с. Чекмагуш, ул.Ленина, д.55, e-mail: 40.jilotdel2@bashkortostan.ru 8(34796)3-22-17 e-mail: chekmag.opeka@yandex.ru 8(34796)3-21-63"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 52,
        "col3": "Администрация муниципального района Чишминский район РБ (отдел опеки и попечительства)",
        "col4": "452170, р.п.Чишмы, ул.Кирова, д.17, к.8, тел (834797) 2-29-95, opekachishmi@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 53,
        "col3": "Администрация МР Шаранский район (сектор опеки и попечительства)",
        "col4": "452630, РБ, Шаранский район, с.Шаран, ул. Пролетарская. д. 1 тел 34769 2-20-62"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 54,
        "col3": "Администрация муниципального района Янаульский район РБ (отдел по опеке и попечительству)",
        "col4": "452800, РБ, г.Янаул, пер.Малышева, д.4Б, ms,opeka@bk,ru, тел.:8(34760)51824"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 55,
        "col3": "Администрация ГО г.Агидель РБ",
        "col4": "г.Агидель Цветочный бульвар,д.2 43.jil@bashkortostan.ru 28215"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 56,
        "col3": "Администрация городского округа город Кумертау Республики Башкортостан (Жилищный отдел)",
        "col4": "453300, Республика Башкортостан, г.Кумертау, ул.Ленина, д.18, \nadm52@bashkortostan.ru (52.house@bashkortostan.ru), 83476143861"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 57,
        "col3": "Администрация ЗАТО Межгорье Республики Башкортостан (Отдел по учету и распределению жилья Администрации ЗАТО Межгорье Республики Башкортостан)",
        "col4": "453571, Республика Башкортостан, г. Межгорье, ул. 40 лет Победы, д. 60, adm53@bashkoptostan.ru , тел. 8(34781)2-24-77, 8(34781)2-15-00"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 58,
        "col3": "Администрация городского округа город Нефтекамск Республики Башкортостан",
        "col4": "452680, г. Нефтекамск, пр-кт. Комсомольский, д. 25, тел. 8(34783)4-92-46, adm55@bashkortostan.ru; отдел опеки и попечительства - г. Нефтекамск, ул. Ленина, д. 13 А, тел. 8(34783)4-03-48, neft_opeka@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 59,
        "col3": "Администрация городского округа город Октябрьский Республики Башкортостан (отдел опеки и попечительства администрации)",
        "col4": "452600, г. Октябрьский, ул. Чапаева, д. 23, тел. 8(34767)61610, opeka61610@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 60,
        "col3": "Администрация городского округа город Салават Республики Башкортостан (отдел опеки и попечительства)",
        "col4": "453261, г. Салават, ул. Ленина, д. 2, opeka@salavat.bashkortostan.ru тел. 8(3476)32-64-74"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 61,
        "col3": "Администрация г.о.г.Сибай (Отдел опеки и попечительства)",
        "col4": "453830,Республика Башкортостан,город Сибай,улица Ленина,д.12,каб.203;т.34775 2 35 53"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 62,
        "col3": "Администрация городского округа город Стерлитамак Республики Башкортостан",
        "col4": "453100, г.Стерлитамак, пр-кт Октября, д.32, adm59@bashkortostan.ru, 8(3473) 24-18-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 63,
        "col3": "1) Администрация Демского района ГО г. Уфа \n2) Администрация Калининского района ГО г. Уфа  \n3) Администрация Кировского района ГО г. Уфа \n4) Администрация Ленинского района ГО г. Уфа \n5) Администрация Октябрьского района ГО г. Уфа  \n6) Администрация Орджоникидзевского района ГО г. Уфа \n7) Администрация Советского района ГО г. Уфа ",
        "col4": "1) г. Уфа, ул. Ухтомского, 3 surz_dema@mail.ru, 8 (347)281-28-33, 8(347)281-16-74 \n2)  г. Уфа, ул. Орджоникидзе, 3 kalinaourz@mail.ru, 8(347)263-34-49, 8(347)263-35-29 \n3) г. Уфа, ул. Пушкина, д. 85, Gafarova.rm@ufacity.info 8(347)279-90-10, 8(347)279-90-19\n4)  г. Уфа, ул. Мустая Карима, д. 19, regina2015@mail.ru 8(347)229-04-00, 8(347)229-04-06 \n5) г. Уфа, ул. Комсомольская, д. 142/1, oktadmgil@mail.ru, 2373240@gmail.com, 8(347)237-40-00, 8(347)237-47-97 \n6) г. Уфа, ул. Мира, д. 6, jil_org@mail.ru, 8(347)242-03-11, 8(347)242-02-72 \n7)  г. Уфа, ул. Революционная, д.111, sovadm12@bk.ru, 8(347)273-33-09, 8(347)273-15-38\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 1,
        "col3": "Администрация Железнодорожного района г. Улан-Удэ",
        "col4": "670002, Республика Бурятия, г. Улан-Удэ, ул. Октябрьская, 2/ agdr@ulan-ude-eg.ru/ (8-3012) 44-85-25\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 2,
        "col3": "Администрация  Октябрьского района г. Улан-Удэ, ",
        "col4": "670047, Республика Бурятия, г. Улан-Удэ, ул. Павлова, 1/ aоr@ulan-ude-eg.ru/ (8-3012) 37-02-24\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 3,
        "col3": "Администрация Советского района г. Улан-Удэ",
        "col4": " 670000, Республика Бурятия, г. Улан-Удэ, ул. Советская, 23/ 670000, Республика Бурятия, г. Улан-Удэ, ул. Советская, 23/ asr@ulan-ude-eg.ru/ (8-3012) 21-56-46\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 4,
        "col3": "Администрация Баргузинского района",
        "col4": " 671610, Республика Бурятия, Баргузинский район, п. Баргузин, ул. Дзержинского, 24/ admbrg@icm.buryatia.ru/ (8-231) (8-30131) 4-13-22\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 5,
        "col3": "Администрация Баунтовского района",
        "col4": " 671510, Республика Бурятия, Баунтовский район, c. Багдарин, ул. Ленина, 22/ admbnt@icm.buryatia.ru/ (8-253) (8-30153) 4-13-22\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 6,
        "col3": "Администрация Бичурского района",
        "col4": " 671360, Республика Бурятия, Бичурский район, с. Бичура, ул. Советская, 43/ admbich@icm.buryatia.ru/ (8-233) (8-30133) 5-15-18\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 7,
        "col3": "Администрация Джидинского района",
        "col4": " 671920, Республика Бурятия, Джидинский район, с. Петропавловка, ул. Терешковой, 8/ admdzd@icm.buryatia.ru/ (8-234) (8-30134) 9-14-20\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 8,
        "col3": "Администрация Еравнинского района",
        "col4": " 672430, Республика Бурятия, Еравнинский район, с. Сосново-Озерское, ул. Первомайская, 113/ admeravna@icm.buryatia.ru/  (8-235) (8-30135) 2-14-45\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 9,
        "col3": "Администрация Заиграевского район",
        "col4": "Заиграевского района 671310, Республика Бурятия, Заиграевский район, п. Заиграево, ул. Октябрьская, 4/ admzgr@icm.buryatia.ru/ (8-236) (8-30136) 4-22-40\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 10,
        "col3": "Администрация Закаменского района",
        "col4": "671930, Республика Бурятия, Закаменский район, г. Закаменск, ул. Ленина, 17/ admzakam@icm.buryatia.ru/ (8-237) (8-30137) 4-44-53\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 11,
        "col3": "Администрация Иволгинского района",
        "col4": " 670045, Республика Бурятия, Иволгинский район, п. Иволгинск, ул. Ленина, 18а / admivl@icm.buryatia.ru/ (8-240) (8-30140) 2-13-51\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 12,
        "col3": "Администрация Кабанского района",
        "col4": "\n 671200, Республика Бурятия, Кабанский район, с. Кабанск, ул. Кирова, 10/ admkab@icm.buryatia.ru/ (8-238) (8-30138) 4-15-36\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 13,
        "col3": "Администрация Кижингинского района",
        "col4": " 671450, Республика Бурятия, Кижингинский район, с. Кижинга, ул. Коммунистическая, 14/ admkzn@icm.buryatia.ru/ (8-241) (8-30141) 3-26-47  "
    },
    {
        "col1": "Республика Бурятия",
        "col2": 14,
        "col3": "Администрация Курумканского района",
        "col4": " 671613, Республика Бурятия, Курумканский район, с. Курумкан, ул. Булдакова, 13/ admkrm@icm.buryatia.ru/ (8-249) (8-30149) 4-15-15\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 15,
        "col3": "Администрация Кяхтинского района",
        "col4": " 671840, Республика Бурятия, Кяхтинский район, г. Кяхта, ул. Ленина, 33/ admkht@icm.buryatia.ru/ (8-242) (8-30142) 9-14-78\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 16,
        "col3": "Администрация Муйского района",
        "col4": " 671517, Республика Бурятия, Муйский район, п. Таксимо, ул. Советская, 10/ admmsk@icm.buryatia.ru/ (8-239) (8-30139) 5-54-33\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 17,
        "col3": "Администрация Мухоршибирского района",
        "col4": " 671340, Республика Бурятия, Мухоршибирский район, с. Мухоршибирь, ул. Доржиева, 38/ admmhr@icm.buryatia.ru/ (8-243) (8-30143) 2-11-63\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 18,
        "col3": "Администрация Окинского района ",
        "col4": "671030, Республика Бурятия, Окинский район, с. Орлик, ул. Советская, 12/ admoka@icm.buryatia.ru  / (8-250) (8-30150) 5-11-64\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 19,
        "col3": "Администрация Прибайкальского района ",
        "col4": "671260, Республика Бурятия, Прибайкальский район, с. Турунтаево, ул. Комарова, 14/ admprb@icm.buryatia.ru/ (8-244) (8-30144) 5-11-63\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 20,
        "col3": "Администрация г. Северобайкальска",
        "col4": " 671701, Республика Бурятия, г. Северобайкальск, пр. Ленинградский, 7/ admsevbk@icm.buryatia.ru/  (8-239) (8-30139) 2-23-19\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 21,
        "col3": "Администрация Северобайкальского района ",
        "col4": "671710, Республика Бурятия, Северобайкальский район, п. Нижнеангарск, ул. Ленина, 119/ admnrd@icm.buryatia.ru/ (8-230) (8-30130) 4-74-48\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 22,
        "col3": "Администрация Селенгинского района",
        "col4": " 671160, Республика Бурятия, Селенгинский район, г. Гусиноозерск, ул. Пушкина, 12/ admsel@icm.buryatia.ru / (8-245) (8-30145) 4-12-81\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 23,
        "col3": "Администрация Тарбагатайского района",
        "col4": " 671112, Республика Бурятия, Тарбагатайский район, с. Тарбагатай, ул. Школьная, 1/ admtrb@icm.buryatia.ru/ (8-246) (8-30146) 5-53-67\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 24,
        "col3": "Администрация Тункинского района",
        "col4": " 670820, Республика Бурятия, Тункинский район, с. Кырен, ул. Ленина, 107/ admtnk@icm.buryatia.ru / (8-247) (8-30147) 9-15-39\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 25,
        "col3": "Адмиистрайия Хоринского района",
        "col4": " 671410, Республика Бурятия, Хоринский район, с. Хоринск, ул. Первомайская, 41/ admhrn@icm.buryatia.ru/ (8-248) (8-30148) 2-21-63  "
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 1,
        "col3": "Министерство труда и социального развития Республики Дагестан",
        "col4": "367015, Республика Дагестан, г.Махачкала, ул.Абубакарова, 117;\n mintrud@e-dag.ru\n+7 (8722) 64-15-04"
    },
    {
        "col1": "Республика Ингушетия",
        "col2": 1,
        "col3": "Министерство образования и науки",
        "col4": "dopotde@mail.ru; 8(8732) 22-17-93;  386102  Республика Ингушетия, г. Назрань,ул. Московская, 37"
    },
    {
        "col1": "Республика Калмыкия",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Республика Карелия",
        "col2": 1,
        "col3": "Администрация Беломорского муниципального округа",
        "col4": "186500, г. Беломорск, ул. Ленинская, д. 9; (81437) 5-10-50 (приемная), e-mail: belkaradm@belomorsk-mo.ru; (81437) 5-15-84, e-mail: belopeka@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/belomorsk/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 2,
        "col3": "Администрация Калевальского национального муниципального района",
        "col4": "186910, Республика Карелия, пгт. Калевала, ул. Советская, д. 11; (81454) 4-11-05 (приемная); (81454) 4-18-77; e-mail: kalevadm@onego.ru; anna.kenieva@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/kaleval a/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 3,
        "col3": "Администрация Кемского муниципального района",
        "col4": "186610, г. Кемь, просп. Пролетарский, д. 30; (81458) 7-04-33 (приемная); e-mail: admikem@mail.ru; 8-964-317-80-17, (81458) 2-20-03, e-mail: soc.opeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/kem/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 4,
        "col3": "Администрация Кондопожского муниципального района",
        "col4": "186220, г. Кондопога, пл. Ленина, д. 1; (81451) 7-94-52 (приемная); e-mail: kondadm@kmr10.ru; (81451) 7-99-79, 8-964-317-83-58; paukova@kmr10.ru; https://gov.karelia.ru/power/local_govern ment/kondopoga/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 5,
        "col3": "Администрация Костомукшского городского округа",
        "col4": "186931, г. Костомукша ул. Строителей, д. 5; (81459) 5-10-10 (приемная); e-mail: adm-kos@msu.kostomuksha-rk.ru; 8-911-660-89-59, 8-911-660-15-68, 8-911-430-25-52 e-mail: opeka2kgo@list.ru; https://gov.karelia.ru/power/local_gov ernment/kostomuksha/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 6,
        "col3": "Администрация Лахденпохского муниципального района",
        "col4": "186730, г. Лахденпохья, ул. Советская, д.7-А; (81450) 4-54-98 (приемная), e-mail: amcylah@onego.ru; (81450) 46-3-92;’ e-mail: bratchikova-mn@lah-mr.ru; https://gov.karelia.ru/power/local_gov ernment/lah/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 7,
        "col3": "Администрация Лоухского муниципального района",
        "col4": "186660, п. Лоухи, ул. Советская, д. 27; (81439) 5-10-17 (приемная); e-mail: louhiadm@yandex.ru; (81439) 5-17-43, e-mail: opeca.louhi@mail.ru; https://gov.karelia.ru/power/local_gov ernment/louhiadm/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 8,
        "col3": "Администрация Медвежьегорского муниципального района",
        "col4": "186300, г. Медвежьегорск ул. Кирова, д. 7; (81434) 5-15-55 (приемная); e-mail: medgora.msu@onego.ru (81434) 5-26-80, 8-902-772-70-15, e-mail: medgora.opeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/medgora/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 9,
        "col3": "Администрация Муезерского муниципального района",
        "col4": "186960, п. Муезерский, ул. Октябрьская, д. 28; (81455) 3-36-30 (приемная); e-mail: mueadmin@inbox.ru; 8-921-526-60-80, (81455) 338-49, e-mail: mue.opeka@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/muezersky/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 10,
        "col3": "Администрация Олонецкого национального муниципального района",
        "col4": "186000, г. Олонец, ул. Свирских дивизий, д. 1; (81436) 4-15-06; e-mail: administr@onego.ru; 8-964-317-81-16; e-mail: olonopeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/olon/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 11,
        "col3": "Администрация Петрозаводского городского округа",
        "col4": "185001, г. Петрозаводск, просп. Ленина, д. 2; (8142) 71-33-00 (приемная), e-mail: adm@petrozavodsk-mo.ru; (8142) 71-35-52, 71-34-37, 71-34-38, 71-34-89; e-mail: opeka@petrozavodsk-mo.ru; https://gov.karelia.ru/power/local_gov ernment/petrozavodsk/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 12,
        "col3": "Администрация Питкярантского муниципального округа",
        "col4": "186810, г. Питкяранта, ул. Ленина, д. 43; (81433) 4-11-53 (приемная); e-mail: pitkaranta@onego.ru; 8-964-317-50-95; e-mail: opeka.apmr@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/pit/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 13,
        "col3": "Администрация Прионежского муниципального района",
        "col4": "185005, г. Петрозаводск, ул. Правды, д. 14, (8142) 57-81-68 (приемная), e-mail: adm@prionego.ru; 8-902-770-15-04, 8-902-770-12-45; e-mail: ivanova@prionego.ru; https://gov.karelia.ru/power/local_gov ernment/prione go/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 14,
        "col3": "Администрация Пряжинского национального муниципального района",
        "col4": "186601, п. Пряжа, ул. Советская, д. 61; (81456) 3-12-08 (приемная); e-mail: priagad@onego.ru; (81456) 3-21-86, e-mail: opekapr@bk.ru; https://gov.karelia.ru/power/local_gov ernment/pryazha/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 15,
        "col3": "Администрация Пудожского муниципального района",
        "col4": "186150, г. Пудож, ул. Ленина, д. 90; (81452) 5-17-33 (приемная); e-mail: pudogadm@pudogadm.ru; (81452) 5-10-31, e-mail: opeka.karelia@yandex.ru https://gov.karelia.ru/power/local_gov ernment/pudog/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 16,
        "col3": "Администрация Сегежского муниципального округа",
        "col4": "186420, г. Сегежа, ул. Ленина, д. 9-А; (81431) 4-24-24 (приемная), e-mail: ud1@segadmin.onego.ru; (81431) 4-25-74, e-mail: opeka-seg@yandex.ru https://gov.karelia.ru/power/local_gov ernment/seg/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 17,
        "col3": "Администрация Сортавальского муниципального округа",
        "col4": "186750, г. Сортавала, ул. Кирова, д. 11; (81430) 4-53-42 (приемная), e-mail: sort_org_otd@onego.ru; (81430) 4-81-85, e-mail: opeka.sortavala@mail.ru https://gov.karelia.ru/power/local_gov ernment/ sortavala/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 18,
        "col3": "Администрация Суоярвского муниципального округа",
        "col4": "186870, г. Суоярви, ул. Шельшакова, д. 6; (81457) 5-14-50 (приемная), e-mail: suodistrict@onego.ru; (81457) 5-18-17, 8-964-317-83-16, e-mail: opeka suo@mail.ru; https://gov.karelia.ru/power/local_gov ernment/ suoj arvi/"
    },
    {
        "col1": "Республика Коми",
        "col2": 1,
        "col3": "Муниципальное образование городского округа «Сыктывкар»",
        "col4": "ул. Орджоникидзе, д. 11,\n г. Сыктывкар,\n Республика Коми, 167000\nAntonovskaya-MV@sykt.rkomi.ru,\n8 (8212) 215607 "
    },
    {
        "col1": "Республика Коми",
        "col2": 2,
        "col3": "Муниципальный округ «Ухта» Республики Коми",
        "col4": "проезд Пионергорский, д. 2, г. Ухта,  Республика Коми, 169300\nkumiuht@mail.ru,\n8 (8216) 746341"
    },
    {
        "col1": "Республика Коми",
        "col2": 3,
        "col3": "Муниципальный округ «Воркута»",
        "col4": "пл. Центральная, д.7,\n г. Воркута, Республика Коми, 169900\njilotdelpechora@mail.ru,\n8 (82142) 72327 "
    },
    {
        "col1": "Республика Коми",
        "col2": 4,
        "col3": "Муниципальный округ «Инта» Республики Коми",
        "col4": "ул. Горького, д. 16, г. Инта,  Республика Коми, 169840, \nna.docis@inta.rkomi.ru,\n8 (82145) 62941"
    },
    {
        "col1": "Республика Коми",
        "col2": 5,
        "col3": "Муниципальное образование муниципального района «Печора»",
        "col4": "Печорский проспект, д. 46,\n г. Печора,  Республика Коми,169600,\njilotdelpechora@mail.ru,\n8 (82142) 72327                                     8 (82142) 73813"
    },
    {
        "col1": "Республика Коми",
        "col2": 6,
        "col3": "Муниципальный округ «Усинск» Республики Коми",
        "col4": "ул. Ленина, д. 13, г. Усинск, Республика Коми, 169710,\ni.s.kuzmina@usinsk.rkomi.ru,\n8 (82144) 28130 (доп. 136)\n"
    },
    {
        "col1": "Республика Коми",
        "col2": 7,
        "col3": "Муниципальный округ «Вуктыл» Республики Коми",
        "col4": " ул. Комсомольская д. 14, \nг. Вуктыл, Республика Коми, 169570, opeka_vuktyl@mail.ru\n8(82146)2-15-08"
    },
    {
        "col1": "Республика Коми",
        "col2": 8,
        "col3": "Муниципальное образование муниципального района  «Сосногорск»",
        "col4": "ул. Куратова, д.2, г. Сосногорск, Республика Коми,   169500,\nkui.sosn@mail.ru,\n8 (82149) 54471"
    },
    {
        "col1": "Республика Коми",
        "col2": 9,
        "col3": "Муниципальный район Сыктывдинский Республики Коми",
        "col4": "ул. Домны Каликовой, д.62, село Выльгорт, Республика Коми,  168220, Сыктывдинский район, \ne.i.lytkina@syktyvdin.rkomi.ru,\n8 (82130) 72118"
    },
    {
        "col1": "Республика Коми",
        "col2": 10,
        "col3": "Муниципальное образование муниципального района «Сысольский»",
        "col4": "ул. Советская, д. 35, с. Визинга, Сысольский район,Республика Коми,  168100,\ngp@sysola.rkomi.ru,\n8 (82131) 91596 "
    },
    {
        "col1": "Республика Коми",
        "col2": 11,
        "col3": "Муниципальный район «Койгородский»",
        "col4": "Мира ул., д.7, с. Койгородок, Республика Коми,168170,\nremizova.valentinka@mail.ru,\n8 (82132) 91630"
    },
    {
        "col1": "Республика Коми",
        "col2": 12,
        "col3": "Муниципальный район \"Прилузский\" Республики Коми",
        "col4": "ул. Мира, д. 76,с. Объячево, Прилузский район,  Республика Коми,  168130,\nf.a.aleksandrova@priluzie.rkomi.ru,\n8 (82133) 21453 "
    },
    {
        "col1": "Республика Коми",
        "col2": 13,
        "col3": "Муниципальный район «Корткеросский» Республики Коми",
        "col4": "ул. Советская, д. 225, село Корткерос, Корткеросский район, Республика Коми,   168020,\ngkhkortkeros@mail.ru,\n8(82136) 92495"
    },
    {
        "col1": "Республика Коми",
        "col2": 14,
        "col3": "Муниципальное образование муниципального района «Усть-Куломский»",
        "col4": "ул. Советская, д. 37, с. Усть-Кулом, Усть-Куломский р-н, Республика Коми, 168060\n\nosp.uk@yandex.ru\n\n8 8213 79 35 57"
    },
    {
        "col1": "Республика Коми",
        "col2": 15,
        "col3": "Муниципальное образование муниципального района «Троицко-Печорский»",
        "col4": "ул. Ленина, д. 2,  пгт Троицко-Печорск, Республика Коми, 169420,\notdel.zemel1@mail.ru,\n8 (82138) 97509 "
    },
    {
        "col1": "Республика Коми",
        "col2": 16,
        "col3": "Муниципальное образование муниципального района «Усть-Вымский»",
        "col4": "ул. Центральная, д. 112,\n  с. Айкино,Усть-Вымский р-н,   Республика Коми, 169040,                   ust-vym-adm@mail.ru\nadm.upravleniezhilishnoi@yandex.ru,\n8 (82134) 28213"
    },
    {
        "col1": "Республика Коми",
        "col2": 17,
        "col3": "Муниципальный округ «Княжпогостский»",
        "col4": "ул. Дзержинского, д.81,\n г. Емва,Республика Коми, 168200,\nuoemva@yandex.ru,\n8 (82139) 22846"
    },
    {
        "col1": "Республика Коми",
        "col2": 18,
        "col3": "Муниципальное образование муниципального района «Удорский»",
        "col4": "ул. Центральная, д. 144, с. Кослан,Удорский район, Республика Коми,169240,\nzv@udora.rkomi.ru,\n8 (82135) 33034"
    },
    {
        "col1": "Республика Коми",
        "col2": 19,
        "col3": "Муниципальное образование муниципального района «Ижемский»",
        "col4": "ул. Советская, д. 45, с. Ижма,Ижемский район, Республика Коми,169460, \njkhiks@mail.ru,\n8 (82140) 94107 доб. 105  "
    },
    {
        "col1": "Республика Коми",
        "col2": 20,
        "col3": "Муниципальное образование муниципального района «Усть-Цилемский»",
        "col4": "ул. Новый квартал, д.11а, с. Усть-Цильма, Республика Коми, 169480,\nadmin@ust-cilma.rkomi.ru (резерв e.n.ostashova@ust-cilma.rkomi.ru) ,\n8 (82141) 91255"
    },
    {
        "col1": "Республика Крым",
        "col2": 1,
        "col3": "Министерство образования, науки и молодежи Республики Крым",
        "col4": "295000, Республика Крым, г. Симферополь, пер. Совнаркомовский, 3\nedu@monm.rk.gov.ru\n+7(3652) 27-52-32 "
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 1,
        "col3": "Администрация городского округа «Город Йошкар-Ола»",
        "col4": "424001, г. Йошкар-Ола, Ленинский проспект, 27 / admiola@i-ola.ru / (8362) 41-40-37"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 2,
        "col3": " Администрация городского округа \"Город Волжск\"",
        "col4": "425000, г. Волжск, Коммунистическая ул., д.1 / volzhsk.adm@mari-el.gov.ru / (83631) 6-22-58"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 3,
        "col3": "Администрация городского округа «Город Козьмодемьянск»",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / kozm.adm@mari-el.gov.ru / (83632) 7-12-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 4,
        "col3": "Администрация Волжского муниципального района",
        "col4": "425000, г. Волжск, ул. Советская, д. 29 / admrvolzsk@mail.ru / (83631) 6-35-75"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 5,
        "col3": "Администрация Горномарийского муниципального района",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / gorn.adm@mari-el.gov.ru; adm-gorn2006@yandex.ru / (83632) 7-11-53"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 6,
        "col3": "Администрация Звениговского муниципального района",
        "col4": "425060, г. Звенигово, ул. Ленина, д. 39 / zven.adm@mari-el.gov.ru / (83645) 7-17-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 7,
        "col3": "Килемарский муниципальный округ Республики Марий Эл",
        "col4": "425270, Килемарский район, пгт Килемары, ул. Садовая 55 / adm-kilemary@yandex.ru / (83643) 2-14-35"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 8,
        "col3": "Администрация Куженерского муниципального района",
        "col4": "425550, п. Куженер, ул. Кирова 14 / kuzhen.adm@mari-el.gov.ru / (83637) 9-15-80"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 9,
        "col3": "Администрация Мари-Турекского муниципального района",
        "col4": "425500, п. Мари-Турек, ул.Парковая, д. 7 / mturek.adm@mari-el.gov.ru / (83634) 9-71-00"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 10,
        "col3": "Администрация Медведевского муниципального района",
        "col4": "425200, Медведевский район, пгт Медведево, Советская ул., д.20 / MED_ADM@MAIL.RU / (8362) 58-28-50"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 11,
        "col3": "Администрация Моркинского муниципального района",
        "col4": "425120, пос. Морки, ул.Советская, 14 / morki.adm@mari-el.gov.ru / (83635) 9-16-12"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 12,
        "col3": "Администрация Новоторъяльского муниципального района",
        "col4": "425430, п. Новый Торъял, ул. Культуры, д.33 / admtoryal@mail.ru / (83636) 9-15-51"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 13,
        "col3": "Администрация Оршанского муниципального района",
        "col4": "425250, пгт. Оршанка, ул. Советская, 109 / orshan.adm@mari-el.gov.ru / (83641) 2-34-41"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 14,
        "col3": "Администрация Параньгинского муниципального района",
        "col4": "425570, Параньгинский район, пгт Параньга, ул. Новая, д. 4 / paranga.adm@mari-el.gov.ru / (83639)4-72-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 15,
        "col3": "Администрация Сернурского муниципального района",
        "col4": "425450, Сернурский район, пгт. Сернур, ул. Комсомольская, д. 10 / sernur12_adm@mail.ru / (83633) 9-73-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 16,
        "col3": "Администрация Советского муниципального района",
        "col4": "425400, пгт. Советский, ул. Свердлова, д. 8 / sovetsk_adm@mail.ru / (83638) 9-43-15"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 17,
        "col3": "Администрация Юринского муниципального района",
        "col4": "425370, Юринский район, пгт.Юрино, ул. Красная площадь, д.23 / yurino.adm@mari-el.gov.ru / (83644) 3-22-24"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 1,
        "col3": "Министерство социальной защиты, труда и занятости населения Республики Мордовия",
        "col4": "тел. 8(8342)39-31-09, эл. адрес: mtszn-opnd@e-mordovia.ru, почтовый адрес: 430027, Республика Мордовия, г. Саранск, ул. Титова, д. 133."
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 1,
        "col3": "Муниципальный район «Абыйский улус (район)» ",
        "col4": " 678890, Республика Саха (Якутия), пгт. Белая Гора, ул. И.Н.Ефимова, д. 2\n+7 (411) 592-12-59 abijadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 2,
        "col3": "Муниципальный район «Алданский район» ",
        "col4": " 678901, Республика Саха (Якутия), г. Алдан, ул. Ленина, 19\n8(41145) 35-184  aldanray@rambler.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 3,
        "col3": "Муниципальный район «Аллаиховский улус (район)» ",
        "col4": "678800, Республика Саха (Якутия), Аллаиховский у., 1 поселок Чокурдах, ул. им.Ленина, д.8\n8(41158) 21-820  admglava@alla.sakha.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 4,
        "col3": "Муниципальный район «Анабарский национальный (долгано-эвенкийский) улус (район)» ",
        "col4": "678440, Республика Саха (Якутия), Анабарский улус, с. Саскылах, ул. Октябрьская, 10\n+7(4116) 821-458  anadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 5,
        "col3": "Муниципальный район «Амгинский улус (район)»",
        "col4": "678600, Республика Саха (Якутия), Амгинский улус, c. Амга, ул. Партизанская, 69\n+7(4114) 241-455  amga_adm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 6,
        "col3": "Муниципальный район «Булунский улус» ",
        "col4": "678400 Республика саха (Якутия), Булунский улус, п. Тикси, ул. Трусова, 6\n8(41167) 53-533  Org.bulun@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 7,
        "col3": "Муниципальный район «Верхневилюйский улус (район)» ",
        "col4": "678230, Республика Саха (Якутия), Верхневилюйский улус, с.Верхневилюйск, ул.Героя Васильева,2\n+7(4113) 341-237   admvervil@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 8,
        "col3": "Муниципальный район «Верхнеколымский улус (район)» ",
        "col4": "678770, Республика Саха (Якутия), Верхнеколымский улус, п.Зырянка, ул.Водников, д.7\n+7 (411) 552-56-63  vkoladm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 9,
        "col3": "Муниципальное образование «Верхоянский район» ",
        "col4": "678500, Республика Саха (Якутия), Верхоянский улус, п.Батагай, ул.Ленина, д.15\n8(41165) 21005  verhadm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 10,
        "col3": "Муниципальный район «Вилюйский улус (район)»  ",
        "col4": "678200, Республика Саха (Якутия), Вилюйский улус, г.Вилюйск, ул.Ленина, д. 49\n8(41132) 42-435  viluadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 11,
        "col3": "Муниципальный район «Горный улус»  ",
        "col4": "678030 Республика Саха (Якутия), Горный улус, с.Бердигестях, ул. Ленина д.8\n+7(4113) 141-184  gornay_admin@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 12,
        "col3": "Муниципальный район «Жиганский национальный эвенкийский район» ",
        "col4": "678330, Республика Саха (Якутия), Жиганский район, с. Жиганск, улица Аммосова, дом 28\n+7(4116) 421-937  zhiganskadm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 13,
        "col3": "Муниципальный район «Кобяйский улус (район)»",
        "col4": "678300, Республика Саха (Якутия), Кобяйский улус (район), п.Сангар, ул.Ленина, д. 55\n8 (41163) 22-184   amokobylys@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 14,
        "col3": "Муниципальное образование «Ленский район» ",
        "col4": "678144, Республика Саха (Якутия), Ленский район, г.Ленск, ул.Ленина, д. 65\n+7(4113) 742-304  admin@lenskrayon.ru, amolenskiyrayon@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 15,
        "col3": "Муниципальный район «Мегино-Кангаласский улус» ",
        "col4": "678080, Республика Саха (Якутия), Мегино-Кангаласский улус, п. Нижний Бестях, ул. Ленина, 36, корпус 2\n+7(4114) 347-895  adm-mk@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 16,
        "col3": "Муниципальный район «Мирнинский район» ",
        "col4": "678175, Республика Саха (Якутия), Мирнинский улус., г.Мирный, ул.Ленина, д.19\n8(41136) 36-133  kpu-mirny@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 17,
        "col3": "Муниципальное образование «Момский район»",
        "col4": "678860 Республика Саха (Якутия) Момский район, с.Хонуу ул.Молодежная 39\n8(41150) 21028  momadmin@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 18,
        "col3": "Муниципальное образование «Намский улус» ",
        "col4": "678380, Республика Саха (Якутия), Намский улус,  с.Намцы, ул.Октябрьская, д. 1 \n8(4112) 21-184  namsadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 19,
        "col3": "Муниципальный район «Нерюнгринский район» ",
        "col4": "678960, Республика Саха (Якутия),  г.Нерюнгри, пр.Дружбы народов, 21\n+7 (41147) 4-16-40  cityhall@neruadmin.ru "
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 20,
        "col3": "Муниципальный район «Нижнеколымский район» ",
        "col4": "678830 Республика Саха (Якутия), Нижнеколымский район, п.Черский, ул.Ойунского, д. 1\n+7(4115) 722-620  admnkol@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 21,
        "col3": "Муниципальный район «Нюрбинский район» ",
        "col4": "678450, Республика Саха (Якутия), Нюрбинский район, г.Нюрба, ул.Ленина, 31\n+7(4113) 423-553  nurbadm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 22,
        "col3": "Муниципальный район «Оймяконский улус» ",
        "col4": " 678730, Республика Саха (Якутия), пгт. Усть-Нера, ул. Ленина, д. 2, к. А\n+7 (41154) 2-13-00  ojmaadm@inbox.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 23,
        "col3": "Муниципальный район «Олекминский район» Республики Саха (Якутия)",
        "col4": "678100, Республика Саха (Якутия), Олекминский район, г. Олекминск, ул. Молодежная, 10\n+7(41138) 41-460   olekadm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 24,
        "col3": "Муниципальный район «Оленекский эвенкийский национальный район» ",
        "col4": " 678480, Республика Саха (Якутия), с. Оленек, ул. Октябрьская, д. 24\n+7 (411) 692-31-82  olenekskiy-rayon.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 25,
        "col3": "Муниципальный район «Среднеколымский улус (район)» ",
        "col4": "678790, Республика Саха (Якутия) Среднеколымский улус (район), г.Среднеколымск, ул.Ярославского, дом 11\n+7(4115) 641-152  skoladm@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 26,
        "col3": "Муниципальный район «Сунтарский улус (район)» ",
        "col4": "678290, Сунтарский улус, село Сунтар, ул. Ленина, д. 26\n+7(41135) 22-018  Adm_suntar@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 27,
        "col3": "Муниципальный район «Таттинский улус» ",
        "col4": "678650 Республика Саха (Якутия), Таттинский улус (район), с. Ытык-Кюель, ул. Ленина, 17\n+7(4115) 241-339  5241339@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 28,
        "col3": "Муниципальный район «Томпонский район» ",
        "col4": "678720, Респ. Саха, п. Хандыга, ул. Е.Д.Кычкина, д.1\n+7(4115) 341-880  mash_buro_tompo@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 29,
        "col3": "Муниципальный район «Усть-Алданский улус (район)» Республики Саха (Якутия)",
        "col4": "678350 Республика Саха (Якутия) МО \"Усть-Алданский улус (район)\" с. Борогонцы, ул. Ленина, 31\n+7(411) 614-3184  ualdadm@sakhaset.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 30,
        "col3": "Муниципальный район «Усть-Майский улус (район)» Республики Саха (Якутия)",
        "col4": "678620, РЕСПУБЛИКА САХА (ЯКУТИЯ) , УСТЬ-МАЙСКИЙ УЛУС, П. УСТЬ-МАЯ, УЛ. ГОРЬКОГО, 41\n8(41141) 4-27-35  ustmaya-msu@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 31,
        "col3": "Муниципальный район «Усть-Янский улус (район)» Республики Саха (Якутия)",
        "col4": "678540, Республика Саха (Якутия), Усть-Янский у., пгт Депутатский, мкр Арктика, д. 28\n8(41166) 28408   jur_sluzhba@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 32,
        "col3": "Муниципальный район «Хангаласский улус» Республики Саха (Якутия)",
        "col4": "678000, Республика Саха (Якутия), Хангаласский улус г.Покровск, ул. Орджоникидзе, 26\n+7(411) 444-1157   adm@xangalas.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 33,
        "col3": "муниципальный район «Чурапчинский улус» Республики Саха (Якутия)",
        "col4": "678670, ЧУРАПЧИНСКИЙ УЛУС, с. Чурапча, ул. Ленина, 41 \n+7(4115) 141-184  admchurapcha@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 34,
        "col3": "Муниципальный район «Эвено-Бытантайский национальный улус (район)» Республики Саха (Якутия)",
        "col4": "678580, ЭВЕНО-БЫТАНТАЙСКИЙ НАЦИОНАЛЬНЫЙ УЛУС, С БАТАГАЙ-АЛЫТА, \n+7 (41126) 2-10-25  evenadm@mail.ru\n"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 35,
        "col3": "Муниципальное образование Городской округ «Жатай» Республики Саха (Якутия)",
        "col4": "677902, п. Жатай, Республика Саха (Якутия), ул. Северная, д. 29\n8 (411) 242-65-08  gojatay@mail.ru"
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 36,
        "col3": "городской округ «город Якутск»",
        "col4": " 677000, г. Якутск, пр. Ленина, 15 \n40-80-76, 40-80-74   yakutsk@yakadm.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 1,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Алагирскому району",
        "col4": "РСО-Алания, 363426, г. Алагир., ул. Комсомольская, 28, тел. 8(86731) 36405  Email:uszn-alagir@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 2,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Ардонскому  району",
        "col4": "РСО-Алания, 363330, г. Ардон, ул. Пролетарская, 71, тел. 8(86732) 33483, Email: uszn-ardon@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 3,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Дигорскому  району",
        "col4": "РСО-Алания, 363410, г. Дигора, ул. Энгельса, 47, 8(86733) 91960; Email:uszn-digora@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 4,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Ирафскому району",
        "col4": "РСО-Алания, 363500, с. Чикола, ул. Макоева, 19. 8(86734)31380 Email: uszn-iraf@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 5,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Кировскому району",
        "col4": "РСО-Алания,363600,  с.Эльхотово,  ул. Зортова, 20, 8(86735)51056. Email: uszn-kirov@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 6,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Моздокскому району",
        "col4": "РСО-Алания, 363760, г. Моздок, ул. Проезд Юбилейный,6    8(86736)24696  Email: uszn-mozdok@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 7,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Правобережному район",
        "col4": "РСО-Алания, 363020, г. Беслан, ул. Плиева, 16, 8(86737)31564, Email: uszn-prav@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 8,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Пригородному району",
        "col4": "РСО-Алания, 363130, с. Октябрьское, ул. Епхиева, 50, 8(86738)22202  Email: uszn-prig@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 9,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Иристонскому МО г. Владикавказа",
        "col4": "РСО-Алания, 362025, г. Владикавказ, ул. Ватутина, 17  8(8672)536486   Email: uszn-iriston@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 10,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Затеречному  МО г. Владикавказа6",
        "col4": "РСО-Алания, 362008, г. Владикавказ, ул. Коцоева, 17  8(8672)256298   Email: uszn-zater@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 11,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Промышленному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362002, г. Владикавказ, ул. Чкалова,4  8(8672)766474   Email: uszn-prom@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 12,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Северо-Западному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362031 г. Владикавказ, ул. Леонова,4  8(8672)746131   Email: uszn-sevzap@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Татарстан",
        "col2": 1,
        "col3": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Mon@tatar.ru, 8843 294-95-66",
        "col4": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Mon@tatar.ru, 8843 294-95-66"
    },
    {
        "col1": "Республика Тыва",
        "col2": 1,
        "col3": "Министерство образования Республики Тыва",
        "col4": "Республика Тыва, \nг. Кызыл, ул. Ленина, 39,  \nopeka.2022@mail.ru\nраб. номер \n8 (39422) 3-00-21\n"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 1,
        "col3": "Администрация города Абакана",
        "col4": "ул. Щетинкина, д. 10, г. Абакан, 655017, тел.: 8 (3902) 223-791, e-mail: abakanadm@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 2,
        "col3": "Администрация города Абазы",
        "col4": "ул. Ленина, д. 20а, г. Абаза, 655750, тел.: 8 (39047) 2-30-61, e-mail: abm-abaza@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 3,
        "col3": "Администрация города Саяногорска",
        "col4": "микр., Советский, д. 1, г. Саяногорск, 655602, тел.: 8 (39042) 2-02-00, e-mail: sayanogorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 4,
        "col3": "Администрация города Сорска",
        "col4": "ул. Кирова, д. 3, г. Сорск, 655111, тел.: 8 (39033) 2-43-55, e-mail: aosorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 5,
        "col3": "Администрация города Черногорска",
        "col4": "ул. Советская, д. 66, г. Черногорск, 655154, тел.: 8 (39031) 2-25-72, e-mail: adm.ch.22572@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 6,
        "col3": "Администрация Алтайского района",
        "col4": "ул. Ленина, д. 74, с. Белый Яр, Алтайский район,  тел.: 8 (39041) 2-12-53, e-mail: altay@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 7,
        "col3": "Администрация Аскизского района",
        "col4": "ул. Суворова, д. 2, с. Аскиз, Аскизский район, 655700,  тел.: 8 (39045) 9-13-31, e-mail: mo-askiz@mail.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 8,
        "col3": "Администрация Бейского района",
        "col4": "ул. Площадь Советов, д. 20, с. Бея, Бейский район, 655770,  тел.: 8 (39044) 3-20-00, e-mail: mo-beya@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 9,
        "col3": "Администрация Боградского района",
        "col4": "ул. Новая, д. 10, с. Боград, Боградский район, 655340,  тел.: 8 (39034) 9-12-56, e-mail: admbograd@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 10,
        "col3": "Администрация Орджоникидзевского района",
        "col4": "ул. Кирова, д. 16, с. Копьево, Орджоникидзевский район, 655250,  тел.: 8 (39036) 2-17-04, e-mail: amo-ordgo@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 11,
        "col3": "Администрция Таштыпского района",
        "col4": "ул. Ленина, д. 35, с. Таштып, Таштыпский район, 655740,  тел.: 8 (39046) 2-14-42, e-mail: amotash@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 12,
        "col3": "Администрация Усть-Абаканского района",
        "col4": "ул. Рабочая, д. 9, с. Усть-Абакан, Усть-Абаканский район, 655100,  тел.: 8 (39032) 2-16-56, e-mail: oo_ua@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 13,
        "col3": "Администрацитя Ширинского района",
        "col4": "ул. Октябрьская, д. 79, с. Шира, Ширинский район, 655200,  тел.: 8 (39035) 9-12-10, e-mail: adminshira@r-19.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 1,
        "col3": "Администрация Азовского района (Азовский районный отдел образования)",
        "col4": "346780, Россия, Ростовская область, г. Азов, ул. Мира, д. 102, каб. 4. 8 863 42 6 60 40 aar-doc@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 2,
        "col3": "Управление образования Администрации Аксайского района",
        "col4": "346720, Ростовская область, г. Аксай, ул. Ломоносова, 1         rmk@aksay.ru                              8 (86350) 5-51-07"
    },
    {
        "col1": "Ростовская область",
        "col2": 3,
        "col3": "Администрация Багаевского района",
        "col4": "346610 Ростовская область, Багаевский район, ст.Багаевская, ул.Подройкина, д.10, тел. 8(86357) 33-2-44,  е-mail: bagadm@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 4,
        "col3": "Служба реализации жилищных программ Администрации Белокалитвинского района",
        "col4": "347042, Ростовская область, г. Белая Калитва, ул. Чернышевского, д. 8, sektor25645@mail.ru, 8(86383)2-56-45"
    },
    {
        "col1": "Ростовская область",
        "col2": 5,
        "col3": "Отдел образования Администрации Боковского района",
        "col4": "346250 Ростовская область, Боковский район, станица Боковская, улица Ленина, 67/ bokovskoo@yandex.ru/ 8(86382)3-11-67"
    },
    {
        "col1": "Ростовская область",
        "col2": 6,
        "col3": "Отдел образования Администрации Верхнедонского района",
        "col4": "346170, ул. Советская, д. 30, ст-ца Казанская, Верхнедонской район, Ростовская область, vdonroo@mail.ru, 88636431262"
    },
    {
        "col1": "Ростовская область",
        "col2": 7,
        "col3": "Администрации Веселовского района (Сектор строительства, газо-электроснабжения, транспорта и связи Администрации Веселовского района)",
        "col4": "347781, Ростовская область, Веселовский район, п. Веселый, пер. Комсомольский, д.61 E-mail: str-otd.204@yandex.ru 8(86358)6-12-37"
    },
    {
        "col1": "Ростовская область",
        "col2": 8,
        "col3": "Отдел образования администрации Волгодонского района Ростовской области",
        "col4": "347350, Ростовская область, Волгодонской район, ст. Романовская, ул. Почтовая, 9, телефон 88639470390, электронная почта: pomoo@vttc.donpac.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 9,
        "col3": "Дубовский районный отдел образования",
        "col4": "347410, Ростовская область, Дубовский район, с. Дубовское, пер. Восстания, 11, drob@vttc.donpac.ru,  8(86377)51208"
    },
    {
        "col1": "Ростовская область",
        "col2": 10,
        "col3": "Администрация Егорлыкского райрна Ростовской области",
        "col4": "347661, Ростовская область, Егорлыкский район, ст. Егорлыкская, ул. Мира, 90, admegorlyk@donland.ru +7(86370)21-3-15"
    },
    {
        "col1": "Ростовская область",
        "col2": 11,
        "col3": "Администрация Заветинского района",
        "col4": "347430 Ростовская область, Заветинский район,село Заветное, ул. Ломоносова, д.24,  zavetnoe61@donland.ru, +7 (86378) 2-14-00"
    },
    {
        "col1": "Ростовская область",
        "col2": 12,
        "col3": "Администрация Зерноградского района (Комиссия по принятию решений о предоставлении выплаты на приобретение благоустроенного жилого помещения в собственность)",
        "col4": "347740, Ростовская область, г. Зерноград, ул. Мира, 16, rayono@zern.donpac.ru, 886359 36-1-28 (секретарь Комиссии)"
    },
    {
        "col1": "Ростовская область",
        "col2": 13,
        "col3": "Администрация Зимовниковского района",
        "col4": "ул. Ленина, 114, п. Зимовники, Ростовская область, 347460 adm@zima.donpac.ru 8(86376)3-16-98"
    },
    {
        "col1": "Ростовская область",
        "col2": 14,
        "col3": "Администрация Кагальницкого района",
        "col4": "347700, Ростовская обл., Кагальницкий р-он., ст. Кагальницкая, ул. Калинина, д. 101, тел.: 8(86345)96-1-40, kaglobsh@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 15,
        "col3": "Отдел образования Администрации Каменского района ",
        "col4": " Ростовская область, Каменский район, рп. Глубокий ул. Ленина, д. 38,      glubray@mail.ru, 8(86365)-95-2-54; "
    },
    {
        "col1": "Ростовская область",
        "col2": 16,
        "col3": "Администрация Кашарского района (Кашарский отдел образования)",
        "col4": "346200, Ростовская область, Кашарский район, сл. Кашары, ул. Ленина, д. 58, эл.почта: roo_kasharsky@rostobr.ru, тел: 8-863-88-21-4-95"
    },
    {
        "col1": "Ростовская область",
        "col2": 17,
        "col3": "Администрация Константиновского района",
        "col4": "347250 Ростовская область, г. Константиновск, ул. 25 Октября 70, адрес электронной почты:  adm_konst@donland.ru. Номер телефона приемной Главы Администрации (863 93) 2-16-62"
    },
    {
        "col1": "Ростовская область",
        "col2": 18,
        "col3": "Отдел опеки и попечительства управления образования Красносулинского района ",
        "col4": "Ростовская область, \nг. Красный Сулин, \nул. Металлургов, 41 krasnyisulin.opeka@yandex.ru\n8(863)67-5-30-84"
    },
    {
        "col1": "Ростовская область",
        "col2": 19,
        "col3": "Отдел образования Администрации Куйбышевского района",
        "col4": "346940 Ростовская область, Куйбышевский район,  село Куйбышево, улица Пролетарская, 2-Б, kuibroo@donland.ru 8 (86348) 31-202"
    },
    {
        "col1": "Ростовская область",
        "col2": 20,
        "col3": "Администрация Мартыновского района Ростовской области",
        "col4": "346660, Ростовская область, Мартыновский район, сл. Большая Мартыновка, ул. Советская, дом № 61/adm@martadmin.ru/88639521550; 88639521035 "
    },
    {
        "col1": "Ростовская область",
        "col2": 21,
        "col3": "Отдел образования Администрации Матвеево-Курганского района",
        "col4": "346970, Ростовская область, Матвеево-Курганский район, п. Матвеев Курган, ул. 1-я Пятилетка, 104, roo_matveevo-kurgansky@rostobr.ru, тел. 8(86341) 20976"
    },
    {
        "col1": "Ростовская область",
        "col2": 22,
        "col3": "Администрация \nМиллеровского района",
        "col4": " Ленина ул., д. 6, г. Миллерово, \nРостовская обл., 346130\nТел.: +7 (86385) 2-68-69,\nфакс: +7 (86385) 2-68-63\nE-mail: admil@donland.ru\nhttps://millerovo.donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 23,
        "col3": "Администрация Милютинского района",
        "col4": "347120, Ростовская область, ст.Милютинская, ул.Павших Героев, 46, miladm@donland.ru,  8-863-89-2-14-00 "
    },
    {
        "col1": "Ростовская область",
        "col2": 24,
        "col3": "Отдел образования Администрации Морозовского района",
        "col4": "347210, Ростовская область, г.Морозовск, ул.Ленина,д.204, obraz_morozov@donland.ru, 8(86384)50977"
    },
    {
        "col1": "Ростовская область",
        "col2": 25,
        "col3": "Муниципальное учржедние \"Отдел образования Администрации Мясниковского района\"",
        "col4": "346800 Ростовская область, Мясниковский район,с. Чалтырь, ул. 7-я линия, 1б/muotdelobr2017@yandex.ru/88634923695"
    },
    {
        "col1": "Ростовская область",
        "col2": 26,
        "col3": "Управление образования Администрации Неклиновского района",
        "col4": "346830 Ростовская область, Неклиновский район, с. Покровское, ул. Ленина, дом 271       roo_neklinovsky@rostobr.ru         8(86347)21502"
    },
    {
        "col1": "Ростовская область",
        "col2": 27,
        "col3": "Отдел образования Администрации Обливского района",
        "col4": "347140, Ростовская область, Обливский район, ст. Обливская, ул. Ленина, д. 47, obraz@oblivka.donpac.ru, 8(86396)21-8-38"
    },
    {
        "col1": "Ростовская область",
        "col2": 28,
        "col3": "Отдел образования Администрации Октябрьского района",
        "col4": "346480 Ростовская область, Октябрьский район, р.п. Каменоломни, ул. Дзержинского, д. 78-А. Электронный адрес: opeka_okt_s@mail.ru. Тел. 8(86360) 2-27-33"
    },
    {
        "col1": "Ростовская область",
        "col2": 29,
        "col3": "Упраление образования Орловского района",
        "col4": "347510, Ростовская обл,Орловсий р-н., п.Орловский, ул.Пионерская, д.74, orlrono@orlovsky.donpac.ru, 8(86375) 33-1-90"
    },
    {
        "col1": "Ростовская область",
        "col2": 30,
        "col3": "Отдел образования Администрации Песчанокопского района",
        "col4": "347570,  Ростовская область, Песчанокопский район, с.Песчанокопское, ул.Школьная, 1  stars@peschan.donpac.ru,       8 (86373) 9-19-39"
    },
    {
        "col1": "Ростовская область",
        "col2": 31,
        "col3": "Районный отдел образования Администрации Пролетарского района Рост овской области",
        "col4": "347540, Ростовская область, г.Пролетарск, ул.Пионерская, д.51. rooprol@donland.ru 88637496910"
    },
    {
        "col1": "Ростовская область",
        "col2": 32,
        "col3": "Ремонтненский отдел образования Администрации Ремонтненского района",
        "col4": "347480,Ростовская область, Ремонтненский район, с.Ремонтное, ул.Ленинская, 69, +7(86379)31-2-66,remroo@remont.donpac.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 33,
        "col3": "Управление образования Родионово-Несветайского района",
        "col4": "346580, Ростовская обл., Родионово-Несветайский  р-н, сл. Родионово-Несветайская, ул. Пушкинская, 34,\nэл. почта roo_uo@mail.ru\n8(86340)30-8-65"
    },
    {
        "col1": "Ростовская область",
        "col2": 34,
        "col3": "Отдел строительства и капитального ремонта Администрации Сальского района",
        "col4": "Ростовская область, г. Сальск, ул. Ленина, 22;                      adm-otdel401@yandex.ru    886372 5-26-08   "
    },
    {
        "col1": "Ростовская область",
        "col2": 35,
        "col3": "Отдел образования Администрации Семикаракорского района Ростовской области",
        "col4": "3466030, Ростовская область,  г.Семикаракорск, проспект В.А. Закруткина,79,  roo@semikar.donpac.ru, 86356 4-11-82"
    },
    {
        "col1": "Ростовская область",
        "col2": 36,
        "col3": "Администрация Советского района",
        "col4": "347180, Ростовская область, Советский район, ст. Советская, ул. Орджоникидзе, д. 14, sov_adm.ro@mail.ru,  тел. 88636323200"
    },
    {
        "col1": "Ростовская область",
        "col2": 37,
        "col3": "Cектор по организации капитального строительства и координации работ отраслей промышленности, ЖКХ, транспорта и связи Администрации Тарасовского района",
        "col4": "346050 Ростовская область\n Тарасовский район \nп. Тарасовский \nпер. Почтовый дом 5 \n 32004@mail.ru\n 8 (863 86) 32-0-04"
    },
    {
        "col1": "Ростовская область",
        "col2": 38,
        "col3": "Администрация Тацинского района Ростовкой области",
        "col4": "347060, Ростовская область, Тацинский район, станица Тацинская, ул. Ленина, д.43 +7 (86397) 2-12-97 admtacina@tacina.donpac.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 39,
        "col3": "Администрация Усть-Донецкого района",
        "col4": "346550, Ростовская область, Усть-Донецкий район, р.п. Усть-Донецкий, ул. Ленина, д.11, ustland@donland.ru, 8(86351)-9-11-57"
    },
    {
        "col1": "Ростовская область",
        "col2": 40,
        "col3": "Отдел образования Администрации Целинского района,  Координационный совет по социальной адаптации и сопровождению выпускников из числа детей-сирот и детей,оставшихся без попечения родителей.",
        "col4": "347760, Ростовская область, Целинский район, п.Целина, ул.Советская, 35,  адрес электронной почты coo@celina.donpac.ru,      телефон 8 (86371) 9-12-43\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 41,
        "col3": "Отдел образования Администрации Цимлянского района",
        "col4": "347320 Ростовская область, Цимлянский район, г. Цимлянск, ул. Ленина, 24. roo_ciml@mail.ru, тел. 8(86391)5-10-05; 8(86391)2-40-29"
    },
    {
        "col1": "Ростовская область",
        "col2": 42,
        "col3": "Администрация Чертковского района",
        "col4": "346000, Ростовская область, Чертковский р-н, п.Чертково, ул.Петровского, д.115/ra-217@donland.ru/(86387)21722"
    },
    {
        "col1": "Ростовская область",
        "col2": 43,
        "col3": "Отдел образования администрации Шолоховского района",
        "col4": "Ростовская облать, Шолоховский район, ст. Вешенская, ул. Шолохова, 55, raiono.veshenskaya@yandex.ru,88635324458,8635321363"
    },
    {
        "col1": "Ростовская область",
        "col2": 44,
        "col3": "Управление образования администрации города Азова",
        "col4": "346870, Ростовская область, город Азов, ул. Кондаурова, 53/ uo_azov@rostobr.ru/ 8(86342)6-22-14"
    },
    {
        "col1": "Ростовская область",
        "col2": 45,
        "col3": "Администрация города Батайска",
        "col4": "346880, Ростовская область, город Батайск, ул. Куйбышева, д.184а, тел. (886354) 2-36-38, 2-36-06; эл. bataysk.opeka@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 46,
        "col3": "Управление образования г.Волгодонска",
        "col4": "Западный,  пер., д.5, г.Волгодонск, Ростовская область, 347371, тел. (8639)265373, (8639) 543270, адрес электронной почты: goruo@vttc.donpac.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 47,
        "col3": "Муниципальное казенное учреждение города Гуково \"Управление Капитального строительства и жилищно-коммунального хозяйства\" ",
        "col4": "347871 Ростовская область, г. Гуково, ул. Мира, д.42,     mku61@donland.ru, 8(86361)5-28-95"
    },
    {
        "col1": "Ростовская область",
        "col2": 48,
        "col3": "Администрация города Донецка",
        "col4": "346330, Ростовская область, г.Донецк, пр-кт Мира, 39   adm-don@donland.ru  +7(86368) 2-33-15"
    },
    {
        "col1": "Ростовская область",
        "col2": 49,
        "col3": "Отдел образования Администрации города Зверево",
        "col4": "346311 Ростовская область г. Зверево, ул. Рижская, д.5 oozverevo@yandex.ru  88635541055"
    },
    {
        "col1": "Ростовская область",
        "col2": 50,
        "col3": "Администрации город Каменск-Шахтинский ",
        "col4": "347800, Ростовская область, г. Каменск-Шахтинский, ул. Ленина 74 E-mail: abmkam@donland.ru 8 (86365) 7-49-63"
    },
    {
        "col1": "Ростовская область",
        "col2": 51,
        "col3": "Управление образования Администрации города",
        "col4": "346400, Ростовская область, г. Новочеркасск, пр-кт Платовский, д. 59б. Эл. почта: p0school@novoch.ru, тел. 8(86352)259871, 8(86352)246046"
    },
    {
        "col1": "Ростовская область",
        "col2": 52,
        "col3": "Управление образования Администрации города Новошахтинска",
        "col4": " 346918, Ростовская область, г. Нвошахтинск, ул. Советская, 21, oo_novoshakhtinsk@rostobr.ru, 8 (86369) 2-05-68"
    },
    {
        "col1": "Ростовская область",
        "col2": 53,
        "col3": "Управление образования г. Таганрога",
        "col4": "347923, Ростовская область, г. Таганрог, пер. Красногвардейский, д. 1 / goruo@tagobr.ru / +7 (8634) 648-235"
    },
    {
        "col1": "Ростовская область",
        "col2": 54,
        "col3": "Департамент образования г.Шахты",
        "col4": "346500, Ростовская область, г.Шахты, ул.Советская, 145, priem@shakhty-edu.ru             8 (8636) 22-45-46"
    },
    {
        "col1": "Ростовская область",
        "col2": 55,
        "col3": "Администрация Ворошиловского района города Ростова-на-Дону (МКУ \"Отдел образования Ворошиловского района города Ростова-на-Дону\")",
        "col4": "344113, г. Ростов-на-Дону, пр. Королева, 25Г             (863) 235-05-00  roovr@rostov-gorod.ru "
    },
    {
        "col1": "Ростовская область",
        "col2": 56,
        "col3": "Администрация Железнодорожного района города Ростова-на-Дону (МКУ \"Отдел образования Железнодорожного района города Ростова-на-Дону\")",
        "col4": "344101, г. Ростов – на – Дону,  ул. 1я Баррикадная, 48                                    (863) 222 47 83    roog1@bk.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 57,
        "col3": "Администрация Кировского района города Ростова-на-Дону (МКУ \"Отдел образования Кировского района города Ростова-на-Дону\")",
        "col4": "344002, г. Ростов-на-Дону, ул. Московская, д. 76  (863) 262 58 26    rookirovsk@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 58,
        "col3": "Администрация Лениского района города Ростова-на-Дону (МКУ \"Отдел образования Лениского района города Ростова-на-Дону\")",
        "col4": "344082, г. Ростов-на-Дону, пер. Островского, 35     (863)282-02-99     len_roo@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 59,
        "col3": "Администрация Октябрьского района города Ростова-на-Дону (МКУ \"Отдел образования Октябрьского района города Ростова-на-Дону\")",
        "col4": "344068, г. Ростов-на-Дону, ул. Ларина, д. 19/2                           (863) 245 57 30   roopriemnay@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 60,
        "col3": "Администрация Первомайского района города Ростова-на-Дону (МКУ \"Отдел образования Первомайского района города Ростова-на-Дону\")",
        "col4": "344029, Ростовская область, \nг. Ростов-на-Дону, ул. Металлургическая 110/1 (863) 227-91-45   opekap@bk.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 61,
        "col3": "Администрация Пролетарского района города Ростова-на-Дону (МКУ \"Отдел образования Пролетарского района города Ростова-на-Дону\")",
        "col4": "344025, г. Ростов-на-Дону, ул. 27 линия, д. 4                 (863) 251-35-91                 roopr-rnd@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 62,
        "col3": "Администрация Советского района города Ростова-на-Дону (МКУ \"Отдел образования Советского района города Ростова-на-Дону\")",
        "col4": " 344091   Ростов-на-Дону,   Коммунистический пр., дом 24,                               (863) 222-74-12   roosov@mail.ru    \n"
    },
    {
        "col1": "Рязанская область",
        "col2": 1,
        "col3": "Администрация Александро-Невского муниципального района Рязанской области",
        "col4": "391240, Рязанская область, р.п. Александро-Невский, ул. Советская, д. 9; aleksandro-nevskij.r@ryazan.gov.ru, +7 (491-58) 2-23-93"
    },
    {
        "col1": "Рязанская область",
        "col2": 2,
        "col3": "Администрация Ермишинского муниципального района Рязанской области",
        "col4": "391660, Рязанская область, р.п. Ермишь, пл. Ленина, д. 58, ermish@ryazan.gov.ru, +7 (491-44) 2-10-35"
    },
    {
        "col1": "Рязанская область",
        "col2": 3,
        "col3": "Администрация Захаровского муниципального района Рязанской области",
        "col4": "391740, Рязанская область, с. Захарово, ул. Центральная, д. 88, zaxarovo@ryazan.gov.ru, +7 (49153)51-3-26"
    },
    {
        "col1": "Рязанская область",
        "col2": 4,
        "col3": "Администрация Кадомского муниципального района Рязанской области",
        "col4": "391670, Рязанская область, р.п. Кадом, ул. Ленина, д. 37, kadom@ryazan.gov.ru, +7 (491-39) 5-16-90"
    },
    {
        "col1": "Рязанская область",
        "col2": 5,
        "col3": "Администрация Клепиковского муниципального района Рязанской области",
        "col4": "391030, Рязанская область, г. Спас-Клепики, пл. Ленина, д. 1, klepiki@ryazan.gov.ru, +7 (491-42) 2-62-51"
    },
    {
        "col1": "Рязанская область",
        "col2": 6,
        "col3": "Администрация Кораблинского муниципального округа Рязанской области ",
        "col4": "391200, Рязанская область, г. Кораблино, ул. Маяковского, д. 17, korablino@ryazan.gov.ru, +7 (49143) 5-04-25"
    },
    {
        "col1": "Рязанская область",
        "col2": 7,
        "col3": "Администрация Касимовского муниципального округа Рязанской области ",
        "col4": "391300, Рязанская область, г. Касимов, ул. Ленина, д. 9 А, kasimov@ryazan.gov.ru, +7 (49131) 2-24-21\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 8,
        "col3": "Администрация Милославского муниципального района Рязанской области ",
        "col4": "391770, Рязанская область, р.п. Милославское, ул. Ленина, д. 6, miloslavskoe@ryazan.gov.ru, +7 (4912) 20-28-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 9,
        "col3": "Администрация Михайловского муниципального округа Рязанской области ",
        "col4": "391710, Рязанская область, г. Михайлов, пл. Освобождения, д. 1, mixajlov@ryazan.gov.ru, +7 (49130) 2-12-32"
    },
    {
        "col1": "Рязанская область",
        "col2": 10,
        "col3": "Администрация Пителинского муниципального округа Рязанской области ",
        "col4": "391630, Рязанская область, р.п. Пителино, пл. Советская, д. 8, pitelino@ryazan.gov.ru, +7 (49145) 64141"
    },
    {
        "col1": "Рязанская область",
        "col2": 11,
        "col3": "Администрация Пронского муниципального района Рязанской области ",
        "col4": "391140, Рязанская область, р.п. Пронск, пл. Горького, д. 1, pronsk@ryazan.gov.ru, +7 (49155) 3-15-73"
    },
    {
        "col1": "Рязанская область",
        "col2": 12,
        "col3": "Администрация Путятинского муниципального округа Рязанской области ",
        "col4": "391480, Рязанская область, с. Путятино, ул. Ворошилова, д. 56, putyatino@ryazan.gov.ru, +7 (49146) 2-17-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 13,
        "col3": "Администрация Рыбновского муниципального района Рязанской области ",
        "col4": "391110, Рязанская область, г. Рыбное, пл. Ленина, д. 9, rybnoe@ryazan.gov.ru, +7(49137)50-3-42"
    },
    {
        "col1": "Рязанская область",
        "col2": 14,
        "col3": "Администрация Ряжского муниципального округа Рязанской области ",
        "col4": "391964, г. Ряжск, ул. Советская, д. 8, ryazhsk@ryazan.gov.ru, +7 (49132) 2-23-83"
    },
    {
        "col1": "Рязанская область",
        "col2": 15,
        "col3": "Администрация Рязанского муниципального района Рязанской области ",
        "col4": "390047, г. Рязань (п. Соколовка), ул. Связи, д. 14, admryazanr@ryazan.gov.ru, +7 (4912) 28-91-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 16,
        "col3": "Администрация Сапожковского муниципального района Рязанской области ",
        "col4": "391940, Рязанская область, р.п. Сапожок, ул. 50 лет Октября, д. 25, sapozhok@ryazan.gov.ru, +7(49152) 2-11-50"
    },
    {
        "col1": "Рязанская область",
        "col2": 17,
        "col3": "Администрация Сараевского муниципального района  Рязанской области  ",
        "col4": "391870, Рязанская область, р.п. Сараи, ул. Ленина, д. 157, sarai@ryazan.gov.ru, +7 (49148) 3-18-55"
    },
    {
        "col1": "Рязанская область",
        "col2": 18,
        "col3": "Администрация Сасовского муниципального округа Рязанской области ",
        "col4": "391430, Рязанская область, г. Сасово, ул. Садовая, д. 22, sasovo@ryazan.gov.ru, +7 (49133) 5-12-20, +7 (49133) 5-16-10"
    },
    {
        "col1": "Рязанская область",
        "col2": 19,
        "col3": "Администрация Скопинского муниципального района Рязанской области ",
        "col4": "391800, Рязанская область, г. Скопин, пл. Ленина, д. 22 а, skopinr@ryazan.gov.ru, +7 (49156) 2-00-45"
    },
    {
        "col1": "Рязанская область",
        "col2": 20,
        "col3": "Администрация Спасского муниципального района Рязанской области ",
        "col4": "391050, Рязанская область, г. Спасск-Рязанский, ул. Ленина, д. 48, spassk@ryazan.gov.ru, +7 (49135) 3-36-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 21,
        "col3": "Администрация Старожиловского муниципального района Рязанской области ",
        "col4": "391170, Рязанская область, р.п. Старожилово, ул. Толстого, д. 9, starozhilovo@ryazan.gov.ru, +7 (491-51) 2-22-52"
    },
    {
        "col1": "Рязанская область",
        "col2": 22,
        "col3": "Администрация Ухоловского муниципального района Рязанской области ",
        "col4": "391920, Рязанская область, р.п. Ухолово, ул. Ленина, д. 20, ukholovo-adm@ryazan.gov.ru, +7 (4915) 45-12-94"
    },
    {
        "col1": "Рязанская область",
        "col2": 23,
        "col3": "Администрация Чучковского муниципального района Рязанской области",
        "col4": "391420, Рязанская область, р.п. Чучково, пл. Ленина, д. 1, chuchkovo@ryazan.gov.ru, +7 (491-38) 7-62-25\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 24,
        "col3": "Администрация Шацкого муниципального района Рязанской области ",
        "col4": "391550, Рязанская область, г. Шацк, ул. Красная площадь, д. 5, shack@ryazan.gov.ru, +7 (49147) 2-14-52"
    },
    {
        "col1": "Рязанская область",
        "col2": 25,
        "col3": "Администрация Шиловского муниципального района Рязанской области ",
        "col4": "391500, Рязанская область, р.п. Шилово, ул. Советская, д. 14 А, shilovo@ryazan.gov.ru, +7 (491-36) 2-11-80"
    },
    {
        "col1": "Рязанская область",
        "col2": 26,
        "col3": "Администрация городского округа г. Рязани",
        "col4": "390000, г. Рязань, ул. Радищева, д. 28,ryazan@ryazan.gov.ru,+7 (4912) 20-04-12\n\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 27,
        "col3": "Администрация городского округа г. Скопин",
        "col4": "391800, Рязанская область, г. Скопин, ул. Ленина, д. 9, skopin.adm@yandex.ru, +7 (49156) 2-06-57"
    },
    {
        "col1": "Самарская область",
        "col2": 1,
        "col3": "Департамент опеки, попечительства и социальной поддержки Администрации городского округа Самара;",
        "col4": "443099, г. Самара, ул. Куйбышева, д. 44, opeka@samadm.ru, 8(846)332 24 49, 310 31 36"
    },
    {
        "col1": "Самарская область",
        "col2": 2,
        "col3": "департамент социального обеспечения администрации городского округа Тольятти ",
        "col4": "445021, г. Тольятти,   б-р Ленина, д. 15, family@tgl.ru, 54-32-40"
    },
    {
        "col1": "Самарская область",
        "col2": 3,
        "col3": "Управление семьи, опеки и попичительства Администрации городского округа Сызрань Самарской области",
        "col4": "Самарская область, г. Сызрань, ул. Советская, д. 28, uvsmid@yandex.ru, 98-48-92"
    },
    {
        "col1": "Самарская область",
        "col2": 4,
        "col3": "Управление опеки и попечительства Департамента социального развития  админитсрации г. о. Новокуйбышевск",
        "col4": "446200, г. Новокуйбышевск, ул. Миронова, д. 2, ksmd_nvkb@mail.ru, 8( 846) 35         35263, ( 3 5265)"
    },
    {
        "col1": "Самарская область",
        "col2": 5,
        "col3": "МКУ \"Комитет социального развития, семьи, опеки и попечительства администрации городского округа Чапаевск\"",
        "col4": "г. Чапаевск, ул. Куйбышева, 12А, KSMID@yandex.ru, 8(84639)24347, 2-00-82"
    },
    {
        "col1": "Самарская область",
        "col2": 6,
        "col3": "Администрация городского округа Отрадный Самарской области",
        "col4": "г. Отрадный, ул.Отрадная, д.15, komitet_otr@mail.ru, 8(84661)21275, 25081"
    },
    {
        "col1": "Самарская область",
        "col2": 7,
        "col3": "Отдел по жилищным вопросам комитета по управлению муниципальным имуществом администрации городского округа Жигулевск",
        "col4": "445350, г. Жигулевск, ул. Пушкина, д. 17, Poliynskova.Irina@yandeх.ru; zhilotd@admzhg.ru, 8(84862)2-48-93, 2-42-31"
    },
    {
        "col1": "Самарская область",
        "col2": 8,
        "col3": "Администрации г.о.Октябрьск",
        "col4": "г.Октябрьск, ул.Ленина, 54, oktyabrsk-go@mail.ru, 8(84646)21576"
    },
    {
        "col1": "Самарская область",
        "col2": 9,
        "col3": "Администрация городского округа Кинель Самарской области в лице комитета по управлению муниципальным имуществом городского округа Кинель Самарской области",
        "col4": "г. Кинель, ул. Мира, д.42а, kumikinel@mail.ru, 8(84663) 6-17-78"
    },
    {
        "col1": "Самарская область",
        "col2": 10,
        "col3": "Администрация городского округа Похвистнево Самарской области",
        "col4": "446450, Самарская обл., г. Похвистнево, ул. Лермонтова, 16, pohgor@samtel.ru, 8(84656)25812 8(84656)23466 "
    },
    {
        "col1": "Самарская область",
        "col2": 11,
        "col3": "Муниципальное казенное учреждение \"Комитет по вопросам семьи, материнства и детства муниципального района Алексеевский Самарской области\"",
        "col4": "446640, Самарская область, Алексеевский район, с. Алексеевка, ул. Советская, д. 5, alexkomsemdet@yandex.ru, 88467122597"
    },
    {
        "col1": "Самарская область",
        "col2": 12,
        "col3": "Комитет по управлению муниципальным  имуществом Администрации муниципального района Безенчукский Самарской области",
        "col4": "446250, Самарская область, Безенчукский район, пгт.Безенчук, ул. Нефтяников, д.11, к.25, kumibez@mail.ru, yurotd@alexadm63.ru, 8(84676)23308"
    },
    {
        "col1": "Самарская область",
        "col2": 13,
        "col3": "Комитет по вопросам семьи, материнства и детства муниципального района Богатовский самарской области",
        "col4": "с.Богатое, ул. Комсомольская, д.46, osemya@yandex.ru, 8(84666)2-15-87"
    },
    {
        "col1": "Самарская область",
        "col2": 14,
        "col3": "Администрация муниципального района Большеглушицкий Самарской области",
        "col4": "Самарская область, Большеглушицкий район, с. Большая Глушица, ул. Гагарина, д. 91, bg@admbg.org, 8-84673-21633"
    },
    {
        "col1": "Самарская область",
        "col2": 15,
        "col3": "Муниципальное учреждение  «Управление по вопросам семьи и \nдемографии  муниципального района  Большечерниговский \nСамарской области» (сокр. МУ УСиД)\n",
        "col4": "446290  Самарская область, Большечерниговский район, село Большая Черниговка, улица Полевая д. 77, admsemya@rambler.ru, (84672)2-19-09\n"
    },
    {
        "col1": "Самарская область",
        "col2": 16,
        "col3": "Муниципальное казенное учреждение \"Комитет по управлению муниципальным имуществом администрации муниципального района Борский Самарской области\"",
        "col4": "Самарская область  Борский район с.Борское  ул.Октябрьская, д. 57 , kumi-bor@yandex.ru, 8(84667)1862"
    },
    {
        "col1": "Самарская область",
        "col2": 17,
        "col3": "Администрация муниципального района Волжский Самарской области",
        "col4": "443045, г. Самара, ул. Дыбенко, д. 12 Б, каб. 110, ov@v-adm63.ru, 8 846 260 33 35"
    },
    {
        "col1": "Самарская область",
        "col2": 18,
        "col3": "Комитет по управлению муниципальной собственностью муниципального района Елховский Самарской области",
        "col4": "Самарская область, Елховский район, с. Елховка, ул. М.Заводского, д. 41, kums-elh@yandex.ru, 88465833637"
    },
    {
        "col1": "Самарская область",
        "col2": 19,
        "col3": "Администрация муниципального района Исаклинский Самарской области",
        "col4": "446570, Самарская область, Исаклинский район, с. Исаклы, ул. Куйбышевская, д. 75А, isakadm@yandex.ru, 8 (846) 54 2-16-36"
    },
    {
        "col1": "Самарская область",
        "col2": 20,
        "col3": "Администрация муниципального района Камышлинский",
        "col4": "Самарская область Камышлинский район с. Камышла ул. Победы 80, kumiasiya@mail.ru, 8-846-64 -                     3-36-83"
    },
    {
        "col1": "Самарская область",
        "col2": 21,
        "col3": "ГКУ Самарской области «Комплексный центр социального обслуживания населения Восточного округа» отделение социальной помощи семье и детям муниципального района Кинельский",
        "col4": "446431, Самарская область, г. Кинель,  Деповская ул., 28, centr-semjya@yandex.ru, 8 (84663) 21654  8 (84663) 21486"
    },
    {
        "col1": "Самарская область",
        "col2": 22,
        "col3": "Отдел по жилищной политике комитета по управлению имуществом Кинель-Черкасского района",
        "col4": "446350, Самарская область, Кинель-Черкасский район, с. Кинель-Черкассы, ул. Красноармейская,69., jil_otdel@bk.ru, 88466041175"
    },
    {
        "col1": "Самарская область",
        "col2": 23,
        "col3": "Отдел по вопросам семьи, опеки, попечительства и демографического развития МКУ \"Управление делами\" муниципального района Клявлинский Самарской области",
        "col4": "446960, Самарская область, Клявлинский район, ж.ст. Клявлино, ул. 70 лет Октября, д. 33, muusklv@yandex.ru, 8 846 53 2-18-80;                    8 846 53 2-16-49;"
    },
    {
        "col1": "Самарская область",
        "col2": 24,
        "col3": "МКУ Отдел по делам семьи, охране прав материнства и детства Администрации муниципального района Кошкинский Самарской области",
        "col4": "Самарская обл., Кошкинский р-н, с. Кошки, ул. Первомайская, д. 16, otsem@mail.ru, 8(84650)2-25-77"
    },
    {
        "col1": "Самарская область",
        "col2": 25,
        "col3": "Комитет по управлению муниципальным имуществом Красноармейского района Самарской области",
        "col4": "446140,Самарская область, Красноармейский район, с. Красноармейское, пл. Центральная,12, KUMIKR@yandex.ru, 8 846 75 21095"
    },
    {
        "col1": "Самарская область",
        "col2": 26,
        "col3": "Управление опеки и попечительства администрации муниципального района Красноярский Самарской области",
        "col4": "Самарская область, Красноярский район, с.Красный Яр, ул. Комсомольская , д. 92 А,  semya.kryar@yandex.ru, 8(84657) 2-18-50"
    },
    {
        "col1": "Самарская область",
        "col2": 27,
        "col3": "Администрация муниципального района Нефтегорский",
        "col4": "446600 Самарская область, г. Нефтегорск, ул. Ленина, 2, neftadm@yandex,ru, (84670)21543"
    },
    {
        "col1": "Самарская область",
        "col2": 28,
        "col3": "Администрация муниципального района Пестравский Самарской области",
        "col4": "Самарская область, Пестравский район, село Пестравка, ул. Крайнюковская, д. 84, yapestravsky@yandex.ru, 8(84674) 21233"
    },
    {
        "col1": "Самарская область",
        "col2": 29,
        "col3": "Администрация муниципального района Похвистневский Самарской области",
        "col4": "Самарская область,        г. Похвистнево,                ул. Ленинградская,               д. 9, pohr_kumi@mail.ru, 8(84656)2-12-48, 8(84656)2-28-71"
    },
    {
        "col1": "Самарская область",
        "col2": 30,
        "col3": "Комитет по вопросам семьи, материнства и детства админстрации муниципального района Приволжский Самарской области ",
        "col4": "Самарская область, Приволжский район, с. Приволжье, пер. Специалистов, 12 , privksmd2013@yandex.ru, 8(846 47)92285  8(846 47)92556"
    },
    {
        "col1": "Самарская область",
        "col2": 31,
        "col3": "Администрация муниципального района Сергиевский (Комиссия по осуществлению контроля за использованием жилых помещений муниципального специализированного жилищного фонда, предоставленных детям-сиротам, детям, оставшимся без попечения родителей и лицам из числа детей-сирот и детей, оставшихся без попечения родителей, и (или) распоряжением такими жилыми помещениями)",
        "col4": "Самарская область, Сергиевский район, с.Сергиевск, ул. Ленина, д.87А, opeka-sergievsk@yandex.ru, 8(84655)24209"
    },
    {
        "col1": "Самарская область",
        "col2": 32,
        "col3": "МКУ \"Управление по вопросам семьи,опеки и попечительства муниципального района Ставропольский Самарской области\"",
        "col4": " 445011, РФ, Самарская обл., г. Тольятти, Площадь Свободы, д. 9, opeka-stavr@list.ru, 8 (8482) 28-35-13"
    },
    {
        "col1": "Самарская область",
        "col2": 33,
        "col3": "МКУ \"Управление социального развития администрации Сызранского района\"",
        "col4": "Самарская обл., Сызранский район, п. Варламово, ул. Советская, д. 14, ussofis.varlamovo@yandex.ru , 8(8464)998256"
    },
    {
        "col1": "Самарская область",
        "col2": 34,
        "col3": "Администрация муниципального района Хворостянский",
        "col4": "Самарская область, Хворостянский район, с. Хворостянка, пл. Плясункова, д. 10, 88467791400@mail.ru, 8(84677)9-14-00 приемная    "
    },
    {
        "col1": "Самарская область",
        "col2": 35,
        "col3": "Администрация муниципального района Челно-Вершинский Самарской области",
        "col4": "446840, Самарская область, Челно-Вершинский район, с. Челно-Вершины, ул. Почтовая, д. 8, admver@mail.ru, 88465121758"
    },
    {
        "col1": "Самарская область",
        "col2": 36,
        "col3": "Комитет по управлению имуществом, архитектуры, капитального строительства и жилищно-коммунального хозяйства администрации муниципального района Шенталинский",
        "col4": "446910, Самарская область, Шенталинский район, ж/д ст. Шентала, ул. Советская, д.33, ivanov@shentala.su, 8 (846)52-2-15-36"
    },
    {
        "col1": "Самарская область",
        "col2": 37,
        "col3": "Комитет по управлению муниципальным имуществом муниципального района Шигонский",
        "col4": "Самарская область, с. Шигоны, пл. Ленина 1, kumi21943@mail.ru, 8 (84648)21576, 8 (84648)21943"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 1,
        "col3": "Администрация Адмиралтейского района Санкт-Петербурга",
        "col4": "Измайловский пр., д. 10,\nСанкт-Петербург, 190005\nhouse2@tuadm.gov.spb.ru\n(812) 576-83-89"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 2,
        "col3": "Администрация Василеостровского\nрайона Санкт-Петербурга\n",
        "col4": "Большой пр. В.О., д. 55,      \nСанкт-Петербург, 199178,\ntuvo@gov.spb.ru\n(812)576-93-78\n(812)573-93-35"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 3,
        "col3": "Администрация  Выборгского района Санкт-Петербурга ",
        "col4": "пр. Большой Сампсониевский, д.88,\nСанкт-Петербург, 194100\nzhil@tuvyb.gov.spb.ru\n(812) 241-49-85,                  (812) 576-56-09"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 4,
        "col3": "Администрация Калининского района Санкт-Петербурга ",
        "col4": "Арсенальная наб., д.13/1, \nСанкт-Петербург, 195009\ntukalin@gov.spb.ru               (812) 417-47-46, \n(812) 417-47-47"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 5,
        "col3": "Администрация Кировского района Санкт-Петербурга\n",
        "col4": "Стачек пр., д. 18,                           Санкт-Петербург, 198095 tukir@gov.spb.ru                          bordak@tukir.gov.spb.ru\n(812) 252-30-17\n(812) 417-69-04"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 6,
        "col3": "Администрация Колпинского района Санкт-Петербурга",
        "col4": "б-р Победы, д. 1,  г. Колпино,   Санкт-Петербург, 196653\nkaa@tukolp.gov.spb.ru, zia@tukolp.gov.spb.ru, \n(812) 573-92-05,  (812) 573-92-09"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 7,
        "col3": "Администрация Красногвардейского района Санкт-Петербурга",
        "col4": "Среднеохтинский пр., д.50, \nСанкт-Петербург, 195027 \n tukrgv@gov.spb.ru\n(812) 576-87-50"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 8,
        "col3": "Администрация Красносельского района\nСанкт-Петербурга",
        "col4": "ул. Партизана Германа, д. 3, Санкт-Петербург, 198329\nsevastvanova@tukrsl. gov.spb.ru\n(812) 576-14-38"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 9,
        "col3": "Администрация Кронштадтского района\nСанкт-Петербурга",
        "col4": "пр. Ленина, д. 36,  г. Кронштадт,  Санкт-Петербург, 197760    Zhil1@tukrns.gov.spb.ru   zhiln@tukrns.gov.spb.ru       (812) 576-90-07   (812) 576-90-28"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 10,
        "col3": "Администрация  Курортного района  Санкт-Петербурга ",
        "col4": "пл.Свободы, д.1, г.Сестрорецк,                   Санкт-Петербург, 197706    tukur@gov.spb.ru                (812)576-81-06 "
    },
    {
        "col1": "Санкт Петербург",
        "col2": 11,
        "col3": "Администрация Московского района Санкт-Петербурга",
        "col4": "Московский пр., д.129,       Санкт-Петербург, 196006 \ndilyara@tumos.gov.spb.ru\n(812) 576-89-01"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 12,
        "col3": "Администрация Невского района Санкт-Петербурга",
        "col4": "пр. Обуховской\nОбороны, д. 163,                 Санкт-Петербург, 192131\nflat@tunev.gov.spb.ru\n(812) 417-39-62                    (812) 417-39-64"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 13,
        "col3": "Администрация Петроградского района Санкт-Петербурга, ",
        "col4": "ул. Большая Монетная, д. 17-19, Санкт-Петербург, 197101\ntupetr@gov.spb.ru \n(812) 576-50-54  \n (812) 576-50-39"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 14,
        "col3": "Администрация Петродворцового района Санкт-Петербурга",
        "col4": "ул. Калининская, д.7,          г. Петергоф,                        Санкт-Петербург, 198510 \ntuptrdv@gov.spb.ru\n(812) 576-95-61                   (812) 576-95-65 "
    },
    {
        "col1": "Санкт Петербург",
        "col2": 15,
        "col3": "Администрация Приморского района Санкт-Петербурга",
        "col4": "аллея Котельникова, д.2а,                  Санкт-Петербург, 197341  \nrozanov@tuprim.gov.spb.ru\n(812)241-56-32"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 16,
        "col3": "Администрация Пушкинского района Санкт-Петербурга, \n",
        "col4": "Октябрьский бул., д. 24,      г. Пушкин,                      Санкт-Петербург, 196601\nios@tupush.gov.spb.ru\njo@tupush.gov.spb.ru\n(812) 576-92-47  (812) 576-92-15"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 17,
        "col3": "Аадминистрация Фрунзенского района Санкт-Петербурга",
        "col4": "ул. Пражская, д. 46,            Санкт-Петербург, 192241 \nisakova@tufruns.gov.spb.ru\n(812) 576-85-53"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 18,
        "col3": "Администрация Центрального района Санкт-Петербурга",
        "col4": " Невский пр., д. 176,           Санкт-Петербург, 191167,\nmishin@tucentr.gov.spb.ru   (812) 417-45-93"
    },
    {
        "col1": "Саратовская область",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты Саратовской области",
        "col4": "410005, г. Саратов, ул. Большая Горная, 314/320, social@saratov.gov.ru\n(8452) 65-39-26"
    },
    {
        "col1": "Сахалинская область",
        "col2": 1,
        "col3": "Управление социальной политики Александровск-Сахалинского муниципального округа",
        "col4": "694420, Сахалинская область, г. Александровск-Сахалинский, ул. Ленина, д. 4 8(42434)43455, opeka_Alex@bk.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 2,
        "col3": "Администрация Анивского муниципального округа",
        "col4": "694030, Сахалинская область, Анивский район, г. Анива, ул. Калинина, д. 57, тел.: 8 (42441) 42059,   e-mail: aniva@sakhalin.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства администрации муниципального образования Долинский муниципальный округ",
        "col4": "694051, Сахалинская область, г. Долинск, ул. Комсомольская, д. 37, e-mail: dolinsk@sakhalin.gov.ru, тел.: 8 (42442) 2-68-22\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 4,
        "col3": "Отдел опеки и попечительства департамента социального развития администрации \nКорсаковского муниципального округа\n",
        "col4": "694020, Сахалинская область, г. Корсаков, ул. Советская, д. 41,  e-mail: opeka_akgo@sakhalin.gov.ru, тел.: 8 (42435) 4-05-41 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 5,
        "col3": "Администрация Курильского муниципального округа",
        "col4": "694530, Сахалинская область, г. Курильск, ул. Приморское шоссе, д. 5/1,\nтел.: (42454) 42467, e-mail: kurilsk@sakhalin.gov.ru \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 6,
        "col3": "Администрация Макаровского муниципального образования ",
        "col4": "694140, Сахалинская обл., г. Макаров, ул. 50 лет Октября, д. 32, тел.: 8 (42443) 9-01-48, e-mail:  makarov@sakhalin.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 7,
        "col3": "Отдел образования администрации Невельского муниципального округа",
        "col4": "694740, Сахалинская область, г. Невельск, ул. Советская, д. 55, тел.: 8 (42436) 6-10-18, 8 (42436) 6-02-45, e-mail: y.petrova@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 8,
        "col3": "Департамент социальной политики администрации муниципального образования Ногликский муниципальный округ ",
        "col4": "694450, Сахалинская область, пгт. Ноглики, ул. Советская, д. 15, e-mail: opeka@nogliki-adm.ru, тел.: 8 (42444) 9-10-58  \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 9,
        "col3": "Департамент социального развития администрации Охинского муниципального округа \n",
        "col4": "694490, Сахалинская область, г. Оха, ул. Ленина, д. 8, e-mail: uprobrsek@sakhalin.gov.ru, okhaopeka@mail.ru, тел.: 8 (42437) 3-06-14\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 10,
        "col3": "Отдел опеки и попечительства Администрации Поронайского муниципального округа ",
        "col4": "694240, Сахалинская область, г. Поронайск, ул. Октябрьская, д. 61А,  e-mail: poronaisk_opeka@mail.ru, тел.: 8 (42431) 5-60-01 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 11,
        "col3": "Отдел образования,  социальной  и молодежной политики, культуры и спорта администрации Северо-Курильского муниципального округа",
        "col4": "694550, Сахалинская область, г. Северо-Курильск, ул. Сахалинская, д. 34 А, e-mail: Opeka-skgo@yandex.ru, тел.: 8 (42453) 4-22-13 \n\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 12,
        "col3": "Управление образования администрации Смирныховского муниципального округа ",
        "col4": "694350, Сахалинская область, пгт. Смирных, ул. Маяковского, д. 7, тел.: 8 (42452) 42-8-68, e-mail: opeka_smirnih@mail.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 13,
        "col3": "Отдел образования Томаринского муниципального округа",
        "col4": "694820, Сахалинская область, г. Томари, ул. Новая, д. 1, e-mail: ronotomari@mail.ru, тел.: 8 (42446) 2-61-41\n\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 14,
        "col3": "Управление образования Тымовского муниципального округа ",
        "col4": "694400, Сахалинская область,Тымовский район, пгт. Тымовское, ул. Парковая, д. 9, \ne-mail: tymovsk-uo@sakhalin.gov.ru, тел.: 8 (42447) 9-10-77"
    },
    {
        "col1": "Сахалинская область",
        "col2": 15,
        "col3": "Управление образования Углегорского муниципального округа \n\n",
        "col4": "694920, Сахалинская область, г. Углегорск, ул. Победы, д. 142, тел.: (42432) 44-5-16, e-mail: ugo.uougo@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 16,
        "col3": "Администрация Холмского муниципального округа ",
        "col4": "694620, Сахалинская область, г. Холмск, ул. Советская, д. 66, e-mail: kholmsk.oop@sakhalih.gov.ru  \nтел.: 8(42433)2-16-89 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 17,
        "col3": "Отдел образования Южно-Курильского муниципального округа",
        "col4": "694500, Сахалинская область, пгт. Южно-Курильск, пл. Ленина, д. 1, e-mail: yk.obrazov@sakhalin.gov.ru, тел.: 8 (42455) 21-074, 8 (42455) 21-345\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 18,
        "col3": "Департамент образования администрации города Южно-Сахалинска\n\n",
        "col4": "693000, г. Южно-Сахалинск, ул. Амурская, д. 88, e-mail: yusgo.do@sakhalin.gov.ru, тел.: 8 (4242) 3-12-680"
    },
    {
        "col1": "Свердловская область",
        "col2": 1,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 1 (на территории муниципального округа муниципального образования Алапаевское Свердловской области, Муниципального округа Муниципального образования город Алапаевск Свердловской области, муниципального округа Махнёвское муниципальное образование Свердловской области) \n(далее – Управление социальной политики № 1)",
        "col4": "624600, г. Алапаевск, ул. Береговая, 44 / tusp01@egov66.ru / 8 (34346) 2-61-68"
    },
    {
        "col1": "Свердловская область",
        "col2": 2,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 2 (на территории Режевского муниципального округа Свердловской области, Артемовского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 2)",
        "col4": "623750, г. Реж, ул. Бажова, 15, оф. 16 / tusp02@egov66.ru / 8 (34364) 3-52-31"
    },
    {
        "col1": "Свердловская область",
        "col2": 3,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 3 (на территории городского округа Красноуфимск Свердловской области, Красноуфимского муниципального округа Свердловской области, Ачитского муниципального округа Свердловской области, Артинского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 3)",
        "col4": "623300, г. Красноуфимск, ул. Берёзовая, 12 / tusp03@egov66.ru / 8 (34394) 5-21-84"
    },
    {
        "col1": "Свердловская область",
        "col2": 4,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 4 (на территории Нижнесергинского муниципального района Свердловской области, Бисертского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 4)",
        "col4": "623090, г. Нижние Серги, ул. Федотова, 17 / tusp04@egov66.ru / 8 (34398) 27-2-06"
    },
    {
        "col1": "Свердловская область",
        "col2": 5,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 5 (на территории муниципального округа Первоуральск Свердловской области, муниципального округ Ревда Свердловской области, Шалинского муниципального округа Свердловской области, муниципального округа Дегтярск Свердловской области, муниципального округа Староуткинск Свердловской области) \n(далее – Управление социальной политики № 5)",
        "col4": "623100, г. Первоуральск, ул. 1 Мая, 8А / tusp05@egov66.ru / 8 (34396) 4-74-34"
    },
    {
        "col1": "Свердловская область",
        "col2": 6,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 6 (на территории Городского округа «город Ирбит» Свердловской области, муниципального округа Ирбитское муниципальное образование Свердловской области, Байкаловского муниципального района Свердловской области, Слободо–Туринского муниципального района Свердловской области) \n(далее – Управление социальной политики № 6)",
        "col4": "623850, г. Ирбит, ул. Красноармейская, 15 / tusp06@egov66.ru / 8 (34355) 6-41-85"
    },
    {
        "col1": "Свердловская область",
        "col2": 7,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 7 (на территории Тавдинского муниципального округа Свердловской области, Таборинского муниципального района Свердловской области, Туринского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 7)",
        "col4": "623950, г. Тавда, ул. Ленина, 78 А / tusp07@egov66.ru / 8 (34360) 5-26-26"
    },
    {
        "col1": "Свердловская область",
        "col2": 8,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 8 (на территории Камышловского городского округа Свердловской области, Камышловского муниципального района Свердловской области, Пышминского муниципального округа Свердловской области) \n(далее – Управление социальной политики  № 8)",
        "col4": "624860, г. Камышлов, ул. Гагарина, 1-А / tusp08@egov66.ru / 8 (34375) 2-04-60"
    },
    {
        "col1": "Свердловская область",
        "col2": 9,
        "col3": "9) Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 9 (на территории Талицкого муниципального округа Свердловской области, Тугулымского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 9)",
        "col4": "623640, г. Талица, ул. Ленина, д. 71 / tusp09@egov66.ru / 8 (34371) 2-19-78"
    },
    {
        "col1": "Свердловская область",
        "col2": 10,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 10 (на территории Асбестовского муниципального округа Свердловской области, муниципального округа Заречный Свердловской области, Белоярского муниципального округа Свердловской области, городского округа Верхнее Дуброво Свердловской области, городского округа Рефтинский Свердловской области, городского округа ЗАТО Уральский Свердловской области, Малышевского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 10)",
        "col4": "624272, г. Асбест, ул. Московская, 30 / tusp10@egov66.ru / 8 (34365) 2-06-18"
    },
    {
        "col1": "Свердловская область",
        "col2": 11,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 11 (на территории муниципального округа Сухой Лог Свердловской области, муниципального образования муниципального округа Богданович Свердловской области) \n(далее – Управление социальной политики № 11)",
        "col4": "624800, г. Сухой Лог, ул. Юбилейная, 15 / tusp11@egov66.ru / 8 (34373) 4-36-02"
    },
    {
        "col1": "Свердловская область",
        "col2": 12,
        "col3": "12) Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 12 (на территории Каменск–Уральского городского округа Свердловской области, Каменского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 12)",
        "col4": "623406, г. Каменск-Уральский, ул. Строителей, д. 27 / tusp12@egov66.ru / 8 (34393) 5-33-31"
    },
    {
        "col1": "Свердловская область",
        "col2": 13,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 13 (на территории Невьянского муниципального округа Свердловской области, Кировградского муниципального округа Свердловской области, муниципального округа Верхний Тагил Свердловской области) (далее – Управление социальной политики № 13)",
        "col4": "624194, г. Невьянск, ул. Ленина, 20 / tusp13@egov66.ru / 8 (34356) 40-7-40"
    },
    {
        "col1": "Свердловская область",
        "col2": 14,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 14 (на территории Новолялинского муниципального округа Свердловской области, муниципального округа Верхотурский Свердловской области) (далее – Управление социальной политики № 14)",
        "col4": "624400, г. Новая Ляля, ул. Уральская, 2а / tusp14@egov66.ru / 8 (34388) 2-13-79"
    },
    {
        "col1": "Свердловская область",
        "col2": 15,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 15 (на территории муниципального округа Краснотурьинск Свердловской области, Волчанского муниципального округа Свердловской области, муниципального округа Карпинск Свердловской области) (далее – Управление социальной политики № 15)",
        "col4": "624440, г. Краснотурьинск, ул. К. Маркса, 24 / tusp15@egov66.ru / 8 (34384) 6-55-60"
    },
    {
        "col1": "Свердловская область",
        "col2": 16,
        "col3": "16) Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 16 (на территории Кушвинского муниципального округа Свердловской области, муниципального округа Красноуральск Свердловской области, Городского округа Верхняя Тура Свердловской области) \n(далее – Управление социальной политики № 16)",
        "col4": "624300, г. Кушва, ул. Красноармейская, 16 / tusp16@egov66.ru / 8 (34344) 2-57-57"
    },
    {
        "col1": "Свердловская область",
        "col2": 17,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 17 (на территории городского округа «Город Лесной» Свердловской области, Нижнетуринского муниципального округа Свердловской области, Качканарского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 17)",
        "col4": "624200, г. Лесной, ул. К. Маркса, 8 / tusp17@egov66.ru / 8 (34342) 6-87-28"
    },
    {
        "col1": "Свердловская область",
        "col2": 18,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 18 (на территории Североуральского муниципального округа Свердловской области, Ивдельского муниципального округа Свердловской области, муниципального округа Пелым Свердловской области) \n(далее – Управление социальной политики № 18)",
        "col4": "624480, г. Североуральск, ул. Молодёжная, 15 / tusp18@egov66.ru / 8 (34380) 2-79-01"
    },
    {
        "col1": "Свердловская область",
        "col2": 19,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 19 (на территории Серовского муниципального округа Свердловской области, Гаринского муниципального округа Свердловской области, Сосьвинского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 19)",
        "col4": "624992, г. Серов, ул. Победы, 32 / tusp19@egov66.ru / 8 (34385) 7-22-16"
    },
    {
        "col1": "Свердловская область",
        "col2": 20,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 20 (на территории Новоуральского городского округа Свердловской области, городского округа Верх–Нейвинский Свердловской области) \n(далее – Управление социальной политики № 20)",
        "col4": "624130, г. Новоуральск, ул. Гагарина, д. 7А / tusp20@egov66.ru / 8 (34370) 5-40-70"
    },
    {
        "col1": "Свердловская область",
        "col2": 21,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 21 (на территории муниципального округа город Нижний Тагил Свердловской области, муниципального округа Горноуральский Свердловской области) \n(далее – Управление социальной политики № 21)",
        "col4": "622034, г. Нижний Тагил, ул. Карла Маркса, 42 / tusp21@egov66.ru / 8 (3435) 41-92-61"
    },
    {
        "col1": "Свердловская область",
        "col2": 22,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 22 (на территории Верхнесалдинского муниципального округа Свердловской области, городского округа ЗАТО Свободный Свердловской области, муниципального округа Нижняя Салда Свердловской области) \n(далее – Управление социальной политики № 22)",
        "col4": "624760, г. Верхняя Салда, ул. Воронова 6/1 / tusp22@egov66.ru / 8 (34345) 5-25-08"
    },
    {
        "col1": "Свердловская область",
        "col2": 23,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 23 (на территории Орджоникидзевского района городского округа муниципального образования «город Екатеринбург», городского округа Верхняя Пышма Свердловской области, муниципального округа Среднеуральск Свердловской области) (далее – Управление социальной политики № 23)",
        "col4": "620091, г. Екатеринбург, ул. Бабушкина, 22 / tusp23@egov66.ru / 8 (343) 349-38-22"
    },
    {
        "col1": "Свердловская область",
        "col2": 24,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 24 (на территории Кировского района городского округа муниципального образования «город Екатеринбург», Березовского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 24)",
        "col4": "620062, г. Екатеринбург, ул. Генеральская, 6 / tusp24@egov66.ru / 8 (343) 301-66-77"
    },
    {
        "col1": "Свердловская область",
        "col2": 25,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 25 (на территории Чкаловского района городского округа муниципального образования «город Екатеринбург», Сысертского муниципального округа Свердловской области, Полевского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 25)",
        "col4": "620085, г. Екатеринбург, пер. Автомобильный, 3 / tusp25@egov66.ru / 8 (343) 310-28-08"
    },
    {
        "col1": "Свердловская область",
        "col2": 26,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 26 (на территории Верх-Исетского и Железнодорожного районов городского округа муниципального образования «город Екатеринбург»)\n(далее – Управление социальной политики № 26)",
        "col4": "620014, г. Екатеринбург, ул. Хомякова, 14а / tusp26@egov66.ru / 8 (343) 381-85-80"
    },
    {
        "col1": "Свердловская область",
        "col2": 27,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 27 (на территории Ленинского и Октябрьского районов городского округа муниципального образования «город Екатеринбург») \n(далее – Управление социальной политики № 27)",
        "col4": "620014, г. Екатеринбург, ул. Шейнкмана, 22 / tusp27@egov66.ru / 8 (343) 227-85-86"
    },
    {
        "col1": "Севастополь",
        "col2": 1,
        "col3": "Департамент труда и социальной защиты населения города Севастополя",
        "col4": "г. Севастополь, ул. Руднева, 40, +7 (8692) 537058, dtszn@sev.gov.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 1,
        "col3": "Управление образования Администрнациии муниципального образования \"Сафоновский муниципальный округ\" Смоленской области",
        "col4": "215500, Смоленская область, г. Сафоново, ул. Ленина, д. 3  т. 8(48142)4-10-06"
    },
    {
        "col1": "Смоленская область",
        "col2": 2,
        "col3": "Администрация муниципального образования \"Темкинский муниципальный округ\" Смоленской области",
        "col4": "215350, Смоленская область, с.Темкино, ул.Советск4ая, д.27, Temkino_OBR@admin-smolensk.ru,               8(48136)2-14-07"
    },
    {
        "col1": "Смоленская область",
        "col2": 3,
        "col3": "Администрация муниципального образования \"Шумячский муниципальный округ\" Смоленской области",
        "col4": "216410 Смоленская область, п.Шумячи, ул.Школьна,д.1  shumichi@admin-smolensk.ru  4-12-65 "
    },
    {
        "col1": "Смоленская область",
        "col2": 4,
        "col3": "Администрация муниципального образования \"Ельнинский муниципальный округ\" Смоленской области",
        "col4": "216330 Смоленская область, г. Ельня, ул. Советская, д. 23; e-mail admin_elnia@admin-smolensk.ru; тел. (8 481 46) 4-18-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 5,
        "col3": "Администрация муниципального образования \"Новодугинский мунициплаьный округ\" Смоленской области",
        "col4": "215240, Смоленская область, Новодугинский район, с. Новодугино, ул. 30 лет Победы, д. 2     amo-novodug@yandex.ru   (8 481 38)  2-20-31"
    },
    {
        "col1": "Смоленская область",
        "col2": 6,
        "col3": "Администрация муниципального образования \"Сычевский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, г. Сычевка, ул. Пушкина, д.25,\nSychevka.opeka@gmail.com                                   8(48130)4-12-77\n"
    },
    {
        "col1": "Смоленская область",
        "col2": 7,
        "col3": " Администрация муниципального образования \"Велижский муниципальный округ\" Смоленской области",
        "col4": " г.Велиж, пл. Дзержинского, д.7, тел.4-15-33 эл. Почта:velizh@admin-smolensk,ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 8,
        "col3": "Администрация муниципального образования \"Ершичский муниципальный округ\" Смоленской области",
        "col4": "216580, Смоленская область, с. Ершичи, ул. Советская, д.22, тел. 8(48155) 2-16-60, эл.почта <ershadm@yandex.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 9,
        "col3": "Комитет территориального развития, строительства и жилищно-коммунального хозяйства Администрации муниципального образования \"Глинковский муниципальный округ\" Смоленской области",
        "col4": "216320, Смоленская область, Глинковский район, с. Глинка, ул. Ленина, дл. 8, glinka@admin-smolensk.ru 8(481-65) 2-13-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 10,
        "col3": "Управление опеки и попечительства Администрации города Смоленска",
        "col4": "214000, г. Смоленск, ул. Дзержинского, д. 9/ (84812)388930,(84812)383701/ uop@smoladmin.ru,  ssv@smoladmin.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 11,
        "col3": "Администрация муниципального образования \"Угранский муниципальный округ\" Смоленской области",
        "col4": "215430, Смоленская область, село Угра, ул. Ленина, д. 38 ugra@admin-smolensk.ru? 8(48137)4-12-90"
    },
    {
        "col1": "Смоленская область",
        "col2": 12,
        "col3": "Администрация МО \"Духовщинский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, город Духовщина, улица М.Горького, 23, 84816641707, duhovshina.opeka@yandex.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 13,
        "col3": "Администрация муниципального образования \"Хиславичский муниципальный округ\" Смоленской области",
        "col4": "216620, Смоленская область, п. Хиславичи, ул. Советская, д. 23, адрес электронной почты: hislav@admin-smolensk.ru, тел. 8(48140) 2-20-20"
    },
    {
        "col1": "Смоленская область",
        "col2": 14,
        "col3": "Администрация муниципального образования \"Починковский муниципальный округ\" Смоленской области",
        "col4": "216450, Смоленская область,      г. Починок, ул. Советская,            д. 1/potch@admin-smolensk.ru/      8 (48149)4-11-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 15,
        "col3": "Администрация муниципального образования \"Гагаринский муниципальный округ\" Смоленской области",
        "col4": "215010, Смоленская область, г. Гагарин, ул. Советская, д. 8; e-mail:gagarin-komitet@mail.ru, opeka.gagarin@yandex.ru; конт. тел. 8(48135)6-40-61, 6-40-68"
    },
    {
        "col1": "Смоленская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства управления  по образованию и молодежной политике Администрации муниципального образования \"Ярцевский муниципальный округ\" Смоленской области",
        "col4": "8-48143-7-10-30 opeka-yarcevo@mail.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 17,
        "col3": "Администрация МО  \"Демидовский муниципальный округ \" Смоленской области\n ",
        "col4": "216240, Смоленская область, г.Демидов, ул.Коммунистическая, д.10,  https: demidov@admin-smolensk.ru.                     Т.(48147) 4-11-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 18,
        "col3": "Администрация МО «город Десногорск» Смоленской области  ",
        "col4": "г. Десногорск, 2 мкр., стр. 1/ эл. почта: kiizo@yandex.ru/ 8-48153-7-44-60, 848153-7-23-08"
    },
    {
        "col1": "Смоленская область",
        "col2": 19,
        "col3": "Администрация МО \"Монастырщинский муниципальный округ\" Смоленской области",
        "col4": "216130, Смоленская область, п. Монастырщина, ул. 1-я Краснинская, д. 14.                       E-mail: monast@admin-smolensk.ru, т. 8 (48148) 4-18-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 20,
        "col3": "Администрация МО \"Краснинский муниципальный округ",
        "col4": "пгт. Красный,ул. Карла-Маркса, д.16,krasniy@admin-smolensk.ru, 84814541449"
    },
    {
        "col1": "Смоленская область",
        "col2": 21,
        "col3": "Администрация МО \"Дорогобужский муниципальный округ\"",
        "col4": "г. Дорогобуж, ул. Кутузова, д.1 dorobrz67@mail.ru  848144 41756 "
    },
    {
        "col1": "Смоленская область",
        "col2": 22,
        "col3": "Администрация МО \"Вяземский муниципальный округ\" Смоленской области",
        "col4": " 215110,  Смоленская область, г. Вязьма, ул. 25 Октября, д. 21, 8 (48131) 5–25–11, vzm.opeka@yandex.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 23,
        "col3": "Администрации МО \"Смоленский муниципальный округ\" Смоленской области",
        "col4": "тел./факс  8 (4812) 55-53-89\nе-mail: smolray@admin-smolensk.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 24,
        "col3": "Администрация МО \"Кардымовский муниципальный округ\" Смоленской области",
        "col4": "215850, Смоленская область, пгт. Кардымово, ул. Красноармейская, д.  7/ roo@kardymovo.ru/ 8 (48167) 4-21-56"
    },
    {
        "col1": "Смоленская область",
        "col2": 25,
        "col3": "Администрация МО \"Рославльский муниципальный округ\" Смоленской области ",
        "col4": "216500, Смоленская область, г. Рославль, площадь Ленина, д. 1, 8-48134-6-84-52, administrator@roslavl.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 26,
        "col3": "Отдел по образованию Администрации МО  \"Холм-Жирковский муниципальный округ\" Смоленской области",
        "col4": "215650\nСмоленская область, п.г.т.Холм-Жирковский , ул. Нахимовская, д.9\nE-mail: holm_otdel@mail.ru\nтел./факс:  8 (48139) 2-14-67"
    },
    {
        "col1": "Смоленская область",
        "col2": 27,
        "col3": "Отдел образования Администрации муниципального образования Руднянский муниципальный округ Смоленской области",
        "col4": "Смоленская область, г.Рудня, ул.Киреева, д.93; rud_obrazovanie@admin-smolensk.ru; Телефон (факс): (48141) 4-94-86"
    },
    {
        "col1": "Ставропольский край",
        "col2": 1,
        "col3": "Министерство образования Ставропольского края",
        "col4": "355003, г. Ставрополь, ул. Ломоносова, д.3, info@stavminobr.ru (8652) 74-75-50"
    },
    {
        "col1": "Тамбовская область",
        "col2": 1,
        "col3": "Министерство социальной защиты и семейной политики Тамбовской области\n",
        "col4": "392000, Тамбовская область, г. Тамбов, ул. Московская, 27а, email:uprsoc@uszn.tambov.gov.ru, телефон: 8(4752)791602"
    },
    {
        "col1": "Тверская область",
        "col2": 1,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» г Твери",
        "col4": " 170100, г. Тверь, ул. Склизкова, д.48  (4822) 78-20-35\nsotszashitatver@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 2,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Вышневолоцкого городского округа Тверской области",
        "col4": "171164, Тверская область, г.Вышний Волочек, Двор фабрики Пролетарский Авангард , д.26а  (48233) 2-12-34\ncspnvv2017@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 3,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Кимрского муниципального округа Тверской области",
        "col4": "171506, Тверская область, г.Кимры, ул.Кирова, д.18 (48236) 2-28-65\ntosznkimry@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 4,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Ржевского муниципального округа Тверской области",
        "col4": "172390, Тверская область, г. Ржев, ул. Академика Обручева, д. 82 (48232) 3-21-53\ntoszn10@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 5,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» города Торжок и Торжокского района Тверской области",
        "col4": "172002, г. Торжок, пл. Ананьина, д.7 (48251) 9-24-66\ntoszn-torzhok@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 6,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Андреапольского муниципального округа Тверской области",
        "col4": "172800, Тверская область, г.Андреаполь, ул.Гагарина, д.11 (48267) 3-12-84\ntosznandr@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 7,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Бежецкого муниципального округа Тверской области",
        "col4": "171987, Тверская область, г.Бежецк, ул.Большая, д.43 (48231) 2-25-24\ntosznbezh@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 8,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Бельского муниципального округа Тверской области",
        "col4": "172530, Тверская область, г.Белый, ул. Озерная, д.7 (48250) 2-22-94\ntosznbely@yandex.ru "
    },
    {
        "col1": "Тверская область",
        "col2": 9,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Бологовского муниципального округа Тверской области",
        "col4": "171080, Тверская область, г.Бологое, ул.Кирова, д.13 (48238) 2-28-18\ntosznbologoe@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 10,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Весьегонского муниципального округа Тверской области",
        "col4": "171720, Тверская область, г.Весьегонск, ул.Степанова, д.11 (48264) 2-14-63\ntoszn015@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 11,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Жарковского муниципального округа Тверской области",
        "col4": "172460, Тверская область, п. Жарковский, ул. Доватора, д. 16 а (48273) 2-11-88\ntoczn.garki@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 12,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Западнодвинского муниципального округа Тверской области",
        "col4": "172610, Тверская область, г.Западная Двина, ул.Школьная, д.2а  (48265) 2-19-36\ntosznzapd@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 13,
        "col3": " Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Зубцовского муниципального округа Тверской области",
        "col4": "172332, Тверская область, г.Зубцов, пер.Образцова, д.1а  (48262) 2-17-48\nzubcovtoszn@mail.ru "
    },
    {
        "col1": "Тверская область",
        "col2": 14,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Калининского муниципального округа Тверской области",
        "col4": "170100, Тверская область, г.Тверь, наб. реки Лазури, д.3 (4822) 32-07-11\ntosznkalinin@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 15,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Калязинского муниципального округа Тверской области",
        "col4": "171573, Тверская область, г.Калязин, ул.Коминтерна, д.77 (48249) 2-33-19\ncspn.kalyazin@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 16,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Кашинского городского округа Тверской области",
        "col4": "171640, Тверская область, г.Кашин, ул.Карла Маркса, д.1/18  (48234) 2-14-51\ncspnkashin@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 17,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Кесовогорского муниципального округа Тверской области",
        "col4": "171470, Тверская область, пгт. Кесова Гора, ул. Кооперативная, д. 7 (48274) 2-20-09\nraysobeskg@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 18,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Конаковского муниципального округа Тверской области",
        "col4": "171251, Тверская область, г.Конаково, ул.Маяковского, д.7  (48242) 3-27-10\nkoncspn@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 19,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Краснохолмского муниципального округа Тверской области",
        "col4": "171660, Тверская область, Краснохолмский район, г.Красный Холм, пл.Народная, д.24/32 (48237) 2-22-76\ntosznkrasholm@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 20,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Кувшиновского муниципального округа Тверской области",
        "col4": "172110, Тверская область, г.Кувшиново, ул.Советская, д.55 (48257) 4-43-11\ntoszn2015@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 21,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Лесного муниципального округа Тверской области",
        "col4": "171890, Тверская область, Лесной район, с.Лесное, ул.Дзержинского, д.30а (48271) 2-10-06\ntoszn_lesn@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 22,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Лихославльского муниципального округа Тверской области",
        "col4": "171210, Тверская область, г.Лихославль, ул.Советская, д.41а (48261) 3-54-31\ntosznlihoslavl@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 23,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Максатихинского муниципального округа Тверской области",
        "col4": "171900, Тверская область, пгт. Максатиха, ул. Железнодорожная, д.1 Б. (48253) 2-25-57\ntoszn-max@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 24,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Молоковского муниципального округа Тверской области",
        "col4": "171680, Тверская область, п.Молоково, ул.Ленина, д.16 (48275) 2-13-78\ntoszn-molk@yandex.ru "
    },
    {
        "col1": "Тверская область",
        "col2": 25,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Нелидовского городского округа Тверской области",
        "col4": "172527, Тверская область, г.Нелидово, пр.Ленина, д.1/10 (48266) 5-10-22 nel_toszn@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 26,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Оленинского муниципального округа Тверской области",
        "col4": " 172400, Тверская область, п.Оленино, ул.Октябрьская, д.7  (48258) 2-23-70\noszn1olen@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 27,
        "col3": " Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Осташковского городского округа Тверской области",
        "col4": "172735, Тверская область, г.Осташков, пер.Советский, д.3 (48235) 5-43-55\ntoszn-ostashkov@yandex.ru "
    },
    {
        "col1": "Тверская область",
        "col2": 28,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Пеновского муниципального округа Тверской области",
        "col4": "172770, Тверская область, п.Пено, ул.Тарасова, д.11 (48230) 2-36-13\ntosznpeno@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 29,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Рамешковского муниципального округа Тверской области",
        "col4": "171400, Тверская область, пгт.Рамешки, ул.Советская, д.23 (48244) 2-12-59\ntoszn32@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 30,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Сандовского муниципального округа Тверской области",
        "col4": "171750, Тверская область, пгт. Сандово, ул. 50 лет Октября, д. 5  (48272) 2-13-40\ntoszn_sand@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 31,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Селижаровского муниципального округа Тверской области",
        "col4": "172200, Тверская область, пгт.Селижарово, ул. Ленина, д.12  (48269) 2-21-43\ncspnsel@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 32,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Сонковского муниципального округа Тверской области",
        "col4": "171450, Тверская область, пгт.Сонково, пр-т.Ленина, д.24 (48246) 2-13-50\nsonktoszn@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 33,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Спировского муниципального округа Тверской области",
        "col4": "171170, Тверская область, п.Спирово, ул.Речная, д.4  (48276) 2-20-36\ntosznspirovo@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 34,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Старицкого муниципального округа Тверской области",
        "col4": "171360, Тверская область, г.Старица, ул.Карла Маркса, д.54 (48263) 2-33-54\nStarTOSZN@yandex.ru "
    },
    {
        "col1": "Тверская область",
        "col2": 35,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Торопецкого муниципального округа Тверской области",
        "col4": "172850, Тверская область, г.Торопец, ул.Октябрьская, д.53, (48268) 2-12-45\ntos.tor@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 36,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Удомельского городского округа Тверской области",
        "col4": "171850, Тверская область, г.Удомля, ул.Попова, д.22, (48255) 5-43-55\ncspnud@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 37,
        "col3": "Государственное казенное учреждение Тверской области «Центр социальной поддержки населения» Фировского муниципального округа Тверской области",
        "col4": "172721, Тверская область, пгт.Фирово, ул.Советская, д.37, (48239) 3-18-93\nfirtoszn@mail.ru"
    },
    {
        "col1": "Томская область",
        "col2": 1,
        "col3": "Отдел опеки и попечительства Администрации Александровского района Томской области",
        "col4": "636760, Томская область, Александровский район, \nс. Александровское, ул. Ленина, д. 7\nт. 8 (382 55) 2-46-76\nAlsOpeka@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 2,
        "col3": "Орган опеки и попечительства администрации Асиновского района",
        "col4": "636840, Томская область, г. Асино, ул.  имени Ленина, 40\n/ opecaasino@mail.ru /\n8 (38241) 2-76-00\n"
    },
    {
        "col1": "Томская область",
        "col2": 3,
        "col3": "Отдела опеки и попечительства Администрации Бакчарского района  ",
        "col4": "Томская область, Бакчарский район, с. Бакчар ул. Ленина, \nд. 53 \nтел (838249)22-489\nbakopeka@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 4,
        "col3": "Управление образования Администрации Верхнекетского района (Отдел опеки и попечительства",
        "col4": "636500, Томская область, Верхнекетский район, р. п.Белый Яр, ул.Советская д. 1а стр.2,\n8(38)258-256-52, 8(38)258-25652\nopeka@belyar.tomsknet.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 5,
        "col3": "Отдел опеки и попечительства Администрации Зырянского района",
        "col4": "636850 Томская область, Зырянский район, с.Зырянское, ул.Советская, д.10\n(адрес электронной почты: ziropeka@tomsk.gov.ru; тел. 8(38243) 38142)\n"
    },
    {
        "col1": "Томская область",
        "col2": 6,
        "col3": "Управление образования, опеки и попечительства МО \"Каргасокский район\"",
        "col4": "636700, Томская область, Каргасокский район, с. Каргасок, ул. Октябрьская,97/\nopeka.uooip@mail.ru/\n2-34-75, 2-24-89, 2-33-80\n"
    },
    {
        "col1": "Томская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства Администрации Кожевниковского района",
        "col4": "636160, Томская область, Кожевниковский район, с. Кожевниково, л. Гагарина, д.17\n otdel_opeky@kozhevnikovo.gov70.ru \n(838244)22-715, (838244) 23-191\n"
    },
    {
        "col1": "Томская область",
        "col2": 8,
        "col3": "Колпашевское городское поселение",
        "col4": "636460, Томская область, Колпашевский район, г. Колпашево, ул. Победы, д. 5\nkolpash@tomsk.gov.ru\n8 (38254) 5 82 62\n"
    },
    {
        "col1": "Томская область",
        "col2": 9,
        "col3": "Инкинское сельское поселение",
        "col4": "636443,Томская область, Колпашевский район,  д. Инкино, пер. Кооперативный 11\ninkino@tomsk.gov.ru\n8 (38254) 9-31-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 10,
        "col3": "Новогоренское сельское поселение",
        "col4": "636444, Томская область, Еолпашевский район, д. Новогорное, ул. Береговая 42, n-gorins@tomsk.gov.ru \n8 (38254) 9 41 36\n"
    },
    {
        "col1": "Томская область",
        "col2": 11,
        "col3": "Новоселовское сельское поселение",
        "col4": "636425, Томская область, Колпашевский район,  с. Новоселово, ул. Центральная 11/1\nn-selovs@tomsk.gov.ru\n8 (38254) 22 160\n"
    },
    {
        "col1": "Томская область",
        "col2": 12,
        "col3": "Саровское сельское поселение",
        "col4": "636431, Томская область, Колпашевский район, п. Большая Саровка, ул. Советская 35/2\nsarovka@tomsk.gov.ru\n8 (38254) 2-74-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 13,
        "col3": "Чажемтовское сельское поселение",
        "col4": "636423,Томская область, Колпашевский район,  с. Чажемто, ул. Ленина 24\nchagemto@tomsk.gov.ru, 8 (38254) 2 12 42\n"
    },
    {
        "col1": "Томская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства Администрации Кривошеинского района",
        "col4": "636300, Томская область, Кривошеинский район, с. Кривошеино, ул. Ленина. 26, kr-kov@tomsk.gov.ru, 8(38251) 2-14-16"
    },
    {
        "col1": "Томская область",
        "col2": 15,
        "col3": "Администрация Молчановского района",
        "col4": "636330, Томская область Молчановский район, с. Молчаново, ул. Димитрова, 25, т. +7 382 562-32-48, ml-priem@tomsk.gov.ru"
    },
    {
        "col1": "Томская область",
        "col2": 16,
        "col3": "МКУ Отдел образования Администрации Парабельского района",
        "col4": "636601, Томская область, \nПарабельский район,\nс. Парабель ул. Советская, 26/8(38252)2-14-95/\nopekaparabel@yandex.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 17,
        "col3": "Администрация Первомайского района",
        "col4": "636930, Томская область, Первомайский район, с. Первомайское, ул. Коммунистическая, 2, 8(38245) 22-730, otdel.opeki.00@mail.ru"
    },
    {
        "col1": "Томская область",
        "col2": 18,
        "col3": "Администрация Тегульдетского района",
        "col4": "636900, Томская область, Тегульдетский район, с. Тегульдет, ул. Ленина, 97,\nТелефон: +7 (38246) 2-16-42\nE-mail: tgdadm@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 19,
        "col3": "Администрация Томского района",
        "col4": "634050, Томская область, г. Томск, ул. Карла Маркса, 56/ адрес электронной\nпочты: fap@atr.tomsk.gov.ru/\nномер телефона 58-91-77\n"
    },
    {
        "col1": "Томская область",
        "col2": 20,
        "col3": "Управление образования Администрации Чаинского района",
        "col4": "Томская область, Чаинский район, с. Подгорное, ул. Ленинская, 11/ chainopeka@tomsk.gov.ru/ 8(38257) 23163"
    },
    {
        "col1": "Томская область",
        "col2": 21,
        "col3": "МКУ «Администрация Шегарского района»\nОтдел опеки и попечительства\n",
        "col4": "636130, Томская область, Шегарский район,\nс. Мельниково, ул. Калинина, д.51\nshgadm@tomsk.gov.ru\nopsheg@list.ru\n8(38247) 2-18-33\n8(38247) 2-39-86\n"
    },
    {
        "col1": "Томская область",
        "col2": 22,
        "col3": "Отдел опеки и попечительства Администрации города Кедрового",
        "col4": " 636615, Томская область,  г. Кедровый, 1-й мкр. ул., д.39/1,\nkedropeka@tomsk.gov.ru\n(38250)35-314\n"
    },
    {
        "col1": "Томская область",
        "col2": 23,
        "col3": "Администрация ЗАТО Северск",
        "col4": "636070, Томская область, г. Северск, пр. Коммунистический, 51\n8 (382-3) 77-23-23\nzato-seversk@gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 24,
        "col3": "Администрация городского округа Стрежевой Отдел жилищной политики",
        "col4": "636785, Томская область, г. Стрежевой, ул. Ермакова, д,46А,                                               8 (38259) 51-560,                                     Koroleva@admstrj.romsk.ru"
    },
    {
        "col1": "Томская область",
        "col2": 25,
        "col3": "Администрация Кировского района",
        "col4": "634034, г. Томск, пр. Кирова, д. 11а\nmail@aks.admin.tomsk.ru\n+7 3822 56-40-95\n+7 3822 56-43-22\n"
    },
    {
        "col1": "Томская область",
        "col2": 26,
        "col3": "Администрация Ленинского района",
        "col4": "634009, г. Томск, ул. Карла Маркса, д. 34\nalradmin@alr.admin.tomsk.ru\n+7 3822 51-71-13\n+7 3822 51-71-43\n"
    },
    {
        "col1": "Томская область",
        "col2": 27,
        "col3": "Администрация Октябрьского района",
        "col4": "634003, г. Томск, ул. Пушкина, д. 17\nalomail@alo.admin.tomsk.ru\n+7 3822 65-94-93\n+7 3822 65-94-49\n"
    },
    {
        "col1": "Томская область",
        "col2": 28,
        "col3": "Администрация Советского района",
        "col4": "634012, г. Томск, ул. Елизаровых ,59\nkans@asr.admin.tomsk.ru\n+7 3822 54-45-25\n+7 3822 54-30-54\n"
    },
    {
        "col1": "Тульская область",
        "col2": 1,
        "col3": "Государственное учреждение Тульской Области \"Управление социальной защиты населения Тульской области\"",
        "col4": "г. Тула. ул. Плеханово, д. 48б, тел. 8 (4872) 40-01-14, tula.szn@tularegion.ru"
    },
    {
        "col1": "Тюменcкая область",
        "col2": 1,
        "col3": "Территориальные подразделения Департамента социального развития Тюменской области",
        "col4": "Территориальные подразделения Департамента социального развития Тюменской области\nhttps://soc.admtyumen.ru/OIGV/dsrto/about/structure/soc_zash.htm "
    },
    {
        "col1": "Тюменcкая область",
        "col2": 2,
        "col3": "Департамент социального развития Тюменской области",
        "col4": "\n625048, Тюменская обл., г. Тюмень, ул. Республики, д. 83а / dsoc@72to.ru / (3452) 50-21-68"
    },
    {
        "col1": "Удмуртская Республика",
        "col2": 1,
        "col3": "Управление социальной защиты населения  Удмуртской Республики  при  Министерстве социальной политики и труда Удмуртской Республики",
        "col4": "426033, Удмуртская Республика, г. Ижевск, ул. 30 Лет Победы, д. 20А; 8(3412) 22-15-90; uszn@msp-to.udmr.ru"
    },
    {
        "col1": "Ульяновская область\n",
        "col2": 1,
        "col3": "Министерство социального развития            Ульяновской области",
        "col4": "432071, г.Ульяновск, ул.Федерации, д.60            (8422) 44-96-84, доб. 5268, 5210 z.praw@mail.ru"
    },
    {
        "col1": "Хабаровский край",
        "col2": 1,
        "col3": "Министерство социальной защиты Хабаровского края",
        "col4": "г. Хабаровск, ул. Фрунзе, д. 67, 680000. Электронная почта: mszn@khv.gov.ru, Тел.:8 (4212) 40-25-05"
    },
    {
        "col1": "Херсонская область",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты Херсонской области\n",
        "col4": "273000; г. Херсон,                   ул. Потемкинская, 13а/ depsoc@khogov.ru/ +79900340767"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 1,
        "col3": "Департамент социального развития \nХанты-Мансийского автономного округа – Югры\n",
        "col4": "628006, ул. Мира, 5, г. Ханты-Мансийск,\nХанты-Мансийский автономный округ - Югра \nSocprotect@admhmao.ru\nтелефон :8 (3467) 32-20-40 (доб. 3602, 3603, 3696)\n"
    },
    {
        "col1": "Челябинская область",
        "col2": 1,
        "col3": "Управление социальной защиты населения Агаповского муниципального района",
        "col4": "457400, Челябинская область, Агаповский район, с. Агаповка, ул. Рабочая, д. 34, uszn01@minsoc74.ru 83514021671"
    },
    {
        "col1": "Челябинская область",
        "col2": 2,
        "col3": "Управление социальной защиты населения Аргаяшского муниципального района",
        "col4": "456880, Челябинская область, Аргаяшский район, с. Аргаяш, ул. Октябрьская, д. 6, uszn02@minsoc74.ru, opekaargayash@minsoc74.ru, 8 (35131) 2 - 23 - 64"
    },
    {
        "col1": "Челябинская область",
        "col2": 3,
        "col3": "Управление социальной защиты населения администрации Ашинского муниципального района",
        "col4": "456010, Челябинская область, Ашинский муниципальный район, г.Аша, ул.Мира, д. 23, 8(35159)95010, uszn03@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 4,
        "col3": "Управление социальной защиты населения администрации Брединского муниципального района ",
        "col4": "457310, Челябинская область Брединский район п. Бреды ул. Дорожная, д. 19 uszn05@minsoc74.ru Тел: 8(35141)35593 "
    },
    {
        "col1": "Челябинская область",
        "col2": 5,
        "col3": "Управление социальной защиты населения администрации Варненского муниципального района",
        "col4": "457200, Челябинская обл., Варненский район, с. Варна, ул. Советская, д. 135,каб.16 uszn_varna@mail.ru. E-mail: uszn07@minsoc74.ru "
    },
    {
        "col1": "Челябинская область",
        "col2": 6,
        "col3": "Управление социальной защиты населения администрации Верхнеуральского муниципального района",
        "col4": "457670, Челябинская область, Верхнеуральский район, г. Верхнеуральск, ул. Советская, д. 17, uszn08@minsoc74.ru 8(35143) 2-23-77"
    },
    {
        "col1": "Челябинская область",
        "col2": 7,
        "col3": "Управление социальной защиты населения Верхнеуфалейского городского округа",
        "col4": "Адрес: 456800, Челябинская область, город Верхний Уфалей, улица Якушева, дом 25.\nТелефон: +7(35164) 3-59-69\ne-mail: uszn09@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 8,
        "col3": "Управление социальной защиты населения администрации Еманжелинского муниципального района",
        "col4": "456580 Челябинская область, г. Еманжелинск, ул. МИра, д. 18, 835138-9-25-48, uszn44@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 9,
        "col3": "Управление социальной защиты населения администрации Еткульского муниципального района",
        "col4": "456560, Челябинская область,Еткульский район,с.Еткуль, ул.Ленина, д.33, uszn10@minsoc74.ru, 8(35145)2-21-43"
    },
    {
        "col1": "Челябинская область",
        "col2": 10,
        "col3": "Управление социальной защиты населения Златоустовского городского округа",
        "col4": "457219, Челябинская область, г. Златоуст, пр. им. Ю.А.Гагарина, 3 линия, дом 6, uszn12@minsoc74.ru 8 3513 65 40 44"
    },
    {
        "col1": "Челябинская область",
        "col2": 11,
        "col3": "Управление социальной защиты населения администрации Карабашского городского округа",
        "col4": "456143, Челябинская область, г. Карабаш, ул. Розы Люксембург, д. 2 8(35153)2-34-98 uszn13@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 12,
        "col3": "Управление социальной защиты населения Карталинского муниципального района",
        "col4": "457351, Челябинская область, г. Карталы, ул. Калмыкова, дом 4, uszn60@minsoc74.ru 8 351 33 5 60 02"
    },
    {
        "col1": "Челябинская область",
        "col2": 13,
        "col3": "Управление социальной защиты населения администрации Каслинского муниципального района",
        "col4": "456835, Челябинская область, г. Касли, ул. Стадионная, д. 89, пом. 1 8(3514923972) uszn15@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 14,
        "col3": "Управление социальной защиты населения Администрации Катав-Ивановского муниципального района",
        "col4": "456110, Челябинская область, г. Катав-Ивановск, ул. Гагарина, дом 4, uszn15@minsoc74.ru 8 35147 97-1-97 (доб. 2)"
    },
    {
        "col1": "Челябинская область",
        "col2": 15,
        "col3": "Управление социальной защиты населения Кизильского мунипального района",
        "col4": "457610, Челябинская область, Кизильский район, с. Кизильское, ул. Советская, д. 65в, uszn16@minsoc74.ru, 8(35155)3-04-06"
    },
    {
        "col1": "Челябинская область",
        "col2": 16,
        "col3": "Управление социальной защиты населения администрации Копейского городского округа Челябинской области",
        "col4": "456618, Челябинская область, г. Копейск, ул. Ленина, д. 61, (351) 393-64-64 uszn06@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 17,
        "col3": "Управление социальной защиты населения Коркинского муниципального округа",
        "col4": "456550, Челябинская область, г. Коркино, проспект Горняков, дом 16"
    },
    {
        "col1": "Челябинская область",
        "col2": 18,
        "col3": "Управление социальной защиты населения администрации Красноармейского муниципального района Челябинской области",
        "col4": "456660, Челябинская область, Красноармейский райо, с. Миасское, ул. Спортивная, д. 8а, тел. 8 351 729-98-83, uszn25_opek@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 19,
        "col3": "Управление социальной защиты населения администрации Кунашакского муниципального района Челябинской области",
        "col4": "456730, Челябинская область, Кунашакский район, с. Кунашак, ул. Больничная, д. 2 а, uszn19@minsoc74.ru, тел. 8(35148) 2-50-45"
    },
    {
        "col1": "Челябинская область",
        "col2": 20,
        "col3": "Управление социальной защиты населения Кусинского муниципального района",
        "col4": "455044, Челябинская область, г. Куса, ул. Ленина, д. 8"
    },
    {
        "col1": "Челябинская область",
        "col2": 21,
        "col3": "Управление социальной защиты населения администрации Локомотивного городского округа",
        "col4": "456870, Челябинская область, г. Кыштым, ул. Калинина, д. 156, 8-35151-40450 uszn22@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 22,
        "col3": "Управление социальной защиты населения администрации Локомотивного городского округа",
        "col4": "457390, Челябинская область, п.Локомотивный, ул. Мира, д. 60, 8-35133-567-78, uszn95@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 23,
        "col3": "Управление социальной защиты населения администрации города Магнитогорска",
        "col4": "455044, Челябинская область, г. Магнитогорск, пр. Ленина, 72/uszn-mag@magnitogorsk.ru, uszn91@minsoc74.ru/8 (3519) 26-03-24"
    },
    {
        "col1": "Челябинская область",
        "col2": 24,
        "col3": "Управление социальной защиты населения Администрации Миасского городского округа Челябинской области",
        "col4": "456320, Челябинская область, г. Миасс, пр.Макеева, д.8а, uszn24@minsoc74.ru, 8(3513)52-76-03"
    },
    {
        "col1": "Челябинская область",
        "col2": 25,
        "col3": "Управление социальной защиты населения Нагайбакского муниципального района",
        "col4": "457650, Челябинская область, гс. Фершампенуаз, ул. Труда 64/1, uszn34@mincos74,ru 8 (3519) 22149, 8(35157)22261"
    },
    {
        "col1": "Челябинская область",
        "col2": 26,
        "col3": "Управление социальной защиты населения Нязепетровского муниципального округа",
        "col4": "456970, Челябинская область, г. Нязепетровск, ул. Свердлова, д.74 А. uszn27_opeka@minsoc74.ru 8(35156)32026"
    },
    {
        "col1": "Челябинская область",
        "col2": 27,
        "col3": "Управление социальной защиты населения администрации Озерского городского округа",
        "col4": "456780, Челябинская область, г. Озерск, ул. Космонавтов, д. 20, 8 (35130) 5-55-65, ksz@ozerskadm,ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 28,
        "col3": "Управление социальной защиты населения Октябрьского муниципального района",
        "col4": "457170 Челябинская область, Октябрьский район, с. октябрьское, ул. Тельмана 11, помещение 1. 8(351 58)5-30-48, uszn28@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 29,
        "col3": "Управление социальной защиты населения Пластовского муниципального округа",
        "col4": "457020, Челябинская область, г. Пласт, ул. Чайковского, 1а, uszn29@mincos74.ru 8 (351)-60-2-13-53; 8 (351) -60-2-07-37"
    },
    {
        "col1": "Челябинская область",
        "col2": 30,
        "col3": "Управление социальной защиты населения администрации Саткинского муниципального округа",
        "col4": "456910, Челябинская область, г. Сатка, ул. Индустриальная, д.20, uszn31@minsoc74.ru, 8(35161)41168"
    },
    {
        "col1": "Челябинская область",
        "col2": 31,
        "col3": "Муниципальное казённое учреждение \"Управление социальной защиты населения города Снежинска\"",
        "col4": "456770, Челябинская область, г.Снежинск, ул. Транспортная, д. № 5, uszn93@minsoc74.ru, 8 (35146) 2-55-68"
    },
    {
        "col1": "Челябинская область",
        "col2": 32,
        "col3": "Управление социальной защиты населения администрации Сосновского муниципального района Челябинской области",
        "col4": "456510 Челябинская область, Сосновский район, с. Долгодеревенское, ул. Свердловская, д. 2В, н.п. 7 8(35144)45-300"
    },
    {
        "col1": "Челябинская область",
        "col2": 33,
        "col3": "Управление социальной защиты населения администрации города Трехгорного",
        "col4": "45680, Челябинская область, город Трехгорный, ул.Карла маркса, дом 45, uszn94@minsoc74,ru, 8(351)916-09-89"
    },
    {
        "col1": "Челябинская область",
        "col2": 34,
        "col3": "Управление социальной защиты населения администрации города Троицка Челябинской области",
        "col4": "457100, Челябинскаобласть, г. Троицк, ул. Фрунзе, д. 2, uszn32@minsoc74.ru, 83516322724"
    },
    {
        "col1": "Челябинская область",
        "col2": 35,
        "col3": "Управление социальной защиты населения Троицкого муниципального района",
        "col4": "457100, Челябинская область, г. Троицк, ул. 30 лет ВЛКСМ, д.12 uszn61@minsoc74.ru, 8(35163)2-09-72"
    },
    {
        "col1": "Челябинская область",
        "col2": 36,
        "col3": "Отдел жилищной политики администрации Увельского муниципального района",
        "col4": "457000, Челябинская область, Увельский район, п.Увельский, ул. Советская, д.24, otdgilpolitikiuvelka@mail.ru, 8(35166)3-25-89"
    },
    {
        "col1": "Челябинская область",
        "col2": 37,
        "col3": "Управление социальной защиты населения Уйского муниципального района",
        "col4": "456470, Челябинская область, Уйский район, с.Уйское, ул. Дорожников, д.24, uszn33@minsoc74,ru, 8(35165)231-67"
    },
    {
        "col1": "Челябинская область",
        "col2": 38,
        "col3": "Управление социальной защиты населения администрации Усть-Катавского городского округа",
        "col4": "456043, Челябинская область, г. Усть-Катав, ул. Комсомольская, д. 42   \nuszn14@minsoc74.ru\n8 (35167)2-56-32"
    },
    {
        "col1": "Челябинская область",
        "col2": 39,
        "col3": "Управление социальной защиты населения администрации Чебаркульского городского округа",
        "col4": "456440, Челябинская область, г. Чебаркуль, ул. Ленина, д. 46 А, тел 8(351-68)2-15-12, uszn65minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 40,
        "col3": "Управление социальной защиты населения администрации Чебаркульского муниципального района",
        "col4": "456440, Челябинская область,  г.Чебаркуль, улица Ленина, дом 33 а, usznchebray@yandex.ru uszn35@minsoc74.ru тел. 8(3516-68)2-97-26"
    },
    {
        "col1": "Челябинская область",
        "col2": 41,
        "col3": "Калининское управление социальной \nзащиты населения Администрации\n города Челябинска",
        "col4": "454084, Челябинская область, г. Челябинск, ул. Шенкурская, д. 7-б, uszn45@minsoc74.ru,  8(351)727-56-90"
    },
    {
        "col1": "Челябинская область",
        "col2": 42,
        "col3": "Курчатовское управление социальной защиты населения Администрации города Челябинска",
        "col4": "454100, Челябинская область, г. Челябинск, проспект Комсомольский, д. 107-а, uszn43@minsoc74.ru, 8(351)731-51-01"
    },
    {
        "col1": "Челябинская область",
        "col2": 43,
        "col3": "Ленинское управление социальной \nзащиты населения Администрации\n города Челябинска \n",
        "col4": "454078, Челябинская область, \nгород Челябинск, улица Гагарина,\nдом. 42 А, \nэл. почта: uszn49@minsoc.74ru, \n+7 (351) 256 43 16 "
    },
    {
        "col1": "Челябинская область",
        "col2": 44,
        "col3": "Металлургическое управление социальной \nзащиты населения Администрации\n города Челябинска \n",
        "col4": "454047, Челябинская область,                      город Челябинск, улица Дегтярева,             дом 49-Б.                                                             эл. почта: uszn42@minsoc74.ru,                      +7 (351) 735-85-99 "
    },
    {
        "col1": "Челябинская область",
        "col2": 45,
        "col3": "Советское управление социальной защиты населения Администрации  города Челябинска ",
        "col4": "454005, Челябинская область,                      город Челябинск, улица Цвиллинга,             дом 63. эл. почта: uszn41@minsoc74.ru,                      +7 (351) 761 86 24"
    },
    {
        "col1": "Челябинская область",
        "col2": 46,
        "col3": "Тракторозаводское управление социальной защиты населения Администрации города Челябинска",
        "col4": "454007, Челябинская область, город Челябинск, ул. Артиллерийская, д. 109. эл. почта uszn47@minsoc74.ru, тел. 8 (351) 775 52 17"
    },
    {
        "col1": "Челябинская область",
        "col2": 47,
        "col3": "Центральное управление социальной защиты населения Администрации города Челябинска",
        "col4": "454091, Челябиская область, город Челябинск, улица Советская, д. 36, эл. почта: uszn46@minsc74.ru, 8 (351) 263-65-93"
    },
    {
        "col1": "Челябинская область",
        "col2": 48,
        "col3": "Муниципальное управление социальной защиты населения администрации Чесменского муниципального района Челябинской области",
        "col4": "457221 Челябинская оласть, Чесменский район, с. Чесма, ул. Чапаева, д. 42Е, тел 8(35169)2-14-45, эл. адрес: uszn37@minsoc74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 49,
        "col3": "Управление по имуществу и земельным отношениям администрации Южноуральского городского округа",
        "col4": "457040, Челябинская область, г. Южноуральск, ул. Космонавтов, д. 22, эл. почта: komitet_2008@mail.ru тел: 8(35134)44764"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 1,
        "col3": "ГКУ «Отдел труда и социального развития» г. Аргун",
        "col4": "ГКУ «Отдел труда и социального развития» г. Аргун - ЧР, 366310, г. Аргун, \nул. А.А. Кадырова, д. 66 а, otsrargun@mail.ru;\n"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 2,
        "col3": "ГКУ «Отдел труда и социального развития» Ачхой-Мартановского района",
        "col4": "ГКУ «Отдел труда и социального развития» Ачхой-Мартановского района - ЧР, 366612, Ачхой-Мартановский район, с. Ачхой-Мартан, ул. Почтовая, д. 22, otsrachhoy@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 3,
        "col3": "ГКУ «Отдел труда и социального развития» Грозненского района",
        "col4": "ГКУ «Отдел труда и социального развития» Грозненского района - ЧР, 324034, г. Грозный, пр. А-Х. Кадырова, д. 215, otsrgroznen@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 4,
        "col3": "ГКУ «Отдел труда и социального развития» Гудермесского района",
        "col4": "ГКУ «Отдел труда и социального развития» Гудермесского района - ЧР, 366208, ЧР, г. Гудермес, пр. А-Х, Кадырова, д. 59 а, \nтел/факс – 8 (87152) 22329, otsrgudermes@mail.ru;\n"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 5,
        "col3": "ГКУ «Отдел труда и социального развития» Веденского района",
        "col4": "ГКУ «Отдел труда и социального развития» Веденского района - ЧР, 366337, Веденский район, с. Ведено, ул. Орджоникидзе, д. 2, otsrvedeno@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 6,
        "col3": "ГКУ «Отдел труда и социального развития» Итум-Калинского района",
        "col4": "ГКУ «Отдел труда и социального развития» Итум-Калинского района - ЧР, Итум-Калинский район, с. Итум-Кали, ул. Кадырова, д. 36, \notrsitum-kali@mail.ru;\n"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 7,
        "col3": "ГКУ «Отдел труда и социального развития» Курчалоевского района",
        "col4": "ГКУ «Отдел труда и социального развития» Курчалоевского района - ЧР, 366314, Курчалоевский район, с. Курчалой, ул. А-Х. Кадырова, д. 113, otsrkurchaloy@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 8,
        "col3": "ГКУ «Отдел труда и социального развития» Надтеречного района",
        "col4": "ГКУ «Отдел труда и социального развития» Надтеречного района - ЧР,366831, Надтеречный район, с. Знаменское, ул. Московская, д. 10, otsrnadterechiy@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 9,
        "col3": "ГКУ «Отдел труда и социального развития» Наурского района",
        "col4": "ГКУ «Отдел труда и социального развития» Наурского района - ЧР, 366128, Наурский район, ст. Наурская, ул. Октябрьская, д. 41, otsrnaur@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 10,
        "col3": "ГКУ «Отдел труда и социального развития» Ножай-Юртовского района",
        "col4": "ГКУ «Отдел труда и социального развития» Ножай-Юртовского района - ЧР, Ножай-Юртовский район, с. Ножай-Юрт, ул. А.А. Кадырова, д. 74, otsrnoja-yurt1@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 11,
        "col3": "ГКУ «Отдел труда и социального развития» Сунженского района",
        "col4": "ГКУ «Отдел труда и социального развития» Серноводского района - ЧР, 366701, Серноводский р-он, с. Серноводское, ул. А. Кадырова, д. 5б, otsrsunja@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 12,
        "col3": "ГКУ «Отдел труда и социального развития» Шалинского района",
        "col4": "ГКУ «Отдел труда и социального развития» Шалинского района - ЧР, 366324, Шалинский район, г. Шали, ул. Учительская, д. 2, \notsrshali@mail.ru;\n\n"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 13,
        "col3": "ГКУ «Отдел труда и социального развития» Шатойского района",
        "col4": "ГКУ «Отдел труда и социального развития» Шатойского района - ЧР, Шатойский район, с. Шатой, ул. Мадаева, Б/Н, индекс 366400, otsrshatoy@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 14,
        "col3": "ГКУ «Отдел труда и социального развития» Шаройского района",
        "col4": "ГКУ «Отдел труда и социального развития» Шаройского района -\nЧР, 366413, Шаройский район, с. Химой, \nул. Школьная, д. 1,  otsrsharoy@mail.ru;\n"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 15,
        "col3": "ГКУ «Отдел труда и социального развития» Шелковского района",
        "col4": "ГКУ «Отдел труда и социального развития» Шелковского района - ЧР, 366108, Шелковской район, ст. Шелковская, ул. Ленина, д. 27, otsrshelkovskoy@mail.ru;"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 16,
        "col3": "ГКУ «Отдел труда и социального развития» Урус-Мартановского района",
        "col4": "ГКУ «Отдел труда и социального развития» Урус-Мартановского района - ЧР, 36650, Урус-Мартановский р-н, г. Урус-Мартан, \nул. Н. Усамова б\\н, otsrurus-martan@mail.ru\n"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 17,
        "col3": "ГКУ «Отдел труда и социального развития» Ахматовского района г. Грозного",
        "col4": "ГКУ «Отдел труда и социального развития» Ахматовского района г. Грозного - ЧР, 364020, \nг. Грозный, ул. Моздокская, д. 39, otsrleninskiy@mail.ru\n"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 18,
        "col3": "ГКУ «Отдел труда и социального развития» Висаитовского района г. Грозного",
        "col4": "ГКУ «Отдел труда и социального развития» Висаитовского района г. Грозного - ЧР, 364052, г. Грозный, Старопромысловский район, \nг-к. Иванова\notsrstaroprom@mail.ru\n"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 19,
        "col3": "ГКУ «Отдел труда и социального развития» Байсангуровского района г. Грозного",
        "col4": "ГКУ «Отдел труда и социального развития» Байсангуровского района г. Грозного - ЧР, 364038, Байсангуроский р-н, г. Грозный, \nул. М.С. Гуцериева, д. 65, \nbaysangurovsky1@mail.ru\n"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 20,
        "col3": "ГКУ «Отдел труда и социального развития» Шейх-Мансуровского района г. Грозного",
        "col4": "ГКУ «Отдел труда и социального развития» Шейх-Мансуровского района г. Грозного - ЧР, 364068, ЧР, г. Грозный, ул. Индустриальная, д. 2, otsrzavodskoy@mail.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 1,
        "col3": "Алатырский муниципальный округ Чувашской Республики",
        "col4": "429826, Чувашская Республика, г. Алатырь, ул. Ленина, д. 29     8(83531) 2-24-08 alatr.priem@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 2,
        "col3": "Аликовский муниципальный округ Чувашской Республики",
        "col4": "429250, Чувашская Республика, Аликовский район, с. Аликово, \nул. Октябрьская, д. 21 8(83535) 22-3-82 alikov@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 3,
        "col3": "Батыревский муниципальный округ Чувашской Республики",
        "col4": "429350, Чувашская Республика, Батыревский район, с. Батырево, \nпр. Ленина, д. 5 8(83532) 6-24-20 batyr@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 4,
        "col3": "Вурнарский муниципальный округ Чувашской Республики",
        "col4": "429220, Чувашская Республика, Вурнарский район, пгт. Вурнары, \nул. Советская, д. 20 8(83537) 2-51-91 vurnar@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 5,
        "col3": "Ибресинский муниципальный округ Чувашской Республики",
        "col4": "429700, Чувашская Республика, Ибресинский район, п. Ибреси, \nул. Маресьева, д. 49  \n 8(83538) 2-12-52 ibresi@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 6,
        "col3": "Канашский муниципальный округ Чувашской Республики",
        "col4": "429330, г. Канаш, ул.30 лет Победы, д.87  8(83533) 2-24-90 kanash@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 7,
        "col3": "Козловский муниципальный округ Чувашской Республики",
        "col4": "429430, Чувашская Республика, Козловский район, г. Козловка, \nул. Ленина, д. 55  8(83534) 2-23-37 kozlov@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 8,
        "col3": "Комсомольский муниципальный округ Чувашской Республики",
        "col4": "429140, Чувашская Республика, Комсомольский район, \nс. Комсомольское, ул. Заводская, д. 57 8(83539) 5-11-43  komsml@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 9,
        "col3": "Красноармейский муниципальный округ Чувашской Республики",
        "col4": "429620, Чувашская Республика, Красноармейский район, \nс. Красноармейское, ул. Ленина, д. 35 8(83530) 2-18-41 adm.krarm@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 10,
        "col3": "Красночетайский муниципальный округ Чувашской Республики",
        "col4": "429040 Чувашская Республика, с. Красные Четаи, пл. Победы, д. 1 8(83551) 2-16-76 krchet-adm@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 11,
        "col3": "Мариинско-Посадский муниципальный округ Чувашской Республики",
        "col4": "429570, Чувашская Республика, г. Мариинский Посад,  \nул. Николаева, д. 47               8(83542) 2-14-42 marpos@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 12,
        "col3": "Моргаушский муниципальный округ Чувашской Республики",
        "col4": "429530, Чувашская Республика, Моргаушский район, \nс. Моргауши, ул. Мира, д. 6 8(83541) 62-2-39 morgau@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 13,
        "col3": "Порецкий муниципальный округ Чувашской Республики",
        "col4": "429020, Чувашская Республика, Порецкий район, с. Порецкое, \nул. Ленина, д. 3  8(83543) 2-19-53 porezk@cap.ru; porezk_org3@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 14,
        "col3": "Урмарский муниципальный округ Чувашской Республики",
        "col4": "429400, Чувашская Республика, Урмарский район, п. Урмары, \nул. Мира, д. 5  8(83544) 2-10-85 urmary@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 15,
        "col3": "Цивильский муниципальный округ Чувашской Республики",
        "col4": "429900, Чувашская Республика, Цивильский район, г. Цивильск, ул. Маяковского, \nд. 12  8(83545) 21-3-26 zivil@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 16,
        "col3": "Чебоксарский муниципальный округ Чувашской Республики",
        "col4": "429500, Чувашская Республика, Чебоксарский район, п. Кугеси, \nул. Советская, д. 25  8(83540) 2-30-93 chebs@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 17,
        "col3": "Шемуршинский муниципальный округ Чувашской Республики",
        "col4": "429370, Чувашская Республика, Шемуршинский район, \nс. Шемурша, ул. Советская, д. 8  8(83546)-2-31-07 shemur@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 18,
        "col3": "Шумерлинский муниципальный округ Чувашской Республики",
        "col4": "429122, Чувашская Республика, \nг. Шумерля, ул. Октябрьская, \nд. 24  (83536) 2-06-29 gshum@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 19,
        "col3": "Ядринский муниципальный округ Чувашской Республики",
        "col4": "429060, Чувашская Республика, Ядринский район, г. Ядрин, \nул. 30 лет Победы, д. 1   8(83547) 22-0-36 yadrin@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 20,
        "col3": "Яльчикский муниципальный округ Чувашской Республики",
        "col4": "429380, Чувашская Республика, Яльчикский район, с. Яльчики, \nул. Иванова, д. 16   8(83549) 2-56-12 yaltch@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 21,
        "col3": "Янтиковский муниципальный округ Чувашской Республики",
        "col4": "429290 Чувашская Республика, Янтиковский район, с. Янтиково, пр. Ленина, д. 13  (83548) 2-18-60 yantik@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 22,
        "col3": "г. Новочебоксарск Чувашской Республики",
        "col4": "429951 Чувашская Республика, \nг. Новочебоксарск,  \nул. Винокурова, д.14   8(8352) 73-81-24,\n73-33-88 nowch-doc5@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 23,
        "col3": "Адмимнистрация Калининского района г.Чебоксары",
        "col4": "428022, Чувашская Республика, г.Чебоксары, ул. 50 лет Октября, \nд. 10 а  8(8352) 23-44-28, \n23-44-27 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 24,
        "col3": "Администраация Ленинского района г.Чебоксары",
        "col4": "428000, Чувашская Республика, г.Чебоксары, ул. Гагарина, д. 22 а\n8(8352) 23-44-76, \n23-44-75, 23-40-17 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика",
        "col2": 25,
        "col3": "Администрация Московского района г. Чебоксары ",
        "col4": "428017, Чувашская Республика, г. Чебоксары, пр. Московский, \nд. 33 «а»\n8(8352) 23-52-18,\n23-52-33, 23-52-15, 23-52-29  gcheb@cap.ru"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 1,
        "col3": "Департамент социальной политики Чукотского автономного округа",
        "col4": "689000, Чукотский автономный округ, г. Анадырь, ул. Беринга, д. 2, тел. (42722) 6-21-00, info@dsp.chukotka-gov.ru "
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 1,
        "col3": "Департамент образования Администрации города Новый Уренгой",
        "col4": "629300, Ямало-Ненецкий автономный округ, г. Новый Уренгой, ул. Индустриальная, д. 4\n+7(3494)22-16-75\n portal.edunur.ru\nedu@nur.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 2,
        "col3": "Департамент образования Администрации муниципального образования город Ноябрьск",
        "col4": "629807, Ямало-Ненецкий автономный округ, г. Ноябрьск, ул. 8 Марта, д. 6\n+7(3496)42-15-85\n  donoyabrsk.yanao.ru\ndo@noyabrsk.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 3,
        "col3": "Департамент образования Администрации муниципального образования город Салехард​",
        "col4": "629007, Ямало-Ненецкий автономный округ, г. Салехард, ул. Ямальская, д. 30\n+7(34922)3-21-00\nedu.shd.ru\ndo@slh.yanao.ru\n\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 4,
        "col3": "Департамент образования Администрации муниципального образования Надымский район",
        "col4": "629730, Ямало-Ненецкий автономный округ, Надымский район, г. Надым, ул. Зверева, д.12/2\n+7(3499)52-38-24\nnadymedu.ru\ndo@nadym.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 5,
        "col3": "Департамент образования Администрации муниципального образования Ямальский район",
        "col4": "629700, Ямало-Ненецкий автономный округ, Ямальский район, с. Яр-Сале, ул. Худи Сэроко, д.18\n+7(34996)3-08-11 \nдо-ямальский.рф\ndo@yam.yanao.ru"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 6,
        "col3": "Департамент образования Администрации Тазовского района",
        "col4": "629350, Ямало-Ненецкий автономный округ, Тазовский район, п. Тазовский, ул. Пиеттомина, д. 23\n+7(34940)2-11-52\ntaz-edu.ru\ninbox@taz-edu.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 7,
        "col3": "Департамент образования муниципального образования Пуровский район",
        "col4": "629850, Ямало-Ненецкий автономный округ, Пуровский район, г. Тарко-Сале, ул. Республики, д.25\n+7(34997)2-36-11, +7(34997)6-07-10\ndoapr@pur.yanao.ru info@purovskiydo.ru, \n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 8,
        "col3": "Управление образования Администрации города Губкинского",
        "col4": "629830, Ямало-Ненецкий автономный округ, г. Губкинский, 7 микрорайон, д. 2\n+7(34936)3-61-10\n www.uo-gub.ru\nGSSadykova@mogub.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 9,
        "col3": "Управление образования Администрации муниципального образования Красноселькупский район",
        "col4": "629380, Ямало-Ненецкий автономный округ, Красноселькупский район, с. Красноселькуп, ул. Полярная, д.15\n+7(34932)2-14-32\n uoks.ru\nuoks@krasnoselkupsky.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 10,
        "col3": "Управление образования Администрации муниципального образования Приуральский район",
        "col4": "629620, Ямало-Ненецкий автономный округ, Приуральский район, с. Аксарка, пер. Школьный, д.2\n+7(34993)2-22-11\npriurale.ru\nedu@priuralye.сom, edu@priuralye.yanao.ru\n \n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 11,
        "col3": "Управление образования Администрации муниципального образования Шурышкарский район",
        "col4": "629640, Ямало-Ненецкий автономный округ, Шурышкарский район, с. Мужи, ул. Истомина, д. 9А\n+7(34994)2-13-08, +7(34994)2-19-19\nwww.uomuzhi.ru\nuprobr@shur.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 12,
        "col3": "Управление образования города Лабытнанги",
        "col4": "629400, Ямало-Ненецкий автономный округ, г. Лабытнанги, ул. Первомайская, д. 29\n+734992)2-39-01, +7 (34992) 23416\n uolbt.yanao.ru\n mail@uo.lbt.yanao.ru\n"
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 13,
        "col3": "Управление образования муниципального образования город Муравленко",
        "col4": " 629602, Ямало-Ненецкий автономный округ, г. Муравленко, ул. Ленина, д.65\n7(34938)5-65-00\n www.uomur.org\n uo@muravlenko.yanao.ru\n"
    },
    {
        "col1": "Ярославская область",
        "col2": 1,
        "col3": "Министерство \nобразования Ярославской области",
        "col4": "г.Ярославль, \nул.Советская, д. 7, \n150000,\n(4852)40-18-95,\ndobr@yarregion.ru"
    }
]