export const navDD = [
    [
        [
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Алейский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "658107, Алтайский край, Алейский район, с. Вавилон, ул. Школьная, 22а",
                "col5": "alejsk-cpd@22edu.ru",
                "col6": "http:\/\/domvavilon.ucoz.ru\/",
                "col7": "385 (53) 39338"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Алтайский центр помощи детям, оставшимся без попечения родителей им. В.С. Ершова»",
                "col4": "659653, Алтайский край, Алтайский район, с. Алтайское, ул. Заречная,60",
                "col5": "altajskij-cpd@22edu.ru",
                "col6": "http:\/\/altdetdom.ru\/",
                "col7": "385 (37) 21631"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Барнаульский центр помощи детям, оставшимся без попечения родителей, № 1» ",
                "col4": "656010, Алтайский край,  г. Барнаул,ул. Э.Алексеевой, 29",
                "col5": "barnaul-cpd1@22edu\/ru",
                "col6": "http:\/\/priyut.edu22.info\/",
                "col7": "385 (2) 336059"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Барнаульский центр помощи детям, оставшимся без попечения родителей, № 2»",
                "col4": "656022, Алтайский край, г. Барнаул, п. Ново-силикатный, ул. Суворова, 8",
                "col5": "barnaul-cpd2@22edu.ru",
                "col6": "http:\/\/ddom6.ru\/o-dome\/nashi-kontakty\/",
                "col7": "385 (2) 313752"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Барнаульский центр помощи детям, оставшимся без попечения родителей, № 3»",
                "col4": "656044, Алтайский край, г. Барнаул,ул. Юрина, 218",
                "col5": "barnaul-cpd3@22edu.ru",
                "col6": "http:\/\/barcpd3.ru\/",
                "col7": "385 (2) 51-17-23"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Барнаульский центр помощи детям, оставшимся без попечения родителей, № 4»",
                "col4": "656099, Алтайский край, г. Барнаул, ул. Интернациональная, 26",
                "col5": "barnaul-cpd4@22edu.ru",
                "col6": "http:\/\/detdom8.edu22.info\/",
                "col7": "385 (2) 226235"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Бийский центр помощи детям, оставшимся без попечения родителей» ",
                "col4": "659334, Алтайский край, г. Бийск,ул. Пушкина, 188а",
                "col5": "bijsk-cpd@22edu.ru",
                "col6": "http:\/\/biy-centerdeti.edu22.info\/homepage.html",
                "col7": "385 (4) 375978"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Волчихинский помощи детям, оставшимся без попечения родителей»",
                "col4": "659653, Алтайский край, Волчихинский район, с. Волчиха ул. Ленина, 85",
                "col5": "volchiha-cpd@22edu.ru",
                "col6": "http:\/\/voldetdom.ucoz.com\/",
                "col7": "385 (65) 22153"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Заринский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "659100, Алтайский край,  г. Заринск,  ул. Горького, 23",
                "col5": "zarinsk-cpd@22edu.ru",
                "col6": "http:\/\/zarinskdetdom.edu22.info\/",
                "col7": "385 (95) 22183"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Каменский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "658710, Алтайский край, г. Камень - на - Оби, ул. Пушкинская, 83",
                "col5": "kamen-cpd@22edu.ru",
                "col6": "https:\/\/center-kamen.edu22.info\/",
                "col7": "385 (84) 22345"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Куйбышевский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "658200, Алтайский край, Рубцовский район, с. Куйбышево,ул. Советская, 1а",
                "col5": "kujbyshev-cpd@22edu.ru",
                "col6": "http:\/\/detskiy-dom.rubtsovsk.ru\/",
                "col7": "385 (57) 74566"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Кытмановский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "659240, Алтайский край, Кытмановский район, с. Кытманово,ул. Заречная, 4а",
                "col5": "kytmanovo-cpd@22edu.ru",
                "col6": "http:\/\/kcdd.ucoz.ru\/",
                "col7": "385 (90) 22651"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Михайловский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "659630, Алтайский край, Михайловский район, с. Михайловское,ул. К. Маркса, 84",
                "col5": "mihajlovka-cpd@22edu.ru",
                "col6": "https:\/\/mihdetdom.ucoz.ru\/",
                "col7": "385 (70) 26561"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Павловский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "659010, Алтайский край, Павловский район,  с. Павловск, ул. Шумилова, 1",
                "col5": "pavlovsk-cpd@22edu.ru",
                "col6": "http:\/\/pavlovskdom.ucoz.ru\/",
                "col7": "385 (81) 26393"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Панкрушихинский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "658760, Алтайский край, Панкрушихинский район,с. Панкрушиха, ул. Совхозная, 1",
                "col5": "pankrushiha-cpd@22edu.ru",
                "col6": "http:\/\/detipan.ru\/",
                "col7": "385 (80) 22450"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Поспелихинский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "658330, Алтайский край, Поспелихинский район, с. Поспелиха, ул. Инженерная, 14",
                "col5": "pospeliha-cpd@22edu.ru",
                "col6": "http:\/\/det-dom.ucoz.ru\/",
                "col7": "385 (56) 23733"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Романовский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "658640, Алтайский край, Романовский район, с. Романово, ул. Партизанская,1",
                "col5": "romanovo-cpd@22edu.ru",
                "col6": "http:\/\/romdetdom.ru\/",
                "col7": "385 (61) 21369"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Рубцовский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "658221, Алтайский край, г. Рубцовск, ул. Тракторная, 78",
                "col5": "rubcovsk-cpd@22edu.ru",
                "col6": "http:\/\/rub-centrdeti.edu22.info\/",
                "col7": "385 (57) 21896"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Среднесибирский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "658007, Алтайский край, Тальменский район, п. Среднесибирский, ул. Юбилейная, 1г",
                "col5": "srednesibirsk-cpd@22edu.ru",
                "col6": "http:\/\/sredd-d.my1.ru\/",
                "col7": "385 (91) 37417"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Топчихинский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "659070, Алтайский край, Топчихинский район, с. Топчиха, ул. Пушкинская, 86",
                "col5": "topchiha-cpd@22edu.ru",
                "col6": "http:\/\/topcentrpomdet.edu22.info\/",
                "col7": "385 (52) 22671"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Троицкий центр помощи детям, оставшимся без попечения родителей»",
                "col4": "659840, Алтайский край, Троицкий район, с. Троицкое, ул. Комсомольская, 74",
                "col5": "troick-cpd@22edu.ru",
                "col6": "http:\/\/dom-detei.edu22.info\/",
                "col7": "385 (34) 22180"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУ «Яровской центр помощи детям, оставшимся без попечения родителей»",
                "col4": "658839, Алтайский край, г. Яровое, квартал \"А\", 41",
                "col5": "yarovoe-cpd@22edu.ru",
                "col6": "https:\/\/kvartala.nethouse.ru\/",
                "col7": "385 (68) 20450"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУЗ \"Дом ребенка специализированный, г. Барнаул\"",
                "col4": "656003, Алтайский край, г.Барнаул, ул. Аванесова,46",
                "col5": "domrebenkabarnaul@yan dex.ru",
                "col6": "http:\/\/drbrn.zdravalt.ru\/",
                "col7": "8(3852)6 8-30-50"
            },
            {
                "col1": "Алтайский край\n",
                "col2": "Министерство образования и науки Алтайского края",
                "col3": "КГБУЗ \"Дом ребенка специализированный, г. Бийск\"",
                "col4": "659304, Алтайский край, г. Бийск, ул. Михаила Лермонтова ,199\/1",
                "col5": "drsbiysk.buh @corp.zdrav alt.ru",
                "col6": "drbsk.zdravalt.r u",
                "col7": "8(3854)3 33299"
            },
        ],
    ],
    [
        [
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное автономное учреждение Амурской области для детей-сирот и детей, оставшихся без попечения родителей, \"Благовещенский детский дом\"",
                "col4": "675020, Амурская область, г.Благовещенск, п.Мясокомбинат, 4",
                "col5": "blag_dd@amurszn.ru",
                "col6": "http:\/\/blagdetskiydom.ru\/ ",
                "col7": "(4162)                              44-31-24, 44-31-25, 44-31-26"
            },
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное автономное учреждение Амурской области для детей-сирот и детей, оставшихся без попечения родителей, \"Дмитриевский детский дом\"",
                "col4": "676931, Амурская область, Ивановский муниципальный округ, с. Дмитриевка, ул. Мира, 20",
                "col5": "dmit_dd@amurszn.ru",
                "col6": "https:\/\/dmitdetdom.amur.socinfo.ru\/",
                "col7": 84164923622
            },
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное автономное учреждение социального обслуживания Амурской области \"Свободненский специальный (коррекционный) детский дом\"",
                "col4": "676450, Амурская область, г.Свободный, ул. Карла Маркса, д.27\n",
                "col5": "svb_sdd@amurszn.ru",
                "col6": "http:\/\/gaudetskidom3.ucoz.ru\/",
                "col7": "8 (41643) 5-09-56"
            },
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное автономное учреждение Амурской области для детей-сирот и детей, оставшихся без попечения родителей, \"Семиозерский детский дом\"",
                "col4": "676935, Амурская обл., Ивановский муниципальный округ,  с. Семиозерка,  ул. Кирова 41 А.",
                "col5": "7ozer_dd@amurszn.ru",
                "col6": "www.detdom19.ucoz.ru",
                "col7": "8(41649) 20 5 33"
            },
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное автономное учреждение Амурской области \"Константиновский центрсоциальной помощи семье и детям \"Росток\"",
                "col4": "676980, Амурская область, Константиновский район, с. Константиновка, ул. Советская, 100\n",
                "col5": "rostok@amurszn.ru   ",
                "col6": "http:\/\/rostok-amur.my1.ru ",
                "col7": "8(41639)91-3-75"
            },
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное автономное учреждение Амурской области \"Белогорский центр содействия семейному устрйству детей, оставшихся без попечения родителей, подготовки и сопровождения замещающих семей \"Радуга\"",
                "col4": " 676854  г. Белогорск, Амурская область, ул. Производственная, д. 11",
                "col5": "raduga@amurszn.ru",
                "col6": "http:\/\/internat20.ucoz.ru\/",
                "col7": "8(41641)5-96-91"
            },
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное автономное учреждение Амурской области \"Поярковский центр содействия семейному устройству детей, оставшихся без попечения родителей, подготовки и сопровождения замещающих семей \"Доверие\"",
                "col4": "676680, Амурская область, Михайловский район,  село Поярково,  ул. Гагарина, 12",
                "col5": " doverie@amurszn.ru",
                "col6": "http:\/\/sch-int6dv.ucoz.ru ",
                "col7": "8(416 37) 41-7-91"
            },
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное автономное учреждение Амурской области \"Райчихинский центр содействия семейному устройству детей, оставшихся без попечения родителей, подготовки и сопровождения замещающих семей \"Шанс\"",
                "col4": "676785, Амурская область, г. Райчихинск, п. Широкий,  ул. Станционная, д. 7",
                "col5": "shans@amurszn.ru",
                "col6": "http:\/\/det-dom.su\/",
                "col7": "8(416(47)29262"
            },
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное автономное учреждение социального обслуживания Амурской области \"Малиновский социально-реабилитационный центр\"",
                "col4": "676713, Амурская область, Бурейский район, с. Малиновка, ул. Красноармейская д.3            ",
                "col5": "#ERROR!",
                "col6": "#ERROR!",
                "col7": "#ERROR!"
            },
            {
                "col1": "Амурская область",
                "col2": "Министерство социальной защиты населения Амурской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Амурской области  \"Социально-реабилитационный центр для несовершеннолетних \"Забота\"",
                "col4": "Амурская область, г.Благовещенск, ул. Воронкова д.28",
                "col5": "https:\/\/zabota-blag.ru\/",
                "col6": "www.domrebenkaamur.ru",
                "col7": "8 4162 23-86-51"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области для детей-сирот и детей, оставшихся без попечения родителей, «Архангельский центр содействия семейному устройству «Мамонтенок»",
                "col4": "163057, г. Архангельск, просп. Ленинградский, д. 279, корп. 1\n",
                "col5": "detdomn1@babyarh.ru",
                "col6": "http:\/\/www.babyarh.ru",
                "col7": "8(8182) 62-88-72"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, детей \nс ограниченными возможностями здоровья «Архангельский центр помощи детям «Лучик»",
                "col4": "163000, г. Архангельск, ул. Логинова, д. 16, корп. 1\n",
                "col5": "detdomcor@mail.ru",
                "col6": "http:\/\/acpd-luchik.edusite.ru\/",
                "col7": "8(8182) 65-11-84      "
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Вельский центр содействия семейному устройству»",
                "col4": "165150, Архангельская область, Вельский район, г. Вельск, \nул. Гайдара, д. 13\n",
                "col5": "15gln@mail.ru, velskdd@yandex.ru (бухгалтерия)\n",
                "col6": "http:\/\/velskdd.ru\/ru\/",
                "col7": "8(81836) 6-53-65                           8(81836) 6-54-66"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Каргопольский центр содействия семейному устройству»",
                "col4": "164110, Архангельская область, Каргопольский район, \nг. Каргополь, \nул. Советская, д. 90",
                "col5": "kargopol.detdom@yandex.ru ",
                "col6": "http:\/\/kargopoldetdom.edusite.ru\/",
                "col7": "8(81841) 2-14-82"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Коношский центр содействия семейному устройству»",
                "col4": "164012, Архангельская область, \nКоношский район, \nпос. Коноша, \nул. Сельскохозяйственная, д. 1",
                "col5": "dom258@list.ru       kon-dd@yandex.ru",
                "col6": "http:\/\/коношскийдетскийдом.сайт-оу.рф\/",
                "col7": "8(81858) 2-34-76 8(81858) 2-34-35              "
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Котласский центр содействия семейному устройству»",
                "col4": "165300, Архангельская область, \nг. Котлас, \nул. Мелентьева, д. 27\n",
                "col5": "ddom27@yandex.ru",
                "col6": "http:\/\/moydom.nubex.ru\/",
                "col7": "8(81837) 2-44-13"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Красноборский центр содействия семейному устройству»",
                "col4": "165430, Архангельская область, Красноборский район, \nд. Алексеевская,       ул. 1-я Линия, д. 1",
                "col5": "krasdd@yandex.ru",
                "col6": "http:\/\/krasdd.edusite.ru\/",
                "col7": "8(81840) 3-47-16"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Мошинский центр содействия семейному устройству\"",
                "col4": "164224, Архангельская область, Няндомский район,\nд. Макаровская, \nул. Городская, д. 9",
                "col5": "detdommosha@mail.ru",
                "col6": "http:\/\/detdommosha.qlite.ru\/",
                "col7": "8(81838) 5-11-28  8(81838) 5-12-56  \n\n"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Низовский центр содействия семейному устройству»",
                "col4": "165105, Архангельская область, Вельский район, \nдер. Теребино, \nул. Молодежная, д. 1",
                "col5": "nizdd@yandex.ru",
                "col6": "http:\/\/nizovskdd.ucoz.org\/",
                "col7": "8(81836) 5-63-32"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Новодвинский центр содействия семейному устройству»",
                "col4": "164900, Архангельская область, \nг. Новодвинск, \nул. 3-й Пятилетки, \nд. 17",
                "col5": "novdetdom@mail.ru\n",
                "col6": "http:\/\/novdetdom.ucoz.ru\/",
                "col7": "8(81852) 4-44-75                        8(81852) 4-21-92"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Октябрьский центр содействия семейному устройству»",
                "col4": "165210, Архангельская область, Устьянский район, пос. Октябрьский, \nул. Загородная, д. 1",
                "col5": "ustddom@mail.ru\n",
                "col6": "http:\/\/oktdetdom.ucoz.ru\/",
                "col7": "8(81855) 5-24-71               8(81855) 5-11-06"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Онежский центр содействия семейному устройству»",
                "col4": "164840, Архангельская область, г. Онега, \nул. Гончарика, \nд. 26",
                "col5": "ondetdom@inbox.ru",
                "col6": "http:\/\/ondetdom.ru\/",
                "col7": "8(81839) 7-39-34"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Плесецкий центр содействия семейному устройству»",
                "col4": "164262, Архангельская область, Плесецкий район,\nр\/п Плесецк, \nул. Зеленая, д. 9а",
                "col5": "beregini94@mail.ru",
                "col6": "http:\/\/plesdetdom.3dn.ru\/",
                "col7": "8(81832) 7-45-11                         8(81832) 7-20-49"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Ракуло-Кокшеньгский центр содействия семейному устройству»",
                "col4": "165123, Архангельская область, Вельский район, \nдер. Козловская, \nул. Крайняя, д. 3\n",
                "col5": "rkdetdom@mail.ru          rkdetdom.buh@mail.ru (бухгалтерия)",
                "col6": "http:\/\/rkdetdom.nubex.ru\/",
                "col7": "8(81836) 4-51-92                       8(81836) 4-52-21"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Рембуевский центр содействия семейному устройству»",
                "col4": "164535, Архангельская область, Холмогорский район, \nд. Рембуево ",
                "col5": "rembuevodd@yandex.ru ",
                "col6": "http:\/\/rembuevodd.ru\/",
                "col7": "8 921-291-10-15 \n8 953-934-14-41 \n8 953-934-16-16"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Ровдинский центр содействия семейному устройству»",
                "col4": "165170, Архангельская область, Шенкурский район, с. Ровдино, \nул. Первомайская, д. 2",
                "col5": "rovdd@yandex.ru            rovdinodd@yandex.ru\n",
                "col6": "http:\/\/Rovdino-detdom.ru",
                "col7": "8(81851) 4-21-49                      8(81851) 4-21-40"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Северодвинский центр содействия семейному устройству «Олененок»",
                "col4": "164509, Архангельская область, \nг. Северодвинск, \nул. Макаренко, д. 20",
                "col5": "detdom20@mail.ru",
                "col6": "http:\/\/sevdetdom29.ru",
                "col7": "8(8184) 57-22-65                   8(8184) 57-22-74 "
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Сольвычегодский центр содействия семейному устройству»",
                "col4": "165330, Архангельская область, Котласский район, \nг. Сольвычегодск, пер. Мирный, \nд. 6\n",
                "col5": "sirotdom-sec@mail.ru,\nsolsirotdom@mail.ru (бухгалтерия)",
                "col6": "http:\/\/solsirotdom.my1.ru\/",
                "col7": "8(81837) 3-29-49 8(81837) 3-29-14"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Яренский центр содействия семейному устройству»",
                "col4": "165780, Архангельская область, Ленский район, с. Яренск, \nул. Дубинина, д. 37",
                "col5": "yardd@rusobr.ru\n\n",
                "col6": "http:\/\/yardd.edusite.ru\/",
                "col7": "8(81859) 5-20-30"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Ильинский центр содействия семейному устройству»",
                "col4": "165680, Архангельская область, Вилегодский район, \nс. Ильинско-Подомское, \nул. Комсомольская, \nд. 6, фл. 3",
                "col5": "ilinskii_detskii_dom@bk.ru,                                  byh-idd@bk.ru",
                "col6": "http:\/\/iddschool.narod2.ru\/",
                "col7": "8(81843) 4-19-94 8(81843) 4-17-20"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное общеобразовательное учреждение Архангельской области для детей-сирот и детей, оставшихся без попечения родителей, \"Коряжемский детский дом-школа»\n",
                "col4": "165650, Архангельская область, \nг. Коряжма, \nпр. Ленина, д. 31\n",
                "col5": "kordetdom@mail.ru",
                "col6": "http:\/\/kordetdom.edusite.ru\/",
                "col7": "8(81850) 3-27-61"
            },
            {
                "col1": "Архангельская область ",
                "col2": "Министерство труда, занятости и социального развития Архангельской области ",
                "col3": "Государственное бюджетное учреждение Архангельской области \nдля детей-сирот и детей, оставшихся без попечения родителей, «Цигломенский центр содействия семейному устройству»",
                "col4": "163044, \nг. Архангельск, \nул. Пустошного, \nд. 31\n",
                "col5": "gbou.cdd@mail.ru ",
                "col6": "http:\/\/cdd.edusite.ru\/",
                "col7": "8(8182) 69-52-27          8(8182) 69-52-24"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской области \"Социально-реабилитационный центр для несовершеннолетних \"Вера\"",
                "col4": "416010 Астраханская область, Харабалинский район, г. Харабали ул. Галкина д.4",
                "col5": "src.vera@mail.ru",
                "col6": "https:\/\/center-vera.astr.socinfo.ru\/",
                "col7": "8(85148)5-23-91"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное казенное стационарное учреждение социального обслуживания Астраханской области \"Разночиновский детский дом-интернат, предназначенный для де-тей, имеющих психические расстройства»",
                "col4": "416120, АО, Наримановский район, с. Разночиновка, ул. Волжская,8",
                "col5": "65-0-75@list.ru",
                "col6": "http:\/\/dom-internat.astr.socinfo.ru\/",
                "col7": "8(85171)65075, 8(85171)65126"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской области «Социально-реабилитационный центр для несовершеннолетних «Дружба»",
                "col4": "416400  Астраханская область, Лиманский район, с.Зензели, ул.Мира, д.36 «А»",
                "col5": "osrc_zenzeli@bk.ru",
                "col6": "https:\/\/druzhba.astr.socinfo.ru",
                "col7": "8(85147)92646"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской области \"Областной социально-реабилитационный центр для несовершеннолетних \"Исток\"",
                "col4": "414056, г. Астрахань, ул. Марии Максаковой, д. 10",
                "col5": "osrc2012@yandex.ru",
                "col6": "https:\/\/istok30.ru\/",
                "col7": "(8512) 54-21-58"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение «Центр помощи детям, оставшимся без попечения родителей «Улитка»",
                "col4": "г. Астрахань, 414000, ул. Красная Набережная, 171 б литер А",
                "col5": "priut-ulitka@mail.ru",
                "col6": "https:\/\/www.domulitka.ru\/",
                "col7": "35-29-50, 47-25-84"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное казенное учреждение Астраханской области \"Специализированный дом ребенка \"Капелька\"",
                "col4": "414041, город Астрахань, ул. Яблочкова, дом 1б",
                "col5": "domrebenka-2 h@yandex.ru",
                "col6": "htt:\/\/sdr-kapelka.ru\/\/",
                "col7": "8 (8512) 36-62-08"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской области \"Социальный приют для детей \"Любава\"",
                "col4": "416200, Астраханская область, Енотаевский район, с.Енотаевка, ул.Чапаева\/Томилина, д.51\/2",
                "col5": "reab5@mail.ru",
                "col6": "https:\/\/priutlubava.astr.socinfo.ru\/",
                "col7": "88514391-2-84"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской области \"Центр помощи детям, оставшимся без попечения родителей \"Юность\" ",
                "col4": "416111 Астраханская область, Наримановский район, г. Нариманов, ул. Астраханская, 8 А",
                "col5": "3782255@mail.ru",
                "col6": "http:\/\/yunost30.ru\/",
                "col7": "(85171) 61-3-09"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской\nобласти «Центр помощи детям, оставшимся без попечения родителей\n«Малышок»",
                "col4": "г. Астрахань, ум. Медиков, 3",
                "col5": "detdomast@mail.ru",
                "col6": "https:\/\/14110.maam.ru\/",
                "col7": "8(8512)49-85-81"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской области \"Центр помощи детям, оставшимся без попечения родителей \"Солнечный\"",
                "col4": "416231, Астраханская область, Черноярский район, село Черный Яр, улица Емельяна Пугачева, дом 3",
                "col5": "solnechnyy20@bk.ru",
                "col6": "ddi-solnechnyy.astr.socinfo.ru",
                "col7": "8 (85149) 2-12-92"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное\nказенное учреждение Астраханской области «Центр помощи детям, оставшимся без попечения родителей «Созвездие»\n",
                "col4": "414013, г. Астрахань, ул. Мелиоративная, 4В",
                "col5": "astrdetdom1@mail.ru",
                "col6": "https:\/\/sozvezdie.astr.socinfo.ru\/",
                "col7": "8(8512)579862"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской области \"Центр помощи детям, оставшимся без попечения родителей \"Ручеек\"",
                "col4": "416370, Астраханская область, Икрянинский район, с. Икряное, ул.Кирова,92 \"а\"",
                "col5": "ikrogsu@yandex.ru",
                "col6": "http:\/\/center-rucheek.astr.socinfo.ru",
                "col7": "8(851244) 2-15-65"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской области \"Социально-реабилитационный центр для несовершеннолетних \"Радуга\"",
                "col4": "416181 Астраханская область, Володарский район, с. Козлово, ул. Школьная, дом 10",
                "col5": "gskyraduga2017@yandex.ru",
                "col6": "https:\/\/srcn-raduga.astr.socinfo.ru\/",
                "col7": "8851429-41-23"
            },
            {
                "col1": "Астраханская область",
                "col2": "Министерство социального развития и труда Астраханской области",
                "col3": "Государственное специализированное казенное учреждение Астраханской области \"Социально-реабилитационный центр \"Островок\"",
                "col4": "416309 Астраханская, область Камызякский район, РП Волго-Каспийский, ул.Заводская, д.10",
                "col5": "deti_ostrovok@mail.ru",
                "col6": "https:\/\/ostrovok.astr.socinfo.ru",
                "col7": "8(85145)98875; 8(85145)98073"
            },
        ],
    ],
    [
        [
            {
                "col1": "Белгородская область",
                "col2": "Министерство социальной защиты населения и труда Белгородской области",
                "col3": "ГБУ \"Прохоровский центр развития и социализации ребенка\"",
                "col4": "309000, Белгородская область, Прохоровский район, посёлок городского типа Прохоровка, ул. Парковая, д. 49",
                "col5": "pro_ddest@mail.ru",
                "col6": "www.prohdetdombel31.ru",
                "col7": "8 (47242) 2-16-93, 8 (47242) 2-33-91"
            },
            {
                "col1": "Белгородская область",
                "col2": "Министерство социальной защиты населения и труда Белгородской области",
                "col3": "ГБУ \"Центр подготовки и постинтернатного сопровождения выпускников \"Расправь крылья\"",
                "col4": "308519, Белгородская обл., Белгородский р-он, п. Северный, ул. Школьная, 37",
                "col5": "centr.psrk@mail.ru",
                "col6": "http:\/\/rkcentr31.ru\/",
                "col7": "8(4722) 23-10-36  8(4722) 39-92-48 8(4722) 39-99-47 "
            },
            {
                "col1": "Белгородская область",
                "col2": "Министерство социальной защиты населения и труда Белгородской области",
                "col3": "ГБУ «Старооскольский центр развития и социализации детей физкультурно-спортивной направленности «Старт»",
                "col4": "309508, Белгородская область, г. Старый Оскол, ул. Рубежная, д.30",
                "col5": "st_centr_start@mail.ru",
                "col6": "st-centr-start.ru",
                "col7": "(4725) 32-19-83, 46-27-43, 32-59-47, 32-59-74, 32-71-01, 32-42-56   "
            },
            {
                "col1": "Белгородская область",
                "col2": "Министерство социальной защиты населения и труда Белгородской области",
                "col3": "ГБУ «Белгородский центр развития и социализации ребёнка «Южный»",
                "col4": "308031, Белгородская область, город Белгород, бульвар Юности, д.18",
                "col5": "udd911@yandex.ru                       ds-24@usznbel.ru",
                "col6": "http:\/\/detdomug.ru\/",
                "col7": "8 (4722) 53-03-60,  8 (4722) 53-53-70, 8 (4722) 53-65-59, 8(4722) 53-64-77  "
            },
            {
                "col1": "Белгородская область",
                "col2": "Министерство социальной защиты населения и труда Белгородской области",
                "col3": "ГБУ \"Большетроицкий центр развития                                   и социализации\"",
                "col4": "309280, Белгородская область, Шебекинский городской округ, \nс. Большетроицкое                                      ул. Садовая, д. 1а",
                "col5": " btrddi@yandex.ru - приемная",
                "col6": "http:\/\/detdomsheb.ru\/",
                "col7": "8(47)248 62-2-32  8(47)248 62-4-46   8(47)248 62-1-85   8(47)248 62- 1-78  8(47)248 62-2-39  "
            },
            {
                "col1": "Белгородская область",
                "col2": "Негосударственная (частная) организация для детей-сирот, в том числе религиозная",
                "col3": "ЧУ \"Разуменский дом детства\"",
                "col4": "308510, Белгородская область, Белгородский район, пгт. Разумное, ул. 78 Гвардейской дивизии, д. 14а",
                "col5": "rdd_bel@mail.ru  dsc-26@usznbel.ru",
                "col6": "https:\/\/netsirot.ru\/",
                "col7": "8 (4722)59-54-83 - кабинет директора; 8(4722) 59-39-88 - заместитель директора, дежурная мед.сестра; 8(4722) 59-55-75 - бухгалтерия (факс)"
            },
        ],
    ],
    [
        [
            {
                "col1": "Брянская область",
                "col2": "Департамент социальной политики и занятости населения  Брянской области ",
                "col3": "ГБСУ СОН Брянской области \"Центр помощидетям, оставшимся без плопечения родителей, Климовского района\"",
                "col4": "243040 Брянская область, Климовский район, р.п. Климово, ул.Новая, дом7",
                "col5": "shk50@yandex.ru",
                "col6": "http:\/\/klm-shi8.brn.eduru.ru\/",
                "col7": "8(48347) 3-18-79           "
            },
            {
                "col1": "Брянская область",
                "col2": "Департамент социальной политики и занятости населения  Брянской области ",
                "col3": "ГБСУ СОН Брянской области\"Центр помощи детям, оставшимся без попечения родителей, Мглинского района",
                "col4": "243220 Брянская область, г.Мглин, ул.Пригородная,      д.1",
                "col5": "skola-internat@yandex.ru",
                "col6": "http:\/\/\/skola-internat9.wixsite.com\/mglin",
                "col7": "8(48339) 2-23-31"
            },
            {
                "col1": "Брянская область",
                "col2": "Департамент социальной политики и занятости населения  Брянской области ",
                "col3": "ГБСУ СОН Брянской области \"Центр помощи детям, оставшимся без попечния родителей, имени Героя России А.А. Титова Жуковского района\"",
                "col4": "242700 Брянская область,\n г. Жуковка, ул. Заречная, д. 2а",
                "col5": "int-school@mail.ru",
                "col6": "http:\/\/int-school.ru",
                "col7": "8(4334) 3-18-32"
            },
            {
                "col1": "Брянская область",
                "col2": "Департамент социальной политики и занятости населения  Брянской области ",
                "col3": "ГБСУ СОН \"Дубровский детский дом-интернат для умственно отсталых детей\"",
                "col4": "242750, Брянская облеасть, п. Дубровка, ул Журавлева, стр.50",
                "col5": "Dubr.ddi@mail.ru",
                "col6": "https:\/\/ddi24.uszn032.ru\/",
                "col7": "8(48332) 9-12-71"
            },
            {
                "col1": "Брянская область",
                "col2": "Департамент социальной политики и занятости населения  Брянской области ",
                "col3": "ГБСУ СОН Брянской области \"Центр помощи детям, оставшимся без попечния родителей, Карачевского района\"",
                "col4": "242500 Брянская область, город Карачев, улица Кольцова, дом 38",
                "col5": "hausbebi@rambler.ru",
                "col6": "hausbebi.ru",
                "col7": "8(8335) 2-35-81"
            },
            {
                "col1": "Брянская область",
                "col2": "Департамент социальной политики и занятости населения  Брянской области ",
                "col3": "ГБСУ СОН Брянской области \"Центр помощи детям, оставшимся без попечния родителей, г. Клинцы\"",
                "col4": "243140, Брянская обл., г. Клинцы, ул. Парижской Коммуны, д. 41",
                "col5": "domreb-klintsy@yandex.ru",
                "col6": "klindr.ru",
                "col7": "8(48336) 4-11-37"
            },
        ],
    ],
    [
        [
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение Владимирской области \"Владимирский детский дом им.К.Либкнехта\"",
                "col4": "600027\nг. Владимир,\nул. Соколова-Соколенка,  д. 24-а.\n",
                "col5": "VladDetDom_1@mail.ru",
                "col6": "http:\/\/t31140x.dou.obrazovanie33.ru\/",
                "col7": "(4922) 21-14-64"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение Владимирской области \"Камешковский детский дом\"",
                "col4": "601330\nВладимирская обл.,\nг. Камешково,\nул. Абрамова, д.11-а\n",
                "col5": "kamddom@mail.ru ",
                "col6": "http:\/\/t23851a.dou.obrazovanie33.ru\/",
                "col7": "(49248) 2-49-58"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение Владимирской области \"Карабановский детский дом\"",
                "col4": "601642\nВладимирская обл.,\nАлександровский р-н,\nг. Карабаново,\nул. Гагарина, д. 5\n",
                "col5": "p3alekskarabdd@mail.ru",
                "col6": "http:\/\/t73740z.dou.obrazovanie33.ru\/",
                "col7": "(49244) 5-29-03"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение Владимирской области \"Ляховский детский дом\"",
                "col4": "602144\nВладимирская обл., Меленковский район, п. Ляхи, Почтовый переулок, д. 2\n",
                "col5": "Lyahidetdom@yandex.ru ",
                "col6": "http:\/\/t55050w.dou.obrazovanie33.ru\/",
                "col7": "(49247) 6-63-40"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение Владимирской области \"Муромский детский дом\"",
                "col4": "602267\nВладимирская обл.,\nг. Муром,\nул. Мечникова, д. 9\n",
                "col5": "detskidom1@mail.ru ",
                "col6": "http:\/\/t29050t.dou.obrazovanie33.ru\/",
                "col7": "(49234) 2-11-62"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение Владимирской области \"Покровский детский дом\"",
                "col4": "601120\nВладимирская обл.,\nг. Покров, \nул. III Интернационала, д. 50\n",
                "col5": "detstvo_pokrov@mail.ru",
                "col6": "http:\/\/t17590s.dou.obrazovanie33.ru\/",
                "col7": "(49243) 6-12-32"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенной учреждение Владимирской области \"Суздальский специальный (коррекционный) детский дом\"",
                "col4": "601293\nВладимирская обл.,\nг. Суздаль,\nул. Ленина, д. 9\n",
                "col5": "suzdal3@mail.ru",
                "col6": "http:\/\/t45060u.dou.obrazovanie33.ru\/",
                "col7": "(49231) 2-05-14"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение Владимирской области \"Центр содейтсвия семейному воспитанию и постинтернатного  сопровождения\"",
                "col4": "601204,\nВладимирская обл.,\nг. Собинка,\nул. Ленина,\nд.101-б\n",
                "col5": "cssvps@yandex.ru",
                "col6": "http:\/\/t49590v.dou.obrazovanie33.ru\/",
                "col7": "(49242) 2-22-89"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное общеобразовательное учреждение Владимирской области \"Лухтоновская специальная (коррекционная) общеобразовательная школа-интернат\"",
                "col4": "601352, Владимирская обл., Судогодский район, д. Лухтоново, ул. Дубенского, д. 11",
                "col5": "luhtonovo-internat@yandex.ru",
                "col6": "https:\/\/t822930.sch.obrazovanie33.ru\/",
                "col7": "(492354) 2-39-54"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение здравоохранения Владимирской области  «Гусь-Хрустальный дом ребенка специализированный»",
                "col4": "601501, Владимирская обл., г.Гусь-Хрустальный, ул.Писарева, д.15 \n",
                "col5": "deti@gus.elcom.ru",
                "col6": "http:\/\/domdetstva33.ru\/",
                "col7": "(49241) 2-73-11"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение здравоохранения Владимирской области «Муромский дом ребенка специализированный»",
                "col4": "602267           Владимирская обл., г.Муром, ул.К.Маркса, д.19 \n",
                "col5": "mdrs@bk.ru",
                "col6": "https:\/\/гкузвомдрс.рф\/",
                "col7": "(49234) 3-14-09"
            },
            {
                "col1": "Владимирская область",
                "col2": "Министерство образования и молодежной политики Владимирской области",
                "col3": "Государственное казенное учреждение социальной защиты Владимирской области  «Кольчугинский детский психоневрологический интернат для детей с особенностями развития»",
                "col4": "601781, Владимирская область, г. Кольчугино, ул. Мира, д. 73",
                "col5": "kolchugino_domint@avo.ru",
                "col6": "https:\/\/www.ddi33.ru\/",
                "col7": "(49245) 2-32-35"
            },
        ],
    ],
    [
        [
            {
                "col1": "Волгоградская область\n",
                "col2": "Комитет социальной защиты населения Волгоградской области",
                "col3": "ГКСУ СО \"Волжский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "404109, Волгоградская область, \nг. Волжский, \nулица 40 лет Победы, 89 ",
                "col5": "Volzhskiy_CPD@volganet.ru",
                "col6": "https:\/\/442fz.volganet.ru\/025284\/",
                "col7": "8(8443) 52-36-99"
            },
            {
                "col1": "Волгоградская область\n",
                "col2": "Комитет социальной защиты населения Волгоградской области",
                "col3": "ГКСУ СО \"Камышинский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "403876, Волгоградская область,\nг. Камышин, 5 микрорайон, 61а\n",
                "col5": "Kamyshinskiy_CPD@volganet.ru",
                "col6": "https:\/\/442fz.volganet.ru\/025282\/",
                "col7": "8(84457) 5-46-61"
            },
            {
                "col1": "Волгоградская область\n",
                "col2": "Комитет социальной защиты населения Волгоградской области",
                "col3": "ГКСУ СО \"Серафимовичский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "403441, Волгоградская область, \nг. Серафимович, ул. Советская, 3\n",
                "col5": "Serafimovichskiy_CPD@volganet.ru",
                "col6": "https:\/\/442fz.volganet.ru\/025283\/",
                "col7": "8(84464) 4-35-93"
            },
            {
                "col1": "Волгоградская область\n",
                "col2": "Комитет социальной защиты населения Волгоградской области",
                "col3": "ГКССУ СО \"Детский психоневрологический интернат \"Солнечный дом\"",
                "col4": "403840, Волгоградская область, Камышинский, р-н, г. Петров Вал, ул. Ленина, 84",
                "col5": "Petrovvalskiy_DI@volganet.ru",
                "col6": "https:\/\/442fz.volganet.ru\/025020\/",
                "col7": "8(84457) 65-1-73"
            },
            {
                "col1": "Волгоградская область\n",
                "col2": "Комитет социальной защиты населения Волгоградской области",
                "col3": "ГКУЗ \"Волгоградский областной специализированный дом ребенка для детей с органическим поражением центральной нервной системы с нарушением психики\"",
                "col4": "400005 г. Волгоград, ул. Коммунистическая, д. 62 а",
                "col5": "sdr@volganet.ru",
                "col6": "https:\/\/xn---34-5cdbfskb4cqrg5a.xn--p1ai\/",
                "col7": "8(8442) 23-00-50"
            },
        ],
    ],
    [
        [
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области  \"Вологодский центр помощи детям, оставшимся без попечения родителней, № 1\"\n",
                "col4": "160002, Вологодская область,г. Вологда, ул. Щетинина, д. 5а",
                "col5": "cpdvol@cpdvol.gov35.ru;",
                "col6": "https:\/\/detdom1.gov35.ru",
                "col7": "(8172) 53-43-00"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области  \"Вологодский центр помощи детям, оставшимся без попечения родителней, \"Флагман\"\"\n",
                "col4": "160012, Вологодская область, г. Вологда,   ул. Ветошкина, д.52 а",
                "col5": "flagman@cpd.gov35.ru;",
                "col6": "https:\/\/cpdflagman.gov35.ru",
                "col7": "(8172) 75-95-73"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области \"Белозерский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "161200,  Вологодская область, Белозерский район,\nг. Белозерск, \nул. Белозер, д. 17-а\n",
                "col5": "cpdbel@cpdbel.gov35.ru;",
                "col6": "https:\/\/bel-cpd.gov35.ru",
                "col7": "(8-1756) 2-32-73"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области \"Великоустюгский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "162340, Вологодская область, г. Великий Устюг, ул. Павла Покровского, д. 23",
                "col5": "ddomvu@cpd.gov35.ru;",
                "col6": "https:\/\/cpdvu.gov35.ru",
                "col7": "(81738) 2-33-24"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области \"Кадниковский детский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "162107, Вологодская область,\n Сокольский район, г. Кадников, ул. К. Маркса, 11",
                "col5": "cpdkad@cpdkad.gov35.ru;",
                "col6": "https:\/\/kad-cpd.gov35.ru",
                "col7": "(81733) 4-11-13"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области \"Тотемский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "161300, Вологодская область, Тотемский район, д. Варницы, ул. Воскресенская, д. 21",
                "col5": "totmacentrpom.deti@yandex.ru; ",
                "col6": "https:\/\/tot-cpd.gov35.ru",
                "col7": "(81739) 2-15-89"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области \"Харовский центр помощи детям, оставшимся без попечения родителей",
                "col4": "162200, Вологодская область, г. Харовск, ул. Энергетиков, д.14",
                "col5": "khardetdom@cpd.gov35.ru;",
                "col6": "https:\/\/cpdkharovsk.gov35.ru",
                "col7": "(81732) 2-33-04"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области \"Череповецкий центр помощи детям, оставшимся без попечения родителей, \"Наши дети\"",
                "col4": "162618, г. Череповец, ул. Юбилейная, д. 26",
                "col5": "nashideti@cpd.gov35.ru;",
                "col6": "https:\/\/centr-nashi-deti.gov35.ru",
                "col7": "(8202) 26-27-70"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области \"Шекснинский центр помощи детям, оставшимся без попечения родителей, \"Альтаир\"",
                "col4": "162560, Вологодская область, Шекснинский район, ул. Путейская, д. 39а",
                "col5": "altair-sheksna@mail.ru; ",
                "col6": "https:\/\/cpdsheksna.gov35.ru",
                "col7": "(81751) 2-39-24"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального обслуживания Вологодской области \"Кадниковский детский дом-интернат, предназначенный для граждан, имеющих психические расстройства\"",
                "col4": "162107, Вологодская область, Сокольский район, г. Кадников, ул. Октябрьская, 15",
                "col5": "kadnikov.ddi@post.gov35.ru;",
                "col6": "https:\/\/kadddi.gov35.ru\/",
                "col7": " (81733) 4-03-57"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Бюджетное учреждение социального Бюджетное учреждение социального \nобслуживания для детей-сирот и детей, \nоставшихся без попечения родителей, \nВологодской области  \"Вологодский центр помощи детям, оставшимся без попечения родителней (специализированный)\"",
                "col4": "160019, г. Вологда, ул. Комсомольская д. 61",
                "col5": "domr2007@yandex.ru;",
                "col6": "https:\/\/voldomr.volmed.org.ru\/",
                "col7": "(8172) 54-13-82"
            },
            {
                "col1": "Вологодская область",
                "col2": "Департамент социальной защиты населения Вологодской области",
                "col3": "Вологодской области \"Череповецкий центр помощи детям, оставшимся без попечения родителей (специализированный)\"",
                "col4": "162624 Вологодская область, г. Череповец, ул. Командарма Белова, д. 42",
                "col5": "chcpd35@mail.ru; ",
                "col6": "https:\/\/domrebcher.volmed.org.ru\/",
                "col7": "(8202) 44-01-75"
            },
        ],
    ],
    [
        [
            {
                "col1": "Воронежская область ",
                "col2": "Министерство социальной защиты Воронежской области",
                "col3": "Казенное учреждение  Воронежской области «Воронежский областной специализированный дом ребенка»",
                "col4": "Воронежская обл., г.Ворнеж, Сухумская, 16",
                "col5": "mail@vosdr.zdrav36.ru",
                "col6": "https:\/\/vosdr.zdrav36.ru\/",
                "col7": "8 (473)227-68-07"
            },
            {
                "col1": "Воронежская область ",
                "col2": "Министерство социальной защиты Воронежской области",
                "col3": "Казенное учреждение Воронежской области«Детский дом города Воронежа»",
                "col4": "Воронежская обл., г. Воронеж, ул. Острогожская,д. 57",
                "col5": "dd-voronezh@govvrn.ru",
                "col6": "https:\/\/dd-voronezh.e-gov36.ru\/",
                "col7": "(473) 236-42-15"
            },
            {
                "col1": "Воронежская область ",
                "col2": "Министерство социальной защиты Воронежской области",
                "col3": "Бюджетное учреждение Воронежской области «Бутурлиновский детский дом-интернат для умственно отсталых детей»",
                "col4": "Воронежская обл., г. Бутурлиновка, ул. Красная, д. 218",
                "col5": "domint.vrn@mail.ru",
                "col6": "https:\/\/pni-buturlinov.e-gov36.ru\/",
                "col7": "8 (47361) 2-33-79"
            },
            {
                "col1": "Воронежская область ",
                "col2": "Департамент образования Воронежской области",
                "col3": "Казенное общеобразовательное учреждение Воронежской области «Бобровская школа-интернат для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья»",
                "col4": "Воронежская обл., г. Бобров, ул. Кирова, д. 32",
                "col5": "bobrschoolint@govvrn.ru",
                "col6": "https:\/\/bshi.obrvrn.ru",
                "col7": 74735041389
            },
        ],
    ],
    [
        [
            {
                "col1": "Донецкая Народная Республика ",
                "col2": "Министерство здравоохранения Донецкой Народной Республики",
                "col3": "Республиканский специализированный дом ребенка г. Донецка Министерства здравоохранения Донецкой Народной Республики",
                "col4": "83003, Донецкая Народная Республика, г. Донецк,       ул. Краснофлотская, 68\"а\"",
                "col5": "malutka-my-life@yandex.ru",
                "col6": "https:\/\/vk.com\/childrenshomedonetsk",
                "col7": "7856 312-93-25 "
            },
            {
                "col1": "Донецкая Народная Республика ",
                "col2": "Министерство здравоохранения Донецкой Народной Республики",
                "col3": "Республиканский специализированный дом ребенка г. Макеевки Министерства здравоохранения Донецкой Народной Республики  ",
                "col4": "286121, Донецкая Народная Республика, г.о. Макеевка,              г. Макеевка,     ул. Тореза, 2",
                "col5": "domrebenka.2014@mail.ru",
                "col6": "https:\/\/domrebenka-makeevka.ru",
                "col7": "79493225191                               79498303694"
            },
            {
                "col1": "Донецкая Народная Республика ",
                "col2": "Министерство здравоохранения Донецкой Народной Республики",
                "col3": "Городской специализированный дом ребенка г. Донецка Министерства здравоохранения Донецкой Народной Республики",
                "col4": "283054, Донецкая Народная Республика, г.о. Донецкий, г. Донецк, ул. Засядько, д. 10а",
                "col5": "gsdr2015@mail.ru",
                "col6": "https:\/\/gsdrdn.wordpress.com\/",
                "col7": "7856 306-45-85 "
            },
            {
                "col1": "Донецкая Народная Республика ",
                "col2": "Министерство труда и социальной политики Донецкой Народной Республики ",
                "col3": "Государственное бюджетное учреждение «Шахтерский детский дом - интернат для инвалидов с недостатками умственного развития»",
                "col4": "286202, Донецкая Народная Республика, м.о. Шахтерский, г. Шахтерск, ул. Свердлова, д. 31",
                "col5": "E-mail: shakhtersk_in@mtspdnr.ru  ",
                "col7": 79493189046
            },
            {
                "col1": "Донецкая Народная Республика ",
                "col2": "Министерство образования Донецкой Народной Республики",
                "col3": "Государственное бюджетное дошкольное образовательное учреждение «Донецкий детский дом № 1 «Теремок»»",
                "col4": "283087, Донецкая Народная Республика, г. Донецк, \nул. Инженерная, д. 4\n",
                "col5": "dd1teremok@mail.ru",
                "col6": "https:\/\/ds1-doneck-r897.gosweb.gosuslugi.ru\/",
                "col7": "79493083188"
            },
            {
                "col1": "Донецкая Народная Республика ",
                "col2": "Министерство образования Донецкой Народной Республики",
                "col3": "Государственное казенное общеобразовательное учреждение «Донецкая школа-интернат № 1»",
                "col4": "283080, Донецкая Народная Республика, г. Донецк, \nул. Гонимова, д. 17а\n",
                "col5": "doshi1@mail.ru",
                "col6": "https:\/\/sh-int1-doneck-r897.gosweb.gosuslugi.ru",
                "col7": "Директор 79493189796, Зам. директора 79493376467"
            },
            {
                "col1": "Донецкая Народная Республика ",
                "col2": "Министерство образования Донецкой Народной Республики",
                "col3": "Государственное бюджетное общеобразовательное учреждение «Амвросиевская школа-интернат № 4»",
                "col4": "287304, Донецкая Народная Республика, г. Амвросиевка, \nул. Молодежная, д. 63 \n",
                "col5": "internat-am@mail.ru",
                "col6": "https:\/\/vk.com\/public219278001,https:\/\/ok.ru\/group\/70000002267318, http:\/\/школа-интернат4.амвросиевка.рф\/, https:\/\/sh-int4-amvrosievka-r897.gosweb.gosuslugi.ru\/",
                "col7": 79493175013
            },
        ],
    ],
    [
        [
            {
                "col1": "Еврейская автономная область",
                "col2": "Департамент социальной защиты населения правительства Еврейской автономной области",
                "col3": "Областное государственноеобщеобразовательное бюджетное учреждение для детей-сирот и детей оставшихся без попечения родителей \"Детский дом № 2\"",
                "col4": "679000, Еврейская автономная область, г. Биробиджан, ул. Советская, д. 49",
                "col5": "cpd_2@post.eao.ru",
                "col6": "deti79.ru",
                "col7": "8 (42622)21835 "
            },
            {
                "col1": "Еврейская автономная область",
                "col2": "Департамент социальной защиты населения правительства Еврейской автономной области",
                "col3": "Областное государственное бюджетное учреждение \"Валдгеймский детский дом-интернат для умственно отсталых детей\"",
                "col4": "679511, Еврейская автономная область, Биробиджанский район, с. Валдгейм, ул. Полевая, д. 5",
                "col5": "vddi@post.eao.ru",
                "col7": "8 (42622)71249"
            },
            {
                "col1": "Еврейская автономная область",
                "col2": "Департамент социальной защиты населения правительства Еврейской автономной области",
                "col3": "Областное государственное бюджетное учреждение здравоохранения \"Дом ребенка специализированный\"",
                "col4": "679000, Еврейская автономная область, г. Биробиджан, ул. Пионеркая, д. 78",
                "col5": "domreb79@post.eao.ru",
                "col6": "dom-rebenka-eao.umi.ru",
                "col7": "8(42622)30378"
            },
        ],
    ],
    [
        [
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО \"Борзинский центр помощи детям, оставшимся без попечения родителей «Горизонт»  Забайкальского края",
                "col4": "674610, Забайкальский край, г.Борзя, ул.Гурьева, 80-й квартал, 11а",
                "col5": "internat_brz@mail.ru",
                "col6": "http:\/\/dd_borz.borz.zabedu.ru\/14:56",
                "col7": 89242787390
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО \"Малетинский центр помощи детям, оставшимся без попечения родителей «Детство» Забайкальского края",
                "col4": "673014, с. Малета, ул. Пиорнерская, 10",
                "col5": "val-sviridova@yandex.ru",
                "col6": "dd_malt.malt.zabedu.ru",
                "col7": 89294887649
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО \"Могочинский центр помощи детям, оставшимся без попечения родителей \"Гармония\" Забайкальского края",
                "col4": "Забайкальский край, г. Могоча, ул. Красноармейская 1 , 673730",
                "col5": "mogzkdom@mail.ru",
                "col6": "dd_mgch.mgch.zabedu.ru\/",
                "col7": 89144849279
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО \"Хохотуйский центр помощи детям, оставшимся без попечения родителей \"Березка\" Забайкаотского края",
                "col4": "673140, Забайкальский край, Петровск-Хабайкальский район, с.Хохотуй, ул. Советская, 58",
                "col5": "gou_hscsi@rambler.ru",
                "col6": "http:\/\/dd_hoht.hoht.zabedu.ru\/",
                "col7": 89144890165
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО \"Урульгинский центр помощи детям, оставшимся без попечения родителей \"Подсолнух\" Забайкальского края",
                "col4": "673335, Забайкальский край, Карымский район, с. Урульга, ул. Коммунальная, д. 86",
                "col5": "urulgainternat@yandex.ru   ",
                "col6": "http:\/\/dd_urlg.urlg.zabedu.ru\/",
                "col7": 89144828670
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Петровск-Забайкальский центр помощи детям, оставшимся без попечения родителей «Единство» ",
                "col4": "673005, Забайкальский край, Петровск-Забайкальский район, г. Петровск-Забайкальский, ул.Мысовая, д.1",
                "col5": "pzabdetdom@yandex.ru",
                "col6": "http:\/\/edinstvo.detiguso.ru",
                "col7": "8-(30-236)3-16-28"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Акшинский социально-реабилитационный центр для несовершеннолетних «Задор» ",
                "col4": "674230, Акшинский район, с.Акша, ул.Лазо, д.14",
                "col5": "zador_aksha@mail.ru",
                "col6": "http:\/\/zador.zabguso.ru",
                "col7": "8-(30-231)3-19-28"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Кыринский социально-реабилитационный центр для несовершеннолетних «Перекрёсток» ",
                "col4": "674250, Кыринский район, с.Кыра, ул.Ленина, д.29",
                "col5": "muso_kyra@mail.ru ",
                "col6": "http:\/\/perekrestok.zabguso.ru",
                "col7": "8-(30-235)2-19-29"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Улетовский социально-реабилитационный центр для несовершеннолетних «Кедр» ",
                "col4": "674050, Улетовский район, с.Улеты, ул.Школьная, д.2а",
                "col5": "kedr-2013@mail.ru",
                "col6": "http:\/\/kedr.zabguso.ru",
                "col7": "8-(30-238)5-35-35"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Краснокаменский социально-реабилитационный центр для несовершеннолетних  «Доброта»  ",
                "col4": "674673, г.Краснокаменск, д.111",
                "col5": "spddip75@mail.ru, ",
                "col6": "http:\/\/dobrota.zabguso.ru",
                "col7": "8-(30-245)2-78-11"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Сретенский социально-реабилитационный центр для несовершеннолетних им. С.Г. Киргизова»  ",
                "col4": "673500, г.Сретенск, ул.2-я Железнодорожная, д.1",
                "col5": "berezka_srt@mail.ru,",
                "col6": "http:\/\/berezka.zabguso.ru",
                "col7": "8-(30-246)2-16-19"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Читинский центр помощи детям, оставшимся без попечения родителей имени В. Н. Подгорбунского» ",
                "col4": "670000, г.Чита, ул.Нагорная, д.84",
                "col5": "centrdeti16@mail.ru, ",
                "col6": "centrchita.detiguso.ru",
                "col7": "8-(30-22)-26-59-47"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Черновский центр помощи детям, оставшимся без попечения родителей «Восточный» ",
                "col4": "672043, с.Колочное 2, ул.Южная, д.9, пом.1",
                "col5": "chdd@inbox.ru",
                "col6": "vostok.detiguso.ru",
                "col7": "8-(30-22)-39-34-04"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Балейский центр помощи детям, оставшимся без попечения родителей «Маяк» ",
                "col4": "673450, г.Балей, ул.Дарасунская, д.4",
                "col5": "internat_baley@mail.ru, ",
                "col6": "mayak.detiguso.ru",
                "col7": "8-(30-232)5-28-99"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Шерловогорский центр помощи детям, оставшимся без попечения родителей «Аквамарин» ",
                "col4": "674607, Борзинский район,  п.Шерловая Гора, ул.Большое садовое кольцо, д.7",
                "col5": "sherlovdd@mail.ru,",
                "col6": "akvamarin.detiguso.ru",
                "col7": "8-(30-233)3-41-83"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Маккавеевский центр помощи детям, оставшимся без попечения родителей «Импульс» ",
                "col4": "672535, Читинский район, с.Маккавеево, ул.Молодежная, д.66\/1",
                "col5": "ddbuhg@mail.ru,",
                "col6": "impuls.detiguso.ru",
                "col7": "8-(30-22)37-85-11"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Карымский центр помощи детям, оставшимся без попечения родителей «Прометей» ",
                "col4": "673330, Карымский район, п.Карымское, ул.Верхняя, д.9 ",
                "col5": "goukdd@mail.ru,",
                "col6": "prometey.detiguso.ru",
                "col7": "8-(30-234)3-14-72"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Первомайский центр помощи детям, оставшимся без попечения родителей «Родник» ",
                "col4": "673390, Шилкинский район, п.Первомайский, ул.Чкалова, д.4",
                "col5": "dd-pmsk@yandex. Ru",
                "col6": "rodnik.detiguso.ru",
                "col7": "8-(30-262)4-36-94"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Вершино-Шахтаминский центр помощи детям, оставшимся без попечения родителей «Маленькая страна» ",
                "col4": "673613, Шелопугинский район, с.Вершино-Шахтаминский, ул.Школьная, д.2",
                "col5": "etdomshahtama@mail.ru",
                "col6": "guso-vershinoshakhtaminskiy.vsite.biz",
                "col7": "8-(30-266)2-64-17"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Борзинский центр помощи детям, оставшимся без попечения родителей им. С.Д. Номоконова» ",
                "col4": "674600, г.Борзя, п.Переездный, д.19",
                "col5": "dd-svetlyachok2@yandex.ru",
                "col6": "svetlyachok.detiguso.ru",
                "col7": "8-(30-233)3-25-17"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГУСО «Приаргунский комплексный центр социального обслуживания  населения «Солнышко» ",
                "col4": "674333, с.Новоцурухайтуй, ул.Лазо, д.64",
                "col5": "solnyshkocspsd@mail.ru",
                "col6": "http:\/\/solnyshko.zabguso.ru",
                "col7": "8-(30-243)3-01-16"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГАУСО «Комплексный центр социального обслуживания населения «Орловский» ",
                "col4": "687510, п.Орловский, ул.30 лет Победы, д.10",
                "col5": "guso-orlovski@mail.ru",
                "col6": "http:\/\/orlovskiy.zabguso.ru",
                "col7": "8-(30-239)3-01-16"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГСУ СО  «Петровск-Забайкальский детский дом для умственно – отсталых детей» Забайкальского края",
                "col4": "673005, г.Петровск-Забайкальский, ул.Таежная, д.1",
                "col5": "kosmostv0437@mail.ru",
                "col6": "http:\/\/petrovzab-ddi.zabguso.ru\/",
                "col7": "8 (30236) 2-12-84"
            },
            {
                "col1": "Забайкальский край",
                "col2": "Министерство труда и социальной защиты населения Забайкальского края",
                "col3": "ГКУЗ \"Краевой специализированный дом ребенка № 2\"",
                "col4": "672530, Читинский район, пгт.Атамановка, тер.Урочище Серебряный Бор",
                "col5": "ser_dom-2@mail.ru",
                "col6": "http:\/\/domrebenka-atamanovka.1gb.ru",
                "col7": "8(3022) 37-91-76; 8(3022) 99-27-70, 89248032585"
            },
        ],
    ],
    [
        [
            {
                "col1": " Запорожская область",
                "col2": "нет организаций для детей-сирот",
                "col3": "-",
                "col4": "-",
                "col5": "-",
                "col6": "-",
                "col7": "-"
            },
        ],
    ],
    [
        [
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казенное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей,  \"Ивановский детский дом \"Ровесник\"",
                "col4": "153038 г. Иваново, ул. Лежневская, д. 205",
                "col5": "rovesnik_dd@ivreg.ru",
                "col6": "https:\/\/iv-rovesnik.ru\/",
                "col7": "8 (4932) 56-97-13"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казенное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей,  \"Ивановский специальный (коррекционный) детский дом для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья \"Радуга\"",
                "col4": "153038,   г. Иваново, ул. Маршала Василевского, д. 7-а",
                "col5": "raduga_dd@ivreg.ru",
                "col6": "ddraduga.ru",
                "col7": "8 (4932) 56-92-28"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казенное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей,  \"Ивановский детский дом \"Звездный\"",
                "col4": "153003, г. Иваново, ул. Кузнецова, д. 50",
                "col5": "zvezdnyi_dd@ivreg.ru",
                "col6": "https:\/\/детдом-звездный.рф",
                "col7": "8 (4932) 32-54-62"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казенное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей,   \"Ивановский детский дом \"Родничок\"",
                "col4": "153022, г. Иваново,  ул. Жугина, д.6",
                "col5": "rodnichok_dd@ivreg.ru",
                "col6": "rodnichok.ivn.socinfo.ru",
                "col7": "8 (4932) 23-46-44"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казенное образовательное учреждение для детей- сирот и детей, оставшихся без попечения родителей, \"Кинешемский детский дом\"",
                "col4": "155800, Ивановская область, г. Кинешма,  ул. Аристарха Макарова, д. 15",
                "col5": "kineshma_dd@ivreg.ru",
                "col6": "kindetdom.ru",
                "col7": "8 (49331) 5-63-71"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казённое образовательное учреждение для детей - сирот и детей, оставшихся без попечения родителей, \"Фурмановский детский дом\" ",
                "col4": "155520, Ивановская область, г. Фурманов, ул. Дачная, д. 58",
                "col5": "furmanov_dd@ivreg.ru",
                "col6": "furmanovdd.ru",
                "col7": "8 (49341) 2-30-21"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное  казенное образовательное учреждение для детей-сирот и детей, осташихся без попечения родителей, \"Шуйский детский дом\"",
                "col4": "155902, Ивановская область, г. Шуя,             ул. Ярославская, д. 77 ",
                "col5": "shuya_dd@ivreg.ru",
                "col6": "detdomshuya.ru",
                "col7": "8 (49351) 4-88-67"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казенное общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Шуйский \nдетский дом-школа»\n",
                "col4": "155912, Ивановская область, г. Шуя,  ул. Школьная 1-я, д.1",
                "col5": "shuya_ddsh@ivreg.ru",
                "col6": "https:\/\/shdetdom.gosuslugi.ru",
                "col7": "8 (49351) 43507"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казённое образовательное учреждение для детей сирот и детей, оставшихся без попечения родителей, \"Васильевский детский дом\"",
                "col4": "155926 Ивановская область, Шуйский район, с. Васильевское, ул. Школьная, д. 1",
                "col5": "vasilevо_dd@ivreg.ru",
                "col6": "vasdetdom.ru",
                "col7": "8 (49351) 3-41-39"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казенное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Ивановский специальный (коррекционный) детский дом для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья\"Солнышко\"",
                "col4": "153027, г. Иваново, ул. М. Жаворонкова, д. 1",
                "col5": "sun_dd@ivreg.ru",
                "col6": "ddomsun.ru",
                "col7": "8 (4932) 33-29-23"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казенное общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Решемская специальная (коррекционная) школа-интернат для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья\"  ",
                "col4": "155840, Ивановская область, Кинешемский район,  с. Решма,  ул. Ленина, д. 14",
                "col5": "reshma_shi@ivreg.ru",
                "col6": " shkola-reshma.ru",
                "col7": "8 (49331) 9-82-77"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное государственное казенное общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Чернцкая специальная (коррекционная) школа-интернат для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья\"  ",
                "col4": "155116, Ивановская область, Лежневский район, с. Чернцы, ул. Санаторная, д. 1",
                "col5": "chern_shi@ivreg.ru",
                "col6": "https:\/\/sh-int-chernckaya-r24.gosweb.gosuslugi.ru",
                "col7": "8 (49357) 2-41-23"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Областное казенное учреждение здравоохранения \"Дом ребенка специализированный\" ",
                "col4": "153040, г. Иваново,   ул. Любимова, д. 11",
                "col5": "domrebenka@ivreg.ru",
                "col6": "ivdrs.ru",
                "col7": "8 (4932) 56-25-33"
            },
            {
                "col1": "Ивановская область",
                "col2": "Департамент социальной защиты населения Ивановской области",
                "col3": "Бюджетное стационарное учреждение социального обслуживания Ивановской области \"Шуйский дом - интернат\", детский дом - интернат, предназначенный для детей, имеющих психические расстройства ",
                "col4": "155904, Ивановская обл., г. Шуя, ул. 11-я Мичуринская, д. 4 ",
                "col5": "shkc2007@mail.ru",
                "col6": "https:\/\/shkc.ru\/",
                "col7": "8 (49351) 4-83-37"
            },
        ],
    ],
    [
        [
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних с. Илир\"",
                "col4": "665746, Иркутская Область,  р-н Братский, с. Илир,  ул. Строителей, д.1",
                "col5": "408430@bk.ru",
                "col6": "https:\/\/src-ilir.ru\/",
                "col7": "8 (3953) 408-430"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ СО \"Иркутский детский дом -интернат № 1 дя умственно отсталых детей\"",
                "col4": "664059, Иркутская область, г. Иркутск, территория 6 - й пос. ГЭС, д. 3А",
                "col5": "iddin@mail.ru",
                "col6": "https:\/\/iddin1.ru\/",
                "col7": "8(3952) 53 -16 -97"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ СО«Иркутский детский дом - интернат № 2 для умственно отсталых детей»",
                "col4": "664049, г. Иркутск, ул. Багратиона,   д. 52",
                "col5": "detskiidom.2@mail.ru ",
                "col6": "https:\/\/дети-дом.рф\/",
                "col7": "8-(3952)46-41-65"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ СО \"Саянский детский дом-интернат для умственно отсталых детей\"",
                "col4": "666301, Иркутская область, г. Саянск, мкр. Благовещенский, д.6",
                "col5": "sddi38@yandex.ru",
                "col6": "https:\/\/sayddi.ru\/",
                "col7": "8(39553)5-25-48"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ СО\"Братский детский дом - интернат для умственно отсталых детей\"",
                "col4": "Иркутская область, 665776, жилой район Осиновка, улица Иркутская, д. 25А",
                "col5": "bddi.priem@mail.ru",
                "col6": "https:\/\/ogbuso-bddi.ru\/",
                "col7": "8 (3953)304-402"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям, оставшимся без попечения родителей, г. Шелехова\"",
                "col4": "666032, Иркутская область, г.Шелехов, ул. Орловских комсомольцев, 44",
                "col5": "shelcpd@yandex.ru",
                "col6": "http:\/\/цпд-шелехов.рф\/",
                "col7": "8(39550)4-27-40"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних \"Малютка\" г.Усолье-Сибирское\"",
                "col4": "665459, Иркутская область, г. Усолье-Сибирское, проезд Серегина, 10 ",
                "col5": "mechta-usib@yandex.ru",
                "col6": "http:\/\/mechta-usib.ru",
                "col7": "8(39543)- 6-81-01"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних  Иркутского района\"",
                "col4": "Иркутский район, Урик с., Ленина ул., 2а-1,  664531",
                "col5": "urik@yandex.ru",
                "col6": "https:\/\/www.srcn-irk.ru\/",
                "col7": "8(3952)49-55-22"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ СО \"Социально-реабилитационный центр для несовершеннолетних Заларинского района\"",
                "col4": "666321, Иркутская область, Заларинский район, п. Залари, ул. Джержинского, 54 А",
                "col5": "osrc@mail.ru",
                "col6": "http:\/\/src-zalarinskoe.bdu.su\/",
                "col7": "8 (39552)2-18-72, 2-23-74"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО «Центр помощи детям, оставшимся без попечения родителей, г. Черемхово»",
                "col4": "665413, Иркутская область,  г.Черемхово ул.Ленина 21-23",
                "col5": "cherdetdom@mail.ru",
                "col6": "http:\/\/detdom.cherobr.ru\/",
                "col7": "8(39546)5-13-83"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям, оставшимся без попечения родителей, г. Тулуна\"",
                "col4": "665268, Иркутская область, г. Тулун Песочная ул., д.76, Тулун, \n",
                "col5": "Tuldetdom@mail.ru",
                "col6": "https:\/\/cpd-tulun.irk.socinfo.ru\/",
                "col7": "(83952) 437800"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних \"Малютка\" г.Братска\"",
                "col4": "665712, Иркутская область, г. Братск, ул.Гидростроителей, д.18 ",
                "col5": "dom.reb@mail.ru\n",
                "col6": "http:\/\/www.domrebbratsk.ru\/\n",
                "col7": "8(3953) 37-16-56 "
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям, оставшимся без попечения родителей, г. Братска\"",
                "col4": "665712, Иркутская область, г. Братск, улица Набережная, 1А ",
                "col5": "detdom.bratsk@rambler.ru",
                "col6": "https:\/\/bratsk-cpd.ru\/",
                "col7": "8(3953) 37-10-61"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних Братского района\"",
                "col4": "665776,  Иркутская область, Братский район, п. Зяба, ул. Цветочная, д. 1",
                "col5": "olen-src@mail.ru",
                "col6": "https:\/\/srcnbr.ru\/",
                "col7": "8 3953 290-158"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних Усольского района\"",
                "col4": "Пр-т. Мира и дружбы, 2, п. Железнодорожный, Усольский район, Иркутская область, 665483",
                "col5": "usolie-srcn@mail.ru",
                "col6": "https:\/\/srcn-usolskiy.my1.ru",
                "col7": "8 (39543) 98115"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО «Социально-реабилитационный центр для несовершеннолетних «Малютка» г. Ангарска»",
                "col4": "665830, Иркутская область,  г. Ангарск, квартал 48-й, стр. 6",
                "col5": "oguz_aosdr@mail.ru",
                "col6": "http:\/\/angdomrebenka.ru\/",
                "col7": "8(3955) 52-31-32     "
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних п. Лесогорска\"",
                "col4": "665500, Иркутская область, Чунский район, р.п. Лесогорск. ул. Шастина, д.8",
                "col5": "src-lesogorsk@mail.ru",
                "col6": "https:\/\/src-lesogorsk.ru\/",
                "col7": "8(39567) 73122"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ \"Комплексный центр социального обслуживания населения Киренского и Катангского районов\"  ",
                "col4": "666702, Иркутская область, Киренсий район, мр-н Мельничный, ул. Сибирская, дом 21. ",
                "col5": "kcson_06@mail.ru",
                "col6": "http:\/\/kcsonkir.ru\/",
                "col7": "8-(39568)-32545"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ СО \"Центр социальной помощи семье и детям г. Усть - Илимска и Усть - Илимского района\"",
                "col4": "Дружбы Напродов пр-ь, д.56, Усть - Илимск, 666682",
                "col5": "uisrc@mail.ru",
                "col6": "https:\/\/www.uicsp5.my1.ru\/",
                "col7": "(39535) 38789"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр социальной помощи семье и детям Тайшетского района\"",
                "col4": "665006, Иркутская область,           г. Тайшет,ул. Первомайская -59",
                "col5": "centr-semia@mail.ru",
                "col6": "http:\/\/cpdtaishet.ru",
                "col7": "(839563)   2-50-99"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям,оставшимся без попечения родителей, г. Ангарска\"",
                "col4": "665834, Иркутская область, г. Ангарск, 15 мкр., д.14",
                "col5": "cpd-angarsk@mail.ru",
                "col6": "http:\/\/odd-angarsk.ucoz.ru\/",
                "col7": "8(39556)50306"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям, оставшимся без попечения родителей, Заларинского района\"",
                "col4": "666327, Иркутская область,Заларинский район,с.Хор-Тагна, улица Школьная,дом 12",
                "col5": "lida07.07@mail.ru",
                "col6": "https:\/\/cpd-hor-tagna.nubex.ru",
                "col7": "89016645399"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям, оставшимся без попечения родителей, г. Усолье-Сибирское\"",
                "col4": "665458 Иркутская область, г. Усолье-Сибирское, проспект Комсомольский, 58",
                "col5": "ddusolie@mail.ru",
                "col6": "http:\/\/ddusolie.ru\/",
                "col7": "8(39543)7-10-05"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр социальной помощи семье и детям Казачинско-Ленского района\"",
                "col4": "666535,  Иркутская область,  Казачинско-Ленский район,  р.п. Улькан \n ул. Набережная  2\n",
                "col5": "priyut_ulkan@mail.ru",
                "col6": "http:\/\/centrulkan.ru\/",
                "col7": "(839562) 3-20-99  "
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям, оставшимся без попечения родителей Усть-Кутского района\"",
                "col4": "666764, Иркутская область, г. Усть-Кут, ул. 2-ая Молодежнпая, д, 3",
                "col5": "odddir@mail.ru",
                "col6": "http:\/\/deti-ustkut.irk.socinfo.ru\/",
                "col7": "8(39565)75478"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям, оставшимся без попечения родителей, Свердловского района г. Иркутска\"",
                "col4": "664049, Иркутская обл, Иркутск г, Багратиона ул, дом № 52а",
                "col5": "664049, Иркутская обл, Иркутск г, Багратиона ул, дом № 52а",
                "col6": " http:\/\/cpd.irk.socinfo.ru\/",
                "col7": "8(3952) 537-067"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ СО \"Комплексный центр социального обслуживания населения г. Саянска\"",
                "col4": "666302, Иркутская область г. Саянск-2, мкр. Центральный, д. 17, а\/я 222",
                "col5": "kzsonsayansk@mail.ru",
                "col6": "http:\/\/kcsonsayansk.ru",
                "col7": "8(39553)53626"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУСО \"Центр помощи детям, оставшимся без попечения родителей Слюдянского района\"",
                "col4": "665932, Иркутская область, Слюдянский район, г.Байкальск, мкр.Гагарина, д. 206",
                "col5": "cpdsr@yandex.ru",
                "col6": "http:\/\/cpdbaikalsk.ru\/",
                "col7": "8(39542)3-40-36"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям, оставшимся без попечения родителей, Ленинского района г. Иркутска\"",
                "col4": "664020, Иркутская область г. Иркутск, ул. Мира, 124",
                "col5": "cpd38@mail.ru",
                "col6": "http:\/\/cpd38.ru\/",
                "col7": "8(3952) 37-27-05"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям, оставшимся без попечения родителей, Куйтунского района\"",
                "col4": "665344 Иркутская область, Куйтунский район, с. Карымск ул. Октябрьская, д. 3",
                "col5": "karimsk_detdom@mail.ru",
                "col6": "https:\/\/dd-karimsk.irk.socinfo.ru\/",
                "col7": "8(39554) 23-2-52"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних Нижнеудинского района\"",
                "col4": "665111, Иркутская область, м.р-н Нижнеудинский, с.п. Усть-Рубахинское, с. Мельница, ул. Ленина,зд. 44Д",
                "col5": "ogusosrnn@yandex\/ru",
                "col6": "http:\/\/ogbusosrcn.ru\/",
                "col7": "8(39557)46-46-1"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Центр помощи детям оставшимся без попечения родителей Нижнеилимского района\"",
                "col4": "665694 Иркутская область Нижнеилимский район п. Коршуновский ул. Ленина 6А",
                "col5": "detdomsun@mail.ru",
                "col6": "https:\/\/www.detdomsuncpd.ru\/",
                "col7": "8(39566)65247"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ СО\"Центр помощи детям, оставшимся без попечения родителе \"Гармония\" г. Черемхово\"",
                "col4": "665413, Иркутская область, г. Черемхово, ул. Леина, д. 18.",
                "col5": "ddgarmoniya.cher@yandex.ru",
                "col6": "https:\/\/ddgarmoniya.irk.socinfo.ru\/",
                "col7": "8(39546)-5-02-40"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГБУ СО \"Региональнгый семейный многофункциональный центр\"",
                "col4": "664049, Иркутская обл, Иркутск г, ул. Баррикад,  дом № 34А,",
                "col5": "Sandd2@socdeti.ru",
                "col6": "http:\/\/socdeti.ru\/",
                "col7": "8 (3952) 33-98-58"
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних г. Иркутска\"",
                "col4": "664020 Иркутская область, г. Иркутск, ул. Ленинградская, 91",
                "col5": "cpd91@mail.ru ",
                "col6": "http:\/\/cpdirk.ru\/",
                "col7": "8(3952) 460767  "
            },
            {
                "col1": "Иркутская облать",
                "col2": "Министрерство социального развития, опеки и попечительства Иркутской области ",
                "col3": "ОГКУ СО \"Социально-реабилитационный центр для несовершеннолетних \"Малютка\" г.Иркутска\"",
                "col4": "664058, Иркутская Область, г. Иркутск, мкр. Первомайский, д.40",
                "col5": "domrebenka_elena@list.ru ",
                "col6": "https:\/\/sites.google.com\/site\/childhomeirk\/",
                "col7": "8(3952) 53-17-01   8(3952) 53-16-97 "
            },
        ],
    ],
    [
        [
            {
                "col1": "Кабардино-Балкарская Республика ",
                "col2": "Министерство просвещения и науки Кабардино-Балкарской Республики",
                "col3": "Государственное бюджетное общеобразовательное учреждение «Центр сопровождения детей-сирот и детей, оставшихся без попечения родителей» Министерства просвещения и науки  Кабардино-Балкарской Республики",
                "col4": "361411, Российская Федерация, Кабардино-Балкарская Республика, Чегемский муниципальный район, сельское поселение Нартан, улица Ленина, 100",
                "col5": "intnartan@yandex.ru",
                "col6": " https:\/\/intnartan.ru\/",
                "col7": "8(86630) 9-76-63"
            },
        ],
    ],
    [
        [
            {
                "col1": "Калининградская область",
                "col2": "Министерство социальной политики Калининградской области",
                "col3": " Государственное бюджетное учреждение социального обслуживания Калининградской области «Центр содействия семейному устройству детей «Колосок» (ГБУСО КО \"Центр содействия семейному устройству детей «Колосок»)",
                "col4": "238420 г. Багратионовск ул. Калининградская д 55",
                "col5": "Адрес электронной почты: kolosok_bgr@bk.ru",
                "col6": " kolosok.klgd.socinfo.ru\n",
                "col7": "8(40156) 3-24-33, 8 (40156) 3-39-50"
            },
            {
                "col1": "Калининградская область",
                "col2": "Министерство социальной политики Калининградской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Калининградской области «Центр содейтсвия семейному устройству детей\n«Надежд»» (ГБУСО КО \"Центр содействия семейному устройству детей «Надежда»)",
                "col4": "236005, Калининградская область, город Калининград, ул. Камская 2-2а",
                "col5": "Адрес электронной почты: ddnadejda@mail.ru",
                "col6": "domnadezhda.klgd.socinfo.ru",
                "col7": "8-(4012) 65-33-49"
            },
            {
                "col1": "Калининградская область",
                "col2": "Министерство социальной политики Калининградской области",
                "col3": " Государственное бюджетное учреждение социального обслуживания Калининградской области  «Центр содействия семейному устройству детей «Наш дом» (ГБУСО КО «Центр содействия семейному устройству детей «Наш дом»)",
                "col4": "238326, РФ, Калининградская область, Зеленоградский район, г. Зеленоградск, ул. Октябрьская, д.7.",
                "col5": "Адрес электронной почты: zeldetdom@inbox.ru",
                "col6": "nash-dom.klgd.socinfo.ru",
                "col7": "8 (40150) 3-23-90"
            },
            {
                "col1": "Калининградская область",
                "col2": "Министерство социальной политики Калининградской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Калининградской области «Ресурсный семейный центр \"Доверие\"",
                "col4": "238051, Российская Федерация, Калининградская обл., г. Гусев, ул. Зои Космодемьянской, д.21",
                "col5": "Адрес электронной почты: centrrostok@mail.ru",
                "col6": "http:\/\/doverie.priut39.ru\/",
                "col7": "8(40143) 3-22-75; 8-(4014)33-27-47"
            },
            {
                "col1": "Калининградская область",
                "col2": "Министерство социальной политики Калининградской области",
                "col3": "Государственное бюджетное стационарное учреждение социального обслуживания Калининградской области «Детский дом-интернат «Маленькая страна» (Детский дом-интернат «Маленькая страна»)",
                "col4": "238414, Калининградская область, Правдинский район, пос. Крылово, ул. Центральная, д.63",
                "col5": "Адрес электронной почты: krdetdom@mail.ru",
                "col6": "smallcountry39.ru",
                "col7": "8-(4015)77-27-19"
            },
            {
                "col1": "Калининградская область",
                "col2": "Министерство здравоохранения политики Калининградской области",
                "col3": "Государственное бюджетное учреждение здравоохранения «Дом ребенка Калининградской области»",
                "col4": "236011, Калининградская область, г. Калининград, ул. Муромская, дом 2",
                "col5": "Адрес электронной почты: dr@infomed39.ru",
                "col6": "https:\/\/dr.infomed39.ru",
                "col7": "8-(4012)64-13-13"
            },
            {
                "col1": "Калининградская область",
                "col2": "Министерство здравоохранения политики Калининградской области",
                "col3": "Государственное бюджетное учреждение здравоохранения «Специализированный дом ребенка Калининградской области № 1»",
                "col4": "238751, Калининградская область, г. Советск, ул. Чапаева, д. 5\/7",
                "col5": "Адрес электронной почты: sdr-1@infomed39.ru",
                "col6": "https:\/\/sdr-1.infomed39.ru",
                "col7": "8 (40161) 65508; 8(40161)32691"
            },
        ],
    ],
    [
        [
            {
                "col1": "Калужская область",
                "col2": "Министерство труда и социальной защиты Калужской области",
                "col3": "Государственное бюджетное учреждение Калужской области \"Центр содействия семейному воспитанию \"БЕРЕГА\"",
                "col4": "249832, Калужская область, Дзержинский район, г. Кондрово, ул. Матросова, д. 37",
                "col5": "gbukoberega@adm.kaluga.ru",
                "col6": "www.40-kdd.edusite.ru",
                "col7": "8(48434) 3 -26-28,                 3-28-31."
            },
            {
                "col1": "Калужская область",
                "col2": "Министерство труда и социальной защиты Калужской области",
                "col3": "Государственное бюджетное учреждение Калужской области \"Центр содействия семейному воспитанию имени Попова В.Т.\" ",
                "col4": "248012,Калужская область,  г. Калуга ул. Кубяка,20",
                "col5": "gbukopopov@adm.kaluga.ru",
                "col6": "www.azardetdom.ru",
                "col7": "8(4842) 58-82-82"
            },
            {
                "col1": "Калужская область",
                "col2": "Министерство труда и социальной защиты Калужской области",
                "col3": "Государственное казенное учреждение Калужской области \"Полотняно-заводской детский дом социального обслуживания\"",
                "col4": "249844, Калужская область, Дзержинский район, д. Старки, 68",
                "col5": "gkukopolotnzav@adm.kaluga.ru",
                "col6": "http:\/\/polzavint.ru.",
                "col7": "8 (484) 343-34-84"
            },
            {
                "col1": "Калужская область",
                "col2": "Министерство труда и социальной защиты Калужской области",
                "col3": "Государственное казенное учреждение здравоохранения Калужской области \"Дом ребенка специализированный для детей с органическим поражением центральной нервной системы с нарушением психики\"",
                "col4": "248002 г. Калуга, ул. Войкова, д. 35.",
                "col5": "ok_domrebenka@mail.ru",
                "col6": "http:\/\/ddr40.ru\/",
                "col7": "8(4842) 54-23-74, 54-08-06"
            },
        ],
    ],
    [
        [
            {
                "col1": "Камчатский край",
                "col2": "Министерство социального благополучи и семейной политики Камчатского края",
                "col3": "КГКУ \"Дом ребёнка\"",
                "col4": "683024, Камчатский край, г. Петропавловск-Камчатский, ул. Кроноцкая, д. 8а.",
                "col5": "gdr_kam@mail.ru",
                "col6": "ребенок41.рф",
                "col7": "8 (4152) 46-15-63"
            },
            {
                "col1": "Камчатский край",
                "col2": "Министерство социального благополучи и семейной политики Камчатского края",
                "col3": "КГБУ «Камчатский детский дом для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья»",
                "col4": "683011 г.Петропавовск-Камчатский, ул.Первомайская, 15А",
                "col5": "Detdom-3olga@mail.ru",
                "col6": "det-dom-3.ru",
                "col7": "8 (415) 2275413, 8 (415) 2274948"
            },
            {
                "col1": "Камчатский край",
                "col2": "Министерство социального благополучи и семейной политики Камчатского края",
                "col3": "КГБУ «Центр содействия развитию семейных форм устройства «Радуга»",
                "col4": "ул.Арсеньева, д.16,г.Петропавловск-Камчатский,  683902      684010 Камчатский край, Елиовский район, город Елизово, ул.Санаторная, дом 4",
                "col5": "center.raduga41@yandex.ru",
                "col6": "www.raduga-pk.ru",
                "col7": "8 (4152) 300-544"
            },
            {
                "col1": "Камчатский край",
                "col2": "Министерство социального благополучи и семейной политики Камчатского края",
                "col3": "КГБУ «Центр содействия развитию семейных форм устройства «Эчган»",
                "col4": "688000, Камчатский край, Тигильский район, пгт. Палана, ул. им. 50-летия Камчатского комсомола, д. 12",
                "col5": "palan-detdom@yandex.ru",
                "col6": "http:\/\/детдом-эчган.рф\/",
                "col7": "8  (41543) 31967"
            },
        ],
    ],
    [
        [
            {
                "col1": "Карачаево-Черкесская Республика",
                "col2": "Министерство труда и социального развития Карачаево-Черкесской Республики",
                "col3": "РГБУ Республиканский детский дом-интернат для умственно отсталых детей «Забота»",
                "col4": "369303, Карачаево-Черкесская Республика, г.Усть-Джегута, микрорайон Московский, РГКУ РДДИ «Забота»",
                "col5": "ddizabota@mail.ru",
                "col6": "https:\/\/ddizabota.ru\/o-nas\/kontakty\/",
                "col7": "Приемная\/факс: 8(87875)75825\nБухгалтерия:  8(87875)75853\nОтдел кадров: 8(87875)75264"
            },
            {
                "col1": "Карачаево-Черкесская Республика",
                "col2": "Министерство труда и социального развития Карачаево-Черкесской Республики",
                "col3": "РГКУ « Государственный Республиканский детский дом для детей-сирот и детей, оставшихся без попечения родителей»",
                "col4": "369303, Карачаево-Черкесская Республика, г.Усть-Джегута,\nмикрорайон Московский, РГКУ «ГР детский дом»",
                "col5": "rgku.grdd@mail.ru",
                "col6": "http:\/\/www.detdom09.ru\/index.php\/about-us\/kontakty",
                "col7": "Приемная\/факс: 8(87875) 7-50-82\nБухгалтерия: 8(87875) 7-64-24\nСоциально-психологическая служба: 8(87875) 7-58-51"
            },
            {
                "col1": "Карачаево-Черкесская Республика",
                "col2": "Министерство труда и социального развития Карачаево-Черкесской Республики",
                "col3": "РГБУЗ  \"Черкесский городской дом ребенка специализированный дя детей с органическим поражением центральной нервной системы с нарушением психики\"",
                "col4": "369000, Карачаево-Черкесская Республика, г. Черкесск, ул. Парковая, д. 17",
                "col5": "domrebenka_30@mail.ru\n",
                "col6": "chgdr.ru",
                "col7": " Главврач:                          8(8782) 27-16-88                       горячая линия: 89631703519 "
            },
        ],
    ],
    [
        [
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казённое учреждение  для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Росток» ",
                "col4": "652473, Кемеровская область - Кузбасс,  г. Анжеро-Судженск,    \nул. Мира, д. 22\n",
                "col5": "dd35@mail.ru",
                "col6": "https:\/\/rostok.asedu.ru",
                "col7": "8(38453)5-12-54"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей  «Детский дом «Надежда» ",
                "col4": "652645, Кемеровская область - Кузбасс,           г. Белово,            пгт. Новый Городок, ул. Киевская, д. 3",
                "col5": "d.domnadegda@mail.ru ",
                "col6": "domnadegda.kemobl.ru",
                "col7": "8(38452)3-10-08"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное бюджетное   учреждение  для детей-сирот и детей, оставшихся без попечения родителей «Детский дом Страна детства» ",
                "col4": "650003,  г. Кемерово, пр. Комсомольскийд. 65 «а»",
                "col5": "dd.stranadetstva@mail.ru\n\n",
                "col6": "http:\/\/detdom1.ucoz.ru",
                "col7": "8(3842) 74-03-91"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное бюджетное  учреждение, осуществляющее обучение,  для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Доверие» ",
                "col4": "650024, г. Кемерово, ул. Ю. Двужильного, д. 13\n",
                "col5": "nashddom@mail.ru  \n",
                "col6": "kemdetdom2.kuz-edu.ru\n",
                "col7": "8(3842) 65-51-11"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное  учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Маяк» ",
                "col4": "652702, Кемеровская область – Кузбасс,  г. Киселевск, ул. Дружбы, д. 2\n",
                "col5": "choolint12@mail.ru ",
                "col6": "dd-rainbow.edusite.ru ",
                "col7": "8(38464) 2-37-47"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное  учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Гармония» ",
                "col4": "652515, Кемеровская область - Кузбасс,  г. Ленинск-Кузнецкий,   пр. Кирова, д. 81а",
                "col5": "dd.garmonia.lk@mail.ru",
                "col6": "http:\/\/detsckiydom.3dn.ru   ",
                "col7": "8(38456)7-03-87"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное образовательное учреждение для детей-сирот и детей, оставшихся  без попечения  родителей «Детский   дом «Единство» ",
                "col4": "652878, Кемеровская область - Кузбасс,  г. Междуреченск, ул. Гули Королевой, 3",
                "col5": "edinstvo-5@mail.ru",
                "col6": "единство.унисад.рф",
                "col7": "8(38475)2-74-23"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Остров надежды» ",
                "col4": "654007,  Кемеровская область - Кузбасс, г. Новокузнецк,\nул. Спартака, д. 19\n",
                "col5": "ostrov.nadezhdy.nk@yandex.ru",
                "col6": "  dd5-nvkz.kemobl.ru",
                "col7": "8(3843) 46-12-22 "
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное  казенное общеобразовательное учреждение для детей-сирот и детей, олставшихся без попечения родителей  «Детский дом-школа «Дом детства» ",
                "col4": "654040, Кемеровская область - Кузбасс,  г. Новокузнецк,\nул. Климасенко, д. 15\n",
                "col5": "nk.domdetstva@yandex.ru",
                "col6": "dd95.ucoz.ru",
                "col7": "8(3843) 53-50-29"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей  «Детский дом-школа «Созвездие» ",
                "col4": "654029, Кемеровская область - Кузбасс,  г. Новокузнецк,   ул. Всесторонняя, д. 46",
                "col5": "sozvezdie_nvkz@mail.ru",
                "col6": "66school.edusite.ru",
                "col7": "8(3843)72-36-60 "
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей  «Детский дом «Огонёк»",
                "col4": "653046, Кемеровская область – Кузбасс,          г. Прокопьевск,\nквартал Северный, д. 1\n",
                "col5": "ddogonyok@yandex.ru\n\n",
                "col6": "http:\/\/dd6deti.ucoz.ru",
                "col7": "8(3846) 63-16-13"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей  «Детский дом  «Дружба»",
                "col4": "653 045, Кемеровская область – Кузбасс,          \nг. Прокопьевск,\nул. Институтская, д. 58\n",
                "col5": "dom7d@mail.ru",
                "col6": "detskiidom7.kemobl.ru",
                "col7": "8(3846) 69-86-73"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное учреждение для детей сирот и детей, оставшихся без попечения родителей  «Детский дом «Радуга» ",
                "col4": "652000, Кемеровская область - Кузбасс, г. Юрга, ул. Машиностроителей, д. 14",
                "col5": "deti39@yandex.ru ",
                "col6": "https:\/\/detiraduga.kemobl.ru",
                "col7": "8(38451) 4-97-91"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное  казенное  учреждение для детей сирот и детей, оставшихся без попечения родителей «Детский дом «Юность»",
                "col4": "652780, Кемеровская область – Кузбасс, г. Гурьевск,\nул. Паризанская,д. д. 3\n",
                "col5": "ddom-yunost@yandex.ru",
                "col6": "dd-1.uogr.ru",
                "col7": "8(38463)5-47-09"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей  «Севский детский дом» ",
                "col4": "653225, Кемеровская область - Кузбасс, Прокопьевский район,  п. Севск, ул. Советская, д. 3",
                "col5": "sevsk-ddom@yandex.ru       ",
                "col6": "sevsk-ddom.edusite.ru",
                "col7": "8(3846) 64-53-68"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное  казенное  учреждение для детей-сирот и детей, оставшихся без попечения родителей  «Окуневский детский дом «Мечта»",
                "col4": "652390, Кемеровская область - Кузбасс, Промышленновский район, с. Окунево, \nпер. Почтовый, д. 12\n",
                "col5": "deti-mechta@rambler.ru        ",
                "col6": "мечта.промобр.рф",
                "col7": "8(384-42) 6-23-16"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Родник» ",
                "col4": "652990, Кемеровская область - Кузбасс, Таштагольский район,   г. Таштагол,  ул. Юбилейная, 9                        ",
                "col5": "shoria_tash3@mail.ru",
                "col6": "deti-rodnik.kemobl.ru",
                "col7": "8(38473 6-06-26"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное  казённое учреждение   для детей-сирот и детей, оставшихся без попечения родителей  «Детский дом «Островок» ",
                "col4": "652340, Кемеровская область - Кузбасс, Топкинский район,  д. Малый Корчуган,  ул. Центральная,    д. 27",
                "col5": " ddostrovoc@rambler.ru",
                "col6": "http:\/\/островок-топки.рф",
                "col7": "8(38454) 9-25-01"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное  казённое учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Росинка»",
                "col4": "652270,\nКемеровская область – Кузбасс, Чебулинский район, \nпгт. Верх-Чебула,\nул. Луговая, д. 25\n",
                "col5": "l.p.maksimova@rambler.ru  ",
                "col6": "http:\/\/ddomchebula.ucoz.com ",
                "col7": "8(3844) 42-14-01"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное бюджетное стационарное учреждение социального обслуживания «Мысковский детский дом-интернат для детей с ментальными нарушениями»",
                "col4": "652842, \nКемеровская область – Кузбасс,\nг. Мыски, \n10-ый квартал, д.5\n",
                "col5": "mddi@yandex.ru   ",
                "col6": "mddi.kemobl.ru",
                "col7": "8(38474) 4-30-48"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное бюджетное стационарное учреждение социального обслуживания «Юргинский детский дом-интернат для детей с ментальными нарушениями»",
                "col4": "652057, Кемеровская область – Кузбасс, г. Юрга, \nул. Космическая, д.12\n",
                "col5": "uddi-yrg@mail.ru ",
                "col6": "uddi-yrga.kemobl.ru",
                "col7": "8(38451) 6-79-93"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное бюджетное стационарное учреждение социального обслуживания «Евтинский  детский дом-интернат для детей с ментальными нарушениями»",
                "col4": "652672, Кемеровская Область - Кузбасс, Беловский район, село Евтино, Садовый пер., д.8",
                "col5": "evtinodom@mail.ru ",
                "col6": "http:\/\/evtino-detdom.kmr.socinfo.ru",
                "col7": "8(384-52) 51-2-83"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Умка»",
                "col4": "650001, Кемеровская область - Кузбасс, г. Кемерово, Инициативная ул., д.14а",
                "col5": "dd.umka@mail.ru",
                "col6": "http:\/\/домребенка42.рф          ",
                "col7": "8(3842) 25-07-60"
            },
            {
                "col1": "Кемеровская область - Кузбасс",
                "col2": "Министерство образования Кузбасса",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Кроха»",
                "col4": "1 здание: 654059, Кемеровская область - Кузбасс, Новокузнецк, Тореза, 90                                      2 здание: 654018, Кемеровская область - Кузбасс, г.  Новокузнецк, пр-кт Дружбы, д. 18 а",
                "col5": "ddkroha@bk.ru",
                "col6": "https:\/\/domreb1.my1.ru",
                "col7": " 8 (3843) 53-07-18            8 (3843) 71-48-40"
            },
        ],
    ],
    [
        [
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Центр помощи детям-сиротам и детям, оставшимся без попечения родителей, и содействия семейному устройству с.Бурмакино Кирово-Чепецкого района\"",
                "col4": "613000 Кировская обл., Кирово-Чепецкий р-он, с.Бурмакино ул. Вихарева, д. 67",
                "col5": "burmakino_korr@mail.ru",
                "col6": "\nhttps:\/\/shkolainternatburmakino-r43.gosweb.gosuslugi.ru\/",
                "col7": "(83361)  7-65-66"
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное  бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Центр помощи детям-сиротам и детям, оставшимся без попечения родителей, и содействия семейному устройству с. Великорецкое  Юрьянского  района»",
                "col4": "613636, Кировская область Юрьянский район с. Великорецкое, ул. Центральная, д.15",
                "col5": "welikordd@mail.ru",
                "col6": "http:\/\/www.velikordd.ru",
                "col7": "(83366) 6-21-29"
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Центр помощи детям-сиротам и детям, оставшимся без попечения родителей, и содействия семейному устройству имени Г.С. Плюснина с. Верховонданка Даровского района\"",
                "col4": "ул. Набережная, д. 7а, с. Верховонданка, Даровской район, Кировская область, 612162",
                "col5": "ver-internat@yandex.ru",
                "col6": "https:\/\/shkolainternatverxovondanka-r43.gosweb.gosuslugi.ru\/",
                "col7": "(83336) 5-15-31, 5-15-41, 5-15-46"
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Центр помощи детям-сиротам и детям, оставшимся без попечения родителей, и содействия семейному устройству г. Слободского\" ",
                "col4": "613150, Кировская область, г. Слободской, ул. Школьная, д. 3",
                "col5": "internat43@mail.ru",
                "col6": "https:\/\/shkolainternatslobodskoj-r43.gosweb.gosuslugi.ru\/ ",
                "col7": "(83362) 4-25-42 "
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Центр помощи детям, оставшимся без попечения родителей, и содействия семейному устройству «Надежда» г. Кирова»\n",
                "col4": "610030 г. Киров, ул. Заречная, д.5",
                "col5": ":nadezhdadetdom@mail.ru",
                "col6": "https:\/\/ds-nadezhda-kirov-r43.gosweb.gosuslugi.ru\/",
                "col7": "(8332) 40-16-70"
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное общеобразовательное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Средняя школа-интерант г. Сосновки Вятскополянского района\"",
                "col4": "612990, Кировская область, Вятскополянский район, г. Сосновка, ул. Спортивная, д. 8",
                "col5": "sosnovkainternat@mail.ru",
                "col6": "http:\/\/internats.ru",
                "col7": "(83334) 3-12-45, (83334) 3-27-09"
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Центр помощи детям-сиротам и детям, оставшимся без попечения родителей, и содействия семейному устройствус. Спас-Талица Оричевского района\"",
                "col4": "612080,Кировская область, Оричевский район, с. Спас-Талица, ул. Советская, д.2",
                "col5": "stalica@yandex.ru",
                "col6": "http:\/\/spastalicadd.ucoz.site",
                "col7": "(83354) 3-81-04"
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Центр помощи детям-сиротам и детям, оставшимся без попечения родителей, и содействия семейному устройству п. Ленинская Искра Котельничского района\"",
                "col4": "612641, РФ, Кировская область Котельничский район п. Ленинская Искра ул. Ленина 17-а",
                "col5": "ddspi@yandex.ru",
                "col6": "https:\/\/spitsinskiy-dd.ru\/",
                "col7": "(83342) 3-11-88, (83342) 3-11-89"
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Центр помощи детям-сиротам и детям, оставшимся без попечения родителей, и содействия семейному устройствупгт Тужа\"",
                "col4": "612200, Кировская обл, пгт Тужа, ул. Свободы, д.6",
                "col5": "tuzha_i-school@mail.ru",
                "col6": "http:\/\/tuzha-internat.ucoz.ru\/",
                "col7": "(83340) 2-23-07, 2-16-99"
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Центр помощи детям-сиротам и детям, оставшимся без попечения родителей, и содействия семейному устройствуг.Уржума\"",
                "col4": "613530 Кировская область, г.Уржум, ул.Некрасова. д.51",
                "col5": "urzhum-dd@mai.lru",
                "col6": "urzhumdd.ucoz.com",
                "col7": "(83363) 2-12-60"
            },
            {
                "col1": "Кировская область",
                "col2": "Министерство образования Кировской области",
                "col3": "Кировское  областное государственное бюджетное учреждение  для детей-сирот и детей, оставшихся  без попечения родителей, «Центр помощи детям-сиротам и детям, оставшимся без попечения родителей, и содействия семейному устройству № 1  г. Кирова»",
                "col4": "610008, г.Киров Нововятский район ул.Пушкина дом 27",
                "col5": "nov-internat1@mail.ru",
                "col6": "http:\/\/www.nov-internat1.ru",
                "col7": "(8332) 31-24-97"
            },
        ],
    ],
    [
        [
            {
                "col1": "Костромская область",
                "col2": "Департамент по труду и социальной защите населения Костромской области",
                "col3": "Государственное казённое учреждение для детей-сирот и детей, оставшихся без попечения родителей Костромской области \"Центр помощи детям, оставшимся без попечения родителей\" ",
                "col4": "156003, город Кострома,  улица Солоница, дом 9",
                "col5": "dd1_kos@dszn44.ru",
                "col6": "detdom44.ru",
                "col7": "(4942) 51-68-18 (4942)35- 57-17"
            },
            {
                "col1": "Костромская область",
                "col2": "Департамент по труду и социальной защите населения Костромской области",
                "col3": "Государственное казённое учреждение для детей-сирот и детей, оставшихся без попечения родителей Костромской области \" Волжский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "156003, Костромская область, город Кострома, посёлок Волжский, квартал 3, дом 27",
                "col5": "ddvol@dszn44.ru",
                "col6": " https:\/\/ волжский-центр.рф\/",
                "col7": "(4942)48-12-80"
            },
            {
                "col1": "Костромская область",
                "col2": "Департамент по труду и социальной защите населения Костромской области",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, Костромской области                          \" Ченцовский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "157932,   Костромская область, Красносельский район, д.Ченцы, ул. Молодежная, д.5",
                "col5": "ddchen@dszn44.ru",
                "col6": "https:\/\/chentsy-center.ru\/",
                "col7": "(49432) 2-51-35"
            },
            {
                "col1": "Костромская область",
                "col2": "Департамент по труду и социальной защите населения Костромской области",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, Костромской области \"Островский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "157900, Костромская область, Островский район, п. Островское, ул. Кинешемская, д. 24",
                "col5": "ddost@dszn44.ru",
                "col6": "http:\/\/ostrdetdom.ru",
                "col7": "(49438)-2-79-23"
            },
            {
                "col1": "Костромская область",
                "col2": "Департамент по труду и социальной защите населения Костромской области",
                "col3": "Государственное казенное учреждение \"Первомайский детский дом-интернат для умственно отсталых детей\"",
                "col4": "156553,Костромская область, Костромской район, Хутор 1 Мая, д.19",
                "col5": "ddpervom@dszn44.ru",
                "col6": "detsky-dom.ru",
                "col7": "(4942)-49-46-76"
            },
            {
                "col1": "Костромская область",
                "col2": "Негосударственная (частная) организация для детей-сирот, в том числе религиозная ",
                "col3": "Частное учреждение \"Ковалевский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "157811, Костромская облать, Нерехтский район, с. Ковалево, д. 5а",
                "col5": "kovalevo.detdom@gmail.com; kovalevo.detdom@mail.ru; ",
                "col6": "https:\/\/domkovalevo.ru",
                "col7": "(4942) 634800"
            },
        ],
    ],
    [
        [
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Краснодарского края «Афипский центр помощи детям, оставшимся без попечения родителей»           ",
                "col4": "353236, Краснодарский край, Северский район, пгт Афипский, ул. Школьная, 42  ",
                "col5": "dd_afips@mtsr.krasnodar.ru;",
                "col6": "http:\/\/afipskiy-dd.ru\/",
                "col7": "8(86166) 3-32-96"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Краснодарского края «Ахтырский центр помощи детям, оставшимся без попечения родителей»           ",
                "col4": "353300, Краснодарский край, Абинский район, пгт Ахтырский, ул.Мира, д.6",
                "col5": "dd_ahtirsk@mtsr.krasnodar.ru;",
                "col6": "http:\/\/homechildren.ru\/",
                "col7": "8(86150) 3-49-19"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Краснодарского края «Краснодарский комплесный центр помощи детям, оставшимся без попечения родителей»           ",
                "col4": "350089, Краснодарский край, город Краснодар, ул. Рождественская набережная,1\/1  ",
                "col5": "dd_rojdest@mtsr.krasnodar.ru",
                "col6": "http:\/\/rogdestvenskiy-dd.ru\/",
                "col7": "8(861) 261-92-62"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Краснодарского края «Медведовский центр помощи детям, оставшимся без попечения родителей, имени Героя труда Кубани А.Г. Цебулевской»           ",
                "col4": "352720, Краснодарский край, Тимашевский район, ст-ца Медведовская, ул. Телеграфная, 1",
                "col5": "dd_medved@mtsr.krasnodar.ru;",
                "col6": "http:\/\/m-d-d.ru\/",
                "col7": "8(86130) 7-10-39"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Краснодарского края «Ейский детский дом-интернат»         ",
                "col4": "353680, Краснодарский край, г. Ейск, ул.Советов, д.103  ",
                "col5": "ddi_eisk@mtsr.krasnodar.ru",
                "col6": "http:\/\/eyskiy-srcrn.ru\/",
                "col7": "8(86132) 2-19-85"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Краснодарского края «Кропоткинский детский дом- интернат»           ",
                "col4": "352392, Краснодарский край, Кавказский район, г.Кропоткин, ул.Журавлиная, д.10  ",
                "col5": "ddi_kropot@mtsr.krasnodar.ru;",
                "col6": "http:\/\/kropotkin-ddi.ru\/",
                "col7": "8(86138) 3-41-36"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Краснодарского края «Лабинский детский дом-интернат»           ",
                "col4": "352500, Краснодарский край, Лабинский район, г. Лабинск, ул. Красноармейская, д. 14\/1  ",
                "col5": "ddi_labinsk@mtsr.krasnodar.ru;",
                "col6": "http:\/\/labinskiy-ddi.ru\/",
                "col7": "8(86169) 3-36-09"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Краснодарского края «Отрадненский детский дом-интернат»           ",
                "col4": "352290, Краснодарский край, Отрадненский район, ст-ца Отрадная, ул. Пионерская, д.73",
                "col5": "dd_otrad@mtsr.krasnodar.ru",
                "col6": "http:\/\/otradnenskiy-ddi.ru\/",
                "col7": "8(86144) 3-35-17"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное общеобразовате льное            учреждение Краснодарского края      «Березанская школа-интернат для детей-сирот и детей, оставшихся без попечения родителей»           ",
                "col4": "353236, Краснодарский край, Выселковский район, ст-ца Березанская, ул.Пионерская, д.1  ",
                "col5": "Shi_berezan@mtsr.krasnodar.ru",
                "col6": "http:\/\/гкоушиберезанской.рф",
                "col7": "8(86157) 5-23-57"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное автономное     общеобразовательное учреждение Краснодарского края    «Новолеушков-ская школа-инетрнат с профессиональ-ным обучением»",
                "col4": "352070, Краснодарский край,      Павловский район,             ст. Новолеушковская,          ул. Калинина, 27",
                "col5": "shi_novoleush@mtsr.krasnodar.ru",
                "col6": "https:\/\/новолеушковскаяшколаинтернат.рф\/",
                "col7": "8(86191)4-43-33"
            },
            {
                "col1": "Краснодарский край",
                "col2": "Министерство труда и социального развития Краснодарского края ",
                "col3": "Государственное казенное учреждение Краснодарского края «Усть-Лабинский дом ребенка специализированный для детей с органическим поражением центральной нервной системы с нарушением психики\"",
                "col4": "352332, Краснодарский край, Усть-Лабинский район, г. Усть-Лабинск, ул. Воронежская, 1 ",
                "col5": "dr_ulab@mtsr.krasnodar.ru",
                "col6": "https:\/\/babyhome-ustlab.ru\/",
                "col7": "8(861 35)4-44-90"
            },
        ],
    ],
    [
        [
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей\n«Ачинский детский дом»",
                "col4": "662150, Красноярский край, г. Ачинск, \nул. Дзержинского, 31;\n662152, Красноярский край, г. Ачинск, \nул. Манкевича, 44 \n\n",
                "col5": "achhouse@mail.ru ",
                "col6": "http:\/\/achdetdom1.ucoz.ru\/ ",
                "col7": "8 (39151) 4-01-01,\n8 (39151) 7-96-79,\n8 (39151) 4-52-25"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Назаровский детский дом»",
                "col4": "662201, Красноярский край, г. Назарово, \nул. Лесная, 4 А\n",
                "col5": "nazdetdom01@mail.ru",
                "col6": "https:\/\/nazdd.krn.socinfo.ru\/",
                "col7": "8 (39155) 7-38-01"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Большеулуйский детский дом»",
                "col4": "662118, Красноярский край, Большеулуйский \nрайон, с. Сучково, \nул. Советская, 56\n",
                "col5": "sergeeva_75@list.ru",
                "col6": "https:\/\/buldd.krn.socinfo.ru ",
                "col7": "8 (39159) 2-93-41, 8 (39159) 2-93-18"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Канский детский дом им. Ю.А. Гагарина»",
                "col4": "663604, Красноярский край, г. Канск,\n ул. Куйбышева, 5 \n",
                "col5": "kansk.gagarin@yandex.ru",
                "col6": "http:\/\/acvariys.ru\/",
                "col7": "8 (39161) 3-21-76"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Бородинский детский дом»",
                "col4": "663981 Красноярский край, г. Бородино, \nул. Советская, 19 а \n",
                "col5": "borodinoddom@mail.ru",
                "col6": "http:\/\/borodinodom.ru\/",
                "col7": "8 (39161) 4-29-20,\n8 (39161) 4-29-21"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Ирбейский детский дом»",
                "col4": "663655, Красноярский край, Ирбейский район, \nс. Ивановка,\nул. Интернациональная, 1\n",
                "col5": "KGOUirbeyDD@yandex.ru",
                "col6": "http:\/\/ddomsirot.ucoz.com\/",
                "col7": "8 (39174) 3-35-18"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Павловский детский дом»",
                "col4": "663826, Красноярский край, Нижнеингашский  \nрайон, д. Павловка,\nул. Центральная, д. 14\n",
                "col5": "pavlovka663826@yandex.ru",
                "col6": "https:\/\/pavldd.ru\/",
                "col7": "8 (39171) 2-22-21"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Партизанский детский дом»",
                "col4": "663546, Красноярский край, Партизанскийрайон, с. Стойба, \nул. Советская, 9 \n",
                "col5": "PartDom@mail.ru",
                "col6": "http:\/\/partizandetidom.ru\/",
                "col7": "8 (39140) 2-38-21,\n8 (39140) 2-38-99"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Дзержинский детский дом»",
                "col4": "663700, Красноярский край, Дзержинский район, с. Дзержинское \nпер. Школьный, 5 А\n",
                "col5": "Dz_dd@krasmail.ru",
                "col6": "https:\/\/dz-dd.ru\/",
                "col7": "8 (39167) 9-11-32"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Зеленогорский детский дом»",
                "col4": "663690, Красноярский край, г. Зеленогорск,\nул. Горького, 7\n",
                "col5": "kgbouzeldd@krasmail.ru",
                "col6": "https:\/\/zeldd.krn.socinfo.ru\/",
                "col7": "8 (39169) 4-01-38"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Минусинский  детский дом»",
                "col4": "662606, Красноярский край, г. Минусинск, \nул. Народная, 35\n",
                "col5": "detiminusa@yandex.ru",
                "col6": "https:\/\/dd-minusinsk.krn.socinfo.ru\/",
                "col7": "8 (39132) 4-05-61"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Шушенский детский дом»",
                "col4": "662712, Красноярский край, Шушенский район, п. Шушенское, \nул. Первомайская, 113 \n",
                "col5": "intershush@yandex.ru",
                "col6": "https:\/\/shushdd.ru\/",
                "col7": "8 (39139) 3-58-92,\n8 (39139) 3-44-60"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Сосновоборский детский дом»",
                "col4": "662500, Красноярский край, г. Сосновоборск, ул. 9 Пятилетки, 13",
                "col5": "sdet24@mail.ru",
                "col6": "http:\/\/sosn-detdom.ucoz.ru\/",
                "col7": "8 (39131) 2-66-62      \n8 (39131) 2-15-76           \n8 (39131) 2-00-74"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Балахтинский детский дом»",
                "col4": "662356, Красноярский край, Балахтинский район, п. Приморск, \nул. Ленина, 1\n",
                "col5": "lespoiren@mail.ru",
                "col6": "http:\/\/balahtadd.ru\/",
                "col7": "8 (39148) 3-22-14"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Есауловский детский дом»",
                "col4": "662518, Красноярский край, Березовский район, с. Есаулово, \nул. Просвещения, 7\n",
                "col5": "sta@esauldom.ru",
                "col6": "http:\/\/esauldom.ru\/",
                "col7": "8 (39175) 9-32-83"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Железногорский детский  дом»",
                "col4": "662970, Красноярский край, г. Железногорск,\nул. Восточная, 19 а\n",
                "col5": "gem@zhdd26.ru",
                "col6": "https:\/\/zhdd26.ru\/",
                "col7": "8 (3919) 74-96-05"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей\n«Детский дом семейного типа»",
                "col4": "\n663090, Красноярский край, г. Дивногорск, \nул. Б. Полевого, 11\n",
                "col5": "kolesnikova-nv@list.ru",
                "col6": "http:\/\/семейный-детский-дом-колесниковых.рф",
                "col7": "8 (39144) 3-84-37"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Красноярский  детский дом \n№ 1»",
                "col4": "660059, Красноярский край, г. Красноярск, \nул. Вавилова, 90 корпус 2; \n660069, Красноярский край, г Красноярск, \nул. Московская, 6 А \n",
                "col5": "ddsh59@yandex.ru",
                "col6": "http:\/\/kdd1.ru\/",
                "col7": "8 (391) 201-41-81"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Красноярский детский дом № 2 им. И.А. Пономарева»",
                "col4": "660130, Красноярский край, г. Красноярск, \nул. Садовая, 10 Д\n",
                "col5": "detdom2@rambler.ru",
                "col6": "https:\/\/detdom2krsk.ru\/",
                "col7": "8 (391) 246-49-09"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Красноярский детский дом «Самоцветы»",
                "col4": "660078, Красноярский край, г. Красноярск, \nул. Парашютная, 16\n",
                "col5": "samocveti124@mail.ru",
                "col6": "http:\/\/www.samocveti124.ru\/",
                "col7": "8 (391) 233-86-60"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Емельяновский детский дом»",
                "col4": "663020, Красноярский край, п. Емельяново, \nул. Декабристов, 105 А \n",
                "col5": "emddom@yandex.ru",
                "col6": "http:\/\/emddom.ucoz.net\/",
                "col7": "8 (391) 234-72-80"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Лесосибирский детский дом\nим. Ф.Э. Дзержинского»",
                "col4": "662544, Красноярский край, г. Лесосибирск, \nул. Победы, 39 \n",
                "col5": "lskdetdom@mail.ru",
                "col6": "http:\/\/ldd.ucoz.ru\/",
                "col7": "8 (39145) 6-27-50"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Норильский детский дом»",
                "col4": "663319, Красноярский край, г. Норильск, \nул. Нансена, 74\n",
                "col5": "nordetdom@mail.ru",
                "col6": "http:\/\/ndd.ucoz.com\/",
                "col7": "8 (3919) 22-58-72"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Дудинский детский дом»",
                "col4": "647000, Красноярский край, г. Дудинка,\nул. Щорса, 7\n",
                "col5": "Dir.taimyr1.8@mail.ru",
                "col6": "https:\/\/дудинский-детский-дом.таймыр-обр.рф",
                "col7": "8 (39191) 5-74-00"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Ванаварский детский дом»",
                "col4": "648490, Красноярский край, Эвенкийский район, \nс. Ванавара, \nул. Пайгинская, 6\n",
                "col5": "detdom-direktor@vanavara.evenkya.ru",
                "col6": "https:\/\/vana-detdom.ru\/",
                "col7": "8 (39177) 3-10-43"
            },
            {
                "col1": "Красноярский край",
                "col2": "Министерство образования Красноярского края",
                "col3": "Краевое государственное казенное учреждение «Психоневрологический интернат для детей «Подсолнух»",
                "col4": "660130, Красноярский край,\nг. Красноярск,\nул. Сады, 12.",
                "col5": "detdom4-kras@mail.ru ",
                "col6": "https:\/\/podsolnukh.krn.socinfo.ru\/",
                "col7": " \n8 (391) 247-77-87"
            },
            {
                "col1": "Красноярский край",
                "col2": "Негосударственная (частная) организация для детей-сирот, в том числе религиозная",
                "col3": "Частное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом им. Х. М. Совмена»",
                "col4": "660032 г. Красноярск, \nул. Дубенского, 11\n",
                "col5": "sovmen@mail.ru",
                "col6": "https:\/\/домсовмена.рф",
                "col7": "8 (391) 266-83-90"
            },
        ],
    ],
    [
        [
            {
                "col1": "Курганская область",
                "col2": "Департамент социальной политики Курганской области",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Центр помощи детям, оставшимся без попечения родителей № 1»",
                "col4": "640008, Курганская область, г. Курган, \nпр-кт Конституции, д.44 ",
                "col5": "kurgan@cpd45.ru",
                "col6": "http:\/\/kurgan-dd.kurg.socinfo.ru\/",
                "col7": "8 (3522) 44-43-82;\n8 (35231)3-16-82"
            },
            {
                "col1": "Курганская область",
                "col2": "Департамент социальной политики Курганской области",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Центр помощи детям, оставшимся без попечения родителей № 2»",
                "col4": "641730, Курганская область, Далматовский район, г. Далматово, \nул. Свердлова, д.24 ",
                "col5": "goyddd@mail.ru",
                "col6": "http:\/\/dalmatovodetdom.kurg.socinfo.ru\/about",
                "col7": "8 (35252) 3-75-50"
            },
            {
                "col1": "Курганская область",
                "col2": "Департамент социальной политики Курганской области",
                "col3": "Государственное бюджетное учреждение «Областной социально-реабилитационный центр для несовершеннолетних»",
                "col4": "640011, Курганская область, г. Курган, \nб-р Мира, д.20 ",
                "col5": "post@osrc45.ru",
                "col6": "http:\/\/sz601.gov45.ru\/",
                "col7": "8 (3522) 25-06-75"
            },
            {
                "col1": "Курганская область",
                "col2": "Департамент социальной политики Курганской области",
                "col3": "Государственное бюджетное учреждение «Шадринский детский дом-интернат для умственно отсталых детей»",
                "col4": "641886, Курганская область, г. Шадринск, \nМальцевский тракт, д.22 ",
                "col5": "shddi@mail.ru",
                "col6": "http:\/\/sz412.gov45.ru\/",
                "col7": "8 (35253) 3-68-75"
            },
            {
                "col1": "Курганская область",
                "col2": "Департамент социальной политики Курганской области",
                "col3": "Государственное бюджетное учреждение «Сумкинский дом-интернат»",
                "col4": "641770, Курганская область, Половинский район, с. Сумки, \nШкольная ул., д.1а ",
                "col5": "sumskiydd@mail.ru",
                "col6": "http:\/\/sz410.gov45.ru\/",
                "col7": "8 (35238) 9-86-96"
            },
        ],
    ],
    [
        [
            {
                "col1": "Курская область",
                "col2": "Министерство социального обеспечения, материнства и детства Курской области ",
                "col3": "Областное бюджетное учреждение стационарного социального обслуживания Курской области \"Беловский детский специализированный психоневрологический дом-интернат\"",
                "col4": "307921, Курская область, Беловский район, п. Коммунар, ул. Полевая, д. 1 ",
                "col5": "svetlana.timofeeva64@yandex.ru",
                "col6": "belovsky-detdom.ru",
                "col7": "8(47149) 2-41-17"
            },
            {
                "col1": "Курская область",
                "col2": "Министерство социального обеспечения, материнства и детства Курской области ",
                "col3": "Областное бюджетное учреждение стационарного социального обслуживания Курской области \"Железногорский детский дом-интернат \"Надежда\"",
                "col4": "307147 Курская область,\nг. Железногорск,\n ул. Привокзальная, здание 7\n",
                "col5": "dom-nadeshda@mail.ru",
                "col6": "https:\/\/dom-internatnadeshda.ru\/",
                "col7": "8 (47148) 3-29-82"
            },
            {
                "col1": "Курская область",
                "col2": "Министерство социального обеспечения, материнства и детства Курской области ",
                "col3": "Областное казенное учреждение социального обслуживания \"Центр содействия семейному воспитанию \"Мы вместе\"",
                "col4": "307340, Курская область, Рыльский район,                                  с. Ивановское, ул. Тагинка,                 д. 98",
                "col5": "rylsk731@mail.ru",
                "col6": "http:\/\/ivanovskschool.ucoz.ru\/",
                "col7": "8 (47152)7-72-46"
            },
            {
                "col1": "Курская область",
                "col2": "Министерство социального обеспечения, материнства и детства Курской области ",
                "col3": "Областное казенное\nучреждение социального обслуживания\n\"Центр содействия семейному воспитанию \"Первоцвет\"\n",
                "col4": "305523, Курская область, Курский район, д. 1-е Цветово",
                "col5": "novoposelenov@yandex.ru ",
                "col6": "novoposelen.narod.ru",
                "col7": "8 (4712) 59-22-59"
            },
            {
                "col1": "Курская область",
                "col2": "Министерство социального обеспечения, материнства и детства Курской области ",
                "col3": "Областное казенное учреждение социального обслуживания «Специализированный центр содействия семейному воспитанию \"Аистёнок\"",
                "col4": "305008, г.Курск, ул.Пучковка, д.38",
                "col5": "osdr2012@yandex.ru",
                "col6": "dom.swsu.ru",
                "col7": "8 (4712) 52-65-36"
            },
        ],
    ],
    [
        [
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Пикалевский ресурсный центр по содействию семейному устройству\"",
                "col4": "187683, Ленинградская область, Бокситогорский район, д. Пикалево, 1 мкр, д. 20",
                "col5": "anisimovodd@mail.ru",
                "col6": "https:\/\/anisimovorc.ru\/",
                "col7": "8 931 540 17 50"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Выборгский ресурсный центр по содействию семейному устройству\"",
                "col4": "188804, Ленинградская область, \n г.  Выборг, \nулица Клубная дом 5 г\n",
                "col5": "d. dom-vbg@mail.ru",
                "col6": "https:\/\/вбр.ресурсный-центр-ло.рф",
                "col7": "8-813-78 -70 283, 8-813-78-70200"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Каложицкий ресурсный центр по содействию семейному устройству\"",
                "col4": "188430, Ленинградская обл.,\nВолосовский район, п.Каложицы, д.20а\n",
                "col5": "KalozhiciDD@yandex.ru",
                "col6": "https:\/\/каложицкий-рц.рф",
                "col7": "8-813-73-61117,8-813-73- 49044"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Ивангородский центр по содействию семейному воспитанию для детей с ограниченными возможностями здоровья\"",
                "col4": "188490 Ленинградская область, Кингисеппский район, \nг. Ивангород, ул. Восточная, д. 13\n",
                "col5": "ivg-cssv@bk.ru",
                "col6": "https:\/\/ivg-cssv.ru",
                "col7": "8-813-75-52326;8-813-75-52-898;8-813-75-51-898"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Кингисеппский ресурсный центр по содействию семейному устройству\"",
                "col4": "188480, Ленинградская область, Кингисеппский район, город Кингисепп, улица Жукова, дом 6Б",
                "col5": "detctvo2008@yandex.ru",
                "col6": "кнг.ресурсный-центр-ло.рф",
                "col7": "8-813-75-29448"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Никольский ресурсный центр по содействию семейному устройству\"",
                "col4": "187026, Ленинградская область, Тосненский район, г. Никольское, Советский пр-кт, дом 203",
                "col5": "detdom1965@yandex.ru",
                "col6": "https:\/\/нкл-ресурсный-центр-ло.рф",
                "col7": "8-813-61-54876"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Свирьстройский ресурсный центр по содействию семейному устройству\"",
                "col4": "187726, ленинградская область, Лодейнопольский район, г.п. Свирьстрой, ул. Сосновая, д.2",
                "col5": "Svir-rc@yandex.ru",
                "col6": "https:\/\/Свирь-рц.рф",
                "col7": "8-813-64 38133"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Сиверский  ресурсный центр по содействию семейному устройству\"",
                "col4": "188330 Ленинградская область, Гатчинский район, пгт Сиверский, улица Красная, дом 18",
                "col5": "Siv.detdom@yandex.ru",
                "col6": "https:\/\/СВК.РЕСУРСНЫЙ-ЦЕНТР-ЛО.РФ",
                "col7": "8-813-71-44397"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Тихвинский  ресурсный центр по содействию семейному устройству\"",
                "col4": "187556, Ленинградская область, Тихвинский район, г.Тихвин, \nмкр. 4, д.42А\n",
                "col5": "tihvin_dd@mail.ru",
                "col6": "http:\/\/тхв-ресурсный-центр-ло.рф",
                "col7": "8-813-67-70670"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Государственное бюджетное учреждение Ленинградской области центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Толмачевский ресурсный центр по содействию семейному устройству\"",
                "col4": "188255, Ленинградская область, Лужский район, пгт. Толмачево, ул. Парк, д. 2",
                "col5": "tolmachevskiydd@mail.ru",
                "col6": "http:\/\/xn--k1acq.xn-----mlcbspjdllbfgbui0d1e.xn--p1ai\/",
                "col7": "8-813-2-74207"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по социальной защите населения Ленинградской области",
                "col3": "Ленинградское областное государственное бюджетное учреждение \"Ленинградский областной многопрофильный реабилитационный центр для детей-инвалидов\"",
                "col4": "188760, Ленинградская область, Приозерский район, город Приозерск, Ленинградское шоссе, 63",
                "col5": "priozerskddi@yandex.ru",
                "col6": "https:\/\/ddi-priozersk.47social.ru\/",
                "col7": "8-813-79-352314"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по здравоохранению Ленинградской области",
                "col3": "Государственное казенное учреждение здравоохранения Ленинградской области «Всеволожский специализированный центр для детей»",
                "col4": "188641 Ленинградская область, город Всеволожск, Христиновский проспект, дом 2а",
                "col5": "pndr@mail.ru",
                "col6": "vsevsdr.ru",
                "col7": "8-813-70-30593"
            },
            {
                "col1": "Ленинградская область\n",
                "col2": "Комитет по здравоохранению Ленинградской области",
                "col3": "Государственное казенное учреждение здравоохранения  Ленинградской области\n«Лужский специализированный  центр для детей»\n",
                "col4": "188230, Ленинградская область, \nг. Луга, ул. Тоси Петровой, д. 11.\n",
                "col5": "luga_dom_rebenka@mail.ru",
                "col6": "http:\/\/лужскийдомребенка.рф\/",
                "col7": "8 (81372) 21043, 8 (81372) 22374"
            },
        ],
    ],
    [
        [
            {
                "col1": "Липецкая область",
                "col2": "Управление образования и науки Липецкой области",
                "col3": "Государственное (областное) бюджетное учреждение \"Центр помощи детям, оставшимся без попечения родителей, и содействия семейному устройству имени Героя Российской Федерации Эдуарда Борисовича Белана\"",
                "col4": "398006, Липецкая обл.,  г. Липецк,\nул. Коммунистическая, д. 27\n",
                "col5": "gboou-dd@lipetsk.ru ",
                "col6": "http:\/\/www.cpd48.ru\/ ",
                "col7": "8 (4742) 45-55-11,\n73-09-56\n"
            },
            {
                "col1": "Липецкая область",
                "col2": "Управление образования и науки Липецкой области",
                "col3": "Государственное (областное) бюджетное учреждение \"Лебедянский центр помощи детям, оставшимся без попечения родителей, и содействия семейному устройству\"",
                "col4": "399610, Липецкая обл., г. Лебедянь,\nул. Победы,11а\n",
                "col5": "lebedyancpd@mail.ru ",
                "col6": "http:\/\/lebedyan-centr.ru\/",
                "col7": "8 (47466) 5-20-27\n"
            },
            {
                "col1": "Липецкая область",
                "col2": "Управление образования и науки Липецкой области",
                "col3": "Государственное бюджетное общеобразовательное учреждение Липецкой области \"Специальная школа-интернат г. Ельца\" ",
                "col4": "399778, Липецкая область, г. Елец, ул. Ростовская, д. 1",
                "col5": "elrsc25@yelets.lipetsk.ru   ",
                "col6": "https:\/\/shkola-internat-elets.gosuslugi.ru\/\/",
                "col7": "8 (47467)\n5-78-10\n"
            },
            {
                "col1": "Липецкая область",
                "col2": "Управление образования и науки Липецкой области",
                "col3": "Государственное бюджетное общеобразовательное учреждение Липецкой области \"Специальная школа-интернат с. Вторые Тербуны\"",
                "col4": "399551, Липецкая область, Тербунский район,\nс. Вторые Тербуны,\nул. Советская, д. 54\n",
                "col5": "te2e@mail.ru",
                "col6": "http:\/\/internat2e.ru\/",
                "col7": "8 (47474) 2-83-84"
            },
            {
                "col1": "Липецкая область",
                "col2": "Управление образования и науки Липецкой области",
                "col3": "ГОАОУ «Центр непрерывного образования обучающихся с особыми образовательными потребностями \"Траектория\"",
                "col4": "399059, Липецкая область, г. Грязи, ул. Партизанская, д. 2",
                "col5": "centr.t48@gmail.com'",
                "col6": "https:\/\/sko-griazi.ru\/",
                "col7": "8 (47461) 2-66-05"
            },
        ],
    ],
    [
        [
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "Государственное бюджетное  общеобразовательное учреждение Луганской Народной Республики \"Перевальская специальная (коррекционная) школа-интернат\"",
                "col4": "294300, М.О.Перевальский, г.Перевальск, ул.Победы, д.45",
                "col5": "perevalskinternat2008@rambler.ru",
                "col7": "8857-41-4-21-56"
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "Государственное бюджетное общеобразовательное учреждение Луганской Народной Республики \"Червонопартизанская специальная (коррекционная) школа-интернат\"",
                "col4": "294830, ул. Гагарина, д. 25, г. Червонопартизанск, м.о. Свердловский, Луганская Народная Республика",
                "col5": "chshi2017@yandex.ru",
                "col6": "https:\/\/чскши.школа-лнр.рф\/",
                "col7": 79591134235
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "ГБОУ ЛНР \"Счастьенская санаторная школа-интернат\"",
                "col4": "291480г.о.г.Луганск,г.Счастье,ул.Республиканская , 55",
                "col5": "schastye-internat@yandex.ru",
                "col6": "https:\/\/счастьесанаторий.школа-лнр.рф\/",
                "col7": "8572 96-00-90"
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "Государственное бюджетное общеобразовательное учреждение Луганской Народной Республики \"Антрацитовская специальная (коррекционная) школа-интернат\"",
                "col4": "294613, Луганская Народная Республика, м.о. Антрацитовский, г. Антрацит,                   ул. Строительная,11",
                "col5": "specinter@yandex.ru",
                "col6": "https:\/\/аскши.школа-лнр.рф\/",
                "col7": "(857-31) 2-90-49"
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "Государственное бюджетное общеобразовательное учреждение Луганской Народной Республики \"Лутугинская специальная (коррекционная) школа-интернат\"",
                "col4": "292000 Луганская Народная Республика, м.о. Лутугинский, г. Лутугино, ул. Ленина, д.126",
                "col5": "internat.lutugino@yandex.ru",
                "col6": "https:\/\/лутскши.школа-лнр.рф\/",
                "col7": "(0236)24444"
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "Государственное бюджетное общеобразовательное учреждение Луганской Народной Республики \"Луганская специальная (коррекционная) школа-интернат\" ",
                "col4": "291034 Луганская Народная Республика,Г.О. Луганский,г.Луганск, ул.Ватутина, д.67А",
                "col5": "lugintergluh2022@yandex.ru",
                "col6": "https:\/\/лугскши.школа-лнр.рф\/",
                "col7": 79591954726
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "Государственное бюджетное общеобразовательное учреждение Луганской Народной Республики «Стахановская основная школа-интернат № 2»",
                "col4": "294008, Луганская Народная Республика, г. Стаханов, ул. Шоссейная, д. 14",
                "col5": "s.shkola-internat2@yandex.ru",
                "col6": "https:\/\/соши2.школа-лнр.рф\/",
                "col7": "(0244) 4-13-62, 4-02-41"
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "ГБОУ ЛНР \"Ирминская специальная (коррекционная) школа-интернат\"",
                "col4": "294092 Луганская Народная Республика, г. о. Стахановский, г.Ирмино, у. Межпланетная, д. 11",
                "col5": "irmino_internat@mail.ru",
                "col6": "https:\/\/искши.школа-лнр.рф\/",
                "col7": 89591148173
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "Государственное бюджетное общеобразовательное учреждение Луганской Народной Республики \"Луганский детский дом\"",
                "col4": "переулок Крупской, 17, г. Луганск, Луганская Народная Республика, 291002",
                "col5": "lodb1@mail.ru",
                "col6": "https:\/\/детский дом.школа-лнр.рф",
                "col7": "8(8572)93-28-79"
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "ГБОУ ЛНР \"Краснодонский учебно-воспитательный комплекс \"Забота\"",
                "col4": "294430,  Луганская Народная Республика, м.о. Краснодонский, пгт. Краснодон, ул. Садовая, д.1",
                "col5": " s.internat@mail.ru",
                "col6": "https:\/\/кши.школа-лнр.рф\/",
                "col7": 79591451390
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство образования и науки Луганской Народной Республики",
                "col3": "Государственное казенное учреждение здавоохранения  \"Луганский республиканский дом ребенка\" Луганской Народной Республики",
                "col4": "291002  Луганская Народная Республика,  г.Луганск,  пл. Ленина,43а",
                "col5": "lodr2@уa.ru",
                "col7": "8 (8572) 42-18-99,       42-17-96"
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство труда и социальной политики Луганской Народной Республики",
                "col3": "Государственное учреждение Луганской Народной Республики  «Краснодонский детский дом-интернат»",
                "col4": "294405, Луганская Народная Республика, м.о. Краснодонский, г. Краснодон, ул. Рыбалко, д. 1 ",
                "col5": "Krasnodondetdom@mail.ru",
                "col7": "(0235) 6-24-69, 6-35-63"
            },
            {
                "col1": "Луганская Народная Республика",
                "col2": "Министерство труда и социальной политики Луганской Народной Республики",
                "col3": "Государственное Бюджетное Учреждение Луганской Народной Республики \"Ровеньковский дом-интергнат для людей с особыми потребностями\"\n",
                "col4": "294707, Луганская Народная Республика, г.о. город Ровеньки, г. Ровеньки, ул. Дзержинского, д. 92 ",
                "col5": "detdomrvn@mail.ru",
                "col7": "(0233) 5-40-55"
            },
        ],
    ],
    [
        [
            {
                "col1": "Магаданская область",
                "col2": "Министерство образования Магаданской области",
                "col3": "Магаданское областное государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом «Надежда»",
                "col4": "685000 г. Магадан, ул. Арманская, д. 49 корп. 2",
                "col5": "detsk_dom@mail.ru",
                "col6": "http:\/\/nadezhda.detckiydom.ru\/",
                "col7": "8 (4132) 637500"
            },
            {
                "col1": "Магаданская область",
                "col2": "Министерство образования Магаданской области",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Магаданский областной детский дом»",
                "col4": "686010 Магаданская обл., п. Ола, ул. Лесная, д. 12а",
                "col5": "detki2010@mail.ru",
                "col6": "http:\/\/modd.ucoz.net",
                "col7": "8 (41341) 25712"
            },
            {
                "col1": "Магаданская область",
                "col2": "Министерство образования Магаданской области",
                "col3": "Государственное казенное общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей, обучающихся по адаптированным образовательным программам «Магаданская областная школа-интернат»",
                "col4": "686018 г. Магадан, п. Сокол, ул. Гагарина, д. 19",
                "col5": "skolasokolin@mail.ru",
                "col6": "https:\/\/internat-sokol.ru\/",
                "col7": "8 (4132) 603743"
            },
            {
                "col1": "Магаданская область",
                "col2": "Министерство образования Магаданской области",
                "col3": "Областное государственное казенное учреждение для детей с ментальными нарушениями в развитии «Магаданский детский дом-интернат»",
                "col4": "685004 г. Магадан, ул. Арманская, д. 27",
                "col5": "magddi@mail.ru",
                "col6": "https:\/\/gku-ddi.webnode.ru\/",
                "col7": "8 (4132) 637825"
            },
            {
                "col1": "Магаданская область",
                "col2": "Министерство образования Магаданской области",
                "col3": "Областное государственное казенное учреждение \"Магаданский областной дом ребенка специализированный\"",
                "col4": "685030 г. Магадан, ул. Лукса, 2 корп. 1",
                "col5": "guzmodrs@mail.ru",
                "col6": "https:\/\/dr.49med.ru\/",
                "col7": "8 (4132) 658996"
            },
        ],
    ],
    [
        [
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию \"Алые паруса\" Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Алые паруса\")",
                "col4": "127562, г. Москва, ул. Декабристов, дом 8, корпус 3;\n127081, г. Москва, Ясный пр., д. 24-А",
                "col5": "cssv-alyeparusa@mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/alye_parusa\/",
                "col7": "8 (499) 907-93-56"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию \"Берег надежды\" Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Берег надежды\")",
                "col4": "119634, г. Москва, ул. Шолохова, д.6, корп. 3;\n119620, г. Москва, Солнцевский просп., д. 8-А",
                "col5": "beregnadezhdy@social.mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/bereg_nadezhdy\/",
                "col7": " 8 (495) 732-56-12,\n8 (495) 934-94-96"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию \"Вертикаль\" Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Вертикаль\")",
                "col4": "117638, г. Москва, Криворожский проезд, д.1, стр.1",
                "col5": "cssv-vertical@mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/vozrozhdeniye\/",
                "col7": "8 (499) 613-07-54"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию \"Каховские ромашки\" Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Каховские ромашки\")",
                "col4": "117303, г. Москва, Каховская ул., д.2, стр.3",
                "col5": "cssv-kromashki@social.mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/kahovskie_romashki\/",
                "col7": " 8 (495) 318-16-46"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию \"Маяк\" Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Маяк\")",
                "col4": "107143, г. Москва, Открытое шоссе, д.29, корп.12;\n107150, г. Москва, ул. Лосиноостровская, д. 29, стр.1",
                "col5": "info-mayak@social.mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/mayak\/",
                "col7": " 8 (499) 160-16-01"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию \"Наш дом\" Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Наш дом\")",
                "col4": "121309, г. Москва, Новозаводская ул., д.19 а, стр.2;\n119602, г. Москва, ул Академика Анохина д 28 к 2",
                "col5": "cssv-nashdom@mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/nash_dom\/",
                "col7": "8 (499) 148-36-21"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию \"Полярная звезда\" Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Полярная звезда\")",
                "col4": "127282, г. Москва, Полярная ул., д.54 а",
                "col5": "cssv-polyarzvezda@mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/polyarnaya_zvezda\/",
                "col7": " 8 (499) 473-34-41"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию \"Радуга\" Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Радуга\")",
                "col4": "107497, г. Москва, ул. Байкальская, д.48;\n111539, г. Москва, ул. Реутовская, д.24 б;\n111402, г. Москва, аллея Жемчуговой, д. 3А",
                "col5": " raduga@social.mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/raduga\/",
                "col7": "8 (495) 469-40-69,\n8 (495) 770-08-44"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию \"Сколковский\" Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Сколковский\")",
                "col4": "121353, г. Москва, Сколковское шоссе, д.6;\n119517, г. Москва, ул. Нежинская, д. 12\n117420, г. Москва, Профсоюзная ул., д.47, корп.2",
                "col5": "ddi24@mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/skolkovskiy\/",
                "col7": "8 (495) 446-13-94"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы  Центр содействия семейному воспитанию \"Спутник\",  Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Спутник\")",
                "col4": "109316, г. Москва, ул. Мельникова, 4, стр. 2",
                "col5": "cssv-sputnik@social.mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/sputnik\/",
                "col7": "8 (495) 676-03-88"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы  Центр содействия семейному воспитанию \"Центральный\",  Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ \"Центральный\")",
                "col4": "119017, г. Москва, ул. Пятницкая, д. 40-42, корп. 1;\n111538, г. Москва, ул. Вешняковская, д. 27-А ;\n107113, г. Москва, 1-й Лучевой просек, д. 10;\n125167, г. Москва, Красноармейская ул., д. 1а",
                "col5": "Centr-deti@social.mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/centralniy\/",
                "col7": "8 (495) 951-23-84"
            },
            {
                "col1": "Москва",
                "col2": "Департамент труда и социальной защиты населения города Москвы",
                "col3": "Государственное бюджетное учреждение города Москвы Центр содействия семейному воспитанию имени Ю.В.Никулина  Департамента труда и социальной защиты населения города Москвы (ГБУ ЦССВ имени Ю.В. Никулина)",
                "col4": "109378, г. Москва, Волгоградский просп. д.169, корп.2;\n109439, г. Москва, ул. Юных Ленинцев, д. 96",
                "col5": "shi15@mos.ru",
                "col6": "https:\/\/usynovi-moskva.ru\/our_centers\/cssv_imeni_nikulina\/",
                "col7": "8 (495) 919-29-31"
            },
            {
                "col1": "Москва",
                "col2": "Негосударственная (частная) организация для детей-сирот, в том числе религиозная",
                "col3": "Автономная некоммерческая организация комплексного социального обслуживания «Православный Свято-Софийский социальный дом» (АНО \"Свято-Софийский социальный дом\")",
                "col4": "119311, г. Москва, ул. Крупской, д. 12а",
                "col5": "info@domik.today",
                "col6": "https:\/\/domik.today\/",
                "col7": "8 (499) 133-87-25"
            },
            {
                "col1": "Москва",
                "col2": "Негосударственная (частная) организация для детей-сирот, в том числе религиозная",
                "col3": "Частное учреждение \"Православный Елизаветинский детский дом (смешанный) для девочек\"  (ЧУ \"Православный Елизаветинский детский дом (смешанный) для девочек\")",
                "col4": "119017, г. Москва, ул. Большая Ордынка,  д. 34,  стр . 7",
                "col5": "elizdeti@gmail.com",
                "col6": "https:\/\/www.mmom.ru\/sotsialnoe-sluzhenie\/sotsialnye-organizatsii\/elizavetinskiy-detskiy-dom.html",
                "col7": "8 (499) 704-20-09"
            },
        ],
    ],
    [
        [
            {
                "col1": "Московская Область",
                "col2": "нет организаций для детей-сирот",
                "col3": "-",
                "col4": "-",
                "col5": "-",
                "col6": "-",
                "col7": "-"
            },
        ],
    ],
    [
        [
            {
                "col1": "Мурманская область",
                "col2": "Министерство образования и науки Мурманской области",
                "col3": "Государственное областное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Мурманский центр помощи детям, оставшимся без попечения родителей, \"Ровесник\"",
                "col4": "183010,  Мурманская область, город Мурманск, ул. Марата, дом 19",
                "col5": "detidom5@mail.ru",
                "col6": "https:\/\/ddrovesnik.ru\/",
                "col7": "8 (8152) 23-98-34"
            },
            {
                "col1": "Мурманская область",
                "col2": "Министерство образования и науки Мурманской области",
                "col3": "Государственное областное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Мурмашинский центр помощи детям, оставшимся без попечения родителей, \"Журавушка\"",
                "col4": "184358, Россия, Мурманская область, Кольский р-он, поселок городского типа Мурмаши, ул. Молодежная, д. 1А",
                "col5": "juravushka95@mail.ru",
                "col6": "https:\/\/журавушка51.рф\/",
                "col7": "8(815-53) 79-142"
            },
            {
                "col1": "Мурманская область",
                "col2": "Министерство образования и науки Мурманской области",
                "col3": "Государственное областное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Кандалакшский центр помощи детям, оставшимся без попечения родителей, \"Берег\"",
                "col4": "Мурманская область, г. Кандалакша,ул. Первомайская, д. 8 А",
                "col5": "beregkand@yandex.ru",
                "col6": "http:\/\/www.kandabereg.ru\/",
                "col7": "8(81533) 9-30-24 "
            },
        ],
    ],
    [
        [
            {
                "col1": "Ненецкий автономный округ",
                "col2": "Департамент здравоохранения, труда и социальной защиты населения Ненецкого автономного округа",
                "col3": "ГБУ НАО для детей-сирот и детей, оставшихся без попечения родителей, «ЦССУ «Наш дом»",
                "col4": "166000, Ненецкий автономный округ, г. Нарьян-Мар, ул. Рабочая, д. 3",
                "col5": "Nmdetdom@yandex.ru",
                "col6": "https:\/\/nmdetdom.ru",
                "col7": "Администрация: 8(81853)4-24-48, Директор: 8(81853) 2-30-09  "
            },
        ],
    ],
    [
        [
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКУ \"Центр помощи, адаптации и коррекции несовершеннолетних, в том числе детей-сирот и детей, оставшихся без попечения родителей, города Нижнего Новгорода\"\n",
                "col4": "603022, Нижегородская область, г. Нижний Новгород, ул. Елецкая, д. 10А",
                "col5": "gkoyintrnat1@mail.ru",
                "col6": "school1nn.ru",
                "col7": "8 (83143) 3-44-02"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКУ \"Центр помощи, адаптации и коррекции несовершеннолетних, в том числе детей-сирот и детей, оставшихся без попечения родителей, Володарского муниципального округа Нижегородской области\"\n",
                "col4": "ул. Школьная, д. д. 24, с. Золино, Володарский район, Нижегородская обл., 606083",
                "col5": "sik_vld@mail.52gov.ru",
                "col6": "http:\/\/xn--80ajfeaqpbbdd1aye.xn--p1ai\/",
                "col7": "(83136) 7-74-31"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГБУ \"Центр помощи и адаптации, в том числе детей-сирот и детей, оставшихся без попечения родителей, Сергачского  муниципального округа Нижегородской области\"",
                "col4": "607513, Нижегородская область, Сергачский р-н, г Сергач, ул Коммуны, д. 5б ",
                "col5": "nadezhda@soc.ser.nnov.ru",
                "col6": "https:\/\/nadezhda-deti.ucoz.ru\/",
                "col7": "8(83191) 5-94-49"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Детский дом НиГРЭС»",
                "col4": "606400, Нижегородская область, г. Балахна, ул. Дзержинского, д. 77",
                "col5": "dd_bal@mail.52gov.ru",
                "col6": "balachna-detdom-nigres.edusite.ru",
                "col7": "8(83144) 6 52 70"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Богоявленский детский дом»",
                "col4": "606303, Нижегородская область, Дальнеконстантиновский район, село Богоявление, Шоссейная ул, д. 39",
                "col5": "dd_dkn@mail.52gov.ru",
                "col6": "detdomdk.nnov.ru",
                "col7": "8(83168)-34-7-19"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Дальнеконстантиновский специальный (коррекционный) детский дом для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья»",
                "col4": "606310, Нижегородская область, Дальнеконстантиновский район, рабочий поселок Дальнее Константиново, Советская ул, д. 184",
                "col5": "kdd_dkn@mail.52gov.ru",
                "col6": "dkdet74.edusite.ru",
                "col7": " 8 (83168) 2-23-12     "
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Городецкий детский дом»",
                "col4": "606504, Нижегородская область,район Городецкий, г. Городец, ул. Загородная, 47",
                "col5": "dd_grd@mail.52gov.ru",
                "col6": "gorodecdetdom.ru",
                "col7": "8-831-61- 9-69-79"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Дзержинский санаторный детский дом для детей-сирот и детей, оставшихся без попечения родителей»",
                "col4": "606037, Нижегородская область,г.Дзержинск, ул.Буденного,д. 20",
                "col5": "sdd_dzr@mail.52gov.ru",
                "col6": "www.dzrdd.ucoz.ru",
                "col7": "8 (8313) 22-54-15"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Краснобаковский детский дом»",
                "col4": "606710, Нижегородская область, район Краснобаковский, р.п. Красные баки, ул.Коммунальная, д.1Б",
                "col5": "dd_krb@mail.52gov.ru",
                "col6": "krdd.ucoz.ru",
                "col7": "8 (83156) 2-16-36"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Либежевский детский дом «Кораблик»",
                "col4": "606549 Нижегородская область, г.о.г Чкаловск, д.Кузнецово (Кузнецовский с\/с), ул.Алексеева,д.12",
                "col5": "dd_chk@mail.52gov.ru",
                "col6": "korablikdetdom.besaba.com",
                "col7": "8(83160) 3-63-59"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Первый санаторный детский дом»",
                "col4": "603054, Нижегородская область, г. Нижний Новгород, ул.Никиты Рыбакова, д.14, к.А",
                "col5": "sdd_nn@mail.52gov.ru",
                "col6": "nashdom1.ru",
                "col7": "8 831 222‑17-61"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Детский дом № 3 для детей-сирот и детей, оставшихся без попечения родителей»",
                "col4": "603144, Нижегородская область, г.Нижний Новгород, ш.Анкудиновское,д.11",
                "col5": "detskidom3@yandex.ru",
                "col6": "https:\/\/dd3.nobl.ru\/",
                "col7": "8 (831)-422-53-10"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Павловский санаторный детский дом для детей-сирот и детей, оставшихся без попечения родителей»",
                "col4": "606107, Нижегородская область, Павловский район, город Павлово, Трудовая ул., д.73а",
                "col5": "sdd_pvl@mail.52gov.ru",
                "col6": "pavlovo-detdom.ucoz.net",
                "col7": "8 (831) 713-76-65"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКОУ «Таремский детский дом для детей-сирот и детей, оставшихся без попечения родителей»",
                "col4": "606135, Нижегородская область, м.о. Павловский, с.Таремское, ул.Школьная, д.19 Б",
                "col5": "dd_pvl@mail.52gov.ru",
                "col6": "taremskdd.ru",
                "col7": "8 (831 71) 7-03-82"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКУ НО \"Заволжский специализированный дом ребенка\"",
                "col4": "606520 Нижегородская область                          Городецкий район, г. Заволжье, ул. Веденеева, д. 9   ",
                "col5": " ZavolgieDomrebenka@yandex.ru",
                "col6": "https:\/\/zavolgiedomrebenka.wixsite.com\/zavd",
                "col7": "8-83161-79604"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКУ НО \"Выксунский специализированный дом ребенка\" ",
                "col4": "607061, Нижегородская область, г.о.г. Выкса, ул. Пирогова, д. 8А",
                "col5": "domrebenka-vyksa@yandex.ru",
                "col6": "https:\/\/vsdr.mznn.ru\/",
                "col7": "8(83177) 3-52-64"
            },
            {
                "col1": "Нижегородская область",
                "col2": "Министерство социальной политики Нижегородской области",
                "col3": "ГКУЗ НО «Дзержинский специализированный дом ребёнка № 2»",
                "col4": "606024, Нижегородская область, г.Дзержинск, улица Гайдара, дом 3 А.",
                "col5": " ddrd@inbox.ru",
                "col6": "http:\/\/www.ddrd.ru\/",
                "col7": "8 (8313) 22-18-90"
            },
        ],
    ],
    [
        [
            {
                "col1": "Новгородская область",
                "col2": "Министерство образования Новгородской области",
                "col3": "Государственное областное бюджетное общеобразовательное учреждение  \"Школа-интернат для детей-сирот и детей, оставшихся без попечения родителей, № 5\"",
                "col4": "173502, Новгородская область, Новгородский район, \nд. Подберезье, ул. Школа-интернат, д. 7",
                "col5": "school_podberit@mail.ru ",
                "col6": "https:\/\/internat53.gosuslugi.ru",
                "col7": "8(8162)742-532"
            },
            {
                "col1": "Новгородская область",
                "col2": "Министерство образования Новгородской области",
                "col3": "Государственное областное бюджетное учреждение здравоохранения \"Боровичский областной   специализированный Дом ребенка для детей с органическим поражением центральной   нервной системы с нарушением психики\"",
                "col4": "174403, Новгородская область,\nБоровичский район, \nг. Боровичи, ул. Сушанская, \nд. 7",
                "col5": "bordomrebenka@mail.ru",
                "col6": "http:\/\/bordomrebenka.ru\/ ",
                "col7": "8(81664) 4-93-88"
            },
            {
                "col1": "Новгородская область",
                "col2": "Министерство образования Новгородской области",
                "col3": "Областное бюджетное учреждение социального обслуживания \"Детский дом-интернат имени Ушинского\"",
                "col4": "174150, Новгородская область, Шимский район, \nр.п. Шимск, \nул. Новгородская, д. 37",
                "col5": "detdombuh@yandex.ru ",
                "col6": "http:\/\/detdomush.ru\/",
                "col7": "8(81656) 54-910"
            },
        ],
    ],
    [
        [
            {
                "col1": "Новосибирская область",
                "col2": "Министерство труда и социального развития Новосибирской области",
                "col3": "Муниципальное казенное учреждение \"Центр помощи детям, оставшимся без попечения родителей Чистоозерного района Новосибирской области\"",
                "col4": "632720, Новосибирская область, Чистоозерный район, д. Очкино, ул. 50 лет Октября, д. 67",
                "col5": "chisdetdom@yandex.ru",
                "col6": "http:\/\/chisdetdom.edusite.ru\/",
                "col7": "8 383 68 91452, 8 383 68 92772"
            },
            {
                "col1": "Новосибирская область",
                "col2": "Министерство труда и социального развития Новосибирской области",
                "col3": "Муниципальное бюджетное учреждение \"Центр помощи детям, оставшимся без попечения родителей Татарского района Новосибирской области\"",
                "col4": "632122, Новосибирская область, г. Татарск, пер. Кузнечный, д. 3",
                "col5": "al.nata2011@yandex.ru",
                "col6": "https:\/\/zentr-tatarsk.edusite.ru\/",
                "col7": "8 383 64  20779"
            },
            {
                "col1": "Новосибирская область",
                "col2": "Министерство труда и социального развития Новосибирской области",
                "col3": "Муниципальное казённое учреждение Тогучинского района  \"Центр помощи детям, оставшимся  без попечения родителей\"",
                "col4": "633452, Новосибирская область, г. Тогучин, ул. Коммунистическая, д. 1",
                "col5": "toguchin-dd2@yandex.ru ",
                "col6": "togcpd.edusite.ru",
                "col7": "8 383 40 29 126"
            },
            {
                "col1": "Новосибирская область",
                "col2": "Министерство труда и социального развития Новосибирской области",
                "col3": "Муниципальное казенное учреждение города Новосибирска \"Центр помощи детям, оставшимся без попечения родителей, \"Созвездие\"",
                "col4": " 630049, г. Новосибирск, ул. Дуси Ковальчук, д. 384",
                "col5": "mkusozvezdie@gmail.com",
                "col6": "http:\/\/sozvezdie-centr.ru\/",
                "col7": "8 383 22 59 654"
            },
            {
                "col1": "Новосибирская область",
                "col2": "Министерство труда и социального развития Новосибирской области",
                "col3": "Муниципальное казенное учреждение города Новосибирска \"Центр помощи детям, оставшимся без попечения родителей, \"Теплый дом\"",
                "col4": "630073, г.Новосибирск, ул. Выставочная, д. 40",
                "col5": "nskddom6@yandex.ru",
                "col6": "https:\/\/teplyidomnsk.ru",
                "col7": "8 383 315 39 03"
            },
            {
                "col1": "Новосибирская область",
                "col2": "Министерство труда и социального развития Новосибирской области",
                "col3": "Муниципальное казенное учреждение Центр содействия семейному устройству детей-сирот и детей, оставшихся без попечения родителей \"Дорогинский\" Черепановского района Новосибирской области ",
                "col4": "633512, Новосибирская область, Черепановский район, р.п. Дорогино, ул. Светлая, д. 23",
                "col5": "dordetdom@mail.ru",
                "col6": "http:\/\/www.mkudorogino.edusite.ru",
                "col7": "8 383 45 714 30"
            },
            {
                "col1": "Новосибирская область",
                "col2": "Министерство труда и социального развития Новосибирской области",
                "col3": "Государственное автономное стационарное учреждение социального обслуживания Новосибирской области  «Ояшинский дом-интернат для детей инвалидов  и молодых инвалидов, имеющих  психические расстройства» ",
                "col4": "633150,  Новосибирская область, Мошковский район, р.п. Станционно-Ояшинский, ул. Западная, д. 40",
                "col5": "odiuod@yandex.ru",
                "col7": "8 383 309 25 45"
            },
            {
                "col1": "Новосибирская область",
                "col2": "Министерство труда и социального развития Новосибирской области",
                "col3": "Муниципальное казенное учреждение города Новосибирска \"Центр помощи детям, оставшимся без попечения родителей, \"Жемчужина\"",
                "col4": "630048, г. Новосибирск, ул. Ватутина, д. 30",
                "col5": "dd_1_nsk@mail.ru",
                "col6": "http:\/\/www.dd-1.nios.ru",
                "col7": "8 383 314 45 42"
            },
        ],
    ],
    [
        [
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "Казенное учреждение Омской области для детей-сирот и детей, оставшихся без попечения родителей, \"Дом детства на Кордной\"",
                "col4": "644018, Омская область, г. Омск, ул. 5-я Кордная, д. 7",
                "col5": "post@shkola-int5.omskportal.ru",
                "col6": "ddkord.omsk.obr55.ru",
                "col7": "8 (3812) 562597"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "казенное учреждение Омской области для детей-сирот и детей, оставшихся без попечения родителей \"Петропавловский дом детства\"",
                "col4": "646432, Омская область, Муромцевский район, р.п. Муромцево, ул. Кооперативная, д. 2",
                "col5": "post@petropavlovka-dd.omskportal.ru",
                "col6": "www.petropavlovskiy-dom.ru",
                "col7": "8 (38158) 34167"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "казенное учреждение Омской области для детей-сирот и детей, оставшихся без попечения родителей \"Тарский дом детства им.Д.М. Карбышева\" ",
                "col4": "646530, Омская область , г.Тара, ул. Транспортная д. 18",
                "col5": "post@detsky.omskportal.ru",
                "col6": "ddkarb.tar.obr55.ru",
                "col7": "8 (38171) 24102"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "казенное учреждение Омской области для детей-сирот и детей, оставшихся без попечения родителей \"Полтавский дом детства\"",
                "col4": "646740, Омская область, Полтавский район, р.п. Полтавка, ул. 4-я Восточная, д. 25",
                "col5": "post@poltdet-dom.omskportal.ru",
                "col6": "http:\/\/dd.pol.obr55.ru",
                "col7": "8 (38163) 21612"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "казенное учреждение Омской области для детей-сирот и детей, оставшихся без попечения родителей, \"Дом детства\" имени Героя Советского Союза Григория Михайловича Кравцова",
                "col4": "644065, Омская область, г.Омск, ул. 19 Партсъезда, д. 38 а",
                "col5": "post@detdom2010.omskportal.ru",
                "col6": "http:\/\/dd4.omsk.obr55.ru\/",
                "col7": "8 (3812) 645338, 8 (3812) 226809"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "Казенное учреждение Омской области длй детей-сирот и детей, оставшихся без попечения родителей \"Дом детства Радуга\"",
                "col4": "644060, Омская область, г. Омск, ул. 14-я Чередовая, д. 4 А",
                "col5": "post@schul16.omskportal.ru",
                "col6": "http:\/\/ouint16.omsk.obr55.ru",
                "col7": "8 (3812) 437450"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "Казенное учреждение Омской области для детей-сирот и детей, оставшихся без попечения родителей, Колосовский дом детства\"",
                "col4": "646350, Омская область, Колосовский район, с. Колосовка, ул.Кирова, д. 117",
                "col5": "post@kolos-det-dom.omskportal.ru",
                "col6": "http:\/\/dd.kolos.obr55.ru",
                "col7": "8 (38160) 21594"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "Казенное учреждение Омской области для детей-сирот и детей, оставшихся без попечения родителей \"Большеуковский дом детства\"",
                "col4": "646380, Омская область, Большеуковский район, с. Большие Уки, ул. Пролетарская, д. 64",
                "col5": "post@scshibuki8.omskportal.ru",
                "col6": "http:\/\/ouint.b-uki.obr55.ru",
                "col7": "8 (38162) 21919, 8 (38162) 21910"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "Казенное учреждение Омской области для детей-сирот и детей, оставшихся без попечения родителей \"Дом детства Лучик\"",
                "col4": " 644076, Омская область, г. Омск, ул. Петра Осминина, д. 11 А",
                "col5": "post@detdom3omsk.omskportal.ru",
                "col6": " http:\/\/dd3.omsk.obr55.ru",
                "col7": "8 (3812) 576847"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "Бюджетное стационарное учреждение социального обслуживания Омской области \"Кировский детский дом-интернат\"",
                "col4": "644903, г. Омск, мкр. Входной, ул. Челябинская, д. 2",
                "col5": "kirovski-internat@yandex.ru",
                "col6": "www.kddi.omsk.socinfo.ru",
                "col7": "(3812) 712-500"
            },
            {
                "col1": "Омская область",
                "col2": "Министерство образования Омской области",
                "col3": "Бюджетное учреждение здравоохранения Омской области \"Специализированный дом ребенка\"",
                "col4": "644110, Омская область, г. Омск, ул. Бархатовой, д. 4 Г",
                "col5": "sdr3_mail@minzdrav.omskportal.ru",
                "col6": "http:\/\/www.sdr-omsk.ru\/",
                "col7": "62-25-60"
            },
        ],
    ],
    [
        [
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное бюджетное образовательное учреждение «Школа-интернат для детей-сирот и детей, оставшихся без попечения родителей «Дом детства»",
                "col4": "460023, г. Оренбург, ул. Кольцевая, д. 6",
                "col5": "mail@orendetstvo.ru",
                "col6": "https:\/\/sh-int-dom-detstva-orenburg-r56.gosweb.gosuslugi.ru\/",
                "col7": "8 (3532)47-94-07, 8 (3532)56-36-23"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное казенное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Детский дом\"  г. Абдулино Оренбургской области",
                "col4": "461743 Оренбургская область г. Абдулино, ул. Ленина, д.8",
                "col5": "iu11@mail.orb.ru",
                "col6": "https:\/\/sh-int-abdulino-r56.gosweb.gosuslugi.ru\/",
                "col7": "8 (35355) 2-40-93, 8 (35355)2-56-92"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "ГКОУ для детей-сирот и детей, осташихся без попечения родителей \"Детский дом\" с. Кирсановка Тоцкого района Оренбургской области",
                "col4": "461121, Оренбургская обл., Тоцкий р-н, с.Кирсановка, ул.Советская, д.69",
                "col5": "iu18@mail.orb.ru",
                "col6": "https:\/\/sh-int-kirsanovskij-r56.gosweb.gosuslugi.ru\/",
                "col7": "8 (35349) 9-41-63"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное казеное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Детский дом\" г. Соль-Илецка",
                "col4": "461500 Оренбургская обл., г. Соль-Илецк, ул Шевченко,1",
                "col5": "iu16@mail.orb.ru",
                "col6": "https:\/\/sh-int-detskij-dom-solileck-r56.gosweb.gosuslugi.ru\/",
                "col7": "8 (35336) 2-55-39"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное казенное образовательное учреждение для детей - сирот и детей, оставшихся без попечения родителей \"Чебеньковский детский дом Оренбургского района",
                "col4": "460550 Оренбургская область, Оренбургский район , п. Чебеньки, ул. Гагарина д.1 \"А\"",
                "col5": "chebdd08@rambler.ru",
                "col6": "orenchebdd.ru ",
                "col7": "8 (3532)39-87-21"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное казенное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей «Детский дом» г. Орска Оренбургской области",
                "col4": "462419, г. Орск, ул. Московская 23\/Новосибирская 42а",
                "col5": "iu33@mail.orb.ru",
                "col6": "https:\/\/det-dom.gosuslugi.ru\/",
                "col7": "8 (3537) 25-33-94"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное казенное общеобразовательное учреждение «Специальная     (коррекционная) школа-интернат для детей-сирот и детей, оставшихся без попечения родителей»         с. Ташла Тюльганского района Оренбургской области.",
                "col4": "инд.462002 Оренбургская область Тюльганский район село Ташла ул.Лушная,4",
                "col5": "iu42@mail.orb.ru ",
                "col6": "https:\/\/sh-int-tashlinskaya-r56.gosweb.gosuslugi.ru\/",
                "col7": "8 (35332) 27-3-44"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное казенное общеобразовательное учреждение «Специальная (коррекционная) школа-интернат для детей-сирот и детей, оставшихся без попечения родителей»  с. Покровка Новосергиевского района Оренбургской области",
                "col4": "461230   Оренбургская область, Новосергиевский район, \nсело Покровка, улица Кооперативная, дом 12\n",
                "col5": "iu37@mail.orb.ru",
                "col6": "https:\/\/sh-int-pokrovskaya-r56.gosweb.gosuslugi.ru\/",
                "col7": "8 (35339) 97-195"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное казенное общеобразовательное учреждение \"Специальная (коррекционная) школа-интернат для детей-сирот и детей, оставшихся без попечения родителей\" с.Зиянчурино Кувандыкского городского округа Оренбургской области",
                "col4": "462202 Оренбургская область, Кувандыкский район, с.Зиянчурино, ул.Советская, д.21",
                "col5": "iu36@mail.orb.ru",
                "col6": "https:\/\/sh-int-ziyanchurinskaya-r56.gosweb.gosuslugi.ru\/",
                "col7": "8 (35361)-61-1-10"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное казенное учреждение здравоохранения \"Орский специализированный дом ребенка\"",
                "col4": "462431, Оренбургская обл.,г.Орск, ул.Короленко, дом 44",
                "col5": "domreb56@mail.ru",
                "col6": "https:\/\/orskdomreb.orb.ru",
                "col7": "8 (3537)21-42-90"
            },
            {
                "col1": "Оренбургская область",
                "col2": "Министерство образования Оренбургской области",
                "col3": "Государственное казенное учреждение здравоохранения \"Оренбургский областной дом ребенка\"",
                "col4": "460056, г. Оренбург, ул. Салмышская, д. 7\/1",
                "col5": "info@oodr.ru",
                "col6": "https:\/\/oodr.orb.ru",
                "col7": "8 (3532) 43-57-47"
            },
        ],
    ],
    [
        [
            {
                "col1": "Орловская область",
                "col2": "Департамент социальной защиты, опеки и попечительства, труда и занятости Орловской области",
                "col3": "Казенное стационарное учреждение социального обслуживания Орловской области \"Центр помощи детям, оставшимся без попечения родителей \"Теплый дом\"",
                "col4": "303030, Орловская область, г. Мценск, ул. 20 июля, д. 1а             ",
                "col5": "oo_ddom_ms@orel-region.ru",
                "col6": "http:\/\/mtsensk-dd.obr57.ru\/",
                "col7": "8 (48646) 4-07-77"
            },
            {
                "col1": "Орловская область",
                "col2": "Департамент социальной защиты, опеки и попечительства, труда и занятости Орловской области",
                "col3": "Казенное стационарное учреждение социального обслуживания Орловской области \"Центр помощи детям, оставшимся без попечения родителей \"Расправь крылья\"",
                "col4": "302015, Орловская область, Орловский муниципальный округ, дер. Некрасовка, ул. Интернатная, д. 25",
                "col5": "oo_cpd@orel_region.ru",
                "col6": "https:\/\/расправькрылья-орел.рф\/",
                "col7": "8 (4862) 44-13-57"
            },
            {
                "col1": "Орловская область",
                "col2": "Департамент социальной защиты, опеки и попечительства, труда и занятости Орловской области",
                "col3": "Казенное стационарное учреждение социального обслуживания Орловской области \"Центр помощи детям, оставшимся без попечения родителей \"Остров детства\"",
                "col4": "303140, Орловская область, г. Болхов, ул. Фрунзе, д. 41",
                "col5": "oo_bol_ddi@orel-region.ru",
                "col6": "http:\/\/www.boldetdom.ru\/",
                "col7": "8 (48640) 2-45-33"
            },
            {
                "col1": "Орловская область",
                "col2": "Департамент социальной защиты, опеки и попечительства, труда и занятости Орловской области",
                "col3": "Казенное стационарное учреждение социального обслуживания Орловской области \"Центр помощи детям, оставшимся без попечения родителей \"Дом Солнца\"",
                "col4": "303030 Орловская область,  г.Мценск,\nул. Дзержинского, д. 6\n",
                "col5": "oo_msnk_ddi@orel-region.ru",
                "col6": "мдди.рф",
                "col7": "8 (48646) 9 12 23"
            },
            {
                "col1": "Орловская область",
                "col2": "Департамент социальной защиты, опеки и попечительства, труда и занятости Орловской области",
                "col3": "Казенное стационарное учреждение социального обслуживания Орловской области \"Центр помощи детям, оставшимся без попечения родителей \"Маленькая страна\"",
                "col4": "302019, г.Орёл, ул.Трудовых Резервов, д.34",
                "col5": "oo_kuz_domreb@orel-region.ru",
                "col6": "dr57.ru",
                "col7": "8 (4862) 43-31-34"
            },
        ],
    ],
    [
        [
            {
                "col1": "Пензенская область",
                "col2": "Министерство труда, социальной защиты и демографии Пензенской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Пензенской области         «Спасский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "442600, Пензенская обл., \nСпасский р-н, \nг. Спасск,                                    ул.Коммунальная, \nд. 3",
                "col5": "spasskddom@mail.ru",
                "col6": "http:\/\/www.spasskddom.ru\/",
                "col7": " 8 (84151) 3-24-70; 8 (84151)3-24-68   "
            },
            {
                "col1": "Пензенская область",
                "col2": "Министерство труда, социальной защиты и демографии Пензенской области",
                "col3": "Государственное бюджетное стационарное учреждение социального обслуживания Пензенской области «Мокшанский детский дом-интернат для умственно отсталых детей»",
                "col4": "442370, Пензенская обл., \nМокшанский район, р.п. Мокшан, \nул. Милосердия, д. 1",
                "col5": "detdom58@mail.ru",
                "col6": "https:\/\/mddi.socpnz.ru\/",
                "col7": "8 (84150)2-71-72"
            },
            {
                "col1": "Пензенская область",
                "col2": "Министерство труда, социальной защиты и демографии Пензенской области",
                "col3": "Государственное бюджетное учреждение системы социальной защиты населения «Областной социально-реабилитационный центр для детей и молодых инвалидов»\n\nКузнецкий филиал Государственного бюджетного учреждения системы социальной защиты населения «Областной социально-реабилитационный центр для детей и молодых инвалидов»",
                "col4": "440000, Пензенская обл., Пензенский р-н,\n п. Кичкилейка,                    ул. Санаторская, д. 9 А\n\n\n\n442533, Пензенская обл., г. Кузнецк, ул. Заводская, д. 303 А",
                "col5": "orc.penza@mail.ru   \n\n\n\n\n\n penzdomreb@mail.ru",
                "col6": "https:\/\/osrc.socpnz.ru\/\n\n\n\n\n\nhttps:\/\/penzdomreb.narod.ru\/",
                "col7": "8 (8412)94-37-28                   \n8 (8412)94-47-10  \n8 (84157) 9-00-86                                                                        "
            },
        ],
    ],
    [
        [
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Центр помощи детям, оставшимся без попечения родителей\" г. Горнозаводска",
                "col4": "618820, г. Горнозаводск, ул. Мира, д. 27",
                "col5": "cpdet.gorn@social.permkrai.ru ",
                "col6": "https:\/\/ddgorn1.3dn.ru\/",
                "col7": " 8 (34269) 4-19-30 "
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Центр помощи детям, оставшимся без попечения родителей\" г. Краснокамска",
                "col4": "617064, г. Краснокамск, ул. Дзержинского, д. 6",
                "col5": "cpdet.kkamsk@social.permkrai.ru ",
                "col6": "https:\/\/deti273.ru\/home",
                "col7": "8 (342) 206 34 50"
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Центр помощи детям, оставшимся без попечения родителей\" г. Перми",
                "col4": "614023, г. Пермь, ул. Светлогорская, д. 18",
                "col5": "cpdet.perm@social.permkrai.ru ",
                "col6": "https:\/\/ddom3-perm.ru\/",
                "col7": "8 (342) 206-04-73"
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Центр помощи детям, оставшимся без попечения родителей\" г. Березники",
                "col4": "618426, г. Березники, ул. Пятилетки, д. 65",
                "col5": "cpdet.berez@social.permkrai.ru",
                "col6": "https:\/\/berkdd.ru\/?ysclid=m40r1cxwh2812464303",
                "col7": "8 (3424) 201-321"
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Центр помощи детям, оставшимся без попечения родителей\" г. Кудымкара",
                "col4": "619545, Кудымкарский район, с. Пешнигорт, ул. Молодёжная, д. 8",
                "col5": "cpdet.kudymkar@social.permkrai.ru",
                "col6": "https:\/\/cpd-kudymkar.ru\/contacts.html",
                "col7": "8 (34260) 3-12-95"
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Центр помощи детям, оставшимся без попечения родителей\" г. Кунгура",
                "col4": "617470, г. Кунгур,  ул. Ситникова, д. 102",
                "col5": "cpdet.kungur@social.permkrai.ru",
                "col6": "https:\/\/zentr-kungur14.ucoz.ru\/",
                "col7": "8 (34271) 6-45-25"
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Центр помощи детям, оставшимся без попечения родителей\" г. Чайковского",
                "col4": "617763, г. Чайковский, ул. Мира, д. 26",
                "col5": "cpdet.chaik@social.permkrai.ru",
                "col6": "https:\/\/srcn59.ru\/?ysclid=m40r46dvf9561977943",
                "col7": "8 (34241) 3-41-28"
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Центр помощи детям, оставшимся без попечения родителей\" Ильинского района",
                "col4": "617037, Ильинский район, д. Садки, ул. Центральная, д. 2",
                "col5": "cpdet.ils@social.permkrai.ru",
                "col6": "https:\/\/src-ilinsk.permarea.ru\/",
                "col7": "8 34 276 (9-36-34)"
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Социально-реабилитационный центр для несовершеннолетних\"",
                "col4": "614021, г. Пермь, ул. Куйбышева, д. 169\/4",
                "col5": "srcnperm@social.permkrai.ru",
                "col6": "https:\/\/srcnperm.ru\/?ysclid=m40r5wcigm561715147",
                "col7": "8 (342) 206-07-48"
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Осинский дом – интернат \"Солнечный\"",
                "col4": "618120, г. Оса, ул. Мелентьева, д. 3",
                "col5": "osa.ddi@social.permkrai.ru",
                "col6": "https:\/\/osaddi.ru\/",
                "col7": "8 (34291) 4-35-46"
            },
            {
                "col1": "Пермский край ",
                "col2": "​Министерство труда и  социального развития Пермского края ",
                "col3": "Государственное казенное учреждение социального обслуживания Пермского края \"Рудничный дом – интернат \"Территория заботы\"",
                "col4": "618350, г. Кизел, пос. Рудничный, ул. Аносова, д. 5",
                "col5": "rudn.ddi@social.permkrai.ru ",
                "col6": "http:\/\/www.rddi.ru\/",
                "col7": "8 (34255) 5-06-53"
            },
        ],
    ],
    [
        [
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству детей-сирот и детей, оставшихся без попечения родителей, г. Артема»",
                "col4": "692760 г. Артем, ул. Фрунзе, д.8",
                "col5": "ziminara@mail.ru",
                "col6": "http:\/\/artem.ddpk.ru",
                "col7": "8 (42337) 43912"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, г. Арсеньева»",
                "col4": "692330 г. Арсеньев, \nул. Малиновая, 15",
                "col5": "detdomars@mail.ru",
                "col6": "http:\/\/ddars.ucoz.ru\/",
                "col7": "8 (42361) 32219, 8 (42361) 32177"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, г. Большой Камень»",
                "col4": "692806 г. Большой Камень, ул. Гагарина д. 15а",
                "col5": "teremok-bk@mail.ru",
                "col6": "https:\/\/www.centrbk.ru",
                "col7": "8 (42335) 56200"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, № 1 г. Владивостока»",
                "col4": "690080 г. Владивосток \nул. Космонавтов 19-а",
                "col5": "ddvl4@mail.ru",
                "col6": "http:\/\/ddvl4.ru",
                "col7": "(423) 2259648"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, № 2 г. Владивостока»",
                "col4": "690087 г. Владивосток,\nул. Сабанеева, д. 24",
                "col5": "det-dom2vl@mail.ru",
                "col6": "https:\/\/детдом2.рф\/ ",
                "col7": "8 (423) 2442326"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, с. Вольно-Надеждинское»",
                "col4": "692481 Надеждинский район, с. Вольно – Надеждинское, ул. Геологов д. 2",
                "col5": "nad_detdom@mail.ru",
                "col6": "http:\/\/volno.ddpk.ru",
                "col7": "8 (42334) 20009"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, г. Дальнереченска имени Героя Советского Союза Д.В. Леонова»",
                "col4": "692132 г. Дальнереченск, ул. Калинина, д.56",
                "col5": "ddom06@mail.ru",
                "col6": "http:\/\/ddomd.ucoz.ru",
                "col7": "8 (42356) 33045"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, г. Лесозаводска»",
                "col4": "692036 г. Лесозаводск ул. Челюскина, 10",
                "col5": "les_det@mail.ru ",
                "col6": "http:\/\/les.ddpk.ru",
                "col7": "8 (42355) 27877"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, пос. Мысовой»",
                "col4": "692824, Шкотовский район, пос. Мысовой, ул. 1 Мая, д. 17",
                "col5": "ddommasov@mail.ru",
                "col6": "http:\/\/mus.ddpk.ru",
                "col7": "8 (42335) 36-6-24"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, г. Находки»",
                "col4": "692917, г. Находка, ул. Верхне-Морская, 120",
                "col5": "vmorskay2008@yandex.ru",
                "col6": "http:\/\/nahodka.ddpk.ru",
                "col7": "8 (4236) 62-19-60"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, пгт Новошахтинский»",
                "col4": "692656, Михайловский  район, п. Новошахтинский, ул. Юбилейная, д. 1",
                "col5": "detskii.dom@mail.ru",
                "col6": "http:\/\/shahta.ddpk.ru",
                "col7": "8 (42346) 26-1-39"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, г. Партизанска»",
                "col4": "692860, г. Партизанск, ул. В.И.Чкалова , 34",
                "col5": "ddom_loz@mail.ru",
                "col6": "http:\/\/part.ddpk.ru",
                "col7": "8 (42363) 6-44-83"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству детей-сирот и детей, оставшихся без попечения родителей, с. Преображенка»",
                "col4": "692088, Кировский район, с. Преображенка, ул. Школьная, д. 1-а",
                "col5": "preodom@yandex.ru",
                "col6": "http:\/\/preobrajenka.ddpk.ru",
                "col7": "8 (42354) 29681"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству детей-сирот и детей, оставшихся без попечения родителей, с. Светлогорье»",
                "col4": "692028, Пожарский район, с. Светлогорье, ул. В. Хомякова 1",
                "col5": "ddsvet@ mail.ru",
                "col6": "https:\/\/svetlogor.vl.socinfo.ru\/",
                "col7": "8 (42357) 35232,\n8 (42357) 35180"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, с. Сержантово»",
                "col4": "692436, г. Дальнегорск, с. Сержантово, ул. Ленинская, 10",
                "col5": "ch_serzhantovo@mail.ru",
                "col6": "http:\/\/ser.ddpk.ru",
                "col7": "8 (42373) 37-0-86, 8 (42373) 39-5-69"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству детей-сирот и детей, оставшихся без попечения родителей, пгт Славянка»",
                "col4": "692701, пгт Славянка, ул. Парковая, 3",
                "col5": "detdoms@mail.ru",
                "col6": "http:\/\/slav.ddpk.ru",
                "col7": "8 (42331) 49-3-31"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству детей-сирот и детей, оставшихся без попечения родителей, г. Спасска-Дальнего»",
                "col4": "692245, г. Спасск-Дальний ул. Ленинская д. 116",
                "col5": "spsddbux@rambler.ru",
                "col6": "http:\/\/spassk.ddpk.ru",
                "col7": "8 (42352) 2 47-32; \n8 (42352) 2 29-79"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, г. Уссурийска»",
                "col4": "692524, г. Уссурийск, ул. Фадеева, 20",
                "col5": "detskiydom2ussur@mail.ru",
                "col6": "http:\/\/detskiydomuss.ru",
                "col7": "8 (4234)331-190"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству  детей-сирот и детей, оставшихся без попечения родителей, с. Черниговка»",
                "col4": "692372, Черниговский район, с.Черниговка, ул. Будённого, 27",
                "col5": "ddomchernigovka@bk.ru",
                "col6": "http:\/\/chernigov.ddpk.ru",
                "col7": "8 (42351) 25-5-16"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству детей-сирот и детей, оставшихся без попечения родителей, с. Чугуевка»",
                "col4": "692621, Чугуевский район, с. Чугуевка, ул. Комарова 13-А",
                "col5": "chugddom@rambler.ru",
                "col6": "http:\/\/chugdetdom.ru\/",
                "col7": "8 (42372) 21-6-32"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству детей-сирот и детей, оставшихся без попечения родителей, с. Яковлевка»",
                "col4": "692361, Яковлевский район, с. Яковлевка, ул. Лазо, 11",
                "col5": "ykovlevka_detdom@mail.ru                                                                                                                                                                                                       ",
                "col6": "http:\/\/ykov.ddpk.ru",
                "col7": "8 (42371)91594"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное казенное учреждение  «Центр содействия семейному устройству детей-сирот и детей, оставшихся без попечения родителей, пгт Ярославский»",
                "col4": "692270, Хорольский район, п. Ярославский, ул. Юбилейная, 47а",
                "col5": "moudd@rambler.ru",
                "col6": "https:\/\/центр-яр.рф",
                "col7": "8 (42347) 28 7 81"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство труда и социальной политики Приморского края",
                "col3": "Краевое государственное\nбюджетное учреждение\nсоциального обслуживания «Екатериновский \nдетский психоневрологический дом-интернат»\n\n",
                "col4": "692911,\nПартизанский\nрайон,\nс. Екатериновка,\nул. Советская,\n3",
                "col5": "delo@kgbuso-eddi.ru",
                "col6": "https:\/\/kgbuso-edpni.ru\/",
                "col7": "8 (42365) 29-1-39"
            },
            {
                "col1": "Приморский край \n",
                "col2": "Министерство здравоохранения Приморского края",
                "col3": "ГКУЗ «Краевой психоневрологический дом ребенка»",
                "col4": "690017, г.Владивосток, ул.Окатовая, д.46",
                "col5": "dom-rebenka1@mail.ru",
                "col6": "https:\/\/kpdrvl.ru\/",
                "col7": "8 (423) 260-27-88\n8 (423) 260-16-06\n"
            },
        ],
    ],
    [
        [
            {
                "col1": "Псковская область",
                "col2": "Комитет по социальной защите Псковской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Псковской области «Бобровский детский дом-интернат». ",
                "col4": "д.Бобры, Печорский район, Псковская область,  181513",
                "col5": "bobrov-detdom@yandex.ru",
                "col6": "http:\/\/bobrovddi.pskov.socinfo.ru\/",
                "col7": "(81148) 9-81-70"
            },
            {
                "col1": "Псковская область",
                "col2": "Комитет по социальной защите Псковской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Псковской области «Бельско-Устьенский дом-интернат\" ",
                "col4": "д.Бельское Устье, Порховский район, Псковская область, 182630",
                "col5": "budetdom@social.pskov.ru",
                "col6": "http:\/\/bddi.ru\/",
                "col7": "(81134) 2-64-51"
            },
            {
                "col1": "Псковская область",
                "col2": "Комитет по социальной защите Псковской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Псковской области «Центр помощи детям, оставшимся без попечения родителей, Печорского района»",
                "col4": "ул.Свободы, д.27, г.Печоры, Псковская область, 181500 ",
                "col5": "cpd-pechory@social.pskov.ru",
                "col6": "https:\/\/cpd-pechory60.ru\/",
                "col7": "(81148) 2-19-62"
            },
            {
                "col1": "Псковская область",
                "col2": "Комитет по социальной защите Псковской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Псковской области «Центр помощи детям, оставшимся без попечения родителей, г.Великие Луки»",
                "col4": "ул.Щорса, д.90, г.Великие Луки, Псковская область, 182106",
                "col5": "cpd-vluki@social.pskov.ru",
                "col6": "https:\/\/vluki-cpd.ru\/",
                "col7": "(81153) 6-42-34"
            },
            {
                "col1": "Псковская область",
                "col2": "Комитет по социальной защите Псковской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Псковской области «Центр помощи детям, оставшимся без попечения родителей, г. Пскова»",
                "col4": "ул. М.Горького, д.23, г.Псков, 180007",
                "col5": "cpd-pskov@social.pskov.ru",
                "col6": "http:\/\/cpd-pskov60.ru\/",
                "col7": "(8112) 56-54-51"
            },
            {
                "col1": "Псковская область",
                "col2": "Комитет по социальной защите Псковской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания Псковской области «Печорский детский дом-интернат»",
                "col4": "Прибалтийское шоссе, 1, г. Печоры, Псковская область, 181502",
                "col5": "babyhome@social.pskov.ru",
                "col7": "(81148) 2-15-81"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Адыгея",
                "col2": "Министерство труда и социального развития Республики Адыгея",
                "col3": "Государственное бюджетное учреждение Республики Адыгея «Адамийский дом-интернат»",
                "col4": "385336 Республика Адыгея, Красногвардейский р-он, аул. Адамий, ул. Шоссейная,43",
                "col5": " guraapdi@mail.ru",
                "col6": "https:\/\/adamdom.adg.socinfo.ru",
                "col7": "8 (87778) 5-61-48"
            },
            {
                "col1": "Республика Адыгея",
                "col2": "Министерство труда и социального развития Республики Адыгея",
                "col3": "Государственное казенное учреждение Республики Адыгея «Дом социального обслуживания для детей»",
                "col4": "385440, Республика Адыгея, Шовгеновский р-н, аул Хакуринохабль, Краснооктябрьская ул, влд. 104",
                "col5": "dsoshov72@mail.ru",
                "col6": "нет",
                "col7": "8 (87773) 9-22-99"
            },
            {
                "col1": "Республика Адыгея",
                "col2": "Министерство труда и социального развития Республики Адыгея",
                "col3": "Государственное казенное учреждение Республики Адыгея «Адыгейский республиканский дом ребенка»",
                "col4": "385017,\nРеспублика Адыгея,\nг. Майкоп,\nул. 2-я Крылова, 19\n",
                "col5": "domrebenka01@yandex.ru",
                "col6": "https:\/\/ardrmaykop.ru",
                "col7": "8 (8772) 56-45-11"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Алтай",
                "col2": "Министерство труда, социального развития и занятости населения Республики Алтай",
                "col3": "Бюджетное учреждение Республики Алтай \"Республиканский Дом-интернат \"Алтай\"",
                "col4": " 649002, Республика Алтай, г. Горно-Алтайск, проспект Коммунистический, 156",
                "col5": "dpi3-altay@mail.ru",
                "col6": "https:\/\/dpi3-altay.ru\/",
                "col7": "8 (38822)64495"
            },
            {
                "col1": "Республика Алтай",
                "col2": "Министерство труда, социального развития и занятости населения Республики Алтай",
                "col3": "Казенное учреждение Республики Алтай «Центр помощи детям, оставшимся без попечения родителей «Очаг»",
                "col4": "649002, г.Горно-Алтайск, просп. Коммунистический , 113",
                "col5": "cpd-ochag@mt04.ru",
                "col6": "https:\/\/vk.com\/club225391575",
                "col7": "8 (38822)61036"
            },
            {
                "col1": "Республика Алтай",
                "col2": "Министерство здравоохранения Республики Алтай",
                "col3": "Бюджетное учреждение здравоохранения Республики Алтай \"Специализированный дом ребенка с органическим поражением центральной нервной системы с нарушением психики»",
                "col4": "649113, Республика Алтай, Майминский район, с. Манжерок, ул. Катунская 36",
                "col5": "dom_ra@inbox.ru",
                "col6": "https:\/\/dom-reb.med04.ru\/",
                "col7": "8 (38844)2-85-38"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан  для детей-сирот и детей, оставшихся без попечения родителей, Центр содействия семейному воспитанию Республики Башкортостан \"Курай\"",
                "col4": "453626, РБ, Абзелиловский район, д. Равилово, ул. Айдара Мухтаруллина, 24",
                "col5": "cssv02@bashkortostan.ru ",
                "col6": "https:\/\/kuraj.bashkortostan.ru\/",
                "col7": "8 (347) 7228815 "
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан для детей-сирот и детей, оставшихся без попечения родителей, Центр содействия семейному воспитанию «Феникс»",
                "col4": "452455, Республика Башкортостан, г. Бирск, ул. Интернациональная, 74А",
                "col5": "cssv04@bashkortostan.ru",
                "col6": "https:\/\/feniks.bashkortostan.ru\/about\/contacts\/",
                "col7": "8 (34784) 21370"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное  учреждение Республики Башкортостан  для детей-сирот и детей, оставшихся без попечения родителей, Центр содействия семейному воспитанию  «Алые паруса» ",
                "col4": "453149, Республика Башкортостан, Стерлитамакский район, с. Большой Куганак, ул. Ленина, д.11",
                "col5": "cssv10@bashkortostan.ru ",
                "col6": "https:\/\/alyeparusa.bashkortostan.ru\/",
                "col7": "8 (3473) 276733"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан для детей-сирот и детей, оставшихся без попечения родителей Центр содействия семейному воспитанию «Детство»",
                "col4": "453316, Россия, Республика Башкортостан, г. Кумертау, с. Маячный, ул. Гафури, д.30",
                "col5": "cssv11@bashkortostan.ru",
                "col6": "https:\/\/detstvo.bashkortostan.ru\/",
                "col7": "8 (34761) 26407"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан  для детей-сирот и детей, оставшихся без попечения родителей,  Центр содействия семейному воспитанию «Петровский»",
                "col4": "453230, Республика Башкортостан, Ишимбайский район и г. Ишимбай, с. Петровское, ул. Школьная, д. 20",
                "col5": "cssv13@bashkortostan.ru",
                "col6": "https:\/\/petrovskij.bashkortostan.ru\/",
                "col7": "8 (34794) 76482"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан для детей-сирот и детей, оставшихся без попечения родителей, Центр содействия семейному  воспитанию «Солнечный»",
                "col4": "453252,  Россия, Республика Башкортостан,  г. Салават,  ул. Строителей, д. 1 а",
                "col5": "cssv14@bashkortostan.ru",
                "col6": "https:\/\/solnechnyj.bashkortostan.ru\/",
                "col7": "8 (3476) 356474 "
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение  Республики Башкортостан для детей-сирот и детей, оставшихся без попечения родителей,  Центр содействия семейному воспитанию «Виктория»",
                "col4": "453130, Россия, Республика Башкортостан, г. Стерлитамак, ул. Полевая, д.138",
                "col5": "cssv16@bashkortostan.ru",
                "col6": "https:\/\/viktoriya.bashkortostan.ru\/",
                "col7": "8 (3473) 256690"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан для детей - сирот и детей, оставшихся без попечения родителей, Центр содействия семейному воспитанию «Спутник»",
                "col4": "453522, Республика Башкортостан, Белорецкий район, с. Узян, ул. Доменная, 3",
                "col5": "cssv18@bashkortostan.ru",
                "col6": "https:\/\/sputnik.bashkortostan.ru\/",
                "col7": "8 (34792) 75472"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан для детей-сирот и детей, оставшихся без попечения родителей, Центр содействия семейному воспитанию имени Шагита Худайбердина",
                "col4": "450075, Республика Башкортостан, Орджоникидзевский район       г. Уфа, ул. Блюхера,7.",
                "col5": "cssv19@bashkortostan.ru",
                "col6": "https:\/\/hudajberdina.bashkortostan.ru\/",
                "col7": "8 (347) 2841961"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан для детей-сирот и детей, оставшихся без попечения родителей, Центр социальной и коррекционной помощи «Радуга» ",
                "col4": "450059, Россия, Республика Башкортостан, г. Уфа, Октябрьский р-н, ул. Рихарда Зорге, д. 19\/3",
                "col5": "cssv20@bashkortostan.ru",
                "col6": "https:\/\/raduga.bashkortostan.ru\/",
                "col7": "8 (347) 2232214"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан для детей-сирот и детей, оставшихся без попечения родителей, Центр содействия семейному воспитанию г. Уфы",
                "col4": "450032, Республика Башкортостан, г. Уфа, Орджоникидзевский район, ул. Кольцевая, д.103",
                "col5": "cssv21@bashkortostan.ru",
                "col6": "https:\/\/cssvufa.bashkortostan.ru\/",
                "col7": "8 (347) 2466819"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан для детей-сирот и детей, оставшихся без попечения родителей, Центр содействия семейному воспитанию «Росток»",
                "col4": "452960, РБ, Бураевский район, д. Шабаево, ул. Султанова, 74",
                "col5": "cssv24@bashkortostan.ru",
                "col6": "https:\/\/rostok.bashkortostan.ru\/",
                "col7": "8 (3475) 622017"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан для детей-сирот и детей, оставшихся без попечения родителей, Центр содействия семейному воспитанию «Самрау»",
                "col4": "453336, Россия, Республика Башкортостан, Кугарчинский район, с .Юмагузино, ул. Строителей, д.1",
                "col5": "cssv25@bashkortostan.ru",
                "col6": "https:\/\/samrau.bashkortostan.ru\/",
                "col7": "8 (3478) 924635"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное казённое учреждение Республики Башкортостан   для детей-сирот и детей, оставшихся без попечения родителей, Республиканский  дом ребенка специализированный",
                "col4": "450092, Республика Башкортостан, город Уфа, ул. Авроры, 9",
                "col5": "drs@bashkortostan.ru ",
                "col6": "https:\/\/rdrs.bashkortostan.ru\/",
                "col7": "8 (347) 2959844"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан Белебеевский межрайонны центр \"Семья\"",
                "col4": "452000, Республика Башкортостан, город Белебей, ул. Революционеров 7 а",
                "col5": "mintrud.mr9@bashkortostan.ru ",
                "col6": "https:\/\/belebey-semya.bashkortostan.ru\/",
                "col7": "8 (3478) 659925"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное учреждение Республики Башкортостан Учалинский центр \"Семья\"",
                "col4": "453731,Россия, Республика Башкортостан, село Учалы, ул. Чкалова, д. 47",
                "col5": "mintrud.mr10@bashkortostan.ru",
                "col6": "https:\/\/uchaly-semya.bashkortostan.ru\/",
                "col7": "8 (34791) 52342"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное автономное учреждение социального обслуживания населения Старобаишский дом-интернат для престарелых и инвалидов",
                "col4": "452307 Дюртюлинский р-н, с.Старобаишево, ул.Спортивна, 31",
                "col5": "stbdi@mail.ru",
                "col6": "https:\/\/starobaish.bashkortostan.ru\/",
                "col7": "8 (34787) 63262; 8 (34787) 63251"
            },
            {
                "col1": "Республика Башкортостан",
                "col2": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан ",
                "col3": "Государственное бюджетное стационарное учреждение социального обслуживания населения Серафимовский детский дом-интернат",
                "col4": "452781, РБ, Туймазинский район, с. Серафимовский, ул. 55-летия Победы, 11",
                "col5": "mintrud.su22@bashkortostan.ru",
                "col6": "https:\/\/serafimovsk.bashkortostan.ru\/",
                "col7": "8 (34782) 20719"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "Государственное бюджетное учреждение социального обслуживания Республики Бурятия «Центр помощи детям, оставшимся без попечения родителей «Добрый»",
                "col4": "671840, Республика Бурятия, Кяхтинский р-н, Субуктуй у., Ангарская ул, дом № 8",
                "col5": "kyahta_dd@govrb.ru",
                "col6": "http:\/\/center-dobryi.bur.socinfo.ru",
                "col7": "(8-301-42) 91-5-07"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "ГБУСО \"Бичурский Центр помощи детям, оставшимся без попечения родителей\"               ",
                "col4": "671360, РБ, Бичурский район, с. Бичура, ул. Соломенникова, 8 ",
                "col5": "Bcspsd@govrb.ru",
                "col6": "http:\/\/bcspsd.sdep.ru",
                "col7": "(8-301-45) 42-0-44"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "Детский дом-интернат для детей с серьезными нарушениями в интеллектуальном развитии\"Журавушка\"",
                "col4": "670045, г. Улан-Удэ, пос. Верхняя Березовка 22",
                "col5": "Sanator_dd@govrb.ru",
                "col6": "http:\/\/zhuravushka03.bur.socinfo.ru\/",
                "col7": "(83012) 27-07-37"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "ГБУСО РБ \"Центр помощи детям, оставшимся без попечения родителей, «Звездный»",
                "col4": "671610, РБ, Баргузинский  район,   п. Баргузин,  ул. Очирова, 17",
                "col5": "barguzin_dd@govrb.ru",
                "col6": "https:\/\/zvz-buryatia.ru\/",
                "col7": "(8-301-31) 41-2-90"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "Государственное бюджетное учреждение социального обслуживания \"Кабанский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "671200, РБ, Кабанский районн, с. Кабанск,\nул. Ленина, д. 39",
                "col5": "kabansk_cspsd@govrb.ru",
                "col6": "https:\/\/center-pomoschy.bur.socinfo.ru\/",
                "col7": "(8-301-38) 43-1-61"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "Государственное бюджетное учреждение социального обслуживания РБ \"Центр помощи детям, оставшимся без попечения родителей \"Малышок\"",
                "col4": "670009, Республика Бурятия, г. Улан-Удэ, ул. Комарова, 14",
                "col5": "malyshok_dd@govrb.ru",
                "col6": "https:\/\/malishokuu.bur.socinfo.ru\/",
                "col7": "(8-3012) 55-73-11"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "Государственное бюджетное учреждение социального обслуживания\n «Хоринский центр помощи детям, оставшимся без попечения родителей»",
                "col4": "671435, Республика Бурятия, Хоринский район, \nс. Санномыск, ул. Октябрьская, 7",
                "col5": "horinsk_srcn@govrb.ru",
                "col6": "http:\/\/detstvosmail.ru",
                "col7": "(8-301-48) 26-6-66"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "Государственное бюджетное учреждение социального обслуживания \"Курумканский центр социальной помощи семье и детям",
                "col4": "671640, Республика Бурятия, Курумканский район, с. Курумкан, ул. Балдакова, 45 г.",
                "col5": "kurumkan_cspsd@govrb.ru",
                "col6": "http:\/\/kcenter.sdep.ru\/",
                "col7": "8(301-49) 42-1-70"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "Государственное бюджетное учреждене социального обслуживания \"Селенгинский центр социальной помощи семье и детям\"",
                "col4": "671160, РБ, Селенгинский район, г. Гусиноозерск, ул. Карла Маркса, 11",
                "col5": "selenga_cspsd@govrb.ru",
                "col6": "http:\/\/www.semia03.ru",
                "col7": "(8-301-45) 42-0-44"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "ГБУСО \"Центр социальной помощи семье и детям \"Баяр\"",
                "col4": "671235, РБ, Джидинский район, с. Нижний Бургалтай, ул. Яковлева, 40",
                "col5": "djida_srcn@govrb.ru",
                "col6": "https:\/\/bayar.bur.socinfo.ru\/",
                "col7": "(8-301-34) 98-2-22"
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "ГБУСО \"Северобайкальский комплексный центр помощи семье и детям\"",
                "col4": "671700 Республика Бурятия, г. Северобайкальск, ул. Мира 4",
                "col5": "sever_srcn@govrb.ru",
                "col6": "https:\/\/skcpsid.bur.socinfo.ru",
                "col7": 83013022336
            },
            {
                "col1": "Республика Бурятия ",
                "col2": "Министерство социальной защиты населения",
                "col3": "ГБУ «Специализированный психоневрологический дом ребенка «Аистенок»",
                "col4": "670033 Республика Бурятия\nг.Улан-Удэ, ул. Краснофлотская - 46а",
                "col5": "spdr_aist@govrb.ru",
                "col6": " http:\/\/aisthome.ru",
                "col7": "(8-3012) 41-91-11"
            },
        ],
    ],
    [
        [
            {
                "col1": "Респулика Дагестан",
                "col2": "Министерство труда и социального развития",
                "col3": "Государственное бюджетное учреждение Республики Дагестан \"Дом-интернат для умственно отсталых детей \"Забота\"",
                "col4": "367014, Россия, Республика Дагестан, город Махачакала, ул. Солдатская , д. 3",
                "col5": "zabotard2012@mail.ru",
                "col6": "https:\/\/dagminrud.ru",
                "col7": "8 (8722) 60-14-56"
            },
            {
                "col1": "Респулика Дагестан",
                "col2": "Министерство труда и социального развития",
                "col3": "Государственное казенное учреждение Республики Дагестан \"Республиканский Дом ребенка\" г.Буйнакск.",
                "col4": "368220, Россия, Республика Дагестан, город Буйнакск, ул. Дахадаева  , д.48",
                "col5": "domrebenkab@mail.ru",
                "col6": "https:\/\/dagminrud.ru",
                "col7": "8 (87237)2-71-55"
            },
            {
                "col1": "Респулика Дагестан",
                "col2": "Министерство труда и социального развития",
                "col3": "Государственное казенное учреждение Республики Дагестан \"Детский дом № 7\"",
                "col4": "368500, Россия, Республика Дагестан, город Избербаш, ул. Маяковского  , д.139 в",
                "col5": "izberbash.detdom7-2010@mail.ru",
                "col6": "https:\/\/dagminrud.ru",
                "col7": "8 (87245)2-41-80"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Ингушетия",
                "col2": "нет организаций для детей-сирот",
                "col3": "-",
                "col4": "-",
                "col5": "-",
                "col6": "-",
                "col7": "-"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Калмыкия",
                "col2": "Министерство образования и науки Республики Калмыкия",
                "col3": "Казенное учреждение Республики Калмыкия  «Социальный приют для детей и подростков»",
                "col4": "358001, Республика Калмыкия,  г. Элиста, Н. Очирова, дом 2",
                "col5": "socpriutrk@mail.ru;",
                "col6": "https:\/\/priut-elista.kalm.socinfo.ru\/",
                "col7": "884722-6-64-10"
            },
            {
                "col1": "Республика Калмыкия",
                "col2": "Министерство образования и науки Республики Калмыкия",
                "col3": "Казенное учреждение Республики Калмыкия  «Дом ребенка (специализированный)»",
                "col4": "358000\nРеспублика Калмыкия,  г. Элиста, улица Чкалова, дом 23 а\n\n\n",
                "col5": "d.rebenka@rk08.ru",
                "col6": "https:\/\/dom-rebenka08.ru\/",
                "col7": "884722-4-13-42"
            },
            {
                "col1": "Республика Калмыкия",
                "col2": "Министерство социального развития, труда и занятости Республики Калмыкия",
                "col3": "Казенное учреждение Республики Калмыкия «Детский психоневрологический интернат с отделением молодых инвалидов» ",
                "col4": "358001  Республика Калмыкия,  г. Элиста, ул. Добровольского, дом 2",
                "col5": "dominternat_elista@mail.ru",
                "col6": "http:\/\/internat-elista.kalm.socinfo.ru\/",
                "col7": "884722-3-28-39"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Карелия",
                "col2": "Министерство социальной защиты Республики Карелия",
                "col3": "Государственное бюджетное стационарное учреждение социального обслуживания Республики Карелия «Ладвинский детский дом-интернат для умственно отсталых детей»",
                "col4": "185518, Карелия Республика, р-н Прионежский, п. Ладва, ул. Комсомольская, д.4",
                "col5": " ladva.ddi@mail.ru",
                "col6": "https:\/\/www.ladvaddi.ru\/",
                "col7": " 8 (8142) 537-350, 8-965-817-12-25"
            },
            {
                "col1": "Республика Карелия",
                "col2": "Министерство социальной защиты Республики Карелия",
                "col3": "Государственное бюджетное учреждение социального обслуживания Республики Карелия «Центр помощи детям, оставшимся без попечения родителей, № 1»",
                "col4": "186931, Республика Карелия, г. Костомукша, ул. Ленинградская, дом 11; ",
                "col5": "soccentr@onego.ru ",
                "col6": "https:\/\/centr-pomochi.krl.socinfo.ru\/",
                "col7": "(81459) 7-44-44, 8 (921) 018-01-61;"
            },
            {
                "col1": "Республика Карелия",
                "col2": "Министерство социальной защиты Республики Карелия",
                "col3": "Государственное бюджетное учреждение социального обслуживания Республики Карелия «Центр помощи детям, оставшимся без попечения родителей, № 4»",
                "col4": " 186601, Карелия Республика, р-н Кемский, п Рабочеостровск, ул. Железнодорожная, д. 8",
                "col5": "\ndetikdom@rambler.ru\n",
                "col6": "https:\/\/www.gbusork4.ru\/",
                "col7": "(814-58) 56280"
            },
            {
                "col1": "Республика Карелия",
                "col2": "Министерство социальной защиты Республики Карелия",
                "col3": "Государственное бюджетное учреждение социального обслуживания Республики Карелия «Центр помощи детям, оставшимся без попечения родителей, № 5»",
                "col4": "186420, Карелия Республика, р-н Сегежский, г. Сегежа, ул. Гагарина, д. 15а",
                "col5": "gbucodeti5@mail.ru",
                "col6": "https:\/\/www.cpd5.ru\/",
                "col7": "(814-31) 42531,42470\n\n"
            },
            {
                "col1": "Республика Карелия",
                "col2": "Министерство социальной защиты Республики Карелия",
                "col3": "Государственное бюджетное учреждение социального обслуживания Республики Карелия «Центр помощи детям, оставшимся без попечения родителей, № 6»",
                "col4": " 186150, Карелия Республика, р-н Пудожский, г. Пудож, ул. Пионерская, д. 69б",
                "col5": "zabota-pudozh@yandex.ru",
                "col6": "https:\/\/cpd6-karelia.ru\/about\/contacts\/",
                "col7": "(814 52) 53790"
            },
            {
                "col1": "Республика Карелия",
                "col2": "Министерство социальной защиты Республики Карелия",
                "col3": "Государственное бюджетное учреждение социального обслуживания Республики Карелия «Центр помощи детям, оставшимся без попечения родителей, № 7»",
                "col4": " 186790, Карелия Республика, г. Сортавала, ул. Гагарина, д. 10",
                "col5": "\nsortdd2001@mail.ru\n",
                "col6": "http:\/\/sorthelpcenter.ru\/",
                "col7": "(814-30) 47771,45293"
            },
            {
                "col1": "Республика Карелия",
                "col2": "Министерство социальной защиты Республики Карелия",
                "col3": "Государственное бюджетное учреждение социального обслуживания Республики Карелия «Центр помощи детям, оставшимся без попечения родителей, «Солнечный»",
                "col4": "186000,\nРесп. Карелия,\nг. Олонец, ул. Урицкого, 4а\n",
                "col5": "olon_deti_8@mail.ru",
                "col6": "https:\/\/cpdolonec.krl.socinfo.ru\/",
                "col7": " (81436) 4-13-70"
            },
            {
                "col1": "Республика Карелия",
                "col2": "Министерство социальной защиты Республики Карелия",
                "col3": "Государственное бюджетное учреждение социального обслуживания Республики Карелия «Центр помощи детям, оставшимся без попечения родителей, «Надежда»",
                "col4": " 185034, Карелия Республика, г. Петрозаводск, ул Судостроительная (ключевая Р-н), д. 24-а",
                "col5": "nadegdakarelia@mail.ru",
                "col6": "http:\/\/cpd-nadezhda.krl.socinfo.ru\/",
                "col7": "89114000983, 879114001318"
            },
            {
                "col1": "Республика Карелия",
                "col2": "Министерство социальной защиты Республики Карелия",
                "col3": "Государственное бюджетное учреждение социального обслуживания Республики Карелия «Центр помощи детям, оставшимся без попечения родителей, «Дом ребенка»",
                "col4": " 185000, Карелия Республика, г. Петрозаводск, пер.Попова, д. 10",
                "col5": "cpd-dr@ya.ru",
                "col6": "https:\/\/vk.com\/cpd_dr",
                "col7": "8 (8142) 75 01 60"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГУ РК \"Детский дом № 2 для детей-сирот и детей, оставшихся без попечения родителей\" г. Ухты",
                "col4": "169300, Республика Коми, \nг. Ухта, \nул. Дзержинского, д. 26 \n\n",
                "col5": "shu2@minobr.rkomi.ru",
                "col6": "https:\/\/dd2.rkomi.ru\/",
                "col7": " 8 (8216)74-13-28"
            },
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГУ РК \"Детский дом № 1 для детей-сирот и детей, оставшихся без попечения родителей\" г. Сыктывкара",
                "col4": "167904, Республика Коми, \nг. Сыктывкар, \nпгт. Краснозатонский, \nул. Ломоносова, д. 49\n",
                "col5": "dd1@minobr.rkomi.ru",
                "col6": "https:\/\/dd1.rkomi.ru\/",
                "col7": "8 (8212) 23-60-70"
            },
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГУ РК \"Детский дом № 3 для детей-сирот и детей, оставшихся без попечения родителей\" г. Сыктывкара",
                "col4": "167023, Республика Коми, \nг. Сыктывкар, \nул. Коммунистическая, \nд. 75а\n",
                "col5": "dd3@minobr.rkomi.ru",
                "col6": "http:\/\/detdom3.ru\/",
                "col7": "8 (8212) 286-432"
            },
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГУ РК \"Детский дом № 4 для детей-сирот и детей, оставшихся без попечения родителей\" г. Усинска",
                "col4": "169711, Республика Коми, \nг. Усинск, \nул. Молодежная, \nд. 20\n\n",
                "col5": "dd4@minobr.rkomi.ru",
                "col6": "https:\/\/ddusinsk.nubex.ru\/",
                "col7": "8 (82144) 4-62-06"
            },
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГУ РК \"Детский дом № 13 для детей-сирот и детей, оставшихся без попечения родителей\" п. Чим",
                "col4": "169253, Республика Коми, \nУдорский район, пос. Чим, \nул. Мира, д. 13\n\n",
                "col5": "dd13@minobr.rkomi.ru",
                "col6": "https:\/\/dd13.rkomi.ru\/",
                "col7": "8 (82135)27-5-11"
            },
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГУ РК \"Детский дом № 17 для детей-сирот и детей, оставшихся без попечения родителей\" г. Печора",
                "col4": "169600, Республика Коми, \nг. Печора, \nул. Ю.Гагарина, \nд. 45 \n\n",
                "col5": "dd17@minobr.rkomi.ru",
                "col6": "https:\/\/dd17.rkomi.ru\/",
                "col7": "8 (82142) 7-41-35"
            },
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГУ РК \"Детский дом № 18 для детей-сирот и детей, оставшихся без попечения родителей\" г. Воркуты",
                "col4": "169900, Республика Коми, \nг. Воркута, \nул. Ломоносова, д. 14 \n\n",
                "col5": "dd18@minobr.rkomi.ru",
                "col6": "https:\/\/dd18.rkomi.ru\/",
                "col7": "8 (82151) 3-61-21"
            },
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГУ РК \"Детский дом им.А.А. Католикова для детей-сирот и детей, оставшихся без попечения родителей\" ",
                "col4": "167000, Республика Коми, \nг. Сыктывкар, \nул. Карла Маркса, \nд. 120\n",
                "col5": "ddsh1@minobr.rkomi.ru",
                "col6": "http:\/\/ddkatolikova.ru\/",
                "col7": "8 (212) 240146"
            },
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГУ РК \"Специальный (коррекционный) детский дом № 1 для детей-сирот и детей, оставшихся без попечения родителей\" г. Сыктывкара",
                "col4": "167000, Республика Коми, г. Сыктывкар, Набережный пр-д, д. 1",
                "col5": "ckdd1@minobr.rkomi.ru",
                "col6": "https:\/\/skdd1.rkomi.ru\/",
                "col7": "8 (8212) 30-16-19"
            },
            {
                "col1": "Республика Коми",
                "col2": "Министерство образования и науки Републики Коми",
                "col3": "ГОУ РК \"Специальная (коррекционная) школа-интернат № 3 для детей-сирот и детей, оставшихся без попечения родителей\" г. Сыктывкара",
                "col4": "167000, Республика Коми, \nг. Сыктывкар, \nул. Мира, д. 1\n",
                "col5": "ckddsh1@minobr.rkomi.ru",
                "col6": "https:\/\/skoshi3.gosuslugi.ru\/",
                "col7": "8 (8212) 25-54-48"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Крым\n",
                "col2": "Министерство образования, науки и молодежи Республики Крым",
                "col3": "Государственное бюджетное учреждение Республики Крым для детей-сирот и детей, оставшихся без попечения родителей \"Чернышевский детский дом \"",
                "col4": "296210 Республика Крым, Раздольненский район, с. Чернышево, ул. Кирова, 1а",
                "col5": "022@crimeaedu.ru",
                "col6": "https:\/\/чернышевский-детский-дом.рф\/",
                "col7": "(06553) 9-56-24 "
            },
            {
                "col1": "Республика Крым\n",
                "col2": "Министерство образования, науки и молодежи Республики Крым",
                "col3": "Государственное бюджетное общеобразовательное учреждение Республики Крым \"Джанкойская санаторная школа-интернат\"",
                "col4": "296102 Республика Крым, г. Джанкой, пер. Керченский, 38",
                "col5": "004@crimeaedu.ru",
                "col6": "https:\/\/sanatorium-school.ru\/",
                "col7": "8 (36559) 91406"
            },
            {
                "col1": "Республика Крым\n",
                "col2": "Министерство образования, науки и молодежи Республики Крым",
                "col3": "Государственное бюджетное общеобразовательное учреждение Республики Крым \"Керченская школа-интернат  с усиленной физической подготовкой\"",
                "col4": "298306, Республика Крым, г. Керчь, ул. 12 Апреля, д. 20",
                "col5": "006@crimeaedu.ru",
                "col6": "https:\/\/спорт-кшиф.рф\/",
                "col7": "8 (36561) 66762 "
            },
            {
                "col1": "Республика Крым\n",
                "col2": "Министерство труда и социальной защиты Республики Крым",
                "col3": "Государственное бюджетное учреждение Республики Крым \"Белогорский дом-интернат для детей-инвалидов\"",
                "col4": "297600, Республика Крым, \nг. Белогорск, ул. Дубинина, 12-а\n",
                "col5": "internatbelog2007@rambler.ru ",
                "col6": "https:\/\/dom-internat-belogorsk.nubex.ru\/",
                "col7": "8 (36559) 91406 "
            },
            {
                "col1": "Республика Крым\n",
                "col2": "Министерство здравоохранения Республики Крым",
                "col3": "Государственное бюджетное учреждение здравоохранения Республики Крым «Республиканский специализированный дом ребёнка для детей с поражением центральной нервной системы и нарушением психики «Ёлочка»",
                "col4": "295044, Республика Крым, г. Симферополь, ул. Аральская, д. 4",
                "col5": "elo4kadom@yandex.ru",
                "col6": "https:\/\/respelochka.zdrav82.ru\/",
                "col7": "8 (3652) 44-01-25"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Марий Эл ",
                "col2": "Министерство труда и социальной защиты Республики Марий Эл",
                "col3": "Государственное бюджетное учреждение Республики Марий Эл\n\"Люльпанский центр для детей-сирот и детей, оставшихся без попечения родителей\"\n",
                "col4": "425205   Республика Марий Эл, Медведевский район, д.Люльпаны, улЛесная,д.24",
                "col5": "lulpan@mail.ru",
                "col6": "http:\/\/edu.mari.ru\/ou_respub\/sh22\/default.aspx",
                "col7": "8 (8362)574230"
            },
            {
                "col1": "Республика Марий Эл ",
                "col2": "Министерство труда и социальной защиты Республики Марий Эл",
                "col3": "Государственное бюджетное учреждение Республики Марий Эл \"Моркинский социально-реабилитационный центр для несовершеннолетних\"",
                "col4": "425120, Республика Марий Эл, Моркинский район, пгт. Морки, бул. Калинина, д. 8",
                "col5": "reabsoz@mail.ru",
                "col6": "https:\/\/mari-el.gov.ru\/ministries\/minsoc\/d_morki\/",
                "col7": "8 (83635)9-16-66"
            },
            {
                "col1": "Республика Марий Эл ",
                "col2": "Министерство труда и социальной защиты Республики Марий Эл",
                "col3": "Государственное бюджетное учреждение Республики Марий Эл\n«Волжский социально-реабилитационный центр для несовершеннолетних»\n",
                "col4": "425000, Республика Марий Эл, г.Волжск, ул.Леваневского, д. 18",
                "col5": " dom.nadezhda@mail.ru",
                "col6": "https:\/\/mari-el.gov.ru\/ministries\/minsoc\/d_volgsksrc\/",
                "col7": " 8 (363) 16-49-97"
            },
            {
                "col1": "Республика Марий Эл ",
                "col2": "Министерство труда и социальной защиты Республики Марий Эл",
                "col3": "Государственное бюджетное учреждение Республики Марий Эл \"Социально-реабилитационный центр для несовершеннолетних \"Журавушка\"",
                "col4": "424915, Республика Марий Эл, Звениговский район, территория реабилитационный центр Журавушка, д.1",
                "col5": "zhuravushka@inbox.ru",
                "col6": "https:\/\/mari-el.gov.ru\/ministries\/minsoc\/d_guravushka\/",
                "col7": "8 (836)45 66641"
            },
            {
                "col1": "Республика Марий Эл ",
                "col2": "Министерство труда и социальной защиты Республики Марий Эл",
                "col3": "Государственное бюджетное учреждение Республики Марий Эл\n «Детский дом-интернат «Солнышко»\n",
                "col4": "424918, РМЭ, г.Йошкар-Ола, д.Савино, ул.Первомайская,д.2",
                "col5": "internat.s@yandex.ru",
                "col6": "https:\/\/mari-el.gov.ru\/ministries\/minsoc\/d_savino\/",
                "col7": "8 (8362) 721580"
            },
            {
                "col1": "Республика Марий Эл ",
                "col2": "Министерство труда и социальной защиты Республики Марий Эл",
                "col3": "Государственное бюджетное учреждение Республики Марий Эл «Октябрьский центр для детей-сирот и детей, оставшихся без попечения родителей»",
                "col4": "425130, Республика Марий Эл, Моркинский район, п. Октябрьский, ул. Профсоюзная, д. 71",
                "col5": "oskosh-in@yandex.ru; okt_centr@minso.lokal",
                "col6": "https:\/\/oktzentr.ru\/",
                "col7": "8 (83635) 9-27-65 "
            },
            {
                "col1": "Республика Марий Эл ",
                "col2": "Министерство труда и социальной защиты Республики Марий Эл",
                "col3": "Государственное бюджетное учреждение Республики Марий Эл \"Волжский центр для детей-сирот и детей, оставшихся без попечения родителей\"",
                "col4": "РМЭ г.Волжск ул.Либкнехта д.128 425003",
                "col5": "sh-int-v@mail.ru",
                "col6": "http:\/\/internat-volga.com.ru\/",
                "col7": "8 (83631)6-88-12"
            },
            {
                "col1": "Республика Марий Эл ",
                "col2": "Министерство здравоохранения Республики Марий Эл",
                "col3": "Государственное бюджетное учреждение Республики Марий Эл, специализированное отделение «Дом ребенка»",
                "col4": "424028, Республика Марий Эл, г.Йошкар-Ола, ул.Йывана Кырли, д.16А",
                "col5": "dombaby@mail.ru",
                "col6": "https:\/\/mari-el.gov.ru\/ministries\/minzdrav\/drb",
                "col7": "8 (8362)738260 8 (8362)738305"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Мордовия",
                "col2": "Министерство социальной защиты, труда и занятости населения Республики Мордовия",
                "col3": "Государственное бюджетное учреждение Республики Мордовия «Центр помощи детям, оставшимся без попечения родителей»",
                "col4": "430904, Республика Мордовия, г.Саранск, п.Ялга, ул.Пионерская, 45",
                "col5": " d.dom.yalg@e-mordovia.ru",
                "col6": "ylgahome.3dn.ru",
                "col7": "8(8342) 25-38-80"
            },
            {
                "col1": "Республика Мордовия",
                "col2": "Министерство социальной защиты, труда и занятости населения Республики Мордовия",
                "col3": "ГБСУСОН РМ «Потьминский психоневрологический интернат с детским отделением»",
                "col4": "431100, Республика Мордовия, Зубово-Полянский район, п.Потьма, ул.Советская, 70",
                "col5": "szn.potm@e-mordovia.ru",
                "col6": "di_potma.soc13.ru",
                "col7": " (83458) 3-75-83"
            },
            {
                "col1": "Республика Мордовия",
                "col2": "Министерство социальной защиты, труда и занятости населения Республики Мордовия",
                "col3": "ГБСУСОН РМ «Темниковский детский дом-интернат»",
                "col4": "431220, Республика Мордовия, Темниковский район, г. Темников, ул. Первомайская,30",
                "col5": "szn.temndi@e-mordovia.ru",
                "col6": "di_temnikov.soc13.ru",
                "col7": " (83445) 2-27-96,  2-21-82"
            },
            {
                "col1": "Республика Мордовия",
                "col2": "Министерство здравоохранения Республики Мордовия",
                "col3": "Государственное казенное учреждение здравоохранения  Республики Мордовия «Большеберезниковский дом ребенка специализированный»",
                "col4": "431750, Республика Мордовия, Большеберезниковский район, с. Большие Березники, ул. Лесная, д. 10, ",
                "col5": " socbdrs@e-mordovia.ru",
                "col6": "bbdr13.nethouse.ru",
                "col7": " 8(83436) 2-31-76"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКУ РС (Я) \"Республиканский центр содействия семейному воспитанию \"Берегиня\"",
                "col4": "677000,  г. Якутск, ул. Петра Алексеева д.21\/4",
                "col5": "rcdn_yak@gov14.ru",
                "col6": "https:\/\/vk.com\/cssv_bereginya",
                "col7": "8 (4112) 422359"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКУ РС (Я) \"Алданский центр помощи детям-сиротам и детям, оставшимся без попечения родителей\"",
                "col4": "678901, Алданский улус, г.Алдан, ул.Калинина, д.2\/5",
                "col5": "cods_ald@gov14.ru",
                "col6": "https:\/\/vk.com\/public217319151",
                "col7": "8 (41145) 32952"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКУ РС(Я) \"Верхневилюйский центр содействия семейному воспитанию им. И.И. Седалищева\"",
                "col4": "678230,  Верхневилюйский улус, с. Верхневилюйск, ул. Советская, дом 17",
                "col5": "cpds_vvil@gov14.ru",
                "col6": "http:\/\/vvil-detskyidom.ucoz.ru",
                "col7": "8 (41133) 43358"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКУ РС (Я) \"Вилюйский центр содействия семейному воспитанию им. С.М. Аржакова\"",
                "col4": "678200, Вилюйский улус г. Вилюйск, пер. П.Семенова , д. 18\/3",
                "col5": "cpds_vil@gov14.ru",
                "col6": "https:\/\/vil-detdom.profiedu.ru\/ ",
                "col7": "8 (41132) 43169"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКУ РС (Я) \"Оймяконский центр содействия семейному воспитанию\"",
                "col4": "678730,  Оймяконский улус, пгт. Усть-Нера, ул. Андрионова д. 4.",
                "col5": "cpds_oymk@gov14.ru",
                "col6": "https:\/\/vk.com\/public217265998",
                "col7": "8 (41154) 20044"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКУ РС(Я)  \"Нерюнгринский центр помощи детям-сиротам и детям, оставшимся без попечения родителей \"Вектор\"",
                "col4": "678981, Нерюнгринский район , п. Чульман, ул. Советская, д. 75 корпус \"а\"",
                "col5": "cpds_neru@gov14.ru",
                "col6": "https:\/\/vk.com\/cpds_vektor",
                "col7": "8 (41147) 71367"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКУ РС (Я) \"Сунтарский центр содействия семейному воспитанию имени С.Г. Кривошапкина\"",
                "col4": "678282\nРеспублика Саха(Якутия)\nСунтарский улус\nс. Тойбохой\nул. Партизанская, 39\n",
                "col5": "spds_sun@gov14.ru",
                "col6": "https:\/\/vk.com\/club217298195",
                "col7": "8 (51135) 25225"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКУ РС (Я) \"Хангаласский центр содействия семейному воспитанию\" ",
                "col4": " 678020,  Хангаласский у, Мохсоголлох п, улица Заводская, 13",
                "col5": "cpds_khang@gov14.ru",
                "col6": "https:\/\/hcssv.ru\/#gsc.tab=0",
                "col7": "8 (41144) 47392"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКУ РС (Я) \"Республиканский детский специализированный дом социального обслуживания\"",
                "col4": "678960, Нерюнгринский район, п.Серебряный Бор, д. 159.",
                "col5": "rddi_yak@gov14.ru",
                "col6": "https:\/\/detdom14.nethouse.ru\/page\/793249",
                "col7": "8 (41147) 79275"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГБУ РС (Я) \"Республиканский центр содейсвия семейному воспитанию\"",
                "col4": "677000, Республика Саха (Якутия), г. Якутск, ул. П.Алексеева, 97\/1",
                "col5": "rcssv@gov14.ru",
                "col6": "https:\/\/yktfamilycenter.ru\/",
                "col7": "8 (4112) 405071"
            },
            {
                "col1": "Республика Саха (Якутия)",
                "col2": "Министерство труда и социального развития Республики Саха (Якутия)",
                "col3": "ГКОУ РС(Я) \"Республиканская коррекционная (специальная) школа-интернат для детей-сирот и детей, оставшихся без попечения родителей\"",
                "col4": "677008, г. Якутск Сергеляхское шоссе 10 км, дом 5",
                "col5": " rskshi@gov14.ru",
                "col6": "https:\/\/rskshi2.obr.sakha.gov.ru\/",
                "col7": "8 411(22) 368505"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Северная Осетия - Алания",
                "col2": "Министерство труда и социального развития Республики Северная Осетия - Алания",
                "col3": "Государственное казенное учреждение социального обслуживания\nРСО-Алания «Центр содействия семейному воспитанию\nг.Владикавказ «Хуры тын»",
                "col4": "362042,\nРСО-Алания,\nг.Владикавказ, ул.Бзарова,29",
                "col5": "hurtin@minsotc.alania.gov.ru",
                "col6": "http:\/\/detdom-huritin.ru",
                "col7": "8 (8672) 51-05-14"
            },
            {
                "col1": "Республика Северная Осетия - Алания",
                "col2": "Министерство труда и социального развития Республики Северная Осетия - Алания",
                "col3": "Государственное казенное учреждение социального обслуживания             РСО-Алания «Центр  содействия семейному воспитанию г.Моздок «Амонд»",
                "col4": "363751\nРСО-Алания,\n  г.Моздок,\nул.Маркова,д.58",
                "col5": "amond@minsotc.alania.gov.ru",
                "col6": "http:\/\/amond.mozdokschool.ru\/",
                "col7": "8 (86736) 4-13-44"
            },
            {
                "col1": "Республика Северная Осетия - Алания",
                "col2": "Министерство труда и социального развития Республики Северная Осетия - Алания",
                "col3": "Государственное бюджетное учреждение социального обслуживания РСО-Алания «Республиканский детский дом – интернат «Ласка»",
                "col4": "362035,                      РСО-Алания,\nг. Владикавказ. Западный проезд, 5",
                "col5": "laska@minsotc.alania.gov.ru",
                "col6": "http:\/\/www.laska-alania.ru\/",
                "col7": "8 (8672) 52-20-87"
            },
            {
                "col1": "Республика Северная Осетия - Алания",
                "col2": "Министерство труда и социального развития Республики Северная Осетия - Алания",
                "col3": "Государственное бюджетное учреждение  социального обслуживания РСО-Алания \" Центр содействия семейному воспитанию \"Саби\" ",
                "col4": "362003,                 РСО-Алания, г.Владикавказ,   ул.Калоева, 123",
                "col5": "sabi@minsotc.alania.gov.ru",
                "col6": "@zentrsabi",
                "col7": "8 (8672) 25-88-47"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Детский дом Приволжского района г. Казани\"",
                "col4": "420049, Республика Татарстан, г. Казань, ул. Агрономическая, д. 14",
                "col5": "ddprivr@mail.ru",
                "col6": "https:\/\/edu.tatar.ru\/priv\/page441104.htm",
                "col7": "8 (843) 277-12-04"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Альметьевский детский дом\"",
                "col4": "423452, Республика Татарстан, г.Альметьевск, ул.К.Цеткин, д.54",
                "col5": "Detisolnca2009@mail.ru",
                "col6": "https:\/\/edu.tatar.ru\/almet\/page441088.htm",
                "col7": "8 (843) 343-08-45"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Елабужский детский дом\"",
                "col4": "423600, Республика Татарстан, город Елабуга, улица Казанская, дом 2",
                "col5": "dd.Elb@tatar.ru",
                "col6": "https:\/\/edu.tatar.ru\/elabuga\/page890.htm",
                "col7": "8 (855) 577-85-29"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Лаишевский детский дом\"",
                "col4": " 422610, Республика Татарстан, Лаишевский район, г.Лаишево, ул. Космонавтов, д.2 ",
                "col5": "Laishevo.Ddom@tatar.ru",
                "col6": "https:\/\/edu.tatar.ru\/laishevo\/laish\/GBOUlaish.htm",
                "col7": "8 (843) 782-53-57"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Лениногорский детский дом\"",
                "col4": "423259, Республика Татарстан, г. Лениногорск, ул. Садриева, д. 51",
                "col5": "lendetdom2005@mail.ru",
                "col6": "https:\/\/edu.tatar.ru\/login\/",
                "col7": "8 (843) 955-17-73"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Нижнекамский детский дом\"",
                "col4": "423570, Республика Татарстан, г. Нижнекамск, ул. Чабьинская, д. 19",
                "col5": "detskiydom-nk@mail.ru",
                "col6": "https:\/\/edu.tatar.ru\/nkamsk\/page1634.htm",
                "col7": "8 (843) 539-25-40"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Нурлатский детский дом\"",
                "col4": "422510, Республика Татарстан, Зеленодольский район, с.Нурлаты, ул.Ленина, д.8",
                "col5": "ddn.Zel@tatar.ru",
                "col6": "https:\/\/edu.tatar.ru\/z_dol\/nurlatdetdom",
                "col7": "8 (84371) 3-98-80"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Чистопольский детский дом\"",
                "col4": "Республика Татарстан, 422980, г. Чистополь, ул. Нариманова, д.58",
                "col5": "chist.detdom@yandex.ru",
                "col6": "https:\/\/edu.tatar.ru\/chistopol\/page2157.htm",
                "col7": "8 (843) 425-28-57"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное общеобразовательное учреждение \"Мензелинская школа-интернат для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья»",
                "col4": "423700, Республика Татарстан, г. \nМензелинск,\nул. Татарстан, д. 2А\n",
                "col5": "Kor_sh@mail.ru",
                "col6": "https:\/\/edu.tatar.ru\/menzelinsk\/sch_korr-int",
                "col7": "8 (85555) 3-54-01"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство образования и науки Республики Татарстан",
                "col3": "Государственное бюджетное общеобразовательное учреждение \"Казанская школа-интернат № 11 для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья\"",
                "col4": "420075, Республика Татарстан, г. Казань, ул. Халезова, д. 24",
                "col5": "kaz.shk-int11@mail.ru",
                "col6": "https:\/\/edu.tatar.ru\/sovetcki\/page441113.htm",
                "col7": "8 (843) 234-12-61"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство труда, занятости и социальной защиты Республики Татарстан",
                "col3": "Государственное казенное учреждение «Верхне-Отарский детский дом-интернат, предназначенный для граждан, имеющих психические расстройства»",
                "col4": "422051, Сабинский район, с. Верхний Отар, ул. Новая, д. 1 ",
                "col5": "Ddiuod.V-otarskiy@tatar.ru",
                "col6": "https:\/\/sobes.tatarstan.ru\/gauso-verhne-otarskiy-detskiy-dom-internat-dlya.htm",
                "col7": "8 (4362) 4-97-59"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство труда, занятости и социальной защиты Республики Татарстан",
                "col3": "Государственное казенное учреждение «Дербышкинский детский дом-интернат, предназначенный для граждан, имеющих психические расстройства»",
                "col4": "420096, г. Казань, Советский район, ул. Лесная, д. 1 ",
                "col5": "Ddiuod.Derbyshki@tatar.ru    ",
                "col6": "https:\/\/sobes.tatarstan.ru\/gauso-derbishkinskiy-detskiy-dom-internat-dlya.htm",
                "col7": "8 (843) 239-50-19"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство здравоохранения Республики Татарстан",
                "col3": "ГКУЗ \"Республиканский дом ребенка специализированный\"",
                "col4": "РТ, г.Казань, Ямашева 88а",
                "col5": "mz.kazan_dr@tatar.ru",
                "col6": "https:\/\/rdrs.tatarstan.ru\/",
                "col7": "8 (843) 521-75-43"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство здравоохранения Республики Татарстан",
                "col3": "филиал ГКУЗ \"Республиканский дом ребенка специализированный\"",
                "col4": "420141, г.Казань, ул. Ю.Фучика, д. 73; филиал",
                "col5": "mz.rdrs@tatar.ru ",
                "col6": "https:\/\/rdrs.tatarstan.ru\/",
                "col7": "8 (843) 268-59-20"
            },
            {
                "col1": "Республика Татарстан",
                "col2": "Министерство здравоохранения Республики Татарстан",
                "col3": "филиал ГКУЗ \"Республиканский дом ребенка специализированный\"",
                "col4": "423810 г.Набережные Челны, бульвар Романтиков, д.5 (54\/05), филиал",
                "col5": "mz.nchelny_drs@tatar.ru ",
                "col6": "https:\/\/rdrs.tatarstan.ru\/",
                "col7": "8 (855) 259-13-75"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Тыва (Тува) ",
                "col2": "Министерство образования Республики Тыва (Тува) ",
                "col3": "ГБОУ Республики Тыва \"Социально-Реабилитационный Центр для Несовершеннолетних\"",
                "col4": "Республика Тыва, г. Кызыл, ул. Кочетова, д. 21",
                "col5": "detski-dom.tuva@mail.ru",
                "col7": "8 (394) 22-3-16-18"
            },
            {
                "col1": "Республика Тыва (Тува) ",
                "col2": "Министерство образования Республики Тыва (Тува) ",
                "col3": "Государственное бюджетное образовательное учреждение «Кызыл-Арыгская школа-интернат»",
                "col4": "Республика Тыва, Тандинский р-н, с. Кызыл-Арыг, ул. Школьная, д. 14",
                "col5": "surguul@mail.ru",
                "col6": "https:\/\/kor-kyzyl-aryg.rtyva.ru\/",
                "col7": "8 (923) 546-97-35"
            },
            {
                "col1": "Республика Тыва (Тува) ",
                "col2": "Министерство образования Республики Тыва (Тува) ",
                "col3": "Государственное бюджетное образовательное учреждение «Республиканская школа-интернат «Тувинский кадетский корпус»",
                "col4": "Республика Тыва, г. Кызыл, ул. Колхозная, д. 56",
                "col5": "shk.in@mail.ru",
                "col6": "https:\/\/rshi-tkk.rtyva.ru\/",
                "col7": "8 (394) 22 -4-82-47"
            },
        ],
    ],
    [
        [
            {
                "col1": "Республика Хакасия",
                "col2": "Министерство образования и науки Республики Хакасия",
                "col3": "Государственное бюджетное учреждение Республики Хакасия для детей-сирот и детей, оставшихся без попечения родителей,   «Детский дом «Ласточка»",
                "col4": "655602, Республика Хакасия, г. Саяногорск, Ленинградский м-он, д.35 ",
                "col5": "education_19_278@mail.ru",
                "col6": "http:\/\/detdom-sayan-obr.edusite.ru\/",
                "col7": "8 (39042) 2-75-71"
            },
            {
                "col1": "Республика Хакасия",
                "col2": "Министерство образования и науки Республики Хакасия",
                "col3": "Государственное бюджетное общеобразовательное учреждение Республики Хакасия для детей- сирот и детей, оставшихся без попечения родителей, «Черногорская школа-интернат»",
                "col4": "655158, Республика Хакасия, г, Дзержинского ул, дом.16",
                "col5": "school_19_52int@r-19.ru",
                "col6": "https:\/\/chernogorsk-internat.khakasiyaschool.ru",
                "col7": "8 (390) 312-17-20"
            },
        ],
    ],
    [
        [
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Азовский центр помощи детям»",
                "col4": "346787, г. Азов, ул. Ленина, 79",
                "col5": "dd_azov@rostobr.ru",
                "col6": "http:\/\/deticentrazov.ru\/",
                "col7": "(863-42) 4-02-15, 5-40-48 "
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Батайский центр помощи детям»",
                "col4": "346880, г. Батайск, ул. Куйбышева, 165",
                "col5": "dd_bataysk@rostobr.ru",
                "col6": "http:\/\/detdom-bataysk.ru\/",
                "col7": "(863-54) 2-25-66"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Донецкий центр помощи детям»",
                "col4": "346330, г. Донецк, пер. Карбышева, 32",
                "col5": "dd_donetsk@rostobr.ru",
                "col6": "http:\/\/detdomdonetsk.ru\/",
                "col7": "(863-68) 2-75-80, 2-75-88"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Зверевский центр помощи детям»",
                "col4": "346311, г.Зверево, ул. Космонавтов, 18",
                "col5": "dd_zverevo@rostobr.ru",
                "col6": "https:\/\/ddzverevo.edusite.ru\/",
                "col7": "(863-55) 4-23-39"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Новочеркасский центр помощи детям № 1»",
                "col4": "346410, г. Новочеркасск, ул. Грекова, 110",
                "col5": "dd_novoch_1@rostobr.ru",
                "col6": "https:\/\/www.novotch-detdom1.ru\/",
                "col7": "(863-52) 4-02-44"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Новочеркасский центр помощи детям № 8»",
                "col4": "346400, г. Новочеркасск, ул. Дворцовая, 13",
                "col5": "dd_novoch_8@rostobr.ru",
                "col6": "https:\/\/ncpd8.ru\/",
                "col7": "(843-52) 4-44-87\n (863-52) 4-22-94\n"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Новошахтинский центр помощи детям»",
                "col4": "346918, г. Новошахтинск, ул. Садовая, 23",
                "col5": "dd_novosh_1@rostobr.ru",
                "col6": "https:\/\/detdom1-nov.ucoz.ru\/",
                "col7": "(863-69) 2-13-74, 2-13-06"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Ростовский центр помощи детям с ограниченными возможностями здоровья № 4»",
                "col4": "344039, г.Ростов-на-Дону, пер. Дальний, 17",
                "col5": "dd_rnd_4@rostobr.ru",
                "col6": "https:\/\/dondetdom4.ru\/",
                "col7": "(863) 234-04-33"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Ростовский центр помощи детям № 7»",
                "col4": "344095, г. Ростов-на-Дону, ул. Вятская, 37\/4",
                "col5": "dd_rnd_7@rostobr.ru",
                "col6": "https:\/\/rcpd7.ru\/",
                "col7": "(863) 252-69-23, 252-76-70"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Ростовский центр помощи детям № 10»",
                "col4": "344020, г.Ростов-на-Дону, ул. Грисенко, 19",
                "col5": "dd_rnd_10@rostobr.ru",
                "col6": "https:\/\/detdom10-rostov.ru\/",
                "col7": "(863) 211-11-73"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Таганрогский центр помощи детям № 3»",
                "col4": "347917, г. Таганрог, проезд 4-й Линейный, 146-а",
                "col5": "dd_tagan_3@rostobr.ru",
                "col6": "http: \/\/тцпд3.рф\/",
                "col7": "(863-4) 60-48-59, 324-711"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Таганрогский центр помощи детям № 5»",
                "col4": "347923, г. Таганрог, ул.Свободы, 17-3",
                "col5": "dd_tagan_5@rostobr.ru",
                "col6": "http:\/\/super-kids.ucoz.ru\/",
                "col7": "(863-4) 64-34-60, 64-20-76"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Таганрогский центр помощи детям № 7»",
                "col4": "347905, г.Таганрог, ул.Заводская, 7",
                "col5": "dd_tagan_7@rostobr.ru",
                "col6": "http:\/\/myideti.sochost.ru\/",
                "col7": "(863-4) 62-24-11(12), 65-01-39"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Шахтинский центр помощи детям № 1»",
                "col4": "346500, г. Шахты, ул. Шевченко, 113",
                "col5": "dd_shahti_1@rostobr.ru",
                "col6": "http:\/\/dd61.ru\/",
                "col7": "(863-62) 2-77-61, 2-31-97"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Шахтинский центр помощи детям № 3»",
                "col4": "346510, г. Шахты, ул. Обуховой, 2-б",
                "col5": "dd_shahti_3@rostobr.ru",
                "col6": "(863-62) 3-40-62, 3-45-65",
                "col7": "http:\/\/domdetei-shakhty.ru\/"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Большелогский центр помощи детям»",
                "col4": "346710, Аксайский р-н, х. Большой Лог, ул.Советская, 73",
                "col5": "dd_bol_log@rostobr.ru",
                "col6": "http:\/\/ddbollog.gauro-riacro.ru\/",
                "col7": "(863-50) 3-40-30, 3-42-03"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Ёлкинский центр помощи детям»",
                "col4": "346621, Багаевский район, х. Елкин, ул. Тимирязева, 1",
                "col5": "dd_elkin@rostobr.ru",
                "col6": "https:\/\/cpd-elkin.ru\/",
                "col7": "(863-57) 4-15-36"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Сулинский центр помощи детям»",
                "col4": "346112, Миллеровский р-н, с. Сулин, пер. Западный, 1",
                "col5": "dd_sulin_miller@rostobr.ru",
                "col6": "http:\/\/sulin-dd.gauro-riacro.ru\/",
                "col7": "(863-85) 54-6-83"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Мясниковский центр помощи детям»",
                "col4": "348000, Мясниковский р-н, с.Чалтырь, ул. К.Маркса, 29",
                "col5": "dd_arevik@rostobr.ru",
                "col6": "http:\/\/centr-arevik.ru\/",
                "col7": "(863-49) 2-11-41, 2-38-06"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Островянский центр помощи детям»",
                "col4": "347523, Орловский р-н, х. Островянский, ул. Советская, 21",
                "col5": "dd_ostrov@rostobr.ru",
                "col6": "http:\/\/ostrov-cpd.ru\/",
                "col7": "(863-75) 4-41-75"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Кочетовский центр помощи детям»",
                "col4": "346633, Семикаракорский р-н, ст. Кочетовская, 11 переулок, 24",
                "col5": "dd_kochet@rostobr.ru",
                "col6": "www.цпд-кочетовский.рф",
                "col7": "(863-56) 2-36-48, 2-36-66"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Ерофеевский центр помощи детям»",
                "col4": "346060, Тарасовский р-н, х. Ерофеевка, ул. Школьная, 17",
                "col5": "dd_erofeevka@rostobr.ru",
                "col6": "http:\/\/cpd-erofeevka.ru\/",
                "col7": "(863-86) 3-81-37"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "государственное казенное учреждение социального обслуживания Ростовской области центр помощи детям, оставшимся без попечения родителей, «Шолоховский центр помощи детям»",
                "col4": "346266, Шолоховский р-н, х. Калининский, ул. Центральная, 25 ",
                "col5": "dd_kalinin@rostobr.ru",
                "col6": "http:\/\/detdomkalin.ucoz.net\/",
                "col7": "(863-53) 7-35-53"
            },
            {
                "col1": "Ростовская область ",
                "col2": "Министерство общего и профессионального образования Ростовской области",
                "col3": "ГБУСОН РО «Азовский детский дом-интернат для умственно отсталых детей»",
                "col4": "346780, Ростовская область, \nг. Азов, Кагальницкое шоссе, д. 1",
                "col5": "addi-01@mail.ru",
                "col6": "https:\/\/azovdom.rnd.socinfo.ru\/",
                "col7": "(863-42) 6-93-32, (863-42) 6-86-75"
            },
        ],
    ],
    [
        [
            {
                "col1": "Рязанская область",
                "col2": "Министерство образования и молодежной политики Рязанской области",
                "col3": "ОГБОУ «Солотчинская школа-интернат для детей-сирот и детей, оставшихся без попечения родителей»",
                "col4": "390021 г. Рязань, ул. Владимирская, (п.Солотча) д.100\n",
                "col5": "solochinskaya.shi@ryazan.gov.ru",
                "col6": "https:\/\/sh-int-solotchinskaya-ryazan-r62.gosweb.gosuslugi.ru\/",
                "col7": "8 (4912) 28-79-75"
            },
            {
                "col1": "Рязанская область",
                "col2": "Министерство образования и молодежной политики Рязанской области",
                "col3": "ОГБОУ «Рыбновская школа-интернат для детей-сирот и детей, оставшихся без попечения родителей»",
                "col4": "391110 Рязанская область, Рыбновский район, г. Рыбное,  ул. Макаренко, д.16\n",
                "col5": "rybnovskaya.shi@ryazan.gov",
                "col6": "https:\/\/sh-int-rybnovskaya-r62.gosweb.gosuslugi.ru\/",
                "col7": "8 (49137) 5-14-27"
            },
            {
                "col1": "Рязанская область",
                "col2": "Министерство образования и молодежной политики Рязанской области",
                "col3": "ОГБОУ «Елатомская школа-интернат для детей-сирот и детей, оставшихся без попечения родителей»",
                "col4": "391351 Касимовский р-н, р.п. Елатьма, ул. Янина, 1",
                "col5": "elatomskaya.shi@ryazan.gov.ru",
                "col6": "https:\/\/sh-int-elatomskaya-r62.gosweb.gosuslugi.ru\/",
                "col7": "(49131)  9-12-57"
            },
            {
                "col1": "Рязанская область",
                "col2": "Министерство образования и молодежной политики Рязанской области",
                "col3": "ГБСУ РО «Елатомский детский дом социального обслуживания»",
                "col4": "391351, Рязанская область, Касимовский район, п. Елатьма, ул. Егерева, 36\/2",
                "col5": "ddi.elatma@ryazan.gov.ru",
                "col6": "https:\/\/el-di.ryazanszn.ru\/",
                "col7": " 8 (49131) 91-0-77"
            },
        ],
    ],
    [
        [
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области «Центр помощи детям, оставшимся без попечения родителей «Иволга» городского округа Самара (коррекционный)»",
                "col4": "443093 г. Самара ул. М. Тореза, 8  ",
                "col5": "detdom163@yandex.ru",
                "col6": "https:\/\/samara-detdom3.ru\/",
                "col7": "8(846)3361504   8(846)3361508"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области \"Сергиевский комплексный центр социального обслуживания населения \"Янтарь\"",
                "col4": "446550, Самарская область, Сергиевский район, п. Светлодольск, ул. Комсомольская, д. 25",
                "col5": "yantar@socio.samregion.ru,  ambra08@mail.ru",
                "col6": "http:\/\/kcson-yantar.smr.socinfo.ru\/",
                "col7": "8(84655)43326   8(84655)43238 "
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области «Центр помощи детям, оставшимся без попечения родителей «Искра» городского округа Сызрань (коррекционный)»  ",
                "col4": "446026, Самарская область, г.Сызрань, ул. Декабристов, 34                ",
                "col5": "syzrandetdom@mail.ru",
                "col6": "https:\/\/www.syzrandetdom.ru",
                "col7": "8(8464)333358"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное казенное учреждение Самарской области  «Центр помощи детям, оставшимся без попечения родителей «Созвездие» городского округа Тольятти (коррекционный)»",
                "col4": "445042, РФ, Самарская обл., г. Тольятти, \nул. Дзержинского, д. 41.\n",
                "col5": "ddsozvezdie@yandex.ru",
                "col6": "http:\/\/sozvezdie.smr.socinfo.ru\/",
                "col7": "8(8482)331442 8(8482)331445"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области \"Тольяттинский социальный приют для детей и подростков \"Дельфин\"",
                "col4": "445051, Самарская область, г.о.Тольятти, ул.Маршала Жукова, д.20",
                "col5": "delfin_priut@mail.ru",
                "col6": "www.deti-tlt.ru",
                "col7": "8(8482)345095"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное казенное учреждение Самарской области «Центр помощи детям, оставшимся без попечения родителей, имени Фролова Б.П. городского округа Самара (коррекционный)»",
                "col4": "443072 г.Самара, Московское шоссе, 18км., д.18а",
                "col5": "samaradd1@yandex.ru",
                "col6": "dd1-samara.ru",
                "col7": "8(846)9259977"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казённое учреждение здравоохранения Самарской области \"Дом ребенка специализированный\"",
                "col4": "446028, Самарская область, г. Сызрань, пр. Гагарина, д.44",
                "col5": "szdomrebenka@mail.ru",
                "col7": "8(8464)354874"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области \"Кинель-Черкасский социально-реабилитационный центр для несовершеннолетних \"Солнечный\"",
                "col4": "446321 Самарская область, Кинель-Черкасский район, поселок Подгорный, улица Строителей 5 а",
                "col5": "prijt99@mail.ru",
                "col6": "http:\/\/srcn-solnechniy.smr.socinfo.ru\/",
                "col7": "8(84660)23807"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное казенное учреждение Самарской области \"Комплексный центр социального обслуживания населения \"Ровесник\"",
                "col4": "443081 Самарская область, г. Самара, ул.Стара Загора 113А",
                "col5": "sprovesnik@mail.ru",
                "col6": "\nrovesniksamara.ru",
                "col7": " 8(846)9513523"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное казенное учреждение Самарской области \"Чапаевский социально-реабилитационный центр для несовершеннолетних\"",
                "col4": "446103, Самарская область, г. Чапаевск, ул. Белинского, д. 49",
                "col5": "chapsrcn@socio.samregion.ru",
                "col6": "http:\/\/srcn-chap.ukit.me\/",
                "col7": "8 (84639)30365"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области \"Социльно-реабилитационный центр для несовершеннолетних \"Наш дом\"",
                "col4": "446206, Самарская область, г. Новокуйбышевск, ул. Островского, 12Б          ",
                "col5": "srcnashdom@yandex.ru",
                "col6": "https:\/\/srcnashdom.ru",
                "col7": "8(84635)73460"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казённое учреждение здравоохранения Самарской области \"Тольяттинский дом ребенка\"",
                "col4": "445019, Самарская область, г. Тольятти, ул. Чапаева, 42а",
                "col5": "domrebnv56@mail.ru",
                "col7": "8(8482)221886"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области \"Волжский социально-реабилитационный центр для несовершеннолетних \"Тополек\"",
                "col4": "443548, Самарская область, Волжский район, пгт Смышляевка, пер. Коммунистический, д.14",
                "col5": "topolek443548@mail.ru",
                "col6": "https:\/\/srcn-topolek.ucoz.site",
                "col7": "8(846)9991950"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное казенное учреждение Самарской области \"Социально-реабилитационный центр для несовершеннолетних \"Солнечный лучик\"",
                "col4": " 445556, Самарская область, Приволжский район, с. Спасское, ул. Галактионовская д.76",
                "col5": "sollychik@mail.ru",
                "col6": "https:\/\/sollychik.ru\/",
                "col7": "8(84647)92231"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области \"Комплексный центр социального обслуживания населения \"Радуга\" ",
                "col4": "446643, Самарская область, Алексеевский район,                 поселок Авангард, ул. Советская, 5а",
                "col5": "raduga63@socio.samregion.ru",
                "col6": "http:\/\/kcson-raduga.smr.socinfo.ru\/ ",
                "col7": "8(8467)148112"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное казенное учреждение Самарской области \"Клявлинский социальный приют для детей и подростков \"Надежда\" ",
                "col4": "446941, Самарская область, Клявлинский район, с.Назаровка, ул. Молодежная д.12",
                "col5": "ikvt04986.nadezhda@yandex.ru",
                "col6": "http:\/\/nadezhda.smr.socinfo.ru\/",
                "col7": "8(84653)43184"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное казенное учреждение Самарской области \"Социально-реабилитационный центр для несовершеннолетних \"Огонёк\"",
                "col4": "446302,  Самарская область, г. Отрадный, ул. Гагарина 59 А",
                "col5": "ogonekadm@socio.samregion.ru",
                "col6": "https:\/\/www.ogonekotr.ru\/ru",
                "col7": "8(84661)23526 8(84661)25829 8(84661)21876"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное казенное учреждение Самарской области \"Красноярский социально-реабилитационный центр для несовершеннолетних \"Феникс\"",
                "col4": "446379, Самарская область, Красноярский район, пгт.Новосемейкино, ул.Мира, д.4",
                "col5": "ks-rc@yandex.ru",
                "col6": "https:\/\/ksrc-feniks.smr.socinfo.ru\/about",
                "col7": "8(846)2258939"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области \"Тольяттинский социально-реабилитационный центр для несовершеннолетних \"Гармония\"",
                "col4": "445041 г. Тольятти, Железнодорожная д.19",
                "col5": "garmonia_tlt@mai.ru",
                "col6": "https:\/\/center-garmonia63.ru\/ ",
                "col7": "8(8482)971148"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственнон казенное учреждение  Самарской области «Октябрьский социально-реабилитационный центр для несовершеннолетних»",
                "col4": "445246, Самарская область,             г. Октябрьск, ул. Пионерская, 18",
                "col5": "src-okt@yandex.ru",
                "col6": "http:\/\/src-okt.ru\/",
                "col7": "8(84646)44481 8(84646)41351"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение Самарской области «Центр помощи детям, оставшимся без попечения родителей «Единство» городского округа Тольятти (коррекционный)»  ",
                "col4": "Самарская обл, г.Тольятти 445 015 ул. Шлюзовая д.8,",
                "col5": "detdom-edinstvo@yandex.ru",
                "col6": "https:\/\/dd-edinstvo.smr.socinfo.ru",
                "col7": "8(8482)452093"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "Государственное казенное учреждение здравоохранения Самарской области \"Дом ребенка \"Солнышко\" специализированный\" ",
                "col4": "443110, Самарская область, г. Самара, ул. Осипенко, дом 128 ",
                "col5": "solnyshco07@rambler.ru",
                "col6": "http:\/\/dr-soln.ru\/",
                "col7": "8(846)3369709"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное бюджетное учреждение Самарской области «Самарский дом-интернат, в том числе детский, предназначенный для граждан, имеющих психические расстройства»",
                "col4": "443016,\nСамарская область,\nг. Самара, \nул. Нагорная, д.15\n",
                "col5": "sampans@socio.samregion.ru\n\nsampans@mail.ru",
                "col6": "https:\/\/samara-pans.ru\/",
                "col7": "8(846)951-23-52"
            },
            {
                "col1": "Самарская область",
                "col2": "Министерство социально-демографической и семейной политики Самарской области ",
                "col3": "государственное бюджетное учреждение Самарской области «Сергиевский дом-интернат, в том числе детский, предназначенный для граждан, имеющих психические расстройства»",
                "col4": "446522,\nСамарская область,\nСергиевский район,\nс. Воротнее,\nул. Почтовая, д.22\n",
                "col5": "serdetpans@socio.samregion.ru \n\nspdi@mail.ru",
                "col6": "http:\/\/spdi-63.ru\/\n",
                "col7": "8(846)554-11-17"
            },
        ],
    ],
    [
        [
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение\nцентр для детей-сирот и детей, оставшихся без попечения родителей\n«Центр содействия семейному воспитанию № 2»",
                "col4": "199155, Санкт-Петербург, Морская набережная д.41, к. 2, лит. А",
                "col5": "cssv2@ksp.gov.spb.ru ",
                "col6": "https:\/\/цссв2.рф\/ ",
                "col7": "(812) 352-29-93      "
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": " Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшихся без попечения родителей  \"Центр содействия семейному воспитанию № 3\"",
                "col4": "194354, Санкт-Петербург, ул. Сикейроса, д. 19, к. 3, лит.А",
                "col5": "cssv3@ksp.gov.spb.ru",
                "col6": "http:\/\/gu81.ksp.gov.spb.ru",
                "col7": "(812) 594-03-35"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшихся без попечения родителей «Центр содействия семейному воспитанию № 4»",
                "col4": "194156, Санкт-Петербург, Ярославский пр., д.4",
                "col5": "cssv4@ksp.gov.spb.ru",
                "col6": "https:\/\/dom53spb.ru",
                "col7": "(812) 293-43-61"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшися без попечения родителей \"Центр содействия семейному воспитанию № 5\"",
                "col4": "195267, Санкт-Петербург, ул.Ушинского, дом 17, корп.2, лит.А",
                "col5": "cssv5@ksp.gov.spb.ru",
                "col6": "ddomik3.ru",
                "col7": "(812) 531-03-73 "
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшихся без попечения родителей «Центр содействия семейному воспитанию № 6»",
                "col4": "198216, Санкт-Петербург, улица Счастливая, дом 6, литера А",
                "col5": "cssv6@ksp.gov.spb.ru",
                "col6": "www.susocds1.ru",
                "col7": "(812) 417-52-34"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшихся без попечения родителей «Центр содействия семейному воспитанию № 7»",
                "col4": "198262, Санкт- Петербург, ул. Лени Голикова, дом 32, литера А",
                "col5": "cssv7@ksp.gov.spb.ru",
                "col6": "ddom7.ru",
                "col7": "(812) 752-41-05"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт - Петербургское государственное бюджетное учреждение центр для детей - сирот и детей оставшихся без попечения родителей \"Центр содействия семейному воспитанию №8\"",
                "col4": "198095, Санкт - Петербург, ул. Ивана Черных, д. 11а",
                "col5": "cssv8@ksp.gov.spb.ru",
                "col6": "http:\/\/centr8.ru",
                "col7": "(812) 246-45-80"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшихся без попечения родителей \"Центр содействия семейному воспитанию № 9\"",
                "col4": "169653, Санкт-Петербург, город Колпино, улица Адмиралтейская дом 5, литера А",
                "col5": "cssv9@ksp.gov.spb.ru",
                "col6": "cssv9.ru",
                "col7": "(812) 461-00-08"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр \nдля детей-сирот и детей, оставшихся без попечения родителей \n\" Центр содействия семейному воспитанию № 10\"\n",
                "col4": "195112,  Санкт-Петербург, Малоохтинский пр., дом 51, литер А",
                "col5": "cssv10@ksp.gov.spb.ru",
                "col6": "centr10spb.ru",
                "col7": "(812) 528-62-02"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшихся без попечения родителей  «Центр содействия семейному воспитанию № 11»",
                "col4": "196244, Санкт-Петербург, пр. Космонавтов, д. 18, корп. 3, лит. А, ",
                "col5": "cssv11@ksp.gov.spb.ru",
                "col6": "http:\/\/cssv11.ksp.gov.spb.ru",
                "col7": " (812) 417-21-03"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей сирот и детей, оставшихся без попечения родителей «Центр содействия семейному воспитанию № 12»",
                "col4": "197110, Санкт-Петербург, ул. Большая Зеленина, д. 30, лит. Б",
                "col5": "cssv12@ksp.gov.spb.ru",
                "col6": "https:\/\/цссв12.рф",
                "col7": "(812) 235-08-49"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшихся без попечения родителей \"Центр содействия семейному воспитанию № 13\"",
                "col4": "197341, Санкт-Петербург,                                                                          Коломяжский пр, дом 34, корпус 1, литера А",
                "col5": "cssv13@ksp.gov.spb.ru",
                "col6": "http:\/\/www.cssv-13.ru",
                "col7": "(812) 395-27-56"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшихся без попечения родителей \"Центр содействия семейному воспитанию № 14\"",
                "col4": "196607   Санкт-Петербург, г.Пушкин, ул.Ленинградская , д.53а, литер А",
                "col5": "cssv14@ksp.gov.spb.ru",
                "col6": "http:\/\/centr14.ksp.gov.spb.ru",
                "col7": "(812) 573-98-85"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное учреждение центр для детей-сирот и детей, оставшихся без попечения родителей «Центр содействия семейному воспитанию  № 15»",
                "col4": "192286, Санкт-Петербург, Бухарестская ул., дом 63, литера А",
                "col5": "cssv15@ksp.gov.spb.ru",
                "col6": "http:\/\/centr15.ru\/",
                "col7": "(812) 772-46-53      "
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания «Дом-интернат для детей-инвалидов и инвалидов с детства с нарушениями умственного развития №1»",
                "col4": "198517, г Санкт-Петербург, г. Петергоф, ул. Воровского, д. 12 ",
                "col5": " ddi1@ksp.gov.spb.ru ",
                "col6": "ddi-1.ru\/",
                "col7": "(812) 450-70-39"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания «Дом-интернат для детей с отклонениями в умственном \nразвитии № 2»",
                "col4": "198504, г Санкт-Петербург, г. Петергоф, ул. Петергофская, д. 4\/2 ",
                "col5": " ddi2@ksp.gov.spb.ru ",
                "col6": "ddi-2.ru",
                "col7": "(812) 450-50-83"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания «Дом-интернат для детей с отклонениями в умственном \nразвитии № 3»",
                "col4": "197720, г Санкт-Петербург, п. Ушково, ш. Приморское, д. 617, Литер О ",
                "col5": " ddi3@ksp.gov.spb.ru ",
                "col6": "ddi-3.ru",
                "col7": "(812) 433-30-34"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "Санкт-Петербургское государственное бюджетное стационарное учреждение социального обслуживания «Дом-интернат для детей с отклонениями в умственном \nразвитии № 4»",
                "col4": "196620, г Санкт-Петербург, г. Павловск, ул. Елизаветинская, д. 9-15, Литер А ",
                "col5": " ddi4@ksp.gov.spb.ru ",
                "col6": "ddi4.ru",
                "col7": "(812) 452-23-36"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "СПб  ГКУЗ «Специализированный Дом ребенка № 3 (психоневрологический)»",
                "col4": "192289, г.Санкт-Петербург, Загребский д.42 лит.А",
                "col5": "sdr3@mail.ru",
                "col6": "www.spb-kroshka.ru",
                "col7": "778-40-06"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "СПб ГКУЗ \"Психоневрологический дом ребёнка № 6\"",
                "col4": "199155, г. СПб, ул. Одоевского, д.23",
                "col5": "pndr6@mail.ru  ",
                "col6": "http:\/\/domrebenka6.ru\/",
                "col7": "350-16-67"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "СПб ГКУЗ \"Психоневрологический дом ребенка № 8\"",
                "col4": "195176, Санкт-Петербург, ул.Синявинская, д.18, литер А",
                "col5": "domr8@zdrav.spb.ru",
                "col6": "http:\/\/guzpdr8krasnogr.krgv.gov.spb.ru ",
                "col7": "227-25-62"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "СПб ГКУЗ \"Психоневрологический дом ребенка №9\"",
                "col4": "197183, г. Санкт-Петербург, пр. Приморский, дом 47.",
                "col5": "deti09@mail.ru",
                "col6": "http:\/\/pndr9.rprim.gov.spb.ru\/",
                "col7": "8 (812) 430-39-60"
            },
            {
                "col1": "Санкт-Петербург",
                "col2": "Комитет по социальной политике Санкт-Петербурга",
                "col3": "СПб КГУЗ \"Специализированный психоневрологический дом ребенка № 13\"",
                "col4": "190068, Санкт - Петербург, набережная канала Грибоедова, д.98",
                "col5": "babyhome13@mail.ru",
                "col6": "http:\/\/babyhome13.spb.ru",
                "col7": "8 (812) 407-43-08"
            },
        ],
    ],
    [
        [
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "государственное бюджетное учреждение Саратовской области «Центр психолого-педагогического и медико-социального сопровождения детей» г. Балаково",
                "col4": "413865, Саратовская область,\n г. Балаково, ул. Набережная, \nд. 3а",
                "col5": "detdom-4@mail.ru",
                "col6": "https:\/\/centr-ppms.ru\/",
                "col7": "8 (8453) 46-41-04 "
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "государственное бюджетное учреждение Саратовской области «Центр психолого-педагогического и медико-социального сопровождения детей» г. Красноармейска",
                "col4": "412800, \nСаратовская область,\n г. Красноармейск,\nул. Ленина,48\n",
                "col5": "d.dom1@mail.ru",
                "col6": "https:\/\/krasdetdom1.ucoz.ru\/",
                "col7": "8 (84550) 2-12-34"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "государственное бюджетное учреждение Саратовской области  \"Центр психолого-педагогического  и медико-социального сопровождения детей\" Модин Озинского района ",
                "col4": "413609 Саратовская область, Озинский район, п.Модин, ул.Советская, 2а",
                "col5": "detdom_ ozn@mail.ru",
                "col6": "https:\/\/centr-modin.nubex.ru\/",
                "col7": "8 (84576) 4-31-04"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "государственное бюджетное обще-образовательное учреждение Саратовской области «Школа-интернат г.Пугачева»",
                "col4": "413720, \nСаратовская область,\n г. Пугачев, \nул. Топорковская, д.40\n",
                "col5": "secretary@shkola-internat.ru ",
                "col6": "https:\/\/shkola-internat.ru\/",
                "col7": "8 845 (74) 4-16-12"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "государственное бюджетное обще-образовательное учреждение Саратовской области «Школа-интернат г.Хвалынска",
                "col4": "412780, \nСаратовская область,\n г.  Хвалынск \nул. Советская, 130\n",
                "col5": "internat_hvl@inbox.ru",
                "col6": "https:\/\/shkola-internat.srt.eduru.ru\/",
                "col7": "(84595) 2-14-32"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "государственное бюджетное образовательное учреждение Саратовской области «Средняя общеобразовательная школа п.Алексеевка Хвалынского района имени В.М. Пашина»",
                "col4": "412781, \nСаратовская обл.,\nХвалынский район,\n п. Алексеевка, \nул. Чапаева, 111\n",
                "col5": " paschinzi@yandex.ru  ",
                "col6": "https:\/\/paschinzi.gosuslugi.ru ",
                "col7": "8 (845-95) 2-18-80"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "государственное бюджетное образовательное учреждение Саратовской области «Средняя общеобразовательная школа с.Белогорное Вольского района»",
                "col4": "412971, \nСаратовская область,\nВольский район, с.Белогорное, \nПлощадь 65-летия Октября, д. 7\n",
                "col5": "ddbelogornoe@yandex.ru",
                "col6": "https:\/\/sh-volskaya-belogornoe-r64.gosweb.gosuslugi.ru\/",
                "col7": "8 (84593) 6-25-68"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "государственное бюджетное общеобразовательное учреждение Саратовской области «Школа-интернат для обучающихся по адаптированным образовательным программам п.Алексеевка Хвалынского района»",
                "col4": "412761, Саратовская область, Хвалынский район, п.Алексеевка, ул. Грошева,  д. 20.",
                "col5": "alex-internat@yandex.ru",
                "col6": "https:\/\/sh-int-alekseevka-r64.gosweb.gosuslugi.ru\/",
                "col7": "8 (845-95) 2-31-33"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "государственное бюджетное общеобразовательное учреждение Саратовской области «Школа-интернат для обучающихся по адаптированным образовательным программам с.Широкий Буерак Вольского района»",
                "col4": "412935, Саратовская область, Вольский р-он, с.Широкий Буерак, ул.Коммуни-стическая, д.1",
                "col5": "skola-internatSB@yandex.ru",
                "col6": "https:\/\/skola-internatsb.gosuslugi.ru\/",
                "col7": "8 (84593) 6-22-71"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "Государственное бюджетное учреждение Саратовской области \"Базарно-Карабулакский специальный реабилитационный дом-интернат для детей\"",
                "col4": "412601, Саратовская область, р.п. Базарный Карабулак, ул. Карла Маркса, д. 1",
                "col5": "bkinternat@bk.ru",
                "col6": "https:\/\/bkinternat.my1.ru\/",
                "col7": "8 (845-91) 7-20-78 "
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "Государственное бюджетное учреждение Саратовской области \"Дергачевский специальный реабилитационный дом- интернат для детей\"",
                "col4": "413440 Саратовская область,Дергачевский р-н, р.п.Дергачи, ул.Льва Толстого,68",
                "col5": "dergachiddi@yandex.ru",
                "col6": "https:\/\/dergachiddi.nubex.ru\/",
                "col7": "8 (84563) 2-13-60"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "Государственное бюджетное учреждение Саратовской области \"Орловский специальный реабилитационный дом-интернат для детей\"",
                "col4": "413070, Саратовская обл, Марксовский р-н, с. Орловское, ул. Октябрьская, д5",
                "col5": "gsuorlovskiidom@yandex.ru",
                "col6": "https:\/\/gsuorlovskiidom.ru\/4816\/",
                "col7": "8 (84567)5-13-96"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "Государственное учреждение здравоохранения «Специализированный дом ребенка для детей с органическим поражением центральной нервной системы, в том числе детскими церебральными параличами, без нарушения психики» г.Саратова",
                "col4": "410054, г.Саратов, ул.2-я Садовая, зд. 103Б, стр.1",
                "col5": "dom-reb@yandex.ru",
                "col6": "https:\/\/domrebenka.ru\/",
                "col7": "8 (8452) 56-12-45"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "Государственное учреждение здравоохранения «Марксовский Дом ребенка для детей с заболеванием центральной нервной системы и нарушением психики» ",
                "col4": "Российская Федерация, Саратовская область, город Маркс, проспект Строителей, здание 1.",
                "col5": "domrebonka@mail.ru",
                "col6": "https:\/\/mrksdomreb.medportal.saratov.gov.ru\/sitemap\/",
                "col7": "8 (84567) 5-42-88"
            },
            {
                "col1": "Саратовская область",
                "col2": "Министерство образования Саратовской области",
                "col3": "Государственное учреждение здравоохранения \"Вольский специализированный дом ребенка для детей с органическими поражениями центральной нервной системы без нарушения психики\"",
                "col4": "412911, Саратовская область, \nг. Вольск, \nул. Чернышев-ского, д.199а",
                "col5": "kids-dr@rambler.ru",
                "col6": "https:\/\/vlskdomreb.medportal.saratov.gov.ru\/about\/",
                "col7": "8 (84593) 6-40-07"
            },
        ],
    ],
    [
        [
            {
                "col1": "Сахалинская область",
                "col2": "Министерство образования Сахалинской области",
                "col3": "ГКУ ДО СО \"Центр содействия семейному устройству \"Звездный»",
                "col4": "694046, Сахалинская область, Анивский район, с. Троицкое, ул. Центральная, д. 28-Б",
                "col5": "zvezdniy@sakhalin.gov.ru",
                "col6": "https:\/\/detdom.sakhalin.gov.ru ",
                "col7": "8 (4242) 31-20-35"
            },
            {
                "col1": "Сахалинская область",
                "col2": "Министерство образования Сахалинской области",
                "col3": "ГКУ ДО СО «Центр содействия семейному устройству «Феникс»",
                "col4": "694810, Сахалинская область, Томаринский район, с. Красногорск, ул. Ушакова, д. 17-А",
                "col5": "cssufeniks@sakhalin.gov.ru",
                "col6": "https:\/\/feniks.sakhalin.gov.ru",
                "col7": "8 (42446) 3-14-29"
            },
            {
                "col1": "Сахалинская область",
                "col2": "Министерство образования Сахалинской области",
                "col3": "ГКОУ «Школа-интернат «Радуга",
                "col4": "694350, Сахалинская область, пгт. Смирных, ул. Западная, д. 16-А",
                "col5": "mo.gkousshir@sakhalin.gov.ru",
                "col6": "https:\/\/raduga.sakhalin.gov.ru\/",
                "col7": "8 (42452) 4-25-06"
            },
        ],
    ],
    [
        [
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних № 2 города Асбеста»",
                "col4": "624266, Свердловская область, город Асбест, ул. Советская, д.12",
                "col5": "msp-dd-asb@egov66.ru",
                "col6": "zabota114.msp.midural.ru",
                "col7": "8 (34365) 7-48-07"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних города Асбеста»",
                "col4": "624260, Свердловская область, город Асбест, пр-кт Ленина, д. 20\/3",
                "col5": "msp-sd-asb@egov66.ru ",
                "col6": "147.msp.midural.ru ",
                "col7": "8 (34365) 2-71-89 "
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения города Березовского» ",
                "col4": "623700, Свердловская область, город Берёзовский, улица Красных Героев, дом 9.",
                "col5": "soc034@egov66.ru",
                "col6": "https:\/\/zabota034.msp.midural.ru",
                "col7": "8 (34369) 3-73-01"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних № 2 города Каменска-Уральского» ",
                "col4": "623414, Свердловская область,  г. Каменск-Уральский, ул. Лермонтова, д. 2 Б",
                "col5": "soc140@egov66.ru",
                "col6": "zabota140.msp.midural.ru",
                "col7": 83439351950
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних «Красногорский» города Каменска-Уральского» ",
                "col4": "623412, Свердловская область, г. Каменск-Уральский, ул. Строителей, д. 83",
                "col5": "soc160@egov66.ru",
                "col6": "https:\/\/zabota160.msp.midural.ru",
                "col7": "8 (3439) 30-77-33"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Каменского района»",
                "col4": "623462, Свердловская область, Каменский район, поселок Мартюш, улица Молодежная, дом 8.",
                "col5": "soc158@egov66.ru",
                "col6": "https:\/\/zabota158.msp.midural.ru",
                "col7": "8 (3439) 350-988"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних «Синарский» города Каменска-Уральского» ",
                "col4": "623401, Свердловская область, г. Каменск-Уральский, ул. Зои Космодемьянской, д. 14А",
                "col5": "soc165@egov66.ru",
                "col6": "zabota165.msp.midural.ru",
                "col7": 83439530070
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Сухоложского района» ",
                "col4": "624800, Свердловская область, г. Сухой Лог, ул.93 Стрелковой бригады, д.7",
                "col5": "soc166@egov66.ru",
                "col6": "https:\/\/zabota166.msp.midural.ru\/ ",
                "col7": "8 (34373) 6-52-82"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Сысертского района» ",
                "col4": "624027 Свердловская область, Сысертский район,  с. Новоипатово,  ул. Мира, д.26 а",
                "col5": "soc167@egov66.ru",
                "col6": "https:\/\/zabota167.msp.midural.ru\/",
                "col7": "8 (3437) 46-46-33"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Артинского района» ",
                "col4": "623342, Свердловская область, пгт. Арти, ул. Бажова, зд. 89",
                "col5": "soc110@egov66.ru",
                "col6": "https:\/\/zabota110.msp.midural.ru\/",
                "col7": "8 (34391) 2-23-31"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Ачитского района» ",
                "col4": "623230 Свердловская область, пгт.Ачит, ул.Строителей, д.5 ",
                "col5": "soc111@egov66.ru",
                "col6": "https:\/\/zabota111.msp.midural.ru\/",
                "col7": "8 (34391) 70024"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Ревдинского района» ",
                "col4": "623280, Свердловская область, г. Ревда, ул. Толстого, д. 2 А",
                "col5": "soc132@egov66.ru ",
                "col6": "https:\/\/zabota132.msp.midural.ru",
                "col7": "(34397) 5-28-85"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Шалинского района» ",
                "col4": "623032,Свердловская область, Шалинский район, пос. Илим, ул. 8 Марта, д.13",
                "col5": "soc123@egov66.ru",
                "col6": "https:\/\/zabota123.msp.midural.ru",
                "col7": "(34358) 32-41-6"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Нижнесергинского района» ",
                "col4": "623090 Свердловская область, Нижнесергинский район,  г. Нижние Серги, ул. Ленина, д.14",
                "col5": "soc162@egov66.ru",
                "col6": "https:\/\/zabota162.msp.midural.ru\/",
                "col7": "8 (34398) 27170"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Пышминского района» ",
                "col4": "623550, Свердловская область, Пышминский район, р.п. Пышма, ул. Строителей, 9а",
                "col5": "soc119@egov66.ru",
                "col6": "https:\/\/zabota119.msp.midural.ru",
                "col7": "8 (34372) 2-45-96 - директор                                        8 (34372) 2-35-45 -стационар"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Режевского района» ",
                "col4": "623751, Свердловская область, Режевской район, г. Реж, ул. О.Кошевого д.11",
                "col5": "soc082@egov66.ru",
                "col6": "http:\/\/zabota082.msp.midural.ru\/",
                "col7": "34364) 33939, (34364) 33675"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних «Золушка» Тавдинского района» ",
                "col4": "623950, Свердловская область город Тавда ул.Рабочая, д.3 ",
                "col5": "soc083@egov66.ru ",
                "col6": "https:\/\/zabota083.msp.midural.ru\/ https:\/\/vk.com\/zolushkatavda",
                "col7": "7 (34360) 5-23-45"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Талицкого района» ",
                "col4": "623640, Свердловская область,г. Талица, ул. Луначарского, д. 72; 623640, Свердловская область, Талицкий район, п.Пионерский, ул. Ленина, д. 5",
                "col5": "soc121@egov66.ru",
                "col6": "https:\/\/zabota121.msp.midural.ru\/",
                "col7": "8 (343) 71 2-22-05"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Тугулымского района» ",
                "col4": "623650, Свердловская область, р.п.Тугулым, ул. Гагарина, д.13",
                "col5": "soc122@egov66.ru",
                "col6": "https:\/\/zabota122.msp.midural.ru\/",
                "col7": 83436722461
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних города Алапаевска»",
                "col4": "624602, Свердловская обасть, город Алапаевск, ул.Клубная , д. 22",
                "col5": "soc149@egov66.ru",
                "col6": "https:\/\/zabota149.msp.midural.ru",
                "col7": "8 (34346) 2-75-95"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Камышловского района» ",
                "col4": "624860, Свердловская область, Камышлов,. ул. Жукова, 57а",
                "col5": "soc159@egov66.ru",
                "col6": "https:\/\/zabota159.msp.midural.ru",
                "col7": "8 (34375) 2-36-86"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Туринского района»",
                "col4": "623903 Свердловская область, г.Туринск, ул.Чкалова, д. 58",
                "col5": "soc168@egov66.ru",
                "col6": "https:\/\/zabota168.msp.midural.ru\/\n",
                "col7": "8 (34349) 2-45-67"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних города Нижняя Салда» ",
                "col4": "624740 Свердловская область, г. Нижняя Салда,ул. Советская 56",
                "col5": "soc115@egov66.ru",
                "col6": "https:\/\/zabota115.msp.midural.ru\/",
                "col7": "8 (34345) 3-03-64"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Верхнесалдинского района» ",
                "col4": "624760,Свердловская область, город Верхняя Салда ул. Строителей, д. 11 ",
                "col5": "soc112@egov66.ru ",
                "col6": "https:\/\/zabota112.msp.midural.ru\/deiatelnost-u112\/12.html                      https:\/\/vk.com\/srcnvs",
                "col7": "8 (34345) 5-58-70"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних № 2 города Нижняя Салда» ",
                "col4": "624740 Свердловская область, г. Нижняя Салда,ул.Строителей, д. 50",
                "col5": "soc141@egov66.ru",
                "col6": "https:\/\/zabota141.msp.midural.ru",
                "col7": "8 (34345) 4-72-57"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Невьянского района» ",
                "col4": "624194 , Свердловская область, г. Невьянск, ул. Демьяна Бедного, д. 21",
                "col5": "msp-dd-nev@egov66.ru",
                "col6": "zabota129.msp.midural.ru",
                "col7": "8 (34356) 4-56-08, 4-56-09"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Октябрьского района города Екатеринбурга» ",
                "col4": "620912, Свердловская область, город Екатеринбург, улица Реактивная, 19",
                "col5": "maly-istok@mail.ru soc163@egov66.ru",
                "col6": "http:\/\/zabota163.msp.midural.ru",
                "col7": "8 (343) 252-04-78"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Орджоникидзевского района города Екатеринбурга»",
                "col4": "620057, г. Екатеринбург, ул. Ползунова, д. 51",
                "col5": "soc164@egov66.ru",
                "col6": "http:\/\/zabota164.msp.midural.ru",
                "col7": "8 (343) 306-55-54"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное стационарное учреждение социального обслуживания Свердловской области «Екатеринбургский реабилитационный центр для детей-инвалидов»",
                "col4": "620085 Свердловская область, г.Екатеринбург, ул. Ляпустина, д. 4",
                "col5": "msp-dd-eddi@egov66.ru",
                "col6": "https:\/\/zabota128.msp.midural.ru\/",
                "col7": "8 (343) 297-25-06\n8 (343) 297-25-07\n8 (343) 297-25-08\n8 (343) 297-25-09\n8 (343) 297-26-26"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное стационарное учреждение социального обслуживания Свердловской области «Карпинский детский дом-интернат»",
                "col4": "624933 Свердловская обл., г.Карпинск, ул. Чайковского, 44",
                "col5": "soc135@egov66.ru  ",
                "col6": "https:\/\/zabota135.msp.midural.ru\/",
                "col7": "8 (34383)3-61-73"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное стационарное учреждение социального обслуживания Свердловской области «Нижнетуринский детский дом-интернат»",
                "col4": "624214 Свердловская область, г. Лесной, п. Ёлкино, ул. Мельничначя, д.1. ",
                "col5": "soc134@egov66.ru",
                "col6": " http:\/\/zabota134.msp.midural.ru",
                "col7": "8 (34342) 2-64-60"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное бюджетное учреждение социального обслуживания Свердловской области «Филатовский социально-реабилитационный центр для несовершеннолетних»",
                "col4": "624819, Свердловская область, Сухоложский район, с. Филатовское, ул. Ленина, д.87",
                "col5": "filat_sirota@mail.ru",
                "col6": " https:\/\/zabota170.msp.midural.ru",
                "col7": "8 (34373) 97297"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное бюджетное учреждение социального обслуживания Свердловской области «Нижнетагильский социально-реабилитационный центр для несовершеннолетних»",
                "col4": "622013, Свердловская область, \nг. Нижний Тагил, ул. Красногвардейская, д. 55\n",
                "col5": "ntddsh@mail.ru",
                "col6": "https:\/\/zabota171.msp.midural.ru\/",
                "col7": "8 (3435) 25-57-40"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения города Волчанска»",
                "col4": "624940, Свердловская область, г. Волчанск, ул. Карпинского, д. 19А",
                "col5": "#ERROR!",
                "col6": "https:\/\/zabota036.msp.midural.ru\/ ",
                "col7": "8(34383)5-32-24"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания населения Свердловской области «Комплексный центр социального обслуживания населения «Надежда» города Красноуральска»",
                "col4": "624330, Свердловская область, г. Красноуральск, ул. Горняков, д. 34",
                "col5": "#ERROR!",
                "col6": "https:\/\/zabota058.msp.midural.ru\/",
                "col7": "8(34343) 2-27-24,                2-24-65"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения города Краснотурьинска»",
                "col4": "624448, Свердловская область, г. Краснотурьинск, ул. Карла Маркса, д. 35;  г.Краснотурьинск, ул. Карпинского, 19А ",
                "col5": "#ERROR!",
                "col6": "https:\/\/zabota041.msp.midural.ru\/",
                "col7": "8(34384)6-89-01 8(34384)3-48-31"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения Сысертского района»",
                "col4": "624013, Свердловская область, Сысертский район, п. Двуреченск, ул. Озерная, д. 2а",
                "col5": "soc106@egov66.ru ",
                "col6": "https:\/\/zabota106.msp.midural.ru\/",
                "col7": "8(34374)27-9-18"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения поселка Рефтинский»",
                "col4": "624285, Свердловская область, пгт. Рефтинский, ул. Гагарина, зд. 26",
                "col5": "msp-so-vtr@egov66.ru",
                "col6": "zabota064.msp.midural.ru",
                "col7": "8 (34365) 3-50-51 (доб. 201)"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения Слободо-Туринского района»",
                "col4": "623930, Слободо-Туринский район, с. Туринская-Слобода, ул. Ленина д. 87",
                "col5": "soc105@egov66.ru",
                "col6": "zabota105.msp.midural.ru",
                "col7": "8(34361)2-18-99"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания населения Свердловской области «Комплексный центр социального обслуживания населения Туринского района»",
                "col4": "623900, Свердловская область, Туринский район, г. Туринск, ул. Советская, д. 10",
                "col5": "msp-tur-so@egov66.ru",
                "col6": "http:\/\/zabota087.msp.midural.ru",
                "col7": 83434921562
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Новоуральский комплексный центр социального обслуживания населения»",
                "col4": "624134, Свердловская область, г. Новоуральск, ул. Гагарина, д. 7А",
                "col5": "soc071@egov66.ru",
                "col6": "https:\/\/zabota071.msp.midural.ru",
                "col7": "8 (34370) 4-84-18                8 (34370) 4-84-19"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения «Золотая осень» города Нижний Тагил»",
                "col4": "622051, Свердловская область, г. Нижний Тагил, ул. Правды, д. 9«А»",
                "col5": "msp-so-zos@egov66.ru",
                "col6": "https:\/\/zabota065.msp.midural.ru\/",
                "col7": "(3435) 33-59-37                 (3435) 33-58-49                 (3435) 31-96-64       (3435) 31-73-55"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения Ленинского района города Нижний Тагил»",
                "col4": "622034, Свердловская область, г. Нижний Тагил, ул. Пархоменко, д. 16",
                "col5": "soc057@egov66.ru",
                "col6": "https:\/\/zabota056.msp.midural.ru\/",
                "col7": "8(3435)41-15-51"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения Пригородного района»",
                "col4": "622034, Свердловская область, г. Нижний Тагил, пр. Строителей, д. 11",
                "col5": "soc062@egov66.ru",
                "col6": "https:\/\/zabota062.msp.midural.ru\/",
                "col7": "8 (3435) 47-86-88"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения Тагилстроевского района города Нижний Тагил»",
                "col4": "622005, Свердловская область, г. Нижний Тагил, ул. Землячки, д. 3",
                "col5": "soc066@egov66.ru",
                "col6": "https:\/\/zabota066.msp.midural.ru\/",
                "col7": "8 (3435) 33-32-40"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр помощи семье и детям «Отрада» Октябрьского района города Екатеринбурга»",
                "col4": "620138, Свердловская область, г. Екатеринбург, ул. Байкальская, д. 37А",
                "col5": "msp-sd-otrada@egov66.ru",
                "col6": "http:\/\/zabota017.msp.midural.ru",
                "col7": "(343)262-08-68"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр социальной помощи семье и детям «Гнездышко» Кировского района города Екатеринбурга»",
                "col4": "620033, Свердловская область, г.Екатеринбург, ул. Искровцев, д. 23А",
                "col5": "ekbgnezdo@mail.ru",
                "col6": "https:\/\/zabota125.mps.midural.ru\/",
                "col7": "8 343 360-20-21"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр социальной помощи семье и детям «Каравелла» Верх-Исетского района города Екатеринбурга»",
                "col4": "620131, Свердловская область, г. Екатеринбург, ул. Металлургов, д. 42а",
                "col5": "msp-sd-karavella@egov66.ru",
                "col6": "https:\/\/zabota077.msp.midural.ru\/",
                "col7": "8 (343) 242-63-05"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения Чкаловского района города Екатеринбурга»",
                "col4": "620130, Свердловская область, г. Екатеринбург, пер. Трактористов, д. 19; г.Екатеринбург, пер. Хибиногорский , д. 29",
                "col5": "msp-so-chk@egov66.ru",
                "col6": "http:\/\/zabota052.msp.midural.ru\/",
                "col7": "8(343)287-56-31;  8(343)258-20-32   "
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр социальной помощи семье и детям города Качканара»",
                "col4": "624351, Свердловская область, г. Качканар, ул. Мира, д. 44",
                "col5": "soc127@egov66.ru",
                "col6": "https:\/\/zabota127.msp.midural.ru ",
                "col7": "8(34341) 6-83-83"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр социальной помощи семье и детям города Серова»",
                "col4": "624992, Свердловская область, г. Серов, ул. Л. Толстого, д. 25",
                "col5": "soc089@egov66.ru",
                "col6": "https:\/\/zabota089.msp.midural.ru",
                "col7": "834385988-45"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр социальной помощи семье и детям города Богдановича»",
                "col4": "623530, Свердловская область, Богданочский район, г. Богданович, ул. Новая, д. 16, к.А",
                "col5": "soc086@egov66.ru",
                "col6": "https:\/\/zabota086.msp.midural.ru\/",
                "col7": "8(34376)5-13-76"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр социальной помощи семье и детям «Росинка» города Первоуральска»",
                "col4": "623111, Свердловская область, г. Первоуральск, ул. Советская, д. 5.",
                "col5": "soc088@egov66.ru",
                "col6": "https:\/\/zabota088.msp.midural.ru",
                "col7": "(3439) 64-11-78 "
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения \"Спутник\" города Верхняя Пышма»",
                "col4": "624090, Свердловская область, г. Верхняя Пышма, пр. Успенский, д. 42а",
                "col5": "soc063@egov66.ru",
                "col6": "https:\/\/zabota063.msp.midural.ru",
                "col7": "8(34368)5-37-94"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр социальной помощи семье и детям города Красноуфимска и Красноуфимского района»",
                "col4": "623300, Свердловская область, Красноуфимский район, п. Березовая Роща, ул. Нарядная, д. 2",
                "col5": "msp-sd-krf@egov66.ru",
                "col6": "https:\/\/zabota031.msp.midural.ru\/",
                "col7": "8(34394)6-00-96"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр социальной помощи семье и детям Нижнесергинского района»",
                "col4": "623050, Свердловская область, Нижнесергинский район, пгт. Бисерть,  ул. Чкалова, 35Б",
                "col5": "soc130@egov66.ru",
                "col6": "http:\/\/zabota130.msp.midural.ru",
                "col7": "8(343-98) 6-41-71 "
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Комплексный центр социального обслуживания населения города Полевского»",
                "col4": "623391, Свердловская область, г. Полевской, ул. Розы Люксембург, д. 85, ул.Красноармейская, д. 87А",
                "col5": "soc045@egov66.ru",
                "col6": "https:\/\/zabota045.msp.midural.ru\/",
                "col7": "8(34350) 2-39-55"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания населения Свердловской области «Центр социальной помощи семье и детям Артемовского района»",
                "col4": "623794, Свердловская область, Артемовский район, пос. Буланаш, ул. Кутузова д. 23, К.А",
                "col5": "soc084@egov66.ru, bulanach@mail.ru",
                "col6": "http:\/\/zabota084.msp.midural.ru",
                "col7": "34363)55297"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Центр социальной помощи семье и детям города Алапаевска»",
                "col4": "624604, Свердловская область, г. Алапаевск, ул. Бочкарева, д. 130",
                "col5": "soc126@egov66.ru",
                "col6": "https:\/\/zabota126.msp.midural.ru",
                "col7": "8(34346) 3-33-13"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания  Свердловской области «Ирбитский центр социальной помощи семье и детям»",
                "col4": "623850, Свердловская область, г. Ирбит, ул. Карла Маркса, д. 62",
                "col5": "soc026@egov66.ru",
                "col6": "https:\/\/zabota026.msp.midural.ru\/",
                "col7": "8 (343 55) 6 54 03"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних города Лесного»",
                "col4": "624203, Свердловская область, г. Лесной, ул. Орджоникидзе, д. 16А",
                "col5": "msp-chk-sd@egov66.ru",
                "col6": "https:\/\/zabota113.msp.midural.ru\/",
                "col7": "8(34342) 9-95-72"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Новолялинского района»",
                "col4": "624400, Свердловская область, Новолялинский район, г. Новая Ляля, ул. Розы Люксембург, д. 74Б",
                "col5": "soc118@egov66.ru",
                "col6": "http:\/\/zabota118.msp.midural.ru",
                "col7": 83438821785
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних города Североуральска»",
                "col4": "624480, Свердловская область, Г.О. Североуральский, г. Североуральск, ул. Чкалова, д. 2",
                "col5": "soc116@egov66.ru",
                "col6": "http:\/\/zabota116.msp.midural.ru",
                "col7": "8(34380)21362"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних Верхотурского района»",
                "col4": "624393, Свердловская область, Верхотурский район, д. Заимка",
                "col5": "soc148@egov66.ru",
                "col6": "https:\/\/zabota148.msp.midural.ru\/",
                "col7": "8(34389) 2-32-04"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних имени Ю. Гагарина города Карпинска»",
                "col4": "624931, Свердловская область, г. Карпинск, ул. Чайковского, д. 34",
                "col5": "soc082@egov66.ru",
                "col6": "https:\/\/zabota157.msp.midural.ru\/",
                "col7": "8(34383)3-61-53"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение социального обслуживания Свердловской области «Социально-реабилитационный центр для несовершеннолетних города Нижняя Тура»",
                "col4": "624220, Свердловская область, г. Нижняя Тура, ул. Советская, д. 9",
                "col5": "soc153@egov66.ru",
                "col6": "https:\/\/zabota153.msp.midural.ru\/",
                "col7": "8(34342) 2-59-54"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное автономное учреждение социального обслуживания  Свердловской области «Социально-реабилитационный центр для несовершеннолетних города Каменска-Уральского»",
                "col4": "623401, Свердловская область, г. Каменск-Уральский, ул. Олега Кошевого, д. 19",
                "col5": "soc080@egov66.ru",
                "col6": "https:\/\/zabota080.msp.midural.ru\/",
                "col7": "8(3439)39-63-80"
            },
            {
                "col1": "Свердловская область ",
                "col2": "Министерство социальной политки Свердловской области",
                "col3": "государственное казенное учреждение Свердловской области \"Дом ребенка\"",
                "col4": "620143, Свердловская область, г. Екатеринбург, ул. Уральских рабочих, стр. 36",
                "col5": "specdor-public@mis66.ru",
                "col6": "https:\/\/zabota172.msp.midural.ru",
                "col7": "8 (343) 307-18-94"
            },
        ],
    ],
    [
        [
            {
                "col1": "Севастополь",
                "col2": "Департамент образования и науки города Севастополя",
                "col3": "Государственное казенное учреждение города Севастополя Центр помощи детям оставшимся без попечения родителей \"Наш дом\"",
                "col4": "299011, г. Севастополь, ул. Кулакова д. 1",
                "col5": "perviy_dom@mail.ru      или nash.dom@sev.gov.ru",
                "col6": "https:\/\/perviy-dom.ru\/",
                "col7": 78692555142
            },
        ],
    ],
    [
        [
            {
                "col1": "Смоленская область",
                "col2": "Департамент Смоленской области по образованию и науке",
                "col3": "смоленское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей,  СОГБУ \"Шаталовский детский дом\"",
                "col4": "216491,Смоленская область, Починковский район,  деревня Шаталово, дом 10",
                "col5": "BBBB771@ yandex.ru",
                "col6": "https:\/\/shatdom.gov67.ru",
                "col7": "8 (48149)3-25-29"
            },
            {
                "col1": "Смоленская область",
                "col2": "Департамент Смоленской области по образованию и науке",
                "col3": "смоленское областное государственное бюджетное  учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Сафоновский  детский дом\"",
                "col4": " 215563, Смоленская область, Сафоновский район, деревня Клинка, улица Школьная,    дом 1а",
                "col5": "safschi2012@ yandex.ru",
                "col6": "https:\/\/safschi.gosuslugi.ru",
                "col7": "8 (48142) 7-19-45"
            },
            {
                "col1": "Смоленская область",
                "col2": "Департамент Смоленской области по образованию и науке",
                "col3": "смоленское областное государственное бюджетное общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей,                          \"Ярцевская общеобразовательная школа-интернат\"",
                "col4": "215800, Смоленская область, город Ярцево,                                  улица Макаренко, дом 3",
                "col5": "jashi81@mail.ru",
                "col6": "https:\/\/sh-int-yarcevskaya-r66.gosweb.gosuslugi.ru",
                "col7": "8 (48143) 3-68-46"
            },
            {
                "col1": "Смоленская область",
                "col2": "Департамент Смоленской области по образованию и науке",
                "col3": "смоленское областное государственное бюджетное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Детский дом \"Гнездышко\"",
                "col4": "214025, город Смоленск,   улица Нарвская, дом 11-а",
                "col5": "abrgnezdo-@mail.ru",
                "col6": "http:\/\/gnezdishko-smol.ru",
                "col7": "8 (4812) 64-20-96 "
            },
            {
                "col1": "Смоленская область",
                "col2": "Департамент Смоленской области по образованию и науке",
                "col3": "смоленское областное государственное бюджетное общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей,                     \"Демидовская школа-интернат\"",
                "col4": "216240, Смоленская область,\nгород Демидов, улица Гаевская, дом 68\n\n ",
                "col5": "demidov.internat@ list.ru",
                "col6": "https:\/\/sh-int-demidovskaya-r66.gosweb.gosuslugi.ru",
                "col7": "(48147) 4-10-76"
            },
            {
                "col1": "Смоленская область",
                "col2": "Департамент Смоленской области по образованию и науке",
                "col3": "смоленское областное государственное бюджетное дошкольное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей,\"Дом ребенка \"Солнышко\"",
                "col4": "215800, Смоленская область, город Ярцево,                                   улица Космонавтов, дом 62",
                "col5": "yardomreb@ yandex.ru",
                "col6": "https:\/\/domrebenka-yar.ru",
                "col7": "(48143) 7-45-90"
            },
            {
                "col1": "Смоленская область",
                "col2": "Негосударственная (частная) организация для детей-сирот, в том числе религиозная",
                "col3": "частное дошкольное образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей, \"Православный детский дом\"",
                "col4": "214000, город Смоленск,   улица Соборный двор, дом 13",
                "col5": "praw.dom2010@ yandex.ru",
                "col6": "https:\/\/smoleparh.ru\/eparhiya\/uchebnyie-zavedeniya\/pravoslavnyiy-detskiy-dom-g-smolensk",
                "col7": "8 (4812) 27-33-55"
            },
        ],
    ],
    [
        [
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом № 1 «Колосок »",
                "col4": "357334 Ставропольский край, Минераловодский  округ,  с. Нижняя Александровка, ул. Клубная, 2а",
                "col5": "detdom1@mosk.stavregion.ru",
                "col6": "https:\/\/kolosok1.ru",
                "col7": "8 87922 2-25-39"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом (смешанный) № 2 «Золотой ключик»",
                "col4": "357308 Ставропольский край, Минераловодский округ, с. Розовка, пер. Мира, 6",
                "col5": "dklyuchik@yandex.ru",
                "col6": "https:\/\/dklyuchik.ru\/",
                "col7": "8 87922 2-24-36"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом № 4 «Солнышко»",
                "col4": "356126 Изобильненский  округ, пос. Солнечнодольск, б-р. Школьный, 11",
                "col5": "detskydom4@mail.ru",
                "col6": "http:\/\/detdom4.ru\/",
                "col7": "8 86545 3-54-51"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом № 6»",
                "col4": "356722 Ставропольский край, Апанасенковский округ , с. Дивное, ул. Советская, 199",
                "col5": "detdom6@mosk.stavregion.ru",
                "col6": "www.detidom6-divnoe.ru",
                "col7": "8 86555 4-54-96"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом №8»",
                "col4": "356040 Ставропольский край, Красногвардейский округ,   с. Преградное, ул. Красная, 241                              ",
                "col5": "det-dom8@yandex.ru",
                "col6": "pregradnoe8.ru",
                "col7": "8 86541 5-11-92"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное специальное    (коррекционное) образовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья «Дошкольный детский дом № 9»",
                "col4": "355008 Ставропольский край, г.Ставрополь,  ул. Дзержинского, 4а ",
                "col5": "skdd9@mail.ru",
                "col6": "www.skdd9.ru\/",
                "col7": "8 8652 28-48-68"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом (смешанный) №10 им. Н.К. Крупской»",
                "col4": "357432 Ставропольский край, г. Железноводск,  пос. Иноземцево,  ул. Достоевского, 4",
                "col5": "krupskaya10@yandex.ru",
                "col6": "http:\/\/dd-kmv.ru",
                "col7": "8 87932 5-95-03"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Санаторный детский дом для детей-сирот и детей, оставшихся без попечения родителей, № 12»",
                "col4": "356007 Ставропольский край,  г. Ставрополь, ул. Трунова, 71",
                "col5": "detdom12@mosk.stavregion.ru",
                "col6": "https:\/\/12sdd.ru\/",
                "col7": "8 8652 36-28-00"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом (смешанный) №13»",
                "col4": "356220  Ставропольский край, Шпаковский округ,  с. Надежда, ул. Рабочая, 77",
                "col5": "detdomnadezhda@mail.ru",
                "col6": "http:\/\/detdom13.ru",
                "col7": "8 86553 4-15-62"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом № 15 «Надежда»",
                "col4": "357083 Ставропольский край Андроповский  округ, п. Новый Янкуль, ул. Комсомольская 6",
                "col5": "ddom15nadejda@mail.ru",
                "col6": "https:\/\/ddom15.edusite.ru\/",
                "col7": "8 86556 5-33-02"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом (смешанный) №19»",
                "col4": "357110  Ставропольский край, Кировский округ,   г. Новопавловск,  ул. Восточная, 26",
                "col5": "detdomnovo@mail.ru",
                "col6": "http:\/\/www.detdom19.edusite.ru\/",
                "col7": "8 87938 5-13-58"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом № 20 «Надежда»",
                "col4": "357015 Ставропольский край, Кочубеевский  округ,   ст. Балахоновская, ул. Октябрьская, 39",
                "col5": "detdom20@bk.ru",
                "col6": "www.detdom20-b.ru",
                "col7": "8 86550 4-36-95"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом (смешанный) №22»",
                "col4": "357960 Ставропольский край, Левокумский муниципальный округ, пос. Малосадовый, ул. Мира, 20",
                "col5": "detsk-do@yandex.ru",
                "col6": "детдом22.рф",
                "col7": "8 86543 5-62-97"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом №24 «Аврора »",
                "col4": "356020  Ставропольский край, Новоалександровский округ, ст. Григорополисская,  ул. Мартыненко, 11б",
                "col5": "detdom24-1526@bk.ru",
                "col6": "http:\/\/detdom24-1526.ucoz.ru\/",
                "col7": "8 86544 5-13-06"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом (смешанный) № 25»",
                "col4": "356500  Ставропольский край, Петровский округ,  г. Светлоград, ул. Калинина, 305а",
                "col5": "detdom25@mosk.stavregion.ru",
                "col6": "http:\/\/petrdetdom25.ru\/",
                "col7": "8 86547 4-74-70"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом (смешанный) № 30»",
                "col4": "357800  Ставропольский край, г. Георгиевск,                             ул. Воровского, 2",
                "col5": "detdomgeorg@mail.ru",
                "col6": "www.detdom30.ru",
                "col7": "8 87951 2-47-60"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей детей, «Детский дом № 31»",
                "col4": "357736 Ставропольский край, г. Кисловодск,  ул. Островского, 72",
                "col5": "dd31buh@mail.ru",
                "col6": "https:\/\/Detidom31.ru",
                "col7": "8 87937 7-74-96"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей, «Детский дом (смешанный) № 32»",
                "col4": "357501 Ставропольский край, г. Пятигорск, ул. Лермонтова, 3",
                "col5": "detdom32@mosk.stavregion.ru",
                "col6": "http:\/\/детдом-32.рф",
                "col7": "8 87933 3-58-90"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей с ограниченными возможностями здоровья «Специальная (коррекционная) школа-интернат № 14»",
                "col4": "356500  Ставропольский корай, Петровский округ, с. Константиновское, пл. Свободы,35",
                "col5": "kh14@yandex.ru",
                "col6": "https:\/\/www.sk-26.ru\/",
                "col7": "8 86547 6-22-38"
            },
            {
                "col1": "Ставропольский край",
                "col2": "Министерство образования Ставропольского края",
                "col3": "Государственное казенное общеобразовательное учреждение «Специальная (коррекционная) школа-интернат № 9 для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья»",
                "col4": "356353 Ставропольский край, Новоселицкий округ,   п. Падинское,   ул. Подгорная, 101",
                "col5": "soshint9@mosk.stavregion.ru",
                "col6": "https:\/\/sh-int9-padinskoe-r07.gosweb.gosuslugi.ru\/",
                "col7": "8 86548 2-86-25"
            },
        ],
    ],
    [
        [
            {
                "col1": "Тамбовская область",
                "col2": "Министерство образования и науки  Тамбовской области",
                "col3": "Тамбовское областное государственное бюджетное учреждение для детей, находящихся в трудной жизненной ситуации, «Центр поддержки семьи и помощи детям им.А.В.Луначарского»",
                "col4": "393250 Тамбовская область, г.Рассказово, ул.М.Горького, д.74",
                "col5": "ddlunaras@obraz.tambov.gov.ru",
                "col6": "http:\/\/ddlunaras.68edu.ru\/",
                "col7": "8 (47531) 2-97-44"
            },
            {
                "col1": "Тамбовская область",
                "col2": "Министерство образования и науки  Тамбовской области",
                "col3": "Тамбовское\nобластное государственное бюджетное учреждение для детей,\nнаходящихся в трудной жизненной ситуации,\n«Центр поддержки семьи и помощи детям «Семейный причал»",
                "col4": "393345, Тамбовская область, Инжавинский район с.Красивка, ул. Первомайская, д. За",
                "col5": "togou2008@obraz.tambov.gov.ru",
                "col6": " www.krasdd.68edu.ru",
                "col7": " 8 (47553) 6-62-64"
            },
            {
                "col1": "Тамбовская область",
                "col2": "Министерство образования и науки  Тамбовской области",
                "col3": "Тамбовское областное государственное бюджетное общеобразовательное учреждение «Красносвободненская санаторная школа-интернат»",
                "col4": "392530 Тамбовская область, Тамбовский район, с. Красносвободное, ул. Школа-интернат, 6",
                "col5": "kasvobshin@obraz.tambov.gov.ru",
                "col6": "http:\/\/kasvobshin.68edu.ru\/",
                "col7": " 8 (4752) 66-44-16"
            },
            {
                "col1": "Тамбовская область",
                "col2": "Министерство образования и науки  Тамбовской области",
                "col3": "Тамбовское областное государственное бюджетное учреждение для детей, находящихся в трудной жизненной ситуации, «Центр поддержки семьи и помощи детям им. Г.В.Чичерина»",
                "col4": "Тамбовская область, Инжавинский район, с. Караул, ул. Садовая, д. 57",
                "col5": "karaul@obraz.tambov.gov.ru ",
                "col6": "https:\/\/det-dom.mid.ru\/",
                "col7": "8 (47553) 3-71-42"
            },
            {
                "col1": "Тамбовская область",
                "col2": "Министерство образования и науки  Тамбовской области",
                "col3": "Тамбовское областное государственное бюджетное общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родитетелей, \"Горельская школа-интернат для обучающихся с ограниченными возможностями здоровья\"",
                "col4": "Тамбовская область, Тамбовский район, с. Горелое, ул.Заводская, д.1",
                "col5": "gchi@obraz.tambov.gov.ru",
                "col6": "http:\/\/gorinternat.68edu.ru\/",
                "col7": " 8 (4752) 61-61-32 "
            },
            {
                "col1": "Тамбовская область",
                "col2": "Министерство образования и науки  Тамбовской области",
                "col3": "Тамбовское областное государственное бюджнетное учреждение социального обслуживания детский дом-интернат для детей с серьезными нарушениями в интеллектуальном развитии «Мишутка» ",
                "col4": "393450 Тамбовская область, Сампурский район,с. Гавриловка, ул. Сиреневая  д. 14",
                "col5": "mishutka@obraz.tambov.gov.ru",
                "col6": "https:\/\/mishutka.tmbreg.ru\/",
                "col7": "8 (47556) 4-11-00"
            },
            {
                "col1": "Тамбовская область",
                "col2": "Министерство образования и науки  Тамбовской области",
                "col3": "Государственное бюджетное учреждение здравоохранения «Тамбовский областной специализированный дом ребенка»",
                "col4": "Тамбовская область, г.Тамбов, ул.Мичуринская, д. 112 б",
                "col5": "tosdr@obraz.tambov.gov.ru",
                "col6": "http:\/\/дом-ребенка-тамбов.рф\/",
                "col7": "8 (4752) 51-73-61"
            },
        ],
    ],
    [
        [
            {
                "col1": "Тверская область",
                "col2": "Министерство образования Тверской области",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Митинский детский дом\"",
                "col4": "172061, Торжокский район, л.Митино, д.16",
                "col5": "mitinodetdom@mail.ru\n",
                "col6": "http:\/\/mitinodetdom.twsite.ru\/",
                "col7": "84825164341; 84825164349"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство образования Тверской области",
                "col3": "Государственное казеннлое учреждение для детей-сироти детей, оставшихся без попечения родителей \"Удомельский детский дом\"",
                "col4": "171843 Тверская область город Удомля переулок Больничный дом 1",
                "col5": "uddaib@mail.ru",
                "col6": "Удомельский детский дом (detdom-udomlya.ru)",
                "col7": "8(48-255)5-91-09"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство образования Тверской области",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей\n «Центр кадетского воспитания»\n",
                "col4": "170551, Тверская область, Калининский район, \nд. Некрасово, ул. Центральная, д.20\n",
                "col5": "nekrasddom@mail.ru  ",
                "col6": "http:\/\/gkutskv.twsite.ru\/",
                "col7": "8(4822)389818"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство образования Тверской области",
                "col3": "Государственное казенное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Чистореченский детский дом\"",
                "col4": "172800, Тверская область, Андреапольский м.о.,             п. Чистая Речка, д.1,    стр. 1",
                "col5": "chrechka@rambler.ru",
                "col6": "https:\/\/chrechka-dd.nubex.ru\/",
                "col7": 84826732512
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство образования Тверской области",
                "col3": "Государственное казённое учреждение для детей - сирот и детей, оставшихся без попечения родителей, \"Калязинский детский дом \"Родничок\"\"",
                "col4": "171573, Тверская область, г. Калязин, ул. Челюскинцев, д.12",
                "col5": "   dd_rodnichok@mail.ru",
                "col6": "http:\/\/кдд-родничок.рф\/",
                "col7": "8(48249)2-15-79"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство образования Тверской области",
                "col3": "Государственное казенное учреждение для детей - сирот и детей, оставшихся без попечения родителей \"Зубцовский детский дом\"",
                "col4": "172333 Тверская область город Зубцов переулок Рыбацкий дом 20",
                "col5": "gkuzzubcov@yandex.ru",
                "col6": "зубцовский-дд.рф",
                "col7": "848(262) 2-14-27, 2-11-06"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство образования Тверской области",
                "col3": "Государственное казённое общеобразовательное учреждение для детей- сирот и детей, оставшихся без попечения родителей \"Торжокская школа-интернат\"   ",
                "col4": "172022, Тверская область, Торжокский район, п.Зелёный, ул.Школа-интернат, д.8",
                "col5": "internat_zeleniy@mail.ru",
                "col6": "https:\/\/www.internat-zeleniy.ru",
                "col7": "8(48251)64642"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство образования Тверской области",
                "col3": "Государственное казенное общеобразовательное учреждение для детей-сирот и детей,оставшихся без попечения родителей \"Кимрская школа-интернат\"",
                "col4": "171505, Тверская обл., г.Кимры, ул.Парковая, д.3",
                "col5": "tverspetsschool@mail.ru",
                "col6": "школа-интернат-кимры.рф",
                "col7": "8 (48236)42982, 44137"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство образования Тверской области",
                "col3": "Государственное казённое общеобразовательное учреждение для детей-сирот и детей, оставшихся без попечения родителей \"Бежецкая школа-интернат №1\"",
                "col4": "171988, Тверская область, Бежецкий район, г.Бежецк, площадь имени Константина Чернышова, дом 27",
                "col5": "helenpakkanen@mail.ru",
                "col6": "www.bezhinternat.ru",
                "col7": 84823121961
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство социальной защиты населения Тверской области",
                "col3": "Государственное бюджетное учреждение «Кашаровский детский дом-интернат для детей с серьезными нарушениями в интеллектуальном развитии»",
                "col4": "171168, Тверская область, г. Вышний Волочек, Ржевский тракт, 113-а.",
                "col5": "kddivvol@mail.ru",
                "col6": "https:\/\/krc-vvol.ru\/",
                "col7": "(48233) 6-29-64, (48233) 6-25-58"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство социальной защиты населения Тверской области",
                "col3": "Государственное бюджетное учреждение социального обслуживания для детей-сирот и детей, оставшихся без попечения родителей, Тверской области «Областной Центр помощи детям, оставшимся без попечения родителей» (г. Торжок)",
                "col4": "172003, Тверская обл., г. Торжок, Ленинградское ш.,73",
                "col5": "centrtorzhok@mail.ru",
                "col6": "http:\/\/центрпомощидетям-торжок.рф\/",
                "col7": "848(251)9-05-12"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство здравоохранения Тверской области",
                "col3": "Государственное казенное учреждение здравоохранения Тверской области «Специализированный дом ребенка \"Теремок\"",
                "col4": "170028, г. Тверь, проспект Победы, д. 80",
                "col5": "teremok.tver@gmail.com",
                "col6": "http:\/\/www.teremok69.ru",
                "col7": "(4822)32-79-60"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство здравоохранения Тверской области",
                "col3": "Государственное казённое учреждение здравоохранения Тверской области \"Конаковский специализированный Дом ребенка для детей с органическим поражением центральной нервной системы с нарушением психики\"",
                "col4": "171252, Тверская область, г. Конаково, ул. Учебная, д. 19",
                "col5": "mail@ksdr.org",
                "col6": "htt:\/\/ksdr.org",
                "col7": "8(48242) 4-31-98"
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство здравоохранения Тверской области",
                "col3": "Государственное казенное учреждение здравоохранения Тверской области «Кашинский специализированный дом ребенка»",
                "col4": "171640, Тверская область, г. Кашин, ул. Карла Маркса, д. 34\/23",
                "col5": "kashin_dom_rebenok@mail.ru",
                "col6": "kashin-dom-rebenok.ru",
                "col7": "8 (48234) 2-00-46, 8 (48234) 2-11-46 "
            },
            {
                "col1": "Тверская область",
                "col2": "Министерство здравоохранения Тверской области",
                "col3": "Государственное казенное учреждение здравоохранения Тверской области «Вышневолоцкий Дом ребенка «Солнышко»",
                "col4": "171158 Тверская обл. г. Вышний Волочек,ул. Д. Бедного,78",
                "col5": "domrebenkasolnishko@yandex.ru",
                "col6": "domvv.ru",
                "col7": "8(48233)6-26-69"
            },
        ],
    ],
    [
        [
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКУ «Центр помощи детям, оставшимся без попечения родителей, Асиновского района»",
                "col4": "636843, Томская область, г. Асино, ул. Дзержинского, 15",
                "col5": "cpdbr-asino@tomsk.gov.ru",
                "col6": "cpdbr-asino@tomsk.gov.ru",
                "col7": "7 (38241) 287-02, 7 (38241) 287-03, 7 (38241) 283-52"
            },
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКУ «Центр помощи детям, оставшимся без попечения родителей, «Орлиное гнездо»",
                "col4": "634016 г. Томск,\nпер. Басандайский 6-й, д.1\n",
                "col5": "cpdbr-orlin@tomsk.gov.ru",
                "col6": "http:\/\/og-tsk.ru\/",
                "col7": "7 (3822) 4247-04"
            },
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКУ «Центр помощи детям, оставшимся без попечения родителей, «Росток»",
                "col4": "634021 г. Томск, \nул. Л.Толстого,47\n",
                "col5": "cpdbr-rostok@tomsk.gov.ru",
                "col6": " http:\/\/detdom1.tomsk.ru",
                "col7": "7 (3822) 452-599"
            },
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКУ «Центр помощи детям, оставшимся без попечения родителей, г. Томска»",
                "col4": "634050г. Томск, ул. Тверская,74\/1",
                "col5": "detdom4tomsk@yandex.ru",
                "col6": "https:\/\/tomsk-cpd.tom.socinfo.ru\/",
                "col7": "7 (3822) 521-116, 7 (3822) 521-032"
            },
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКУ «Центр помощи детям, оставшимся без попечения родителей, Бакчарского района»",
                "col4": "636200 Томская область, с. Бакчар, ул. Хомутского, 48.",
                "col5": "cpdbr-bak@tomsk.gov.ru",
                "col6": "http:\/\/bakdd.tomedu.ru\/",
                "col7": " 7 (38249) 213-72"
            },
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКУ «Центр помощи детям, оставшимся без попечения родителей, «Родник»»",
                "col4": "636859 Томская область, с.Чердаты, пер. Мира, 2а",
                "col5": "cpdbr-rodnik@tomsk.gov.ru",
                "col6": "http:\/\/чердатыдд.рф",
                "col7": "7 (38243) 322-66"
            },
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКУ «Центр помощи детям, оставшимся без попечения родителей, Зырянского района»",
                "col4": "636850, Томская область, с. Зырянское, пер. Гоголя,2",
                "col5": "cpdbr-zir@tomsk.gov.ru",
                "col6": "http:\/\/cpdbr-zyr.tom.socinfo.ru",
                "col7": "7 (38243) 217-81"
            },
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКУ «Центр помощи детям, оставшимся без попечения родителей, имени М.И.Никульшина»",
                "col4": "636451 \nс.Тогур, ул.Советская, 84\n",
                "col5": "cpdbr-kolp@tomsk.gov.ru",
                "col6": "http:\/\/togur-dd.tom.socinfo.ru",
                "col7": "7 (38254) 551-65"
            },
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКСУ для умственно отсталых детей \"Тунгусовский детский дом-интернат\" ",
                "col4": "636353, Томская область, с. Тунгусово, ул. Школьная, 1",
                "col5": "ddi-tugusovo@tomsk.gov.ru",
                "col6": "https:\/\/tddi.tom.socinfo.ru\/",
                "col7": "7 (38256) 354-66 "
            },
            {
                "col1": "Томская область ",
                "col2": "Департамент по вопросам семьи и детей Томской области ",
                "col3": "ОГКУ \"Центр \"Малышок\"",
                "col4": "634009 г. Томск, ул. Карла Маркса, 52",
                "col5": "babyhome@trecom.tomsk.ru",
                "col6": "http:\/\/babyhometomsk.ru\/",
                "col7": "7 (3822) 563-483, 400-051"
            },
        ],
    ],
    [
        [
            {
                "col1": "Тульская область",
                "col2": "Министерство труда и социальной защиты Тульской области",
                "col3": "Государственное учреждение Тульской области \"Социально-реабилитационный центр для несовершеннолетних № 1\"",
                "col4": "300004, г. Тула, ул. Р.Зорге, д. 36",
                "col5": "srtc.tcpd@tularegion.ru",
                "col6": "\nhttps:\/\/srcn1-tula.ru\n",
                "col7": "\n8(4872)41-09-51\n"
            },
            {
                "col1": "Тульская область",
                "col2": "Министерство труда и социальной защиты Тульской области",
                "col3": "Государственное учреждение Тульской области \"Социально-реабилитационный центр для несовершеннолетних № 2\"",
                "col4": "301440, Тульская область, рп Одоев, ул.Октябрьская, д.72-а",
                "col5": "srtc.odoev@tularegion.ru",
                "col6": "http:\/\/срцн2.рф\/\n\n\n\n\n​\n",
                "col7": "​8(48736)4-11-96"
            },
            {
                "col1": "Тульская область",
                "col2": "Министерство труда и социальной защиты Тульской области",
                "col3": "Государственное учреждение Тульской области \"Социально-реабилитационный центр для несовершеннолетних № 3\"",
                "col4": "301361, Тульская область, г. Алексин, ул. Арматурная, д. 19",
                "col5": "srcn.aleksin@tularegion.ru",
                "col6": "http:\/\/srcn3.ru ",
                "col7": "8 (48753)21940"
            },
            {
                "col1": "Тульская область",
                "col2": "Министерство труда и социальной защиты Тульской области",
                "col3": "Государственнон учреждение Тульской области \"Социально-реабилитационный центр для несовершеннолетних № 4\"",
                "col4": "301164, Тульская область, Дубенский район, п.Гвардейский, ул.Молодёжная, д. 11",
                "col5": "srtc4.dubna@tularegion.ru",
                "col6": "https:\/\/centrdubna.ru",
                "col7": "8-487(32)3-26-68"
            },
            {
                "col1": "Тульская область",
                "col2": "Министерство труда и социальной защиты Тульской области",
                "col3": "Государственное учреждение Тульской области «Социально-реабилитационный центр для несовершеннолетних №5»",
                "col4": "301845, Тульская область,г.Ефремов, ул.Строителей, д.41а",
                "col5": "srtc.efremov@tularegion.ru",
                "col6": "https:\/\/srcn5-efremov.ru\/\n\n\n\n​\n\n​",
                "col7": "​8(48741)5-57-24​"
            },
            {
                "col1": "Тульская область",
                "col2": "Министерство труда и социальной защиты Тульской области",
                "col3": "Государственное учреждение Тульской области «Социально-реабилитационный центр для несовершеннолетних № 6»",
                "col4": "301262 Тульская область, г. Киреевск, ул. Комарова, д. 17",
                "col5": "srcn6@tularegion.org",
                "col6": " http:\/\/срцн-6.рф",
                "col7": "8(48754)6-28-77\n"
            },
            {
                "col1": "Тульская область",
                "col2": "Министерство труда и социальной защиты Тульской области",
                "col3": "Государственное учреждение социального обслуживания Тульской области «Центр развития и сопровождения»",
                "col4": "301262 Тульская область, г. Киреевск, ул. Кирова, д. 12",
                "col5": "shi.kireevsk@tularegion.ru",
                "col6": "https:\/\/crskireevsk.ukit.me\/",
                "col7": "8(48754)6-23-81"
            },
            {
                "col1": "Тульская область",
                "col2": "Министерство труда и социальной защиты Тульской области",
                "col3": "Государственное учреждение Тульской области \"Головеньковский детский дом-интернат\"",
                "col4": "301210, Тульская область, Щекинский район, пос.Головеньковский, д.28-а ",
                "col5": "ddi.golovenkovskiy@tularegion.ru",
                "col6": "https:\/\/www.detdomgdi.ru\/",
                "col7": "8(48751)38-6-07"
            },
        ],
    ],
    [
        [
            {
                "col1": "Тюменская область",
                "col2": "Департамент социального развития Тюменской области",
                "col3": "Автономное учреждение Тюменской области «Региональный специализированный дом ребенка»",
                "col4": "625000, г.Тюмень, ул.Кузнецова д.5",
                "col5": "domreb@obl72.ru ",
                "col6": "http:\/\/dom-malyutki72.ru\/ru\/",
                "col7": "3452 22-78-00"
            },
            {
                "col1": "Тюменская область",
                "col2": "Департамент социального развития Тюменской области",
                "col3": "Автономное учреждение Тюменской области «Центр помощи детям, оставшимся без попечения родителей «Дом детства »",
                "col4": "626153, г. Тобольск, ул.. Ремезова,, д. 70, корп. 1",
                "col5": "Dom_detstva@obl72.ru",
                "col6": "https:\/\/ddom.sznto.ru\/",
                "col7": "3456 25-91-02"
            },
            {
                "col1": "Тюменская область",
                "col2": "Департамент социального развития Тюменской области",
                "col3": "Автономное стационарное учреждение социального обслуживания населения Тюменской области «Детский психоневрологический дом-интернат»",
                "col4": "625503, Тюменская область, Тюменский район,  296 км Федеральной автомобильной  дороги  «Екатеринбург — Тюмень»",
                "col5": "uddi72@mail.ru",
                "col6": "http:\/\/uddi72.ru\/",
                "col7": "3452 72-69-93 "
            },
            {
                "col1": "Тюменская область",
                "col2": "Департамент социального развития Тюменской области",
                "col3": "Автономное учреждение социального обслуживания населения Тюменской области и дополнительного профессионального образования «Региональный социально-реабилитационный центр для несовершеннолетних «Семья»",
                "col4": "625025, г. Тюмень, ул. Геологоразведчиков, 14а",
                "col5": "semya-centr@obl72.ru",
                "col6": "центр-семья72.рф",
                "col7": "8 3452 20-89-88   "
            },
            {
                "col1": "Тюменская область",
                "col2": "Департамент социального развития Тюменской области",
                "col3": "Автономное учреждение социального обслуживания населения Тюменской области «Социально-реабилитационный   центр для несовершеннолетних «Согласие» города Ишима»",
                "col4": "627750, г. Ишим, ул. Малая Садовая, 71",
                "col5": "srcn-soglasie@obl72.ru",
                "col6": "srcnsoglasie.ru",
                "col7": "8 34551 5-11-43\n\n"
            },
        ],
    ],
    [
        [
            {
                "col1": "Удмуртская Республика",
                "col2": "Министерство социальной политики и труда Удмуртской Республики",
                "col3": "Казенное учреждение социального обслуживания Удмуртской Республики \"Республиканский социально - реабилитационный центр для несовершеннолетних\"",
                "col4": "426006, Удмуртская Республика, г. Ижевск, ул. Телегина, д. 42а",
                "col5": "rcdn-ur@msp-org.udmr.ru",
                "col6": "https:\/\/srcn18.ru\/  ",
                "col7": "8 (341) 70-84-15"
            },
            {
                "col1": "Удмуртская Республика",
                "col2": "Министерство социальной политики и труда Удмуртской Республики",
                "col3": "казенное учреждение здравоохранения Удмуртской Республики «Республиканский специализированный психоневрологический дом ребенка»",
                "col4": "426009;  г. Ижевск, ул. Ухтомского, д. 19,",
                "col5": "neiron-adm@mail.ru",
                "col6": "https:\/\/domrebenka18.ru\/    ",
                "col7": "8 (3412) 37-97-55"
            },
        ],
    ],
    [
        [
            {
                "col1": "Ульяновская область",
                "col2": "Министерство социального развития Ульяновской области",
                "col3": "Областное государственное казённое учреждение для детей-сирот и детей, оставшихся без попечения родителей - Ульяновский специальный (коррекционный) детский дом для детей с ограниченными возможностями здоровья «Гнёздышко» - Центр по развитию семейных форм устройства и сопровождению семей и детей",
                "col4": "432032, г.Ульяновск, ул.Терешковой, д. 3 а",
                "col5": "kouud@mail.ru ",
                "col6": "http:\/\/gnezdyshko.org\/",
                "col7": "7 (8422) 45-27-98"
            },
            {
                "col1": "Ульяновская область",
                "col2": "Министерство социального развития Ульяновской области",
                "col3": "Областное государственное казённое  учреждение для детей-сирот и детей, оставшихся без попечения родителей - специальный (коррекционный) детский дом для детей с ограниченными возможностями здоровья «Дом детства»",
                "col4": "432057, г. Ульяновск, ул. Оренбургская, д. 33",
                "col5": "koudd@mail.ru",
                "col6": "https:\/\/domdetstva-ul.ru\/",
                "col7": "7 (8422) 52-65-32"
            },
            {
                "col1": "Ульяновская область",
                "col2": "Министерство социального развития Ульяновской области",
                "col3": "Областное государственное казённое учреждение для детей - сирот и детей, оставшихся без попечения родителей - Майнский специальный (коррекционный) детский дом для детей с ограниченными возможностями здоровья «Орбита»",
                "col4": "433130, Ульяновская область, Майнский район р.п.Майна, ул.Ленинская, д.8",
                "col5": "koumadd@mail.ru",
                "col6": "https:\/\/orbita.3dn.ru\/",
                "col7": "7 (8422)44-37-50"
            },
            {
                "col1": "Ульяновская область",
                "col2": "Министерство социального развития Ульяновской области",
                "col3": "Областное государственное казённое учреждение для детей-сирот и детей, оставшихся без попечения родителей - Димитровградский специальный (коррекционный) детский дом  для детей с ограниченными возможностями здоровья «ПЛАНЕТА»",
                "col4": "  433513, Ульяновская область, г. Димитровград, ул. Ленинградская,  д.54 а",
                "col5": "kou2dd@bk.ru",
                "col6": "https:\/\/planetadd.ucoz.ru\/index\/osnovnye_svedenija\/0-78",
                "col7": "7 (84235) 2‒62‒54"
            },
            {
                "col1": "Ульяновская область",
                "col2": "Министерство социального развития Ульяновской области",
                "col3": "Областное государственное казённое  учреждение  для детей-сирот и детей, оставшихся без попечения родителей Детский дом  «Соловьиная роща»",
                "col4": "432072, г.Ульяновск,  проспект Авиастроителей, дом 19",
                "col5": "solov-roscha@mail.ru",
                "col6": "http:\/\/roshcha.ru\/",
                "col7": "7 (8422)20-85-2"
            },
            {
                "col1": "Ульяновская область",
                "col2": "Министерство социального развития Ульяновской области",
                "col3": "Областное государственное бюджетное учреждение социального обслуживания «Детский дом-интернат для умственно отсталых детей «Родник» в с.Максимовка»",
                "col4": "433319, Ульяновская область, Ульяновский район, с.Максимовка, ул.Максима Горького, д.1 а",
                "col5": "Maximovdetdom@rambler.ru",
                "col6": "https:\/\/максимовскийдетдом.рф\/",
                "col7": "7 (84254) 3-12-97"
            },
            {
                "col1": "Ульяновская область",
                "col2": "Министерство социального развития Ульяновской области",
                "col3": "Государственное казённое учреждение \n«Областной специализированный дом ребёнка для детей с органическим поражением центральной нервной системы с нарушением психики»",
                "col4": "432049, г.Ульяновск, ул.Пушкарева, д.52 А",
                "col5": "osdreb@mz73.ru",
                "col6": "https:\/\/спецдомребенка73.рф\/",
                "col7": "7 (8422) 40-83-12"
            },
        ],
    ],
    [
        [
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное бюджетное учрежде-ние \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попе-чения родителей \"Детский дом № 1\"\n(КГБУ Детский дом 1)",
                "col4": "ул. Ленина, д. 20, г. Хабаровск, 680000",
                "col5": "dd1@khv.gov.ru",
                "col6": "дд1.рф",
                "col7": " (4212) 45-62-25 "
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 4\"\n(КГКУ Детский дом 4)",
                "col4": "ул. Краснореченская, д. 185, а, г. Хабаровск, 680023",
                "col5": "dd4@khv.gov.ru",
                "col6": "дд4.рф",
                "col7": "(4212) 36-41-87"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 6\"\n(КГКУ Детский дом 6)",
                "col4": "пер. Гаражный, д. 16, г. Хабаровск, 680014",
                "col5": "dd6@khv.gov.ru",
                "col6": "ddom6.ippk.ru",
                "col7": "4212) 27-56-64 "
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 8\"\n(КГКУ Детский дом 8)",
                "col4": "ул. Гамарника, д. 19, корп. 5, г. Комсомольск-на-Амуре,\nХабаровский край, 681032\n",
                "col5": "dd8@khv.gov.ru",
                "col6": "ddn8.edu.27.ru",
                "col7": "(4217) 59-53-77 "
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 10\"\n (КГКУ Детский дом № 10)",
                "col4": "ул. Вокзальная, д. 44, корпус 3, г. Комсомольск-на-Амуре,\nХабаровский край, 681010\nул. Парижской коммуны, 32\/2 (2 корпус)\n",
                "col5": "dd10@khv.gov.ru",
                "col6": "ddn10.edu.27.ru",
                "col7": "(4217) 53-23-26 "
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 12\"\n(КГКУ Детский дом 12)",
                "col4": "пр. Строителей, д. 58 а, г. Амурск, Хабаровский край, 682640",
                "col5": "dd12@khv.gov.ru",
                "col6": "dd12.ru",
                "col7": "(42142) 9-98-91"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 14\"\n(КГКУ Детский дом 14)",
                "col4": "ул. Дальневосточная, д. 21, г. Бикин, Хабаровский край, 682970",
                "col5": "dd14@khv.gov.ru",
                "col6": "дд14.рф",
                "col7": "(42155) 21-5-86"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 16\"\n(КГКУ Детский дом 16)",
                "col4": "ул. Вокзальная, д. 13, рп. Октябрьский,\nВанинский район,\nХабаровский край, 682890\n",
                "col5": "dd16@khv.gov.ru",
                "col6": "ddn16@edu.27.ru",
                "col7": "(42137) 25-4-85"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 17\"\n(КГКУ Детский дом 17)",
                "col4": "ул. 60 лет образования СССР, д. 2, п. Новый Ургал,\nВерхнебуреинский рай-он,\nХабаровский край, 682071\n",
                "col5": "dd17@khv.gov.ru",
                "col6": "ddn17.edu.27.ru",
                "col7": "(42149) 4-23-99 "
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попечения родителей \"Детский дом № 20\" (КГКУ Детский дом 20)",
                "col4": "ул. Молодежная, 2, с. Хурба, Комсомольский район,\nХабаровский край, 681062\n",
                "col5": "dd20@khv.gov.ru",
                "col6": "ddom20.ippk.ru",
                "col7": "(4217) 56-86-28"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 21\"\n(КГКУ Детский дом 21)",
                "col4": "ул. Взлетная, д. 5, с. Хурба, Комсомоль-ский район,\nХабаровский край, 681060 \n",
                "col5": "dd21@khv.gov.ru",
                "col6": "ddom21.ippk.ru",
                "col7": "(4217) 56-04-36"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 23\"\n(КГКУ Детский дом 23)",
                "col4": "ул. Сентябрьская, д. 16 а, рп. Переяславка,\nрайон имени Лазо,\nХабаровский край, 682910\n",
                "col5": "dd23@khv.gov.ru",
                "col6": "dd23.edu.27.ru",
                "col7": "(42154) 21-3-08"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 24\"\n(КГКУ Детский дом 24)",
                "col4": "ул. Советская, д. 25, г. Николаевск-на-Амуре,\nХабаровский край, 682460\n",
                "col5": "dd24@khv.gov.ru",
                "col6": "ddn24.edu.27.ru",
                "col7": "(42135) 2-03-37"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 27\"\n(КГКУ Детский дом 27)",
                "col4": "ул. Ленина, д. 18  б, рп. Солнечный,\nСолнечный район,\nХабаровский край, 682711\n",
                "col5": "dd27@khv.gov.ru",
                "col6": "detdom-27.ru",
                "col7": "(42146) 2-31-92 "
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное бюджетное учрежде-ние \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попе-чения родителей \"Детский дом № 28\"\n(КГБУ Детский дом 28)",
                "col4": "ул. Советская, д. 54, \nс. Богородское,\nУльчский район,\nХабаровский край, 682400\n",
                "col5": "dd28@khv.gov.ru",
                "col6": "bog-dd28.edu.27.ru",
                "col7": "(42151) 5-16-89"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное бюджетное учрежде-ние \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попе-чения родителей \"Детский дом № 32\"\n(КГБУ Детский дом 32)",
                "col4": "ул. Школьная, д. 22, с. Некрасовка,\nХабаровский район,\nХабаровский край, 680507\n",
                "col5": "dd32@khv.gov.ru",
                "col6": "dd32@khv.gov.ru",
                "col7": "(4212) 54-90-50 "
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 34\"\n(КГКУ Детский дом 34)",
                "col4": "ул. Советская, д. 20, г. Комсомольск-на-Амуре,\nХабаровский край, 681029\n",
                "col5": "dd34@khv.gov.ru",
                "col6": "dd34.edu.27.ru",
                "col7": "(4217) 22-85-64"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 36\"\n(КГКУ Детский дом 36",
                "col4": "ул. Коммунистическая, д. 60, рп. Охотск, Хабаровский край, 682480",
                "col5": "dd36@khv.gov.ru",
                "col6": "ddn36.edu.27.ru",
                "col7": "(42141) 9-13-65"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Организация, осуществляющая обучение, для детей-сирот и детей, оставшихся без попече-ния родителей \"Детский дом № 37\"\n(КГКУ Детский дом 37)",
                "col4": "ул. Школьная, д. 36а, с. Найхин, Нанайский район,\nХабаровский край, 682375\n",
                "col5": "dd37@khv.gov.ru",
                "col6": "naihinint.obrnan.ru",
                "col7": "(42156) 43-1-66"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное казенное учреждение \"Хабаровский детский психоневрологический интернат\" (КГБУ \"Хабаровский ДПНИ\")",
                "col4": "ул. Ванинская, д.4, г. Хабаровск,  Хабаровский край,  680000",
                "col5": "diuodhab@khv.gov.ru",
                "col6": "diuodhab.mszn27.ru",
                "col7": "(4212) 48-14-41"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное бюджетное учреждение  \"Хабаровский центр помощи детям, оставшимся без попечения родителей\" (КГБУ \"Хабаровский ЦПД\")",
                "col4": "ул.Рокоссовского, д. 35А, г. Хабаровск, Хабаровский край, 680051",
                "col5": "hab-dom-rebenka@mail.ru",
                "col6": "domrebenka-2.ru",
                "col7": "(4212) 52-12-92"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное бюджетное учреждение  Комсомольский-на-Амуре центр помощи детям, оставшимся без попечения родителей\" (КГБУ \"Комсомольский-на-Амуре ЦПД\")",
                "col4": "ул. Молодогвардейская, д. 5, г. Комсомольск-на-Амуре, Хабаровский край, 681000",
                "col5": "cpdkoms@khv.gov.ru",
                "col6": "sdr-kms.medkhv.ru",
                "col7": "(4217) 55-23-63"
            },
            {
                "col1": "Хабаровский край",
                "col2": "Министерство социальной защиты Хабаровского края",
                "col3": "краевое государственное бюджетное учреждение  \"Николаевский-на-Амуре центр помощи детям, оставшимся без попечения родителей\" (КГБУ \"Николаевский-на-Амуре ЦПД\")",
                "col4": "ул. Бошняка, д. 4, г. Николаевск-на-Амуре, Хабаровский край, 682460",
                "col5": "spd-nikol@mail.ru",
                "col6": "sdr-nikol.medkhv.ru",
                "col7": "(42135) 2-73-61"
            },
        ],
    ],
    [
        [
            {
                "col1": "Ханты-Мансийский автономный округ - Югра",
                "col2": "Департамент социального развития Ханты-Мансийского автономного округа - Югры",
                "col3": "бюджетное учреждение Ханты-Мансийского автономного округа - Югры \"Ханты-Мансийский центр содействия семейному воспитанию\"",
                "col4": "628012, г.Ханты-Мансийск, ул.Свердлова, дом 23",
                "col5": "HMCSSV@admhmao.ru",
                "col6": "https:\/\/hmcpd.ru\/",
                "col7": "8 (3467) 32-07-91"
            },
        ],
    ],
    [
        [
            {
                "col1": "Херсонская область",
                "col2": "Министерство труда и социальной защиты Херсонской области",
                "col3": "Государственное бюджетное учреждение Херсонской области \"Алешкинский детский дом-интернат\" ",
                "col4": "Юридический адрес: 275100, Херсонская область, Алешкинский район, г. Алешки, пер. Ракетный, д 28.\nФактический адрес: 275700, Херсонская область, Скадовский район, г. Скадовск, ул. 33 Гв.Дивизии, д.5",
                "col5": "detskiydom.oleshki@khogov.ru",
                "col6": "-",
                "col7": "7(990)015-6158"
            },
            {
                "col1": "Херсонская область",
                "col2": "Министерство труда и социальной защиты Херсонской области",
                "col3": "Государственное бюджетное учреждение Херсонской области \"Херсонский областной дом-ребенка\"",
                "col4": "Юридический адрес: 273003, Херсонская область, г. Херсон, ул. Илюши Кулика, д.149а.\nФактический адрес: 75322, Херсонская область, Новотроицкий район, с. Воскресенское, ул. 9 Мая, д.4.",
                "col5": "-",
                "col6": "-",
                "col7": "7(990)097-9068"
            },
            {
                "col1": "Херсонская область",
                "col2": "Министерство труда и социальной защиты Херсонской области",
                "col3": "Государственное бюджетное учреждение Херсонской области \"Новокиевский социально-реабилитационный центр для несовершеннолетних\"",
                "col4": "Херсонская область, \nКаланчакский р-н, \nс. Новокиевка, \nул. Шедловского, д. 1 ",
                "col5": "t.skripec@khogov.ru",
                "col6": "-",
                "col7": "7(990)080-6884"
            },
        ],
    ],
    [
        [
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение социального обслуживания Центр помощи детям, оставшимся без попечения родителей, Агаповского муниципального района",
                "col4": "457431 Челябинская область,\nАгаповский район,\nп. Магнитный,\nул. Молодежная,12\n",
                "col5": "magdetdom@mail.ru",
                "col6": "https:\/\/agapovkacpd.chel.socinfo.ru",
                "col7": "8 (35140) 95-1-50"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение «Центр помощи детям, оставшимся без попечения родителей с.Кулуево» Аргаяшского муниципального района",
                "col4": "456894 Челябинская область, Аргаяшский район,\nс. Кулуево,\nул. Маяковского, 18\n",
                "col5": "detdomkuluevo@mail.ru\ncpd02@minsoc74.ru\n",
                "col6": "https:\/\/detdomkuluevo.eps74.ru",
                "col7": 83513195310
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение социального обслуживания «Цент помощи детям, оставшимся без попечения родителей г. Миньяр Ашинского муниципального района Челябинской области",
                "col4": "456007 Челябинская область,\nАшинский район\nг. Миньяр,\nул. Сорокина, 67\n",
                "col5": "ddom-minyar@mail.ru\ncpdminyar03@minsoc74.ru\n",
                "col6": "dd-minyar.ru",
                "col7": "8 (35159) 2-20-28"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение социального обслуживания «Центр помощи детям, оставшимся без попечения родителей г.Сим Ашинского муниципального района Челябинской области»",
                "col4": "456020 Челябинская область,\nАшинский район,\nг. Сим,\nул. Пушкина,11 «б»\n",
                "col5": "ddomsim@mail.ru",
                "col6": "http:\/\/sim-detdom.ru",
                "col7": "8 (35159) 9-51-50"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение \"Центр помощи детям, оставшимся без попечения родителей\" Брединского муниципального района Челябинской области",
                "col4": "457315 Челябинская область,\nБрединский район,\nп. Маяк,\nМагнитогорская, 4\n",
                "col5": "detdom_bredy@mail.ru\ndd05@minsoc74.ru\n",
                "col6": "https:\/\/detdom-bredy.nubex.ru\/",
                "col7": "8-351-41-75-1-93"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение \"Центр помощи детям, оставшимся без попечения родителей\" Варненского муниципального района",
                "col4": "457202 Челябинская область, Варненский район,\nп. Новый Урал,\nпер. Школьный,4\n",
                "col5": "varnadom@mail.ru",
                "col6": "varnadom74.ru",
                "col7": "8 (35142) 2-84-99"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждения  \"Верхнеуральский центр помощи детям, оставшимся без попечения родителей\"",
                "col4": "457670 Челябинская область,\nг. Верхнеуральск,\nул. Карла Маркса, 62а\n",
                "col5": "vurdetdom@yandex.ru\n cpd08@minsoc74.ru\n",
                "col6": "http:\/\/vercpd.eps74.ru",
                "col7": "8 (35143) 2-16-33"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение «Центр помощи детям, оставшимся без попечения родителей, «Искорка» Еманжелинского муниципального района Челябинской области",
                "col4": "456591 Челябинская область, Еманжелинский район\nп. Зауральский,\nул. Пятилетки, 1А \n",
                "col5": "cpd44@minsoc74.ru detdom_nadejda@mail.ru",
                "col6": "https:\/\/rosinka.eps74.ru\/",
                "col7": "8-351-38-40-009"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение социального обслуживания «Центр помощи детям, оставшимся без попечения родителей» Златоустовского городского округа",
                "col4": "456227 Челябинская область,\nг. Златоуст,\nпос. Айский д.20 «а» стр.1\n",
                "col5": "teremok_adm@mail.ru\ncenterpd12@minsoc74.ru\n",
                "col6": "https:\/\/zlat-teremok.ru\/",
                "col7": "8 (3513) 661944"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение \"Центр помощи детям, оставшимся без попечения родителей\" Карабашского городского округа",
                "col4": "456141 Челябинская область,\nг. Карабаш,\nул. 1 Мая, 23-а\n",
                "col5": "kdd1karabash@mail.ru",
                "col6": "http:\/\/karab.detdom.eps74.ru",
                "col7": "8 (35153) 5-55-09"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное учреждение социального обслуживания «Центр помощи детям, оставшимся без попечения родителей» Карталинского муниципального района Челябинской области (казенное)",
                "col4": "457375, Челябинская область,\nКарталинский район,\nс. Анненское,\nул. Шоссейная, 9\n",
                "col5": "cpd60@minsoc74.ru\nann_detdom@mail.ru\n",
                "col6": "https:\/\/detdomkar.educhel.ru\/   http:\/\/annensk-detdom.mpliders.ru\/",
                "col7": 83513394102
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение «Центр помощи детям, оставшимся без попечения родителей» Каслинского муниципального района",
                "col4": "454830 Челябинская область\nг. Касли,\nул. Лесная,2 «Б»\n",
                "col5": "ddom_kasli@mail.ru\n ddom40@minsoc74.ru\n",
                "col6": "http:\/\/kasli-cpd.chel.socinfo.ru\/",
                "col7": "835149 2-15-92,\n2-69-09 (доб.:201)\n"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение \"Центр помощи детям, оставшимся без попечения родителей» Катав-Ивановского муниципального района",
                "col4": "456110, Челябинская область\nг. Катав-Ивановск\nул. Пугачевская, д.66\n",
                "col5": "detdomkatav@yandex.ru\ndd15@minsoc74.ru\n",
                "col6": "katav-detdom.ru",
                "col7": "8 (35147) 2-49-93"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение \"Центр помощи детям, оставшимся без попечения родителей\" Кизильского муниципального района",
                "col4": "457610 Челябинская область, Кизильский район,\nс. Кизильское, ул. Мира, 38\/1\n",
                "col5": "detdom_kizil@mail.ru",
                "col6": "https:\/\/dd-kizil.ru\/",
                "col7": "8 (35155) 3-05-35"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение \"Центр помощи детям, оставшимся без попечения родителей\" Копейский городской округ",
                "col4": "456655 Челябинская область, г. Копейск,\nп. Горняк\nул. Чернышевского, 8\n",
                "col5": "moudd.kop@mail.ru",
                "col6": "ddomkopeisk.ucoz.ru",
                "col7": "8 (35139) 77798"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение центр помощи детям, оставшимся без попечения родителей \"Добрые сердца\" Коркинского муниципального района.",
                "col4": "456550 Челябинская область,\nг. Коркино,\nул. Сакко и Ванцетти, 26\n",
                "col5": "moudeti@mail.ru",
                "col6": "https:\/\/korkinodetdom2.eps74.ru\/",
                "col7": "8-351-52-3-76-39,\n8-351-52-3-76-38\n"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение социального обслуживания «Кунашакский центр помощи детям, оставшимся без попечения родителей» Кунашакского муниципального района",
                "col4": "456730 Челябинская область,\nКунашакский район,\nс. Кунашак,\nул. Совхозная,26\n",
                "col5": "kunashakdetdom@mail.ru",
                "col6": "kunashak-cpd.ru",
                "col7": "8 (35148) 2-87-19"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение социального обслуживания \"Центр помощи детям, оставшимся без попечения родителей\" Кусинского муниципального района",
                "col4": "456940 Челябинская область,\nг. Куса, ул. Гагарина, 26\n",
                "col5": "detdom-kusa@mail.ru",
                "col6": "detdom-kusa.eps74.ru",
                "col7": "8 (35154) 3-35-33"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение социального обслуживания Центр помощи детям, оставшихся без попечения родителей, Кыштымского городского округа «Горизонт»",
                "col4": "456870 Челябинская область,\nг. Кыштым,\nул. Энгельса, 4\n",
                "col5": "detskiydom95@mail.ru\ncpd22@minsoc74.ru\n",
                "col6": "mou5.eps74.ru",
                "col7": "8 (35151) 4-29-96"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное учреждение «Центр помощи детям, оставшимся без попечения родителей «Родник» города Магнитогорска ",
                "col4": "455044 Челябинская область,\nг. Магнитогорск,\nпр. Ленина,86\/3\n",
                "col5": "magdetdom1@yandex.ru",
                "col6": "https:\/\/mgndetdom1.eps74.ru\/",
                "col7": "8 (3519) 26-47-55"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное учреждение «Центр помощи детям, оставшимся без попечения родителей «Надежда» города Магнитогорска",
                "col4": "455049 Челябинская область,\nг. Магнитогорск,\nул. Галиуллина, 29\/2\n",
                "col5": "DOM-NADEGDAmgn74@yandex.ru",
                "col6": "https:\/\/dom-nadegdamgn74.eps74.ru\/",
                "col7": "8 (3519) 30-98-00"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное общеобразовательное учреждение «Школа-интернат для детей-сирот и детей, оставшихся без попечения родителей «Семья» города Магнитогорска ",
                "col4": "455026 Челябинская область\nг. Магнитогорск,\nул. Дружбы, 25\n",
                "col5": "internatsemya@yandex.ru\nuszn91_shinfamily@minsoc74.ru\n",
                "col6": "http:\/\/school-int7-ya.ucoz.ru\/",
                "col7": "8 (3519) 20-29-17"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное общеобразовательное учреждение «Специальная (коррекционная) школа-интернат № 5 для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья» города Магнитогорска",
                "col4": "455036 Челябинская область,\nг. Магнитогорск,\nул. Советской Армии, 5\/1\n",
                "col5": "internat5mag@mail.ru",
                "col6": "https:\/\/verba-56.wixsite.com\/internat5\/home",
                "col7": "8 (3519) 40-21-23"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Государственное казенное учреждение социального обслуживания\n«Миасский центр помощи детям, оставшимся без попечения родителей, \n«Алые паруса»\n",
                "col4": "456313 Челябинская область,\nг. Миасс,\nул. Нахимова, 2\n",
                "col5": "miass.sdd@yandex.ru\ncpd24@minsoc74.ru\n",
                "col6": "http:\/\/sdd-miass.ru\/",
                "col7": "8 (3513) 24-18-88"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "сГосударственное казенное учреждение социального обслуживания\n«Миасский центр помощи детям, оставшимся без попечения родителей, \n«Радуга»\n",
                "col4": "456318 Челябинская область,\nг. Миасс,\nул. Попова,7\n",
                "col5": "detdommiass@yandex.ru\nddraduga24@minsoc74.ru\n",
                "col6": "http:\/\/detdom-miass.ru\/",
                "col7": "8 (3513) 53-07-87"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение социального обслуживания «Центр помощи детям, оставшимся без попечения родителей» Нагайбакского муниципального района",
                "col4": "457650, Челябинская область,\nНагайбакский район,\nс. Фершампенуаз,\nул. Мира, 37.\n",
                "col5": "detdom35@mail.ru",
                "col6": "mou34.eps74.ru",
                "col7": "8 (351) 57 2-24-46"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение социального обслуживания «Центр помощи детям, оставшимся без попечения родителей» Нязепетровского муниципального района",
                "col4": "456970 Челябинская область,\nг.Нязепетровск,\nул. Коммунаров,19\n",
                "col5": "dd.nzp@yandex.ru\ncpd27@minsoc74.ru\n",
                "col6": "https:\/\/centr-nzp.ru\/",
                "col7": "8 (35156) 3-18-38"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение социального обслуживания «Центр помощи  детям-сиротам  и детям, оставшимся без попечения родителей» Озерского городского округа",
                "col4": "Юридический адрес:\n456780, Челябинская область, г. Озерск,\nул. Набережная, 19;\nфакт адрес:\nЧелябинская область,\nг. Озерск,\nул. Набережная, 19;\nул. Матросова, 5 – 2 здание\n",
                "col5": "o.detskiidom@yandex.ru\ndd92@minsoc74.ru\n",
                "col6": "http:\/\/www.озерскдетдом.рф\/",
                "col7": "8 (35130) 25826"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение социального обслуживания «Центр помощи детям, оставшимся без попечения родителей» Пластовского муниципального района",
                "col4": "457022\nЧелябинская область,\nг. Пласт\nул. Октябрьская,22\n",
                "col5": "moupdd@mail.ru",
                "col6": "plastdeti.eps74.ru",
                "col7": "8 (35160) 2-16-23"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Государственное казенное общеобразовательное учреждение \n«Саткинская школа-интернат для детей – сирот и детей, оставшихся без попечения родителей»\n",
                "col4": "456901, Челябинская область,\nСаткинский район,\nг. Бакал,\nул. Чапаева,45\n",
                "col5": "74322_s_22@mail.ru\nddsh31@minsoc74.ru\n",
                "col6": "74322s022.edusite.ru",
                "col7": "8 (35161) 9-69-55"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казённое учреждение социального обслуживания \"Центр помощи детям, оставшимся без попечения родителей Снежинского городского округа",
                "col4": "456770 Челябинская область,\nг. Снежинск\nул. Ленина,10\n",
                "col5": "detidoma@mail.ru\ncpd93@minsoc74.ru\n",
                "col6": "https:\/\/zlat-teremok.ru\/",
                "col7": "8 (3513) 661944"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное общеобразовательное учреждение «Есаульская специальная (коррекционная) общеобразовательная школа-интернат для детей сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья (нарушение интеллекта)»",
                "col4": "456530, Челябинская область, Сосновский район, пос. Есаульский, ул. Лесная, 7",
                "col5": "MOU-Esaulskaja-SKOU@yandex.ru",
                "col6": "http:\/\/esaul-internat.ru",
                "col7": "8 (35144) 97285"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное социальное учреждение \"Центр помощи детям, оставшимся без попечения родителей\" Трехгорного городского округа",
                "col4": "456080 Челябинская область,\nг. Трехгорный,\nул. Володина,1\n",
                "col5": "ddi94@minsoc74.ru\ncentr-trg@mail.ru\n",
                "col6": "http:\/\/detdom-trg.ru\/",
                "col7": "8 (351-91) 4-02-77"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казённое учреждение \"Центр помощи детям - сиротам и детям, оставшимся без попечения родителей\" города Троицка",
                "col4": "457100 Челябинская область,\nг. Троицк,\nул. Пионерская, 8\n",
                "col5": "tdd74@ya.ru\ntdd74@yandex.ru\ndd32@minsoc74.ru\/\n",
                "col6": "cpds.eps74.ru",
                "col7": "8 (35163) 21748"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение \"Центр помощи детям, оставшимся без попечения родителей\" Троицкого муниципального района",
                "col4": "457141 Челябинская область,\nТроицкий район,\nп. Новый Мир,\nул. Центральная, 27А\n",
                "col5": "trdd04@mail.ru\nspdp61@minsoc74.ru\n",
                "col6": "mou53.eps74.ru",
                "col7": "8 (35163) 41731"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казённое учреждение социального обслуживания \"Центр помощи детям, оставшимся без попечения родителей\" Усть-Катавского городского округа",
                "col4": "456040 Челябинская область,\nг. Усть-Катав, МРК-2,\nул. Строителей, 5\n",
                "col5": "det-domik@mail.ru",
                "col6": " www.detdom-uk.ru",
                "col7": "8 (35167) 30671"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное казенное учреждение Чебаркульского городского округа «Центр помощи детям, оставшимся без попечения родителей»",
                "col4": "456400 Челябинская область,\nг. Чебаркуль,\nул. Калинина, 10\n",
                "col5": "detdom1@inbox.ru\ncpd65@minsoc74.ru\n",
                "col6": "http:\/\/chebddkalinina.ucoz.com\/",
                "col7": "8 (35168) 55457"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение социального обслуживания \"Травниковский центр помощи детям, оставшимся без попечения родителей\" Чебаркульского муниципального района",
                "col4": "456402 Чебаркульский район,\nс. Травники,\nул. Победы, 64\n",
                "col5": "dtravniki@yandex.ru\n cpd35@minsoc74.ru\n",
                "col6": "mou54.eps74.ru",
                "col7": "8 (35168) 73-2-97"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение города Челябинска \"Центр помощи детям, оставшимся без попечения родителей, \"Аистёнок\"",
                "col4": "454006\nг. Челябинск,\nул. Нагорная, 18\n\n",
                "col5": "detsdom2@yandex.ru",
                "col6": "http:\/\/aistyata-2.ru",
                "col7": "8 (351) 264-48-22"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение города Челябинска \"Центр помощи детям, оставшимся без попечения родителей, \"Гнёздышко\"",
                "col4": "454084\nг. Челябинск,\nул. Калинина, 3А\n",
                "col5": "cheldetdom5@mail.ru",
                "col6": "https:\/\/cheldeddom5.blogspot.com\/",
                "col7": "8 (351) 791-98-13"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение города Челябинска \"Центр помощи детям, оставшимся без попечения родителей, \"Надежда\"",
                "col4": "454048\nг. Челябинск,\nул. Шаумяна, 83а\n",
                "col5": "ddom6_chel@mail.ru",
                "col6": "http:\/\/chelnadezhda.edu.ru\/",
                "col7": "8 (351) 237-27-13"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение города Челябинска «Центр помощи детям, оставшимся без попечения родителей, «Акварель»",
                "col4": "454139\nг. Челябинск,\nул. Василевского, 27\n\n",
                "col5": "akvareldd8@mail.ru\nddi49@minsoc74.ru\n",
                "col6": "http:\/\/detdom8.ru",
                "col7": "8 (351) 253-28-12"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение города Челябинска \"Центр помощи детям, оставшимся без попечения  родителей, \"Солнечный\"",
                "col4": "454014\nг. Челябинск\nул. Солнечная, 14-В\n\n",
                "col5": "detdom14@list.ru",
                "col6": "soldomchel.ru",
                "col7": "8 (351) 741-27-09"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное общеобразовательное учреждение «Специальная (коррекционная) общеобразовательная школа-интернат для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья (нарушение интеллекта) № 9»",
                "col4": "454012\nг. Челябинск,\nул. Уральская, 10а\n",
                "col5": "int9chel@mail.ru",
                "col6": "https:\/\/школа-интернат9.рф\/",
                "col7": "8 (351) 253-65-67"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное общеобразовательное учреждение «Специальная (коррекционная) общеобразовательная школа-интернат для детей-сирот и детей, оставшихся без попечения родителей, с ограниченными возможностями здоровья № 13 города Челябинска»",
                "col4": "454047\nг. Челябинск,\nул. Трудовая, 16\n\n",
                "col5": "Internat13@mail.ru\n13shi90@minsoc74.ru\n",
                "col6": "http:\/\/internat13.ru",
                "col7": "8 (351) 217-17-52 "
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное учреждение социального обслуживания \"Центр помощи детям, оставшимся без попечения родителей\" Чесменского муниципального района",
                "col4": "457220 Челябинская область,\nЧесменский район,\nс. Чесма,\nул. Ленина,10,корпус 1\n",
                "col5": "chesmdetdom@yandex.ru\nddi37@minsoc74.ru\n",
                "col6": "http:\/\/detcenter-chesma.chel.socinfo.ru\/",
                "col7": "8351-69-2-22-39"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Муниципальное бюджетное  учреждение «Центр помощи детям, оставшимся без попечения родителей» Южноуральского городского округа",
                "col4": "457040 Челябинская область,\nг. Южноуральск,\nул. Спортивная, 34А\n",
                "col5": "ddom.uuralsk@mail.ru\n cpd20@minsoc74.ru \n",
                "col6": "yu-deti.ru",
                "col7": "8 (35134) 42601"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Государственное казенное учреждение социального обслуживания «Центр содействия семейному воспитанию имени Зинаиды Антоновой г. Челябинска»",
                "col4": "454014\nг. Челябинск, ул. Захаренко, д. 16\n",
                "col5": "dom_rebenka2@mail.ru",
                "col6": "dom-rebenka2.ru",
                "col7": "8 (351) 742-75-11"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Государственное казенное учреждение социального обслуживания «Центр содействия семейному воспитанию имени № 1 г. Магнитогорска»",
                "col4": "450049\nг. Магнитогорск, ул. Галиуллина, д. 45\/3\n",
                "col5": "domreb4@bk.ru",
                "col6": "цссв-1.рф",
                "col7": "8 (3519) 40-50-07"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Государственное казенное учреждение социального обслуживания «Центр содействия семейному воспитанию № 2 \nг. Магнитогорска»\n",
                "col4": "450049\nг. Магнитогорск, ул. Доменщиков, д. 17\/2\n",
                "col5": "dom.reb.5@mail.ru",
                "col6": "domreb5.ru",
                "col7": "8 (3519) 34-38-83"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Государственное казенное учреждение социального обслуживания «Центр содействия семейному воспитанию г. Миасса»",
                "col4": "456320\nг. Миасс, \nул. Богдана Хмельницкого, д. 38\n",
                "col5": "guzodr6@mail.ru",
                "col6": "www.odr6",
                "col7": "8 (3513) 52-70-30"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Государственное казенное учреждение социального обслуживания «Центр содействия семейному воспитанию г. Златоуста»",
                "col4": "456207\nг. Златоуст, ул. им. А.С. Макаренко, д. 4 А\n",
                "col5": "psiodr@yandex.ru",
                "col6": "http:\/\/domrebenkazlat.ru\/",
                "col7": "8 (3513) 67-77-65"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Государственное казенное учреждение социального обслуживания «Центр содействия семейному воспитанию г. Копейска»",
                "col4": "456655\nг. Копейск, п. Потанино, пер. Тореза, д. 6\n",
                "col5": "odr8@mail.ru",
                "col6": "http:\/\/odr8.ko74.ru\/",
                "col7": "8 (35139) 2-03-44"
            },
            {
                "col1": "Челябинская область",
                "col2": "Министерство социальных отношений Челябинской области",
                "col3": "Государственное казенное учреждение социального обслуживания «Центр содействия семейному воспитанию г. Троицка",
                "col4": "457100\nг. Троицк, ул. Строителей, д. 7\n",
                "col5": "chil_house_9@mail.ru\n\n",
                "col6": "https:\/\/odr9-troitsk.chel.medobl.ru\/",
                "col7": "8 (35163) 3-19-87"
            },
        ],
    ],
    [
        [
            {
                "col1": "Чеченская Республика ",
                "col2": "нет организаций для детей-сирот",
                "col3": "-",
                "col4": "-",
                "col5": "-",
                "col6": "-",
                "col7": "-"
            },
        ],
    ],
    [
        [
            {
                "col1": "Чувашская Республика",
                "col2": "Министерство образования Чувашской Республики ",
                "col3": "БУ ЧР «Чебоксарский центр для детей-сирот и детей, оставшихся без попечения родителей» Минобразования Чувашии)",
                "col4": "428014, Чувашская Республика, г. Чебоксары, ул. Магницкого, д. 24.",
                "col5": "ch_deddom@mail.ru",
                "col6": "https:\/\/chdeddom.rchuv.ru\/",
                "col7": "8(8352) 52-72-39"
            },
            {
                "col1": "Чувашская Республика",
                "col2": "Министерство образования Чувашской Республики ",
                "col3": "КОУ «Порецкий детский дом имени И.Н. Ульянова» Минобразования Чувашии",
                "col4": "429020, Чувашская Республика, Порецкий район,  с. Порецкое, ул. Комсомольская, 5",
                "col5": "por_inte@mail.ru",
                "col6": "https:\/\/ddom-porezk.edu21.cap.ru\/?t=gal&eduid=3937",
                "col7": "8(83543) 2-15-78"
            },
            {
                "col1": "Чувашская Республика",
                "col2": "Министерство образования Чувашской Республики ",
                "col3": "БУ ЧР «Шумерлинский центр для детей-сирот и детей, оставшихся без попечения родителей» Минобразования Чувашии",
                "col4": "429124, Чувашская Республика,\nг. Шумерля, Порецкое шоссе, д. 4\n",
                "col5": "gshum_detdom@mail.ru",
                "col6": "https:\/\/elochka-gshum.edu21.cap.ru\/news\/",
                "col7": "8(83536) 2-27-56"
            },
            {
                "col1": "Чувашская Республика",
                "col2": "Министерство образования Чувашской Республики ",
                "col3": "БОУ «Кугесьская общеобразовательная школа-интернат для обучающихся с ограниченными возможностями здоровья» Минобразования Чувашии",
                "col4": "429500, Чувашская Республика, Чебоксарский район, п. Кугеси, ул. Шоршелская, д.5",
                "col5": "kugesyinternat1@rambler.ru ",
                "col6": "https:\/\/кугеси-интернат.рф\/",
                "col7": "8(3540) 2-10-01, 2-19-40"
            },
            {
                "col1": "Чувашская Республика",
                "col2": "Министерство образования Чувашской Республики ",
                "col3": "КУ «Специализированный Дом ребенка «Малютка» Минобразования Чувашии",
                "col4": "428022, Чувашская Республика, г.Чебоксары, проспект Мира,  д. 36 «А» ",
                "col5": "malutka@rchuv.ru",
                "col6": "https:\/\/dr-malutka.rchuv.ru",
                "col7": "8(8352) 63-10-24"
            },
        ],
    ],
    [
        [
            {
                "col1": "Чукотский автономный округ",
                "col2": "Департамент социальной политики Чукотского автономного округа",
                "col3": "Государственное казённое учреждение социального обслуживания «Чукотский социально-реабилитационный центр для несовершеннолетних»",
                "col4": "689000, Чукотский автономный округ, г. Анадырь,\n ул. Строителей, 17 ",
                "col5": "detidom.@mail.ru",
                "col6": "Chukotkabezsirot.chao.socinfo.ru",
                "col7": "8(42722) 2-15-19"
            },
        ],
    ],
    [
        [
            {
                "col1": "Ямало-Ненецкий автономный округ",
                "col2": "Департамент образования Ямало-Ненецкого автономного округа ",
                "col3": "Муниципальное казённое  учреждение «Детский дом «Семья»",
                "col4": "629807, Россия, Ямало-Ненецкий автономный округ, город Ноябрьск, ул. Дзержинского, д. 5",
                "col5": "deti.93@noyabrsk.yanao.ru; deti.93@mail.ru",
                "col6": "https:\/\/dom-semya.yanao.ru ",
                "col7": "(3496) 35-09-66 "
            },
            {
                "col1": "Ямало-Ненецкий автономный округ",
                "col2": "Департамент образования Ямало-Ненецкого автономного округа ",
                "col3": "государственное казенное учреждение Ямало-Ненецкого автономного округа «Детский дом «Сияние Севера»",
                "col4": "626389, Тюменская область, Исетский район, \nп. Кировский, ул. Центральная 10",
                "col5": "ddssevera@mail.ru",
                "col6": "https:\/\/ddssevera.yam.socinfo.ru\/",
                "col7": " 8 (922) 045-74-33"
            },
        ],
    ],
    [
        [
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГБУ ЯО «Детский дом МХВ им. Винокуровой Н.Н.»",
                "col4": "150014,                           г. Ярославль, ул. Салтыкова-Щедрина, д.78",
                "col5": "ddmxv.yaroslavl@yarregion.ru",
                "col6": "ddmhv.edu.yar.ru",
                "col7": "(4852) 21-24-08"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО «Детский дом-центр комплексного сопровождения детей-сирот и детей, оставшихся без попечения родителей «Солнечный»»",
                "col4": "150043, г. Ярославль ул. Автозаводская д. 57а",
                "col5": "dom.solnce@yarregion.ru",
                "col6": "yarlendom.edu.yar.ru",
                "col7": "(4852) 75-13-82"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО «Переславль-Залесский санаторный детский дом»",
                "col4": "152020, Ярославская обл., г. Переславль-Залесский, \nул. Кардовского, д. 53-а.\n",
                "col5": "postmaster.detdom.pereslavl@yarregion.ru",
                "col6": "detdom-per.edu.yar.ru ",
                "col7": "(48532) 3-08-94"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО «Детский дом «Волжский»»",
                "col4": "152914, Ярославская обл.,                         г. Рыбинск, \nул. Набережная Космонавтов, \nд. 31 а.\n",
                "col5": "o.volga@yarregion.ru",
                "col6": "\ndd72.edu.yar.ru",
                "col7": "(4855) 25-45-84"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО «Рыбинский детский дом»",
                "col4": "152914,  Ярославская область,  \nг. Рыбинск,  \nул. Качалова д. 4а\n",
                "col5": "rybdd.rybinsk@yarregion.ru",
                "col6": "\nryb79dom.edu.yar.ru",
                "col7": "(4855) 27-16-42"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГОУ ЯО «Багряниковская школа-интернат» ",
                "col4": "152430, Ярославская обл., Первомайский \nр-н, д. Багряники, ул. Школьная, \nд. 5\n",
                "col5": "internat.bagryanniki@yarregion.ru",
                "col6": "\nbagraniki.edu.yar.ru",
                "col7": "(48549) 34-5-45"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО Климатинский детский дом",
                "col4": "152128, \nЯрославская обл., Ростовский р-он, с. Климатино, Поречский сельский округ, ул. Нагорная, д. 1А  \n",
                "col5": "mouddk.klimatino@yarregion.ru",
                "col6": "mouddk.edu.yar.ru\n",
                "col7": "(48536) 2-02-04"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО «Петровский детский дом»",
                "col4": " 152130, Ярославская обл, Ростовский р-он, р.п. Петровское, ул. Пролетарская, д. 14",
                "col5": "dd.petrovsk@yarregion.ru",
                "col6": "petrovsk-dd.edu.yar.ru",
                "col7": "(48536) 4-01-42"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО «Угличский детский дом»",
                "col4": "152615, Ярославская обл., г. Углич, ул. Ольги Берггольц, д. 6",
                "col5": "ydd.uglich@yarregion.ru ",
                "col6": "\ndom-ugl.edu.yar.ru",
                "col7": "(48532) 2-02-36, (48532) 2-10-02"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО «Детский дом «Чайка»",
                "col4": "150052,                   г. Ярославль, ул. Александра Невского, д. 7 «а»",
                "col5": "detdom1.yar@yandex.ru",
                "col6": "\nyar1dom.edu.yar.ru",
                "col7": "(4852) 57-86-00"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО  «Великосельский детский дом» ",
                "col4": "152250, Ярославская обл., Гаврилов-Ямский район  Великосельский сельский округ, с. Великое, \nул. Ярославская, д. 14.\n",
                "col5": "lara.pehtereva@yandex.ru",
                "col6": "\nvelk-domgav.edu.yar.ru",
                "col7": "(48534) 38-3-29"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО «Даниловский детский дом»",
                "col4": "152090, Ярославская обл., Даниловский р-н, с. Спас, ул. Школьная, д. 2",
                "col5": "danilov.detdom@yandex.ru ",
                "col6": "dom-dan.edu.yar.ru",
                "col7": "(48538) 33-6-99"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГУ ЯО «Первомайский детский дом»",
                "col4": "152434 Ярославская обл. Первомайский район, \nд. Шильпухово, д.99\n",
                "col5": "pervomaidom@yandex.ru",
                "col6": "dom-prv.edu.yar.ru",
                "col7": "(48549)34-7-05"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГКУ СО ЯО Гаврилов-Ямский детский дом-интернат для УО детей",
                "col4": "152240, Ярославская область, \nг. Гаврилов-Ям, ул. Сосновая, д.7\n",
                "col5": "gavyamdetdom@mail.ru",
                "col6": "gavyamdetdom.ru\n",
                "col7": "(48534) 2-05-20"
            },
            {
                "col1": "Ярославская область",
                "col2": "Министерство образования Ярославской области",
                "col3": "ГКУЗ ЯО «Областной специализированный дом ребенка №1»",
                "col4": "150008, \nг. Ярославль, \nул. Моховая, \nд. 14\n",
                "col5": "osdr1@mail.ru",
                "col6": "\ndommalysham.ru",
                "col7": "(4852) 50-29-40"
            }
        ]
    ]
]
