import s from "./Cabinet.module.css";
import { NavLink } from "react-router-dom";
import { Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

const columns = [
  {
    title: "Название",
    dataIndex: "header",
    key: "header",
    render: (text, record) => <NavLink to={record.ref}>{text}</NavLink>,
  },
  {
    title: "Период",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "Кем направлен",
    dataIndex: "creator",
    key: "creator",
  },
  {
    title: "Дата направления",
    key: "date",
    dataIndex: "date",
    render: (date) => <>{new Date(date).toLocaleDateString("ru-RU")}</>,
  },
  {
    title: "Основание",
    key: "file_name",
    dataIndex: "file_name",
    render: (text, record) => (
      <a target="_blank" href={record.file} rel="noreferrer">
        {text}
      </a>
    ),
  },
  {
    title: "Статус отчета",
    key: "status",
    dataIndex: "status",
  },
  {
    title: "Срок исполнения",
    key: "ending_date",
    dataIndex: "ending_date",
    render: (ending_date) => (
      <>
        До
        <br />
        {new Date(ending_date).toLocaleDateString("ru-RU")}
      </>
    ),
  },
];

const AllReportsPage2025 = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const fetchData = async () => {
    setLoading(true);
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: `https://frcopeca.ru/api/reports25`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await axios.request(config);
      setData(response.data);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className={s.main}>
      <h1>Отчеты за 2025 год</h1>
      <div className={s.table}>
        <Table loading={loading} columns={columns} dataSource={data} />
      </div>
    </div>
  );
};
export default AllReportsPage2025;
