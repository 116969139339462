import s from "./Cabinet.module.css";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Report2025_5 = () => {
    const [respData, setData] = useState({
        p1: "",
        p2: "",
        p3: "",
        p5: "",
        p6: "",
        p8: "",
        p9: "",
        p10: "",
        p11: "",
        p12: "",
        contact: "",
        регион: "",
        p1p1: "",
        p1p2: "",
        p1p3: "",
        p1p4: "",
        p1p5: "",
        comm10: "",
        comm9: "",
        comm8: "",
        примечание2: "",
        p7p3: "",
        p7p2: "",
        p7p1: "",
        p6p4p1: "",
        p6p4: "",
        p6p3: "",
        p6p2: "",
        p6p1: "",
        p5p3: "",
        p5p2: "",
        p5p1: "",
        примечание1: "",
        p4p4: "",
        p4p3: "",
        p4p2: "",
        p4p1: "",
        p3p4p1: "",
        p3p4: "",
        p3p3: "",
        p3p2: "",
        p3p1: "",
        p2p5: "",
        p2p4: "",
        p2p3: "",
        p2p2: "",
        p2p1: "",
    });
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user.id) {
                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: `https://frcopeca.ru/api/child_death20251?id=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                };
                try {
                    const response = await axios.request(config);
                    if (response.data[0]) {
                        setData(response.data[0]);
                    }
                } catch (error) {
                    setData({
                        p1: "",
                        p2: "",
                        p3: "",
                        p5: "",
                        p6: "",
                        p8: "",
                        p9: "",
                        p10: "",
                        p11: "",
                        p12: "",
                        contact: "",
                        регион: "",
                        p1p1: "",
                        p1p2: "",
                        p1p3: "",
                        p1p4: "",
                        p1p5: "",
                        comm10: "",
                        comm9: "",
                        comm8: "",
                        примечание2: "",
                        p7p3: "",
                        p7p2: "",
                        p7p1: "",
                        p6p4p1: "",
                        p6p4: "",
                        p6p3: "",
                        p6p2: "",
                        p6p1: "",
                        p5p3: "",
                        p5p2: "",
                        p5p1: "",
                        примечание1: "",
                        p4p4: "",
                        p4p3: "",
                        p4p2: "",
                        p4p1: "",
                        p3p4p1: "",
                        p3p4: "",
                        p3p3: "",
                        p3p2: "",
                        p3p1: "",
                        p2p5: "",
                        p2p4: "",
                        p2p3: "",
                        p2p2: "",
                        p2p1: "",
                    });
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading) {
        return <div className={s.main}>Загрузка...</div>;
    }

    return (
        <div className={s.main}>
            <h1>Мониторинг сведений о случаях гибели и насильственных преступлений, совершенных в отношении детей-сирот
                и детей, оставшихся без попечения родителей</h1>
            {respData.статус && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#000000",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Статус: {respData.статус}
                </div>
            )}
            {respData.комментарии && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "24px",
                        fontWeight: "normal",
                        color: "red",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Комментарий от проверяющего:
                    <br/>
                    {respData.комментарии}
                </div>
            )}
            <ReportForm respData={respData}/>
            {/* <AddForm/> */}
        </div>
    );
};
export default Report2025_5;

const ReportForm = ({respData}) => {
    return (
        <div>
            <Report2 respData={respData}/>
        </div>
    );
};

const Report2 = ({respData}) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [err, setErr] = useState("");


    const readOnly = respData.статус === "Опубликовано";
    const readOnlyRegion = respData.статус !== "" || respData.статус !== null
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                p1: respData.p1,
                p2: respData.p2,
                p3: respData.p3,
                p5: respData.p5,
                p6: respData.p6,
                p8: respData.p8,
                p9: respData.p9,
                p10: respData.p10,
                p11: respData.p11,
                p12: respData.p12,
                contact: respData.contact,
                регион: respData.регион,
                p1p1: respData.p1p1,
                p1p2: respData.p1p2,
                p1p3: respData.p1p3,
                p1p4: respData.p1p4,
                p1p5: respData.p1p5,
                comm10: respData.comm10,
                comm9: respData.comm9,
                comm8: respData.comm8,
                примечание2: respData.примечание2,
                p7p3: respData.p7p3,
                p7p2: respData.p7p2,
                p7p1: respData.p7p1,
                p6p4p1: respData.p6p4p1,
                p6p4: respData.p6p4,
                p6p3: respData.p6p3,
                p6p2: respData.p6p2,
                p6p1: respData.p6p1,
                p5p3: respData.p5p3,
                p5p2: respData.p5p2,
                p5p1: respData.p5p1,
                примечание1: respData.примечание1,
                p4p4: respData.p4p4,
                p4p3: respData.p4p3,
                p4p2: respData.p4p2,
                p4p1: respData.p4p1,
                p3p4p1: respData.p3p4p1,
                p3p4: respData.p3p4,
                p3p3: respData.p3p3,
                p3p2: respData.p3p2,
                p3p1: respData.p3p1,
                p2p5: respData.p2p5,
                p2p4: respData.p2p4,
                p2p3: respData.p2p3,
                p2p2: respData.p2p2,
                p2p1: respData.p2p1,
            }}
            onSubmit={(values) => {
                let data2 = new FormData();
                data2.append("p1", values.p1);
                data2.append("p2", values.p2);
                data2.append("p3", values.p3);
                data2.append("p5", values.p5);
                data2.append("p6", values.p6);
                data2.append("p8", values.p8);
                data2.append("p9", values.p9);
                data2.append("p10", values.p10);
                data2.append("p11", values.p11);
                data2.append("p12", values.p12);
                data2.append("contact", values.contact);
                data2.append("регион", values.регион);
                data2.append("p1p1", values.p1p1);
                data2.append("p1p2", values.p1p2);
                data2.append("p1p3", values.p1p3);
                data2.append("p1p4", values.p1p4);
                data2.append("p1p5", values.p1p5);
                data2.append("comm10", values.comm10);
                data2.append("comm9", values.comm9);
                data2.append("comm8", values.comm8);
                data2.append("примечание2", values.примечание2);
                data2.append("p7p3", values.p7p3);
                data2.append("p7p2", values.p7p2);
                data2.append("p7p1", values.p7p1);
                data2.append("p6p4p1", values.p6p4p1);
                data2.append("p6p4", values.p6p4);
                data2.append("p6p3", values.p6p3);
                data2.append("p6p2", values.p6p2);
                data2.append("p6p1", values.p6p1);
                data2.append("p5p3", values.p5p3);
                data2.append("p5p2", values.p5p2);
                data2.append("p5p1", values.p5p1);
                data2.append("примечание1", values.примечание1);
                data2.append("p4p4", values.p4p4);
                data2.append("p4p3", values.p4p3);
                data2.append("p4p2", values.p4p2);
                data2.append("p4p1", values.p4p1);
                data2.append("p3p4p1", values.p3p4p1);
                data2.append("p3p4", values.p3p4);
                data2.append("p3p3", values.p3p3);
                data2.append("p3p2", values.p3p2);
                data2.append("p3p1", values.p3p1);
                data2.append("p2p5", values.p2p5);
                data2.append("p2p4", values.p2p4);
                data2.append("p2p3", values.p2p3);
                data2.append("p2p2", values.p2p2);
                data2.append("p2p1", values.p2p1);
                data2.append("статус", "На утверждении")
                data2.append("author", user.id);
                let config = {
                    method: respData.регион ? "patch" : "POST",
                    maxBodyLength: Infinity,

                    url: `https://frcopeca.ru/api/child_death20251`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                    data: data2,
                };

                axios.request(config)
                    .then(() => {
                        setErr("");
                        navigate("/profile");
                    })
                    .catch((e) => {
                        const errorMessage = e.response
                            ? e.response.data?.message || e.response.statusText || "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
                            : e.message || JSON.stringify(e);

                        setErr(errorMessage);


                        console.error("Ошибка:", e);
                    });
            }}
        >
            {(errors, touched) => (
                <Form className={s.reportForm}>
                    <label htmlFor="регион">Субъект Российской Федерации</label>
                    <Field
                        readOnly={readOnlyRegion}
                        id="регион"
                        name="регион"
                        as="textarea"
                        required={true}
                    />

                    <label>
                        По данному мониторингу предоставляются сведения о детях-сиротах и
                        детях, оставшихся без попечения родителей, находящихся на воспитании
                        в семьях (усыновителей, опекунов (попечителей), патронатных
                        воспитателях) и под надзором в организациях для детей-сирот и детей,
                        оставшихся без попечения родителей.
                    </label>

                    <label>
                        Сокращенные понятия, применяемые в мониторинге: организации для
                        детей-сирот – организации для детей-сирот и детей, оставшихся без
                        попечения родителей; дети-сироты – дети-сироты и дети, оставшиеся
                        без попечения родителей; органы опеки и попечительства – органы
                        опеки и попечительства в отношении несовершеннолетних граждан;
                        подготовка – подготовка граждан, выразивших желание принять в свою
                        семью детей, оставшихся без попечения родителей;
                        социально-психологическое обследование – психологическое
                        обследование лиц, желающих принять на воспитание в свою семью
                        ребенка, оставшегося без попечения родителей; сопровождение -
                        сопровождение семей, принявших в свою семью на воспитание
                        детей-сирот и детей, оставшихся без попечения родителей; РФ –
                        Российская Федерация.
                    </label>

                    <label>
                        Указания по заполнению: Сведения по мониторингу предоставляются за
                        отчетный период с нарастающим итогом. Строка 1 должны быть равна
                        сумме строк 1.1-1.5; Строка 2 должна быть равна сумме строк 2.1-2.5;
                        Строка 3 должна быть равна сумме строк 3.1-3.4; Строка 6 должна быть
                        равна сумме строк 6.1-6.4; В строке 12 указывается комментарий к
                        строкам мониторинга (указать номер строки) либо ставится прочерк. В
                        строках 3.3 и 6.3 указываются следующие статьи Уголовного кодекса
                        РФ: Статья 105. Убийство. Статья 106. Убийство матерью
                        новорожденного ребенка. Статья 107. Убийство, совершенное в
                        состоянии аффекта. Статья 109. Причинение смерти по неосторожности.
                        Статья 111. Умышленное причинение тяжкого вреда здоровью.
                    </label>

                    <label htmlFor="p1">
                        1. Численность умерших детей-сирот (сумма строк 1.1 - 1.5),
                        находящихся на воспитании в семьях, из них:
                    </label>
                    <Field readOnly={readOnly} id="p1" name="p1" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p1p1">1.1 от болезни</label>
                    <Field readOnly={readOnly} id="p1p1" name="p1p1" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p1p2">1.2 от транспортных видов травм</label>
                    <Field readOnly={readOnly} id="p1p2" name="p1p2" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p1p3">1.3 от самоубийств</label>
                    <Field readOnly={readOnly} id="p1p3" name="p1p3" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p1p4">1.4 от убийств</label>
                    <Field readOnly={readOnly} id="p1p4" name="p1p4" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p1p5">1.5 другие причины</label>
                    <Field readOnly={readOnly} id="p1p5" name="p1p5" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p2">
                        2. Численность умерших детей-сирот, (сумма строк 2.1 - 2.5)
                        находящихся под надзором в организациях для детей-сирот, из них:{" "}
                    </label>
                    <Field readOnly={readOnly} id="p2" name="p2" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p2p1">2.1 от болезни</label>
                    <Field readOnly={readOnly} id="p2p1" name="p2p1" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p2p2">2.2 от транспортных видов травм</label>
                    <Field readOnly={readOnly} id="p2p2" name="p2p2" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p2p3">2.3 от самоубийств</label>
                    <Field readOnly={readOnly} id="p2p3" name="p2p3" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p2p4">2.4 от убийств</label>
                    <Field readOnly={readOnly} id="p2p4" name="p2p4" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p2p5">2.5 другие причины</label>
                    <Field readOnly={readOnly} id="p2p5" name="p2p5" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p3">
                        3 Всего детей-сирот, находящихся на воспитании в семьях (сумма строк
                        3.1 - 3.4), признанных потерпевшими по направленным в суд уголовным
                        делам, из них:
                    </label>
                    <Field readOnly={readOnly} id="p3" name="p3" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p3p1">
                        3.1 от преступлений против половой неприкосновенности
                    </label>
                    <Field readOnly={readOnly} id="p3p1" name="p3p1" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p3p2">3.2 от совершения истязания</label>
                    <Field readOnly={readOnly} id="p3p2" name="p3p2" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p3p3">
                        3.3 от преступлений против жизни и здоровья, предусмотренными
                        статьями 105-107, 109, 111 УК РФ
                    </label>
                    <Field readOnly={readOnly} id="p3p3" name="p3p3" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p3p4">3.4 иные преступления</label>
                    <Field readOnly={readOnly} id="p3p4" name="p3p4" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="3.4.1 при наличии показателя в строке 3.4 укажите преступления">
                        3.4.1 при наличии показателя в строке 3.4 укажите преступления
                    </label>
                    <Field readOnly={readOnly} id="p3p4p1" name="p3p4p1" as="textarea" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label>
                        4. (показатель строки 3 совпадает с суммой строк 4.1 - 4.4)
                    </label>

                    <label htmlFor="p4p1">
                        4.1 Численность детей-сирот, находящихся на воспитании в семьях,
                        признанных потерпевшими по направленным в суд уголовным делам от
                        преступлений со стороны усыновителя, опекуна (попечителя),
                        патронатного воспитателя
                    </label>
                    <Field readOnly={readOnly} id="p4p1" name="p4p1" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p4p2">
                        4.2 Численность детей-сирот, находящихся на воспитании в семьях,
                        признанных потерпевшими по направленным в суд уголовным делам от
                        преступлений со стороны супруга или сожителя усыновителя, опекуна
                        (попечителя), патронатного воспитателя
                    </label>
                    <Field readOnly={readOnly} id="p4p2" name="p4p2" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p4p3">
                        4.3 Численность детей-сирот, находящихся на воспитании в семьях,
                        признанных потерпевшими по направленным в суд уголовным делам от
                        преступлений со стороны иных лиц, проживающих совместно с
                        усыновителями, опекунами (попечителями), патронатными воспитателями
                    </label>
                    <Field readOnly={readOnly} id="p4p3" name="p4p3" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p4p4">
                        4.4 Численность детей-сирот, находящихся на воспитании в семьях,
                        признанных потерпевшими по направленным в суд уголовным делам от
                        преступлений со стороны посторонних лиц
                    </label>
                    <Field readOnly={readOnly} id="p4p4" name="p4p4" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="примечание1">Примечание</label>
                    <Field readOnly={readOnly} id="примечание1" name="примечание1" as="textarea"/>

                    <label htmlFor="p5">
                        5 Численность усыновителей, опекунов (попечителей), патронатных
                        воспитателей, в отношении которых направлены в суд уголовные дела по
                        совершенным преступлениям в отношении подопечных детей, из них:
                    </label>
                    <Field readOnly={readOnly} id="p5" name="p5" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p5p1">5.1 прошедших подготовку</label>
                    <Field readOnly={readOnly} id="p5p1" name="p5p1" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p5p2">
                        5.2 прошедших социально-психологическое обследование
                    </label>
                    <Field readOnly={readOnly} id="p5p2" name="p5p2" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p5p3">5.3 заключивших договор о сопровождении</label>
                    <Field readOnly={readOnly} id="p5p3" name="p5p3" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p6">
                        6 Всего детей-сирот, находящихся под надзором в организациях для
                        детей-сирот, признанных потерпевшими по направленным в суд уголовным
                        делам (сумма строк 6.1 - 6.4), из них:
                    </label>
                    <Field readOnly={readOnly} id="p6" name="p6" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p6p1">
                        6.1 от преступлений против половой неприкосновенности
                    </label>
                    <Field readOnly={readOnly} id="p6p1" name="p6p1" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p6p2">6.2 от совершения истязания </label>
                    <Field readOnly={readOnly} type="number" onWheel={(e) => e.target.blur()} id="p6p2" name="p6p2"
                           required={true}/>

                    <label htmlFor="p6p3">
                        6.3 от преступлений против жизни и здоровья, предусмотренными
                        статьями 105-107, 109, 111 УК РФ
                    </label>
                    <Field readOnly={readOnly} id="p6p3" name="p6p3" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p6p4">6.4 иные преступления</label>
                    <Field readOnly={readOnly} id="p6p4" name="p6p4" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p6p4p1">
                        6.4.1 при наличии показателя в строке 6.4 укажите преступления
                    </label>
                    <Field readOnly={readOnly} id="p6p4p1" name="p6p4p1" as="textarea"
                           onWheel={(e) => e.target.blur()}/>

                    <label>
                        7 (показатель строки 6 совпадает с суммой строк 7.1 - 7.3)
                    </label>

                    <label htmlFor="p7p1">
                        7.1 Численность детей-сирот, находящихся под надзором в организациях
                        для детей-сирот, признанных потерпевшими по направленным в суд
                        уголовным делам от преступлений со стороны работников организации
                        для детей-сирот
                    </label>
                    <Field readOnly={readOnly} id="p7p1" name="p7p1" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p7p2">
                        7.2 Численность детей-сирот, находящихся под надзором в организациях
                        для детей-сирот, признанных потерпевшими по направленным в суд
                        уголовным делам от преступлений со стороны других воспитанников
                        организации для детей-сирот
                    </label>
                    <Field readOnly={readOnly} id="p7p2" name="p7p2" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="p7p3">
                        7.3 Численность детей-сирот, находящихся под надзором в организациях
                        для детей-сирот, признанных потерпевшими по направленным в суд
                        уголовным делам от преступлений со стороны посторонних лиц
                    </label>
                    <Field readOnly={readOnly} id="p7p3" name="p7p3" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="примечание2">Примечание</label>
                    <Field readOnly={readOnly} id="примечание2" name="примечание2" as="textarea"/>

                    <label htmlFor="p8">
                        8 Численность работников организации для детей-сирот, в отношении
                        которых направлены в суд уголовные дела по совершенным преступлениям
                        в отношении детей, находящихся в организации для детей-сирот{" "}
                    </label>
                    <Field readOnly={readOnly} id="p8" name="p8" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="comm8">Комментарий к п.8</label>
                    <Field readOnly={readOnly} id="comm8" name="comm8" as="textarea"/>

                    <label htmlFor="p9">
                        9 Численность специалистов органов опеки и попечительства, в
                        отношении которых направлены в суд уголовные дела в связи с фактами
                        гибели и насильственных действий в отношении детей-сирот{" "}
                    </label>
                    <Field readOnly={readOnly} id="p9" name="p9" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="comm9">Комментарий к п.9</label>
                    <Field readOnly={readOnly} id="comm9" name="comm9" as="textarea"/>

                    <label htmlFor="p10">
                        10 Численность специалистов органов опеки и попечительства, в
                        отношении которых проведена служебная проверка в связи с фактами
                        гибели и насильственных действий в отношении детей-сирот{" "}
                    </label>
                    <Field readOnly={readOnly} id="p10" name="p10" type="number" onWheel={(e) => e.target.blur()}
                           required={true}/>

                    <label htmlFor="comm10">Введите комментарий к п.10</label>
                    <Field readOnly={readOnly} id="comm10" name="comm10" as="textarea"/>

                    <label htmlFor="p11">
                        11 Мероприятия по рассмотрению на уровне субъекта Российской
                        Федерации вопросов в связи с фактами гибели и насильственных
                        действий в отношении детей-сирот (совещания, обучающие мероприятия,
                        подготовка информационно-аналитических и методических материалов и
                        др.) <b>(указывается дата проведения, название мероприятия, охват
                        участников)</b>
                    </label>
                    <Field readOnly={readOnly} id="p11" name="p11" as="textarea" required={true}/>

                    <label htmlFor="p12">12 Комментарии</label>
                    <Field readOnly={readOnly} id="p12" name="p12" as="textarea"/>

                    <label htmlFor="contact">
                        13 Контактные данные ответственного лица по заполнению формы (ФИО
                        полностью, номер телефона (с кодом субъекта, при наличии), адрес
                        электронной почты)
                    </label>
                    <Field readOnly={readOnly} id="contact" name="contact" as="textarea" required={true}/>
                    <p></p>
                    <button type="submit">Отправить</button>
                    <p></p>
                    {respData.file && <a href={respData.file}>Скачать файл</a>}
                    <p></p>
                    <div style={{color: "red"}}>{err}</div>
                </Form>
            )}
        </Formik>
    );
};